import uuid from 'uuid';
import _set from 'lodash/set';
import _get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import ErrorHandler from 'services/templator/error';

class Controller extends ErrorHandler {
  constructor(view, plugins = {}) {
    super();

    Object.keys(plugins).forEach(key => {
      this[key] = plugins[key];
    });

    this.module = view.module;
    this.view = view;

    this.view_data = view.data;
    this.state = {};
    this.state_listeners = {};
    this.data_listeners = {};
    this.onInit();
  }

  onInit() {}

  notUpdateIfSame(data, key, value) {
    const prevValue = _get(data, key);

    return _isEqual(prevValue, value);
  }

  setStateByKey(key, value) {
    if (this.notUpdateIfSame(this.state, key, value)) { return; }

    _set(this.state, key, value);

    const l_key = key.split('.')[0];

    if (!this.state_listeners[l_key]) { return; }

    this.state_listeners[l_key].forEach(listener => listener(this.state));
  }

  setDataByKey(key, value) {
    if (this.notUpdateIfSame(this.view_data, key, value)) { return; }

    _set(this.view_data, key, value);

    const l_key = key.split('.')[0];

    if (!this.data_listeners[l_key]) { return; }

    this.data_listeners[l_key].forEach(listener => listener(this.view_data));
  }

  setState(newState) {
    this.update(newState, 'state');
  }

  setData(newData) {
    this.update(newData, 'data');
  }

  subscribeState(fn, ...keys) {
    return this.subscribeToChanges(fn, 'state', 'data', ...keys);
  }

  subscribeData(fn, ...keys) {
    return this.subscribeToChanges(fn, 'data', 'state', ...keys);
  }

  get subscribeToChanges() {
    return (fn, main_entity, sub_entity,  ...keys) => {
      const unsubscribe = () => keys.forEach(key => this[`${main_entity}_listeners`][key].delete(fn));

      fn(this[main_entity], this[sub_entity]);

      keys.forEach(key => {
        this[`${main_entity}_listeners`][key] = this[`${main_entity}_listeners`][key] || new Set([]);
        this[`${main_entity}_listeners`][key].add(fn);
      });

      this.module && this.module.addSubscription(unsubscribe);

      return unsubscribe;
    };
  }

  get update() {
    return (newValues, obj_name) => {
      this[`${obj_name}`] = { ...this[`${obj_name}`], ...newValues };

      Object.keys(newValues).forEach(key => {
        if (!this[`${obj_name}_listeners`][key]) { return; }

        this[`${obj_name}_listeners`][key].forEach(listener => listener(this[`${obj_name}`]));
      });
    };
  }

  get router() {
    return this.module.router;
  }

  get new_uuid() {
    return uuid();
  }

  get data() {
    return cloneDeep(this.view_data);
  }
}

export default Controller;
