import { render } from 'lit-html';
import NotAssignedController from 'modules/NotAssigned/not-assigned.controller';
import NotAssignedView from 'modules/NotAssigned/not-assigned.view';
import { RouterModule } from 'services/router/router.m';

@RouterModule({
  name: 'w-not-assigned-module',
  view: NotAssignedView,
  controller: NotAssignedController,
})
class NotAssignedModule {
  public subs: Set<() => void> = new Set([]);
  public view: NotAssignedView;

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default NotAssignedModule;
