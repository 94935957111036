import { differenceInDays, format, parseISO } from 'date-fns';
import { Observe } from 'decorators';
import { showIf } from 'directives';
import { html, nothing } from 'lit-html';
import buttonStyles from 'styles/button.module.scss';
import cardStyles from 'styles/card.module.scss';
import truncateName from 'utils/truncate-name';
import DashMatchController from './dash-match.controller';
import styles from './dash-match.module.scss';

class DashMatchTemplate {
  public controller: DashMatchController;
  private labels = {
    none: 'You are currently unmatched',
    new: 'You have a new match!',
    default: 'Your match details',
  };

  @Observe('matches')
  get switcher() {
    const { matches, handleActiveMatch } = this.controller;

    return matches.length > 1 ? html`
      <select
        class=${cardStyles['header-select']}
        @change=${handleActiveMatch}
      >
        ${matches.map(({ individual }, index) => html`
          <option value=${index}>${truncateName(individual.name)}</option>
        `)}
      </select>
    ` : nothing;
  }

  @Observe('associatedChatId', 'requests')
  get noMatch() {
    const { profile, requests, associatedChatId, handleMeetCommunity, handleRequestMatch } = this.controller;
    const { general: { firstName, lastName, profileImageUrl } } = profile;
    const currentUserName = `${firstName} ${lastName}`;
    const hasRequests = requests.length;

    return html`
      <div class=${styles['no-match']}>
        <div class=${styles.avatars}>
          <w-avatar avatar="${profileImageUrl}" title="${currentUserName}" width="60px" borderWidth="1"></w-avatar>
          <w-avatar title="?" width="80px" borderWidth="1"></w-avatar>
        </div>
        ${associatedChatId && html`
          <button class=${buttonStyles.small} @click=${handleRequestMatch} ?disabled=${hasRequests}>
            ${hasRequests ? 'Match requested' : 'Request a match'}
          </button>
          <button class=${buttonStyles.small} @click=${handleMeetCommunity}>
            Get to know your mentoring community
          </button>
        `}
      </div>
    `;
  }

  public activeMatch(isNewMatch: boolean) {
    const {
      profile,
      matches,
      activeMatchIndex,
      handleViewProfile,
      handleSchedule,
      handleSendMessage,
    } = this.controller;
    const { general: { firstName, lastName, profileImageUrl } } = profile;
    const currentUserName = `${firstName} ${lastName}`;
    const { individual, matched, scheduled, chatId, contactPromise } = matches[activeMatchIndex];
    const { name, avatar, id } = individual;

    return html`
      <div class=${styles.active}>
        <div class=${styles.avatars}>
          <w-avatar avatar="${profileImageUrl}" title="${currentUserName}" width="60px" borderWidth="1"></w-avatar>
          <w-avatar avatar="${avatar}" title="${name}" width="80px" borderWidth="1"></w-avatar>
        </div>
        <p>
          ${isNewMatch ?
            html`You've been matched with <strong>${name}</strong>. Start a conversation to kick things off.`
            : html`You were matched with <strong>${name}</strong> on ${format(parseISO(matched), 'd MMMM yyyy')}.`
          }
          ${showIf(contactPromise, `You promised to catch up ${contactPromise.toLowerCase()}.`)}
        </p>
        <div class=${styles.buttons}>
          <button class=${buttonStyles.small} @click=${() => handleViewProfile(id)}>View profile</button>
          <button class=${buttonStyles.small} @click=${() => handleSendMessage(chatId)}>Send a message</button>
          <button class=${buttonStyles.small} @click=${() => handleSchedule(chatId)}>
            ${(isNewMatch && !scheduled) || !scheduled ?
              'Schedule your first catch up'
              : 'Schedule a catch up'
            }
          </button>
        </div>
      </div>
    `;
  }

  @Observe('profile', 'matches', 'activeMatchIndex')
  get template() {
    let matchState = 'none';
    const { activeMatchIndex, profile, matches } = this.controller;
    const activeMatch = matches[activeMatchIndex];
    const hasMatch = profile && matches.length;
    const isNewMatch = activeMatch && differenceInDays(new Date(), parseISO(activeMatch.matched)) < 7;

    if (isNewMatch) {
      matchState = 'new';
    } else if (activeMatch) {
      matchState = 'default';
    }

    return html`
      <div class=${cardStyles.card}>
        <div class=${cardStyles.header}>
          <span class=${cardStyles.label}>${this.labels[matchState]}</span>
          ${this.switcher}
        </div>
        <div class=${cardStyles.content}>
          ${hasMatch ? this.activeMatch(isNewMatch) : profile && this.noMatch}
        </div>
      </div>
    `;
  }
}

export default DashMatchTemplate;
