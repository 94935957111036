import store from '@werkin/store';
import { alphabetTemplate, Icons } from 'components/lit';
import { userStatus } from 'components/lit/status.view';
import { Observe } from 'decorators';
import GeneralDirectives from 'directives/general';
import {html, nothing} from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import upperFirst from 'lodash/upperFirst';
import { Auth } from 'services';
import { Router } from 'services/router.service';
import styles from './info-tab.module.scss';
import InfoController from './info.controller';
import InfoModule from './info.module';

class Info {
  public controller: InfoController;
  public module: InfoModule;
  public vm: GeneralDirectives;

  public member(user) {
    return html`
      <div class="${styles.member}" @click=${(evt) => this.controller.chooseUser(evt, user)}>
        <div class="${styles.avatar_wrapper}">
          ${userStatus(user.id)}
          <w-avatar avatar="${user.avatar}" title="${user.name}" width="40px" borderWidth="1"> </w-avatar>
        </div>

        <div class="${styles.details}">
          <div class="${styles.name}">
            ${user.name}
          </div>
          <div class="${styles.sub_info}">
            <p>${user.position}</p>
            <p>${user.industry}</p>
          </div>
        </div>
      </div>
    `;
  }

  get access() {
    const { access } = this.controller.chat.attributes;

    return html`
      <div class="${styles.access}">${access}</div>
    `;
  }

  get controlButton() {
    const { chat } = this.controller;
    const userId = Auth.getUserId();
    const isOwner = chat.creator === userId;
    const isMember = !!chat.participants.find(({id}) => id === userId);

    const edit = html`<button class="w-button primary" @click=${() => this.controller.edit()}>Edit</button>`;

    const ljHandler = () => isMember ? this.controller.leaveJoin.leave() : this.controller.leaveJoin.join();
    const ljText = isMember ? 'Leave' : 'Join';
    const lj = html`<button class="w-button primary" @click=${ljHandler}>${ ljText }</button>`;

    return html`<div class="${styles['chat-info-buttons']}"> ${ isOwner ? edit : lj } </div>`;
  }

  get chat_info() {
    const { chat, chatMembers } = this.controller;

    const creator = chat.creator ? chatMembers.find((m) => m.id === chat.creator) : null;
    const createdDate = this.module.moment(chat.created).format('MMMM, Do YYYY');
    const createdBy = creator ? 'by ' + creator.name : '';
    const created = chat.type === 'group' ?  `Created ${createdBy} on ${createdDate}` : 'Official WERKIN channel';

    const info = html`
      <div class="${styles['body-center']}">
        <div class="${styles['chat-info-details']}">
          <h3>${chat.title}</h3>
          <h5>${created}</h5>
        </div>
        ${ this.vm.showIf(chat.type === 'group', this.controlButton) }
      </div>
    `;

    const purpose = html`
      <div class="${styles['body-center']}">
        <div class="${styles['chat-info-details']}">
          <h2>Purpose</h2>
          <p>${chat.purpose}</p>
        </div>
      </div>
    `;

    const tags = html`
      <div class="${styles['body-center']}">
        <div class="${styles['chat-info-details']}">
          <h2>Tags</h2>
          <div class="${styles['tags-list']}">
            ${ chat.tags.map((tag) => tag.label).join(', ') }
          </div>
        </div>
      </div>
    `;

    return html`
      <div class="${styles['chat-info']}">
        <div class="${styles['chat-info-header']}">
          <h2>About ${upperFirst(chat.type)}</h2>
          ${ this.vm.showIf(chat.type === 'group', this.access) }
        </div>

        <div class="${styles['chat-info-body']}">
          <w-avatar avatar="${chat.avatar}" title="${chat.title}" width="50px" borderWidth="2"> </w-avatar>
          <div class="${styles['chat-info-body-center']}">
            ${info}
            ${this.vm.showIf(chat.purpose, purpose)}
            ${this.vm.showIf(chat.tags && chat.tags.length, tags)}
          </div>
        </div>
      </div>
    `;
  }

  @Observe('isSingle', 'chat', 'chatMembers')
  get members() {
    const userId = Auth.getUserId();
    const { chat, chatMembers, edit } = this.controller;
    const chatMembersWithoutSelf = chatMembers.filter((m) => m.id !== userId);
    if (!chat) { return nothing; }

    const { type, participants, creator } = chat;
    const isMember = !!participants.find(({id}) => id === userId);

    const editMembers = html`
      <button
          class="w-button primary"
          @click=${() => edit(true, 'members') }
        >
          Edit members
      </button>
    `;

    return !this.controller.isSingle ? html`
      <div class="${styles['members-wrapper']}">
        <div class="${styles['members-header']}">
          <h2>${upperFirst(type)} Members (${ chatMembers.length })</h2>
          ${this.vm.showIf(type === 'group' && creator === userId, editMembers)}
        </div>
        <div class="${styles.members}">
          ${alphabetTemplate(
            chatMembersWithoutSelf,
            this.member.bind(this),
            isMember ? chatMembers.find((m) => m.id === userId) : null
          )}
        </div>
      </div>
    ` : nothing;
  }

  @Observe('yourShare')
  get yourShareCard() {
    if (!this.controller.user) { return nothing; }
    const name = this.controller.user.name.split(' ')[0];
    const route = {
      name: Router.route.name,
      params: Router.params,
      hash: {
        ProfileEditingModal: true,
        root: 'professionalDevelopment' ,
        targetEntity: 'learningAndDevelopmentAreas',
      },
    };

    return this.controller.yourShare ? html`
      <div class="${styles['share-card']}">
        <div>
          You have not shared your
          <a href="${Router.generate(route)}" @click=${(evt) => this.goTo(evt, route)}>goals</a>
          with ${name}.
        </div>
        <button class="w-button primary" @click=${() => this.controller.shareGoals()}>Share</button>
      </div>
    ` : html`
      <div class="${styles['share-card']}">
        You have shared your goals with ${name}.
      </div>
    `;
  }

  @Observe('userShare')
  get userShareCard() {
    if (!this.controller.user) { return nothing; }
    const name = this.controller.user.name.split(' ')[0];

    return this.controller.userShare ? html`
      <div class="${styles['share-card']}">
        ${name} has shared goals with you
        <button class="w-button primary" @click=${() => this.controller.viewShares()}>View</button>
      </div>
    ` : null;
  }

  public goTo(evt, route) {
    evt.preventDefault();

    Router.go(route);
  }

  public user_info() {
    const { chat, user, userCollective } = this.controller;
    const name = user.name.split(' ')[0];
    const chats = [];

    const landingRoute = store.get('landingRoute');
    const collectiveRoute = { path: landingRoute };

    const close_bar = html`
    <div class="${styles.close}">
      <button
        @click=${() => this.controller.backToUsers()}
      >
        ${Icons.close}
      </button>
    </div>`;

    const group = (chat) => {
      const route = {
        name: 'chats.chat',
        params: { chatId: chat.id },
      };

      return html`
        <a href="${Router.generate(route)}" @click=${(evt) => this.goTo(evt, route)}>${chat.title}</a>,
      `;
    };

    const belongedChats = html`
      <div class="${styles['groups-card']}">
        You both belong to ${ repeat(chats, (chat) => chat.id, group) }
      </div>
    `;

    return html`
      <div class="${styles['user-info']}">
        ${this.vm.showIf(chat.type !== 'direct' || !this.controller.isSingle, close_bar)}

        <h1>About ${name}</h1>

        <div class="${styles.details}">
          <w-avatar
            avatar="${user.avatar}"
            title="${user.name}"
            width="${Router.display === 'desktop' ? '50px' : '40px'}"
            borderWidth="1"
          > </w-avatar>

          <div class="${styles.info}">
            <div class=${styles.name}>
             <h3>${user.name}</h3>
             <span>${user.position}</span>
            </div>
            <p>${user.location}</p>
            <p>Joined ${this.module.moment(user.joined).format('D MMM YYYY')}</p>

            <section>
              <button
                class="w-button primary ${styles.profile}"
                @click=${() => this.controller.showProfile()}
              >
                View Full Profile
              </button>
            </section>
          </div>
        </div>

        <div class="${styles.ops}">
          <h2>You and ${name}</h2>

          ${ this.userShareCard }
          ${ this.yourShareCard }

          <div class="${styles['collectives-card']}">
            You are both in
            <a
              href="${landingRoute}"
              @click=${(evt) => this.goTo(evt, collectiveRoute)}
              class="${userCollective.name}"
            >${userCollective.name}</a>
            collective
          </div>

          ${this.vm.showIf(!!chats.length, belongedChats)}
        </div>

      </div>
    `;
  }

  public goal(goal) {
    return html`
      <div class="${styles['goal-card']}">
        <div class="${styles['goal-header']}">L&D Focus: ${goal.area}</div>
        <div class="${styles['goal-body']}">
          <span class="${styles.goals_text}">Related goal</span>
          <span class="${styles.goals_text} ${styles.goal_body_text}">${goal.goal}</span>
        </div>
      </div>`;
  }

  @Observe('goalsList')
  get goals_list() {
    return html`
      <div class="${styles.goals}">
        ${repeat(this.controller.goalsList, (g, i) => i, this.goal)}
      </div>
    `;
  }

  @Observe('latestVersion')
  public notUpdatedGoals(name) {
    return !this.controller.latestVersion
      ? html`<span class="${styles['not-updated']}">${name}'s goals have changed. Request an update.</span>`
      : nothing;
  }

  get goals_details() {
    const name = this.controller.user.name.split(' ')[0];

    const close_bar = html`
      <div class="${styles.close_goals}">
        <button
          @click=${() => this.controller.backToUser()}
        >
          ${Icons.arrowWhiteLeftLong} Back
        </button>

        <span>About ${name}</span>
      </div>

    `;

    return html`
      <div class="${styles['goals-details-wrapper']}">
        ${close_bar}

        <div>
          <h2> ${name}'s goals (${this.controller.goalsDate})</h2>
          ${ this.notUpdatedGoals(name) }
        </div>

        ${this.goals_list}

      </div>
    `;
  }

  @Observe('user', 'goalsDetails', 'chat')
  get chat_header() {
    const { user, goalsDetails, chat } = this.controller;

    if (!!user && !!goalsDetails) {
      return this.goals_details;
    }

    if (!!user) {
      return this.user_info();
    }

    if (chat && chat.type !== 'direct') {
      return this.chat_info;
    }

    return nothing;
  }

  get template() {
    return html`
      <div class="${styles.wrapper}">
        ${this.chat_header}
        ${this.members}
      </div>
    `;
  }
}

export default Info;
