import apollo from 'api';
import { observable } from 'decorators';
import { IGeneralProfile } from 'interfaces/profile/general-profile.interface';
import ProfileGeneralModule from 'modules/ProfileGeneral/profile-general.module';
import { Auth } from 'services';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';

import { Router } from 'services/router.service';

class ProfileGeneralController {
  public module: ProfileGeneralModule;

  @observable({
    job: {},
    location: {},
    biography: '',
    firstName: '',
    socialMedia: [],
    relevantWebsites: [],
  }) public profile: IGeneralProfile;

  public OnInit() {
    this.fetchProfile();
  }

  public fetchProfile() {
    const observe = apollo.watchQuery({
      query: ProfileQuery,
      variables: {
        id: Auth.getUserId(),
      },
    }).subscribe({
      next: async ({ data: { profile } }) => {
        this.profile = profile.general;
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public onEdit(targetEntity) {
    Router.go({
      hash: {
        ProfileEditingModal: true,
        root: 'general',
        targetEntity,
      },
    });
  }

  public formattedUrl(url: string) {
    try {
      return new URL(url).href;
    } catch {
      return `https://${url}`;
    }
  }

  public getWebsiteURL(type, value) {
    try {
      return new URL(value);
    } catch {
      const urls = {
        twitter: `https://twitter.com/${value}`,
        facebook: `https://www.facebook.com/${value}`,
        instagram: `https://www.instagram.com/${value}`,
        linkedin: `https://www.linkedin.com/in/${value}`,
      };

      return urls[type];
    }
  }
}

export default ProfileGeneralController;
