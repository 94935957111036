import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import DashWorkbookController from './dash-workbook.controller';
import DashWorkbookView from './dash-workbook.view';

@RouterModule({
  name: 'w-dash-workbook-module',
  controller: DashWorkbookController,
  view: DashWorkbookView,
})
class DashWorkbookModule {
  public subs: Set<() => void> = new Set([]);
  public view: DashWorkbookView;

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default DashWorkbookModule;
