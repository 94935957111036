import { render } from 'lit-html';
import dataService from 'services/data';
import { Router } from 'services/router.service';
import { RouterModule } from 'services/router/router.m';
import MentoringContractModal from './mentoring-contract.modal';
import ProfileProfessionalDevelopmentController from './profile-professional-development.controller';
import ProfileProfessionalDevelopmentTemplate from './profile-professional-development.view';

@RouterModule({
  name: 'w-profile-professional-development-module',
  view: ProfileProfessionalDevelopmentTemplate,
  controller: ProfileProfessionalDevelopmentController,
})
class ProfileProfessionalDevelopmentModule {
  public view: ProfileProfessionalDevelopmentTemplate;
  public subs: Set<() => void> = new Set([]);

  public async loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    render(this.view.template, module);
    Router.controller.addTSAutonomousModal(MentoringContractModal);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default ProfileProfessionalDevelopmentModule;
