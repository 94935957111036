/**
 * @file Holds utility to work with date & time
 * @since 0.3.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import {
  format,
  isWeekend,
  isSameDay,
  isSameMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isPast,
  isFuture,
  parseISO,
} from 'date-fns';

/**
 * Provides functionality to work with date and time,
 * including timezone detection
 */
class DateTime {

  /**
   * Getting current date
   * @type {Date}
   */
  get now() {
    return parseISO();
  }

  /**
   * Getting current timezone
   * @type {String}
   */
  get zone() { return this._detectTimezone() || this._fallbackTimezone(); }

  /**
   * Is the given date in the past?
   * @type {Boolean}
   */
  isPast(date) {
    return isPast(parseISO(date));
  }

  /**
   * Is the given date in the future?
   * @type {Boolean}
   */
  isFuture(date) {
    return isFuture(parseISO(date));
  }

  /**
   * Exporting desired functions
   * from date-fns
   */
  get isSameDay() { return isSameDay; }
  get isSameMonth() { return isSameMonth; }
  get isWeekend() { return isWeekend; }
  get addMonths() { return addMonths; }

  /* Public methods
   ******************/

  /**
   * Getting the formatted date string in the given format
   * @param  {Date} date - original date
   * @param  {String} formatString - string of tokens
   * @return {String} formatted date string
   */
  format(date, formatString) {
    return format(date, formatString);
  }

  /**
   * Getting the days of the week
   * @param  {Date} date - original date
   * @return {Array} days of the week
   */
  daysOfWeek(date) {
    return eachDayOfInterval({
      start: startOfWeek(date),
      end: endOfWeek(date),
    });
  }

  /**
   * Getting the days of the month
   * @param  {Date} date - original date
   * @return {Array} days of the month
   */
  daysOfMonth(date) {
    return  eachDayOfInterval({
      start: startOfWeek(startOfMonth(date)),
      end: endOfMonth(date),
    });
  }

  /* Private methods
   ******************/

  /**
   * Getting timezone using ECMAScript Internationalization API
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl|Intl0}
   *
   * @return {String}
   */
  _detectTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * Fallback for timezone detection
   * TODO: replace with desired code
   * @return {String}
   */
  _fallbackTimezone() {
    return 'Etc/Utc';
  }

  /**
   *Round date
   */
  roundDate(date, buffer) {
    const coeff = 1000 * 60 * buffer;

    return parseISO(Math.ceil(date.getTime() / coeff) * coeff);
  }
}

export default new DateTime();
