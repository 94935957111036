import { render } from 'lit-html';
import LoginController from 'modules/Login/login.controller';
import LoginView from 'modules/Login/login.view';
import { RouterModule } from 'services/router/router.m';

@RouterModule({
  name: 'w-login-module',
  view: LoginView,
  controller: LoginController,
})
class LoginModule {
  public view: LoginView;

  public mount(module) {
    render(this.view.template, module);
  }
}

export default LoginModule;
