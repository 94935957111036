interface IErrorData {
  methodName?: string;
  method?: string;
  className?: string;
  message?: string;
}

class RouterError extends Error {
  public message: string;
  public class: string;
  public method: string;
  public methodName: string;

  constructor({
    methodName,
    method,
    className,
    message,
  }: IErrorData) {
    super();

    this.message = message;
    this.class = className;
    this.method = method;
    this.methodName = methodName;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RouterError);
    } else {
      this.stack = (new Error()).stack;
    }
  }

  get name() { return 'RouterError'; }
  get description() { return `RouterError: Error in method ${this.methodName} of class ${this.class}`; }
}

export default RouterError;
