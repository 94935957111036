import apollo from 'api';
import BaseService from 'services/data/base.service';
import { errorHandler } from 'utils/error-helpers';
import StartParticipationMutation from '../../graphql/mutations/startParticipation.graphql';
import CollectivesQuery from '../../graphql/queries/collectives.graphql';

class CollectivesService extends BaseService {
  public async load() {
    this.setLoadingState('Loading your collectives');

    this.queryPrefetcher(CollectivesQuery, 'collectives', {});

    return this.startParticipation();
  }

  public async getCollectives() {
    const { data: { collectives } } = await apollo.query({
      query: CollectivesQuery,
    });

    return collectives;
  }

  public async getActiveCollective() {
    const collectives = await this.getCollectives();

    return collectives.find(({ isActive }) => isActive);
  }

  public async startParticipation() {
    try {
      const { id, name, startedParticipation } = await this.getActiveCollective();

      if (startedParticipation) { return; }

      this.setLoadingState(`Joining ${name}`);

      await apollo.mutate({
        mutation: StartParticipationMutation,
        variables: {
          command: {
            collectiveId: id,
          },
        },
      });
    } catch (err) {
      console.error('startParticipation Error', err);
      errorHandler(err, true);
    }
  }
}

export default new CollectivesService();
