import CenterAutonomousModal from 'components/lit/modals/center-autonomus-modal.view';
import { AutonomousModal } from 'decorators';
import ChatsService from 'services/data/chats.service';
import { NotificationManager } from 'services/index';
import { Router } from 'services/router.service';

@AutonomousModal('DeleteChatModal')
class DeleteChatModal extends CenterAutonomousModal {
  public onOpen() {
    this.title = 'Delete chat?';
    this.text = 'This will permanently delete your group. Are you sure?';

    this.submitBtnText = 'Delete';

    this.template = this.wrap(this.tmp);
  }

  public async submitForm() {
    const id = Router.params.chatId;
    await ChatsService.deleteChat(id);
    Router.go({ name: 'chat' });
    NotificationManager.showNotification({ text: 'The chat was deleted', type: 'warning'});
    this.destroy();
  }
}

export default DeleteChatModal;
