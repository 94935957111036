class VersionService {
  private currentHash: string;
  private endpoint: string = '/version.json';

  constructor() {
    const { REACT_APP_ENVIRONMENT } = process.env;

    if (REACT_APP_ENVIRONMENT !== 'local') {
      this.init();
    }
  }

  public init(frequency = 1000 * 60 * 5) {
    setInterval(() => {
        this.fetchVersion();
    }, frequency);
  }

  private fetchVersion() {
    fetch(this.endpoint, {cache: 'no-cache'})
      .then((response) => response.json())
      .then((response) => {
        const { buildDate, env, hash } = response;
        const hashChanged = this.hasHashChanged(this.currentHash, hash);
        console.log(`VersionService: ${hash} on ${env}, ${buildDate}`);

        if (hashChanged) {
          console.log(`VersionService: New App Version, ${hash}`);
          console.log('DEVELOP TEST CONSOLE');
          window.location.reload();

          return false;
        }

        this.currentHash = hash;
      })
      .catch((err) => {
        console.error('VersionService: Could not fetch version:', err);
      });
  }

  private hasHashChanged(currentHash: string, newHash: string) {
    if (!currentHash) { return false; }

    return currentHash !== newHash;
  }
}

export default VersionService;
