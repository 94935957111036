import CancelActionModal from './cancel-modal.view';

class DiscardModal extends CancelActionModal {
  onOpen() {
    super.onOpen();

    this.title = 'Discard changes?';
    this.text = 'Are you sure you want to discard your changes?';
    this.submitBtnText = 'Discard';
    this.cancelBtnText = 'No';
    this.template = this.wrap(this.tmp);
  }
}

export default DiscardModal;
