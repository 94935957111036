import store from '@werkin/store';
import apollo from 'api';
import DatePicker from 'atoms/lit/datepicker/datepicker.view';
import { isSameDay, parseISO } from 'date-fns';
import { observable, ObserveMethod } from 'decorators';
import moment from 'lib/moment';
import ActionsListModule from 'modules/ActionsList/actions-list.module';
import { Router } from 'services/router.service';
import ActionUtils from 'utils/action-helpers';
import ActionsQuery from '../../graphql/queries/actions.graphql';

class ActionsListController {
  public module: ActionsListModule;
  public action_utils: ActionUtils;

  @observable({}) public loading: any;
  @observable(moment()) public selectedDate: any;
  @observable([]) public list: any[];
  @observable(false) public listUpdated: boolean;
  @observable() public datepicker: any;

  constructor() {
    this.action = this.action.bind(this);
  }

  public OnInit() {
    const { state } = Router;
    const storeDate = store.get('selectedDate');
    const currentDate = this.isCalendarHidden ? storeDate || state.date : storeDate;

    const date = moment(currentDate).toISOString(true);

    this.setDate(date);
    this.getActionsList();
    this.setDatePicker();
  }

  get isCalendarHidden() {
    return Router.route.name === 'calendar' && Router.display === 'desktop';
  }

  public async action(name, event) {
    this.loading = { ...this.loading, [event.id] : true };

    await this.action_utils.action(name, {
      ...event,
      organizer: {
        id: event.organizerId,
      },
      data: JSON.parse(event.data),
    });

    this.loading = { ...this.loading, [event.id] : false };
  }

  public getEventDate(event) {
    const data = JSON.parse(event.data);

    return moment(data.startTime).format('h:mm A, dddd, D MMMM YYYY');
  }

  public get isCalendarList() {
    return ['calendar', 'calendar.event'].includes(Router.route.name);
  }

  private isEmptyObject(entity) {
    return !entity || !Object.keys(entity).length;
  }

  private setDate(date) {
    store.set('selectedDate', date);
    this.selectedDate = date;
  }

  private setDatePicker() {
    this.datepicker = !this.isCalendarHidden && new DatePicker({
      onChange: (data) => this.setDate(data),
      selected: this.selectedDate,
      disablePreviousDates: true,
    });

    if (!this.datepicker) { return; }

    const observe = apollo.watchQuery({
      query: ActionsQuery,
    }).subscribe({
      next: ({ data: { actions } }) => {
        const dates = actions.reduce((acc, action) => {
          const actionData = JSON.parse(action.data);
          const filterByCurrentChat = this.isCalendarList || actionData.chatId === Router.params.chatId;

          if (!this.isEmptyObject(action) && filterByCurrentChat) {
            acc.push(JSON.parse(action.data).startTime);
          }

          return acc;
        }, []);

        this.datepicker.setHighlighted(dates);
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  private getActionsList() {
    this.findList();

    const unsubscribeFromStore = store.subscribe('selectedDate', (selectedDate) => {
      this.selectedDate = selectedDate;
    });

    const observe = apollo.watchQuery({
      query: ActionsQuery,
    }).subscribe({
      next: ({ data: { actions } }) => {
        this.listUpdated = !this.listUpdated;
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
    this.module.subs.add(unsubscribeFromStore);
  }

  @ObserveMethod('selectedDate', 'listUpdated')
  private async findList() {
    const getList = async () => {
      const { data: { actions } } = await apollo.query({
        query: ActionsQuery,
      });

      return actions.filter((action) => {
        if (this.isEmptyObject(action)) { return false; }
        const { startTime, chatId } = JSON.parse(action.data);

        return isSameDay(parseISO(startTime), parseISO(this.selectedDate)) &&
          (this.isCalendarList || chatId === Router.params.chatId);
      });
    };

    this.list = await getList();
  }
}

export default ActionsListController;
