import store from '@werkin/store';
import apollo from 'api';
import { Auth } from 'services';
import { OnInit } from 'services/router/router.interfaces';
import uuid from 'uuid';

import Router from 'services/router';
import createInChatNudgeMutation from '../../../graphql/mutations/createInChatNudge.graphql';
import createPollMutation from '../../../graphql/mutations/createPoll.graphql';
import ChatQuery from '../../../graphql/queries/chat/Chat.graphql';
import SuggestionsModule from './suggestions.module';

class SuggestionsController implements OnInit {
  public router: Router;
  public module: SuggestionsModule;
  public isDirectOrGroup: boolean;

  public async OnInit() {
    await this.isDirectOrGroupChat();
  }

  public async isDirectOrGroupChat() {
    try {
      const { data: { chat } } = await apollo.query({
        query: ChatQuery,
        variables: {
          id: this.router.params.chatId,
        },
      });

      this.isDirectOrGroup = !!chat && ['direct', 'group'].includes(chat.type);
    } catch (err) {
      this.isDirectOrGroup = null;
    }
  }

  public create_schedule(evt) {
    evt.preventDefault();

    store.set('source', 'schedule');
    this.router.go({  name: 'chats.actions.create', params: this.router.params  });
  }

  public create_poll(evt) {
    evt.preventDefault();

    const button = {
      id: uuid(),
      title: 'Button title',
      text: 'Button text',
      type: 'BUTTON',
      recipients: [Auth.getUserId()],
      options: [
        { text: 'Yay', icon: 'poll-yay', type: 'SUBMIT' },
        { text: 'Maybe', icon: 'poll-maybe', type: 'SUBMIT' },
        { text: 'Nay', icon: 'poll-nay', type: 'SUBMIT' },
        { text: 'Tell us', icon: 'poll-tell-us', type: 'INPUT', placeHolder: 'Tell us here', description: 'test description' },
      ],
    };

    const choice = {
      id: uuid(),
      title: 'Choice title',
      text: 'Choice text',
      type: 'CHOICE',
      recipients: [Auth.getUserId()],
      options: [
        { text: 'Very disappointed' },
        { text: 'Somewhat disappointed' },
        { text: 'Not disappointed' },
        { text: 'I no longer use it' },
      ],
    };

    const pollTypes = { choice, button };

    apollo.mutate({
      mutation: createPollMutation,
      variables: {
        command: pollTypes[this.router.query.type || 'button'],
      },
    }).then(() => {
      window.location.reload();
    });
  }

  public nudge_action() {
    const { chatId } =  this.router.params;

    apollo.mutate({
      mutation: createInChatNudgeMutation,
      variables: {
        command: {
          chatId, id: Auth.getUserId(),
        },
      },
    }).then(() => apollo.query({ query: ChatQuery, variables: { id: chatId } }))
      .then(({ data: { chat } }) => {
        this.router.controller.messages.bulkUpsert(chat.messages.map((msg) => ({ ...msg, chatId })));
      });
  }
}

export default SuggestionsController;
