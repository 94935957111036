import { observable } from 'decorators';
import * as programmeOverview from 'interfaces/programmeOverview';
import ProgrammeOverview from 'services/data/programmeOverview.service';
import { Router } from 'services/router.service';
import DashProgrammeModule from './dash-programme.module';

class DashProgrammeController {
  public module: DashProgrammeModule;
  @observable({}) public programmeContent: programmeOverview.ProgrameOverview;
  @observable('') public relationshipId: string;

  constructor() {
    this.handleViewClient = this.handleViewClient.bind(this);
    this.handleViewProfile = this.handleViewProfile.bind(this);
  }

  public async OnInit() {
    this.fetchProgramme();
  }

  public handleViewClient() {
    Router.go({ name: 'chats.chat', params: { chatId: this.programmeContent.communityChatId } });
  }

  public handleViewProfile() {
    Router.go({
      name: 'user-profile',
      params: { tab: 'professional_development' },
      hash: { MentoringContractModal: true, relationshipId: this.relationshipId },
    });
  }

  public async fetchProgramme() {
    this.programmeContent = await ProgrammeOverview.getProgrammeOverview();
  }
}

export default DashProgrammeController;
