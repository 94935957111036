import { render } from 'lit-html';
import ProfileHeaderController from 'modules/ProfileHeader/profile-header.controller';
import ProfileHeaderView from 'modules/ProfileHeader/profile-header.view';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';

@RouterModule({
  name: 'w-profile-header-view',
  view: ProfileHeaderView,
  controller: ProfileHeaderController,
})
class ProfileHeaderModule {
  public view: ProfileHeaderView;
  public controller: ProfileHeaderController;
  public subs: Set<() => void> = new Set([]);

  public async loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default ProfileHeaderModule;
