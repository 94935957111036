import useDebounce from 'hooks/useDebounce';
import Quill from 'quill';
import { useEffect, useState } from 'react';
import React from 'react';
import styles from './GiphyModal.module.scss';

const GIPHY_API_KEY = process.env.REACT_APP_GIPHY_API_KEY;

interface GiphyModalProps {
  quill: Quill;
  showModal: boolean;
  cb?: (gif: MyGif) => void;
}

type SelectedGifMappedType<T> = {
  [K in keyof T]: T[K] extends object ? SelectedGifMappedType<T[K]> : T[K];
};

export type MyGif = SelectedGifMappedType<{
  images: { original: { url: string }; fixed_height: { url: string } };
  id: string;
}>;

function GiphyModal({ quill, showModal = false, cb }: GiphyModalProps) {
  const [searchQuery, setSearchQuery] = useState<string>('love');
  const [gifs, setGifs] = useState<MyGif[]>([]);

  const debouncedQuery = useDebounce(searchQuery, 600);

  useEffect(() => {
    const url = `https://api.giphy.com/v1/gifs/search?api_key=${GIPHY_API_KEY}&q=${debouncedQuery}&limit=20`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setGifs(data.data);
      });
  }, [debouncedQuery]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSelect = (gif: MyGif) => {
    cb(gif);
  };

  if (!showModal) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <input
        type='text'
        placeholder="Search for gif's in werkin, powered by GIPHY"
        className={styles.input}
        onChange={handleInputChange}
        value=''
      />

      <div className={styles['img-wrapper']}>
        {gifs.map((gif) => (
          <img
            key={gif.id}
            src={gif.images.fixed_height.url}
            alt=''
            onClick={() => handleSelect(gif)}
            className={styles.image}
          />
        ))}
      </div>
    </div>
  );
}

export default GiphyModal;
