import { render } from 'lit-html';
import MatchBodyController from 'modules/MatchBody/match-body.controller';
import MatchBodyView from 'modules/MatchBody/match-body.view';
import MatchService from 'services/data/match.service';
import { RouterModule } from 'services/router/router.m';

@RouterModule({
  name: 'w-match-body-module',
  view: MatchBodyView,
  controller: MatchBodyController,
})
class MatchBodyModule {
  public view: MatchBodyView;
  public controller: MatchBodyController;

  public async loading() {
    const data = await MatchService.requestMatch();

    this.controller.match = data.match;
  }

  public mount(module) {
    render(this.view.template, module);
  }
}

export default MatchBodyModule;
