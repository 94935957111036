import styles from './editor.module.scss';

export function selectedMention(item) {
  return `<span class="${styles.mention}">${'@' + item.original.name}</span>`;
}

function userAvatar(user) {
  const name = user.name.split(' ').map((text) => text ? text[0] : '').join('').toUpperCase();

  return `
    <div class="${styles.avatar}">
      ${ user.avatar ? `<img src="${user.avatar}" alt="${user.name}"/>` : name }
    </div>`;
}

export const mentionMenuItem = (user) =>
  `
    <div class="${styles['mention-item']}">
      ${userAvatar(user)}
      <div class="${styles.alias}">@${user.alias}</div>
      <div class="${styles.mention_name}">${user.name}</div>
    </div>`;
