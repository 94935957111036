import generateBody from 'view/generators/generate-body';
import generateEmptyBody from 'view/generators/generate-empty-body';
import generateCalendarBody from 'view/generators/generate-calendar-body';
import messages from 'view/modules/messages';
import sub_nav from 'view/modules/sub_nav';
import schedule from 'view/modules/schedule';
import dash from 'view/modules/dash';

const temp = (mql, { default: template }) => template;
const tempFunc = (mql, withPolls, { default: template }) => template(mql, withPolls);

const generateDefaultTmp = (mql, { default: generateDefault }) => {
  const body = generateBody({});

  return generateDefault(body, mql, true);
};

const generateCalendarTemplate = (mql, { default: generateDefault }) => {
  const body = generateCalendarBody({ hideCalendar: mql === 'mobile' });

  return generateDefault(body, mql, true);
};

const generateMessagesTmp = (mql, { default: generateDefault }) => {
  const body = generateBody(
    {
      center: messages,
      right: sub_nav(),
    }
  );

  return generateDefault(body, mql);
};

const generateScheduleTmp = (mql, { default: generateDefault }) => {
  const body = generateBody(
    {
      center: messages,
      right: schedule(),
    }
  );

  return generateDefault(body, mql, true);
};

const generateDashTmp = (mql, { default: generateDefault }) => {
  const body = generateEmptyBody(dash);

  return generateDefault(body, mql, false);
};

export default {
  // Not authorized register flow part
  forgotPassword:     mql => import ('./layouts/register/forgot-password').then(tempFunc.bind(null, mql, false)),
  getLink:            mql => import ('./layouts/register/get-link').then(tempFunc.bind(null, mql, false)),
  login:              mql => import ('./layouts/register/login').then(tempFunc.bind(null, mql, false)),
  register:           mql => import ('./layouts/register/register').then(tempFunc.bind(null, mql, false)),
  registerInterest:   mql => import ('./layouts/register/register-interest').then(tempFunc.bind(null, mql, false)),
  registrationClosed: mql => import ('./layouts/register/registration-closed').then(tempFunc.bind(null, mql, false)),
  resetPassword:      mql => import ('./layouts/register/reset-password').then(tempFunc.bind(null, mql, false)),
  temporary:          mql => import ('./layouts/register/temporary').then(tempFunc.bind(null, mql, false)),

  // Authorized register flow part
  beforeContinue: mql => import ('./layouts/register/before-continue').then(tempFunc.bind(null, mql, false)),
  join:           mql => import ('./layouts/register/join').then(tempFunc.bind(null, mql, false)),
  launch:         mql => import ('./layouts/register/launch').then(temp.bind(null, mql)),
  willBeInTouch:  mql => import ('./layouts/register/will-be-in-touch').then(tempFunc.bind(null, mql, false)),
  match:          mql => import ('./layouts/register/match').then(temp.bind(null, mql)),
  noMatch:        mql => import ('./layouts/register/no-match').then(temp.bind(null, mql)),
  dash:           mql => import ('./layouts/default').then(generateDashTmp.bind(null, mql)),
  'help':           mql => import ('./layouts/tab_container').then(tempFunc.bind(null, mql, true)),

  'alerts':         mql => import ('./layouts/alerts').then(tempFunc.bind(null, mql, true)),

  'chat':           mql => import ('./layouts/default').then(generateDefaultTmp.bind(null, mql)),
  'chat.create':    mql => import ('./layouts/chat').then(temp.bind(null, mql)),
  'chat.edit':      mql => import ('./layouts/chat').then(temp.bind(null, mql)),
  'logout':         mql => import ('./layouts/default').then(generateDefaultTmp.bind(null, mql)),
  'profile':        mql => import ('./layouts/tab_container').then(tempFunc.bind(null, mql, true)),
  'user-profile':   mql => import ('./layouts/profile').then(tempFunc.bind(null, mql, true)),
  'individual':     mql => import ('./layouts/individual').then(tempFunc.bind(null, mql, true)),
  'credits':        mql => import ('./layouts/credits').then(tempFunc.bind(null, mql, true)),
  'calendar':       mql => import ('./layouts/default').then(generateCalendarTemplate.bind(null, mql)),
  'calendar.event': mql => import('./layouts/default').then(generateCalendarTemplate.bind(null, mql)),
  'report':         mql => import ('./layouts/report').then(tempFunc.bind(null, mql, true)),

  'chats.info':     mql => import ('./layouts/messages').then(generateMessagesTmp.bind(null, mql)),
  'chats.notes':    mql => import ('./layouts/messages').then(generateMessagesTmp.bind(null, mql)),
  'chats.chat':     mql => import ('./layouts/messages').then(generateMessagesTmp.bind(null, mql)),
  'chats.schedule': mql => import ('./layouts/messages').then(generateScheduleTmp.bind(null, mql)),
  'chats.activity': mql => import ('./layouts/messages').then(generateMessagesTmp.bind(null, mql)),
  'chats.sub':      mql => import ('./layouts/messages').then(generateMessagesTmp.bind(null, mql)),

  noCollective:     mql => import('./layouts/no-collective').then(temp.bind(null, mql)),
};
