import {ValidationStrategy} from 'components/custom/enums';
import { loader } from 'components/lit/loader.view';
import { TermsFooter } from 'components/lit/terms-footer.view';
import { Observe } from 'decorators';
import { html } from 'lit-html';
import { Router } from 'services/router.service';
import RegisterInterestController from './register-interest.controller';
import styles from './register-interest.module.scss';


class RegisterInterestView {
  public controller: RegisterInterestController;

  @Observe('valid', 'loading')
  get buttons() {
    const { valid, loading } = this.controller;

    return html`
      <div class="${styles.buttons}">
         <button
          type="button"
          ?disabled=${loading}
          @click="${() => this.controller.onKeepInformed()}"
          class="w-button outline">Skip this but keep me informed</button>
         <button
           type="submit"
           ?disabled=${!valid || loading}
           class="w-button primary"
          >${loading ? 'Loading...' : 'Register'}</button>
       </div>
    `;
  }

  @Observe('industries', 'sector', 'stages', 'city', 'roles')
  get form() {
    return html`
      <form
       novalidate="novalidate"
       class="${styles.form}"
       @changeForm="${(e) => this.controller.onChange(e)}"
       @submit="${(e) => this.controller.onSubmit(e)}"
       >
        <w-validation-box label="Email Address*" strategy="${ValidationStrategy.off}">
          <w-input
            disabled
            value="${Router.state.email}"
            type="email"
            name="emailAddress"
            placeholder="Email Address"
            vRequired
          >
        </w-validation-box>
        <w-validation-box label="First Name*">
          <w-input
            minlength="3"
            type="text"
            name="firstName"
            placeholder="First Name"
            vRequired
          >
        </w-validation-box>
        <w-validation-box label="Last Name*">
          <w-input
            minlength="3"
            type="text"
            name="lastName"
            placeholder="Last Name"
            vRequired
          >
        </w-validation-box>
        <w-validation-box label="Name of organization or network*">
          <w-input
            minlength="3"
            type="text"
            name="organization"
            placeholder="Type name here"
            vRequired
          >
        </w-validation-box>
        <w-validation-box label="Industry*">
          <w-select
           name="industry"
           vRequired
           .options=${this.controller.industries}
           .placeholder="${'Select industry'}"
           .empty="${'No industries found'}"
           />
        </w-validation-box>
        <w-validation-box label="Sector*">
          <w-select
           name="sector"
           vRequired
           .options=${this.controller.sector}
           .placeholder="${'Select sector'}"
           .empty="${this.controller.sectorEmpty}"
           />
        </w-validation-box>
        <w-validation-box label="Group, function or division">
          <w-input
            minlength="3"
            type="text"
            name="group"
            placeholder="Type group, function or division name here"
          >
        </w-validation-box>
        <w-validation-box label="Business unit">
          <w-input
            minlength="3"
            type="text"
            name="unit"
            placeholder="Type business unit name here"
          >
        </w-validation-box>
         <w-validation-box label="Job title*">
          <w-input
            minlength="3"
            type="text"
            name="title"
            placeholder="Type job title here"
            vRequired
          >
        </w-validation-box>
        <w-validation-box label="Country*">
          <w-select
           name="country"
           vRequired
           .options=${this.controller.countries}
           .placeholder="${'Select country'}"
           .empty="${'Countries not found'}"
           />
        </w-validation-box>
        <w-validation-box label="City*">
          <w-select
           name="city"
           vRequired
           .options=${this.controller.city}
           .placeholder="${'Select city'}"
           .empty="${this.controller.cityEmpty}"
           />
        </w-validation-box>
        <w-validation-box label="Are you applying to be a mentor, mentee or buddy?*">
          <w-select
           name="role"
           vRequired
           .options=${this.controller.roles}
           .placeholder="${'Select city'}"
           />
        </w-validation-box>
        <w-validation-box label="Briefly explain your reasons for applying and what you hope to achieve by joining*">
          <w-textarea
            rows="6"
            minlength="3"
            name="reason"
            placeholder="Type your response here"
            vRequired
          >
        </w-validation-box>
        <w-validation-box label="Do you belong to any Employee Resource Groups (ERGs) or Affinity groups in your organisation?*">
          <w-textarea
            minlength="2"
            rows="6"
            name="erg"
            placeholder="Please list them here"
            vRequired
          >
        </w-validation-box>
        ${this.buttons}
      </form>
    `;
  }

  @Observe('header')
  get header() {
    return this.controller.header ?
      html`
        <div class="${styles.desc}">
          ${this.controller.header}
        </div>
      `
      : html`
        <div class="${styles.loading}">
          ${loader}
        </div>
      `;
  }

  public get template() {
    return html`
      <div class="${styles.wrapper}">
        <div class="${styles.request}">Request to join</div>
        ${this.header}
        <div class="${styles.mandatory}">
          *Mandatory fields
        </div>
        ${this.form}
        ${TermsFooter}
      </div>`;
  }
}

export default RegisterInterestView;
