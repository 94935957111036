import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import DashTasksController from './dash-tasks.controller';
import DashTasksView from './dash-tasks.view';

@RouterModule({
  name: 'w-dash-tasks-module',
  controller: DashTasksController,
  view: DashTasksView,
})
class DashTasksModule {
  public subs: Set<() => void> = new Set([]);
  public view: DashTasksView;

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default DashTasksModule;
