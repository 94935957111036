import { html } from 'lit-html';
import styles from './loader.module.scss';

export const loader = html`
  <div class="${styles.loader}">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
`;
