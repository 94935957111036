import ValidatorHelper from 'utils/validation-helpers';
import { ValidationErrors } from './enums';

const vHelper = new ValidatorHelper();

type validationFunction = (v: string) => null | string;

export default  {
  get vrequired(): validationFunction {
    return (value) => value ? null : ValidationErrors.required;
  },

  get vemail(): validationFunction {
    return (value) => vHelper.email(value) ? null : ValidationErrors.email;
  },

  get vname(): validationFunction {
    return (value) => vHelper.name(value) ? null : ValidationErrors.name;
  },

  get vspecial(): validationFunction {
    return (value) => vHelper.special(value) ? null : ValidationErrors.special;
  },

  get vnumber(): validationFunction {
    return (value) => vHelper.number(value) ? null : ValidationErrors.number;
  },

  get vlowercase(): validationFunction {
    return (value) => vHelper.lowercase(value) ? null : ValidationErrors.lowercase;
  },

  get vuppercase(): validationFunction {
    return (value) => vHelper.uppercase(value) ? null : ValidationErrors.uppercase;
  },

  get vdisallowspace(): validationFunction {
    return (value) => vHelper.withoutSpaces(value) ? null : ValidationErrors.withoutSpaces;
  },
};
