import styles from './profile-modal.module.scss';
import CenterAutonomousModal from './center-autonomus-modal.view';

class ProfileAutonomousModal extends CenterAutonomousModal {
  constructor(...args) {
    super(...args);

    this.setStyles(styles);
  }
}

export default ProfileAutonomousModal;
