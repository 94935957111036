export const settings = [
  /* {
  title: 'Calendar',
  autonomous: true,
  options: [{
    description: 'Enable WERKIN events to appear in my own calendar',
    controller: {
      type: 'button',
      targetKey: 'authorizedCalendarAccess',
    },
  },
  // {
  //   description: `Allow other members of the collective to see available slots
  //     in my calendar (they won't see any event details)`,
  //   controller: {
  //     type: 'button',
  //     targetKey: '',
  //   },
  // }
  ],
}, */ {
    title: 'Online presence & location',
    options: [
      {
        description: 'Set my activity status to online when I have the app open',
        controller: {
          type: 'switch',
          targetKey: 'setOnlineLogin',
        },
      },
      {
        description:
          "Make my current location invisible to the app (This means we won't be able to propose meet ups with relevant people who are nearby)",
        controller: {
          type: 'switch',
          targetKey: 'locationVisible',
        },
      },
    ],
  },
  {
    title: 'Public profile',
    options: [
      {
        description: 'Display my mentor availability to members of this collective',
        controller: {
          type: 'switch',
          targetKey: 'showMentoringAvailability',
        },
      },
      {
        description: 'Display my Skills and Interests to members of this collective',
        controller: {
          type: 'switch',
          targetKey: 'showSkillsInterests',
        },
      },
      // {
      //   description: 'Use my alias as my display name in this collective',
      //   controller: {
      //     type: 'switch',
      //     targetKey: 'useAliasAsName',
      //   },
      // }
    ],
  },
];
