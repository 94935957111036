import apollo from 'api';
import Controller from 'services/templator/controller';
import { nav } from 'modules/ChatFlow/chat-flow.data';
import { readBlobAsDataUrl } from '@werkin/lib/fileReader';
import REST from 'services/rest.service';
import upperFirst from 'lodash/upperFirst';
import { Router } from 'services/router.service';
import createChatMutation from '../../graphql/mutations/createChat.graphql';
import editChatMutation from '../../graphql/mutations/updateChat.graphql';
import { Auth, NotificationManager } from 'services';
import unionWith from 'lodash/unionWith';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import IndividualsService from 'services/data/individuals.service';
import ProfileService from 'services/data/profile.service';

class ChatFlowController extends Controller {
  SELECTED_TAG_POSTFIX = '_selected_tag';
  cachedTags = [];

  get chat() {
    const defaultTitle = this.data.type === 'direct' ? 'Direct' : '';

    return ({
      id: this.data.id,
      title: this.data.title.value || defaultTitle,
      purpose: this.data.purpose.value,
      attributes: { access: this.data.isPublic ? 'public' : 'private' },
      tags: this.data.tags.map(tag => typeof tag === 'string' ? tag : tag.id),
      avatar: this.data.icon.value,
      type: this.data.type,
      visible: true,
      participantIds: this.data.members.map(member => member.id),
    });
  }

  get selectedTags() {
    return Object.keys(this.state).reduce((acc, key) => {
      if (key.includes(this.SELECTED_TAG_POSTFIX)) {
        return [ ...acc, key ];
      }

      return acc;
    }, []);
  }

  async onInit() {
    this.SELECTED_TAG_POSTFIX = '_selected_tag';
    if (!this.data.id) {
      this.setDataByKey('id', this.new_uuid);
    }

    this._initState();
    await this._getMembers(this.state.memberSearchDelay);
    this._initStateForSelected();
    this._subscribeOnSearch();
    this._subscribeOnData();
  }

  async next() {
    const { nav } = this.state;
    const { selectedScreen } = this.data;
    const { nextScreen } = nav[selectedScreen];

    if (!nextScreen)  {
      await this._createOrUpdateChat();

      return this._close(true);
    }

    this.setDataByKey('selectedScreen', nextScreen);

    if (!nav[nextScreen].nextScreen) {
      this.setStateByKey('allowNext', this.data.members.length >= 1);
    }
  }

  back() {
    const { nav } = this.state;
    const { selectedScreen } = this.data;
    const { prevScreen } = nav[selectedScreen];

    if (!prevScreen) {
      return this._close();
    }

    this.setDataByKey('selectedScreen', prevScreen);
    this.setStateByKey('allowNext', true);
  }

  addRemove(evt, value, entity) {
    evt.stopPropagation();

    const members = this.data[`${entity}s`];
    const getValue = item => typeof item === 'string' ? item : item.id;
    const selected = !!this.state[`${value.id}_selected_${entity}`];
    let data = [...members];

    if (selected) {
      data = members.reduce((acc, item) => {
        getValue(item) !== value.id && acc.push(item);

        return acc;
      } , []);
    } else {
      data.push(value);
    }

    this.setDataByKey(`${entity}s`, data);
    this.setStateByKey(`${value.id}_selected_${entity}`, !selected);
    this.setStateByKey('allowNext', entity === 'tag' || this.data.members.length >= 1);
  }

  handleSwitchChange(evt) {
    this.setDataByKey('isPublic', evt.target.checked);
  }

  async handleIconChange(evt) {
    const file = evt.target.files[0];

    if (file) {
      const image = await readBlobAsDataUrl(file);

      this.setDataByKey('icon.title', file.name);
      this.setDataByKey('icon.value', image);
      this.setDataByKey('icon.valid', !!image);
    }
  }

  onInput(evt) {
    const { name, value } = evt.target;

    this.setDataByKey(`${name}.valid`, true);
    this.setDataByKey(`${name}.error`, null);

    if (name === 'title') {
      this.setStateByKey('allowNext', !!value.trim());
      !value.trim() && this.setDataByKey('title.valid', false);
      !value.trim() && this.setDataByKey('title.error', 'Title is required');
    } else {
      !value.trim() && this.setDataByKey(`${name}.valid`, null);
    }

    this.setDataByKey(`${name}.value`, value);
  }

  onSearch(evt) {
    const { name, value } = evt.target;

    name === 'tagSearch' && clearTimeout(this.timeout);

    this.setStateByKey(`${name}Delay`, value);
  }

  scrollTo(action) {
    const parent = document.getElementsByClassName(this.view.styles['participants-list-wrapper'])[0];

    setImmediate(() => {
      action === 'right' ? this._right(parent) : this._left(parent);
    });
  }

  _initState() {
    const { tab, single } = this.router.state.left;

    this.setStateByKey('allowNext', !['details'].includes(this.data.selectedScreen) || !!this.data.title.valid);
    this.setStateByKey('nav', nav());
    const nextText = this.router.state.left.action === 'create' ? 'create' : 'save';
    this.setStateByKey('nav.members.nextLabel', upperFirst(nextText));

    single && this.setStateByKey(`nav.${tab}.prevScreen`, '');

    this.setStateByKey('tagSearchDelay', '');
    this.setStateByKey('tags', []);
    this.setStateByKey('memberSearchDelay', '');

    this.setDataByKey('type', this.router.state.left.text);
  }

  async _getMembers(search) {
    const individuals = await IndividualsService.getIndividuals();

    const filtered = individuals.filter((i) => i.name.toLowerCase().includes(search.toLowerCase()));
    this.setStateByKey('members', orderBy(filtered, ['name', 'asc']));
  }

  _initStateForSelected() {
    this.data.members.forEach(member => {
      this.state[`${member.id}_selected_member`] = true;
    });
    this.setStateByKey('cachedTags', this.data.tags);

    this.data.tags.forEach(tag => {
      this.setStateByKey(tag.id + this.SELECTED_TAG_POSTFIX, true);
    });

  }

  _subscribeOnSearch() {
    this.subscribeState(({ tagSearchDelay }) => {
      this.setStateByKey('loading', true);
      this.setStateByKey('allowNext', false);

      if (tagSearchDelay) {
        this.timeout = setTimeout(() => {
          this.setStateByKey('tagSearch', tagSearchDelay);
          this.setStateByKey('allowNext', true);

          REST.getSkills(tagSearchDelay.toLowerCase()).then(({ data }) => {
            this.setStateByKey('cachedTags', unionWith(this.state.cachedTags, data, isEqual));
            this.setStateByKey('tags', data);
            this.setStateByKey('loading', false);
          });
        }, 400);
      } else {
        this.setStateByKey('tagSearch', tagSearchDelay);
        this.setStateByKey('loading', false);
        this.setStateByKey('allowNext', true);
      }
    }, 'tagSearchDelay');

    this.subscribeState(async ({ memberSearchDelay }) => {
      this._getMembers(memberSearchDelay);
      this.setStateByKey('memberSearch', memberSearchDelay);
    }, 'memberSearchDelay');
  }

  _getOffset() {
    const { MEMBER_WIDTH, DESKTOP_VISIBLE_MEMBERS } = this.module.constants.chat_flow;
    const count = this.data.members.length - DESKTOP_VISIBLE_MEMBERS;
    const offset = count * MEMBER_WIDTH;

    this.setStateByKey('offset', offset);
  }

  _subscribeOnData() {
    this.subscribeData(() => {
      const userId = Auth.getUserId();
      const participants = this.data.members.filter(member => member.id !== userId);

      if (this.router.display === 'desktop') { this._getOffset(); }

      this.setStateByKey('scrollToEnd', !this.state.scrollToEnd);
      this.setStateByKey('allowNext', participants.length !== 0);
    }, 'members');
  }

  _left(parent) {
    if (this.state.offset === 0) { return; }

    const minusOne = this.state.offset - this.module.constants.chat_flow.MEMBER_WIDTH;
    const offset = minusOne <= 0 ? 0 : minusOne;

    parent.firstElementChild.style.setProperty('--offset', `-${offset}px`);

    this.setStateByKey('offset', offset);
  }

  _right(parent) {
    const offset = this.state.offset + this.module.constants.chat_flow.MEMBER_WIDTH;

    parent.firstElementChild.style.setProperty('--offset', `-${offset}px`);

    this.setStateByKey('offset', offset);
  }

  async _createOrUpdateChat() {
    const { action } = this.router.state.left;
    const profile = await ProfileService.getProfile();
    const currentUser = action === 'edit' ? [] : [profile.id];

    const command = {
      ...this.chat,
      participantIds: [...this.chat.participantIds, ...currentUser],
    };

    this.setStateByKey('allowNext', false);
    this.setStateByKey('loading', true);

    if (action !== 'create') {
      delete command.attributes;
      delete command.type;
    }

    await apollo.mutate({
      mutation: action === 'create' ? createChatMutation : editChatMutation,
      variables: { command },
    });

    this.setStateByKey('allowNext', true);
    this.setStateByKey('loading', false);

    const notification = `Group was ${ action === 'create' ? 'created' : 'updated' }`;

    NotificationManager.showNotification({ text: notification, type: 'success' });
  }

  _close(isFinalize) {
    Router.deleteState('left');

    if (isFinalize) {
      this.router.go({ name: 'chats.chat', params: { chatId: this.chat.id } });
    } else {
      Router.display === 'mobile' && Router.go({ name: 'chat' });
    }

  }
}

export default ChatFlowController;
