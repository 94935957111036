import get from 'lodash/get';
import { Router } from 'services/router.service';
import Sentry from 'services/sentry.service';
import { NotificationManager } from 'services';

// Display error to user
const displayError = (error) => {
  const text = get(error, 'reason.message') || get(error, 'message') || 'Unknown error';
  NotificationManager.showNotification({ text , type: 'error' });
};

export const errorHandler = (error, isUnhandled) => {
  Sentry.captureException(error);
  if (!isUnhandled) { return; }

  displayError(error);

  /** When event access is denied */
  if (error.extensions && error.extensions.code === 'NotAllowed') {
    const { chatId } = Router.params;
    const route = chatId ? { name: 'chats.chat', params: { chatId } } : { name: 'chat' };

    Router.go(route);
  }
};
