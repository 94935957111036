import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';

import { Observe } from 'decorators';
import moment from 'moment';
import { Router } from 'services/router.service';

import SharedGoalsController from './shared-goals.controller';
import styles from './shared-goals.module.scss';

class SharedGoalsView {
  public controller: SharedGoalsController;

  @Observe('disabledShare')
  get shareButton() {
    const { disabledShare, onSave } = this.controller;

    return html`
      <button
        class="w-button primary"
        ?disabled=${disabledShare}
        @click=${onSave.bind(this.controller)}
      >
        Share
      </button>`;
  }

  get template() {
    return html`
      <div class=${styles['share-goal-modal']}>
        <div class=${styles.title}>Who do you want to share your goals with?</div>
        <w-search
          @search=${(evt) => this.controller.onSearch(evt)}
          @clear=${(evt) => this.controller.onSearch(evt)}
          searchDelay="300"
          name="users_search"
          placeholder="Name here"
          label="Search name"
          value="${this.controller.search}"
        > </w-search>

        <div class=${styles.body}>
          ${this.userList}
          ${this.sharedToUsers}
        </div>

        <footer>
          <button class="w-button outline" @click=${this.controller.destroy}>Cancel</button>
          ${this.shareButton}
        </footer>
      </div>
    `;
  }

  @Observe('search')
  private userCard(user) {
    return user.name.toLowerCase().includes(this.controller.search.toLowerCase()) ? html`
      <section>
        <div class=${styles.card}>
          <div class=${styles['avatar-block']}>
            ${this.avatar(user)}
          </div>

          <div class=${styles['user-info']}>
            ${this.userLink(user)}
            <div>${user.position}</div>
            <div>Based in ${user.location}</div>
          </div>

          <w-checkbox
            value="${this.controller.selectedUsers.some(({id}) => id === user.id)}"
            @toggle="${(evt) => this.controller.onChange(user, evt.target.value)}"
          > </w-checkbox>
        </div>
      </section>
    ` : nothing;
  }

  @Observe('individuals')
  private get userList() {
    const { individuals } = this.controller;

    return html`
      <div class=${styles['user-list']}>
        ${repeat(individuals, (u, i) => i, (user) => this.userCard(user))}
      </div>
    `;
  }

  private goalTmp(goal) {
    return html`
      <div class=${styles['shared-item']}>
        ${this.userLink(goal.individual)}
        (${moment(goal.shared).format('D MMMM YYYY')})
      </div>
    `;
  }

  @Observe('goals')
  private get sharedToUsers() {
    return this.controller.goals.length ? html`
      <div class=${styles.shared_users_list}>
        <p class=${styles.description}>You have previously shared your goals with the following people:</p>

        <div class=${styles['shared-users']}>
          ${repeat(this.controller.goals, (g, i) => i, (goal) => this.goalTmp(goal))}
        </div>
      </div>
    ` : nothing;
  }

  private avatar(user) {
    const width = Router.display === 'desktop' ? '45px' : '35px';

    return html`<w-avatar avatar="${user.avatar}" title="${user.name}" width=${width} borderWidth="2"> </w-avatar>`;
  }

  private userLink({ id, name }) {
    return html`<div class=${styles.link} @click=${() => this.controller.onShowUserProfile(id)}>${name}</div>`;
  }
}

export default SharedGoalsView;
