import { classNames } from '@werkin/lib/helpers';
import { Observe } from 'decorators';
import { SessionLink } from 'interfaces/sessionGuides';
import { html, nothing } from 'lit-html';
import MarkdownIt from 'markdown-it';
import cardStyles from 'styles/card.module.scss';
import DashSessionController from './dash-session-guides.controller';
import styles from './dash-session-guides.module.scss';

const md = new MarkdownIt({
  html: true,
});

class DashSessionTemplate {
  public controller: DashSessionController;

  @Observe('selectedSessionGuide')
  get sessionGuideContent() {
    const { selectedSessionGuide } = this.controller;
    const { leadText }: { leadText: string } = selectedSessionGuide;

    return leadText
      ? html`
          <div>
            <p .innerHTML=${md.render(leadText)}></p>

            ${selectedSessionGuide.links &&
            selectedSessionGuide.links.map(
              (link: SessionLink) => html`<div class=${styles.linksWrapper}>
                <svg width="15px" height="17px" viewBox="0 0 21 23" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18.8991 21.4802V10.1828H19.659H20.4189V23H0.581224V10.1828H1.34114H2.10105V21.4802H18.8991ZM5.91327 0H15.086V7.92213H19.3147L17.4041 10.1828L10.5 18.3512L3.59595 10.1828L1.68512 7.92203H5.91327V0ZM7.4331 9.44185H4.95963L10.5 15.9969L16.0403 9.44206H13.5663V1.51982H7.4331V9.44185Z"
                    fill="black"
                  />
                </svg>

                <a href="${link.url}" download target="_blank">${link.title}</a>
              </div>`
            )}
          </div>
        `
      : nothing;
  }

  @Observe('sessionGuides')
  get switcher() {
    const { sessionGuides, handleSessionGuides } = this.controller;

    if (sessionGuides.length === 1) {
      const { title } = sessionGuides[0];

      return html`<p class=${styles.header}>${title}</p>`;
    }

    return sessionGuides
      ? html`
          <select class=${cardStyles['header-select-full']} @change=${handleSessionGuides}>
            ${sessionGuides.map(({ title, id }) => html` <option value=${id}>${title}</option> `)}
          </select>
        `
      : nothing;
  }

  get template() {
    return html`
      <div class=${cardStyles.card}>
        <div class=${cardStyles.header}>${this.switcher}</div>
        <div class=${classNames('cardContent', styles, cardStyles.content)}>${this.sessionGuideContent}</div>
      </div>
    `;
  }
}

export default DashSessionTemplate;
