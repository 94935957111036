import MessagesController from 'controllers/messages';
import Chat from 'modules/Chat/chat.module';

class DirectController extends MessagesController {
  controller() {
    super.controller();
    this.addTSModule(Chat);
  }
}

export default DirectController;
