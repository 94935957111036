import styles from './mentions.module.scss';

export default (Quill) => {
  const Embed = Quill.import('blots/embed');

  class MentionsBlot extends Embed {
    public static create(value) {
      const node = document.createElement('span');

      node.innerText = value.denotationChar + value.value;
      node.dataset.denotationChar = value.denotationChar;
      node.dataset.id = value.id;
      node.dataset.value = value.value;

      node.classList.add(styles.mention);

      return node;
    }

    public static value(node) {
      return {
        value: node.dataset.value,
        denotationChar: node.dataset.denotationChar,
        id: node.dataset.id,
      };
    }
  }

  MentionsBlot.blotName = 'mention';

  Quill.register({ 'formats/mention': MentionsBlot }, true);

  return MentionsBlot;
};
