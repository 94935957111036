import { observable } from 'decorators';
import { Window } from 'global';
import _groupBy from 'lodash/groupBy';
import { NotificationManager, REST } from 'services';
import { Router } from 'services/router.service';

interface CustomWindow extends Window {
  analytics?: any;
}

class RegisterInterestController {
  public groups: any;

  @observable() public valid: boolean;
  @observable() public loading: boolean;
  @observable() public header: string;
  @observable() public countries: any;
  @observable() public industries: any;
  @observable() public stages: any;
  @observable() public city: any;
  @observable() public sector: any;
  @observable() public roles: any;
  @observable() public industrySelected: any;
  @observable() public countrySelected: any;

  private controls: any;

  public OnInit() {
    if (!Router.state.email) {
      return Router.go({ name: 'get-link' });
    }

    REST.getRegisterInterestFormData().then(({ data: { values, onboarding } }) => {
      this.groups = _groupBy(values, 'category');
      this.header = onboarding.text.header.value;
      this.countries = this.groups.country;
      this.industries = this.groups.industry;
      this.stages = this.groups['career-stages'];
      this.roles = this.groups['mentoring-preferences'];
    });
  }

  public get sectorEmpty() {
    return this.industrySelected ? 'Sectors not found' : 'Select industry first';
  }

  public get cityEmpty() {
    return this.countrySelected ? 'Cities not found' : 'Select country first';
  }

  public onChange(e) {
    if (e.detail.current.name === 'industry') {
      this.resetChildSelect(e, 'industry', 'sector');
    }
    if (e.detail.current.name === 'country') {
      this.resetChildSelect(e, 'country', 'city');
    }

    this.controls = e.target.controls;
    this.valid = Array.from(this.controls).every(([, { valid }]) => valid);
  }

  public async onSubmit(e) {
    e.preventDefault();

    this.trackAnalytic('Registration data sent');
    this.loading = true;
    this.valid = false;

    await REST.registerInterest(this.controlsData);

    this.redirectToWerkin();
    this.loading = false;
  }

  public resetChildSelect(e, parent: string, child: string) {
    const childSelect = e.target.elements[child];
    childSelect && childSelect.dispatchEvent(new CustomEvent('reset', { bubbles: true }));
    const parentCtrl = e.target.controls.get(parent);
    if (parentCtrl && parentCtrl.value) {
      this[`${parent}Selected`] = true;
      this[child] = this.groups[child].filter(({ parentId }) => parentId === parentCtrl.value.id);
    }
  }

  public trackAnalytic(message) {
    window.onload = () => {
      if ((window as CustomWindow).analytics.track) {
        (window as CustomWindow).analytics.track(`Register: Unknown user: ${message}`, {
          email: Router.state.emailAddress,
        });
      } else {
        console.error('Analytics is not defined.');
      }
    };
  }

  public redirectToWerkin() {
    NotificationManager.showNotification({
      text: 'Thank you for registering your interest. We’ll be in touch via email.',
      type: 'success',
      duration: 2000,
    });

    setTimeout(() => {
      window.location.replace('https://getwerkin.com/');
    }, 2000);
  }

  public async onKeepInformed() {
    const { emailAddress = Router.state.email, firstName = '', lastName = '' } = this.controlsData;

    this.trackAnalytic('Keep me informed');
    this.loading = true;
    this.valid = false;

    await REST.keepMeInformed({ emailAddress, firstName, lastName });

    this.redirectToWerkin();
    this.loading = false;
  }

  private get controlsData(): any {
    return !this.controls
      ? {}
      : Array.from(this.controls).reduce((acc, [name, { value }]) => {
          acc[name] = value && value.id ? value.id : value;

          return acc;
        }, {});
  }
}

export default RegisterInterestController;
