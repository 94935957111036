import { html } from 'lit-html';
import { showIf } from 'directives';
import { Router } from 'services/router.service';

export default ({ left, hideCalendar = false }) => {
  const calendarTmp = html`<div id="calendar"></div>`;

  const actionViews = {
    'calendar': html`
      <w-actions-list-module> </w-actions-list-module>
    `,
    'calendar.event': html`
      <w-action-module> </w-action-module>
    `,
  };

  return html`
  <div id="left">
    <div id="left_modal">
      ${left}
    </div>
  </div>

  <div id="calendar-center">
    ${showIf(!hideCalendar, calendarTmp)}
    <div id="calendar-actions">
      ${actionViews[Router.route.name]}
    </div>
  </div>
`;
};
