import { html } from 'lit-html';
import styles from 'components/lit/modals/center-info-modal.module.scss';
import AutonomousModal from 'atoms/lit/modal/autonomous-modal.view';

class CenterInfoModal extends AutonomousModal {
  get tmp() {
    return html`
      <div class="${styles.wrapper}">
        ${this.backPanel}
        <div class="${styles.header}">
          <span>${this.title}</span>
          ${this.closeBtn}
        </div>
        ${this.main}
      </div>
    `;
  }

  get main() {
    return html``;
  }
}

export default CenterInfoModal;
