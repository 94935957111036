import { Werkin } from 'services';

const ChatProjection = {
  title: undefined,
  purpose: '',
  attributes: { access: 'public' },
  tags: [],
  avatar: '',
  type: '',
  participantIds: [],
};

export const nav = () => ({
  // type: {
  //   prevScreen: '',
  //   nextScreen: 'details',
  //   name: 'type',
  //   nextIcon: 'circleArrowRight',
  //   nextLabel: 'Next',
  //   backIcon: 'circleCross',
  //   backLabel: 'Close'
  // },
  details: {
    prevScreen: '',
    nextScreen: 'tags',
    name: 'details',
    nextIcon: 'circleArrowRight',
    nextLabel: 'Next',
    backIcon: 'circleCross',
    backLabel: 'Close',
  },
  tags: {
    nextScreen: 'members',
    prevScreen: 'details',
    name: 'tags',
    nextIcon: 'circleArrowRight',
    nextLabel: 'Next',
    backIcon: 'circleArrowLeft',
    backLabel: 'Back',
  },
  members: {
    prevScreen: 'tags',
    name: 'members',
    nextIcon: 'circleArrowRight',
    nextLabel: 'Create',
    backIcon: 'circleArrowLeft',
    backLabel: 'Back',
  },
});

export default (chat = { ...ChatProjection, selectedScreen: 'details' }) => ({
  id: chat.id,
  selectedScreen: chat.selectedScreen,
  isPublic: chat.attributes.access === 'public',
  icon: {
    title: 'Avatar',
    value: chat.avatar,
    valid: chat.avatar ? true : null,
    error: null,
    placeholder: 'Choose a group profile icon',
  },

  title: {
    value: chat.title || '',
    valid: chat.title !== undefined ? !!chat.title.length : null,
    error: chat.title !== undefined && !chat.title.length ? 'Title is required' : '',
    label: 'Group name*',
    placeholder: 'Enter group name here',
  },

  purpose: {
    value: chat.purpose,
    valid: chat.purpose.length ? true : null,
    error: '',
    label: 'Purpose',
    placeholder: 'Brief describe the purpose of this group',
    fillToEnd: true,
  },

  tags: chat.tags || [],
  members: chat.participants || chat.participantIds.map(id => Werkin.individuals.getById(id)),
});
