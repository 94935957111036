import { render } from 'lit-html';

export const manageMount = (template, id) => {
  const el = document.getElementById(id);

  if (!el) { return; }

  render(template, el);
};

export const manageUnmount = () => {
};
