import Controller from 'services/templator/controller';

class LoadController extends Controller {
  onInit() {
    this.pwaEvent = this.module.store.get('promptPWA');
  }

  async installApp() {
    if (this.pwaEvent) {
      this.pwaEvent.prompt();

      const { outcome } = await this.pwaEvent.userChoice;

      if (outcome === 'accepted') {
        this.module.store.unset('promptPWA');
      }
    }
  }
}

export default LoadController;
