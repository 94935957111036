import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import RegisterInterestController from './register-interest.controller';
import RegisterInterestView from './register-interest.view';

@RouterModule({
  name: 'w-register-interest-module',
  controller: RegisterInterestController,
  view: RegisterInterestView,
})
class RegisterInterestModule {
  public view: RegisterInterestView;

  public mount(module) {
    render(this.view.template, module);
  }

}

export default RegisterInterestModule;
