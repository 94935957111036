import store from '@werkin/store';
import findChatsQuery from '../../graphql/queries/findChats.graphql';
import ChatsListQuery from '../../graphql/queries/chat/ChatsList.graphql';
import apollo from 'api';
import { Router } from 'services/router.service';
import { manageMount } from 'lib/lit-components-manager';
import wrapper from './search.view';
import IndividualsService from 'services/data/individuals.service';

const searchProperties = {
  name: ['name', 'alias'],
  location: ['location'],
  interests: ['skills'],
  group: ['title'],
  tags: ['tag'],
};


class SearchController {
  constructor() {
    this.searchPropertyValue = searchProperties.name;
    this.listenSearch();
    this.timeoutId = null;
  }

  listenSearch() {
    store.subscribe('generalSearch', value => this.setQuery(value));
  }

  setQuery(query) {
    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      this.query = query;
      manageMount(wrapper(), 'search');
    }, 300);
  }

  async filterResults() {
    let list;
    let mutateFunc;

    if (
      this.searchPropertyValue.includes('name') ||
      this.searchPropertyValue.includes('alias') ||
      this.searchPropertyValue.includes('location') ||
      this.searchPropertyValue.includes('skills')
    ) {
      list = await IndividualsService.getIndividuals();
      mutateFunc = function(item) {
        return Object.assign(item, {
          skills: item.skillsAndInterests
            .map(skill => skill.label)
            .join(', '),
        });
      };
    } else if (['title', 'tag'].includes(this.searchPropertyValue[0])) {
      const { data: { findChats } } = await apollo.query({
        query: findChatsQuery,
        variables: { [`${this.searchPropertyValue[0]}String`]: this.query },
        fetchPolicy: 'no-cache',
      });

      list = findChats;
      mutateFunc = function(item) {
        return Object.assign(item, {
          tag: item.tags
            .map(tag => tag.label)
            .join(', '),
        });
      };
    } else {
      const { data: { chats } } = await apollo.query({ query: ChatsListQuery });

      list = chats;
      mutateFunc = function(item) {
        return Object.assign(item, {
          tag: item.tags
            .map(tag => tag.label)
            .join(', '),
        });
      };
    }
    const filtered = list.reduce((acc, item) => {
      item = mutateFunc(item);

      this.searchPropertyValue.forEach(property => {
        if (item[property].toLowerCase().includes(this.query.toLowerCase())) {
          const duplicateItemIndex = acc.findIndex((a) => a[property] === item[property]);

          if (duplicateItemIndex >= 0) {
            acc.splice(duplicateItemIndex, 1);
          }

          acc.push(item);
        }
      });

      return acc;
    }, []);

    return filtered;
  }

  get results() {
    return this.query ? this.filterResults() : Promise.resolve(false);
  }

  set searchProperty(value) {
    this.searchPropertyValue = searchProperties[value] || searchProperties.name;
    manageMount(wrapper(), 'search');
  }

  get searchProperty() {
    return this.searchPropertyValue;
  }

  navToProfile(id) {
    Router.go({ name: 'individual', params: { userId: id } });
    store.set('generalSearch', '');
  }

  navToGroup(id) {
    Router.go({ name: 'chats.chat', params: { chatId: id } });
    store.set('generalSearch', '');
  }
}

export default new SearchController();
