import { render } from 'lit-html';
import TabbarController from 'modules/Tabbar/tabbar.controller';
import TabbarView from 'modules/Tabbar/tabbar.view';
import { RouterModule } from 'services/router/router.m';

@RouterModule({
  name: 'w-tabbar-module',
  view: TabbarView,
  controller: TabbarController,
})
class TabbarModule {
  public view: TabbarView;
  public subs: Set<() => void> = new Set([]);

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default TabbarModule;
