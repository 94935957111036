import { html } from 'lit-html';

export default ({ left, center, right }) => html`
  <div id="left">
    <div id="left_modal">
      ${left}
    </div>
  </div>

  <div id="main-center">
    <div id="center">
       ${center}
    </div>
    <div id="right">
      ${right}
    </div>
  </div>
`;
