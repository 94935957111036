import apollo from 'api';
import differenceWith from 'lodash/differenceWith';
import { isHaveStr } from '@werkin/lib/helpers';
import auth from 'services/auth.service';
import { differenceInCalendarDays, format } from 'date-fns';
import { Router } from 'services/router.service';
import { NotificationManager } from 'services';
import joinChatMutation from '../graphql/mutations/joinChat.graphql';
import leaveChatMutation from '../graphql/mutations/leaveChat.graphql';
import ChatQuery from '../graphql/queries/chat/Chat.graphql';
import _cloneDeep from 'lodash/cloneDeep';
import _isPlainObject from 'lodash/isPlainObject';
import _camelCase from 'lodash/camelCase';
import _isArray from 'lodash/isArray';
import _forEach from 'lodash/forEach';

/** When create chat add current user to the members */
export const getParticipantsIds = (participants) => {
  const ids = participants.map(participant => participant.id);
  const current = auth.getUserId();

  ids.push(current);

  return ids;
};

export const getChatTitle = (chat) => {
  if (chat.type !== 'direct') {
    return chat.title;
  }
  const current = auth.getUserId();
  const guests = chat.participants.filter(({ id }) => id !== current);

  return guests.map(guest => guest.name).join(', ');
};

export const getChatAvatar = (chat) => {
  if (chat.type !== 'direct' || chat.participants.length > 2) {
    return chat.avatar;
  }
  const current = auth.getUserId();
  const [interlocutor] = chat.participants.filter(({ id }) => id !== current);

  return interlocutor.avatar;

};

export const checkIfChatCreated = (ids) => {
  const chats = Router.controller.chats.getAll().exec().map(
    chat => ({
      ...chat,
      participants: chat.participants.map(
        participant => participant.id
      ),
    })
  );

  return chats.filter(chat => chat.type === 'direct' && !differenceWith(chat.participants, ids).length)[0];
};

export const isJoined = (participants = []) => {
  const id = auth.getUserId();
  const user = participants.find( member => member.id === id );

  return user || null;
};

export const groupAndFilter = (arr, query, groupFields) =>
  groupFields.reduce((acc, [property, groupName]) => {
    const filtered = arr.filter(i => isHaveStr(i[property], query) && !(acc.get(groupName) || []).flat().includes(i));
    const prev = acc.get(groupName) || [];

    if (prev.length || filtered.length) {
      acc.set(groupName, [...prev, ...filtered]);
    }

    return acc;
  }, new Map());

export const groupObjectsInMap = (items, key) => {
  const map = new Map();

  items.forEach((item) => {
    map.set(
      item[key][0].toUpperCase(),
      [...(map.get(item[key][0].toUpperCase()) || []), item]
    );
  });

  return [...map.entries()];
};

export const sortByAlphabet = (items) => {
  const map = new Map(items.sort());

  return [...map.entries()];
};

export const getMessageDate = (item) => {
  const diffFromNow = differenceInCalendarDays(new Date(), new Date(item.sent));
  const date = new Date(item.sent);

  switch (true) {
  case (diffFromNow === 0):
    return 'Today';
  case (diffFromNow === 1):
    return 'Yesterday';
  default:
    return format(date, 'EEEE, LLLL do');
  }
};

export const leaveJoinChat = {
  leave: async () => {
    try {
      await apollo.mutate({
        mutation: leaveChatMutation,
        variables: {
          command: {
            id: Router.params.chatId,
          },
        },
        refetchQueries: [{
          query: ChatQuery,
          variables: {
            id: Router.params.chatId,
          },
        }],
      });

      Router.go({ name: 'chat'});
      NotificationManager.showNotification({ text: 'You have left the channel or group.', type: 'success' });
    } catch (err) {
      console.error('leaveJoinChat', err);
      NotificationManager.showNotification({ text: err.message, type: 'error' });
    }
  },

  join: async () =>  {
    try {
      console.log('join');
      const { chatId } = Router.params;
      await apollo.mutate({
        mutation: joinChatMutation,
        variables: { command: { id: chatId } },
        refetchQueries: [
          {
            query: ChatQuery,
            variables: {
              id: Router.params.chatId,
            },
          },
        ],
      });

      NotificationManager.showNotification({ text: 'You have joined the channel or group.', type: 'success' });
    } catch (e) {
      NotificationManager.showNotification({ text: e.message, type: 'error' });
    }
  },
};

export function convertKeys(obj) {
  const rawObj = _cloneDeep(obj);
  const newObj = _isArray(rawObj) ? [] : {};
  _forEach(rawObj, (value, key) => {
    newObj[_camelCase(key)] = _isPlainObject(value) || _isArray(value) ? convertKeys(value) : value;
  });

  return newObj;
}
