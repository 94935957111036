import apollo from 'api';
import { observable } from 'decorators';
import { IContactDetails } from 'interfaces/profile/contact-detail.interface';
import ProfileContactDetailsModule from 'modules/ProfileContactDetails/profile-contact-details.module';
import { Auth } from 'services';
import { Router } from 'services/router.service';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';

class ProfileContactDetailsController {
  public module: ProfileContactDetailsModule;

  @observable({
    alias: '',
    emailAddresses: [],
    telephoneNumbers: [],
  }) public contactDetails: IContactDetails;

  public async OnInit() {
    const observe = apollo.watchQuery({
      query: ProfileQuery,
      variables: {
        id: Auth.getUserId(),
      },
    }).subscribe({
      next: ({ data: { profile } }) => {
        this.contactDetails = profile.contactDetails;
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public onEdit(targetEntity) {
    Router.go({
      hash: {
        ProfileEditingModal: true,
        root: 'contactDetails',
        targetEntity,
      },
    });
  }
}

export default ProfileContactDetailsController;
