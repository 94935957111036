import { render } from 'lit-html';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';
import NavigationController from './navigation.controller';
import NavigationView from './navigation.view';

@RouterModule({
  name: 'w-navigation-module',
  view: NavigationView,
  controller: NavigationController,
  observers: ['tabs', 'nav:title'],
})
class NavigationModule {
  public subs: Set<() => void> = new Set([]);
  public view: NavigationView;
  public controller: NavigationController;

  public async loading() {
    return dataService.awaitLoading;
  }

  public remount(module) {
    this.controller.tabs = JSON.parse(module.tabs || '[]');
    this.controller.title = module['nav:title'];
    this.controller.initSelected();
  }

  public mount(module) {
    this.controller.tabs = JSON.parse(module.tabs || '[]');
    this.controller.title = module['nav:title'];

    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default NavigationModule;
