import classnames from 'classnames';
import { Icons, loader } from 'components/lit';
import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import styles from '../join.module.scss';
import JoinTemplate from '../join.view';

class KPISFragment {
  public view: JoinTemplate;

  get fields() {
    return ['kpis'];
  }

  get header() {
    const { kpis } = this.view.controller;
    const { title, description } = kpis;

    return kpis ? html`
      <div class=${styles['kpi-header']}>
        <h2>${title}</h2>
        <p>${description}</p>
      </div>
    ` : nothing;
  }

  public single(name, options) {
    const { handleKPISelect, selectedKPIs } = this.view.controller;

    return html`
      <div class=${styles.singles}>
        ${repeat(Object.entries(options), ([key]) => `${name}-${key}`, ([key, value]) => {
          const isActive = selectedKPIs[name] === key;

          return html`
            <div
              class=${classnames(styles.single, isActive && styles.active)}
              @click=${() => handleKPISelect(name, key)}
            >
              <span>${value}</span>
              ${isActive ? Icons.circleCheckIcon() : nothing}
            </div>
          `;
        })}
      </div>
    `;
  }

  @Observe('selectedKPIs', 'activeKPI')
  public questions(questions) {
    const { activeKPI } = this.view.controller;
    const activeName = questions[activeKPI].name;

    return html`
      <div class=${styles['questions-wrapper']}>
        ${repeat(questions, ({ name }) => name, ({ label, name, options, type }, index) => html`
          <div class=${classnames(styles.question, activeName === name && styles.activeq)}>
            <h4>${index + 1}: ${label}</h4>
            ${this[type](name, options)}
          </div>
        `)}
      </div>
    `;
  }

  @Observe('kpis', 'activeKPI', 'selectedKPIs')
  private get buttons() {
    const { kpis, activeKPI, selectedKPIs, onPreviousKPI, onBack } = this.view.controller;
    const hasValue = selectedKPIs[kpis.questions[activeKPI].name];
    const isLastKPI = kpis.questions.length - 1 === activeKPI;
    const handleBack = (e) => activeKPI ? onPreviousKPI() : onBack(e);

    return html`
      <div class="${styles.buttons}">
        <button
          type="button"
          @click=${(e) => handleBack(e)}
          class="w-button outline ${styles.back}"
        >
          Back
        </button>
        ${hasValue && isLastKPI ? html`
          <button
            class="w-button primary ${styles.next}"
            @click=${(e) => this.view.controller.onNext(e, 'Skills and interests completed')}
            >
            Next
          </button>
          ` : nothing
        }
      </div>
    `;
  }

  @Observe('kpis', 'activeKPI')
  get template() {
    const { kpis } = this.view.controller;

    return kpis ? html`
      <div class=${styles.kpis}>
        ${this.header}
        ${this.questions(kpis.questions)}
        ${this.buttons}
      </div>
    ` : html`
      <div class="${styles.loaderWrapper}">
        ${loader}
      </div>
    `;
  }
}

export default KPISFragment;
