import Fragment from 'services/templator/fragment';
import { html, nothing } from 'lit-html';
import { loader } from 'components/lit';
import { repeat } from 'lit-html/directives/repeat';
import { showIf } from 'directives';

class TagsFragment extends Fragment {
  tagLayout(tag, text) {
    return html`
      <div class="${this.styles.tag}">
        ${tag.label}
        <button @click=${evt => this.controller.addRemove(evt, tag, 'tag')}>
          ${text}
        </button>
      </div>
    `;
  }

  tag(tag) {
    if (this.controller.state[tag.id + this.controller.SELECTED_TAG_POSTFIX]) {
      return nothing;
    }

    return this.tagLayout(tag, 'Add');
  }

  selectedTag(tag) {
    if (!this.controller.state[tag.id + this.controller.SELECTED_TAG_POSTFIX]) {
      return nothing;
    }

    return this.tagLayout(tag, 'Remove');
  }

  get results() {
    const { tags, tagSearch, cachedTags } = this.controller.state;

    return html`
      <div class="${this.styles.results}">
        ${repeat(cachedTags, tag => tag.id, tag => this.directives.renderEntity(tag, 'tag', this.selectedTag))}
        ${showIf(tagSearch, repeat(tags, tag => tag.id, tag => this.directives.renderEntity(tag, 'tag')))}
      </div>
    `;
  }

  get loading() {
    return this.controller.state.loading
      ? html`
        <div class="${this.styles.loading}">
          ${loader}
        </div>
      `
      : nothing;
  }

  get template() {
    return html`
      <h1>
        Tag your group (Optional)
      </h1>

      <p>
        Tags make it easier for members to discover your public groups.
      </p>

      ${this.directives.renderSearch('tagSearch', 'Search')}
      ${this.directives.renderResults('tagSearch', 'tags')}
    `;
  }
}

export default TagsFragment;
