import styles from 'components/custom/w-timer.module.scss';
import { html } from 'lit-element';
import { repeat } from 'lit-html/directives/repeat';
import { styleMap } from 'lit-html/directives/style-map';
import { customElement } from './decorators';
import { Element } from './Element';

interface TimeObject {
  days: number | undefined;
  hours: number | undefined;
  minutes: number | undefined;
  seconds: number | undefined;
}

@customElement({name: 'w-timer'})
class WTimer extends Element {
  private timeObject: TimeObject = { days: 0, hours: 0, minutes: 0, seconds: 0};
  private interval: any;
  private date: Date;
  private valueColor: string;
  private unitColor: string;

  public block(unit: string) {
    const value = this.timeObject[unit].toString().length > 1 ? this.timeObject[unit] : `0${this.timeObject[unit]}`;
    const valueStyle = styleMap({
      color: this.valueColor,
    });

    const unitStyle = styleMap({
      color: this.unitColor,
    });

    return html`
      <div class="${styles['timer-block']}">
        <div class="${styles['value-area']}" style="${valueStyle}">${value}</div>
        <div class="${styles['unit-area']}"  style="${unitStyle}">${unit}</div>
      </div>
    `;
  }

  public connectedCallback() {
    super.connectedCallback();
    this.classList.add(styles['timer-wrapper']);
    this.startCountDown();
  }

  public startCountDown() {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      const distance = this.date.getTime() - new Date().getTime();
      if (distance < 1000) {
        const e = new CustomEvent('expire', { bubbles: true});
        this.dispatchEvent(e);
        clearInterval(this.interval);
      } else {
        this.timeObject = {
          days: Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((distance % (1000 * 60)) / 1000),
        };
        this.update();
      }
    }, 1000);

  }

  static get observedAttributes() {
    return ['date', 'unitColor', 'valueColor'];
  }

  get template() {
    return html`${repeat(Object.keys(this.timeObject), (u) => this.block(u))}`;
  }
}

export default WTimer;
