import apollo from 'api';
import { RouterModule } from 'services/router/router.module';
import ActionFlowTemplate from './action-flow.view';
import ActionFlowDirective from './action-flow.directives';
import ActionFlowController from './action-flow.controller';
import ActionQuery from '../../graphql/queries/action.graphql';
import styles from './action-flow.module.scss';
import initialData from './action-flow.data';

class ActionFlowModule extends RouterModule {
  async mount() {
    let initialAction;

    if (this.router.params.actionId) {
      const { data: { action } } = await apollo.query({
        query: ActionQuery,
        variables: {
          id: this.router.params.actionId,
        },
      });

      initialAction = {
        ...action,
        data: JSON.parse(action.data),
      };
    }

    const storedData = window.localStorage.getItem('meet_up');
    const config = { source: this.store.get('source'), messageId: this.store.get('messageId') };
    const data = storedData ? JSON.parse(storedData) : initialData(config, initialAction);
    const actions_flow = new ActionFlowTemplate(this.module, data);

    this.store.unset('source');
    this.store.unset('messageId');

    actions_flow.controller = ActionFlowController;
    actions_flow.directives = ActionFlowDirective;
    actions_flow.styles = styles;

    setTimeout(() => {
      this.manageLitMount(actions_flow.template, 'schedule');
    });
  }

  unmount() {
    this.manageReactUnmount('actions_flow-body');
  }
}

export default ActionFlowModule;
