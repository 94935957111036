import { Collapsable } from 'components/lit/collapsable.view';
import { Observe } from 'decorators';
import { IFrequentlyAskedQuestionCategory } from 'interfaces/credits/faq.interface';
import { html, nothing, TemplateResult } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import CreditsFAQController from './credits-faq.controller';
import styles from './credits-faq.module.scss';

class CreditsFAQView {
  public controller: CreditsFAQController;

  @Observe('questions')
  get questionsCategories() {
    const buildView = ({ category, questions }) => {

      if (!questions.length) {
        return html`
          <div class=${styles['empty-node']}>
            ${category}
          </div>
        `;
      }

      return new Collapsable({
        title: category,
        template: this.displayQuestions(questions),
      }).template;
    };

    if (!this.controller.questions) {
      return nothing;
    }

    return html`
      <div class=${styles.categories}>
        ${repeat(this.controller.questions, buildView)}
      </div>
    `;
  }

  public displayQuestions(questions: IFrequentlyAskedQuestionCategory[]) {
    const buildView = (category: IFrequentlyAskedQuestionCategory): TemplateResult => {

      if (!category.answer) {
        return html`
          <div class=${styles['empty-node']}>
            ${category.question}
          </div>
        `;
      }

      return new Collapsable({
        title: category.question,
        template: this.displayQuestion(category),
      }).template;
    };

    return html`
      <div class=${styles.questions}>
        ${repeat(questions, (category) => buildView(category))}
      </div>
    `;
  }

  public displayQuestion(question) {
    return html`
      <div class=${styles['question-answer']}>
        ${question.answer}
      </div>
    `;
  }

  get template() {
    return html`
      <div class=${styles['credits-faq']}>
        <h1>Frequently Asked Questions</h1>

        ${this.questionsCategories}
      </div>
    `;
  }
}

export default CreditsFAQView;
