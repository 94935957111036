import { Icons } from 'components/lit';
import { Observe } from 'decorators';
import { showIf } from 'directives';
import { html } from 'lit-html';
import HeaderCommunityController from 'modules/HeaderCommunity/header-community.controller';
import { Router } from 'services/router.service';
import styles from '../Header/header.module.scss';

class HeaderCommunityView {
  public controller: HeaderCommunityController;

  @Observe('unreadMessagesCount')
  get template() {
    const { unreadMessagesCount, handleRoute } = this.controller;
    const active = Router.route.name.includes('chats');

    return html`
      <div
        class=${[styles.nav, active ? styles.active : ''].join(' ')}
        @click=${handleRoute}
      >
        ${Icons.messageSquare(active, '#fff')}
        <span>Community</span>
        ${showIf(unreadMessagesCount, html`
          <span class=${styles.badge}>
            ${unreadMessagesCount < 100 ? unreadMessagesCount : '99+'}
          </span>
        `)}
      </div>
    `;
  }
}

export default HeaderCommunityView;
