import { Auth } from 'services';

declare const window: any;

interface IEventAttributes {
  category: string;
  action: string;
  label?: string;
  value?: number;
}

function GATrackingId() {
  const { REACT_APP_ENVIRONMENT } = process.env;

  return {
    local: 'UA-69799267-4',
    develop: 'UA-69799267-4',
    release: 'UA-69799267-5',
    master: 'UA-69799267-6',
  }[REACT_APP_ENVIRONMENT];
}

export function initGA() {
  const { REACT_APP_ENVIRONMENT } = process.env;
  const script = document.createElement('script');

  script.text = `
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

    ga('create', '${GATrackingId()}', 'auto');
    ga('send', 'pageview');
  `;

  document.head.appendChild(script);

  if (REACT_APP_ENVIRONMENT === 'master') {
    initHotjar();
  }
}

export function gaTrackEvent({ category, action, label, value }: IEventAttributes) {
  window.ga('send', {
    hitType: 'event',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value,
  });
}

export function gaTrackLogin() {
  window.ga('set', 'userId', Auth.getUserId());
  window.ga('send', 'event', 'authentication', 'logged-in');
}

export function gaTrackLogout() {
  window.ga('set', 'userId', Auth.getUserId());
  window.ga('send', 'event', 'authentication', 'logged-out');
}

export function gaTrackPage(pathname: string) {
  window.ga('set', 'page', pathname);
  window.ga('send', 'pageview');
}

export function gaTrackChatId(chatId: string) {
  window.ga('set', 'dimension1', chatId);
}

function initHotjar() {
  (function(h, o, t, j, a, r) {
    h.hj = h.hj || function() {(h.hj.q = h.hj.q || []).push(arguments); };
    h._hjSettings = {hjid: 1421377, hjsv: 6};
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script'); r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}
