// import wrapper from 'modules/Search/search.view';
import { RouterModule } from 'services/router/router.module';
import './search.controller';

class SearchModule extends RouterModule {
  mount() {
    // this.manageLitMount(wrapper(), 'search');
  }
}

export default SearchModule;
