import emojilib from 'emojilib';
import {directive, html} from 'lit-html';
import {repeat} from 'lit-html/directives/repeat';
import {unsafeHTML} from 'lit-html/directives/unsafe-html';
import uEmojiParser from 'universal-emoji-parser';
import styles from './emoji.module.scss';
import * as Icons from './icons';

const listeners = new Set([]);

let category = 'people';

const subscribe = (fn) => {
  fn(category);

  listeners.add(fn);
};

const updateCategory = (newCategory) => {
  category = newCategory;

  listeners.forEach((fn) => fn(category));
};

const renderHeader = directive((categories) => (part) => {
  subscribe((category) => {
    part.setValue(emojiHeader(categories, category));
    part.commit();
  });
});

const renderBody = directive((list, handleSelect) => (part) => {
  subscribe((category) => {
    part.setValue(emojiBody(list, handleSelect, category));
    part.commit();
  });
});

const categoryButton = (category, selected) =>
  html`
    <button
      class="${ category === selected ? styles['active-category'] : ''}"
      @click=${() => updateCategory(category)}
    >
      ${ Icons[category] }
    </button>`;

const emojiHeader = (categories, selected) =>
  html`
    <div class="${styles['emoji-header']}">
      ${repeat(
        categories,
        (category) => category,
        (category) => categoryButton(category, selected)
      )}
    </div>
  `;

const emojiItem = (item, handleSelect) => {
  const emoji = uEmojiParser.parse(`:${item.name}:`);

  return html`
    <span
      @click=${() => handleSelect({ name: item.name, shortname: `:${item.name}:` })}
    >
      ${ unsafeHTML(emoji) }
    </span>`;
};

const emojiBody = (list, handleSelect, selected) =>
  html`
    <div class="${styles['emoji-body']}">
      ${repeat(list[selected], (item, i) => i, (item) => emojiItem(item, handleSelect)) }
    </div>
  `;

export const emojiSelect = (handleSelect) => {
  const emojiList = Object.keys(emojilib.lib).reduce((acc, name) => {
    const emojiData = emojilib.lib[name];

    return Object.assign(
      acc,
      {
        [emojiData.category]:  [
          ...(acc[emojiData.category] || []),
          { ...emojiData, name },
        ],
      }
    );
  }, {});

  const categories = [
    'people', 'symbols', 'animals_and_nature',
    'food_and_drink', 'activity',
    'travel_and_places', 'objects', 'flags',
  ];

  return html`
    <div class="${styles.wrapper}">
      ${renderHeader(categories)}
      ${renderBody(emojiList, handleSelect)}
    </div>
  `;
};
