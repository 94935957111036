import { observable } from 'decorators';
import { IFrequentlyAskedQuestionCategory } from 'interfaces/credits/faq.interface';
import faqsService from 'services/data/faq.service';

class CreditsFAQController {
  @observable() public questions: IFrequentlyAskedQuestionCategory[];

  public async OnInit() {
    this.questions = await faqsService.getQuestions();
  }
}

export default CreditsFAQController;
