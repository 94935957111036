import CalendarModule from 'modules/Calendar';
import DefaultLeftController from 'controllers/default.left';

class CalendarController extends DefaultLeftController {
  controller() {
    super.controller();

    this.addModule(CalendarModule);
  }
}

export default CalendarController;
