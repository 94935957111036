import { Icons } from 'components/lit';
import { html } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { repeat } from 'lit-html/directives/repeat';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import { Auth } from 'services/index';
import { Router } from 'services/router.service';
import uEmojiParser from 'universal-emoji-parser';
import Editor from '../editor.service';
import styles from './templates.module.scss';

const listItem = (item) => html`<li>${item.ops[0].insert.value}</li>`;

export const list = (group, level = 0) => {
  const listType = group.items[0].item.op.attributes.list;

  return html`
    <ul class="${styles[`${listType}-${level}`]}">
      ${repeat(
        group.items,
        (i, idx) => idx,
        ({ item, innerList }) => {
          if (innerList) {
            return html`
              <li>
                <span>${item.ops[0].insert.value}</span>
                <div>${list(innerList, level + 1)}</div>
              </li>
            `;
          }

          return listItem(item);
        }
      )}
    </ul>
  `;
};

export const block = (group) => {
  const {
    attributes: { header },
    insert: { type },
  } = group.op;

  if (header && type === 'text') {
    const { value } = group.ops[0].insert;

    return header === 1 ? html`<h1>${value}</h1>` : html`<h2>${value}</h2>`;
  }

  console.log('Received group config, but block template is not ready %s', JSON.stringify(group));

  return html`block`;
};

export const blot = (group) => {
  console.log('Received group config, but blot template is not ready %s', JSON.stringify(group));

  return html`blot`;
};

export const video = (group) => {
  console.log('Received group config, but video template is not ready %s', JSON.stringify(group));

  return html`video`;
};

export const mention = (option) => {
  const { denotationChar, value, id } = option.insert.value;

  const openProfile = (evt) => {
    evt.stopPropagation();

    Editor.quill.blur();
    Router.go({ hash: { IndividualModal: true, userId: id !== Auth.getUserId() && id } });
  };

  return html` <span @click=${openProfile} @touchstart=${openProfile} class="${styles.mention}"
    >${denotationChar + value}</span
  >`;
};

export const emoji = (option, isAlone) => {
  const emoji = uEmojiParser.parse(`:${option.insert.value}:`);

  const classes = {
    [styles.emoji]: true,
    [styles['alone-emoji']]: isAlone,
  };

  return html` <span class="${classMap(classes)}">${unsafeHTML(emoji)}</span>`;
};

export const image = (option) =>
  html` <div class="${styles.wrapper}">
    <img style="max-width: 300px;" src="${option.insert.value}" alt="Some image" />

    <a href=${option.insert.value} download class="${styles.anchor}"> ${Icons.download} </a>
  </div>`;

export const text = (option) => {
  const { link, bold, italic, strike, underline } = option.attributes;

  const el = document.createElement(link ? 'a' : 'span');

  el.innerHTML = option.insert.value;

  if (link) {
    let cleanLink = link.split('unsafe:').join('');

    if (link.includes('unsafe')) {
      cleanLink = `http://${cleanLink}`;
    }

    el.setAttribute('href', cleanLink);
    el.setAttribute('target', '_blank');
  }

  if (bold) {
    el.style.fontWeight = 'bold';
  }

  if (italic) {
    el.style.fontStyle = 'italic';
  }

  if (strike || underline) {
    const decorators = [];

    strike && decorators.push('line-through');
    underline && decorators.push('underline');

    el.style.textDecoration = decorators.join(' ');
  }

  return html`${unsafeHTML(el.outerHTML)}`;
};

export const br = () => html`<br />`;
