import styles from 'components/custom/w-select.module.scss';
import {html, nothing, svg, TemplateResult} from 'lit-html';
import {classMap} from 'lit-html/directives/class-map';
import { repeat } from 'lit-html/directives/repeat';
import { customElement } from './decorators';
import Element from './Element';
import Validator from './validators';

interface Option {
  category: string;
  id: string;
  label: string;
}

enum MenuDirection {
  top = 'top',
  bottom = 'bottom',
}

@customElement({name: 'w-select'})
class WSelect extends Element {
  private _el: any = document.createElement('select');
  public validators: Array<(value: any) => string> = [];
  private options: Option[] = [];
  private selected: Option;
  private isOpened = false;
  private direction: MenuDirection;
  private searchValue: string;
  private placeholder: string;
  private empty: string;

  private get arrow() {
    return svg`
     <svg viewBox="0 0 61 61">
      <g fill="#014660" fill-rule="evenodd" stroke="#014660" stroke-width="2">
        <rect width="6" height="35" x="38" y="13" rx="3" transform="rotate(45 41 30.5)"/>
        <rect width="6" height="35" x="17" y="13" rx="3" transform="rotate(-45 20 30.5)"/>
      </g>
     </svg>
    `;
  }

  private get searchIcon() {
    return svg`
      <svg viewBox="0 0 32 32">
        <path fill="#014660" d="M31.608 29.727l-7.693-7.693c1.907-2.325 3.056-5.303 3.056-8.549 0-7.445-6.041-13.486-13.486-13.486-7.451 0-13.486 6.041-13.486 13.486s6.034 13.486 13.486 13.486c3.246 0 6.217-1.143 8.542-3.050l7.693 7.687c0.522 0.522 1.365 0.522 1.887 0 0.522-0.516 0.522-1.365 0-1.881zM13.486 24.287c-5.962 0-10.808-4.846-10.808-10.802s4.846-10.808 10.808-10.808c5.956 0 10.808 4.852 10.808 10.808s-4.852 10.802-10.808 10.802z"/>
      </svg>
    `;
  }

  private get emptyState() {
    return html`<div class="${styles.empty}">${this.empty || 'Empty'}</div>`;
  }

  private option = (option: Option) => {
    const classes = classMap({
      [styles.option]: true,
      [styles.selected]: this.selected && option.id === this.selected.id,
    });

    return html`<div class="${classes}" @click="${this.selectOption.bind(this, option)}">${option.label}</div>`;
  }


  private get renderOptions() {
    return html`
      <div class="${styles.options}">
        ${repeat(this.filteredOptions, (k) => k.id, this.option)}
      </div>
    `;
  }

  private get search() {
    return html`
     <div class="${styles.search}">
        ${this.searchIcon}
       <input type="text" id="select-search" @input=${this.onSearch}/>
     </div>
    `;
  }

  private get selectMenu() {
    const classes = classMap({
      [styles.selectmenu]: true,
      [styles[this.direction]]: true,
    });

    return html`
      <div class="${classes}">
        ${this.options && this.options.length > 5 ? this.search : nothing}
        ${this.filteredOptions.length ? this.renderOptions : this.emptyState}
      </div>
    `;
  }

  private get btn() {
    return html`
      <div class="${styles.btn}" @click="${this.btnClickHandler}">
        ${this.selected.label}
        ${this.arrow}
      </div>
    `;
  }

  private get placeholderTmp() {
    return html`
      <div class="${styles.placeholder}" @click="${this.btnClickHandler}">
      ${this.placeholder}
      ${this.arrow}
      </div>
    `;
  }

  get template(): TemplateResult {
    return html`
        ${this.selected ? this.btn : this.placeholderTmp}
        ${this.isOpened ? this.selectMenu : nothing}
    `;
  }

  private keyDownHandler = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      this.toggleMenu(!this.isOpened);
    }
    if (e.code === 'Tab') {
      this.toggleMenu(false);
    }
    if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
      e.preventDefault();
      const fOptions = this.filteredOptions;
      let ind = fOptions.findIndex(({id}) => this.selected  && id === this.selected.id);
      const diff = e.code === 'ArrowDown' ? 1 : -1;
      ind = ind === -1 && diff === -1 ? fOptions.length : ind;
      const opt = fOptions[ind + diff];
      if (opt) {
        this.selected = opt;
        this._el._value = this.selected;
        this.onInput();
        this.update();
        this.isOpened && this.scrollToSelected(ind + diff);
      }
    }
  }

  private scrollToSelected(i: number) {
    const menu = this.getElementsByClassName(styles.options)[0];
    const selectedOption = menu.getElementsByClassName(styles.option)[i];
    selectedOption && selectedOption.scrollIntoView({ block: 'nearest', inline: 'nearest'});
  }

  private onInput = () => {
    const { form, name, validity, _value, validationMessage } = this._el;
    const errors = this.validators.map((fn) => fn(_value)).filter((e) => !!e);
    this._el.setCustomValidity(errors[0] || '');
    const validationEvent = new CustomEvent('validation', {bubbles: true});
    this._el.dispatchEvent(validationEvent);
    form.controls.set(name, { valid: validity.valid, validationMessage, value: _value });
    const changeEvent = new CustomEvent(
      'changeForm',
      { bubbles: true, detail: { current: this._el } }
    );
    form.dispatchEvent(changeEvent);
  }

  private onSearch = (e) => {
    this.searchValue = e.target.value;
    if (this.filteredOptions.includes(this.selected)) {
      this.selected = null;
      this._el._value = this.selected;
      this.onInput();
    }
    this.update();
  }

  private outsideClick = (e) => {
    if (!this.contains(e.target)) {
      this.toggleMenu(false);
    }
  }

  private btnClickHandler = () => {
    this.toggleMenu(!this.isOpened);
  }

  private selectOption = (option: Option, ev: MouseEvent) => {
    ev.stopPropagation();
    this.selected = option;
    this._el._value = this.selected;
    this.onInput();
    this.toggleMenu(!this.isOpened);
  }

  static get observedAttributes() {
    return ['name', 'disabled', ...Object.keys(Validator)];
  }

  private toggleMenu(isOpened: boolean) {
    this.isOpened = isOpened;
    const { top } = this.getBoundingClientRect();
    this.classList.remove(styles.top);
    this.classList.remove(styles.bottom);
    this.direction = top + 300 <= document.body.offsetHeight ? MenuDirection.bottom : MenuDirection.top;
    if (this.isOpened) {
      this.classList.add(styles[this.direction]);
      this.classList.add(styles.opened);
      setImmediate(() => document.body.addEventListener('click', this.outsideClick));

    } else {
      this.searchValue = null;
      this.onInput();
      this.classList.remove(styles.opened);
      document.body.removeEventListener('click', this.outsideClick);
    }
    this.update();
    if (this.isOpened && this.selected) {
      const index = this.filteredOptions.findIndex(({id}) => this.selected  && id === this.selected.id);
      index >= 0 && this.scrollToSelected(index);
    }

    if (window.matchMedia('(min-width: 1024px)').matches) {
      this._el.focus();
    }

    const searchInput = document.getElementById('select-search');
    searchInput && searchInput.focus();
  }

  private onReset = () => {
    this.selected = null;
    this._el._value = null;
    this.toggleMenu(false);
  }

  public connectedCallback() {
    super.connectedCallback();
    setImmediate(() => {
      const { form } = this._el;
      if (!form.controls) {
        form.controls = new Map();
      }
      const errors = this.validators.map((fn) => fn(this._el._value)).filter((e) => !!e);
      this._el.setCustomValidity(errors[0] || '');
      const { name, validity, _value, validationMessage } = this._el;
      form.controls.set(name, { valid: validity.valid, validationMessage, _value });
      if (_value) {
          const validationEvent = new CustomEvent('validation', {bubbles: true});
          this._el.dispatchEvent(validationEvent);
      }
    });
    this.classList.add(styles.wrapper);
    this._el.classList.add(styles.input);
    this._el._value = this.selected;
    this.appendChild(this._el);
    this.addEventListener('keydown', this.keyDownHandler);
    this._el.addEventListener('reset', this.onReset);
  }


  public attributeChangedCallback(name, oldValue, newValue) {
    if (!!Validator.hasOwnProperty(name)) {
      this.validators.push(Validator[name]);
    } else {
      this._el.setAttribute(name, newValue);
    }

    if (name === 'disabled') {
      this.classList.add(styles.disabled);
    }
  }


  private disconnectedCallback() {
    this.removeEventListener('keydown', this.keyDownHandler);
    document.body.removeEventListener('click', this.outsideClick);
    this._el.removeEventListener('reset', this.onReset);
  }

  private get filteredOptions(): Option[] {
    return this.searchValue ?
      (this.options || []).filter(({label}) => label.toLowerCase().includes(this.searchValue.toLowerCase()))
      : (this.options || []);
  }

}

export default WSelect;
