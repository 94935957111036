/**
 * services/intercom.service.js
 *
 * @file Holds service to work with Intercom
 * @see {@link https://developers.intercom.com/installing-intercom/docs/intercom-javascript} API reference
 */
import { whenDefined } from '@werkin/lib/helpers';

/**
 * Defining variables
 */
const app_id = process.env.REACT_APP_INTERCOM_APP_ID;
const widgetURL = 'https://widget.intercom.io/widget/';
const pushState = window.history.pushState;
const targetRoutes = ['dashboard', 'register', 'get-link', 'login', 'launch', 'help', 'join', 'temporary'];

/**
 * Initializing widget
 */
export function init() {


  window.intercomSettings = {
    horizontal_padding: 20,
    vertical_padding: 60,
  };

  // Create and appendToBody Intercom widget script
  const script = document.createElement('script');
  script.src = widgetURL;
  script.onload = () => {
    handler('reattach_activator');
    handler('update', { app_id });
    toggleWhenLocationChanged();
  };

  document.head.appendChild(script);

  const app = document.getElementById('App');

  toggleWhenFocused(document.activeElement, true);

  // Initialize focus listeners
  app.addEventListener('focusin', (e) => {
    toggleWhenFocused(e.target, true);
  });

  app.addEventListener('focusout', (e) => {
    toggleWhenFocused(e.target, false);
  });

  // Initialize history listeners
  window.history.pushState = proxyHistoryEvents;
  window.history.replaceState = proxyHistoryEvents;
}

function toggleWhenFocused(el, on) {
  if (el instanceof HTMLInputElement || el instanceof HTMLTextAreaElement) {
    const hide_default_launcher = (!!on && isMobile()) || isTargetPath();
    hide_default_launcher && handler('hide');
    setImmediate(() => handler('update', { hide_default_launcher }));
  }
}

function proxyHistoryEvents() {
  const appliedFn = pushState.apply(window.history, arguments);
  setImmediate(toggleWhenLocationChanged);

  return appliedFn;
}

function handler() {
  whenDefined(window, 'Intercom', () => {
    window.Intercom(...arguments);
  });
}

const isTargetPath = () => !targetRoutes.some((r) => window.location.pathname.includes(r));

const isMobile = () => window.matchMedia('(max-width: 767px)').matches;

export function toggleWhenLocationChanged() {
  const hide_default_launcher = isTargetPath();
  hide_default_launcher && handler('hide');
  handler('update', { hide_default_launcher });
}

/**
 * Provides an interface for
 * accessing Intercom's API
 */
export default handler;
