export enum InputTypes {
  text = 'text',
  email = 'email',
  password = 'password',
}

export enum ValidationStrategy {
  off = 'off',
  delay = 'delay',
  immediate = 'immediate',
}

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  OUTLINE = 'outline',
  LINK = 'link',
}

export enum ValidationErrors {
  required = 'The field is required',
  email = 'Invalid email address',
  name = 'Invalid value',
  special = 'Required at least 1 special symbol',
  number = 'Required at least 1 number',
  lowercase = 'Required at least 1 letter in lower case',
  uppercase = 'Required at least 1 letter in upper case',
  withoutSpaces = 'The field should not include spaces',
}

