import DefaultLeftController from 'controllers/default.left';
import store from '@werkin/store';
import RouterError from 'services/router/router-error';

class ChatController extends DefaultLeftController {
  before() {
    if (this.store.get('isAlertsModal')) {
      this.router.go({ name: 'alerts' });

      return Promise.reject(new RouterError({ message: 'Redirecting to alerts...' }));
    }

    if (this.router.display === 'desktop') {
      const landingRoute = store.get('landingRoute');

      this.router.go({ path: landingRoute });

      return Promise.reject(new RouterError({ message: `Redirecting to ${landingRoute}` }));
    }
  }

  controller() {
    super.controller();
  }
}

export default ChatController;
