import apollo from 'api';
import { observable, ObserveMethod } from 'decorators';
import { ISkillAndInterest } from 'interfaces/profile/skills-and-interests.interface';
import ProfileSkillsAndInterestsModule from 'modules/ProfileSkillsAndInterests/profile-skills-and-interests.module';
import { Auth } from 'services';
import { NotificationManager } from 'services';
import REST from 'services/rest.service';
import setIndividualSkillsAndInterests from '../../graphql/mutations/setIndividualSkillsAndInterests.graphql';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';

class ProfileSkillsAndInterestsController {
  public module: ProfileSkillsAndInterestsModule;
  public suggestedSkillsStore: ISkillAndInterest[] = [];

  @observable([]) public suggestedSkills: ISkillAndInterest[];
  @observable([]) public skillsAndInterests: ISkillAndInterest[];
  @observable(false) public isEditMode: boolean;
  @observable(false) public loading: boolean;
  @observable(true) public next: boolean;

  public OnInit() {
    this.fetchProfile();
    this.subscribeFilter();
  }

  public async fetchProfile() {
    const { data: { profile } } = await apollo.query({
      query: ProfileQuery,
      variables: {
        id: Auth.getUserId(),
      },
    });

    this.skillsAndInterests = profile.skillsAndInterests;
  }

  @ObserveMethod('skillsAndInterests')
  public subscribeFilter() {
    this.filterSuggestedSkills();
  }

  public filterSuggestedSkills() {
    this.suggestedSkills = this.suggestedSkillsStore.filter(
      (skill) => !this.skillsAndInterests.some((exSkill) => skill.id === exSkill.id)
    );
  }

  public onEnableEditMode() {
    this.isEditMode = true;
  }

  public onRemove(id) {
    this.skillsAndInterests = this.skillsAndInterests.filter((skill) => skill.id !== id);
  }

  public onIncr(id) {
    this.skillsAndInterests =  this.skillsAndInterests.map((skill) => {
      if (skill.id === id) {
        return {
          ...skill,
          yearsOfExperience: skill.yearsOfExperience + 1,
        };
      }

      return skill;
    });
  }

  public onDecr(id) {
    this.skillsAndInterests = this.skillsAndInterests.map((skill) => {
      if (skill.id === id && skill.yearsOfExperience) {
        return {
          ...skill,
          yearsOfExperience: skill.yearsOfExperience - 1,
        };
      }

      return skill;
    });
  }

  public async onSearchSkills(e) {
    const searchQuery = e.target.value.toLowerCase();
    if (!searchQuery) {
      return  this.suggestedSkills = [];
    }

    ({ data: this.suggestedSkillsStore } = await REST.getSkills(searchQuery));
    this.suggestedSkills = this.suggestedSkillsStore;

    this.filterSuggestedSkills();
  }

  public onAddSkill(skill) {
    const newSkill = {
      ...skill,
      yearsOfExperience: 0,
    };

    this.isEditMode = true;
    this.skillsAndInterests = [
      ...this.skillsAndInterests,
      newSkill,
    ];
  }

  public async onSaveSkills() {
    this.loading = true;
    this.next = false;

    const { data: { setIndividualSkillsAndInterests: { errors } } } = await apollo.mutate({
      mutation: setIndividualSkillsAndInterests,
      variables: {
        command: {
          skillsAndInterests: this.skillsAndInterests,
        },
      },
    });

    if (errors && errors.length) {
      this.fetchProfile();
      NotificationManager.showNotification({
        text: errors[0],
        type: 'error',
      });
    }

    this.loading = false;
    this.next = true;
    this.isEditMode = false;
  }
}

export default ProfileSkillsAndInterestsController;
