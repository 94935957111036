import { HubConnectionBuilder } from '@microsoft/signalr';
import auth from 'services/auth.service';

const defaultListeners = () =>
  ({
    ChatMessageCreated: new Set([]),
    ChatMessageUpdated: new Set([]),
    ChatMessagesUpdated: new Set([]),
    ChatMessageDeleted: new Set([]),
    AlertEvent: new Set([]),
    AchievementAwarded: new Set([]),
    GoalsShared: new Set([]),
    CreditAwarded: new Set([]),
    ChatListUpdated: new Set([]),
    AlertCreated: new Set([]),
    AlertDeleted: new Set([]),
    AlertCenterUpdated: new Set([]),
    ActionCreated: new Set([]),
    ActionUpdated: new Set([]),
    ActionDeleted: new Set([]),
    PollCreated: new Set([]),
    UserPresenceStatus: new Set([]),
    IndividualUpdated: new Set([]),
    ProfileUpdated: new Set([]),
    ChatDeleted: new Set([]),
    ActivityFeedUpdated: new Set([]),
    StreakUpdated: new Set([]),
    CollectiveSwitched: new Set([]),
    MatchCreated: new Set([]),
    OutstandingTasksUpdated: new Set([]),
  });

const REACT_APP_API_WS_URL = process.env.REACT_APP_API_WS_URL;

class SignalRService {
  constructor() {
    this.listeners = defaultListeners();
  }

  async init() {
    this.connection = new HubConnectionBuilder()
      .withUrl(REACT_APP_API_WS_URL, { accessTokenFactory: () => auth.getToken() })
      .withAutomaticReconnect()
      .build();

    Object.keys(this.listeners)
      .forEach((evtName) => this.connection.on(evtName, (data) => this.updateListeners(evtName, data)));

    try {
      await this.connection.start();
    } catch (e) {
      console.log('Connection to signalR is failed!!!!!!!!!!!!');
    }
  }

  async stop() {
    this.listeners = defaultListeners();
    await this.connection.stop();
  }

  updateListeners(evt, data) {
    this.listeners[evt].forEach((fn) => fn(data));
  }

  invoke(method, ...args) {
    return this.connection.invoke(method, ...args);
  }

  subscribe(evt, callback) {
    if (evt in this.listeners) {
      this.listeners[evt].add(callback);
    } else {
      console.error(`Event ${evt} can't be found`);
    }

    return () => {
      this.listeners[evt].delete(callback);
    };
  }
}

export default new SignalRService();
