import ModalBase from 'atoms/lit/modal/base';
import { html } from 'lit-html';
import { Router } from 'services/router.service';

class FullscreenCollectivesModal extends ModalBase {
  public onOpen() {
    this.template = this.wrap(this.tmp);

    const unsubscribe = Router.subscribeOnResizing((display) => {
      if (display === 'desktop') {
        this.destroy();
      }
    });

    this.subscribe(unsubscribe, 'destroy');
  }

  public get tmp() {
    return html`<w-sidebar-module .destroy=${() => this.destroy()}> </w-sidebar-module>`;
  }
}

export default FullscreenCollectivesModal;
