import { Icons } from 'components/lit';
import { showIf } from 'directives';
import { directive, html, TemplateResult } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import Component from 'services/templator/component';
import styles from './collapsable.module.scss';

export interface ICollapsableItem {
  onChangeCollapseState: () => void;
  readonly template: TemplateResult;
}

export class Collapsable extends Component implements ICollapsableItem {
  public onInit() {
    this.setOptions({ isCollapsed: true });
  }

  public onChangeCollapseState() {
    this.setOptions({ isCollapsed: !this.options.isCollapsed });

    const { collapsableGroupCb } = this.options;
    collapsableGroupCb && collapsableGroupCb();
  }

  get renderCollapsableButton() {
    return directive(() => (part) => {
      this.subscribe(({ isCollapsed }) => {
        part.setValue(this.collapsableButton(isCollapsed));
        part.commit();
      }, 'isCollapsed');
    });
  }

  private collapsableButton(isCollapsed) {
    const icon = isCollapsed ? Icons.circleArrowDown : Icons.circleArrowUp;

    return html`
      <div class=${styles['collapsable-button']}>
        ${icon}
      </div>
    `;
  }

  get renderContent() {
    return directive(() => (part) => {
      this.subscribe(({ isCollapsed }) => {
        part.setValue(this.content(isCollapsed));
        part.commit();
      }, 'isCollapsed');
    });
  }

  private content(isCollapsed) {
    return showIf(!isCollapsed, this.options.template);
  }

  get template() {
    const { title, isMobile } = this.options;

    const classes = classMap({
      [styles['collapsable-wrapper']]: true,
      [styles['grouped-mobile']]: isMobile,
    });

    return html`
      <div class=${classes}>
        <div
          @click=${() => this.onChangeCollapseState()}
          class=${styles['header-collapsable']}
        >
          <div class=${styles.title}>${title}</div>
          ${this.renderCollapsableButton()}
        </div>

        <div class=${styles.content}>
          ${this.renderContent()}
        </div>
      </div>
    `;
  }
}
