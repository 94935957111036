import { ValidationStrategy } from 'components/custom/enums';
import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import styles from '../join.module.scss';
import JoinTemplate from '../join.view';

class StepThreeFragment {
  public view: JoinTemplate;

  get fields() {
    return ['ld1'];
  }

  get header() {
    return html`
     <div class="${styles.desc}">
          What are the areas you want to focus on during the programme?
          These options have been supplied by your organisation or network. Please choose 3
      </div>
    `;
  }

  @Observe('threeValid')
  get buttons() {
    return html`
    <div class="${styles.buttons}">
      <button
       type="button"
       @click=${(e) => this.view.controller.onBack(e)}
       class="w-button outline ${styles.back}">
       Back
      </button>
      <button
       type="submit"
       class="w-button primary ${styles.next}" ?disabled="${!this.view.controller.threeValid}">
       Next
       </button>
    </div>
    `;
  }

  @Observe('learningOptions', 'learning_one', 'learning_two', 'learning_three')
  get learning_one() {
    const { learning_one } = this.view.controller;

    return html`
      <w-validation-box label="Learning and development area #1">
        <w-select
         name="learning_one"
         .selected=${learning_one}
         vRequired
         .options=${this.view.controller.learningAndDevOptions(learning_one)}
         .placeholder="${'Please choose'}"
         />
      </w-validation-box>
    `;
  }

  @Observe('learningOptions', 'learning_one', 'learning_two', 'learning_three')
  get learning_two() {
    const { learning_two } = this.view.controller;

    return html`
      <w-validation-box label="Learning and development area #2">
        <w-select
         name="learning_two"
         .selected=${learning_two}
         vRequired
         .options=${this.view.controller.learningAndDevOptions(learning_two)}
         .placeholder="${'Please choose'}"
         />
      </w-validation-box>
    `;
  }

  @Observe('learningOptions', 'learning_one', 'learning_two', 'learning_three')
  get learning_three() {
    const { learning_three } = this.view.controller;

    return html`
      <w-validation-box label="Learning and development area #3">
        <w-select
         name="learning_three"
         .selected=${learning_three}
         vRequired
         .options=${this.view.controller.learningAndDevOptions(learning_three)}
         .placeholder="${'Please choose'}"
         />
      </w-validation-box>
    `;
  }

  @Observe('learning_one_goal', 'learning_two_goal', 'learning_three_goal')
  private input(areaKey: string) {
    const field = () => html`
       <w-validation-box
       label="${`Goal for "${this.view.controller[areaKey].label}" (Optional)`}"
       strategy="${ValidationStrategy.off}"
       >
          <w-input
            type="text"
            name=${`${areaKey}_goal`}
            placeholder="Enter your goal here"
            value="${this.view.controller[`${areaKey}_goal`]}"
          >
      </w-validation-box>
    `;

    return this.view.controller[areaKey] ? field() : nothing;
  }

  @Observe('disabledFields')
  get form() {
    return html`
       <form
         name="three"
         novalidate="novalidate"
         class="${styles.form}"
         @changeForm="${(e) => this.view.controller.onChange(e)}"
         @submit="${(e) => this.view.controller.onNext(e, 'Learning and development areas completed')}"
       >
       ${this.learning_one}
       ${this.learning_two}
       ${this.learning_three}
       ${this.buttons}
      </form>
    `;
  }

  get template() {
    return html`
      <div>
        ${this.header}
        ${this.view.mandatory}
        ${this.form}
      </div>
    `;
  }
}

export default StepThreeFragment;
