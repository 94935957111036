import { Auth } from 'services';
import { directive, nothing } from 'lit-html';
import Directive from 'services/templator/directive';
import store from '@werkin/store';
import { ValidatedInput, ValidatedSelect, ValidatedTextArea } from 'components/lit';
import { FormSubmitButtonView } from '../components/lit/form-submit-button.view';
import { ValidatedUploader } from 'components/lit/validated/validated-uploader.view';

class GeneralDirectives extends Directive {
  get isMember() {
    return directive((trueComponent, falseComponent) => part => {
      const userId = Auth.getUserId();
      const unsubscribe = this.router.controller.chats.subscribe({
        next: ({ all_docs: chats }) => {
          const { chatId } = this.router.params;

          if (!chatId) { return; }
          let chat;

          chat = chats.find(chat => chat.id === chatId);

          if (!chat) {
            chat = this.router.controller.protected_chats.getById(chatId);
          }

          const participant = chat.participants.find(participant => participant.id === userId);

          part.setValue(participant ? trueComponent : falseComponent || nothing);
          part.commit();
        },
        keys: ['participants'],
      });

      this.module.addSubscription(unsubscribe);
    });
  }

  get watchStore() {
    return directive((key, component) => part => {
      part.setValue(component());
      part.commit();

      const unsubscribe = store.subscribe(key, () => {
        part.setValue(component());
        part.commit();
      });

      this.module.addSubscription(unsubscribe);
    });
  }

  get showIf() {
    return directive((condition, component) => part => {
      if (condition) {
        part.setValue(component);
      } else {
        part.setValue(nothing);
      }

      part.commit();
    });
  }

  get renderInput() {
    return directive((name, controller) => part => {
      const input = new ValidatedInput({
        name,
        inputHandler: controller.onInput,
      });

      controller.subscribeData(data => {
        input.setOptions(data[name]);

        part.setValue(input.template);
        part.commit();
      }, name);
    });
  }

  get renderUploader() {
    return directive((name, controller) => part => {
      const input = new ValidatedUploader({
        name,
        handleIconChange: controller.handleIconChange,
      });

      controller.subscribeData(data => {
        input.setOptions(data[name]);

        part.setValue(input.template);
        part.commit();
      }, name);
    });
  }

  get renderTextArea() {
    return directive((name, controller) => part => {
      const textarea = new ValidatedTextArea({
        name,
        rows: 6,
        inputHandler: controller.onInput,
      });

      controller.subscribeData(state => {
        textarea.setOptions(state[name]);

        part.setValue(textarea.template);
        part.commit();
      }, name);
    });
  }

  get renderSelect() {
    return directive((name, controller) => part => {
      const select = new ValidatedSelect({
        name,
        rows: 6,
        inputHandler: controller.onInput,
        selectHandler: controller.onSelect,
        handleOpen: controller.onSelectOpen,
        handleClose: controller.onSelectClose,
      });

      controller.subscribeData(state => {
        select.setOptions(state[name]);

        part.setValue(select.template);
        part.commit();
      }, name);
    });
  }

  _abstractDirectiveRender(watchableArgs, view, part) {
    return (state) => {
      const params = watchableArgs.map(argument => {
        const { [argument]: arg } = state;

        return arg;
      });

      part.setValue(view(...params));
      part.commit();
    };
  }

  _directiveBuilder(view, watchableArgs, subscribeMethod) {
    return directive(() => part => {
      this.controller[subscribeMethod](this._abstractDirectiveRender(watchableArgs, view, part),  ...watchableArgs);
    });
  }

  _directiveBuilderUseState(view, watchableArgs) {
    return this._directiveBuilder(view, watchableArgs, 'subscribeState');
  }

  _directiveBuilderUseData(view, watchableArgs) {
    return this._directiveBuilder(view, watchableArgs, 'subscribeData');
  }

  _directiveBuilderUseClientDB(view, clientDBEntity, watchableArgs) {
    return directive(() => part => {
      this.router.controller[clientDBEntity].subscribe({
        next: this._abstractDirectiveRender(watchableArgs, view, part),
        keys: watchableArgs,
      });
    });
  }

  get renderConfirmButton() {
    return directive((title = 'Confirm', loadingText = '') => part => {
      const button = new FormSubmitButtonView({ title, loadingText });

      this.controller.subscribeState(({ loading, next })  => {
        button.setOptions({ loading, next });

        part.setValue(button.template);
        part.commit();
      }, 'next', 'loading');
    });
  }
}

export default GeneralDirectives;
