import { Icons } from 'components/lit';
import { Collapsable } from 'components/lit/collapsable.view';
import { RadioButton } from 'components/lit/radio-buttons.view';
import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import ProfileNotificationsAndEmailsController from './profile-notifications-and-emails.controller';
import styles from './profile-notifications-and-emails.module.scss';

export const permissionsDefaultValue = [{
  title: 'Message alerts',
  description: '* Where ‘Alert or email’ option selected, an email will only be sent if the alert is unread for a period of 30 minutes. If your mobile device is an iPhone, you will only receive alerts to your desktop as Apple does not support push notifications on web apps. Yet.',
}, {
  title: 'Calendar alerts',
  description: '* Where ‘Alert or email’ option selected, an email will only be sent if the alert is unread for a period of 30 minutes. If your mobile device is an iPhone, you will only receive alerts to your desktop as Apple does not support push notifications on web apps. Yet.',
}];

class ProfileNotificationsAndEmailsTemplate {
  public controller: ProfileNotificationsAndEmailsController;

  @Observe('permissions')
  get collapsableAlerts() {
    return this.controller.permissions.map((permission) => {
      const key = permission.category.split(' ').map((w) => w.toLowerCase()).join('_');

      this.controller.collapsableAlerts[key] = this.controller.collapsableAlerts[key] || new Collapsable({
        title: permission.category,
        template: this.buildAlert(permission),
      });

      return this.controller.collapsableAlerts[key].template;
    });
  }

  @Observe('permissions')
  public buildAlert(permission) {
    // HARDCODED description value, should be added by backend at next sprint
    let description = '';
    if (permission.description) {
      ({ description } = permission);
    } else {
      const permissionDefault = permissionsDefaultValue.find(({ title }) => title === permission.category);
      if (permissionDefault) {
        ({ description } = permissionDefault);
      }
    }

    return html`
      <div class=${styles.table}>
        <div class='${styles['col-title']} ${styles['table-row']}'>
          <div></div>
          ${repeat(permission.options, (options: any) => html`<div>${options.label}</div>`)}
        </div>

        ${this.radioGroup(permission)}
      </div>

      <div class=${styles['collapsable-description']}>
        ${description}
      </div>
    `;
  }

  @Observe('selectedSettings')
  public radioGroup({ category, options }) {
    const settings = this.controller.selectedSettings[category];

    return settings.map((setttingsItem) => html`
      <div class=${styles['table-row']}>
        <div class=${styles['row-title']}>${setttingsItem.label}</div>
        ${this.radioGroupOptions(category, options, setttingsItem)}
      </div>
    `);
  }

  public radioGroupOptions(category, options, setttingsItem) {
    return options.map(({ tag }) => {
      const radioButton = RadioButton({
        checked: tag === setttingsItem.value,
        handler: () => this.controller.onSetPermission(category, { ...setttingsItem, value: tag, }),
      });

      return html`<div>${radioButton}</div>`;
    });
  }

  @Observe('allowNotifications')
  public get notifications() {
    const {allowNotifications, handleNotificationsPermission } = this.controller;
    const text = {
      granted: 'Push notifications are enabled and helping you get the most out of WERKIN. (If you wish to disable them for a while, you can do so in the information panel in the address bar.)',
      prompt: 'Enabling push notifications will bring updates on your WERKIN journey straight to your device. Simply click Enable, and then Allow in the browser prompt.',
      denied: 'It looks like you have previously blocked push notifications from WERKIN. If you wish to re-enable them, you can do so by clicking the information icon in the address bar.',
    };

    return html`
      <div class="${styles.card} ${styles['notification-card']}">
        <div>
          <div class=${styles['notification-title']}>Push notification permissions</div>
          <div class=${styles['notification-content']}>
            <p class=${styles['notification-description']}>
              ${text[allowNotifications] || (!allowNotifications && text.prompt) || 'Please check back later to update your push notification permissions.'}
            </p>
          </div>
        </div>

        <div>
          ${allowNotifications === 'prompt' || !allowNotifications ? html `
            <button
              @click=${() => handleNotificationsPermission()}
              class=${styles.button}
            >
              Enable
            </button>
          ` : nothing}
        </div>
      </div>
    `;
  }

  get template() {
    const supportsPushNotifications = 'Notification' in window;

    return html`
      <div class=${styles['cards-wrapper']}>
      <div class=${styles.header}>
        <h3 class=${styles.title}>Notifications and Emails</h3>
        <p class=${styles.description}>
          We’ll let you know when anything important happens by posting to your alert centre
          <span class=${styles.bell}>${Icons.bell}</span>
        </p>
      </div>

        ${supportsPushNotifications ? this.notifications : nothing}

        <div class=${styles.card}>
          ${this.collapsableAlerts}
        </div>

        <div
          class=${styles['reset-config']}
          @click=${() => this.controller.setDefaultPermissions()}
        >
          Reset to default notification settings
        </div>
      </div>
    `;
  }
}

export default ProfileNotificationsAndEmailsTemplate;
