import apollo from 'api';
import { observable } from 'decorators';
import { Resource } from 'interfaces/sessionGuides';
import resources from '../../graphql/queries/resourceGuides.graphql';

import DashResourcesModule from './dash-resources.module';

class DashResourcesController {
  public module: DashResourcesModule;
  @observable([]) public resources;
  @observable({}) public selectedResource;

  constructor() {
    this.handleResources = this.handleResources.bind(this);
  }

  public async OnInit() {
    const data = await this.fetchResources();
    const {
      data: {
        profile: { resources },
      },
    } = data;

    this.resources = resources;
    this.selectedResource = resources[0];
  }

  private async fetchResources() {
    const data = await apollo.query({
      query: resources,
      fetchPolicy: 'no-cache',
    });

    return data;
  }

  public handleResources(event: React.FormEvent<HTMLSelectElement>) {
    const { value } = event.currentTarget;
    if (!value) {
      return;
    }

    this.selectedResource = this.resources.find((resource: Resource) => resource.id === value);
  }
}

export default DashResourcesController;
