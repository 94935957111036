import { normalizeString } from '@werkin/lib/helpers';
import apollo from 'api';
import { observable } from 'decorators';
import get from 'lodash/get';
import HeaderModule from 'modules/ChatHeader/header.module';
import { Auth, NotificationManager } from 'services';
import { Router } from 'services/router.service';
import ChatQuery from '../../graphql/queries/chat/Chat.graphql';

class ChatHeaderController {
  @observable(null, true) public chat: any;
  @observable(false, true) public invisible: boolean;
  private leaveJoin: any;
  public module: HeaderModule;

  public async OnInit() {
    await this.fetchChat();
    this.invisible = Router.display === 'mobile' && Router.route.name === 'chats.actions.create';

    const routerUnsubscribe = Router.listen(async () => {
      if (Router.params.chatId && !this.invisible) {
        await this.fetchChat();
      }

      this.invisible = Router.display === 'mobile' && Router.route.name === 'chats.actions.create';
    });

    this.module.subs.add(routerUnsubscribe);
  }

  public async fetchChat() {
    const { data: { chat } } = await apollo.query({
      query: ChatQuery,
      variables: {
        id: Router.params.chatId,
      },
    });

    this.chat = chat;
  }

  public getChatTitle() {
    if (this.chat.type !== 'direct') {
      return this.chat.title;
    }

    const current = Auth.getUserId();
    const guests = this.chat.participants.filter(({ id }) => id !== current);
    const guestNames = guests.map((guest) => guest.name).join(', ');

    return guestNames;
  }

  public goToChat() {
    Router.go({ name: 'chat' });
  }

  public getHandler(actionType) {
    const actions = {
      join: this.leaveJoin.join,
      leave: this.leaveJoin.leave,
      delete: async () => {
        try {
          Router.go({ hash: { DeleteChatModal: true }});
        } catch (e) {
          NotificationManager.showNotification({
            text: e.message,
            type: 'error',
          });
        }
      },
      add_members_to: async () => {
        const modal = this.chat.type !== 'direct' ? 'groups' : 'direct';

        const configs = {
          tab: 'members',
          modal,
          action: 'edit',
          single: true,
          search: '',
          chatId: Router.params.chatId,
        };

        Router.go({
          name: Router.display === 'desktop' ? Router.route.name : 'chat.edit',
          state: { left: configs },
        });
      },
      edit: async () => {
        const modal = this.chat.type !== 'direct' ? 'groups' : 'direct';

        const configs = {
          tab: 'details',
          modal,
          action: 'edit',
          search: '',
          chatId: Router.params.chatId,
        };

        Router.go({
          name: Router.display === 'desktop' ? Router.route.name : 'chat.edit',
          state: { left: configs },
        });
      },
    };

    return actions[actionType];
  }

  public get isVisibleMenu() {
    const type = this.getSettingsType();

    return !(this.chat.type === 'channel' || type === 'non-member-direct' || type === 'non-member-private-group');
  }

  public getSettingsType() {
    let type = this.chat.type;
    let role = 'non-member';

    const userId = Auth.getUserId();
    const access = get(this.chat, 'attributes.access', '');

    if (get(this.chat, 'participants', []).some(({ id }) => id === userId)) {
      role = 'member';
    }

    if (get(this.chat, 'creator', null) === userId) {
      role = 'creator';
    }

    if (type === 'group') {
      type = `${access}-${type}`;
    }

    return `${role}-${type}`;
  }

  public getSettingsByTypes(typesSetting, typeChat) {
    return typesSetting.map((typeSetting) =>
      ({
        label: `${normalizeString(typeSetting)} ${typeChat}`,
        handler: this.getHandler(typeSetting),
        disabled: !this.getHandler(typeSetting),
      }));
  }

  public getMuteOption() {
    return {
      label: 'Mute conversation',
      handler: () => console.log('Handle mute'),
      disabled: true,
    };
  }

  public getBlockOption(participant) {
    return {
      label: `Block ${participant.name}`,
      handler: () => console.log('Handle block'),
      disabled: true,
    };
  }

  public getDirectOptions(participants) {
    const members = participants.filter(({ id }) => id !== Auth.getUserId());
    const options = [this.getMuteOption()];

    members.length === 1 && options.push(this.getBlockOption(members[0]));

    return options;
  }

  public getSettings() {
    switch (this.getSettingsType()) {
      case 'creator-public-group':
      case 'creator-private-group':
        return this.getSettingsByTypes(['edit', 'add_members_to', 'delete', 'archive'], this.chat.type);
      case 'creator-direct':
        return [
          ...this.getDirectOptions(this.chat.participants),
          ...this.getSettingsByTypes(['archive'], this.chat.type),
        ];
      case 'member-public-group':
        return this.getSettingsByTypes(['suggest', 'leave'], this.chat.type);
      case 'member-private-group':
        return this.getSettingsByTypes(['leave'], this.chat.type);
      case 'member-direct':
        return this.getDirectOptions(this.chat.participants);
      case 'non-member-public-group':
        return this.getSettingsByTypes(['join'], this.chat.type);
      default:
        return [];
    }
  }
}

export default ChatHeaderController;
