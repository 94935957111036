import apollo from 'api';
import jstz from 'jstz';
import { errorHandler } from 'utils/error-helpers';
import SetTimeZoneMutation from '../graphql/mutations/setTimeZone.graphql';

class TimeZoneService {
  private key: string = 'werkin-x-timezone';

  public update() {
    if (this.localTimeZone !== this.systemTimeZone) {
      this.handleTimeZoneUpdate();
    }
  }

  private get localTimeZone() {
    return window.localStorage.getItem(this.key);
  }

  private get systemTimeZone() {
    const timezone = jstz.determine();

    return timezone.name() || Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  private setLocalTimeZone(timeZone: string) {
    console.info(`${this.key}: changed from ${this.localTimeZone} to ${timeZone}`);
    window.localStorage.setItem(this.key, timeZone);
  }

  private async handleTimeZoneUpdate() {
    try {
      const timeZone = this.systemTimeZone;

      apollo.mutate({
        mutation: SetTimeZoneMutation,
        variables: {
          command: {
            timeZone,
          },
        },
      });

      this.setLocalTimeZone(timeZone);
    } catch (err) {
      console.error('setTimeZone', err);
      errorHandler(err, true);
    }
  }
}

export default TimeZoneService;
