import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import styles from './skeleton.module.scss';

export const skeleton = ({
  height,
  count = 1,
  backgroundColor,
  gradientColor,
  className,
  circle,
  width,
}) => {
  const bgColor = backgroundColor || 'rgb(238, 238, 238)';
  const color = gradientColor || 'rgb(245, 245, 245)';

  const style = `
    ${circle ? 'border-radius: 100%' : ''};
    ${height ? `height: ${height}px` : ''};
    ${width ? `width: ${width}px` : ''};
    background-color: ${bgColor};
    background-image: linear-gradient(90deg, ${bgColor}, ${color}, ${bgColor});
  `;

  return repeat(Array(count).fill(null), () => html`
    <div
      class="${styles.skeleton} ${className}"
      style="${style}"
    />
  `);
};
