import { Observe } from 'decorators';
import { html } from 'lit-html';
import GetLinkController from './get-link.controller';
import styles from './get-link.module.scss';

class GetLinkTemplate {
  public controller: GetLinkController;

  @Observe('error')
  public get email() {
    return html`
      <w-validation-box
        strategy="delay"
        label="Email Address"
        outsideError="${this.controller.error}"
      >
        <w-input
          autofocus
          name="email"
          placeholder="Type email address here"
          type="email"
          vRequired
          vEmail
        />
      </w-validation-box>
    `;
  }

  @Observe('valid', 'loading')
  private get button() {
    const { valid, loading } = this.controller;

    return html`
      <button class="w-button primary" type="submit" ?disabled="${!valid || loading}">
        ${loading ? 'Loading...' : 'Next'}
      </button>`;
  }

  public get template() {

    return html`
      <div class="${styles.wrapper}">
        <form
          @changeForm=${(e) => this.controller.onChange(e)}
          novalidate="novalidate"
          @submit=${(evt) => this.controller.onNext(evt)}>
          ${this.email}
          ${this.button}
        </form>
      </div>
    `;
  }
}

export default GetLinkTemplate;
