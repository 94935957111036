import { directive, html } from 'lit-html';
import styles from './tag-input.module.scss';
import { repeat } from 'lit-html/directives/repeat';
import { Icons } from 'components/lit';

class TagInput {
  get tags() {
    const tags = [];

    this.tags_map.forEach(value => {
      tags.push(value);
    });

    return tags;
  }

  constructor() {
    this.onChange = () => {};
    this.onDeleteTag = () => {};

    this.tags_map = new Map();
    this.name = 'tags-input';
    this.placeholder = 'Search';
    this.listeners = new Set([]);
    this.keys = [{ property: 'id', template: this.defaultKeyTemplate }];
  }

  configure(options) {
    this.onChange = options.onChange || this.onChange;
    this.onDeleteTag = options.onDeleteTag || this.onDeleteTag;

    this.limit = options.limit;
    this.name = options.name || this.name;
    this.placeholder = options.placeholder || this.placeholder;

    this.keys = options.keys.map(key_opts => {
      key_opts.template = key_opts.template || this.defaultKeyTemplate;

      return key_opts;
    });
  }

  async add(value) {
    if (this.limit && this.tags.length === this.limit) {
      throw new Error('Max limit of users is reached');
    }

    if (value.id) {
      this.tags_map.set(value.id, value);
    } else {
      const id = this.tags_map.size() + 1;

      this.tags_map.set(id, { id, ...value });
    }

    this.listeners.forEach(fn => fn());
    this._focusInput(true);
  }

  _delete(tag) {
    this.tags_map.delete(tag.id);
    this.listeners.forEach(fn => fn());

    this._focusInput();
    this.onDeleteTag(tag);
  }

  _subscribe(fn) {
    fn();

    this.listeners.add(fn);

    return () => this.listeners.delete(fn);
  }

  _focusInput(clearInput) {
    const search = document.getElementsByClassName(styles.wrapper)[0];

    if (search && clearInput) {
      search.lastElementChild.value = '';
    }

    if (search) {
      search.lastElementChild.focus();
    }
  }

  defaultKeyTemplate(tag, key) {
    return html`<span>${tag[key]}</span>`;
  }

  get renderSearch() {
    return directive(() => part => {
      this._subscribe(() => {
        part.setValue(this.search);
        part.commit();
      });
    });
  }

  get renderTags() {
    return directive(() => part => {
      this._subscribe(() => {
        part.setValue(this.tagsTemplate);
        part.commit();
      });
    });
  }

  tagTemplate(tag) {
    return html`
      <span class="${styles.tag}">
        ${repeat(this.keys, key => key.property, key => key.property ? key.template(tag, key.property) : '')}

        <button @click="${() => this._delete(tag)}">${Icons.cross()}</button>
      </span>
    `;
  }

  get tagsTemplate() {
    return repeat(this.tags, tag => tag.id, tag => this.tagTemplate(tag));
  }

  get search() {
    return html`
      <input
        name="${this.name}"
        type="text"
        ?disabled=${this.tags.length === this.limit}
        placeholder="${this.tags_map.size ? '' : this.placeholder}"
        @input=${evt => this.onChange(evt)}
        />
    `;
  }

  get template() {
    return html`
      <div class="${styles.wrapper} tag-search">
        ${this.renderTags()}
        ${this.renderSearch()}
      </div>
    `;
  }
}

export default TagInput;
