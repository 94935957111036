import { Icons, NotJoined } from 'components/lit';
import { Observe } from 'decorators';
import GeneralDirectives from 'directives/general';
import { IMessage } from 'interfaces';
import { html, nothing } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';
import { repeat } from 'lit-html/directives/repeat';
import { Auth } from 'services';
import MessagesController from './messages.controller';
import MessagesModule from './messages.module';
import styles from './messages.module.scss';

class MessagesView {
  public controller: MessagesController;
  public module: MessagesModule;
  public vm: GeneralDirectives;

  get wave() {
    const { handleSendWave } = this.controller;

    return html`
      <div class="${styles.wave}">
        <div>
          ${Icons.wave}
          <span>This is the start of your chat.</span>
          <span>Click wave to say a quick hello or type a message below.</span>
          <button class=${styles.wave_button} @click=${() => handleSendWave()}>Wave</button>
        </div>
      </div>
    `;
  }

  public message(message: IMessage) {
    const isSender = !message.sender || message.sender.id === Auth.getUserId();
    const {
      onContextOpen,
      onContextClose,
      handleCommand,
      handleUrl,
      handleDeleteMessage,
      handleReportMessage,
    } = this.controller;

    return html`
      <div class="${isSender ? styles.own_group_item : ''} ${styles.group_item}">
        <wwc-message-context
          @open=${(e) => onContextOpen(e)}
          @close=${(e) => onContextClose(e)}
          @delete=${(evt) => handleDeleteMessage(evt)}
          @report=${(evt) => handleReportMessage(evt)}
        >
          <wwc-message
            @url=${(evt) => handleUrl(evt)}
            @command=${(evt) => handleCommand(evt)}
            .type="${message.attributes && !message.sender ? 'alert' : 'message'}"
            message=${JSON.stringify(message)}
            ?myMessage=${ifDefined(isSender)}
          > </wwc-message>
        </wwc-message-context>
      </div>
    `;
  }

  public fetchMore() {
    const { getCursor, handleFetchMore, isFetching } = this.controller;
    const { pageInfo: { hasPreviousPage } } = getCursor;

    return hasPreviousPage ? html`
      <div class="${styles.fetch_wrapper}">
        ${isFetching ? html`
          <div class="${styles.fetching_message}">
            Loading older messages
            ${Icons.loader}
          </div>
        ` : html`
          <button class="${styles.fetch_button}" @click="${() => handleFetchMore()}">
            Load older messages
            ${Icons.arrowDown}
          </button>
        `}
      </div>
    ` : nothing;
  }

  public groupDate(date) {
    const { formatDate } = this.controller;

    return html`
      <div class="${styles.date_wrapper}">
        <div class="${styles.date}">${formatDate(date)}</div>
      </div>
    `;
  }

  public messageGroup(group: [string, IMessage[]]) {
    const [date, messages] = group;

    return html`
      <div class="${styles.group_wrapper}">
        ${this.groupDate(date)}
        ${repeat(messages, (message) => message.id, this.message.bind(this))}
      </div>
    `;
  }

  @Observe('isFetching')
  get messages() {
    const { groupedMessages } = this.controller;

    return groupedMessages.size ? html`
      <div class="${styles.wrapper}" id="messages-list">
        ${this.fetchMore()}
        ${repeat(groupedMessages, ([key]) => key, this.messageGroup.bind(this))}
      </div>
    `
    : this.wave;
  }

  get loader() {
    return html`
      <div class="${styles.loader_wrapper}">
        ${Icons.messageLoaderLeft}
        ${Icons.messageLoaderRight}
      </div>
    `;
  }

  @Observe('isMember')
  get initialLoader() {
    const { isMember } = this.controller;

    return isMember ? this.messages : NotJoined('chat');
  }

  @Observe('isLoading')
  get template() {
    const { isLoading } = this.controller;

    return isLoading ? this.loader : this.initialLoader;
  }
}

export default MessagesView;
