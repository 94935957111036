import React from 'react';
import { Router } from 'services/router.service';
import PropTypes from 'prop-types';

function Link({ href, children, ...props }) {
  return (
    <a
      href={ Router.generate(href) }
      onClick={(e) => {
        e.preventDefault();
        Router.go(href);
      }}
      {...props}
    >{children}</a>
  );
}

Link.propTypes = {
  href: PropTypes.object,
  children: PropTypes.any,
};

export default {
  ReactLink: Link,
  link(evt, route) {
    evt.preventDefault();
    route && this.router.go(route);
  },
};
