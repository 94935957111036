import { Observe } from 'decorators';
import { showIf } from 'directives';
import { IOutstandingTask, IOutstandingTaskButton } from 'interfaces';
import { html } from 'lit-html';
import MarkdownIt from 'markdown-it';
import emoji from 'markdown-it-emoji';
import buttonStyles from 'styles/button.module.scss';
import cardStyles from 'styles/card.module.scss';
import DashTasksController from './dash-tasks.controller';
import styles from './dash-tasks.module.scss';

const md = new MarkdownIt();
md.use(emoji, []);

class DashTasksTemplate {
  public controller: DashTasksController;

  get empty() {
    return html`
      <div class=${styles.empty}>
        <span class=${styles.emoji}>🎉</span>
        You're all set for now!
      </div>
    `;
  }

  public button(button: IOutstandingTaskButton, points: string) {
    const { handleTaskAction } = this.controller;

    return html`
      <button
        class=${buttonStyles.small}
        @click=${() => handleTaskAction(button)}
      >
        ${button.text}
        ${showIf(points, html`<span class=${buttonStyles.badge}>${points}</span>`)}
      </button>
    `;
  }

  public task({ id, points, text, button }: IOutstandingTask) {
    return html`
      <div class=${cardStyles.item}>
        <p .innerHTML=${md.render(text)}></p>
        <div class=${cardStyles.actions}>
          ${this.button(button, points)}
        </div>
      </div>
    `;
  }

  public list(tasks: IOutstandingTask[]) {
    return html`
      <div class=${cardStyles.list}>
        ${tasks.map((task) => this.task(task))}
      </div>
    `;
  }

  @Observe('tasks')
  get template() {
    const { tasks } = this.controller;

    return html`
      <div class=${cardStyles.card}>
        <div class=${cardStyles.header}>
          <span class=${cardStyles.label}>Outstanding tasks</span>
        </div>
        <div class=${cardStyles.content}>
          ${tasks.length ? this.list(tasks) : this.empty}
        </div>
      </div>
    `;
  }
}

export default DashTasksTemplate;
