import apollo from 'api';
import CenterPromisifiedModal from 'components/lit/modals/center-promisified-modal.view';
import cancelMutation from '../../../graphql/mutations/cancelAction.graphql';
import declineMutation from '../../../graphql/mutations/declineActionInvitation.graphql';
import ActionsQuery from '../../../graphql/queries/actions.graphql';

class CancelActionModal extends CenterPromisifiedModal {
  onOpen() {
    this.pending = false;
    this.title = this.isOrganizer ? 'Cancel event?' : 'Decline event?';
    this.text = `This will ${this.isOrganizer ? 'cancel your' : 'decline this'} event, are you sure?`;
    this.submitBtnText = 'Yes';
    this.cancelBtnText = 'No';

    this.template = this.wrap(this.tmp);
  }

  submitForm() {
    this.pending = true;

    apollo
      .mutate({
        mutation: this.isOrganizer ? cancelMutation : declineMutation,
        variables: { command: { id: this.id } },
        refetchQueries: [{
          query: ActionsQuery,
        }],
      })
      .then(() => this.resolve(true))
      .catch(e => this.reject(e));

    this.destroy();
  }

  async open(id, isOrganizer) {
    return new Promise((resolve, reject) => {
      this.id = id;
      this.reject = reject;
      this.resolve = resolve;
      this.isOrganizer = isOrganizer;
      super.open();
    });
  }

  onDestroy() {
    !this.pending && this.resolve(false);
  }
}

export default CancelActionModal;
