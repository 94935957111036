import apollo from 'api';
import { RouterModule } from 'services/router/router.module';
import ChatFlowTemplate from './chat-flow.view';
import ChatFlowDirective from './chat-flow.directives';
import ChatFlowController from './chat-flow.controller';
import styles from './chat-flow.module.scss';
import initData from './chat-flow.data';
import ChatQuery from '../../graphql/queries/chat/Chat.graphql';

class ChatFlowModule extends RouterModule {
  async mount() {
    if (!this.router.state || !this.router.state.left) {
      this.router.deleteState('left');

      return;
    }
    const { tab, text, single, chatId } = this.router.state.left;
    const storedData = this.module.store.get('chat_flow');
    let chat;

    if (!chatId) {
      chat = storedData;
    } else {
      const { data: { chat: apolloChat } } = await apollo.query({
        query: ChatQuery,
        variables: {
          id: chatId,
        },
      });

      chat = {
        ...apolloChat,
        selectedScreen: storedData ? storedData.selectedScreen : tab,
      };
    }

    const data =  initData(chat);
    const baseData = { ...data, type: text, selectedScreen: single ? tab : data.selectedScreen || tab };
    const chat_flow = new ChatFlowTemplate(this.module, baseData);

    chat_flow.controller = ChatFlowController;
    chat_flow.directives = ChatFlowDirective;
    chat_flow.styles = styles;

    this.manageLitMount(chat_flow.template, this.router.display === 'mobile' ? 'chat' : 'left');

    // const unsubscribe = this.router.subscribeOnResizing(() => {
    //   const { controller, data } = chat_flow;
    //   this.module.store.set('chat_flow', { ...controller.chat, selectedScreen: data.selectedScreen });
    // });
    //
    // this.addSubscription(unsubscribe);
  }
}

export default ChatFlowModule;
