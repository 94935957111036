import { html, directive } from 'lit-html';
import styles from './preloader.module.scss';
import image from './logo.png';

const preloadAnimation = () =>
  html`<div class=${styles.preload_animation}>
    <div class=${styles.loading_bar}></div>
    <div class=${styles.loading_bar}></div>
    <div class=${styles.loading_bar}></div>
    <div class=${styles.loading_bar}></div>
  </div>`;

const progressBar = html`
  <div class="${styles.progress_bar}"></div>
`;

const statusBar = status =>
  html`
    <div class="${styles.status}">${status}</div>
  `;

const preloader = document.getElementById('preloader');
const loading = directive(() => part => {
  part.setValue(progressBar);

  function setProgress(progress) {
    const progressBar = document.getElementsByClassName(styles.progress_bar)[0];
    progressBar.style.setProperty('--progressBarWidth', progress + '%');
  }

  let interval;
  let progress = 0;

  preloader.addEventListener('loading', handleLoading);

  function handleLoading({ detail: { loading } }) {
    if (loading) {
      interval = setInterval(() => setProgress(progress < 90 ? ++progress : 90 ), 100);
    } else {
      clearInterval(interval);
      progress = 100;
      setProgress(progress);
    }
  }
});

const status = directive(() => part => {
  preloader.addEventListener('loading_state', handleLoadingState);

  function handleLoadingState({ detail: { state } }) {
    part.setValue(statusBar(state));
    part.commit();
  }
});

export default () =>
  html`
    <div class="${styles.preloader}">
      ${loading()}
      <img src="${image}" alt="">
      ${preloadAnimation()}
      ${status()}
    </div>
  `;
