class ErrorHandler {
  constructor() {
    const proto = Object.getPrototypeOf(this);
    const names = Object.getOwnPropertyNames(proto);

    names.forEach(name => {
      if (name === 'constructor') { return; }

      const descr = Object.getOwnPropertyDescriptor(proto, name);

      if (!descr.set && descr.get) { return; }

      this[name] = (...args) => {
        try {
          return proto[name].call(this, ...args);
        } catch (e) {
          console.error('Caught Error By Error Handler layer ', e);
        }
      };
    });

  }
}

export default ErrorHandler;
