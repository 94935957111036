import Preloader from './preloader.view';
import { manageMount } from 'lib/lit-components-manager';

export default {
  mount() {
    const el = document.getElementById('preloader');
    const App = document.getElementById('App');

    el.setAttribute('style', 'display: flex');
    App.setAttribute('style', 'position: fixed;');

    manageMount(Preloader(), 'preloader');
  },

  unmount() {
    const preloader = document.getElementById('preloader');
    const clonePreloader = preloader.cloneNode(true);
    const App = document.getElementById('App');

    clonePreloader.setAttribute('style', 'display: none');
    document.body.replaceChild(clonePreloader, preloader);

    App.setAttribute('style', 'position: relative;');
  },
};
