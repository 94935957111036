import { ValidationStrategy } from 'components/custom/enums';
import { TermsFooter } from 'components/lit';
import { Observe } from 'decorators';
import { IInput } from 'interfaces';
import { html, nothing } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';
import { repeat } from 'lit-html/directives/repeat';
import { Router } from 'services/router.service';
import RegisterController from './register.controller';
import RegisterModule from './register.module';
import styles from './register.module.scss';

const INPUTS = [
  {
    label: 'Email Address',
    type: 'email',
    placeholder: 'Email Address',
    name: 'emailAddress',
    disabled: true,
    validationStrategy: ValidationStrategy.off,
  },
  {
    autofocus: true,
    label: 'First Name',
    type: 'text',
    placeholder: 'First name',
    name: 'firstName',
    validationStrategy: ValidationStrategy.delay,
  },
  {
    label: 'Last Name',
    type: 'text',
    placeholder: 'Last name',
    name: 'lastName',
    validationStrategy: ValidationStrategy.delay,
  },
];

class RegisterView {
  public controller: RegisterController;
  public module: RegisterModule;

  public get template() {
    return html`
      <div class="${styles.wrapper}">
       <form @changeForm="${(e) => this.controller.onChange(e)}" @submit="${(e) => this.controller.onSubmit(e)}">
          ${this.inputs}
          ${this.submitButton}
        </form>
        ${ TermsFooter }
        ${this.error}
      </div>
    `;
  }

  @Observe('error')
  public get error() {
    const { error } = this.controller;

    return !!error ? html`<div class="${styles.error}">${error}</div>` : nothing;
  }

  public get inputs() {
    return repeat(INPUTS, (i) => i.name, (i) => this.input(i));
  }

  public input(ops: IInput) {
    const value = ops.type === 'email' ? Router.state.email : undefined;

    return html`
      <w-validation-box
        name="${ops.name}"
        label="${ops.label}"
        strategy=${ops.validationStrategy}
        outsideerror="${ops.catchError ? this.controller.error : undefined}"
      >
        <w-input
          autocomplete="new-password"
          autofocus=${ifDefined(ops.autofocus)}
          ?disabled=${ops.disabled}
          value="${ifDefined(value)}"
          type="${ops.type}"
          name="${ops.name}"
          placeholder="${ops.placeholder}"
          vRequired
          ?vName=${ops.type === 'text'}
          ?vEmail=${ops.type === 'email'}
          >
      </w-validation-box>
    `;
  }

  @Observe('valid', 'loading')
  private get submitButton() {
    const { valid, loading } = this.controller;
    const text = loading ? 'Loading...' : 'Register';

    return html`<button class="w-button primary" type="submit" ?disabled="${!valid || loading}">${text}</button>`;
  }
}

export default RegisterView;
