import { directive, html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import { showIf } from 'directives';
import controller from './search.controller';
import styles from './search.module.scss';
import { Icons, loader } from 'components/lit';
import difference from 'lodash/difference';

const searchProperties = {
  name: ['name', 'alias'],
  location: ['location'],
  interests: ['skills'],
  group: ['title'],
  tags: ['tag'],
};

const tabs = html`
  <div class="${styles.tabs}">
    ${repeat(Object.keys(searchProperties), key => tab(key))}
  </div>
`;

const tab = key => {
  const selected = !difference(searchProperties[key], controller.searchProperty).length;

  return html`
  <div @click="${() => controller.searchProperty = key}" class="${selected ? styles.selected : ''}">
    By ${key}
  </div>`;
};

const list = (results) => html`
  <div class="${styles.list}">
    ${repeat(results, item => Item(item))}
  </div>
`;

const Item = item => html`
  <div @click="${() => item.name ? controller.navToProfile(item.id) : controller.navToGroup(item.id)}">
    <div>
      <w-avatar avatar="${item.avatar || item.icon}" title="${item.name || item.title}" width="45px"> </w-avatar>
    </div>
    <div>
      <h1>${item.name || item.title}</h1>
      ${showIf(item.skills, html`<span>${item.skills}</span>`)}
      ${showIf(item.location, html`<span>${item.location}</span>`)}
      ${showIf(item.tag, html`<span>${item.tag}</span>`)}
    </div>
  </div>
`;

const emptyState = html`
  <div class="${styles.search}">
    ${Icons.search}

    <h4>No results found.</h4>
    <h4>Please try a different search.</h4>
  </div>
`;

const container = (results) =>
  html`
    <div class="${styles.wrapper}">
      ${tabs}

      ${showIf(results.length, html`<h1 class="${styles.title}">${results.length} results</h1>`)}

      ${results.length ? list(results) : emptyState}
    </div>
  `;

const loadingState = html`
  <div class="${styles.loader_wrapper}">
    ${loader}
  </div>
`;

export default directive(() => part => {
  part.setValue(loadingState);
  document.getElementById('search').style.zIndex = '9999';

  controller.results.then(results => {
    if (results) {
      part.setValue(container(results));
    } else {
      part.setValue(nothing);
      document.getElementById('search').style.zIndex = '';
    }

    part.commit();
  });
});
