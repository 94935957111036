import ActionsModule from 'modules/ActionFlow';
import DirectController from 'controllers/direct';

class ScheduleController extends DirectController {
  controller() {
    super.controller();

    this.addModule(ActionsModule);
  }
}

export default ScheduleController;
