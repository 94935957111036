import people from 'assets/people.svg';
import { Icons } from 'components/lit';
import { Observe } from 'decorators';
import { html } from 'lit-html';
import NoMatchController from './no-match.controller';
import styles from './no-match.module.scss';

class NoMatchTemplate {
  public controller: NoMatchController;

  @Observe('loading')
  get getStartedButton() {
    const textContent = this.controller.loading ?  'Loading...' : 'Get started';

    return html`
      <button
        ?disabled=${this.controller.loading}
        @click=${() => this.controller.getStarted()}
      >
        ${textContent}
      </button>
    `;
  }

  get template() {
    return html`
      <div class="${styles.wrapper}">
        <div class="${styles.items}">
          <h1>Welcome to WERKIN</h1>
          <h2>While you wait for your match to register, get to know your mentoring community:</h2>
          <div class="${styles.item}">
            ${Icons.matchStar}
            Say hello in the ‘all member’s’ channel and get involved in the mentoring conversation.
          </div>
          <div class="${styles.item}">
            ${Icons.matchStar}
            Start a public or private group to discuss a specific challenge or topic.
          </div>
          <div class="${styles.item}">
            ${Icons.matchStar}
            Search for other members by name, location and interests and see how you can help each other.
          </div>

          ${this.getStartedButton}
        </div>

        <img alt="people" src="${people}">
      </div>
    `;
  }
}

export default NoMatchTemplate;
