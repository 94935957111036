import apollo from 'api';
import { observable } from 'decorators';
import { IUser } from 'interfaces';
import PublicProfileModule from 'modules/ProfilePublic/public-profile.module';
import { StatusesService } from 'services';
import auth from 'services/auth.service';
import { Router } from 'services/router.service';
import { errorHandler } from 'utils/error-helpers';
import findDirectChatQuery from '../../graphql/queries/findDirectChat.graphql';
import IndividualQuery from '../../graphql/queries/individual.graphql';

class PublicProfileController {
  public module: PublicProfileModule;
  public isModal = false;

  @observable(null, true) public user: IUser;
  @observable([]) public activeSocialMedia: any[];
  @observable(false, true) public hasError: boolean;

  constructor() {
    this.handleChatRoute = this.handleChatRoute.bind(this);
  }

  public async OnInit() {
    await this.fetchUser();

    const routerUnsubscribe = Router.listen(async () => {
      await this.fetchUser();
    });

    this.module.subs.add(routerUnsubscribe);
  }

  public async fetchUser() {
    const userId = Router.params.userId || Router.hash.userId;

    if (!userId) { return; }
    try {
      const { data: { individual } } = await apollo.query({
        query: IndividualQuery,
        variables: {
          id: userId,
        },
      });

      StatusesService.subscribe(this.constructor.name, [userId]);

      return this.user = individual;
    } catch (err) {
      this.hasError = true;
      console.error(err);
      errorHandler(err, true);
    }
  }

  public async handleChatRoute(route: string) {
    const { data: { findDirectChat } } = await apollo.query({
      query: findDirectChatQuery,
      variables: {
        createIfNotExists: true,
        participantIds: [this.user.id, auth.getUserId()],
      },
    });

    Router.deleteHash('IndividualModal', 'userId', 'ShareGoals');
    Router.go({ name: route, params: { chatId: findDirectChat.chat.id } });
  }

  public formattedUrl(url: string) {
    try {
      return new URL(url).href;
    } catch {
      return `https://${url}`;
    }
  }

  public getWebsiteURL(type, value) {
    try {
      return new URL(value);
    } catch {
      const urls = {
        twitter: `https://twitter.com/${value}`,
        facebook: `https://www.facebook.com/${value}`,
        instagram: `https://www.instagram.com/${value}`,
        linkedin: `https://www.linkedin.com/in/${value}`,
      };

      return urls[type];
    }
  }

  public get firstName() {
    return this.user.name.split(' ')[0];
  }
}

export default PublicProfileController;
