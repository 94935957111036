import { IFeedItemButton } from 'interfaces';
import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import styles from 'modules/Activity/activity.module.scss';
import { Router } from 'services/router.service';
import ActivityTemplate from '../activity.view';
import { IActivityFragmentProps } from './fragment.interface';

class SimpleItem {
  public view: ActivityTemplate;

  constructor() {
    this.template = this.template.bind(this);
  }

  public internalButton({ text, value }) {
    const route = (): void => Router.go({ path: value });

    return html`
      <button class=${styles.item_button} @click=${route}>${text}</button>
    `;
  }

  public template({ item, loading }: IActivityFragmentProps) {
    const {
      created,
      content: {
        text,
      },
      buttons,
      id,
      likes,
    } = item;

    const buttonTypes = {
      url: this.internalButton,
      command: null,
    };

    return html`
      <div class=${styles.feed_item}>
        ${this.view.itemHead({ created, text })}
        <div class=${styles.item_footer}>
          ${this.view.social({ id, likes }, loading)}
          <div class=${styles.item_button_group}>
            ${repeat(buttons, (button: IFeedItemButton) => button.value, (button: IFeedItemButton) => {
              if (!button.text || !button.value) { return null; }

              return buttonTypes[button.type](button);
            })}
          </div>
        </div>
      </div>
    `;
  }
}

export default SimpleItem;
