import { RouterModule } from 'services/router/router.module';

import MobileWrapper from 'modules/Help/MobileWrapper';
import Wrapper from 'modules/Help/Wrapper';

const Wrappers = {
  'mobile': MobileWrapper,
  'desktop': Wrapper,
};

class HelpModule extends RouterModule {
  mount(...props) {
    this.manageReactMount(Wrappers[this.router.display], 'center', ...props);
  }

  unmount() {
    this.manageReactUnmount('center');
  }
}

export default HelpModule;
