import React from 'react';
import styles from './TechnicalRequirements.module.scss';
import Detector, { browserState } from 'utils/browser-detection';
import { decodeJWT, upperFirst } from '@werkin/lib/helpers';
import auth from 'services/auth.service';
import Button from 'atoms/Button';

const TechnicalRequirements = () => {

  const [isOpen, setOpen] = React.useState(false);
  const [allowed, setAllowed] = React.useState(false);

  const detector = new Detector();
  const detected = detector.current;

  const onAssess = () => {
    setAllowed(detector.isSupportedBrowser === browserState.supported);

    setOpen(true);
  };

  const getLink = () => {

    const email = decodeJWT(auth.getToken('idToken')).email;

    let message;

    if (detected) {
      message =
        `My email address is: ${email}

        My browser details are: ${upperFirst(detected.name)} V${detected.version} ${detected.os}

        Write any additional questions or information here:

        `;
    } else {
      message =
        `My email address is: ${email}

        My browser details are: Sorry, we have been unable to detect your browser

        Write any additional questions or information here:
        `;
    }

    const subject = 'WERKIN Technical Requirements Report';

    const body = encodeURIComponent(message);

    return `mailto:support@getwerkin.com?subject=${subject}&body=${body}`;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className='mt-0'>Is my browser supported?</h2>

        <h2>Minimum technical requirements</h2>

        <span>
          To use WERKIN as intended you need to access it with one of the available supported browsers: Chrome
          for Desktop and Android (71 and above), or iOS Safari on iPhone (12 and above). While Microsoft Edge
          is not officially supported, the app will work on versions 17+ although you may experience some degradation
          in layouts.
        </span>

        <span>
          Access this page with all the devices (both desktop and mobile) that
          you will be using the WERKIN platform with and follow the
          instructions for “What is my Browser?”. We can assess your Operating
          System, Browser and Browser Version and advise any required changes.
        </span>

        {
          isOpen ?
            <div className={styles.card}>
              <h3>What is my browser?</h3>

              {detected ?
                <>
                  <span>Below is the technical file set up for your current device with this browser:</span>

                  <h4>Operating System</h4>
                  <span>{detected.os}</span>

                  <h4>Browser</h4>
                  <span>{upperFirst(detected.name)}</span>

                  <h4>Browser version</h4>
                  <span>{detected.version}</span>
                </>
                :
                <h5>Sorry, we have been unable to detect your browser</h5>
              }
            </div>
            :
            <div className={styles.block}>
              <h3>What is my browser?</h3>
              <span>Click the `Assess` button to have WERKIN evaluate your technical set up.</span>

              <Button variant='primary' onClick={onAssess}>Assess</Button>
            </div>
        }

        {
          isOpen &&
          <>
            <h2>What do I need to do now?</h2>

            {allowed ?
              <>
                <h3>Absolutely nothing, you are good to go.</h3>

                <span>
                  Just a heads up about a few other technical bits and pieces. You will
                  be able to install WERKIN like an app. Just look for the ‘Install icon’
                  button when you are using it.
                </span>

                <span>
                  WERKIN asks for permission to sync with your calendar which means
                  you can see all your appointments in one place. It also means you can
                  see available slots in someone’s calendar when scheduling something.
                  It prevents the usual back and forth - a real time saver.
                </span>

                <span>
                  We will also ask permission to send you notifications about important
                  stuff like new messages from your mentoring match, meeting
                  requests and connections to people you should meet. You’ll be able to
                  manage all this in your settings.
                </span>

                <span>
                  Happy mentoring!
                </span>
              </>
              :
              <>
                <h3>You need to consider upgrading</h3>

                <span>
                  Your current browser or device does not meet the minimum specifications to run Werkin.
                  Although some functionality may work we recommend upgrading on (platform) and to a
                  minimum browser version of (version) for the best experience using the application.
                </span>

                <span>
                  Please reach out to us at <a href={getLink()}>support@getwerkin.com</a> if we can advise further.
                </span>

                <span>
                  Also, just a heads up about a few other technical bits and pieces. You will
                  be able to install WERKIN like an app. Just look for the ‘Install icon’
                  button when you are using it.
                </span>

                <span>
                  WERKIN asks for permission to sync with your calendar which means
                  you can see all your appointments in one place. It also means you can
                  see available slots in someone’s calendar when scheduling something.
                  It prevents the usual back and forth - a real time saver.
                </span>

                <span>
                  We will also ask permission to send you notifications about important
                  stuff like new messages from your mentoring match, meeting
                  requests and connections to people you should meet. You’ll be able to
                  manage all this in your settings.
                </span>

                <span>
                  Happy mentoring!
                </span>
              </>
            }
          </>
        }
      </div>
    </div>
  );
};

export default TechnicalRequirements;
