import apollo from 'api';
import Controller from 'services/templator/controller';
import { nav } from 'modules/ActionFlow/action-flow.data';
import _sortBy from 'lodash/sortBy';
import isNaN from 'lodash/isNaN';
import { Auth, REST } from 'services';
import createAction from '../../graphql/mutations/createAction.graphql';
import updateAction from '../../graphql/mutations/updateAction.graphql';
import ChatQuery from '../../graphql/queries/chat/Chat.graphql';
import ActionsQuery from '../../graphql/queries/actions.graphql';
import { NotificationManager } from 'services';
import profileService from 'services/data/profile.service';

const TOAST_DATE_FORMAT = 'dddd, D MMMM, hh:mm A';

class ActionFlowController extends Controller {
  onInit() {
    this._initState();
    this._calendarAccess();
    this._getTimezones();
    this._initCurrentUserTimezone();
    this._getActionParticipants();
    this._clearLocalStorage();
  }

  save() {
    this._clearLocalStorage();
    this.setStateByKey('loading', true);

    const { moment, apollo } = this.module;
    const date = moment(this.data.date).tz(this.data.timezone).utc();
    const startTime = date.toISOString();
    const endTime = moment(this.data.date).add(this.data.duration.value, this.data.duration.entity).toISOString();
    const organizerId = Auth.getUserId();
    const data = {
      subType: this.data.type,
      details: this.data.details.value,
      location: this.data.location.value,
      startTime,
      endTime,
      chatId: this.router.params.chatId,
      timezone: this.data.timezone,
      source: this.data.source,
      messageId: this.data.messageId,
      cronofyView: this.data.cronofyView,
      link: `${document.location.origin}/chats/${this.router.params.chatId}/actions/${this.data.id}/info`,
    };
    const command = {
      data: JSON.stringify(data),
      id:  this.data.id,
      organizerId,
      notes:  this.data.notes.value,
      type: 'scheduled',
      participantIds:  [...this.data.participants.map(({ id }) => id), organizerId],
      title:  this.data.title.value,
    };

    apollo.mutate({
      mutation: this.state.isNew ? createAction : updateAction,
      variables: { command },
      refetchQueries: [{
        query: ActionsQuery,
      }],
    })
      .then(({ data: { [this.state.isNew ? 'createAction' : 'updateAction']: { command } } }) => {
        const { startTime } = JSON.parse(command.data);

        this.module.store.set('selectedDate', startTime);

        NotificationManager.showNotification(
          { type: 'success', text: `The action was successfully ${this.state.isNew ? 'created' : 'updated'}` }
        );

        this.setStateByKey('loading', false);
        this.router.go({ name: 'chats.actions.list', params: this.router.params, state: { date: this.data.date } });
      }).catch((err) => {
        console.log(err);
        NotificationManager.showNotification(
          { type: 'error', text: `The action wasn't ${this.state.isNew ? 'created' : 'updated'}. Server error` }
        );
        this.setStateByKey('loading', false);
      });
  }

  setFormattedDate({ newDate, existingDate }) {
    const { moment } = this.module;
    const { timezone } = this.data;
    const date = moment(newDate || new Date()).tz(this.data.timezone).utc();
    const dayOfWeek = moment(date).weekday();
    const coeff = 1000 * 60 * 15;

    if (existingDate) {
      this.setDataByKey('date', moment(existingDate).tz(timezone).utc().toDate());
    } else {
      [6, 7].includes(dayOfWeek)
      ? date.weekday(8)
      : date.add(15, 'minute');

      this.setDataByKey('date', new Date(Math.ceil(date.toDate().getTime() / coeff) * coeff));
    }

    this.setDataByKey('minDate', new Date(Math.ceil(date.toDate().getTime() / coeff) * coeff));
  }

  back() {
    const { nav, selectedScreen } = this.state;
    const { prevScreen } = nav[selectedScreen];

    if (!prevScreen) {
      return this.router.back();
    }

    this.setStateByKey('selectedScreen', prevScreen);
    this.setStateByKey('allowNext', true);
  }

  next() {
    const { nav, selectedScreen } = this.state;
    const { nextScreen } = nav[selectedScreen];
    const start = new Date();
    const selected = new Date(this.data.date);

    if (nextScreen === 'details' && start > selected) {
      this.setFormattedDate({
        newDate: new Date(),
      });

      NotificationManager.showNotification(
        { text: `Proposed ${this.data.type} start time has elapsed.
                 Your ${this.data.type} has been moved to
                 <b>${this.module.moment(this.data.date).format(TOAST_DATE_FORMAT)}</b>`,
        type: 'warning',
        duration: 6000,
        }
      );

      return;
    }

    if (nextScreen === 'actions_list') {
      return this.save();
    }

    if (nextScreen) {
      nextScreen === 'details' && this.isValidTitle();
      this.setStateByKey('selectedScreen', nextScreen);
    }
  }

  selectOption(option, nextScreen) {
    this.setStateByKey('selectedOption', option);
    this.setStateByKey('allowNext', true);
    this.setStateByKey('nav.scheduling_options.nextScreen', nextScreen);
    this.setStateByKey('selectedScreen', nextScreen);
    this.setDataByKey('type', this.state.selectedOption);
  }

  chooseTimezone() {
    this.setStateByKey('selectedScreen', 'select_timezone');
  }

  selectTimezone(tz) {
    const { moment } = this.module;
    const date = moment(this.data.date).tz(tz).utc().format();

    this.setDataByKey('timezone', tz);
    this.setDataByKey('date', date);
    this.back();
  }

  showHideParticipantsTzs() {
    this.setStateByKey('action', this.state.action === 'show' ? 'hide' : 'show');
  }

  onTimeZoneSearch(evt) {
    const { value } = evt.target;
    const filteredTimezones = this.state.timezones.filter((tz) =>
      tz.name.toUpperCase().includes(value.toUpperCase())
            || tz.offset.toUpperCase().includes(value.toUpperCase()));

    this.setStateByKey('filteredTimezones', filteredTimezones);
  }

  isValidTitle() {
    const hasLength = this.data.title.value.length;

    if (this.data.title.touched) {
      this.setDataByKey('title.valid', !!hasLength);
      this.setDataByKey('title.error', !hasLength ? 'Title is required' : null);
    }

    this.setStateByKey('allowNext', hasLength);
  }

  onMinLimitReached() {
    const date = this.module.moment(this.data.minDate).tz(this.data.timezone).format(TOAST_DATE_FORMAT);

    NotificationManager.showNotification({
      type: 'warning',
      text: `The earliest available date is <b>${date}</b>`,
      duration: 8000,
    });
  }

  onDateTimeChange(date) {
    this.setDataByKey('date', this.module.moment(date).format());
  }

  onChangeDuration(duration) {
    this.setDataByKey('duration', duration);
  }

  onTabChange(tab) {
    this.setStateByKey('dateTimeTab', tab);
  }

  onDateChange(newDate) {
    const calcDate = new Date(this.data.date);
    const newD = new Date(newDate);
    const minD = new Date(this.data.minDate);
    const isSameYear = newD.getFullYear() === minD.getFullYear();
    const isSameMonth = newD.getMonth() === minD.getMonth();
    const isSameDate = newD.getDate() === minD.getDate();
    const isLowerHrsThanMin = newD.getHours() < minD.getHours();
    const isLowerMinutesThanMin = newD.getMinutes() < minD.getMinutes();
    const isLowerThanMin = isSameYear && isSameMonth && isSameDate && (isLowerHrsThanMin || isLowerMinutesThanMin);

    calcDate.setFullYear(newD.getFullYear());
    calcDate.setMonth(newD.getMonth());
    calcDate.setDate(newD.getDate());

    if (isLowerThanMin) {
      calcDate.setHours(minD.getHours());
      calcDate.setMinutes(minD.getMinutes());
    }

    this.onDateTimeChange(calcDate);
  }

  changeCalendarAccess(modal) {
    modal.open();

    modal.subscribe(() => {
      window.localStorage.setItem('meet_up', JSON.stringify(this.data));
      window.localStorage.setItem('selected_screen', this.state.selectedScreen);
    }, 'submit');
  }

  _initState() {
    const selectedScreen = window.localStorage.getItem('selected_screen');

    this.setStateByKey('nav', nav());
    this.setStateByKey('action', 'show');
    this.setStateByKey('isNew', !this.router.params.actionId);
    this.setStateByKey('dateTimeTab', 'Date');
    this.setStateByKey('selectedScreen', selectedScreen || 'scheduling_options');
    this.setStateByKey('selectedOption', this.data.type || 'schedule');

    if (!this.data.id) {
      this.setDataByKey('id', this.new_uuid);
    }

    if (this.data.type) {
      this.setStateByKey('nav.scheduling_options.nextScreen', 'meet_time');
      this.setStateByKey('allowNext', true);
    }
  }

  async _calendarAccess() {
    const { individual } = await profileService.getProfileAndIndividual();

    this.setStateByKey('authorizedCalendarAccess', individual.authorizedCalendarAccess);
  }

  async _getTimezones() {
    const { moment } = this.module;
    const { data: { values } } = await REST.getSystemData();
    const timezones = values.filter((v) => v.category === 'time-zone');

    if (!timezones && !timezones.length) { return; }

    const formattedTimezones = _sortBy(timezones, 'value').map(tz => {
      let { value, offset } = tz;
      const abbr = moment.tz(value).zoneAbbr();
      offset = offset.startsWith('-') || offset === '00:00:00' ?  offset : '+' + offset;
      const formattedOffset = `${isNaN(+abbr) ? abbr : 'UTC'} ${offset}`;

      return {
        value,
        name: tz.value.split('_').join(' ').replace(new RegExp('/', 'g'), ' / '),
        offset: formattedOffset,
      };
    });

    this.setStateByKey('timezones', formattedTimezones);
    this.setStateByKey('filteredTimezones', formattedTimezones);
  }

  _initCurrentUserTimezone() {
    const { moment } = this.module;
    const { date, timezone } = this.data;

    !timezone && this.setDataByKey('timezone', moment.tz.guess(true));
    this.setFormattedDate({
      existingDate: date,
    });
  }

  async _getActionParticipants() {
    const { data: { chat } } = await apollo.query({
      query: ChatQuery,
      variables: {
        id: this.router.params.chatId,
      },
    });
    const profile = await profileService.getProfile();
    const members = chat.participants.filter(member => member.id !== profile.id);

    const participants = members.map(member => {
      const timezone = member.timeZone || 'Etc/Utc';

      return ({ id: member.id, name: member.name, timezone });
    });

    this.setDataByKey('participants', participants);
  }

  onChange(e) {
    const { name, value } = e.detail.current;
    this.setDataByKey(`${name}.value`, value);
    this.setStateByKey('allowNext', Array.from(e.target.controls).every(([, { valid }]) => valid));
  }

  _clearLocalStorage() {
    window.localStorage.removeItem('meet_up');
    window.localStorage.removeItem('selected_screen');
  }
}

export default ActionFlowController;
