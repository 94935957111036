import apollo from 'api';
import { IAlertButton, IAlertItem } from 'interfaces';
import { Router } from 'services/router.service';
import readAlertMutation from '../../graphql/mutations/readAlert.graphql';
import readAlertsMutation from '../../graphql/mutations/readAlerts.graphql';
import AlertsQuery from '../../graphql/queries/alerts.graphql';
import AlertsModule from './alerts.module';

import store from '@werkin/store';
import { isAfter, isThisWeek, isToday, isYesterday } from 'date-fns';
import { observable } from 'decorators';
import moment from 'lib/moment';
import AlertsService from 'services/data/alerts.service';

class AlertsController {
  public module: AlertsModule;
  @observable([]) public items: IAlertItem[];
  @observable(false) public loading: boolean;

  constructor() {
    this.handleAlert = this.handleAlert.bind(this);
    this.handleAlertButton = this.handleAlertButton.bind(this);
  }

  public OnInit() {
    let observe;

    this.viewAlertsOnMount();

    const unsubscribe = store.subscribe('isAlertsModal', (isAlertsOpen) => {
      if (!isAlertsOpen) {
        return observe && observe.unsubscribe();
      }

      observe = apollo.watchQuery({
        query: AlertsQuery,
      }).subscribe({
        next: ({ data: { alerts } }) => {
          this.items = alerts.items;
        },
        error: (e) => console.error(e),
      });

      this.module.subs.add(() => observe.unsubscribe());
    });

    this.module.subs.add(unsubscribe);
  }

  private viewAlertsOnMount() {
    AlertsService.viewAlerts();
  }

  get unreadAlertsIds() {
    return this.items.reduce((acc, { id, read }) => !read ? [...acc, id] : acc, []);
  }

  public sortedAlertsByCreate(items: IAlertItem[]) {
    return items
      .sort((a: IAlertItem, b: IAlertItem) => isAfter(new Date(a.createdAt), new Date(b.createdAt)) ? -1 : 1);
  }

  get alertsGroups() {
    return this.items.reduce((memo, alert: IAlertItem) => {
      if (!alert.createdAt) {
        return memo;
      }

      if (isToday(new Date(alert.createdAt))) {
        memo.Today.push(alert);

        return memo;
      }

      if (isYesterday(new Date(alert.createdAt))) {
        memo.Yesterday.push(alert);

        return memo;
      }

      if (isThisWeek(new Date(alert.createdAt))) {
        memo['This Week'].push(alert);

        return memo;
      }

      memo.Older.push(alert);

      return memo;
    }, { 'Today': [], 'Yesterday': [], 'This Week': [], 'Older': []});
  }

  public handleAlert(item: IAlertItem, link: string) {
    !item.read && apollo.mutate({
      mutation: readAlertMutation,
      variables: { command: { alertId: item.id } },
    });

    store.set('isAlertsModal', false);

    this.handleAlertLink(link);
  }

  public handleAlertButton(button: IAlertButton, item: IAlertItem) {
    const { value } = button;
    this.handleAlert(item, value);
  }

  public readAll() {
    this.loading = true;

    apollo.mutate({
      mutation: readAlertsMutation,
      variables: {
        command: {
          ids: this.unreadAlertsIds,
        },
      },
      refetchQueries: [{
        query: AlertsQuery,
      }],
    }).then(() => {
      this.loading = false;
    });
  }

  public formatDate(createdAt) {
    const date = new Date(createdAt);
    const dateFormat = isToday(date) || isYesterday(date) ? 'hh:mm A' : 'ddd DD MMM,  hh:mm A';

    return moment(createdAt).format(dateFormat);
  }

  private handleAlertLink(link) {
    try {
      const url = new URL(link);
      if (!url || !url.hostname) { throw Error(); }

      window.open(link, '_blank');
    } catch (err) {
      link && Router.go({ path: link });
    }
  }
}

export default AlertsController;
