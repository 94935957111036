import { render } from 'lit-html';
import ActionsListController from 'modules/ActionsList/actions-list.controller';
import ActionsListView from 'modules/ActionsList/actions-list.view';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';
import ActionUtils from 'utils/action-helpers';
const plugins = new Map();

plugins.set('action_utils', new ActionUtils());

@RouterModule({
  name: 'w-actions-list-module',
  view: ActionsListView,
  controller: ActionsListController,
  plugins,
})
class ActionsListModule {
  public subs: Set<() => void> = new Set([]);
  public view: ActionsListView;

  public async loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default ActionsListModule;
