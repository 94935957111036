import apollo from 'api';
import { ILeaderboard } from 'interfaces';
import { SignalR } from 'services';
import BaseService from 'services/data/base.service';
import leaderboardQuery from '../../graphql/queries/leaderboard.graphql';

class LeaderboardService extends BaseService {
  public subscribeLeaderboardUpdated(handler) {
    return SignalR.subscribe('CreditAwarded', handler);
  }

  public getLeaderboard(type: string) {
    return apollo
      .query({ query: leaderboardQuery, variables: { type }, fetchPolicy: 'no-cache' })
      .then(({ data: { leaderboard } }): ILeaderboard => leaderboard);
  }
}

export default new LeaderboardService();
