import { ValidationStrategy } from 'components/custom/enums';
import { loader } from 'components/lit';
import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import styles from '../join.module.scss';
import JoinTemplate from '../join.view';

class StepOneFragment {
  public view: JoinTemplate;

  get fields() {
    return   ['industry', 'sector', 'group', 'unit', 'title', 'stage', 'country'];
  }

  @Observe('onboardHeader')
  get header() {
    const { onboardHeader } = this.view.controller;

    return html`
     <div class="${styles.desc}">
        ${onboardHeader}
      </div>
    `;
  }

  @Observe('oneValid')
  get buttons() {
    return html`
    <div class="${styles.buttons}">
    <button class="w-button primary ${styles.next}" ?disabled="${!this.view.controller.oneValid}">Next</button>
    </div>
    `;
  }

  @Observe('stageOptions', 'stage')
  get stagesSelect() {
    return html`
      <w-validation-box label="Career stage*">
        <w-select
         name="stage"
         .selected=${this.view.controller.stage}
         vRequired
         .options=${this.view.controller.stageOptions}
         .placeholder="${'Select career stage'}"
         />
      </w-validation-box>
    `;
  }

  @Observe('industryOptions', 'industry')
  get industriesSelect() {
    return html`
      <w-validation-box label="Industry*">
        <w-select
         name="industry"
         .selected=${this.view.controller.industry}
         vRequired
         .options=${this.view.controller.industryOptions}
         .placeholder="${'Select industry'}"
         .empty="${'Industries not found'}"
         />
      </w-validation-box>
    `;
  }

  @Observe('sectorOptions', 'sector')
  get sectorSelect() {
    return html`
      <w-validation-box label="Sector*">
        <w-select
         name="sector"
         .selected=${this.view.controller.sector}
         vRequired
         .options=${this.view.controller.sectorOptions}
         .placeholder="${'Select sector'}"
         .empty="${'Sectors not found'}"
         />
      </w-validation-box>
    `;
  }

  @Observe('countryOptions', 'country')
  get countriesSelect() {
    const { country, countryOptions } = this.view.controller;

    return html`
      <w-validation-box label="Country*">
        <w-select
         name="country"
         .selected=${country}
         vRequired
         .options=${countryOptions}
         .placeholder="${'Select country'}"
         .empty="${'Countries not found'}"
         />
      </w-validation-box>
    `;
  }

  @Observe('cityOptions', 'city')
  get citiesSelect() {
    const { city, cityOptions, cityEmpty } = this.view.controller;

    return html`
      <w-validation-box label="City*">
        <w-select
         name="city"
         .selected=${city}
         vRequired
         .options=${cityOptions}
         .placeholder="${'Select city'}"
         .empty="${cityEmpty}"
         />
      </w-validation-box>
    `;
  }

  @Observe('organization')
  get organization() {
    const disabled = this.view.controller.disabledFields.includes('organization');

    return html`
      <w-validation-box
       label="Name of organization or network*"
       strategy="${disabled ? ValidationStrategy.off : ValidationStrategy.delay}"
       >
        <w-input
          ?disabled=${disabled}
          value="${this.view.controller.organization}"
          minlength="3"
          type="text"
          name="organization"
          placeholder="Type name here"
          vRequired
        >
      </w-validation-box>
    `;
  }

  @Observe('group')
  get group() {
    const disabled = this.view.controller.disabledFields.includes('group');

    return html`
      <w-validation-box
      label="Group, function or division"
      strategy="${disabled ? ValidationStrategy.off : ValidationStrategy.delay}"
      >
        <w-input
          ?disabled=${disabled}
          value="${this.view.controller.group}"
          minlength="3"
          type="text"
          name="group"
          placeholder="Type group, function or division name here"
        >
      </w-validation-box>
    `;
  }

  @Observe('unit')
  get unit() {
    const disabled = this.view.controller.disabledFields.includes('unit');

    return html`
      <w-validation-box
       label="Business unit"
       strategy="${disabled ? ValidationStrategy.off : ValidationStrategy.delay}"
       >
        <w-input
          ?disabled=${disabled}
          value="${this.view.controller.unit}"
          minlength="3"
          type="text"
          name="unit"
          placeholder="Type business unit name here"
        >
      </w-validation-box>
    `;
  }

  @Observe('title')
  get title() {
    const disabled = this.view.controller.disabledFields.includes('title');

    return html`
      <w-validation-box
       label="Job title*"
       strategy="${disabled ? ValidationStrategy.off : ValidationStrategy.delay}"
       >
          <w-input
            ?disabled=${disabled}
            minlength="3"
            type="text"
            name="title"
            placeholder="Type job title here"
            vRequired
            value="${this.view.controller.title}"
          >
      </w-validation-box>
    `;
  }

  get linkedin() {
    const disabled = this.view.controller.disabledFields.includes('linkedin');

    return html`
      <w-validation-box
       label="LinkedIn profile"
       strategy="${disabled ? ValidationStrategy.off : ValidationStrategy.delay}"
       >
          <w-input
            ?disabled=${disabled}
            minlength="3"
            type="text"
            name="linkedin"
            placeholder="Enter your LinkedIn profile (improves match quality)"
            value="${this.view.controller.linkedin}"
          >
      </w-validation-box>
    `;
  }

  @Observe('disabledFields', 'hiddenFields')
  get form() {
    const { hiddenFields } = this.view.controller;

    return html`
       <form
         name="one"
         novalidate="novalidate"
         class="${styles.form}"
         @changeForm="${(e) => this.view.controller.onChange(e)}"
         @submit="${(e) => this.view.controller.onNext(e, 'About me completed')}"
       >
        ${hiddenFields && !hiddenFields.includes('organization') ? this.organization : nothing}
        ${hiddenFields && !hiddenFields.includes('industry') ? this.industriesSelect : nothing}
        ${hiddenFields && !hiddenFields.includes('sector') ? this.sectorSelect : nothing}
        ${hiddenFields && !hiddenFields.includes('group') ? this.group : nothing}
        ${hiddenFields && !hiddenFields.includes('unit') ? this.unit : nothing}
        ${hiddenFields && !hiddenFields.includes('title') ? this.title : nothing}
        ${this.linkedin}
        ${hiddenFields && !hiddenFields.includes('stage') ? this.stagesSelect : nothing}
        ${hiddenFields && !hiddenFields.includes('country') ? this.countriesSelect : nothing}
        ${hiddenFields && !hiddenFields.includes('city') ? this.citiesSelect : nothing}
        ${this.buttons}
      </form>
    `;
  }

  @Observe('hiddenFields')
  get template() {
    const { hiddenFields } = this.view.controller;

    return html`
      <div>
      ${hiddenFields ? html`
        ${this.header}
        ${this.view.mandatory}
        ${this.form}
      ` : html`
        <div class="${styles.loaderWrapper}">
          ${loader}
        </div>
      `}
      </div>
    `;
  }
}

export default StepOneFragment;
