import { Observe } from 'decorators';
import { html } from 'lit-html';
import buttonStyles from 'styles/button.module.scss';
import cardStyles from 'styles/card.module.scss';
import DashPreMatchController from './dash-prematch.controller';
import styles from './dash-prematch.module.scss';

class DashPreMatchTemplate {
  public controller: DashPreMatchController;

  @Observe('prematchActions')
  get actions() {
    const { prematchActions, handleAction } = this.controller;

    return html`
      <div class=${styles.actions}>
        ${prematchActions.map((action) => html`
          <div class=${styles.item}>
            <p>${action.title}</p>
            <button class=${buttonStyles.small} @click=${() => handleAction(action)}>${action.label}</button>
          </div>
        `)}
      </div>
    `;
  }

  get template() {
    return html`
      <div class=${cardStyles.card}>
        <div class=${cardStyles.header}>
          <span class=${cardStyles.label}>Pre-match fitness</span>
        </div>
        <div class=${cardStyles.content}>
          <p><b>While you wait for your match, there are a bunch of things you can do to get ready.</b></p>
          ${this.actions}
        </div>
      </div>
    `;
  }
}

export default DashPreMatchTemplate;
