import {html, svg, SVGTemplateResult, TemplateResult} from 'lit-html';
import { customElement } from './decorators';
import { TemplatedElement } from './TemplatedElement';

@customElement({name: 'w-password-validation'})
class WPasswordValidation extends TemplatedElement {
  static get observedAttributes() {
    return ['number', 'length', 'special', 'uppercase', 'lowercase'];
  }

  public attributeChangedCallback() {
    this.update();
  }

  public get errorIcon(): SVGTemplateResult {
    return svg`
      <svg viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path fill="#e55e50" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/>
      </svg>
    `;
  }

  public validIcon(state): SVGTemplateResult {
    const color = state === 'valid' ? '#4cb49c' : '#b9b9b9';

    return svg`
      <svg viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path fill="${color}" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
      </svg>
    `;
  }

  public getIcon(state): SVGTemplateResult {
    return state === 'invalid' ? this.errorIcon : this.validIcon(state);
  }

  public get template(): TemplateResult {
    return html`
      <style>
        .box {
          display: flex;
          padding: 20px;
        }

        .box > section {
          flex: 1;
        }

        .box > section > span {
          align-items: center;
          display: flex;
          line-height: 2;
        }

        .box > section > span > svg {
          height: 15px;
          margin-right: 5px;
          min-width: 15px;
        }

        @media (max-width: 450px) {
          .box {
            display: block;
          }
        }
      </style>

      <div class="box">
        <section>
          <span>${this.getIcon(this.hasLowerCase)} 1 lowercase character</span>
          <span>${this.getIcon(this.hasNumber)} 1 number</span>
          <span>${this.getIcon(this.hasLength)} 8 characters minimum</span>
        </section>

        <section>
          <span>${this.getIcon(this.hasUpperCase)} 1 uppercase character</span>
          <span>${this.getIcon(this.hasSpecial)} 1 special character</span>
        </section>
      </div>
    `;
  }

  private get hasNumber() {
    return this.getAttribute('number');
  }

  private get hasSpecial() {
    return this.getAttribute('special');
  }

  private get hasLength() {
    return this.getAttribute('length');
  }

  private get hasUpperCase() {
    return this.getAttribute('uppercase');
  }

  private get hasLowerCase() {
    return this.getAttribute('lowercase');
  }
}

export default WPasswordValidation;
