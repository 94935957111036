import { Icons } from 'components/lit';
import { Observe } from 'decorators';
import { html } from 'lit-html';
import ProfileHeaderController from 'modules/ProfileHeader/profile-header.controller';
import styles from './profile-header.module.scss';

class ProfileHeaderTemplate {
  public controller: ProfileHeaderController;

  @Observe('points')
  private get points() {
    return html`<span>${this.controller.points}</span>`;
  }

  private get removeAvatarBtn() {
    return html`
      <span class=${styles.remove} title="Remove avatar">
        ${Icons.removeIcon(() => this.controller.removeAvatar())}
      </span>
    `;
  }

  private get uploadAvatarBtn() {
    return html`
      <label
        for="avatar-uploader"
        class=${styles.pencil}
        title="Upload avatar"
      >
        ${Icons.editPencil}
      </label>
      <input
        id="avatar-uploader"
        type='file'
        accept='image/*'
        @change=${(e) => this.controller.handleAvatarUpload(e)}
      />
    `;
  }

  @Observe('general')
  private get header() {
    const { lastName, firstName, profileImageUrl } = this.controller.general;

    return html`
      <div class=${styles['header-wrapper']}>

        <div class=${styles.avatar}>
          <w-avatar
            avatar="${profileImageUrl}"
            title="${`${firstName} ${lastName}`}"
            width="90px"
            borderWidth="2"
          >
          </w-avatar>
          ${profileImageUrl ?  this.removeAvatarBtn : this.uploadAvatarBtn}
        </div>

        <div class=${styles.details}>
          <div class=${styles.username}>${firstName} ${lastName}</div>

          <button class=${styles.pts} @click="${this.controller.onPointsRoute}">
            ${Icons.ptsStar}
            ${this.points}
          </button>

          <button
            class="${styles.button}"
            @click=${this.controller.onOpenPublicProfile}
          >
            View public profile
          </button>
        </div>

      </div>
    `;
  }

  get template() {
    return this.header;
  }
}

export default ProfileHeaderTemplate;
