import store from '@werkin/store';
import apollo from 'api';
import { observable } from 'decorators';
import get from 'lodash/get';
import Auth from 'services/auth.service';
import { Router } from 'services/router.service';
import startParticipationMutation from '../../graphql/mutations/startParticipation.graphql';
import CollectivesQuery from '../../graphql/queries/collectives.graphql';
import findDirectChatQuery from '../../graphql/queries/findDirectChat.graphql';

class MatchBodyController {
  public match: any;

  @observable() public matchId: string;
  @observable() public matchName: string;
  @observable([]) public matchLookingFor: [];
  @observable([]) public matchCommitment: [];
  @observable(false) public loading: boolean;

  public OnInit() {
    this.matchId = this.match.id;
    this.matchName = this.match.name;
    this.matchLookingFor = this.match.lookingFor;
    this.matchCommitment = this.match.commitment;
  }

  public async fetchCollective() {
    const { data: { collectives } } = await apollo.query({
      query: CollectivesQuery,
    });

    return collectives.find((collective) => collective.isActive);
  }

  public async startParticipation({ id }) {
    return apollo.mutate({
      mutation: startParticipationMutation,
      variables: {
        command: {
          collectiveId: id,
        },
      },
    });
  }

  public async getStarted() {
    this.loading = true;

    store.set('loading', true);

    const collective = await this.fetchCollective();

    await this.startParticipation(collective);

    const participantIds = [this.matchId, Auth.getUserId()];

    const response = await apollo.query(
      {
        query: findDirectChatQuery,
        variables: { createIfNotExists: true, participantIds },
      });

    const chatId = get(response, 'data.findDirectChat.chat.id');

    Router.go({ name: 'chats.chat', params: { chatId } });
  }
}

export default MatchBodyController;
