import ClientDB from '@werkin/clientdb';
import auth from 'services/auth.service';
import NotificationService from 'services/notificationManager';
import { Statuses } from 'services/statuses.service';
import REST from './rest.service';
import SentryService from './sentry.service';
import SharedGoal from './shared-goals.service';
import signalR from './signalR.service';
import TimeZoneService from './timezone.service';
import VersionService from './version.service';
import WorkerService from './worker.service';

export const Worker = new WorkerService();
export const Version = new VersionService();
export const TimeZone = new TimeZoneService();
export const Auth = auth;
export const Werkin: any = new ClientDB();
export const SignalR = signalR;
export const Sentry = new SentryService.init();
export const NotificationManager = new NotificationService();

export const StatusesService = new Statuses();
export { REST, SharedGoal };
