import apollo from 'api';
import { ISharedGoalsWith } from 'interfaces';
import receivedGoals from '../graphql/queries/receivedGoals.graphql';
import sharedGoals from '../graphql/queries/sharedGoals.graphql';
import sharedGoalsWithQuery from '../graphql/queries/sharedGoalsWith.graphql';

class SharedGoalsService {
  public shares: ISharedGoalsWith[] = [];
  public listeners = new Set([]);

  public collectMyShares() {
    apollo.query({ query: sharedGoalsWithQuery, fetchPolicy: 'no-cache'  }).then(({ data: { sharedGoalsWith } }) => {
      this.shares = sharedGoalsWith;

      this.listeners.forEach((fn) => fn(sharedGoalsWith));
    });
  }

  public getUserShares(individualId) {
    return apollo.query({ query: receivedGoals, variables: { individualId }, fetchPolicy: 'network-only' });
  }

  public shareStatus(id) {
    return apollo.query({ query: sharedGoals, variables: { id }, fetchPolicy: 'no-cache' });
  }

  public subscribe(fn) {
    fn(this.shares);

    this.listeners.add(fn);

    return () => {
      this.listeners.delete(fn);
    };
  }

  public clearListeners() {
    this.listeners = new Set([]);
  }
}


export default new SharedGoalsService();
