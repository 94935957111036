import { Observe } from 'decorators';
import { html } from 'lit-html';
import { Router } from 'services/router.service';
import ForgotPasswordController from './forgot-password.controller';
import styles from './forgot-password.module.scss';

class ForgotPasswordView {
  public controller: ForgotPasswordController;

  get template() {
    const back = { name: 'login' };

    return html`
      <div class=${styles.wrapper}>
        <p>We will email you a one time password to verify your identity.</p>

        <form @changeForm=${(e) => this.controller.onChange(e)} @submit=${(evt) => this.controller.onSend(evt)}>
          ${this.input}

          <div class="${styles.buttons}">
            <a href="${Router.generate(back)}" @click=${(evt) => this.controller.link(evt, back)}>Back</a>

            ${this.button}
          </div>
        </form>
      </div>
    `;
  }

  @Observe('loading', 'valid')
  private get button() {
    const { valid, loading } = this.controller;
    const text = loading ? 'Loading...' : 'Send';

    return html`<button class="w-button primary" ?disabled=${!valid || loading}>${text}</button>`;
  }

  @Observe('error')
  private get input() {
    const { error } = this.controller;

    return html`
      <w-validation-box
        name="email"
        label="Email Address"
        outsideerror=${ error }
        >
          <w-input
            autocomplete="new-password"
            autofocus
            type="email"
            name="email"
            placeholder="Type email address here"
            vRequired
            vEmail
          >
      </w-validation-box>
    `;
  }
}

export default ForgotPasswordView;
