import ControllerMiddleware from 'controllers/middleware';
import { Auth } from 'services';
import RouterError from 'services/router/router-error';

class AuthorizedController extends ControllerMiddleware {
  guard() {
    return Auth.isAuthenticated().then(authorized => {

      if (this.router.route.private && !authorized) {
        window.location.href = '/get-link';

        return Promise.reject(new RouterError({ message: 'Not authorized' }));
      }
    });
  }

  awaiter() {
    return this.setAwaiter();
  }
}

export default AuthorizedController;
