import { RouterModule } from 'services/router/router.module';

import Collective from './collective';
import LoadIcon from './loadButton';
import Profile from './profile';
import Nav from './navs';
import Search from './search';

import './search.setup';

const onScroll = e => {
  const header = document.getElementById('header');

  if (!header) { return; }

  const scrollTop = e.target.scrollTop;

  if (scrollTop >= 50 && !header.classList.contains('contracted')) {
    header.classList.add('contracted');
  } else if (scrollTop <= 40 && header.classList.contains('contracted')) {
    header.classList.remove('contracted');
  }
};

class Header extends RouterModule {
  mount() {
    this.router.controller.addModule(Collective);
    this.router.controller.addModule(LoadIcon);
    this.router.controller.addModule(Profile);
    this.router.controller.addModule(Nav.CalendarModule);
    this.router.controller.addModule(Nav.CreditsModule);
    this.router.controller.addModule(Nav.DashboardModule);
    this.router.controller.addModule(Nav.HelpModule);
    this.router.controller.addModule(Search);

    this.bindAnimation();

    this.router.controller.addDestroyer(this.unbindAnimation);
  }

  bindAnimation() {
    const main = document.getElementById('main');

    if (!main) { return; }

    main.addEventListener('scroll', onScroll);
  }

  unbindAnimation() {
    const main = document.getElementById('main');

    if (!main) { return; }

    main.removeEventListener('scroll', onScroll);
  }
}

export default Header;
