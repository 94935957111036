import { ValidationStrategy } from 'components/custom/enums';
import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import styles from '../join.module.scss';
import JoinTemplate from '../join.view';

class StepFiveFragment {
  public view: JoinTemplate;

  get fields() {
    return ['erg'];
  }

  @Observe('onboardHeader')
  get header() {
    const { onboardHeader } = this.view.controller;

    return html`
     <div class="${styles.desc}">
        ${onboardHeader}
      </div>
    `;
  }

  @Observe('fiveValid')
  get buttons() {
    return html`
    <div class="${styles.buttons}">
      <button
       type="button"
       @click=${(e) => this.view.controller.onBack(e)}
       class="w-button outline ${styles.back}">
       Back
      </button>
      <button
       type="submit"
       class="w-button primary ${styles.next}" ?disabled="${!this.view.controller.fiveValid}">
       Next
       </button>
    </div>
    `;
  }

  @Observe('desiredQualities')
  get erg() {
    const disabled = this.view.controller.disabledFields.includes('desiredQualities');

    return html`
      <w-validation-box
       label="Do you belong to any Employee Resource Groups (ERGs) or Affinity groups in your organisation?*"
       strategy="${disabled ? ValidationStrategy.off : ValidationStrategy.delay}"
       >
       <w-textarea
          value=${this.view.controller.erg}
          minlength="2"
          rows="6"
          name="erg"
          placeholder="Please list them here or just say no"
          vRequired
        >
      </w-validation-box>
    `;
  }

  @Observe('disabledFields', 'hiddenFields')
  get form() {
    const { hiddenFields } = this.view.controller;

    return html`
       <form
         name="five"
         novalidate="novalidate"
         class="${styles.form}"
         @changeForm="${(e) => this.view.controller.onChange(e)}"
         @submit="${(e) => this.view.controller.onNext(e, 'ERGs completed')}"
       >
       ${hiddenFields && !hiddenFields.includes('erg') ? this.erg : nothing}
       ${this.buttons}
      </form>
    `;
  }

  get template() {
    return html`
      <div>
        ${this.header}
        ${this.form}
      </div>
    `;
  }
}

export default StepFiveFragment;
