import { html } from 'lit-html';
import styles from './styles.module.scss';

export default ({
  className,
  variant = 'primary',
  onClick = () => {},
  disabled,
  text,
}) => html`<button class="${styles.base} ${className} ${styles[variant]}" @click="${onClick}" ?disabled="${disabled}">${text}</button>`;
