import { html, nothing, directive } from 'lit-html';
import { Icons } from 'components/lit/index';
import styles from 'components/lit/validated/validated-input.module.scss';
import { ValidationWrapper } from 'components/lit/validated/validation-wrapper.view';

export class ValidatedInput extends ValidationWrapper {
  get eyeButton() {
    const clickOnEye = (evt) => {
      evt.preventDefault();
      this.setOptions({ type: this.options.type === 'password' ? 'text' : 'password' });
    };

    return html`
      <button
        type="button"
        class="${styles.show}"
        @click=${evt => clickOnEye(evt)}
      >
        ${Icons.eye(this.options.type === 'password')}
      </button>
    `;
  }

  get clearButton() {
    const clickClearButton = (evt) => {
      evt.preventDefault();
      const { onClearCb } = this.options;

      this.setOptions({ value: '' });
      onClearCb && onClearCb();
    };

    return html`
      <button
        type="button"
        class="${styles.clear}"
        @click=${evt => clickClearButton(evt)}
      >
        ${Icons.circleCross}
       </button>
    `;
  }

  get input() {
    const {
      autofocus = false,
      disabled = false,
      type = 'text',
      value = '',
      placeholder = '',
      name = 'name',
      inputHandler = () => {},
      keyDownHandler = () => {},
      animationStartHandler = () => {},
      showEye = false,
      showClearButton,
    } = this.options;

    return html`
      <div class=${styles.input}>
        ${ showClearButton ? this.clearButton : nothing }

        ${ showEye ? this.eyeButton : nothing }

        <input
          ?disabled=${disabled}
          .autofocus=${autofocus}
          .value=${value || ''}
          type=${type}
          name=${name}
          placeholder=${placeholder}
          @input=${evt => inputHandler(evt)}
          @animationstart=${evt => animationStartHandler(evt)}
          @keydown=${evt => keyDownHandler(evt)}
        />
      </div>
    `;
  }

  get renderInput() {
    return directive(() => part => {
      this.subscribe(() => {
        part.setValue(this.input);
        part.commit();
      }, 'options', 'value');
    });
  }

  get field() {
    return this.renderInput();
  }
}
