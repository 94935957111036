import { Icons } from 'components/lit';
import CenterAutonomousModal from 'components/lit/modals/center-autonomus-modal.view';
import { AutonomousModal } from 'decorators';
import { html } from 'lit-html';
import { Router } from 'services/router.service';

@AutonomousModal('PollModal')
class PollModal extends CenterAutonomousModal {
  public title = 'Poll';

  public onOpen() {
    this.template = this.wrap(this.body);
  }

  public get body() {
    const { pollId } = Router.hash;

    return html`
      <div class=${this.styles.wide}>
      <div class="${this.styles.modal_header}">
        <h2 class="${this.styles.title}"></h2>
        <div class="${this.styles.close_btn}" @click=${() => this.destroy()}>
          ${Icons.circleCross}
        </div>
      </div>
      <w-poller-module
        .pollId=${pollId}
        .destroy=${() => this.destroy()}
      />
    </div>
    `;
  }
}

export default PollModal;
