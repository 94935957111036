import { render } from 'lit-html';
import styles from './emoji.module.scss';
import {emojiSelect} from './emoji.view';
import { emojiIcon } from './icons';

export default (Quill) => {
  const Module = Quill.import('core/module');

  class EmojiTextareaModule extends Module {
    private quill: any;
    private lastSelection: any;
    private container: HTMLElement = document.createElement('div');
    private list: HTMLDivElement = document.createElement('div');

    constructor(quill, options) {
      super(quill, options);

      this.quill = quill;

      this.quill.container.appendChild(this.container);

      this.quill.on('text-change', this.onTextChange.bind(this));
      this.quill.on('selection-change', this.onSelectionChange.bind(this));

      this.container.classList.add(styles['textarea-emoji-control']);
      this.container.innerHTML = emojiIcon;

      this.list.classList.add(styles.list);

      this.openList = this.openList.bind(this);
      this.onSelectEmoji = this.onSelectEmoji.bind(this);
      this.missClickListener = this.missClickListener.bind(this);

      this.container.addEventListener('click', this.openList, false);
      // Prevent opening emoji list on selection emoji
      this.list.addEventListener('click', (evt) => evt.stopPropagation());
    }

    private missClickListener(evt) {
      const isMissClick = !this.list.contains(evt.target) && !this.container.contains(evt.target);

      isMissClick && this.closeEmojiList();
    }

    private onSelectionChange(range) {
      range && (this.lastSelection = range);
    }

    private onTextChange() {
      this.onSelectionChange(this.quill.getSelection());
    }

    private onSelectEmoji(item) {
      this.closeEmojiList();

      this.quill.insertEmbed(this.lastSelection.index, 'emoji', item, Quill.sources.USER);
      this.quill.setSelection(this.lastSelection.index + 1);
    }

    public openList() {
      !this.lastSelection && this.quill.focus();

      this.container.appendChild(this.list);

      window.addEventListener('click', this.missClickListener);

      render(emojiSelect(this.onSelectEmoji), this.list);
    }

    private closeEmojiList() {
      window.removeEventListener('click', this.missClickListener);

      this.container.removeChild(this.list);
    }
  }

  Quill.register({ 'modules/emoji-textarea': EmojiTextareaModule }, true);
};

