import apollo from 'api';
import { observable } from 'decorators';
import { Auth } from 'services';
import { Router } from 'services/router.service';
import likeActivityFeedItemMutation from '../../graphql/mutations/likeActivityFeedItem.graphql';
import ActivitiesQuery from '../../graphql/queries/activities.graphql';
import ActivityModule from './activity.module';

class ActivityController {
  public module: ActivityModule;
  @observable(null) public currentUserId: any;
  @observable([]) public activityFeed: any[];
  @observable(true) public hasActivityItems: boolean;
  @observable(false) public loading: boolean;

  constructor() {
    this.handleLikeItem = this.handleLikeItem.bind(this);
  }

  public OnInit() {
    this.getCurrentUserId();

    const observe = apollo.watchQuery({
      query: ActivitiesQuery,
      variables: {
        chatId: Router.params.chatId,
      },
    }).subscribe({
      next: ({ data: { channelActivityFeed } }) => {
        this.hasActivityItems = !!channelActivityFeed.length;
        this.activityFeed = channelActivityFeed;
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public getCurrentUserId() {
    this.currentUserId = Auth.getUserId();
  }

  public async handleLikeItem(id) {
    try {
      this.loading = true;
      const activeItem = this.activityFeed.find((item) => item.id === id);
      const userAlreadyLikes = activeItem && activeItem.likes.includes(this.currentUserId);

      await apollo.mutate({
        mutation: likeActivityFeedItemMutation,
        variables: {
          command: {
            value: !userAlreadyLikes,
            itemId: id,
          },
        },
        refetchQueries: [{
          query: ActivitiesQuery,
          variables: {
            chatId: Router.params.chatId,
          },
        }],
      });

      this.loading = false;
    } catch (err) {
      console.log('handleLikeItem', err);
      this.loading = false;
    }
  }
}

export default ActivityController;
