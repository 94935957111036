import { html } from 'lit-html';
import Template from 'services/templator/template';

class CollectiveTemplate extends Template {
  get template() {
    return html`
      <a
        href="/"
        @click=${evt => this.controller.handleClick(evt)}
        class="${this.styles.wrapper}"
      >
        ${this.directives.getCollective()}
      </a>
    `;
  }
}

export default CollectiveTemplate;
