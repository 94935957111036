import { html } from 'lit-html';
import styles from './validated-textarea.module.scss';
import { ValidationWrapper } from 'components/lit/validated/validation-wrapper.view';

export class ValidatedTextArea extends ValidationWrapper {
  get field() {
    const {
      rows,
      autofocus = false,
      disabled = false,
      value = '',
      placeholder = '',
      name = 'name',
      inputHandler = () => {},
      keyDownHandler = () => {},
      fillToEnd = false,
    } = this.options;

    return html`
      <textarea
        rows=${rows}
        class="${styles.textarea} ${fillToEnd ? styles.fill_to_end : ''}"
        ?autofocus=${autofocus}
        ?disabled=${disabled}
        placeholder="${placeholder}"
        name=${name}
        .value=${value}
        @input=${evt => inputHandler(evt)}
        @keydown="${evt => keyDownHandler(evt)}"
      ></textarea>
    `;
  }
}
