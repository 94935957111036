import { render } from 'lit-html';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';
import SidebarController from './sidebar.controller';
import SidebarView from './sidebar.view';

@RouterModule({
  name: 'w-sidebar-module',
  view: SidebarView,
  controller: SidebarController,
})
class SidebarModule {
  public view: SidebarView;
  public controller: SidebarController;
  public subs: Set<() => void> = new Set([]);

  public async loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    const defaultDestroyFn = () => {};
    this.controller.destroy = module.destroy || defaultDestroyFn;

    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default SidebarModule;
