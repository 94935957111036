import apollo from 'api';
import { observable } from 'decorators';
import ProfileService from 'services/data/profile.service';
import { Router } from 'services/router.service';
import ActionUtils from 'utils/action-helpers';
import ActionQuery from '../../graphql/queries/action.graphql';
import { IAction } from '../../interfaces';
import ActionModule from './action.module';

class ActionController {
  public module: ActionModule;
  public actionUtils = new ActionUtils();
  @observable(null, true) public action: IAction;
  @observable(false) public loading: boolean;
  @observable(null, true) public currentUserId: string;
  @observable(false, true) public showAllMembers: boolean;

  constructor() {
    this.handleAction = this.handleAction.bind(this);
    this.toggleShowAllMembers = this.toggleShowAllMembers.bind(this);
  }

  public async OnInit() {
    await this.setCurrentUser();
    this.watchActions();
  }

  private watchActions() {
    const observe = apollo.watchQuery({
      query: ActionQuery,
      variables: {
        id: Router.params.actionId,
      },
    }).subscribe({
      next: ({ data: { action } }) => {
        this.action = {
          ...action,
          data: JSON.parse(action.data),
        };
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public async setCurrentUser() {
    const profile = await ProfileService.getProfile();
    this.currentUserId = profile.id;
  }

  public goBack() {
    return Router.back();
  }

  public get participantsSortedByState() {
    const states = ['accepted', 'tentatively-accepted', 'declined', 'invited'];

    return this.action.participants.sort((a, b): number =>
      states.indexOf(a.status) - states.indexOf(b.status));
  }

  public toggleShowAllMembers() {
    this.showAllMembers = !this.showAllMembers;
  }

  public async handleAction(name: string) {
    this.loading = true;

    await this.actionUtils.action(name, {...this.action, organizer: { id: this.action.organizerId } });

    this.loading = false;
  }
}

export default ActionController;
