import React from 'react';
import PropTypes from 'prop-types';
import styles from './FAQ.module.scss';
import Text from 'atoms/Text';
import AccordionGroup from 'components/AccordionGroup';
import Accordion from 'components/Accordion';
import faqsService from 'services/data/faq.service';

import mockedQuestions from './questions';

const FAQ = ({ parent }) => {
  const [frequentlyQuestions, setFrequentlyQuestions] = React.useState(mockedQuestions);

  const updateQuestions = React.useCallback(async () => {
    const requestedQuestions = await faqsService.getQuestions();

    setFrequentlyQuestions([...mockedQuestions, ...requestedQuestions]);

  }, [setFrequentlyQuestions]);

  React.useEffect(() => {
    updateQuestions();

  }, [updateQuestions]);

  return (
    <div className={styles.faq}>
      <Text tag='h4' color='warm-grey' className='mb-8'>Frequently Asked Questions</Text>

      <AccordionGroup>
        {frequentlyQuestions.map((item, i) => (
          <Accordion title={item.category} key={i} parent={parent}>
            <div className={styles.questions_wrapper}>
              <AccordionGroup>
                {item.questions.map((child, y) => (
                  <Accordion
                    title={child.question}
                    className={styles.question}
                    headerClass={styles.question_header}
                    titleClass={styles.question_title}
                    key={y}
                  >
                    <div className={styles.text_container}>{child.answer}</div>
                  </Accordion>
                ))}
              </AccordionGroup>
            </div>
          </Accordion>
        ))}
      </AccordionGroup>
    </div>
  );
};

FAQ.propTypes = {
  parent: PropTypes.object.isRequired,
};

export default FAQ;
