import React from 'react';
import PropTypes from 'prop-types';
import styles from './Support.module.scss';
import Text from 'atoms/Text';
import Textarea from 'atoms/Textarea';
import Button from 'atoms/Button';

const data = {
  suggest: {
    title: 'Suggest an improvement',
    label: 'Is there something you would like to see on the WERKIN plaform?',
    placeholder: 'Tell us about any improvements you think would enhance your mentoring experience',
  },
  report: {
    title: 'Report a problem',
    label: 'Has something happened?',
    placeholder:
      'Tell us about it here and please include a description of what you did, ' +
      'what you expected to be different, and any error messages you saw.',
  },
};

const Support = ({ onSubmit, type }) => {

  const [value, setValue] = React.useState('');

  return (
    <div className={styles.wrapper}>
      <Text tag='h5' color='greyish-teal'>{ data[type].title }</Text>

      <div className={styles.field}>
        <Text color='dark-grey' className='mb-5'>{ data[type].label }</Text>
        <Textarea
          onChange={({ target: { value } }) => setValue(value)}
          value={value}
          rows='5'
          placeholder={data[type].placeholder}
        />
      </div>

      <Button
        onClick={() => onSubmit(value)}
        className={styles.button}
        variant='primary'
      >
        Send
      </Button>
    </div>
  );
};

Support.propTypes = {
  type: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Support;
