import { observable } from 'decorators';
import NavigationModule from 'modules/Navigation/navigation.module';
import { Router } from 'services/router.service';

class NavigationController {
  public module: NavigationModule;
  public navbarWidth = 0;

  @observable([], true) public tabs: any[];
  @observable(0) public selectedTab: number;
  @observable('', true) public title: string;

  public OnInit() {
    setImmediate(() => {
      this.initSelected();
    });
  }

  public initSelected() {
    const navbar = document.getElementById('items');

    if (navbar) {
      this.navbarWidth = navbar.clientWidth;
      this.getSelected();

      const unsubscribe = Router.listen(() => {
        this.getSelected();
      });

      this.module.subs.add(unsubscribe);
    }
  }

  private getSelected() {
    this.tabs.forEach(({path}, index) => {
      if (path === Router.params.tab) {
        this.selectedTab = index;
      }
    });
  }


  public scrollItemToCenter(currentItemIndex) {
    setImmediate(() => {
      const navbar = document.getElementById('items');
      const navLink = document.getElementById(`${currentItemIndex}-navlink`);

      if (!navbar || !navLink) { return; }

      navbar.scrollLeft = navLink.offsetLeft - (navbar.clientWidth - navLink.offsetWidth) / 2;
    });
  }

  get isMobileLayout() {
    return Router.display === 'mobile';
  }

  public goToChat() {
    Router.go({ name: 'chat' });
  }

  public changeTab(tabIndex) {
    Router.go({
      params: {
        ...Router.params,
        tab: this.tabs[tabIndex].path,
      },
    });
  }
}

export default NavigationController;
