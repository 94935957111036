import { RouterModule } from 'services/router/router.module';
import Search from 'modules/Header/search/Search';

class SearchModule extends RouterModule {
  mount() {
    this.manageReactMount(Search, 'header-search');
  }
}

export default SearchModule;
