import styles from './notifications.module.scss';
import { render, html, TemplateResult } from 'lit-html';
import MarkdownIt from 'markdown-it';
import * as Icons from 'components/lit/icons.view';
import { showIf } from 'directives';
import { Router } from 'services/router.service';
import getIcons from 'modules/Alerts/alerts.icons';

const md = new MarkdownIt({
  html: true,
});

class NotificationService {
  constructor() {
    this.notifications = new Set([]);
  }

  convertText(text) {
    return text instanceof TemplateResult ? text : html([text]);
  }

  closeBtn(closeFn) {
    return html`
      <div class="${styles.close_btn}" @click=${closeFn}>
        ${Icons.close}
        <span>Close</span>
       </div>
    `;
  }

  renderAlert(wrapper, data, close) {
    const handler = () => {
      const { link } = data;
      link && this.handleNotificationLink(link);
      close();
    };

    const tmp = html`
      <div class="${`${styles.notification} ${styles.alert}`}" @click=${handler}>
        <div class="${styles.alert_icon_wrapper}">${getIcons(data.icons)}</div>
        <div class="${styles.content_wrapper}">
          <div class="${styles.title}">${data.title}</div>
          <div class=${styles.text} .innerHTML=${md.render(data.text)}></div>
        </div>
      </div>`;
    render(tmp, wrapper);
  }

  renderNotification(wrapper, data, close) {
    const tmp = html`
      <div class="${`${styles.notification} ${styles[data.type]}`}">
        <div class="${styles.icon_wrapper}">${Icons[`notification_${data.type}`]}</div>
        <div class="${styles.content_wrapper}">${this.convertText(data.text)}</div>
        ${showIf(data.closeButton, this.closeBtn(close))}
      </div>`;
    render(tmp, wrapper);
  }

  removeNotification(n) {
    if (this.notifications.has(n)) {
      this.notifications.delete(n);
      n.onClose();
      n.classList.add(styles.closed);
      setTimeout(() => document.body.removeChild(n), 300);
    }
  }

  showNotification(data) {
    const n = document.createElement('div');

    n.classList.add(styles.wrapper);

    const { duration = 1000 } = data;

    Object.assign(n, { onClose: data.onClose || (() => {}) });

    const t = (() => !data.persist && setTimeout(() => this.removeNotification(n), duration))();

    const close = () => {
      clearTimeout(t);
      this.removeNotification(n);
    };

    if (data.type === 'alert') {
      this.renderAlert(n, data.data, close);
    } else {
      this.renderNotification(n, data, close);
    }

    document.body.appendChild(n);

    this.notifications.add(n);

    return { close: {} };
  }

  handleNotificationLink(link) {
    try {
      const url = new URL(link);
      if (!url || !url.hostname) { throw Error(); }

      window.open(link, '_blank');
    } catch (err) {
      link && Router.go({ path: link });
    }
  }
}

export default NotificationService;
