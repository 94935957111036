import apollo from 'api';
import { NotificationManager, Worker } from 'services';
import BaseService from 'services/data/base.service';
import { convertKeys } from 'utils/chat-helpers';
import ViewAlertsMutation from '../../graphql/mutations/viewAlerts.graphql';
import AlertsQuery from '../../graphql/queries/alerts.graphql';


class AlertsService extends BaseService {
  public async load() {
    this.setLoadingState('Fetching notifications');

    return this.queryPrefetcher(AlertsQuery, 'alerts', {});
  }

  public getAlerts = async () => {
    const { data: { alerts } } = await apollo.query({
      query: AlertsQuery,
      fetchPolicy: 'network-only',
    });

    return alerts;
  }

  public setAlert = async (alertData: string) => {
    const alert = convertKeys(JSON.parse(alertData));
    const { title, text, attributes } = alert;

    await this.getAlerts();

    NotificationManager.showNotification({
      data: { title, text, icons: attributes.icons, link: attributes.link },
      type: 'alert',
      duration: 10000,
    });

    Worker.safariNotification('You have a new alert from Werkin', {
      body: text,
      data: {
        url: attributes.link,
      },
    });
  }

  public viewAlerts() {
    apollo.mutate({
      mutation: ViewAlertsMutation,
      variables: {
        viewed: new Date().toISOString(),
      },
      refetchQueries: [{
        query: AlertsQuery,
      }],
    });
  }
}

export default new AlertsService();
