import { render, TemplateResult } from 'lit-html';

export abstract class TemplatedElement extends HTMLElement {
  public abstract get template(): TemplateResult;

  public constructor() {
    super();
    this.attachShadow({ mode: 'open'});
    this.update();
  }

  public update() {
    render(this.template, this.shadowRoot);
  }
}

