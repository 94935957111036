import DefaultController from 'controllers/default';

class AlertsController extends DefaultController {
  before() {
    const { display } = this.router;

    this.store.set('isAlertsModal', true);

    if (display !== 'mobile') {
      return this.router.go({ path: this.store.get('landingRoute') });
    }
  }

  controller() {
    super.controller();
    this.addDestroyer(() => this.router.display === 'mobile' && this.store.set('isAlertsModal', false));
  }
}

export default AlertsController;
