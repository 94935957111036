import { classNames } from '@werkin/lib/helpers';
import { Observe } from 'decorators';
import { ProgrameOverview } from 'interfaces/programmeOverview';
import { html } from 'lit-html';
import moment from 'moment';
import buttonStyles from 'styles/button.module.scss';
import cardStyles from 'styles/card.module.scss';
import DashProgrammeController from './dash-programme.controller';
import styles from './dash-programme.module.scss';

class DashProgrammeTemplate {
  public controller: DashProgrammeController;

  @Observe('programmeContent')
  get programme() {
    const { client, name, startDate, endDate, week, duration }: ProgrameOverview = this.controller.programmeContent;
    const { handleViewClient, handleViewProfile } = this.controller;

    return html`
      <p>
        You are part of the
        <span class=${styles.span} @click=${() => handleViewClient()}><strong>${client} ${name}</strong></span>
        programme.
      </p>
      <p class=${styles.flex}>
        <span class=${styles.dateWrapper}><strong>Start:</strong> ${moment(startDate).format('DD MMM, YYYY')}</span
        ><span class=${styles.dateWrapper}><strong>End:</strong> ${moment(endDate).format('DD MMM, YYYY')}</span>
      </p>
      <p>We are currently in <span class=${styles.colored}>week ${week}</span> of the programme.</p>
      <p class=${styles.border}>
        You have
        <span class=${styles.span} @click=${() => handleViewProfile()}><strong>committed</strong></span> to a
        ${duration} relationship.
      </p>
    `;
  }

  get template() {
    const { handleViewClient } = this.controller;

    return html`
      <div class=${cardStyles.card}>
        <div class=${cardStyles.header}>
          <span class=${cardStyles.label}>Programme overview</span>
        </div>
        <div class=${classNames('cardContent', styles, cardStyles.content)}>
          <div class=${styles.actions}>${this.programme}</div>
          <div class=${styles.buttonWrapper}>
            <button class=${classNames('button', styles, buttonStyles.small)} @click=${() => handleViewClient()}>
              Meet the community
            </button>
          </div>
        </div>
      </div>
    `;
  }
}

export default DashProgrammeTemplate;
