import { render } from 'lit-html';
import SharedGoalsController from 'modules/SharedGoals/shared-goals.controller';
import SharedGoalsView from 'modules/SharedGoals/shared-goals.view';
import { RouterModule } from 'services/router/router.m';

@RouterModule({
  name: 'w-shared-goals-module',
  view: SharedGoalsView,
  controller: SharedGoalsController,
})
class SharedGoalsModule {
  public view: SharedGoalsView;
  public controller: SharedGoalsController;

  public mount(module) {
    this.controller.destroy = module.destroy;

    render(this.view.template, module);
  }
}

export default SharedGoalsModule;
