import 'services/router.service';
import 'styles/base.scss';
import './colors';
import './polyfills';

import store from '@werkin/store';
import { errorHandler } from 'utils/error-helpers';

import('./services/analytics.service')
  .then((analytics) => analytics.initGA())
  .catch(errorHandler);

import('./services/intercom.service')
  .then((intercom) => intercom.init())
  .catch(errorHandler);

store.set('loading', true);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then((reg) => {
      console.log('SW registered: ', reg);
    }).catch((err) => {
      console.error(err);
    });
  });
}

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  store.set('promptPWA', e);
});
