import apollo from 'api';
import throttle from 'lodash/throttle';
import BaseService from 'services/data/base.service';
import { Router } from 'services/router.service';
import deleteChatMutation from '../../graphql/mutations/deleteChat.graphql';
import ChatQuery from '../../graphql/queries/chat/Chat.graphql';
import ChatMessageQuery from '../../graphql/queries/chat/ChatMessage.graphql';
import ChatMessagesQuery from '../../graphql/queries/chat/ChatMessages.graphql';
import ChatsListQuery from '../../graphql/queries/chat/ChatsList.graphql';
import collectivesService from './collectives.service';

class ChatsService extends BaseService {
  public async load() {
    this.setLoadingState('Fetching your latest conversations');

    return this.queryPrefetcher(ChatsListQuery, 'chats', {});
  }

  public fetchChatMessage = async (id, fromMemory = false) => {
    try {
      const { data: { chatMessage } } = await apollo.query({
        query: ChatMessageQuery,
        variables: { id },
        fetchPolicy: fromMemory ? 'cache-only' : 'network-only',
      });

      return chatMessage;
    } catch (err) {
      console.log('fetchChatMessage', err);
    }
  }

  public handleCreateMessage = async (id) => {
    this.handleChatsListUpdated();
    const message = await this.fetchChatMessage(id);
    this.storeChatMessage(message);
  }

  public handleDeleteMessage = async (id) => {
    const message = await this.fetchChatMessage(id, true);
    const deletedMessage = {
      ...message,
      content: JSON.stringify({ ops: [{ insert: 'Message Deleted\n'}]}),
      attributes: null,
      state: 'deleted',
    };

    this.storeChatMessage(deletedMessage);
  }

  public storeChatMessage = (message) => {
    const { chatId } = message;

    try {
      const { chatMessages } = apollo.readQuery({
        query: ChatMessagesQuery,
        variables: { chatId },
      });

      let newNodes = [...chatMessages.nodes];
      const existingIndex = newNodes.findIndex(({ id }) => id === message.id);

      if (existingIndex >= 0) {
        newNodes.splice(existingIndex, 1, message);
      } else {
        newNodes = [...newNodes, message];
      }

      apollo.writeQuery({
        query: ChatMessagesQuery,
        variables: { chatId },
        data: {
          chatMessages: {
            ...chatMessages,
            nodes: newNodes,
          },
        },
      });
    } catch (err) {
      console.info('storeChatMessage info: not inserting message into Chat that has not been loaded');
    }
  }

  public setMessages = (messageData) => {
    if (!Router.params.chatId) { return; }

    apollo.query({
      query: ChatQuery,
      variables: {
        id: Router.params.chatId,
      },
      fetchPolicy: 'network-only',
    });
  }

  public handleChatsListUpdated = throttle(() => {
    apollo.query({
      query: ChatsListQuery,
      fetchPolicy: 'network-only',
    });
  }, 4000);

  public handleChatDeleted = async (chatId) => {
    this.handleChatsListUpdated();
    if (Router.params.chatId !== chatId) { return; }

    const { associatedChatId } = await collectivesService.getActiveCollective();

    Router.go({ name: 'chats.chat', params: { chatId: associatedChatId } });
  }

  public deleteChat(id: string) {
    return apollo.mutate({
      mutation: deleteChatMutation,
      variables: { command: { id } },
      refetchQueries: [{
        query: ChatsListQuery,
      }],
    });
  }
}

export default new ChatsService();
