import ErrorHandler from 'services/templator/error';

class Fragment extends ErrorHandler {
  constructor(container = {}) {
    super();
    this.container = container;
  }

  get controller() { return this.container.controller; }

  get directives() { return this.container.directives; }

  get styles() { return this.container.styles; }

  get data() { return this.container.data; }

  get router() { return this.container.router; }

  get module() { return this.container.module; }

  set view(container) {
    this.container = container;
  }
}

export default Fragment;
