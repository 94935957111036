import { html } from 'lit-html';

import { loader } from 'components/lit';
import { Observe } from 'decorators';
import { classMap } from 'lit-html/directives/class-map';
import { repeat } from 'lit-html/directives/repeat';
import NotAssignedController from 'modules/NotAssigned/not-assigned.controller';
import styles from './not-assigned.module.scss';

class NotAssignedView {
  public controller: NotAssignedController;

  get template() {
    return html`
      <div class="${styles.wrapper}">
        <div class="${styles.collectives_list}">
          <h1>Select collective:</h1>
          <hr color="#4cb49c" size="1px">
          ${this.collectives}
        </div>
      </div>
    `;
  }

  private collective(data, index) {
    const classes = {
      [styles.collective]: true,
      [styles.active]: data.isActive,
    };

    return html`
      <button
        @click=${() => this.controller.selectCollective(data.id)}
        class=${classMap(classes)}
        tabindex=${index + 1}
        autofocus=${!index}
      >
        <w-avatar
          avatar=${data.avatar}
          title=${data.name}
          width="50px"
        >
        </w-avatar>
        <div>${data.name}</div>
      </button>
    `;
  }

  @Observe('collectives', 'loading')
  private get collectives() {
    if (this.controller.loading) {
      return html`
        <div class="${styles.loading}">
          ${loader}
        </div>
      `;
    }

    return html`
      ${repeat(this.controller.collectives, (c, i) => this.collective(c, i))}
    `;
  }
}

export default NotAssignedView;
