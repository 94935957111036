import { html, svg } from 'lit-element';
import { customElement } from './decorators';
import { ValidationStrategy } from './enums';
import { TemplatedElement } from './TemplatedElement';

enum ValidationState {
  VALID,
  INVALID,
  DEFAULT,
}

@customElement({name: 'w-validation-box'})
class WValidationBox extends TemplatedElement {
  private state: ValidationState = ValidationState.DEFAULT;
  private error: string;
  private timout: any;
  private isValidated: boolean = false;

  constructor() {
    super();
    this.onInput = this.onInput.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  public connectedCallback() {
    this.addEventListener('validation', this.onInput);
    this.addEventListener('reset', this.onReset);
  }

  public disconnectedCallback() {
    this.removeEventListener('validation', this.onInput);
    this.removeEventListener('reset', this.onReset);
  }

  get noerror(): boolean {
    return this.hasAttribute('noerror');
  }

  get name(): string {
    return this.getAttribute('name');
  }

  get label(): string {
    return this.getAttribute('label');
  }

  get strategy(): ValidationStrategy {
    return this.getAttribute('strategy') as ValidationStrategy || ValidationStrategy.delay;
  }

  get delay(): number {
    return +this.getAttribute('delay') || 3000;
  }

  get checkIcon() {
    return svg`
      <svg viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path
          fill="${this.color.hash}"
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52
           2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
         />
      </svg>
    `;
  }

  get errorIcon() {
    return svg`
      <svg viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path fill="#e55e50" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/>
      </svg>
    `;
  }

  get icon() {
    return this.state === ValidationState.INVALID ? this.errorIcon : this.checkIcon;
  }

  get color() {
    switch (this.state) {
      case (ValidationState.VALID):
        return window.COLORS.GREYSTASH_TEAL;
      case(ValidationState.INVALID):
        return window.COLORS.PASTEL_RED;
      default:
        return window.COLORS.SIMPLE_GREY;
    }
  }

  get template() {
    return html`
    <style>
      .wrapper {
        padding: 20px;
        display: flex;
        flex-direction: column;
        transition: all 0.3s ease-in;
        margin-bottom: 2px;
        flex: 1;
      }

      label {
        margin-left: 5px;
        margin-bottom: 10px;
        font-size: 14px;
        color: ${window.COLORS.DARK_GREY.hash};
      }

      .error {
        min-height: 14px;
        margin-left: 5px;
        margin-top: 10px;
        font-size: 12px;
        color: ${window.COLORS.PASTEL_RED.hash};
      }

      .field-wrapper {
        display: flex;
        width: 100%;
        height: 100%;
      }

      .v-icon {
        margin: auto;
      }

      .v-icon > svg {
        width: 20px;
      }

      ::slotted(*) {
        width: 100% !important;
        margin-right: 30px !important;
        /*display: flex;*/
      }
    </style>
    <div class="wrapper" style="${`background-color: rgba(${this.color.rgb}, 0.3)`}">
      <label for="${this.name}">${this.label}</label>
      <div class="field-wrapper">
        <slot></slot>
        <div class="v-icon" >${this.icon}</div>
      </div>

      <div class="error">${this.noerror ? '' : this.error}</div>
    </div>`;
  }

  static get observedAttributes() {
    return ['outsideerror', 'delay', 'strategy', 'noerror', 'label'];
  }

  public onInput(evt: any) {
    evt.stopPropagation();
    this.performUpdate(evt);
  }

  public onReset(evt: any) {
    evt.stopPropagation();
    this.state = ValidationState.DEFAULT;
    this.error = null;
    this.update();
  }

  public attributeChangedCallback(attrName: string, oldVal: string, newVal: string) {
    if (attrName === 'outsideerror' && newVal !== 'undefined') {
      this.applyOutsideError(newVal);
    } else {
      this.update();
    }
  }

  private applyOutsideError(error: string) {
    this.error = error;
    this.state = ValidationState.INVALID;
    this.update();
  }

  private immediateValidation(evt: any) {
    this.error = evt.target.validationMessage;
    this.state = evt.target.validity.valid ? ValidationState.VALID : ValidationState.INVALID;
    this.update();
  }

  private delayValidation(evt: any) {
    const valid = evt.target.validity.valid;
    this.error = null;
    this.state = ValidationState.DEFAULT;
    this.timout = setTimeout(() => {
      this.error = evt.target.validationMessage;
      this.state = valid ? ValidationState.VALID : ValidationState.INVALID;
      this.update();
      this.isValidated = true;
    }, this.delay);
  }

  private performUpdate(evt: any) {
    clearTimeout(this.timout);
    const valid = evt.target.validity.valid;
    if (this.strategy === ValidationStrategy.off) {
      return;
    }
    if (
      this.strategy === ValidationStrategy.immediate
      || valid
      || this.isValidated
    ) {
      this.isValidated = true;

      return this.immediateValidation(evt);
    } else if (this.strategy === ValidationStrategy.delay) {
      this.delayValidation(evt);
    }
  }
}

export default WValidationBox;
