
import styles from './Text.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@werkin/lib/helpers';

/**
 * @file Holds shared <Text> component
 * @since 0.2.0
 * @author Anton Komarenko <mi3ta@sent.as>
 *
 * Able to render translated text
 * with different styling
 */
const Text = props => {
  const {
    tag: Tag,
    className,
    color,
    variant,
    tooltip,
    ...rest
  } = props;

  /**
   * Desired classes
   */
  const classnames = classNames(
    `base ${Tag} ${color} ${variant}`,
    styles,
    className
  );

  return <Tag className={classnames} {...rest} title={tooltip}/>;
};

Text.defaultProps = {
  tag: 'span',
  color: 'warm-grey',
};

Text.propTypes = {
  /**
   * Define a DOM tag name for current text component
   * */
  tag: PropTypes.oneOf(['div', 'p', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'b']),
  /**
   * Some additional classes for Text component
   * */
  className: PropTypes.string,
  /**
   * Text color
   * */
  color: PropTypes.string,
  /**
   * Choose theme variant
   * */
  variant: PropTypes.string,
  /**
   * Tooltip text
   * */
  tooltip: PropTypes.string,
};

export default Text;
