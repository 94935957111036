import { html } from 'lit-html';
import styles from 'modules/Activity/activity.module.scss';
import ActivityTemplate from '../activity.view';
import { IActivityFragmentProps } from './fragment.interface';

class HighlightItem {
  public view: ActivityTemplate;

  constructor() {
    this.template = this.template.bind(this);
  }

  public template({ item, loading }: IActivityFragmentProps) {
    const {
      created,
      content: {
        text,
      },
      id,
      likes,
    } = item;

    return html`
      <div class=${styles.feed_item}>
        ${this.view.itemHead({ created, text })}
        ${this.view.social({ id, likes }, loading)}
      </div>
    `;
  }
}

export default HighlightItem;
