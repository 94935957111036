import React from 'react';
import PropTypes from 'prop-types';
import modalStyles from 'styles/components/modals/modal.module.scss';
import Icon from 'atoms/Icon';
import TechnicalRequirements from 'modules/Help/sections/TechnicalRequirements';

const TechnicalRequirementsModal = ({ close }) => (
  <div className={modalStyles.wrapper}>
    <div className={modalStyles.container}>
      <div className={modalStyles.close} onClick={close}>
        <Icon name='close-circle'/>
      </div>

      <TechnicalRequirements/>
    </div>
  </div>
);

TechnicalRequirementsModal.propTypes = {
  close: PropTypes.func,
};

export default TechnicalRequirementsModal;
