import React from 'react';
import PropTypes from 'prop-types';
import styles from 'modules/Poll/Poll.module.scss';
import Text from 'atoms/Text';
import Icon from 'atoms/Icon';
import Button from 'atoms/Button';
import Textarea from 'atoms/Textarea';
import store from '@werkin/store';
import { useMutation } from '@apollo/react-hooks';
import respondToPollMutation from '../../graphql/mutations/respondToPoll.graphql';
import { manageUnmount } from 'lib/react-component-manager';
import { NotificationManager } from 'services';

const Buttons = ({ data, onComplete }) => {
  const [activeInput, setActiveInput] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const inputRef = React.useRef(React.createRef());
  const [inputClass, setInputClass] = React.useState('');

  const handleBtnClick = React.useCallback((button) => {
    if (button.type === 'input') {
      setActiveInput(button);
      store.set('pollActiveInput', true);
      setTimeout(() => {
        setInputClass(styles.active);
        setTimeout(() => inputRef.current.focus(), 300);
      }, 300);
    } else if (button.type === 'submit') {
      onComplete(button.text);
    }
  }, [inputRef, setActiveInput, onComplete]);

  const handleBack = React.useCallback(() => {
    setInputClass('');
    store.set('pollActiveInput', false);
    setTimeout(() => {
      setActiveInput(null);
      setInputValue('');
    }, 300);
  }, [setInputClass, setInputValue, setActiveInput]);

  const handleSend = React.useCallback(() => {
    onComplete(inputValue);
    handleBack();
  }, [inputValue, handleBack, onComplete]);

  return (
    <div className={styles.choose_wrapper}>
      <div className={`${styles.choose_content_wrapper} ${activeInput ? styles.input : ''}`}>
        <div className={styles.choose_content}>
          <div className={styles.text}>{data.text}</div>
          <div className={styles.buttons}>
            {data.options.map((b, i) =>
              (
                <div key={i} onClick={() => handleBtnClick(b)}>
                  <Icon name={b.icon}/>
                  <Text color='deep-teal'>{b.text}</Text>
                </div>
              ))}
          </div>
        </div>
        {activeInput && <div className={styles.choose_content}>
          <div className={styles.field}>
            <Text color='dark-grey'>{activeInput.description}</Text>
            <Textarea
              reference={inputRef}
              rows='1'
              value={inputValue}
              className={`${styles.input} ${inputClass}`}
              placeholder={activeInput.placeHolder}
              onChange={e => setInputValue(e.target.value)}
            />
          </div>
          <div className={styles.report_buttons}>
            <Button onClick={handleBack} variant='primary-outline'>Back</Button>
            <Button onClick={handleSend} variant='primary' >Send</Button>
          </div>
        </div>}
      </div>
    </div>
  );
};

Buttons.propTypes = {
  data: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

const Choice = ({ data, onComplete }) => {

  const options = React.useMemo(() => {
    const startCharCode = 'A'.charCodeAt(0);

    return data.options.map((d, i) =>
      ({ ...d, key: String.fromCharCode(i + startCharCode) }));
  }, [data]);

  return (
    <div className={styles.options_wrapper}>
      <Text color='dark-grey'>{data.text}</Text>

      <div className={styles.list}>
        {
          options.map((b) =>
            <div key={b.key}>
              <Text color='dark-grey' variant='bold'>{b.key}</Text>
              <Text color='dark-grey'>{b.text}</Text>
            </div>)
        }
      </div>

      <div className={styles.buttons}>
        {
          options.map((b) =>
            <div key={b.key} onClick={() => onComplete(b.text)}>
              <Text color='deep-teal'>{b.key}</Text>
            </div>)
        }
      </div>
    </div>
  );
};

Choice.propTypes = {
  data: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

const Poll = ({ poll }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [minMaxIcon, setMinMaxIcon] = React.useState('poll-maximise');

  const [respondToPoll] = useMutation(respondToPollMutation);

  React.useEffect(() => {
    if (poll.state === 'open') {
      setTimeout(() => setIsOpen(true), 200);
      setTimeout(() => setMinMaxIcon('poll-minimise'), 350);
    } else {
      setIsOpen(false);
    }
  }, [poll.state]);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      store.unset('activePoll');
      manageUnmount('poll');
    }, 500);
  }, [setIsOpen]);

  const handleToggle = React.useCallback(async () => {
    respondToPoll({
      variables: {
        command: {
          pollId: poll.id,
          state: isOpen ? 'MINIMIZED' : 'OPEN',
        },
      },
    }).then(() => {
      const toggledPoll = { ...poll, state: isOpen ? 'minimized' : 'open'  };
      store.set('activePoll', toggledPoll);
      setTimeout(() => setMinMaxIcon(isOpen ? 'poll-maximise' : 'poll-minimise'), 150);
    });
  }, [respondToPoll, isOpen, setMinMaxIcon, poll]);

  const handleComplete = React.useCallback((response) => {
    respondToPoll({
      variables: {
        command: {
          pollId: poll.id,
          state: 'COMPLETED',
          response,
        },
      },
    });

    NotificationManager.showNotification({ text: 'Thanks for telling us.', type: 'success', close: false });
    handleClose();
  }, [respondToPoll, handleClose, poll.id]);

  const handleDecline = React.useCallback(() => {
    respondToPoll({
      variables: {
        command: {
          pollId: poll.id,
          state: 'DECLINED',
        },
      },
    });

    handleClose();
  }, [respondToPoll, handleClose, poll]);

  const wrapperClass = React.useMemo(() => {
    let initialClass = styles.wrapper;

    if (isOpen) {
      initialClass = initialClass.concat(` ${styles.opened}`);
    }

    return initialClass;
  }, [isOpen]);

  return (
    <div className={wrapperClass}>
      <div className={styles.header}>
        <Text tag='h5' color='greyish-teal'>{poll.title}</Text>

        <div className={styles.controls}>
          <div onClick={handleToggle}>
            <Icon name={minMaxIcon}/>
          </div>
          <div onClick={handleDecline}>
            <Icon name='poll-close'/>
          </div>
        </div>
      </div>
      { {
        button: <Buttons onComplete={handleComplete} data={poll}/>,
        choice: <Choice onComplete={handleComplete} data={poll}/>,
      }[poll.type] }
    </div>
  );
};

Poll.propTypes = {
  poll: PropTypes.object.isRequired,
};

export default Poll;
