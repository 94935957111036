import apollo from 'api';
import { Router } from 'services/router.service';
import Controller from 'services/templator/controller';
import ActionsQuery from '../../graphql/queries/actions.graphql';

class CalendarController extends Controller {
  onInit() {
    if (!this.isCalendarVisible) { return; }

    this.watchActions();
  }

  watchActions() {
    const observe = apollo.watchQuery({
      query: ActionsQuery,
    }).subscribe({
      next: ({ data: { actions } }) => {
        const dates = actions.map(event => JSON.parse(event.data).startTime);

        this.setStateByKey('highlighted', dates);
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  get isCalendarVisible() {
    return Router.route.name === 'calendar' && Router.display === 'desktop';
  }

  onChange(date) {
    this.router.go({ name: 'calendar' });
    this.module.store.set('selectedDate', date);
  }
}

export default CalendarController;
