import ErrorHandler from 'services/templator/error';

function isConstructor(f) {
  try {
    return new f();
  } catch (err) {
    return !(
      (err.message.indexOf('is not a constructor') >= 0)
      || (err.message.indexOf('Object doesn\'t support this action') >= 0)
    );
  }
}

class Template extends ErrorHandler {
  constructor(module, data = {}) {
    super();

    this.directives_bind = {};
    this.controller_bind = {};
    this.styles_bind = {};
    this.module = module;
    this.data = data;
    this.view_bind = this;
    this.components_bind = {};
    this.fragments_binds = {};
  }

  set directives(Directives) {
    this.directives_bind = new Directives(this.view_bind);
  }

  set controller(Controller) {
    if (isConstructor(Controller)) {
      this.controller_bind = new Controller(this.view_bind);

      return;
    }

    if (Controller.controller) {
      this.controller_bind = new Controller.controller(this.view_bind, Controller.plugins);

      return;
    }

    throw new Error('' +
      'Expected controller constructor as argument or object with required property' +
      ' controller where value should be new controller constructor'
    );
  }

  set styles(styles) {
    this.styles_bind = styles;
  }

  get directives() {
    return this.directives_bind;
  }

  get controller() {
    return this.controller_bind;
  }

  get styles() {
    return this.styles_bind;
  }

  get router() {
    return this.module.router;
  }

  get components() {
    return this.components_bind;
  }

  get fragments() {
    return this.fragments_binds;
  }

  addFragment(name, fragment) {
    fragment.view = this;

    this.fragments_binds[name] = fragment;
  }
}

export default Template;
