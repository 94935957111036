import apollo from 'api';
import Directive from 'services/templator/directive';
import { directive, html } from 'lit-html';
import CollectivesQuery from '../../../graphql/queries/collectives.graphql';

class CollectiveDirectives extends Directive {
  get getCollective() {
    return directive(() => async (part) => {
      const observe = apollo.watchQuery({
        query: CollectivesQuery,
      }).subscribe({
        next: ({ data: { collectives } }) => {
          const { avatar, name } = collectives.find(({ isActive }) => isActive);

          part.setValue(html`
            <w-avatar avatar="${avatar}" title="${name}" width="40px" borderWidth="1"> </w-avatar>
            ${name}
          `);
          part.commit();
        },
        error: (e) => console.error(e),
      });

      this.controller.module.subs.add(() => observe.unsubscribe());
    });
  }
}

export default CollectiveDirectives;
