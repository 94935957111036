import { render } from 'lit-html';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';
import ProfileNotificationsAndEmailsController from './profile-notifications-and-emails.controller';
import ProfileNotificationsAndEmailsTemplate from './profile-notifications-and-emails.view';

@RouterModule({
  name: 'w-profile-notifications-and-emails-module',
  view: ProfileNotificationsAndEmailsTemplate,
  controller: ProfileNotificationsAndEmailsController,
})
class ProfileNotificationsAndEmailsModule {
  public view: ProfileNotificationsAndEmailsTemplate;
  public subs: Set<() => void> = new Set([]);

  public async loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default ProfileNotificationsAndEmailsModule;
