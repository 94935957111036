import store from '@werkin/store';
import { observable } from 'decorators';
import { ICollective } from 'interfaces';
import dataService from 'services/data';
import collectivesService from 'services/data/collectives.service';

class NotAssignedController {
  @observable([]) public collectives: ICollective[];
  @observable(true) public loading: boolean;

  public async OnInit() {
    const collectives = await collectivesService.getCollectives();

    this.collectives = collectives;
    this.loading = false;
  }

  public selectCollective(collectiveId) {
    store.set('loading', true);

    return dataService.changeCollective(collectiveId);
  }
}

export default NotAssignedController;
