import { observable } from 'decorators';
import { Auth } from 'services';
import { Router } from 'services/router.service';

class ForgotPasswordController {
  @observable() public valid: boolean;
  @observable() public loading: boolean;
  @observable() public error: boolean;

  public link(e, route?) {
    e.preventDefault();

    route && Router.go(route);
  }

  public onChange(evt) {
    evt.preventDefault();

    const { valid } = evt.target.controls.get('email');

    this.valid = valid;
  }

  public async onSend(evt) {
    evt.preventDefault();

    try {
      this.loading = true;

      await Auth.forgotPassword(evt.target.email.value);

      Router.go({ name: 'reset-password', state: { email: evt.target.email.value } });
    } catch (e) {
      let error = null;

      if (e.name === 'UserNotFoundException') {
        error = 'User with this email was not fount. Please check email and try again.';
      }


      this.error = error;
      this.valid = false;
    } finally {
      this.loading = false;
    }
  }
}

export default ForgotPasswordController;
