import apollo from 'api';
import { observable } from 'decorators';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';
import WeeklyStatsQuery from '../../graphql/queries/weeklyStats.graphql';
import DashStatsModule from './dash-stats.module';

class DashStatsController {
  public module: DashStatsModule;
  @observable(null) public stats: any;
  @observable(null) public profile: any;

  public async OnInit() {
    this.watchProfile();
    this.fetchStats();
  }

  private watchProfile() {
    const observe = apollo.watchQuery({
      query: ProfileQuery,
    }).subscribe({
      next: ({ data: { profile: profileData } }) => {
        this.profile = profileData;
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  private async fetchStats() {
    const { data: { weeklyStats} } = await apollo.query({
      query: WeeklyStatsQuery,
    });

    this.stats = weeklyStats;
  }
}

export default DashStatsController;
