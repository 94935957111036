import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import authService from 'services/auth.service';
import { Router } from 'services/router.service';

import CreditsLeaderboardController from './credits-leaderboard.controller';
import styles from './credits-leaderboard.module.scss';

class CreditsLeaderboardView {
  public controller: CreditsLeaderboardController;

  @Observe('leaderboard')
  public get displayLeaderboard() {
    const { leaderboard } = this.controller;

    if (!leaderboard) {
      return nothing;
    }

    return html`
      <div class=${styles.leaders}>
        ${this.displayLeaders(leaderboard)}
      </div>

      <div class=${styles['individuals-list']}>
        ${this.displayIndividuals(leaderboard)}
      </div>
    `;
  }

  public displayLeaders({ leaders }) {
    if (!leaders.length) { return; }

    const displayLeader = ({ name, avatar, value, id }, place) => {
      const isMe = authService.getUserId() === id;

      return html`
        <div class=${styles.leader}>
          <div class="${styles.avatar} ${isMe && styles.me}">
            <w-avatar
              borderWidth=8
              borderColor="#fff"
              title=${name}
              avatar=${avatar}
              width=${Router.display === 'mobile' ? '95px' : '115px'}
            > </w-avatar>
          </div>
          <div class="${styles['place-badge']} ${styles['badge-' + place]}">${place}</div>
          <div class=${styles.name}>
            ${this.controller.getFormattedName(id, name)}
          </div>
          <div class=${styles.score}>${value}</div>
        </div>
      `;
    };

    return html`
      ${displayLeader(leaders[1], 2)}
      ${displayLeader(leaders[0], 1)}
      ${displayLeader(leaders[2], 3)}
    `;
  }

  public displayIndividuals({ individuals }) {
    const displayIndividual = ({ name, avatar, value, id }) => {
      const isMe = authService.getUserId() === id;

      return html`
        <div class=${isMe && styles.me}>
          <div class=${styles['individual-item']}>
            <div class=${styles.avatar}>
              <w-avatar
                borderWidth=0
                title=${name}
                avatar=${avatar}
                width=${Router.display === 'mobile' ? '35px' : '50px'}
              > </w-avatar>
            </div>
            <div class=${styles.name}>
              ${this.controller.getFormattedName(id, name)}
            </div>
            <div class=${styles.score}>${value}</div>
          </div>
        </div>
      `;
    };

    return html`
      ${repeat(individuals, displayIndividual)}
    `;
  }

  @Observe('options', 'searchTag')
  get categorySelect() {
    return html`
      <form @changeForm=${(evt) => this.controller.onChangeSearchTag(evt)}>
        <w-box label="Filter by:">
          <w-select
            name="credits-leaderboard"
           .selected=${this.controller.searchTag}
            vRequired
           .options=${this.controller.options}
          />
        </w-box>
      </form>
    `;
  }

  get template() {
    return html`
      <div class="credits-wrapper">
        <div class='credits-wrapper-background'></div>
        <div class="search-card">
          <h1 class=${styles.title}>Leaderboards</h1>
          <p class=${styles.blurb}>Leaderboards update hourly (on the hour), so points may differ from your profile.</p>
          ${this.categorySelect}
        </div>

        <div class=${styles.content}>
          ${this.displayLeaderboard}
        </div>
      </div>
    `;
  }
}

export default CreditsLeaderboardView;
