import { html } from 'lit-html';
import { Icons } from 'components/lit';
import Template from 'services/templator/template';

class LoadButtonTemplate extends Template {
  get button() {
    return html`<button class=${this.styles.load_btn} @click=${this.controller.installApp}>${Icons.download}</button>`;
  }

  get template() {
    return html`
      ${this.directives.showIf(!!this.controller.pwaEvent, this.button)}
    `;
  }
}

export default LoadButtonTemplate;
