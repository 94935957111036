/**
 * @file Holds utility to work with locales
 * @since 0.3.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import localeData from 'mock/Translations'; // TODO: Replace with data coming from backend
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';

/**
 * Adding locale to operate with
 */
addLocaleData([...en, ...ru]);

/**
 * Get user's language. Different browsers have the user locale defined
 * on different fields on the `navigator` object, so we make sure to account
 * for these different by checking all of them
 */
export const locale =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

/**
 * Splitting locales with a region code
 */
const localeWithoutRegionCode = locale.toLowerCase().split(/[_-]+/)[0];

/**
 * Try full locale, try locale without region code
 * with fallback to 'en'
 */
export const translations =
  localeData[localeWithoutRegionCode] ||
  localeData[locale] ||
  localeData.en;
