import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import GetLinkController from './get-link.controller';
import GetLinkView from './get-link.view';

@RouterModule({
  name: 'w-get-link-module',
  view: GetLinkView,
  controller: GetLinkController,
})
class GetLinkModule {
  public view: GetLinkView;

  public mount(el) {
    render(this.view.template, el);
  }
}

export default GetLinkModule;
