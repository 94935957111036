import apollo from 'api';
import BaseService from 'services/data/base.service';
import programmeOverview from '../../graphql/queries/programmeOverview.graphql';

class ProgrammeOverview extends BaseService {
  public async getProgrammeOverview() {
    return apollo
      .query({ query: programmeOverview, fetchPolicy: 'network-only' })
      .then((
        {
          data: {
            programmeOverview,
          },
        }
      ) => programmeOverview);
  }
}

export default new ProgrammeOverview();
