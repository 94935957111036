import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import RegistrationClosedView from './registration-closed.view';

@RouterModule({
  name: 'w-registration-closed-module',
  view: RegistrationClosedView,
})
class RegistrationClosedModule {
  public view: RegistrationClosedView;

  public mount(module) {
    render(this.view.template, module);
  }
}

export default RegistrationClosedModule;
