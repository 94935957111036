import { observable } from 'decorators';
import { REST } from 'services';
import { Router } from 'services/router.service';

class RegisterController {
  @observable() public error: string | undefined;
  @observable(false) public valid: boolean;
  @observable(false) public loading: boolean;

  public OnInit() {
    if (!Router.state.email) {
      Router.go({ name: 'get-link' });
    }
  }

  public onChange(evt) {
    evt.stopPropagation();

    this.error = undefined;
    this.valid = Array.from(evt.target.controls).every(([, { valid }]) => valid);
  }

  public async onSubmit(evt) {
    evt.preventDefault();
    this.loading = true;
    this.valid = false;

    const data: any = Array.from(evt.target.controls).reduce((acc, [k, v]) => {
      acc[k] = v.value;

      return acc;
    }, {});

    try {
      await REST.register(data);

      const { data: { route } } = await REST.getLink(data.emailAddress);
      const state = route === '/temporary' ? { email: data.emailAddress } : undefined;

      Router.go({ path: route, state });
    } catch (e) {
      this.error = e.message;
    } finally {
      this.loading = false;
    }
  }
}

export default RegisterController;
