import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import DashConnectivityController from './dash-connectivity.controller';
import DashConnectivityView from './dash-connectivity.view';

@RouterModule({
  name: 'w-dash-connectivity-module',
  controller: DashConnectivityController,
  view: DashConnectivityView,
})
class DashConnectivityModule {
  public subs: Set<() => void> = new Set([]);
  public view: DashConnectivityView;

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default DashConnectivityModule;
