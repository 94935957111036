import { Observe } from 'decorators';
import { showIf } from 'directives';
import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import ProfileProfessionalDevelopmentController from './profile-professional-development.controller';
import styles from './profile-professional-development.module.scss';

class ProfileProfessionalDevelopmentTemplate {
  private controller: ProfileProfessionalDevelopmentController;

  private displayHeader(label, handler) {
    return html`
      <div class=${styles['card-title']}>
        <span>${label}</span>

        <button
          @click=${handler}
          class=${styles['button-outlined']}
        >
          Edit
        </button>
      </div>
    `;
  }

  @Observe('professionalDevelopment', 'careerStages')
  private get careerStage() {
    const { careerStage, careerStages, onEdit } = this.controller;

    return careerStages && html`
      ${this.displayHeader('Current career stage', () => onEdit('careerStage'))}
      <div class=${styles['card-content']}>
        ${careerStage.label}
      </div>
    `;
  }

  public goalsItem({ label, goal }, index) {
    const displayGoal = html`
      <div class=${styles['area-goal']}>
        Goal: ${goal}
      </div>
    `;

    const shareGoalButton = html`
      <button
        @click=${() => this.controller.onShareGoal()}
        class="${styles['button-outlined']} ${styles['goals-button']}"
      >
        Share
      </button>
    `;

    return html`
      <div class=${styles['area-info']}>
        <div class=${styles['area-label']}>${label}</span>
        ${showIf(goal, displayGoal)}
      </div>
      ${showIf(!index && this.controller.areas.some(({ goal }) => !!goal), shareGoalButton)}
    `;
  }

  get setGoals() {
    return html`
      <div class=${styles.setgoals_wrapper}>
        <button
          @click=${() => this.controller.onEdit('learningAndDevelopmentAreas')}
        >
          Set Goals
        </button>
      </div>
    `;
  }

  @Observe('professionalDevelopment', 'pureAreas')
  private get displayGoals() {
    const { areas, pureAreas } = this.controller;

    return pureAreas && html`
      <div class=${styles.card}>
        ${this.displayHeader(
          'Learning & Development Areas',
          () => this.controller.onEdit('learningAndDevelopmentAreas'))
        }
        ${repeat(areas, this.goalsItem.bind(this))}
        ${this.setGoals}
      </div>
    `;
  }

  @Observe('professionalDevelopment')
  private get relationshipsSection() {
    const { relationships } = this.controller.professionalDevelopment;
    const keys = (v, i) => i;
    const displayMentoringRelationship = (relationship) => this.displayMentoringRelationship(relationship);

    return relationships.length ? html`
      <div class=${styles.card}>
        <div class=${styles['card-title']}>
          Active mentoring relationships
        </div>
        ${ relationships.length ? repeat(relationships, keys, displayMentoringRelationship) : nothing }
      </div>
    ` : nothing;
  }

  private displayMentoringRelationship(relationship) {
    const userLink = html`
      <span
        class=${styles.link}
        @click=${() => this.controller.goToUserProfile(relationship)}
      >${relationship.name}</span>`;

    const content = {
      mentor: html`${userLink} is helping you achieve your career goals`,
      mentee: html`You are helping ${userLink} achieve their career goals`,
      peer: html`You have been buddied with ${userLink}. Share your experiences and empower each other to move forward`,
    }[relationship.role];

    return html`
      <div class=${styles.mentoring}>
        <div>${content}</div>
        <button
          @click=${() => this.controller.openContractModal(relationship)}
          class=${styles['button-outlined']}
        >
          Promise
        </button>
      </div>
    `;
  }

  get template() {
    return html`
      <div class=${styles['cards-wrapper']}>
        <h3 class=${styles.title}>Professional Development</h3>

        ${this.relationshipsSection}

        <div class=${styles.card}>
          <div class=${styles['mobile-title']}>Professional Development</div>

          ${this.careerStage}
        </div>

        ${this.displayGoals}
      </div>
    `;
  }
}

export default ProfileProfessionalDevelopmentTemplate;
