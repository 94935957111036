import { html } from 'lit-html';
import styles from './error.module.scss';

export default () =>
  html`
    <div class="${styles.title}">
      <p>Uh oh…</p>
      <p>something’s not WERKIN</p>
    </div>
    <div class="${styles.suggestion}">
      <a href="/">Click here</a> to see if that fixes it or get in touch: <a href='mailto:support@getwerkin.com'>support@getwerkin.com</a>
    </div>
  `;
