import { directive } from 'lit-html';
import { Router } from 'services/router.service';
import { DynamicListeners } from 'services/router/router.interfaces';
import Sentry from 'services/sentry.service';

export function AutonomousModal(modalName, ...relatedHashKeys) {
  return (Modal) =>
    class extends Modal {
      public modal_name = modalName;

      public open() {
        super.open();

        document.body.style.position = 'fixed';

        const unsubscribe = Router.listenDynamic(DynamicListeners.hash, () => {
          if (!Router.hash[modalName]) {
            if (document.body.contains(this.modalContainer)) {
              document.body.removeChild(this.modalContainer);
            }
            unsubscribe && unsubscribe();
          }
        });
      }

      public onDestroy() {
        super.onDestroy();
        document.body.style.position = 'relative';

        Router.deleteHash(modalName, ...relatedHashKeys);
      }
    } as typeof Modal;
}

export function event(): any {
  return (target, propertyKey: string) => ({
    get() {
      return () => {
        this.triggerListeners(propertyKey);

        return true;
      };
    },
  });
}

export function ObserveEvent(...eventNames) {
  return (target, propertyKey: string, descriptor: PropertyDescriptor) => {
    const isGetter = !!descriptor.get;
    const originFn = isGetter ? descriptor.get : descriptor.value;

    const dir = function(...args) {
      return directive( () => (part) => {
        part.setValue(originFn.call(this, ...args));

        const unsubscribe = this.subscribe(() => {
          setImmediate(() => {
            try {
              if (part.startNode.isConnected) {
                part.setValue(originFn.call(this, ...args));
                part.commit();
              } else {
                unsubscribe();
              }
            } catch (e) {
              const errMsg = `Error in ${this.modal_name}/${target.constructor.name} [${propertyKey}] \n`;
              console.error(errMsg, e);
              Sentry.captureException(e);
            }
          });
        }, ...eventNames);

        this.subscribe(unsubscribe, 'destroy');
      })();
    };

    if (isGetter) {
      descriptor.get = dir;
    } else {
      descriptor.value = dir;
    }
  };
}

export function ObserveResizing() {
  return (target, propertyKey: string, descriptor: PropertyDescriptor) => {
    const isGetter = !!descriptor.get;
    const originFn = isGetter ? descriptor.get : descriptor.value;

    const dir = function(...args) {
      return directive( () => (part) => {
        part.setValue(originFn.call(this));

        const unsubscribe = Router.subscribeOnResizing(() => {
          setImmediate(() => {
            if (part.startNode.isConnected) {
              part.setValue(originFn.call(this, ...args));
              part.commit();
            } else {
              unsubscribe();
            }
          });
        });

        this.subscribe(unsubscribe, 'destroy');
      })();
    };

    if (isGetter) {
      descriptor.get = dir;
    } else {
      descriptor.value = dir;
    }
  };
}
