import styles from 'components/custom/w-input.module.scss';
import { html, nothing, svg } from 'lit-html';
import { customElement } from './decorators';
import Element from './Element';


@customElement({name: 'w-search'})
class WSearch extends Element {
  private value: string = '';
  private name: string;
  private timeout: any;
  private placeholder: string;

  get label() {
    return this.getAttribute('label');
  }

  get variant() {
    return this.getAttribute('variant') || 'default';
  }

  get clear() {
    return svg`
      <svg viewBox="0 0 93 93">
        <g fill="none" fill-rule="evenodd" transform="rotate(-45 48.653 41.366)">
          <path fill="#4CB49C" stroke="#4CB49C" stroke-width="1.85" d="M23.639 43.998A1.447 1.447 0 0 1 22.2 42.54a1.43 1.43 0 0 1 1.439-1.438h17.45V23.659a1.467 1.467 0 0 1 1.462-1.459c.801 0 1.438.658 1.438 1.459v17.443H61.44c.802 0 1.46.636 1.46 1.438 0 .8-.658 1.458-1.46 1.458H43.99v17.443c0 .801-.638 1.459-1.44 1.459-.801 0-1.459-.658-1.459-1.459V43.998H23.64z"/>
          <circle cx="43.475" cy="43.475" r="43.475" stroke="#014660" stroke-width="5.55"/>
        </g>
      </svg>
    `;
  }

  get searchDelay() {
    return parseInt(this.getAttribute('searchdelay') || '300', 10);
  }

  static get observedAttributes() {
    return ['name', 'placeholder', 'value'];
  }

  public attributeChangedCallback(name, oldValue, newValue) {
    this[name] = newValue;

    if (name === 'value') { this.update(); }
  }

  get template() {
    return html`
      <div class="${styles['w-search-wrapper']} ${styles[this.variant]}">
        <label>${this.label}</label>
        <div>
          <input
            type="text"
            name="${this.name}"
            class="${styles['w-input']}"
            autofocus
            @input=${(e) => this.onInput(e)}
            .value="${this.value}"
            placeholder="${this.placeholder}"
          />
          <div @click=${(evt) => this.clearSearch(evt)} class="${styles['clear-wrapper']}">
            ${this.value ? this.clear : nothing}
          </div>
        </div>
      </div>
    `;
  }

  private clearSearch(e) {
    const evt = new CustomEvent('clear', { bubbles: true } );

    this.value = '';
    this.dispatchEvent(evt);
    this.update();
  }

  private setValue(value = '') {

    this.value = value;
    this.update();
  }

  private callChange(el) {
    clearTimeout(this.timeout);

    if (!this.value.length) {
      this.setValue();

      return;
    }

    this.timeout = setTimeout(() => {
      const evt = new CustomEvent('search', { bubbles: true  });

      el.dispatchEvent(evt);
    }, this.searchDelay);
  }

  private onInput = (evt) => {
    this.setValue(evt.target.value);
    this.callChange(evt.target);
  }
}

export default WSearch;
