import apollo from 'api';
import { IFrequentlyAskedQuestionCategory } from 'interfaces/credits/faq.interface';
import BaseService from 'services/data/base.service';
import frequentlyAskedQuestionsQuery from '../../graphql/queries/frequentlyAskedQuestions.graphql';

class FaqsService extends BaseService {
  public getQuestions() {
    return apollo
      .query({ query: frequentlyAskedQuestionsQuery, fetchPolicy: 'no-cache' })
      .then((
        {
          data: {
            frequentlyAskedQuestions: questions,
          },
        }
      ): IFrequentlyAskedQuestionCategory[] => questions);
  }
}

export default new FaqsService();
