import { VALIDATION_STATES } from 'enums';
import { IValidation } from 'interfaces';

const MIN_CHARACTERS = 8;

const regex = {
  phone: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/,
  email: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))*$/,
  url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  name: /^[A-Za-z\-_`'" ]*$/,
  upperCaseCharacter: /[A-Z]/,
  lowerCaseCharacter: /[a-z]/,
  number: /[0-9]/,
  special: /[!@#$%^&*()_+\-=[\]{};':"\\|/.~<>`?\xA2-\xA5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u17DB\u20A0-\u20BD\uA838\uFDFC\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6]/,
  withoutSpaces: /^\S*$/,
};

class ValidatorHelper {
  public validation: IValidation = {
    length: null,
    number: null,
    special: null,
    uppercase: null,
    lowercase: null,
  };

  public email = (str) => regex.email.test(str);
  public name = (str) => regex.name.test(str);
  public lowercase = (str) => regex.lowerCaseCharacter.test(str);
  public uppercase = (str) => regex.upperCaseCharacter.test(str);
  public length = (str) => str.length >= MIN_CHARACTERS;
  public number = (str) => regex.number.test(str);
  public special = (str) => regex.special.test(str);
  public withoutSpaces = (str) => regex.withoutSpaces.test(str);

  get initValidation() {
    return {
      length: null,
      number: null,
      special: null,
      uppercase: null,
      lowercase: null,
    };
  }

  public validate(password) {
    let passedRulesCount = 0;

    Object.keys(this.validation).forEach((key: string) => {
      this.validation[key] = this[key](password) ? VALIDATION_STATES.VALID : VALIDATION_STATES.INVALID;

      if (this.validation[key] === VALIDATION_STATES.VALID) {
        passedRulesCount++;
      }
    });

    const isValid = passedRulesCount === Object.keys(this.validation).length;

    return { validation: this.validation, isValid };
  }
}

export default ValidatorHelper;
