import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import HeaderCommunityController from './header-community.controller';
import HeaderCommunityView from './header-community.view';

@RouterModule({
  name: 'w-header-community-module',
  view: HeaderCommunityView,
  controller: HeaderCommunityController,
})
class HeaderCommunityModule {
  public subs = new Set([]);
  public view: HeaderCommunityView;

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default HeaderCommunityModule;
