import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import DashProfileCompletionController from './dash-profile-completion.controller';
import DashProfileCompletionView from './dash-profile-completion.view';

@RouterModule({
  name: 'w-dash-profile-completion-module',
  controller: DashProfileCompletionController,
  view: DashProfileCompletionView,
})
class DashProfileCompletionModule {
  public subs: Set<() => void> = new Set([]);
  public view: DashProfileCompletionView;

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default DashProfileCompletionModule;
