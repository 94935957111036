import { Observe } from 'decorators';
import { showIf } from 'directives';
import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import buttonStyles from 'styles/button.module.scss';
import cardStyles from 'styles/card.module.scss';
import DashGoalsController from './dash-goals.controller';
import styles from './dash-goals.module.scss';

class DashGoalsTemplate {
  public controller: DashGoalsController;

  get message() {
    const { hasGoals } = this.controller;

    return hasGoals ? html`
      <p>
        Did you know that you can share your goals with others? Share with your
        match or the people in the community who can support you.
      </p>
    ` : html`
      <p>
        What would you like to achieve during the programme and how can your match,
        and the community, help you get there?
      </p>
    `;
  }

  public focus({area, goal, label}) {
    const { hasGoals, handleChange } = this.controller;

    return html`
      <div class=${styles.focus}>
        <h3>
          Focus area: ${label}
          ${showIf(!hasGoals, html`
            <button
              class=${styles['change-button']}
              @click=${handleChange}
            >
              (change?)
            </button>
          `)}
        </h3>
        <p class=${styles.goal}>${goal ? goal : 'No goal set yet!'}</p>
      </div>
    `;
  }

  get buttons() {
    const { hasGoals, handleChange, handleShare } = this.controller;

    return html`
      <div class=${styles.buttons}>
        <button
          class=${buttonStyles.small}
          @click=${handleChange}
        >
          ${hasGoals ? 'Update goals' : 'Set my goals now'}
        </button>
        ${showIf(hasGoals, html`
          <button class=${buttonStyles.small} @click=${handleShare}>Share goals</button>
        `)}
      </div>
    `;
  }

  @Observe('userGoals')
  get template() {
    const { userGoals } = this.controller;

    return html`
      <div class=${cardStyles.card}>
        <div class=${cardStyles.header}>
          <span class=${cardStyles.label}>Your goals</span>
        </div>
        <div class=${cardStyles.content}>
          ${userGoals.length && this.message}
          ${repeat(userGoals, ({ area }) => area, (goal) => this.focus(goal))}
          ${userGoals.length && this.buttons}
        </div>
      </div>
    `;
  }
}

export default DashGoalsTemplate;
