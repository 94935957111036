import Fragment from 'services/templator/fragment';
import { html } from 'lit-html';
import { Icons } from 'components/lit';

class SearchFragment extends Fragment {
  setOptions({ name, placeholder, label }) {
    this.name = name;
    this.label = label;
    this.placeholder = placeholder;
  }

  get clear_button() {
    return html`
      <button
        type="button"
        @click=${() => this.controller.onSearch({ target: { value: '', name: this.name } })}
        class="${this.styles.clear}"
      >
        ${Icons.circleCross}
      </button>
    `;
  }

  get template() {
    return html`
      <div class="${this.styles.search}">
        ${ this.label ? html`<label for=${this.name}>Search name</label>` : '' }
        <div class="${this.styles.box}">
          <input
            .value="${this.controller.state[this.name]}"
            type="text"
            name=${this.name}
            placeholder=${this.placeholder}
            @input=${evt => this.controller.onSearch(evt)}
           />
          ${this.controller.state[this.name] ? this.clear_button : ''}
        </div>
      </div>
    `;
  }
}

export default SearchFragment;
