import CenterAutonomousModal from 'components/lit/modals/center-autonomus-modal.view';
import { AutonomousModal } from 'decorators';
import { Router } from 'services/router.service';

@AutonomousModal('LogoutModal')
class LogoutModal extends CenterAutonomousModal {
  public onOpen() {
    this.title = 'Logout on this device?';
    this.text = `
      This means you will no longer receive any
      push notifications from WERKIN on this
      device and your match may not be able to
      contact you if you are not logged in on
      another device. Are you sure?
    `;
    this.submitBtnText = 'Log out';

    this.template = this.wrap(this.tmp);
  }

  public async submitForm() {
    Router.deleteHash('LogoutModal');
    Router.controller.makeLogout();
  }
}

export default LogoutModal;
