import React from 'react';
import styles from './Wrapper.module.scss';
import Icon from 'atoms/Icon';
import Text from 'atoms/Text';
import { useOpenModal } from 'hooks/useModalState';
import FAQ from 'modules/Help/sections/FAQ';
import { Router } from 'services/router.service';

const links = [
  { text: 'Is my browser supported?', key: 'TechnicalRequirementsModal' },
  { text: 'Suggest an improvement', key: 'SupportModal', type: 'suggest' },
  { text: 'Report a problem', key: 'SupportModal', type: 'report' },
];

const Wrapper = () => {

  const container = React.useRef();

  const openModal = useOpenModal();

  const handleOnOpen = (key, type) => openModal(key, { type });

  return (
    <div className={styles.wrapper}>
      <div className={styles.nav}>
        <div className={styles.back}>
          <Icon name='arrow-left' />
          <div onClick={() => Router.back()}>
            <Text className={styles.text}>Back to WERKIN</Text>
          </div>
        </div>
      </div>

      <div className={styles.container} ref={container}>
        <div className={styles.header}>
          <Text tag='h3' color='deep-teal'>How can we help you?</Text>
          <div className={styles.oval_1} />
          <div className={styles.oval_2} />
        </div>

        <FAQ parent={container} />
      </div>

      <div className={styles.block}>
        {links.map(({ text, key, type }, i) => (
          <div className={styles.card} onClick={() => handleOnOpen(key, type)} key={i}>
            <Text tag='h5' color='deep-teal'>{text}</Text>
            <Icon name='arrow-right-long' />
          </div>
        ))}

        <Text color='dark-grey'>Still need help?</Text>
        <a href='mailto:support@getwerkin.com' className='mt-5'>support@getwerkin.com</a>

        <div className={styles.links}>
          <Text color='dark-grey' className='mb-5'>App version {require('../../../package.json').version}</Text>
          <a target='_blank' rel='noopener noreferrer' href='https://getwerkin.com/terms-of-use'>Terms of Use</a>
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
