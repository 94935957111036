import React from 'react';
import styles from './Search.module.scss';
import Icon from 'atoms/Icon';
import Button from 'atoms/Button';
import { useWerkinStore } from 'hooks/useWerkinStore';

const Search = () => {
  const [mql] = useWerkinStore('mql');
  const input = React.useRef(React.createRef());
  const [value, setData] = useWerkinStore('generalSearch');

  const handleChange = e => setData(e.target.value);

  const handleClean = () => {
    setData('');
    input.current.focus();
  };

  return (
    <div className={styles.wrapper}>
      <Icon name='search'/>

      <input
        type='text'
        onChange={handleChange}
        value={value}
        ref={input}
        placeholder={
          ['mobile', 'laptop'].includes(mql)
            ? 'Find people, channels and groups'
            : 'Find people, channels and groups plus other content'
        }
      />

      {
        value &&
        <Button onClick={handleClean}>
          <Icon name='cross-circle' />
        </Button>
      }
    </div>
  );
};

export default Search;
