import { detect } from 'detect-browser';
import React from 'react';
import { Link, Router } from 'react-router-dom';
import history from 'utils/history';

export const browserState = {
  supported: 'supported',
  unsupported: 'unsupported',
  old: 'old',
};

/**
 * Provides browser detection using
 * detect-browser lib
 */
class Detector {

  constructor() {
    /**
     * Current browser information
     *
     * @type {object}
     */
    this.current = this.detect();

    /**
     * Sets if current browser mets general requirements
     *
     * @type {boolean}
     */
    this.isSupportedBrowser = this.isSupported(this.current);
  }

  /**
   * Getting current browser info
   * from UA
   *
   * @return {object}
   */
  detect() {
    return detect();
  }

  getError(supported) {
    switch (supported) {
    case browserState.unsupported:
      return <span>The browser you are using is unsupported and features
              may not be available or work as expected. Assess your browser
              and get recommendations <a href='/technical-requirements'>here</a></span>;
    case browserState.old:
      return ({ onClose }) => (
        <Router history={history}>
          <span>
            The version of {this.current.name} you are using is unsupported and some features may not be available or
            work as expected. Assess your browser and get recommendations&nbsp;
            <Link
              onClick={() => {
                onClose();
              }}
              to={'/technical-requirements'}
            >
            here
            </Link>
          </span>
        </Router>
      );
    default:
      return null;
    }

  }

  /**
   * Checks if browser mets the requirements
   * Function gets browser and requiremens list
   *
   * @prop browser {object} - browser information
   * @prop browser.name {string} - browser name. F.e. chrome
   * @prop browser.os {string} - client's Operating System. F.e. Linux
   * @prop browser.version {string} - browser version. F.e. 73.0.3683
   *
   *
   * @return {} - if browser supports
   */
  isSupported(browser) {
    const version = browser && parseFloat(browser.version);

    switch (browser && browser.name) {

    // /** Chrome for iOS **/
    case 'crios':
      return this.isOld(version >= 70);

    /** Chrome for Android v71+ and v49+ for Desktop **/
    case 'chrome':
      return browser.os === 'Android OS' ? this.isOld(version >= 71) : this.isOld(version >= 49);

      /** Safari for iOS  v12+ **/
    case 'safari':
    case 'ios':
      return this.isOld(version >= 12);

      /** Microsoft Edge V14+ **/
    case 'edge':
      return this.isOld(version >= 14);

      /** Firefox v54+ **/
    case 'firefox':
      return this.isOld(version >= 54);

      /** Opera v55+ **/
    case 'opera':
      return this.isOld(version >= 55);

      /** Unknown browser **/
    default:
      return browserState.unsupported;
    }
  }

  isOld(notOld) {
    return notOld ? browserState.supported : browserState.old;
  }
}

export default Detector;
