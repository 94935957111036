import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import { tabs } from './subnav.config';
import { Icons } from 'components/lit';
import Template from 'services/templator/template';
import { classMap } from 'lit-html/directives/class-map';

class SubNavTemplate extends Template {
  backButton() {
    return html`
      <button @click=${() => this.controller.goBack()} class="${this.styles.back}">${Icons.arrowLeftLong}</button>
    `;
  }

  button(tab) {
    const classes = {
      [this.styles.active]: this.controller.activePath === tab.pathName,
      [tab.class]: !!tab.class,
    };

    return html`
      <button
        @click=${() => tab.handler()}
        class="${classMap(classes)}"
      >
        ${tab.icon}
        ${this.directives.showIf(this.controller.isOpen, tab.text)}
      </button>
    `;
  }

  navTemplate() {
    const classes = {
      [this.styles.opened_wrapper]: this.controller.isOpen,
      [this.styles.closed_wrapper]: !this.controller.isOpen,
    };

    const isMobileActions = this.router.display === 'mobile' && this.router.route.name.includes('chats.actions');

    return isMobileActions ? null : html`
      <div class="${classMap(classes)}">
        ${this.directives.showIf(this.controller.isOpen && this.router.display !== 'mobile', this.backButton())}
        ${repeat(tabs, tab => tab.id, tab => this.button(tab))}
      </div>
    `;
  }

  get template() {
    return html`
      ${this.directives.renderSubNav()}
      ${this.directives.showIf(this.controller.isOpen, this.directives.renderSubContent())}
    `;
  }
}

export default SubNavTemplate;
