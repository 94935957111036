import apollo from 'api';
import {
  WorkerNotificationOptions,
  WorkerPermissionTypes,
} from 'interfaces';
import { Auth } from 'services';
import { Router } from 'services/router.service';
import { RegisterIndividualDeviceCommandInput } from '../graphql/graphql';
import RegisterDeviceMutation from '../graphql/mutations/registerDevice.graphql';
import ProfileService from './data/profile.service';

const { REACT_APP_VAPID_PUBLIC_KEY } = process.env;

class WorkerService {
  public permission: WorkerPermissionTypes;

  constructor() {
    if (!('serviceWorker' in navigator) || !('Notification' in window)) { return; }

    this.permission = Notification.permission;
    this.listenForRouteBroadcasts();
    this.subscribePushManager();
  }

  public get currentPermission() {
    return this.permission === 'default' ? 'prompt' : this.permission;
  }

  public promptForNotifications() {
    Notification.requestPermission((status) => {
      this.permission = status;
      this.subscribePushManager();
      ProfileService.setNotificationStatus(status);
    });
  }

  public async safariNotification(title: string, options: WorkerNotificationOptions) {
    try {
      const sw = await this.getServiceWorker();
      if (sw.pushManager || !this.isNotifiable) { return; }
      console.log(this.isNotifiable);
      const notification = new Notification(title, {
        ...options,
        icon: '/appicon/android-icon-144x144.png',
      });

      notification.onclick = () => {
        window.focus();
        options.data && options.data.url && Router.go({ path: options.data.url });
      };
    } catch (err) {
      console.error('safariNotification', err);
    }
  }

  public async subscribePushManager() {
    try {
      const sw = await this.getServiceWorker();
      if (!sw.pushManager || this.currentPermission !== 'granted') { return; }

      const subscription = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: REACT_APP_VAPID_PUBLIC_KEY,
      });

      this.handlePushSubscription(subscription);
    } catch (err) {
      if (Notification.permission === 'denied') {
        console.warn('WebPush: Permission for notifications was denied');
      } else {
        console.error('WebPush: Unable to subscribe to push', err);
      }
    }
  }

  public async unsubscribePushManager() {
    try {
      const sw = await this.getServiceWorker();
      const subscription = await sw.pushManager.getSubscription();

      await subscription.unsubscribe();
    } catch (err) {
      console.log('pushManager: unsubscribing failed', err);
    }
  }

  private async getServiceWorker() {
    return navigator.serviceWorker.ready;
  }

  private get isNotifiable() {
    return this.permission === 'granted' && (document.visibilityState === 'hidden' || !document.hasFocus());
  }

  private async listenForRouteBroadcasts() {
    try {
      const sw = await this.getServiceWorker();
      if (!sw.pushManager || this.permission !== 'granted') { return; }

      const channel = new BroadcastChannel('sw-route');

      channel.addEventListener('message', ({ data }) => {
        Router.go({ path: data });
      });
    } catch (err) {
      console.error('listenForRouteBroadcasts', err);
    }
  }

  private async handlePushSubscription(sub) {
    if (!Auth.getUserId()) { return; }

    const { endpoint, keys } = JSON.parse(JSON.stringify(sub));
    console.log(`pushManager: subscribing ${endpoint}`);

    const command: RegisterIndividualDeviceCommandInput = {
      endpoint,
      authKey: keys.auth,
      cryptoKey: keys.p256dh,
    };

    await apollo.mutate({
      mutation: RegisterDeviceMutation,
      variables: {
        command,
      },
    });
  }
}

export default WorkerService;
