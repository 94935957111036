import apollo from 'api';
import GiphyModal, { MyGif } from 'components/GiphyModal';
import { Icons } from 'components/lit';
import Quill from 'quill';
import Delta from 'quill-delta';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import React from 'react';
import ReactDOM from 'react-dom';
import { Auth } from 'services';
import uuid from 'uuid';
import sendChatMessage from '../../graphql/mutations/sendChatMessage.graphql';
import ChatQuery from '../../graphql/queries/chat/Chat.graphql';
import ChatsService from '../data/chats.service';
import REST from '../rest.service';
import { Router } from '../router.service';
import styles from './editor.module.scss';
import { mentionMenuItem } from './editor.view';
import connectEmojiBlot from './emoji/emoji.blot';
import connectEmojiModule from './emoji/emoji.module';
import connectImagesBlot from './images/images.blot';
import connectMentionsBlot from './mentions/mentions.blot';
import connectMentionsModule from './mentions/mentions.module';
import connectUrlModule from './url/url.module';

Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
const icons = Quill.import('ui/icons');
icons.gif = Icons.gif.strings.raw[0];

class Editor {
  public async message() {
    const {
      params: { chatId },
    } = Router;

    const {
      data: { chat },
    } = await apollo.query({
      query: ChatQuery,
      variables: {
        id: chatId,
      },
    });

    const mentions = this.value.ops.reduce((acc, item: any) => {
      if (item.insert && item.insert.mention) {
        acc.push(item.insert.mention.id);
      }

      return acc;
    }, []);

    return {
      chatId: chat.id,
      id: uuid(),
      message: JSON.stringify(this.value),
      sent: new Date().toISOString(),
      mentions,
      participants: chat.participants.map((member) => member.id),
      sender: Auth.getUserId(),
      statuses: chat.participants.map(({ id }) => ({ participantId: id, status: 'unread' })),
      __typename: 'SendChatMessageCommand',
    };
  }

  protected get toolbarConfiguration() {
    return {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{ list: 'ordered' }, { list: 'bullet' }], // list types
        ['image', 'gif'],
        ['clean'],
      ],
      handlers: {
        gif: () => {
          this.showGiphyModal = !this.showGiphyModal;

          ReactDOM.render(
            <GiphyModal showModal={this.showGiphyModal} quill={this.quill} cb={this.handleSelectgedGif.bind(this)} />,
            document.getElementById('giphy_wrapper')
          );
        },
      },
    };
  }

  protected get keyboardConfiguration() {
    return {
      bindings: {
        enter: {
          key: 'enter',
          handler: () => this.handleSubmit(),
        },
      },
    };
  }

  protected get mentionConfiguration() {
    return {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@'],
      source: (searchTerm, renderList, mentionChar) => this.mentionsSource(searchTerm, renderList, mentionChar),
      isolateCharacter: true,
      defaultMenuOrientation: 'bottom',
      fixMentionsToQuill: true,
      // offsetTop: 293,
      renderItem(item) {
        return mentionMenuItem(item);
      },
      mentionListClass: styles['mentions-menu'],
      dataAttributes: ['id', 'value', 'denotationChar', 'link', 'target', 'name', 'alias'],
      onSelect: (item, insert) => {
        insert(item);

        this.openModalOnMentionClick();
      },
    };
  }
  public initialized: boolean = false;
  public quill: any;
  public chatId: string | undefined;
  public wrapper: HTMLElement;
  public showGiphyModal: boolean = false;
  public selectedImage: File;
  private mentionChar: string;
  private value: Delta;
  private editor: HTMLElement;
  public unsubscribe = () => {};

  public init() {
    this.initialized = true;
    this.createTarget();
    this.chatId = Router.params.chatId;

    connectEmojiBlot(Quill);
    connectEmojiModule(Quill);
    connectMentionsBlot(Quill);
    connectMentionsModule(Quill);
    connectImagesBlot(Quill);
    connectUrlModule(Quill);

    /** For all options see:
     *  @url(https://quilljs.com/docs/modules/)
     *  @url(https://www.npmjs.com/package/quill-mention)
     * */
    const options = {
      scrollingContainer: this.wrapper,
      modules: {
        'toolbar': this.toolbarConfiguration,
        'keyboard': this.keyboardConfiguration,
        'emoji-textarea': true,
        'mention': true,
        'url': true,
        'imageDropAndPaste': {
          handler: this.imageHandler,
        },
      },
      placeholder: 'Message',
      theme: 'snow',
    };

    this.quill = new Quill(this.editor, options);

    const toolbar = this.quill.getModule('toolbar');

    toolbar.addHandler('image', () => this.handleImageSelection(Quill));

    this.quill.on('text-change', () => {
      const contents = this.quill.getContents();
      const length = this.quill.getLength();
      const textLength = this.quill.getText().trim().length;

      if (length === 1 && !textLength) {
        localStorage.removeItem(`draft-message-${this.chatId}`);
      } else {
        localStorage.setItem(`draft-message-${this.chatId}`, JSON.stringify(contents));
      }
    });

    if (this.chatId) {
      const startMessage = localStorage.getItem(`draft-message-${this.chatId}`);

      this.quill.setContents(new Delta(JSON.parse(startMessage)));
      this.openModalOnMentionClick();
    }

    this.unsubscribe = Router.listen(() => {
      const chatId = Router.params && Router.params.chatId;

      if (chatId === this.chatId) {
        return;
      }

      if (this.chatId) {
        const length = this.quill.getContents().length() - 1; // 1 because quill always have /n symbol
        const prevMessage = JSON.stringify(this.quill.getContents());

        if (length) {
          localStorage.setItem(`draft-message-${this.chatId}`, prevMessage);
        } else {
          localStorage.removeItem(`draft-message-${this.chatId}`);
        }
      }

      if (chatId) {
        const currentMessage = localStorage.getItem(`draft-message-${chatId}`);

        this.chatId = chatId;
        this.quill.setContents(new Delta(JSON.parse(currentMessage)));
      }

      this.openModalOnMentionClick();
    });
  }

  public openModalOnMentionClick(className = 'mention') {
    setImmediate(() => {
      const items = document.querySelectorAll(`[class=${className}]`);

      items.forEach((domItem) => {
        if (domItem.classList.contains('selected')) {
          return;
        }

        (domItem as HTMLElement).onclick = () => {
          Router.go({ hash: { IndividualModal: true, userId: (domItem as HTMLElement).dataset.id } });
        };
      });
    });
  }

  protected mentionsSource(searchTerm, renderList, mentionChar) {
    this.mentionChar = mentionChar;

    const collection = mentionChar === '#' ? 'chats' : 'individuals';
    const value = searchTerm ? searchTerm.toLowerCase() : '';
    const usersFilter = (doc) =>
      doc.name.toLowerCase().includes(value) || (doc.alias && doc.alias.toLowerCase().includes(value));
    const chatsFilter = (doc) => doc.type !== 'direct' && doc.title.toLowerCase().includes(value);

    const matches = Router.controller[collection]
      .where(mentionChar === '#' ? chatsFilter : usersFilter)
      .exec()
      .map((doc) => ({
        ...doc,
        value: mentionChar === '#' ? doc.title : doc.name,
        name: mentionChar === '#' ? doc.title : doc.name,
      }));

    renderList(matches);
  }

  protected createTarget() {
    const wrapper = document.createElement('div');
    wrapper.classList.add(styles['editor-wrapper']);

    const editor = document.createElement('div');

    editor.setAttribute('id', 'editor-container');

    wrapper.appendChild(editor);

    this.wrapper = wrapper;
    this.editor = editor;
  }

  protected buildMessageFromReponse({ command: { id, chatId, message, mentions, participants, sender } }) {
    return {
      __typename: 'ChatMessageViewModel',
      id,
      chatId,
      mentions,
      participants,
      content: message,
      sent: new Date(),
      attributes: null,
      sender: {
        id: sender,
        alias: null,
        name: null,
        avatar: null,
        __typename: 'ChatParticipantViewModel',
      },
      statuses: null,
      state: 'pending',
    };
  }

  protected async sendMessage() {
    const command = await this.message();
    if (!command.message.trim().length) {
      return;
    }

    await apollo.mutate({
      mutation: sendChatMessage,
      variables: {
        command,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        sendChatMessage: {
          __typename: 'MutationResultOfSendChatMessageCommand',
          command: {
            ...command,
            state: 'pending',
            id: `pending-${command.id}`,
          },
        },
      },
      update: (cache) => {
        ChatsService.storeChatMessage(this.buildMessageFromReponse({ command }));
      },
    });
  }

  protected handleSelectgedGif(gif: MyGif) {
    fetch(gif.images.original.url)
      .then((res) => res.blob())
      .then((image) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onloadend = () => {
          const range = this.quill.getSelection();
          this.quill.insertEmbed(range.index, 'picture', reader.result);
        };
      });

    ReactDOM.render(<GiphyModal showModal={false} quill={this.quill} />, document.getElementById('giphy_wrapper'));
  }

  protected async handleSubmit() {
    const text = this.quill.getText();
    const lengthContents = this.quill.getLength();

    if (text.length === lengthContents && !text.trim().length) {
      return;
    }

    const quillContent = this.quill.getContents();
    this.value = this.quill.getContents();
    this.quill.setContents(new Delta());

    await this.sendMessage();

    if (quillContent.ops[0].insert.picture) {
      const formData = new FormData();
      formData.append('files', this.selectedImage);
      await REST.uploadFile(formData);
    }
  }

  protected handleImageSelection(Quill) {
    const fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', '.png, .jpg, .jpeg, .webp');

    fileInput.onchange = async () => {
      this.selectedImage = fileInput.files[0];
      const length = this.quill.getLength();

      const reader = new FileReader();

      reader.addEventListener('load', () => {
        const dataUrl = reader.result;
        this.quill.insertEmbed(length - 2, 'picture', dataUrl, Quill.sources.USER);
      });

      reader.readAsDataURL(fileInput.files[0]);
      this.quill.setSelection(this.quill.getLength());
    };

    fileInput.click();
  }

  protected async imageHandler(dataUrl, _, imageData) {
    const length = this.quill.getLength();

    imageData
      .minify({
        maxWidth: 320,
        maxHeight: 320,
        quality: 0.7,
      })
      .then(() => {
        this.quill.insertEmbed(length - 2, 'picture', dataUrl);
        this.quill.setSelection(this.quill.getLength());
      });
  }
}

export default new Editor();
