import { directive, html, nothing } from 'lit-html';
import groupBy from 'lodash/groupBy';
import styles from './alphabet-scroll.module.scss';
import { repeat } from 'lit-html/directives/repeat';
import capitalize from 'lodash/capitalize';
import uuid from 'uuid';

class Controller {
  uniqPart = uuid();
  active = null;
  offset = 0;
  availableLetters = [];
  listeners = new Set([]);

  setActive = (section) => {
    this.active = section;

    this.listeners.forEach(listener => listener());
  }

  subscribe = (fn) => {
    this.listeners.add(fn);

    return () => {
      this.listeners.delete(fn);
    };
  }

  handleScroll = (evt) => {
    const { children, scrollTop } = evt.target;
    const childrenArr = Array.from(children);
    const index = childrenArr.findIndex(child =>
      child.offsetTop + child.offsetHeight >= (scrollTop + child.offsetHeight)
    );
    const child = childrenArr[index];

    if (child && child.id !== this.active) {
      this.setActive(child.id.replace(this.uniqPart, ''));
    }
  }

  scrollToSection = (id) => {
    const section = document.getElementById(id + this.uniqPart);

    section.scrollIntoView({ block: 'start' });
    this.setActive(id);
  }
}

export function alphabetTemplate(values, card, current) {
  const letters = Array.from(
    { length: 26 },
    (k, v) => String.fromCharCode(v + 65)
  );

  const grouped = groupBy(values, value => capitalize(value.name[0]));
  const controller = new Controller();

  const lettersTemplate = () =>
    repeat(
      letters,
      letter => letter,
      letter => html`
        <button
          class="${styles.letter} ${letter === controller.active ? styles.active : ''}"
          @click=${() => controller.scrollToSection(letter)}
          ?disabled=${!controller.availableLetters.includes(letter)}
        >
          ${letter}
        </button>
    `);

  const lettersState = directive(() => part => {
    part.setValue(lettersTemplate());

    controller.subscribe(() => {
      part.setValue(lettersTemplate());

      const { clientHeight, scrollTop, offsetTop } = part.startNode.parentElement;
      const [button] = document.getElementsByClassName(styles.active);
      const itemAdditionOffset = 20;
      const scrollOffset = 30;
      const itemPosition = button && button.offsetTop - offsetTop + button.clientHeight + itemAdditionOffset;

      if (itemPosition + scrollOffset > clientHeight) {
        part.startNode.parentElement.scrollTop = itemPosition;
      } else if (scrollTop >= itemPosition - scrollOffset) {
        part.startNode.parentElement.scrollTop = 0;
      }

      part.commit();
    });
  });

  controller.availableLetters = Object.keys(grouped);
  controller.active = controller.availableLetters[0];

  return html`
    <div class="${styles.wrapper}">
      <div class="${styles.items_wrapper}">
        <div class="${styles.items}" @scroll=${controller.handleScroll}>
          ${current
    ? html`
              <div class="${styles.current}">
                <div class="${styles.avatar}">
                  <w-avatar avatar=${current.avatar} title=${current.name} width='40px'></w-avatar>
                </div>
                You (${current.name})
              </div>`
    : nothing
}
          ${repeat(controller.availableLetters,
    group => group,
    letter => html`
              <div id="${letter + controller.uniqPart}">
                ${ repeat(grouped[letter], value => value.id, value => card(value)) }
              </div>
            `)}
        </div>
      </div>

      <div class="${styles.letters}" id=${controller.uniqPart}>
        ${ lettersState() }
      </div>
    </div>
  `;
}
