import { html } from 'lit-html';
import _upperFirst from 'lodash/upperFirst';
import Fragment from 'services/templator/fragment';
import { repeat } from 'lit-html/directives/repeat';

class ParticipantsTimezoneFragment extends Fragment {
  participantTimezone(member) {
    const start = this.controller.state.selectedOption === 'Call' ? 'Call' : 'Meeting';
    const memberName = member.name ? member.name.split(' ')[0] : 'member';
    const date = this.module.moment(this.data.date).tz(member.timezone).format('dddd, D, MMMM hh:mm A');

    return html`
        <h4>${start} time in ${memberName}’s last known location:</h4>

        <div class="${this.styles['like-disabled-input']}">${date}</div>
    `;
  }

  get showHideButton() {
    return html`
      <button
        class="${this.styles['show-hide']}"
        @click=${() => this.controller.showHideParticipantsTzs()}
      >
        ${_upperFirst(this.controller.state.action)} all participants last known locations
      </button>`;
  }

  get timeFields() {
    const { participants } = this.data;
    const members = this.controller.state.action === 'hide' ? participants : participants.slice(0, 2);

    return repeat(members, p => p.id, p => this.participantTimezone(p) );
  }

  get template() {
    const { participants } = this.data;

    return html`
      ${ this.directives.renderParticipantTimesList(this.timeFields) }
      ${participants.length > 2 ? this.showHideButton : ''}
    `;
  }
}

export default ParticipantsTimezoneFragment;
