import apollo from 'api';
import { observable } from 'decorators';
import { IOutstandingTask, IOutstandingTaskButton } from 'interfaces';
import { Router } from 'services/router.service';
import OutstandingTasksQuery from '../../graphql/queries/outstandingTasks.graphql';
import DashTasksModule from './dash-tasks.module';

class DashTasksController {
  public module: DashTasksModule;
  @observable([]) public tasks: IOutstandingTask[];

  constructor() {
    this.handleTaskAction = this.handleTaskAction.bind(this);
  }

  public async OnInit() {
    this.watchTasks();
  }

  private watchTasks() {
    const observe = apollo.watchQuery({
      query: OutstandingTasksQuery,
    }).subscribe({
      next: ({ data: { outstandingTasks } }) => {
        this.tasks = outstandingTasks;
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public handleTaskAction(button: IOutstandingTaskButton) {
    const { type, value } = button;

    switch (type) {
      case 'url':
        Router.go({ path: value });
        break;
      case 'poll':
        Router.go({
          hash: {
            PollModal: true,
            pollId: value,
          },
        });
        break;
    }
  }
}

export default DashTasksController;
