import { IUser } from 'interfaces';
import { html, nothing } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { repeat } from 'lit-html/directives/repeat';
import constants from './constants';
import styles from './mentions.module.scss';

const userTemplate = (handler, selected, user, index) => {
  const classes = {
    [styles.member]: true,
    [constants.ITEM_CLASS_PREFIX + selected]: true,
    [styles.selected]: selected === index,
  };

  return html`
    <div class="${classMap(classes)}" @click=${handler.bind(null, user)}>
      <w-avatar avatar="${user.avatar}" title="${user.name}" width="35px" borderWidth="1"> </w-avatar>
      <div class="${styles.alias}">@${user.alias}</div>
      <div>${user.name}</div>
    </div>
  `;
};

export const mentionsList = (users, selected, clickHandler, close) => {
  !users.length && close();

  return users.length ? html`
    <div class="${styles.list}">
      ${repeat(users, (user: IUser) => user.id, userTemplate.bind(null, clickHandler, selected))}
    </div>
  ` : nothing;
};
