import classnames from 'classnames';
import { Observe } from 'decorators';
import { showIf } from 'directives';
import { IDashLeader } from 'interfaces';
import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import { Auth } from 'services';
import buttonStyles from 'styles/button.module.scss';
import cardStyles from 'styles/card.module.scss';
import DashLeaderboardController from './dash-leaderboard.controller';
import styles from './dash-leaderboard.module.scss';

class DashLeaderboardTemplate {
  public controller: DashLeaderboardController;
  public positions = ['first', 'second', 'third'];

  get empty() {
    return html`
      <div class=${styles.empty}>
        We're working away at calculating the leaderboard. Check back shortly to see if you're on it!
      </div>
    `;
  }

  @Observe('activeLeaderboard')
  get leaderBoost() {
    const { activeLeaderboard, handleBoost } = this.controller;
    const isCurrentLeader = activeLeaderboard.leaders.some(({ id }) => id === Auth.getUserId());

    return isCurrentLeader ? html`
      <p class=${styles.message}>You made it! Keep up the good work ⭐️</p>
    ` : html`
      <button class=${buttonStyles.small} @click=${handleBoost}>
        Get on the leaderboard, schedule a call with your match
      </button>
    `;
  }

  public leader({ name, avatar, value, id, position }) {
    const isMe = Auth.getUserId() === id;

    return html`
      <div class=${classnames(styles.leader, styles[this.positions[position - 1]])}>
        <div class="${styles.avatar} ${isMe && styles.me}">
          <w-avatar
            title=${name}
            avatar=${avatar}
            width="70px"
          > </w-avatar>
        </div>
        <div class="${styles['position-badge']} ${styles[`badge-${position}`]}">${position}</div>
        <div class=${styles.name}>
          ${this.controller.getFormattedName(id, name)}
        </div>
        <div class=${styles.score}>${value}</div>
      </div>
    `;
  }

  public leadersGroup(leaders: IDashLeader[]) {
    return html`
      <div class=${styles.leaders}>
        ${repeat(leaders, (leader) => leader.id, (leader) => this.leader(leader))}
      </div>
    `;
  }

  @Observe('leaderboards')
  get switcher() {
    const { leaderboards, handleActiveLeaderboard } = this.controller;

    return leaderboards.length > 1 ? html`
      <select
        class=${cardStyles['header-select-full']}
        @change=${handleActiveLeaderboard}
      >
        ${leaderboards.map(({ type, label }, index) => html`
          <option value=${type}>${label}</option>
        `)}
      </select>
    ` : nothing;
  }

  @Observe('match', 'activeLeaderboard')
  get template() {
    const { activeLeaderboard, match } = this.controller;
    const { leaders } = activeLeaderboard;

    return html`
      <div class=${cardStyles.card}>
        <div class=${cardStyles.header}>
          ${this.switcher}
        </div>
        <div class=${cardStyles.content}>
          ${activeLeaderboard && leaders.length ? html`
            ${this.leadersGroup(leaders)}
            ${showIf(match, this.leaderBoost)}
          ` : html`
            ${this.empty}
          `}
        </div>
      </div>
    `;
  }
}

export default DashLeaderboardTemplate;
