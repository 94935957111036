import Fragment from 'services/templator/fragment';
import { html } from 'lit-html';
import { Icons } from 'components/lit';

class NoResultsFragment extends Fragment {
  setOptions(opts = {}) {
    this.icon = opts.icon;
    this.text = opts.text;
  }

  get template() {
    return html`
      <div class="${this.styles.no_results}">
        ${Icons[this.icon]}
        <div>
          ${this.text}
        </div>
      </div>
    `;
  }
}

export default NoResultsFragment;
