import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import DashResourcesController from './dash-resources.controller';
import DashResourcesView from './dash-resources.view';

@RouterModule({
  name: 'w-dash-resources-module',
  controller: DashResourcesController,
  view: DashResourcesView,
})
class DashResourcesModule {
  public subs: Set<() => void> = new Set([]);
  public view: DashResourcesView;

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default DashResourcesModule;
