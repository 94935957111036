import { Icons } from 'components/lit';
import { isPast, parseISO } from 'date-fns';
import { Observe } from 'decorators';
import { IAction } from 'interfaces';
import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import groupBy from 'lodash/groupBy';
import _upperFirst from 'lodash/upperFirst';
import styles from 'modules/ActionsList/actions-list.module.scss';
import { Router } from 'services/router.service';
import ActionsListController from './actions-list.controller';

class EventsListTemplate {
  public controller: ActionsListController;

  public button(name, event, type = 'outline') {
    return html`
      <button
        ?disabled=${this.controller.loading[event.id]}
        @click=${() => this.controller.action(name, event)}
        class=" w-button ${type}"
      >
        ${_upperFirst(name)}
      </button>
    `;
  }

  @Observe('loading')
  public buttons(event) {
    const { organizerId, participants, status } = event;
    const organizer = { id: organizerId };
    const {
      isPending,
   } = this.controller.action_utils.getEventButtonCases(organizer, participants, status);

    return html`
      ${this.button('view', event, 'primary') }
      ${isPending ? this.button('nudge', event) : nothing }
    `;
  }

  public action(event) {
    const statusArgs = {
      status: event.status,
      organizer: {
        id: event.organizerId,
        name: event.participants.find((p) => p.id === event.organizerId).name,
      },
      participants: event.participants,
    };

    return html`
      <div class="${styles.event}">
        <div class="${styles['event-body']}">
          <div class="${styles['event-icon']}">${JSON.parse(event.data).subType === 'call' ? Icons.phone : Icons.coffee}</div>
          <div class="${styles['event-info']}">
            <span>${event.title}</span>
            <span>${this.controller.getEventDate(event)}</span>
            <span>${this.controller.action_utils.eventStatus(statusArgs)}</span>
          </div>
          <div class="${styles['event-buttons']}">
            ${this.buttons(event)}
          </div>
        </div>
      </div>
    `;
  }

  get empty() {
    return html`
      <div class="${styles.empty}">
        ${Icons.calendarBusy}

        <h2>No events to display</h2>

        <p>Schedule something with your match or another member of your mentoring community</p>
      </div>
    `;
  }

  public list(status, list) {
    return html`
      <div class="${styles['event-header']}">${_upperFirst(status)}</div>
      ${repeat(list, (action: IAction) => action.id, (action: IAction) => this.action(action))}
    `;
  }

  @Observe('list')
  get groupedList() {
    const list = groupBy(this.controller.list, (item) => {
      const { startTime } = JSON.parse(item.data);

      return isPast(parseISO(startTime)) ? 'past' : 'upcoming';
    });

    return this.controller.list.length
      ? html`${repeat(Object.keys(list), (k) => this.list(k, list[k]))}`
      : this.empty;
  }

  @Observe('selectedDate')
  get selectedDate() {
    return html`
      <div class="${styles['selected-date']}">
        ${this.controller.selectedDate.format('ddd, MMM D YYYY')}
      </div>
    `;
  }

  @Observe('datepicker')
  get calendar() {
    return this.controller.datepicker ? html`
      <div class="${styles.datepicker}">
        ${this.controller.datepicker.template}
      </div>
    ` : nothing;
  }

  get template() {
    const calendar = !this.controller.isCalendarHidden ? this.calendar : nothing;
    const list = html`
      <div class="${styles.list}">
        ${this.groupedList}
      </div>
    `;

    if (Router.display === 'mobile') {
      return html`
        <div class="${styles.wrapper}">
          ${calendar}
          ${list}
        </div>
      `;
    }

    return html`
      <div class="${styles.wrapper}">
        ${calendar}
        ${list}
      </div>
    `;
  }
}

export default EventsListTemplate;
