import { directive, html } from 'lit-html';
import { Router } from 'services/router.service';
import { Icons } from 'components/lit';
import styles from './full-screen-modal.module.scss';
import ModalBase from 'atoms/lit/modal/base';

class FullScreenModal extends ModalBase {
  get desktopHeader() {
    return html`
      <div class="${styles.header}">
        <button class="${styles.close}" @click=${() => this.destroy()}>${Icons.cross()}</button>
      </div>
    `;
  }

  get titleTmp() {
    return html`<span class="${styles.title}">${this.title}</span>`;
  }

  get mobileHeader() {
    return html`
      <div class="${styles['header-mobile']}">
        <button class="${styles.close}" @click=${() => this.destroy()}>${Icons.arrowWhiteLeftLong}</button>
       ${this.titleTmp}
       ${this.renderSubmit ? this.renderSubmit() : this.submit}
      </div>
    `;
  }

  get renderHeader() {
    return directive(() => part => {
      const unsubscribe = Router.subscribeOnResizing(display => {
        const displays = {
          mobile: this.mobileHeader,
          desktop: this.desktopHeader,
        };

        part.setValue(displays[display]);
        part.commit();
      });

      this.subscribe(unsubscribe, 'destroy');
    });
  }

  get body() {
    return html`PUT YOUR BODY HERE`;
  }

  get tmp() {
    return html`
      <div class="${styles.wrapper}">
        ${this.renderHeader()}
        <div class="${styles.body}">
          ${this.body}
        </div>
      </div>
    `;
  }
}

export default FullScreenModal;
