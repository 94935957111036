import apollo from 'api';
import BaseService from 'services/data/base.service';
import OutstandingTasksQuery from '../../graphql/queries/outstandingTasks.graphql';


class DashboardService extends BaseService {
  public async getTasks() {
    const { data: { outstandingTasks } } = await apollo.query({
      query: OutstandingTasksQuery,
      fetchPolicy: 'network-only',
    });

    return outstandingTasks;
  }
}

export default new DashboardService();
