import ErrorView from './error.view';
import { manageMount } from 'lib/lit-components-manager';
import styles from './error.module.scss';
const el = document.createElement('div');
export default {
  mount() {
    el.setAttribute('id', 'error');
    el.classList.add(styles.error);
    document.body.appendChild(el);
    const App = document.getElementById('App');
    App.setAttribute('style', 'position: fixed;');
    manageMount(ErrorView(), 'error');
  },

  unmount() {
    document.body.removeChild(el);
  },
};
