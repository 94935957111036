import Poll from 'modules/Poll/Poll';
import { RouterModule } from 'services/router/router.module';

class PollModule extends RouterModule {
  mount() {
    const unsubscribe = this.store.subscribe('activePoll', () => {
      const poll = this.store.get('activePoll');

      if (poll && this.router.route.name === 'chats.chat' && this.router.display === 'mobile') {
        return setImmediate(() => {
          this.manageReactMount(Poll, 'poll', { poll });
        });
      }

      poll && this.manageReactMount(Poll, 'poll', { poll });
    });

    this.addSubscription(unsubscribe);
  }

  unmount() {
    this.manageReactUnmount('poll');
  }
}

export default PollModule;
