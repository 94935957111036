import { render } from 'lit-html';
import ChatHeaderController from 'modules/ChatHeader/header.controller';
import ChatHeaderView from 'modules/ChatHeader/header.view';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';
import {leaveJoinChat} from 'utils/chat-helpers';

const plugins = new Map([['leaveJoin', leaveJoinChat]]);

@RouterModule({
  name: 'w-chat-header-module',
  controller: ChatHeaderController,
  view: ChatHeaderView,
  plugins,
})
class HeaderModule {
  public subs = new Set([]);
  public view: ChatHeaderView;
  public controller: ChatHeaderController;

  public async loading() {
    await dataService.awaitLoading;
  }

  public mount(el) {
    render(this.view.template, el);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default HeaderModule;
