import AuthorizedController from 'controllers/authorized';

class MatchController extends AuthorizedController {

  awaiter() {
    this.router.go({ name: 'dashboard', strategy: 'replace' });
  }
}

export default MatchController;
