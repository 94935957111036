import apollo from 'api';
import { observable } from 'decorators';
import { NotificationManager, SharedGoal } from 'services';
import IndividualsService from 'services/data/individuals.service';
import { Router } from 'services/router.service';
import shareGoalsMutation from '../../graphql/mutations/shareGoals.graphql';

class SharedGoalsController {
  public destroy: () => void;

  @observable([]) public goals: any[];
  @observable(true) public disabledShare: boolean;
  @observable([], true) public individuals: any;
  @observable([], true) public selectedUsers: any[];
  @observable([]) public displayableUserList: any[];
  @observable('') public search: string;

  public async OnInit() {
    this.individuals = await IndividualsService.getIndividuals();
    SharedGoal.subscribe((goals) => {
      this.goals = goals;
    });
  }

  public onChange(user, value) {
    if (value) {
      this.selectedUsers.push(user);
    } else {
      this.selectedUsers = this.selectedUsers.filter(({ id }) => user.id !== id);
    }

    this.disabledShare = !this.selectedUsers.length;
  }

  public async onSave() {
    const recipientIds = this.selectedUsers.map(({ id }) => id);

    await apollo.mutate({
      mutation: shareGoalsMutation,
      variables: {
        command: {
          recipientIds,
        },
      },
    });

    SharedGoal.collectMyShares();
    NotificationManager.showNotification({
      text: 'Your goals were successfully shared',
      type: 'success',
    });

    this.destroy();
  }

  public onSearch(evt) {
    this.search = evt.target.value;
  }

  public onShowUserProfile(userId) {
    Router.go({ hash: { IndividualModal: true, userId } });
  }

}

export default SharedGoalsController;
