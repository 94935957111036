import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import KPISFragment from './fragments/kpis.fragment';
import StepOneFragment from './fragments/step-1.fragment';
import StepTwoFragment from './fragments/step-2.fragment';
import StepThreeFragment from './fragments/step-3.fragment';
import StepFourFragment from './fragments/step-4.fragment';
import StepFiveFragment from './fragments/step-5.fragment';
import StepSixFragment from './fragments/step-6.fragment';
import JoinController from './join.controller';
import JoinView from './join.view';

const fragments = new Map();

fragments.set('one', StepOneFragment);
fragments.set('two', StepTwoFragment);
fragments.set('three', StepThreeFragment);
fragments.set('four', StepFourFragment);
fragments.set('five', StepFiveFragment);
fragments.set('six', StepSixFragment);
fragments.set('kpis', KPISFragment);

@RouterModule({
  name: 'w-join-module',
  controller: JoinController,
  view: JoinView,
  fragments,
})
class JoinModule {
  public view: JoinView;

  public mount(module: HTMLElement) {
    render(this.view.template, module);
  }
}

export default JoinModule;
