import { customElement } from 'components/custom/decorators';
import styles from 'components/custom/wwc-message.module.scss';
import { IMessage } from 'interfaces';
import moment from 'lib/moment';
import { html, render, TemplateResult } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import querystring from 'querystring-es3';
import Converter from 'services/editor/convertor';

/**
 * Events
 * @url - Event triggered when click on in chat alert message's button with type 'url'
 * @command - Event triggered when click on in chat alert message's button with type 'command'
 * @connected - Event which informed message context-menu that current component connected. Will be triggered only if
 *              parent has tag name `wwc-message-context`
 * */
@customElement({ name: 'wwc-message' })
class WWCMessage extends HTMLElement {
  public message: IMessage;
  public type: string;
  private messageView: HTMLDivElement | undefined;
  private avatarTmp: HTMLElement;
  private sentTimeWrapper: HTMLDivElement = document.createElement('div');
  private textWrapper: HTMLDivElement = document.createElement('div');
  private previewWrapper: HTMLDivElement = document.createElement('div');
  private previewOptions = {
    spotify: /(http:|https:)\/\/(open.|play.)spotify.com\/((track|show|episode|playlist)\/{1}([0-9A-Za-z]+))/g,
    youtube: /(?:https?:\/\/)?(?:(?:(?:www\.?)?youtube\.com(\/((watch\?.*?(v=[^&\s]+).*)|(?:v(\/.*))|(channel\/.+)|(?:user\/(.+))|(?:results\?(search_query=.+)))))|(?:youtu\.be(\/.*)?))/g,
  };

  static get observedAttributes() {
    return ['message'];
  }

  public connectedCallback() {
    this.applyMessageView();
    this.applyMessageText();
    this.applyMessagePreview();

    this.messageView.style.position = 'relative';

    this.appendChild(this.messageView);

    if (this.parentElement.tagName.toLowerCase() === 'wwc-message-context') {
      const event = new CustomEvent('connected', {
        detail: {
          message: this.message,
          isMyMessage: this.myMessage,
          messageView: this.messageView,
        },
      });

      this.dispatchEvent(event);
    }
  }

  public attributeChangedCallback(name, oldValue, newValue) {
    const newMessage = JSON.parse(newValue);
    if (newMessage.id) {
      const { state } = newMessage;
      this.message = newMessage;

      this.applyState(state);
      this.avatarTmp && this.avatarTmp.setAttribute('avatar', this.message.sender.avatar);
    }
  }

  public applyState(state) {
    this.textWrapper.setAttribute('state', state);
    this.applySentTime();
    this.applyMessageText();
  }

  public applyMessageText() {
    if (this.type === 'alert') {
      this.applyInChatAlertMessageText();
    }

    if (this.type === 'message') {
      this.applyRegularMessageText();
    }
  }

  public applyRegularMessageText() {
    render(this.text, this.textWrapper);
  }

  public applyInChatAlertMessageText() {
    this.textWrapper.classList.add(styles.alert_message);
    const alertEl = document.createElement('div');
    alertEl.appendChild(this.inChatAlertMessageTitle);
    alertEl.appendChild(this.textBlock);
    alertEl.appendChild(this.buttons);

    render(alertEl, this.textWrapper);
  }

  private applyMessageView() {
    if (this.myMessage) {
      this.textWrapper.classList.add(styles.inbox_message);
      this.messageView = this.inboxMessageTemplate;
      this.messageView.classList.add(styles.inbox_message_wrapper);
    } else {
      this.textWrapper.classList.add(styles.outbox_message);
      this.messageView = this.outboxMessageTemplate;
      this.messageView.classList.add(styles.outbox_message_wrapper);
    }

    this.textWrapper.classList.add(styles.message);
  }

  private applyMessagePreview() {
    let previewUrl: URL;

    try {
      const messageOps = JSON.parse(this.message.content).ops;
      const messageLink = messageOps.find(({ attributes }) => attributes && attributes.link);
      const validUrl = new URL(messageLink.attributes.link);

      previewUrl = validUrl;
    } catch (e) {
      previewUrl = null;
    }

    if (previewUrl) {
      Object.keys(this.previewOptions).forEach((key) => {
        if (!this.previewOptions[key].test(previewUrl.href)) { return null; }

        this.previewWrapper.innerHTML = this[key](previewUrl);
        this.previewWrapper.classList.add(styles.preview_wrapper, styles[key]);
        this.textWrapper.prepend(this.previewWrapper);
      });
    }
  }

  private spotify({ pathname }: URL) {
    const height = /show|episode/.test(pathname) ? '155' : '380';

    return `
      <iframe src="https://open.spotify.com/embed${pathname}" width="100%" height="${height}" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    `;
  }

  private youtube({ search, pathname }: URL) {
    const youtubeId = querystring.parse(search)['?v'] || pathname;

    return `
    <iframe src="https://www.youtube.com/embed/${youtubeId}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    `;
  }

  /** View parts */
  private get myMessage(): boolean {
    return this.hasAttribute('mymessage');
  }

  private get time(): string {
    return moment(this.message.sent).format('HH:mm');
  }

  private get text(): TemplateResult {
    let template;

    try {
      const config = JSON.parse(this.message.content);
      const result = new Converter(config);
      template = html`${
        repeat(result.templates,
          (t, i) => i,
          (template) => template)
      }`;
    } catch (e) {
      template = html`${this.message.content}`;
    }

    return template;
  }

  private applySentTime(): HTMLDivElement {
    const { state, sender } = this.message;
    const sentTime = this.sentTimeWrapper;
    const stateMessages = {
      pending: 'Sending',
      deleted: 'Deleted',
    };

    sentTime.classList.add(styles.time);

    if (this.myMessage) {
      sentTime.innerText = `${state ? stateMessages[state] : 'You'}, ${this.time}`;
    } else if (sender) {
      sentTime.innerText = `${state ? stateMessages[state] : sender.name}, ${this.time}`;
    }

    return sentTime;
  }

  private get avatar(): HTMLElement {
    this.avatarTmp = document.createElement('w-avatar');
    this.avatarTmp.setAttribute('avatar', this.message.sender.avatar);
    this.avatarTmp.setAttribute('title', this.message.sender.name);
    this.avatarTmp.setAttribute('width', '40px');
    this.avatarTmp.setAttribute('borderWidth', '2');
    this.avatarTmp.style.alignSelf = 'flex-end';

    return this.avatarTmp;
  }

  private get outboxInfo(): HTMLDivElement {
    const info = document.createElement('div');

    info.classList.add(styles.outbox_info);

    info.appendChild(this.applySentTime());
    info.appendChild(this.textWrapper);

    return info;
  }

  private get inboxMessageTemplate(): HTMLDivElement {
    const inboxWrapper = document.createElement('div');

    inboxWrapper.classList.add(styles.inbox_info);

    inboxWrapper.appendChild(this.applySentTime());
    inboxWrapper.appendChild(this.textWrapper);

    return inboxWrapper;
  }

  private get outboxMessageTemplate(): HTMLDivElement {
    const outboxWrapper = document.createElement('div');

    outboxWrapper.appendChild(this.avatar);
    outboxWrapper.appendChild(this.outboxInfo);

    return outboxWrapper;
  }

  private get inChatAlertMessageTitle(): HTMLHeadElement {
    const { title } = this.message.attributes;
    const messageTitle = document.createElement('h1');

    if (title) {
      messageTitle.innerText = this.message.attributes.title;
    }

    return messageTitle;
  }

  private get textBlock(): HTMLSpanElement {
    const textBlock = document.createElement('span');

    render(this.text, textBlock);

    return textBlock;
  }

  private get buttons(): HTMLDivElement {
    const buttons = document.createElement('div');

    if (!this.message.attributes.buttons) { return buttons; }

    this.message.attributes.buttons.forEach((button, index) => {
      const buttonEl = document.createElement('button');
      buttonEl.onclick = () => this.alertAction(button);
      buttonEl.classList.add('w-button');
      buttonEl.classList.add('darken-on-hover');

      buttonEl.innerText = button.text;

      if (!index) {
        buttonEl.classList.add('primary');
      } else {
        buttonEl.classList.add('outline');
        buttonEl.classList.add('filled');
      }

      buttons.appendChild(buttonEl);
    });

    buttons.classList.add(styles.alert_message_buttons);

    return buttons;
  }
  /** View parts finished */

  private alertAction(button) {
    const evt = new CustomEvent(button.type, { detail: { button, messageId: this.message.id } });

    this.dispatchEvent(evt);
  }
}

export default WWCMessage;
