/**
 * @file Holds utilities to work with AWS Cognito AuthService
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
*/
import { decodeJWT } from '@werkin/lib/helpers';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { promisify } from 'util';
import datetime from 'utils/datetime';
import { resetIntercomProfile, setupIntercomProfile } from 'utils/update-intercom-profile';

/* Importing variables */
const REACT_APP_COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
const REACT_APP_COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;

class AuthService {
  public user: any;
  public cognitoUser: CognitoUser;
  public userPool: CognitoUserPool;

  constructor() {
    this.userPool = new CognitoUserPool({
      UserPoolId: REACT_APP_COGNITO_USER_POOL_ID || null,
      ClientId: REACT_APP_COGNITO_CLIENT_ID || null,
    });
    this.login = this.login.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAttributes = this.getAttributes.bind(this);
    this.getToken = this.getToken.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  public getUserId() {
    const user = this.userPool.getCurrentUser();

    if (!user) { return; }

    return user.getUsername();
  }

  /* Checking if user is authenticated */
  public async isAuthenticated() {
    this.cognitoUser = this.userPool.getCurrentUser();

    return new Promise((res) => {
      if (!this.cognitoUser) {
        return res(false);
      }

      this.cognitoUser.getSession((err, session) =>
        err ? res(false) : res(session.isValid()));
    });
  }

  public changePassword(oldPassword, newPassword) {
    return new Promise((res, rej) => {
      if (!this.cognitoUser) { rej('User is not authenticated'); }
      this.cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
        err ? rej(err) : res(result);
      });
    });
  }

  public login(username, password, newPassword?) {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: async (result) => {
          await setupIntercomProfile();
          resolve(result);
        },
        onFailure: (err) => reject(err),

        newPasswordRequired() {
          cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
            onSuccess: (result) => resolve(result),
            onFailure: (err) => reject(err),
          });
        },
      });
    });
  }

  public forgotPassword(username) {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (res) => {
          resolve(res);
        },

        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  public resetPassword(code, password, username) {

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, password, {
        onSuccess: () => {
          localStorage.clear();
          resolve();
        },

        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  public getAttributes(cognitoUser) {
    cognitoUser.getUserAttributes((err, result) => {
      if (err) {
        console.error(err.message || JSON.stringify(err));

        return;
      }

      this.user = result.map((item) =>
        ({ [item.getName()]: item.getValue() }));

      console.log(this.user);
    });
  }

  public getToken(tokenType = 'accessToken') {
    const cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser !== null) {

      return cognitoUser.getSession((err, session) => {

        if (err) {
          console.log(err);

          return;
        }

        return session[tokenType].jwtToken;
      });
    }
  }

  public getCurrentUser() {
    const idToken = this.getToken('idToken');

    if (!idToken) {
      return null;
    }

    const { decoded, email } = decodeJWT(idToken);

    return {
      id: decoded.sub,
      email,
    };
  }

  public setTimezoneAttr(cognitoUser) {
    const attr = new CognitoUserAttribute({
      Name: 'timezone',
      Value: datetime.zone,
    });

    cognitoUser.updateAttributes([attr], (err, result) => {
      if (err) {
        console.error(err.message || JSON.stringify(err));

        return;
      }

      console.log(result);
    });
  }

  /* Performing logout */
  public logout() {
    const cognitoUser = this.userPool.getCurrentUser();
    cognitoUser.signOut();
    resetIntercomProfile();
  }

  public async refreshToken() {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser !== null) {
      const session = await promisify(cognitoUser.getSession.bind(cognitoUser))();
      await promisify(cognitoUser.refreshSession.bind(cognitoUser, session.getRefreshToken()))();
    }
  }
}

export default new AuthService();
