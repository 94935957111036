import {
  addHours,
  setMilliseconds,
  setSeconds,
  setMinutes,
} from 'date-fns';

let date = new Date();
date = setMilliseconds(date, 0);
date = setSeconds(date, 0);
date = setMinutes(date, 15);

export default {
  sender: [
    {
      start: addHours(date, 1).toISOString(),
      end: addHours(date, 2).toISOString(),
    },
    {
      start: addHours(date, 3).toISOString(),
      end: addHours(date, 4).toISOString(),
    },
  ],
  receiver: [
    {
      start: addHours(date, 5).toISOString(),
      end: addHours(date, 7).toISOString(),
    },
    {
      start: addHours(date, 8).toISOString(),
      end: addHours(date, 9).toISOString(),
    },
  ],
};
