import { Observe } from 'decorators';
import { showIf } from 'directives';
import { html } from 'lit-html';
import { cache } from 'lit-html/directives/cache';
import { classMap } from 'lit-html/directives/class-map';
import { repeat } from 'lit-html/directives/repeat';

import styles from './chats.module.scss';

import { Icons, statusTemplate } from 'components/lit';
import { IChat } from 'interfaces';

import Router from '../../services/router';
import ChatsController from './chats.controller';

const CHANNELS_GROUPS_OPTS = {
  modalName: 'ChatListModal',
  modal: 'groups',
  tab: 'details',
  action: 'create',
  text: 'group',
  types: ['group', 'channel'],
};
const DIRECTS_OPTS = {
  modalName: 'CreateDirectModal',
  modal: 'direct',
  tab: 'members',
  action: 'create',
  single: true,
  text: 'direct',
  types: ['direct'],
};

class ChatsView {
  public router: Router;
  public controller: ChatsController;

  public blockHeader(title, options) {
    return cache(html`
      <div class="${styles.title_wrapper}">
        <button
          @click=${() => this.controller.openChatsList(options.modalName)}
          class="${styles.title}"
        >
          ${title}
        </button>
        <button
          class="${styles.create_btn}"
          @click=${() => this.controller.handleClickCreate(options)}
        >
          ${Icons.circlePlus}
        </button>
      </div>

    `);
  }

  public unreadMarker(unread) {
    return html`
      <div class="${styles['unread-marker']}">
        ${showIf(unread, unread < 100 ? unread : '99+')}
      </div>`;
  }

  @Observe('matchIds')
  public chatItem(chat) {
    const { getChatMatch, getChatTitle } = this.controller;
    const { id, unreadMessageIds } = chat;
    const isActive = this.router.params.chatId === id;
    const isMatchedChat = getChatMatch(chat);
    const classes = {
      [styles.active]: isActive,
      [styles.unread]: unreadMessageIds.length > 0,
    };

    return html`
      <a
        href="${this.controller.getLink(chat.id)}"
        @click=${(evt) => this.controller.navToChat(chat.id, evt)}
        class="${classMap(classes)}"
      >
        <div class=${styles['item-inner']}>
          <div class="${styles.status}">${statusTemplate(chat, isActive)}</div>
          <div class="${styles.chat_name} ${unreadMessageIds.length ? styles.unread : ''}" >
            <span>${getChatTitle(chat)} ${showIf(isMatchedChat, html`(your match)`)}</span>
            ${this.unreadMarker(unreadMessageIds.length)}
          </div>
        </div>
      </a>
    `;
  }

  public listTemplate(type) {
    const { getChatsByType } = this.controller;

    return html`${repeat(getChatsByType(type), (chat: IChat) => chat.id, (chat) => this.chatItem(chat))}`;
  }

  @Observe('chats')
  get template() {
    return html`
      <div class="${styles.wrapper}">
        <div class="${styles.channel_group_block}">

          ${this.blockHeader('Channels & Groups', CHANNELS_GROUPS_OPTS)}

          ${html`<div class="${styles.list}">${this.listTemplate('channel')}</div>`}
          ${html`<div class="${styles.list}">${this.listTemplate('group')}</div>`}
        </div>

        <div class="${styles.direct_block}">

        ${this.blockHeader('Direct messages', DIRECTS_OPTS)}

        ${html`<div class="${styles.list}">${this.listTemplate('direct')}</div>`}
        </div>
      </div>
    `;
  }
}

export default ChatsView;
