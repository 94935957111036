import ErrorHandler from 'services/templator/error';

class Directive extends ErrorHandler {
  constructor(view) {
    super();

    this.view = view;
    this.onInit();
  }

  onInit() {}

  get module() {
    return this.view.module;
  }

  get router() {
    return this.view.module.router;
  }

  get data() {
    return this.view.data;
  }

  get controller() {
    return this.view.controller;
  }
}

export default Directive;
