import { Icons } from 'components/lit';
import { pluralizeCounter } from 'utils/pluralize';

export const bubbleContent = (type: string, value?: number) => ({
  chat: {
    label: `You chatted with your match ${value >= 10 ? 'over 10 times' : pluralizeCounter(value, 'time')}`,
    icon: Icons.messageSquare(),
  },
  event: {
    label: `You scheduled ${pluralizeCounter(value, 'catch-up')}`,
    icon: Icons.coffee,
    points: 500,
  },
  group: {
    label: `You joined ${pluralizeCounter(value, 'group')} with your match`,
    icon: Icons.thumbUpIcon,
    points: 800,
  },
  feedback: {
    label: `Feedback on catch-up submitted ${pluralizeCounter(value, 'time')}!`,
    icon: Icons.feedback,
  },
}[type]);
