import AuthorizedController from 'controllers/authorized';
import Header from 'modules/Header';
import Search from 'modules/Search';
import PollModule from 'modules/Poll';
import LogoutModal from 'components/lit/modals/logout-modal.view';
import SuggestFeatureModal from 'components/lit/modals/suggest-feature-modal.view';
import ReportProblemModal from 'components/lit/modals/report-problem-modal.view';
import MessageStatusesModal from 'components/lit/modals/message-statuses-modal.view';
import IndividualModal from 'components/lit/modals/individual-modal-full-screen.view';
import CreateDirectModal from 'components/lit/modals/create-direct-modal.view';
import DeleteMessageModal from 'components/lit/modals/delete-modal.view';
import ChatListModal from 'components/lit/modals/chats-list-modal.view';
import PollModal from 'components/lit/modals/poll-modal.view';
import ProfileEditingModal from 'components/lit/modals/profile-editing-modal.view';
import ShareGoalsModal from '../components/lit/modals/share-goals.modal';
import DeleteChatModal from 'components/lit/modals/delete-chat-modal';

class DefaultController extends AuthorizedController {
  before() {
    const unsubscribe = this.store.subscribe('isAlertsModal', isAlertsModal => {
      if (this.router.display === 'mobile' && isAlertsModal) {
        this.router.go({ name: 'alerts' });
      }
    });

    this.addDestroyer(unsubscribe);
  }

  controller() {
    this.addModule(Header);
    this.addModule(Search);
    this.addModule(PollModule);

    this.addTSAutonomousModal(ShareGoalsModal);
    this.addTSAutonomousModal(MessageStatusesModal);
    this.addTSAutonomousModal(CreateDirectModal);
    this.addTSAutonomousModal(ChatListModal);
    this.addTSAutonomousModal(PollModal);
    this.addTSAutonomousModal(LogoutModal);
    this.addTSAutonomousModal(SuggestFeatureModal);
    this.addTSAutonomousModal(ReportProblemModal);
    this.addTSAutonomousModal(ProfileEditingModal);

    /** Should be mounted after all modals because the could be opened as the second level modal */
    this.addTSAutonomousModal(IndividualModal);
    this.addTSAutonomousModal(DeleteMessageModal);
    this.addTSAutonomousModal(DeleteChatModal);
  }
}

export default DefaultController;
