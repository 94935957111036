import { ValidationStrategy } from 'components/custom/enums';
import { Observe } from 'decorators';
import { IInput } from 'interfaces';
import { html, nothing } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';
import { repeat} from 'lit-html/directives/repeat';
import ProfileResetPasswordController from './profile-reset-password.controller';
import styles from './profile-reset-password.module.scss';

const INPUTS = [
  {
    autofocus: true,
    name: 'oldPassword',
    label: 'Enter your current password',
    placeholder: 'Type old password',
    type: 'password',
    validationStrategy: ValidationStrategy.off,
  },
  {
    name: 'newPassword',
    label: 'Choose a new password',
    placeholder: 'Type new password',
    type: 'password',
    validationBox: true,
    validationStrategy: ValidationStrategy.immediate,
  },
];

class ProfileResetPasswordView {
  public controller: ProfileResetPasswordController;

  public input(ops: IInput) {
    return html`
      <w-validation-box
        name="${ops.name}"
        label="${ops.label}"
        strategy="${ops.validationStrategy}"
        >
          <w-input
            autocomplete="new-password"
            minlength="${!!ops.validationBox ? 8 : 0}"
            autofocus=${ifDefined(ops.autofocus)}
            type="${ops.type}"
            name="${ops.name}"
            value=${ifDefined(this.controller[ops.name])}
            placeholder="${ops.placeholder}"
            vRequired
            ?vSpecial=${!!ops.validationBox}
            ?vNumber=${!!ops.validationBox}
            ?vUpperCase=${!!ops.validationBox}
            ?vLowerCase=${!!ops.validationBox}
            vDisallowSpace
          >
      </w-validation-box>
    `;
  }

  get template() {
    return html`
      <form
        @changeForm=${(evt) => this.controller.onChange(evt)}
        @submit=${(evt) => this.controller.onConfirm(evt)}
        class=${styles['cards-wrapper']}
      >
        <div class=${styles.card}>
          <h3 class=${styles.title}>Reset Password</h3>

          <div class=${styles.inputs}>
            ${this.inputs}
          </div>
        </div>

        ${this.error}

        <div class=${styles.validation}>
          ${this.validationBox}
        </div>

        <div class=${styles['button-wraper']}>
          ${this.button}
        </div>

      </form>
    `;
  }

  private get inputs() {
    return repeat(INPUTS, (i: IInput) => i.name, (i) => this.input(i));
  }

  @Observe('number', 'special', 'length', 'uppercase', 'lowercase')
  private get validationBox() {
    return html`
      <w-password-validation
        number="${this.controller.number}"
        special="${this.controller.special}"
        length="${this.controller.length}"
        uppercase="${this.controller.uppercase}"
        lowercase="${this.controller.lowercase}"
      >
      </w-password-validation>
    `;
  }

  @Observe('error')
  private get error() {
    const { error } = this.controller;

    return !!error ? html`<section class="${styles.error}">${error}</section>` : nothing;
  }

  @Observe('loading', 'valid')
  private get button() {
    const { valid, loading } = this.controller;
    const text = loading ? 'Loading...' : 'Confirm';

    return html`<button class="w-button primary" ?disabled=${!valid || loading}>${text}</button>`;
  }
}

export default ProfileResetPasswordView;
