import { manageMount } from 'lib/lit-components-manager';

import Router from 'services/router';
import { RouterModule } from 'services/router/router.m';

import ChatsController from './chats.controller';
import ChatsView from './chats.view';

@RouterModule({
  view: ChatsView,
  controller: ChatsController,
})
class ChatsModule {
  public subs: Set<() => void> = new Set([]);
  public router: Router;
  public view: ChatsView;

  public mount() {
    if (this.router.display === 'mobile' && this.router.route.name === 'chat') {
      manageMount(this.view.template, 'center');
    } else {
      manageMount(this.view.template, 'left');
    }
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default ChatsModule;
