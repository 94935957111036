import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _set from 'lodash/set';
import uuid from 'uuid';

function Controller(TargetController, plugins = new Map(), data = {}) {
  const controller = new TargetController();
  const defaultFn = () => {};

  controller.OnInit = controller.OnInit || defaultFn;
  controller.OnDestroy = controller.OnDestroy || defaultFn;

  controller.state = {};
  controller.state_listeners = {};
  controller.data_listeners = {};
  controller.stateListeners = new Map();

  controller.data = data;

  plugins.forEach((value, key) => {
    controller[key] = value;
  });

  const notUpdateIfSame = (data, key, value) => {
    const prevValue = _get(controller.state, key);

    return _isEqual(prevValue, value);
  };

  const subscribeToChanges = (fn, main_entity, sub_entity,  ...keys) => {
    const unsubscribe = () => keys.forEach((key) => controller[`${main_entity}_listeners`][key].delete(fn));

    fn(controller[main_entity], controller[sub_entity]);

    keys.forEach((key) => {
      controller[`${main_entity}_listeners`][key] = controller[`${main_entity}_listeners`][key] || new Set([]);
      controller[`${main_entity}_listeners`][key].add(fn);
    });

    controller.module && controller.module.addSubscription(unsubscribe);

    return unsubscribe;
  };

  const updateStateOrData = (key, value, entity) => {
    if (notUpdateIfSame(controller[entity], key, value)) { return; }

    _set(controller[entity], key, value);

    const l_key = key.split('.')[0];

    if (!controller[`${entity}_listeners`][l_key]) { return; }

    controller[`${entity}_listeners`][l_key].forEach((listener) => listener(controller[entity]));
  };

  controller.setStateByKey = (key, value) => updateStateOrData(key, value, 'state');
  controller.setDataByKey = (key, value) => updateStateOrData(key, value, 'data');

  controller.subscribeState = (fn, ...keys) => subscribeToChanges(fn, 'state', 'data', ...keys);
  controller.subscribeData = (fn, ...keys) => subscribeToChanges(fn, 'data', 'state', ...keys);

  controller.new_uuid = uuid;

  return controller;
}

export default Controller;
