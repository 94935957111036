import { RouterModule } from 'services/router/router.module';

import styles from './collective.module.scss';
import CollectiveController from './collective.controller';
import CollectiveTemplate from './collective.view';
import CollectiveDirectives from './collective.directives';

class CollectiveModule extends RouterModule {
  subs = new Set([]);

  mount() {
    const collective = new CollectiveTemplate(this.module);

    collective.directives = CollectiveDirectives;
    collective.controller = CollectiveController;
    collective.styles = styles;

    this.manageLitMount(collective.template, 'header-collective');
  }

  unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default CollectiveModule;
