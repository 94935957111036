import RouterService from 'services/router';

import routes, { PROFILE_TABS } from './routes';

import RouterControllerPlugins from 'plugins/router-controller.plugins';
import RouterModulePlugins from 'plugins/router-module.plugins';
import RouterPlugins from 'plugins/router.plugins';
import errorHandler from './error-handler';
import getModals from './modals';

const config = {
  onInit() {
    console.log('Started %s', new Date());
  },

  routes,

  notFound: () => {
    console.log('Route not found');
  },

  onError: errorHandler,

  plugins: RouterPlugins,
  module_plugins: { ...RouterModulePlugins, constants: { ...RouterModulePlugins.constants, PROFILE_TABS } },
  controller_plugins: RouterControllerPlugins,
};

export const Router = new RouterService(config);

export const Modals = getModals();
