import Fragment from 'services/templator/fragment';
import { html } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';

class ScheduleOptions extends Fragment {
  option(label, nextScreen) {
    const type = label === 'Call' ? 'call' : 'meeting';

    const classes = {
      [this.styles.option]: true,
      [this.styles.selected]: this.controller.state.selectedOption === type,
    };

    return html`
      <div class="${classMap(classes)}" @click=${() => this.controller.selectOption(type, nextScreen)}>
        <w-checkbox className=${this.styles.checkbox} value=${this.controller.state.selectedOption === type}> </w-checkbox>
        <span>${label}</span>
      </div>
    `;
  }

  get template() {
    return html`
      <div class="${this.styles.options}">
        <h1>Scheduling options</h1>
        ${this.option('Call', 'meet_time')}
        ${this.option( 'Meet up', 'meet_time')}
      </div>
    `;
  }
}

export default ScheduleOptions;
