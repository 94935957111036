import { Observe } from 'decorators';
import { html } from 'lit-html';
import MatchHeaderController from 'modules/MatchHeader/match-header.controller';
import styles from './match-header.module.scss';

class MatchHeaderView {
  public controller: MatchHeaderController;

  @Observe(
    'matchAvatar',
    'matchName',
    'matchRole',
    'matchBusinessGroupDivision',
    'matchBusinessUnit',
    'matchLocation'
  )
  get template() {
    const {
      matchAvatar,
      matchLocation,
      matchBusinessUnit,
      matchBusinessGroupDivision,
      matchName,
      matchRole,
    } = this.controller;

    return html`
      <div class="${styles.wrapper}">
        <h1>Meet your match</h1>
        <div class="${styles['info-wrapper']}">
          <w-avatar avatar="${matchAvatar}" title="${matchName}" width="120px"> </w-avatar>

          <div class="${styles.info}">
            <h2>${matchName}</h2>

            <h3>${matchRole}</h3>
            <h3>${matchBusinessGroupDivision}</h3>
            <h3>${matchBusinessUnit}</h3>
            <h3>${matchLocation}</h3>
          </div>
        </div>
      </div>
    `;
  }
}

export default MatchHeaderView;
