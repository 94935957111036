import { observable } from 'decorators';
import { Auth, REST } from 'services';
import { Worker } from 'services';
import { gaTrackLogin } from 'services/analytics.service';
import { Router } from 'services/router.service';

class LoginController {
  @observable() public valid: boolean;
  @observable() public loading: boolean;
  @observable() public error: string;

  public customErrorMessages = {
    'Password attempts exceeded': 'Password attempts exceeded. Please contact your WERKIN Programme Manager to have this reset.',
  };

  public OnInit() {
    if (!Router.state.email) {
      return Router.go({ name: 'get-link' });
    }
  }

  public link(evt, route) {
    evt.preventDefault();

    route && Router.go(route);
  }

  public onChange(e) {
    if (this.error) {
      this.error = undefined;
    }

    this.valid = Array.from(e.target.controls).every(([, { valid }]) => valid);
  }

  public async onNext(evt) {
    evt.preventDefault();

    try {
      this.loading = true;
      this.valid = false;

      const state = { ...Router.state };

      await Auth.login(state.email, evt.target.password.value);
      const { data: { route } } = await REST.getLandingRoute();
      const chatRoute = Router.display === 'mobile' ? '/' : route;

      delete state.email;

      gaTrackLogin();
      Worker.subscribePushManager();
      Router.go({ path: route === '/match' ? route : chatRoute, state });
    } catch (err) {
      console.warn(`${err.code}: ${err.message}`);
      if (err.message === 'New password is required.') {
        Router.go({ name: 'temporary' });
      } else {
        this.error = this.customErrorMessages[err.message] || err.message;
      }
    } finally {
      this.valid = true;
      this.loading = false;

    }
  }
}

export default LoginController;
