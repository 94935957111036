import styles from 'components/custom/w-button.module.scss';
import { html, TemplateResult } from 'lit-html';
import { customElement } from './decorators';
import { ButtonVariant } from './enums';
import { TemplatedElement } from './TemplatedElement';

@customElement({ name: 'w-button' })
class WButton extends TemplatedElement {
  private _el: HTMLButtonElement = document.createElement('button');

  static get observedAttributes() {
    return ['disabled', 'type'];
  }

  public connectedCallback() {
    this.appendChild(this._el);
    this._el.classList.add(styles.button);
  }

  get variant() {
    return this.getAttribute('variant') || ButtonVariant.PRIMARY;
  }

  get type() {
    return this.getAttribute('type') || 'button';
  }

  get disabled() {
    return this.hasAttribute('disabled');
  }

  public attributeChangedCallback(name, old, newValue) {
    name === 'type' && !!newValue && this._el.setAttribute(name, newValue);
    this._el.disabled = this.hasAttribute('disabled');
    this.update();
  }

  get template(): TemplateResult {
    return html`
    <style style="display: none">
    .w-button {
      align-items: center;
      background-color: #ffffff;
      border: 0;
      border-radius: 42px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
      display: flex;
      font-size: 12px;
      font-weight: 700;
      justify-content: center;
      min-height: 22px;
      padding: 5px 30px;
      cursor: pointer;
    }

    .w-button:disabled {
      opacity: 0.6;
    }

    .w-button:active, .w-button:focus {
      outline: none;
    }

    .w-button:hover:not(:disabled) {
      opacity: 0.6;
    }

    button:active, button:focus {
      outline: none;
    }

    button:hover:not(.disabled) {
      opacity: 0.6;
    }

    .primary {
      background-color: ${window.COLORS.DEEP_TEAL.hash};
      color: ${window.COLORS.FLAT_WHITE.hash};
      font-weight: 600;
    }

    .secondary {
      background-color: transparent;
      border-radius: 10px;
      box-shadow: 0 0 3px ${window.COLORS.DEEP_TEAL.hash};
      color: ${window.COLORS.DEEP_TEAL.hash};
    }

    .outline {
      background-color: transparent;
      box-shadow: none;
      border: 1px solid ${window.COLORS.DEEP_TEAL.hash};
      border-radius: 10px;
      color: ${window.COLORS.DEEP_TEAL.hash};
    }

    .link {
      box-shadow: none;
      background-color: transparent;
      color: ${window.COLORS.DEEP_TEAL.hash};
      font-weight: 600
    }

    .light {
      background-color: transparent;
      box-shadow: 0 0 3px rgba(${window.COLORS.VERY_LIGHT_PINK.RGB}, 0.5);
      color: ${window.COLORS.VERY_LIGHT_PINK.hash};
    }

    :disabled {
      opacity: 0.6;
      cursor: default;
    }
    </style>
    <button
      ?disabled="${this.disabled}"
      class="${`w-button ${this.variant}`}"><slot></slot></button>`;
  }
}

export default WButton;
