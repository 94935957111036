import { render } from 'lit-html';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';
import HeaderMenuController from './header-menu.controller';
import HeaderMenuView from './header-menu.view';

@RouterModule({
  name: 'w-header-menu-module',
  view: HeaderMenuView,
  controller: HeaderMenuController,
})
class HeaderMenuModule {
  public subs = new Set([]);
  public view: HeaderMenuView;

  public loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default HeaderMenuModule;
