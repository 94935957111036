import { html } from 'lit-html';
import Template from 'services/templator/template';
import { userStatus } from 'components/lit';
import { Router } from 'services/router.service';
import { Auth } from 'services';
class ProfileAvatarTemplate extends Template {
  get template() {
    return html`
      <button
       @click=${() => this.controller.handleProfileClick()}
       class="${this.styles.avatar}"
      >
        ${this.directives.getAvatar()}
      </button>`;
  }

  avatar(avatar, name, do_not_disturb, notification_mute) {
    return html`
      ${userStatus(Auth.getUserId())}
      <w-avatar avatar="${avatar}" title="${name}" width="${Router.display === 'mobile' ? '40px' : '50px'}"> </w-avatar>
      `;
  }
}

export default ProfileAvatarTemplate;
