import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import ForgotPasswordController from './forgot-password.controller';
import ForgotPasswordView from './forgot-password.view';

@RouterModule({
  name: 'w-forgot-password-module',
  view: ForgotPasswordView,
  controller: ForgotPasswordController,
})

class ForgotPasswordModule {
  public view: ForgotPasswordView;

  public mount(module) {
    render(this.view.template, module);
  }
}

export default ForgotPasswordModule;
