import apollo from 'api';
import { format, isToday, isYesterday } from 'date-fns';
import { observable } from 'decorators';
import { IMessage } from 'interfaces';
import { CursorAttributes } from 'interfaces/cursor.interface';
import { Auth } from 'services';
import Editor from 'services/editor/editor.service';
import { Router } from 'services/router.service';
import { errorHandler } from 'utils/error-helpers';
import AcceptActionInvitationMutation from '../../../graphql/mutations/acceptActionInvitation.graphql';
import DeclineActionInvitationMutation from '../../../graphql/mutations/declineActionInvitation.graphql';
import DeleteChatMessageMutation from '../../../graphql/mutations/deleteMessage.graphql';
import ReadChatMessagesMutation from '../../../graphql/mutations/readChatMessages.graphql';
import SendWaveMutation from '../../../graphql/mutations/sendChatWave.graphql';
import ChatQuery from '../../../graphql/queries/chat/Chat.graphql';
import ChatMessagesQuery from '../../../graphql/queries/chat/ChatMessages.graphql';
import ChatsListQuery from '../../../graphql/queries/chat/ChatsList.graphql';
import MessagesModule from './messages.module';


class MessagesController {
  public module: MessagesModule;
  public limit = 10;
  public scrollOffset;
  public messagesQuery: any;
  public groupedMessages: Map<string, IMessage> = new Map([]);
  @observable(null, true) public isMember: boolean;
  @observable(true, true) public isLoading: boolean;
  @observable(false, true) public isFetching: boolean;

  public initialCursor: CursorAttributes = {
    pageInfo: {
      hasPreviousPage: null,
      startCursor: null,
    },
  };

  constructor() {
    this.handleFetchMore = this.handleFetchMore.bind(this);
    this.handleScrollToBottom = this.handleScrollToBottom.bind(this);
  }

  public async OnInit() {
    await this.watchChat();
  }

  public async watchChat() {
    const userId = Auth.getUserId();
    const observe = await apollo.watchQuery({
      query: ChatQuery,
      variables: {
        id: Router.params.chatId,
      },
    }).subscribe({
      next: ({ data: { chat }, loading }) => {
        if (loading || !chat) {
          return this.isLoading = loading;
        }

        const { participants, unreadMessageIds } = chat;
        this.isMember = participants.find(({ id }) => id === userId);

        if (this.isMember) {
          this.handleReadMessages(unreadMessageIds);
          this.watchMessages();
        }
      },
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public watchMessages() {
    this.messagesQuery = apollo.watchQuery({
      query: ChatMessagesQuery,
      variables: {
        chatId: Router.params.chatId,
        last: this.limit,
        before: null,
      },
    });

    const observe = this.messagesQuery.subscribe({
      next: ({ data: { chatMessages: { nodes, pageInfo } }, loading }) => {
        this.isLoading = loading;
        this.isFetching = true;
        if (nodes.length !== this.limit && nodes.length === (this.messagesTotal + this.limit)) {
          this.retainScrollPosition();
        } else {
          this.observeScrollToBottom();
        }

        this.setCursor({ pageInfo });
        this.formatMessages(nodes);
      },
      error: (e) => {
        console.error('watchMessagesError', e);
        errorHandler(e, true);
      },
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public get messagesTotal(): number {
    return [...this.groupedMessages.values()].flat().length;
  }

  public formatMessages(messages: IMessage[]) {
    const groupings = this.groupedMessages = messages.reduce((acc, message) => {
      const formattedDate = format(new Date(message.sent), 'yyyy-MM-dd');
      const existingMessages = acc.get(formattedDate) || [];

      acc.set(formattedDate, [...existingMessages, message]);

      return acc;
    }, new Map());

    this.isFetching = false;

    return this.groupedMessages = groupings;
  }

  public get getCursor(): CursorAttributes {
    const cursor = localStorage.getItem(`cursor_${Router.params.chatId}`);

    return cursor ? JSON.parse(cursor) : this.initialCursor;
  }

  private setCursor(cursor: CursorAttributes) {
    const updatedCursor = {
      ...this.getCursor,
      ...cursor,
    };

    localStorage.setItem(`cursor_${Router.params.chatId}`, JSON.stringify(updatedCursor));
  }

  public async handleFetchMore() {
    const { pageInfo } = this.getCursor;
    const cursor = pageInfo.startCursor;

    try {
      this.isFetching = true;
      this.messagesQuery.fetchMore({
        variables: {
          before: cursor,
          last: 10,
        },
        updateQuery: (previousResult, { fetchMoreResult: { chatMessages } }) => {
          const previousNodes = previousResult.chatMessages.nodes;
          const newNodes = chatMessages.nodes;
          const pageInfo = chatMessages.pageInfo;

          return newNodes.length ? {
            chatMessages: {
              __typename: previousResult.chatMessages.__typename,
              nodes: [...newNodes, ...previousNodes],
              pageInfo,
              totalCount: chatMessages.totalCount,
            },
          } : previousResult;
        },
      });
    } catch (err) {
      console.error(err);
      this.isFetching = false;
    }
  }

  public handleReadMessages(unreadMessageIds) {
    const { chatId } = Router.params;

    if (unreadMessageIds.length > 0) {
      apollo.mutate({
        mutation: ReadChatMessagesMutation,
        variables: {
          command: {
            chatId,
            ids: unreadMessageIds,
          },
        },
        refetchQueries: [{
          query: ChatsListQuery,
        }],
      });
    }
  }

  public formatDate(date) {
    const parsedDate = new Date(date);

    switch (true) {
      case isToday(parsedDate):
        return 'Today';
      case isYesterday(parsedDate):
        return 'Yesterday';
      default:
        return format(parsedDate, 'EEEE MMMM do');
    }
  }

  public observeScrollToBottom() {
    const messagesWrapper = document.getElementById('chat_messages');
    const observer = new MutationObserver((mutations) => {
      const list = document.getElementById('messages-list');
      if (document.contains(list)) {
        this.handleScrollToBottom();
        observer.disconnect();
      }
    });

    messagesWrapper && observer.observe(messagesWrapper, {
      childList: true,
      subtree: true,
    });
  }

  public handleScrollToBottom() {
    const list = document.getElementById('messages-list');
    this.scrollOffset = list.scrollHeight;
    list.scrollTop = list.scrollHeight;
  }

  public retainScrollPosition() {
    const list = document.getElementById('messages-list');
    const observer = new MutationObserver((mutations) => {
      list.scrollTop = list.scrollHeight - this.scrollOffset;
      this.scrollOffset = list.scrollHeight;
      observer.disconnect();
    });

    list && observer.observe(list, {
      childList: true,
      subtree: true,
    });

    this.module.subs.add(() => observer.disconnect());
  }

  public async handleSendWave() {
    const { chatId } = Router.params;

    await apollo.mutate({
      mutation: SendWaveMutation,
      variables: {
        command: {
          chatId,
        },
      },
      refetchQueries: [{
        query: ChatMessagesQuery,
        variables: {
          chatId,
        },
      }],
    });
  }

  public onContextOpen(e) {
    Editor.quill.blur();
    Editor.quill.enable(false);
  }

  public onContextClose(e) {
    Editor.quill.enable(true);
  }

  public handleUrl(evt) {
    Router.go({ path: evt.detail.button.value });
  }

  public handleCommand(evt) {
    const command = Router.controller.convertKeys(JSON.parse(evt.detail.button.data));

    const mutations = {
      acceptActionInvitation: AcceptActionInvitationMutation,
      deleteChatMessage: DeleteChatMessageMutation,
      declineActionInvitation: DeclineActionInvitationMutation,
    };

    apollo.mutate({
      mutation: mutations[evt.detail.button.value],
      variables: {
        command,
      },
      refetchQueries: [{
        query: ChatQuery,
        variables: {
          id: Router.params.chatId,
        },
      }],
    });
  }

  public handleDeleteMessage(evt: CustomEvent) {
    const { message } = evt.detail;

    Router.go({ hash: { DeleteMessageModal: true, messageId: message.id } });
  }

  public handleReportMessage(evt: CustomEvent) {
    const { message } = evt.detail;

    Router.go(
      {
        name: 'chats.report',
        params: {
          chatId: Router.params.chatId,
          messageId: message.id,
        },
      }
    );
  }
}

export default MessagesController;
