import apollo from 'api';
import CenterAutonomousModal from 'components/lit/modals/center-autonomus-modal.view';
import { AutonomousModal } from 'decorators';
import { Router } from 'services/router.service';
import deleteChatMessageMutation from '../../../graphql/mutations/deleteMessage.graphql';
import ChatQuery from '../../../graphql/queries/chat/Chat.graphql';

@AutonomousModal('DeleteMessageModal', 'messageId')
class DeleteMessageModal extends CenterAutonomousModal {
  public onOpen() {
    this.title = 'Delete message?';
    this.text = `
      This will remove your message from the chat for all chat
      participants and permanently delete it. Are you sure?
    `;

    this.submitBtnText = 'Delete';

    this.template = this.wrap(this.tmp);
  }

  public submitForm() {
    const { messageId } = Router.hash;
    const { chatId } = Router.params;

    apollo.mutate({
      mutation: deleteChatMessageMutation,
      variables: {
        command: {
          chatId,
          id: messageId,
        },
      },
      refetchQueries: [{
        query: ChatQuery,
        variables: {
          id: chatId,
        },
      }],
    })
      .catch((e) => console.log(e));

    this.destroy();
  }
}

export default DeleteMessageModal;
