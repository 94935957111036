import apollo from 'api';
import { nothing, render } from 'lit-html';
import Editor from 'services/editor/editor.service';
import { Router } from 'services/router.service';
import { RouterModule } from 'services/router/router.m';
import ChatQuery from '../../graphql/queries/chat/Chat.graphql';

@RouterModule({
  name: 'w-chat-input-module',
})
class InputModule {
  private subs = new Set([]);

  private async setEditorState(module) {
    const { data: { chat } } = await apollo.query({
      query: ChatQuery,
      variables: {
        id: Router.params.chatId,
      },
    });

    if (!!chat) {
      render(Editor.wrapper, module);
    } else {
      render(nothing, module);
    }
  }

  public mount(module) {
    !Editor.initialized && Editor.init();
    this.setEditorState(module);

    const unsubscribeRouter = Router.listen(async () => {
      if (!Router.params.chatId) { return; }

      this.setEditorState(module);
    });

    this.subs.add(unsubscribeRouter);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default InputModule;
