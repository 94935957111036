import FullScreenModal from 'components/lit/modals/full-screen-modal.view';
import { AutonomousModal, ObserveResizing } from 'decorators';
import { html, nothing } from 'lit-html';
import { Router } from 'services/router.service';
import styles from './chats-list-modal.module.scss';

@AutonomousModal('ChatListModal')
class ChatListModal extends FullScreenModal {
  public title = 'Find your channel or group';

  public onOpen() {
    this.template = this.wrap(this.tmp);
  }

  @ObserveResizing()
  private get desktopTitle() {
    return Router.display === 'desktop' ? this.titleTmp : nothing;
  }

  public get body() {
    return html`
      <div class=${styles.modal}>
        ${this.desktopTitle}
        <w-chats-list-module .destroy=${() => this.destroy()}> </w-chats-list-module>
      </div>
    `;
  }
}

export default ChatListModal;
