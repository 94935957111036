import store from '@werkin/store';
import apollo from 'api';
import { observable } from 'decorators';
import _groupBy from 'lodash/groupBy';
import JoinTemplate from 'modules/Join/join.view';
import { NotificationManager } from 'services';
import { gaTrackEvent } from 'services/analytics.service';
import REST from 'services/rest.service';
import { Router } from 'services/router.service';
import datetime from 'utils/datetime';
import CitiesQuery from '../../graphql/queries/cities.graphql';
import CountriesQuery from '../../graphql/queries/countries.graphql';

class JoinController {
  public view: JoinTemplate;

  @observable(0) public activeScreen: number;
  @observable() public oneValid: boolean;
  @observable() public twoValid: boolean;
  @observable() public threeValid: boolean;
  @observable() public fiveValid: boolean;
  @observable() public sixValid: boolean;
  @observable() public loading: boolean;
  @observable() public micrositeUrl: string;
  @observable('') public commitmentLabel: string;
  @observable('') public roleLabel: string;
  @observable('') public onboardHeader: string;
  @observable([]) public countryOptions: any[];
  @observable([]) public cityOptions: any[];
  @observable([]) public industryOptions: any[];
  @observable([]) public sectorOptions: any[];
  @observable([]) public stageOptions: any[];
  @observable([]) public roleOptions: any[];
  @observable([]) public timezonesOptions: any[];
  @observable([]) public commitmentOptions: any[];
  @observable([]) public contributionOptions: any[];
  @observable([]) public availabilityOptions: any[];
  @observable([]) public frequencyOptions: any[];
  @observable([]) public learningAndDevelopmentSkillsOptions: any[];

  @observable([]) public disabledFields: string[];
  @observable() public hiddenFields: string[];

  @observable('') public organization: string;
  @observable('') public group: string;
  @observable('') public unit: string;
  @observable('') public title: string;
  @observable('') public linkedin: string;
  @observable() public stage: any;
  @observable() public country: any;
  @observable() public city: any;
  @observable() public role: any;
  @observable('') public desiredQualities: string;
  @observable('') public hoping: string;
  @observable() public learning_one: any;
  @observable() public learning_two: any;
  @observable() public learning_three: any;
  @observable('') public learning_one_goal: string;
  @observable('') public learning_two_goal: string;
  @observable('') public learning_three_goal: string;
  @observable('') public erg: string;
  @observable() public frequency: any;
  @observable() public availability: any;
  @observable() public contribution: any;
  @observable() public timezones: any;
  @observable() public commitment: any;
  @observable() public industry: any;
  @observable() public sector: any;

  @observable(null) public kpis: any;
  @observable(0) public activeKPI: number;
  @observable({}) public selectedKPIs: any;

  public results = [];

  @observable('') public search: string;
  @observable([]) public filteredResults: any[];
  @observable({}) public selected: any;
  @observable() public loadingSkills: boolean;

  constructor() {
    this.handleKPISelect = this.handleKPISelect.bind(this);
    this.onPreviousKPI = this.onPreviousKPI.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  public handleKPISelect(name, value) {
    this.selectedKPIs = { ...this.selectedKPIs, [name]: value };

    if (this.activeKPI < this.kpis.questions.length - 1) {
      this.activeKPI = this.activeKPI + 1;
    }
  }

  public onPreviousKPI() {
    this.activeKPI = this.activeKPI ? this.activeKPI - 1 : this.activeKPI;
  }

  public onSearch(evt) {
    REST.getSkills(evt.target.value.trim().toLowerCase()).then((results) => {
      this.results = results.data;
      this.loadingSkills = false;
      this.filteredResults = results.data.filter((item) => !this.selected[item.id]);
    });
  }

  public addSkill(item) {
    this.selected = { ...this.selected, [item.id]: { ...item, experience: 0 } };
    this.filteredResults = this.results.filter((item) => !this.selected[item.id]);
  }

  public removeSkill(id) {
    const selected = this.selected;

    delete selected[id];

    this.selected = selected;
    this.filteredResults = this.results.filter((item) => !this.selected[item.id]);
  }

  public updateExperience(item, change) {
    const selected = this.selected;

    selected[item.id] = { ...selected[item.id], experience: selected[item.id].experience + change };

    this.selected = selected;
  }

  public onInput(evt) {
    this.loadingSkills = !!evt.target.value;
    this.search = evt.target.value;

    if (!evt.target.value) {
      this.filteredResults = [];
    }
  }

  private groups: any;
  private countrySelected: boolean;

  public get cityEmpty() {
    return this.countrySelected ? 'Cities not found' : 'Select country first';
  }

  public OnInit() {
    this.fetchCountries();
    REST.getSystemData().then((
      {
        data: {
          onboarding: {
            flow,
            disabledFields,
            hiddenFields,
            micrositeUrl,
            preselectedData,
            text,
            ...props
          },
          values,
          kpis,
        },
      }
    ) => {
      this.kpis = kpis;
      this.micrositeUrl = micrositeUrl;
      this.commitmentLabel = text['onboard.contract.commitment'];
      this.roleLabel = text['onboard.role.label'];
      this.onboardHeader = text['onboard.header'];
      this.groups = _groupBy(values, (obj) => obj.category);
      this.industryOptions = this.groups.industry;
      this.stageOptions = this.groups['career-stages'];
      this.roleOptions = this.groups['mentoring-preferences'];
      this.timezonesOptions = this.groups['contract-time-zones'];
      this.commitmentOptions = this.groups['contract-commitment'];
      this.contributionOptions = this.groups['contract-contribution'];
      this.availabilityOptions = this.groups['contract-availability'];
      this.frequencyOptions = this.groups['contract-contact-frequency'];
      this.learningAndDevelopmentSkillsOptions = this.groups['learning-development-area'];

      this.disabledFields = disabledFields;
      this.hiddenFields = hiddenFields;

      preselectedData && Object.keys(preselectedData).forEach((key) => {
        if (`${key}Options` in this) {
          const arr = this[`${key}Options`].length ? this[`${key}Options`] : this.groups[key];
          this[key] = arr && arr.find((opt) => preselectedData[key] === opt.id);

          if (key === 'learningAndDevelopmentSkills') {
            this.setLearningAndDevOptions(preselectedData[key]);
          }
        } else {
          this[key] = preselectedData[key];
        }
      });
    });
  }

  public async fetchCountries() {
    const { data: { countries } } = await apollo.query({
      query: CountriesQuery,
      variables: {
        search: '',
      },
    });

    this.countryOptions = countries;
  }

  public async fetchCities(child, country) {
    const { data: { cities } } = await apollo.query({
      query: CitiesQuery,
      variables: {
        countryId: country.id,
        search: '',
      },
    });

    this.city = null;
    this.cityOptions = cities;
    child && child.dispatchEvent(new CustomEvent('reset', { bubbles: true }));
  }

  public setLearningAndDevOptions(opts: string[]) {
    const options = this.learningAndDevelopmentSkillsOptions;
    this.learning_one = options.find((o) => o.id === opts[0]);
    this.learning_two = options.find((o) => o.id === opts[1]);
    this.learning_three = options.find((o) => o.id === opts[2]);
  }

  public learningAndDevOptions(opt: any) {
    const arr = [this.learning_one, this.learning_two, this.learning_three].filter((fOpt) =>
      fOpt && opt && fOpt.id !== opt.id);

    return this.learningAndDevelopmentSkillsOptions.filter((opt) => !arr.some((v) => v.id === opt.id));
  }

  get socialMediaOptions() {
    const types = ['linkedin', 'twitter', 'instagram', 'facebook'];

    return types.map((type) => ({ type, value: this[type] || '' }));
  }

  public onChange(e) {
    const { name, value, _value } = e.detail.current;
    this[name] = _value || value;
    if (name === 'country') {
      const child = e.target.elements.city;

      this.fetchCities(child, this.country);
    }

    if (name === 'industry') {
      this.resetChildSelect(e, 'industry', 'sector');
    }
    const { controls, name: formName } = e.target;
    this[`${formName}Valid`] = Array.from(controls).every(([, { valid }]) => valid);
  }

  public onNext(e, message) {
    e.preventDefault();
    gaTrackEvent({ category: 'onboarding', action: `step-${this.activeScreen}`, label: message });
    this.activeScreen = this.getNext(this.activeScreen);
  }

  private getNext(ind: number, rewind?: boolean) {
    const newInd = rewind ? ind - 1 : ind + 1;
    if (!this.view[this.view.screens[newInd]]) {
      throw new Error('Incorrect step');
    }

    if (this.view[this.view.screens[newInd]].fields.some((f) => !this.hiddenFields.includes(f))) {
      return newInd;
    }

    return this.getNext(newInd, rewind);
  }

  public onBack(e) {
    e.preventDefault();
    this.activeScreen = this.getNext(this.activeScreen, true);
  }

  public resetChildSelect(e, parent: string, child: string) {
    const childSelect = e.target.elements[child];

    this[child] = undefined;

    childSelect && childSelect.dispatchEvent(new CustomEvent('reset', { bubbles: true }));
    const parentCtrl = e.target.controls.get(parent);
    if (parentCtrl && parentCtrl.value) {
      this[`${parent}Selected`] = true;
      this[`${child}Options`] = this.groups[child].filter(({ parentId }) => parentId === parentCtrl.value.id);
    }
  }

  public async onSubmit(e) {
    e.preventDefault();
    this.loading = true;
    const { data: { contractValidation } } = await REST.onboard(this.collectData());

    if (contractValidation === 'fail') {
      gaTrackEvent({
        category: 'onboarding',
        action: 'validation-failure',
        label: 'Contract validation failed',
      });

      window.location.replace(`/before-continue#micrositeUrl=${this.micrositeUrl}`);
    }

    if (contractValidation === 'pass') {
      gaTrackEvent({
        category: 'onboarding',
        action: 'validation-success',
        label: 'Contract validation success',
      });

      NotificationManager.showNotification({
        text: 'Thank you for registering. We hope you enjoy the programme.',
        type: 'success',
        duration: 2000,
      });

      const { data: { route } } = await REST.getLandingRoute();
      console.log('LANDING ROUTE: ', route);
      store.set('loading', true);
      setTimeout(() => { Router.go({ path: route }); }, 2000);
    }
  }

  public collectData() {
    const {
      organization, industry, sector, title, unit,
      group, erg, city, country, availability,
      commitment, contribution, frequency, timezones,
      hoping, learning_one, learning_two, learning_three,
      desiredQualities, role, stage,
      learning_one_goal, learning_two_goal, learning_three_goal,
      socialMediaOptions, selectedKPIs,
    } = this;

    return {
      job: {
        organization,
        industry: industry ? industry.id : null,
        sector: sector ? sector.id : null,
        title,
        unit,
        group,
        ergs: [erg],
      },

      location: {
        city: city.id,
        country: country.id,
        timeZone: datetime.zone,
      },

      contract: {
        availability: availability.id,
        commitment: commitment.id,
        contribution: contribution.id,
        frequency: frequency.id,
        openToDifferentTimeZones: timezones.id,
      },
      goalsAndObjectives: [hoping],
      learningAndDevelopmentAreas: [
        { area: learning_one.id, goal: learning_one_goal },
        { area: learning_two.id, goal: learning_two_goal },
        { area: learning_three.id, goal: learning_three_goal },
      ],
      desiredQualities,
      role: role.id,

      skillsAndInterests: Object.keys(this.selected).map((id) =>
        ({ id, yearsOfExperience: this.selected[id].experience })),

      careerStage: stage.id,
      socialMedia: socialMediaOptions,
      kpis: selectedKPIs,
    };
  }
}

export default JoinController;
