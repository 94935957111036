import apollo from 'api';
import { observable } from 'decorators';
// tslint:disable-next-line:import-spacing
import ProfileNotificationsAndEmailsModule
  from 'modules/ProfileNotificationsAndEmails/profile-notifications-and-emails.module';
import { NotificationManager, Worker } from 'services';
import resetNotificationPreferencesMutation from '../../graphql/mutations/resetNotificationPreferences.graphql';
import setNotificationPreferencesMutation from '../../graphql/mutations/setNotificationPreference.graphql';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';

class ProfileNotificationsAndEmailsController {
  public module: ProfileNotificationsAndEmailsModule;
  public collapsableAlerts = {};

  @observable([], true) public permissions: any[];
  @observable(null, true) public allowNotifications: string;
  @observable(true) public next: boolean;
  @observable(false) public loading: boolean;
  @observable({}, true) public selectedSettings: any;

  public OnInit() {
    this.checkForBrowserNotifications();
    this.watchProfile();
  }

  private watchProfile() {
    const observe = apollo.watchQuery({
      query: ProfileQuery,
    }).subscribe({
      next: ({ data: { profile } }) => {
        const { types } = profile.notifications;

        this.permissions = types;
        this.selectedSettings = types.reduce((acc, { settings, category }) => {
          acc[category] = settings;

          return acc;
        }, {});
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  private checkForBrowserNotifications() {
    this.allowNotifications = Worker.currentPermission;

    const check = setInterval(() => {
      this.allowNotifications = Worker.currentPermission;
    }, 1000);

    this.module.subs.add(() => clearInterval(check));
  }

  private updateSelectedSettings(category, setttingsItem) {
    this.selectedSettings = {
      ...this.selectedSettings,
      [category]: this.selectedSettings[category].map((p) => {
        if (p.id === setttingsItem.id) {
          return setttingsItem;
        }

        return p;
      }),
    };
  }

  public onSetPermission(category, setttingsItem) {
    try {
      apollo.mutate({
        mutation: setNotificationPreferencesMutation,
        variables: {
          command: {
            id: setttingsItem.id,
            value: setttingsItem.value,
          },
        },
        refetchQueries: [{
          query: ProfileQuery,
        }],
      });

      this.updateSelectedSettings(category, setttingsItem);
      NotificationManager.showNotification({ text: 'Your notification settings have been updated!', type: 'success'});
    } catch (err) {
      NotificationManager.showNotification({ text: 'Oh no, we couldn\'t update your settings just now. Please try again.', type: 'warning'});
      console.log('onSetPermission', err);
    }
  }

  public async handleNotificationsPermission() {
    Worker.promptForNotifications();
  }

  public async setDefaultPermissions() {
    await apollo.mutate({
      mutation: resetNotificationPreferencesMutation,
      variables: {
        command: {
          id: '00000000-0000-0000-0000-000000000000',
        },
      },
      refetchQueries: [{
        query: ProfileQuery,
      }],
    });
  }
}

export default ProfileNotificationsAndEmailsController;
