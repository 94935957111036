import 'reflect-metadata';

interface AttributeOptions {
  type: any;
}

interface CustomElementOptions {
  name: string;
  options?: ElementDefinitionOptions;
}

export function customElement({ name, options }: CustomElementOptions) {
  return (target: any) => {
    customElements.define(name, target, options);
  };
}

export function attribute() {
  return (target: any, key: string) =>
    Object.defineProperty(target, key, {
      enumerable: true,
      configurable: true,
      get() {
        return this.getAttribute(key);
      },
    });
}

export function observableProperty(): any {
  return (target, propertyKey: string, descriptor) => ({
    get() {
      return descriptor._property;
    },
    set(value) {
      descriptor._property = value;
      this.update();
    },
  });
}
