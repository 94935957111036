import { ValidationWrapper } from 'components/lit/validated/validation-wrapper.view';
import { html } from 'lit-html';
import styles from 'components/lit/validated/validated-uploader.module.scss';
import { Icons } from 'components/lit/index';

export class ValidatedUploader extends ValidationWrapper {
  get field() {
    const {
      title = '',
      value = '',
      placeholder = '',
      name = 'icon',
      handleIconChange = () => {},
    } = this.options;

    return html`
      <div class="${styles.input}">
          ${!value ? Icons.cameraIcon : html`<img src="${value}" alt="${title}"/>` }
          <input
              type="file" name="${name}" accept=".jpg, .jpeg, .png"
              @input=${evt => handleIconChange(evt)}
           />
          </div>

          <div class="${styles['icon-info']}">
            <span> ${ title || placeholder } </span>
            <h3>Optional</h3>
          </div>
       </div>
    `;
  }
}
