import { RouterModule } from 'services/router/router.module';
import { Auth } from 'services';
import { CREDITS_TABS } from 'services/router.service/routes';

import styles from '../header.module.scss';

import NavTemplate from './nav.view';
import HeaderController from 'modules/Header/header.controller';
import { Icons } from 'components/lit';

class DashboardModule extends RouterModule {
  mount() {
    const nav = new NavTemplate(this.module, { icon: Icons.grid, name: 'dashboard' });

    nav.controller = HeaderController;
    nav.styles = styles;

    this.manageLitMount(nav.template, 'header-dashboard');
  }
}

class CalendarModule extends RouterModule {
  mount() {
    const nav = new NavTemplate(this.module, { icon: Icons.calendar, name: 'calendar' });

    nav.controller = HeaderController;
    nav.styles = styles;

    this.manageLitMount(nav.template, 'header-calendar');
  }
}

class CreditsModule extends RouterModule {
  mount() {
    const nav = new NavTemplate(this.module, {
      icon: Icons.credits,
      name: 'credits',
      params: {
        userId: Auth.getUserId(),
        tab: CREDITS_TABS[0].path,
      },
    });

    nav.controller = HeaderController;
    nav.styles = styles;

    this.manageLitMount(nav.template, 'header-credits');
  }
}

class HelpModule extends RouterModule {
  mount() {
    const nav = new NavTemplate(this.module, { icon: Icons.help, name: 'help' });

    nav.controller = HeaderController;
    nav.styles = styles;

    this.manageLitMount(nav.template, 'header-help');
  }
}

export default {
  CalendarModule,
  CreditsModule,
  DashboardModule,
  HelpModule,
};
