import { html, nothing } from 'lit-html';
import Router from 'services/router';
import SuggestionsController from './suggestions.controller';
import styles from './suggestions.module.scss';
import SuggestionsViewModel from './suggestions.viewmodel';

class SuggestionsView {
  public router: Router;
  public vm: SuggestionsViewModel;
  public controller: SuggestionsController;

  public link(label, schema, action = (e) => {}) {
    return html`
       <a
         href="${this.router.generate(schema)}"
         @click=${action.bind(this.controller)}
       >
         ${label}
       </a>
    `;
  }

  public test_button(label, handler = (e) => {}) {
    return html`
       <a
         class="${styles.test_button}"
         href="#"
         @click=${handler.bind(this.controller)}
       >
         ${label}
       </a>
    `;
  }

  get suggestions() {
    const { isDirectOrGroup, create_schedule, create_poll, nudge_action } = this.controller;
    const schedule = { name: 'chats.actions.create', params: this.router.params };
    const connect = { name: 'chats.connect', params: this.router.params };
    const isPoll = this.router.query.poll;
    const isNudge = this.router.query.nudge;

    return html`
      <div class="${styles.wrapper}">
        ${ this.vm.showIf(false, this.link('Connect', connect)) }
        ${ this.vm.showIf(isDirectOrGroup, this.link('Schedule', schedule, create_schedule)) }
        ${ this.vm.showIf(isPoll, this.test_button('Create poll', create_poll)) }
        ${ this.vm.showIf(isNudge, this.test_button('Nudge Action', nudge_action)) }
      </div>
    `;
  }

  get hide_if_not_member() {
    return html`${this.vm.isMember(this.suggestions, nothing)}`;
  }

  get template() {
    const { isDirectOrGroup } = this.controller;
    const { poll, nudge } = this.router.query;

    return this.vm.showIf(isDirectOrGroup || poll || nudge, this.hide_if_not_member);
  }
}

export default SuggestionsView;
