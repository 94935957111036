import apollo from 'api';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import store from '@werkin/store';
import { manageMount as manageLitMount } from 'lib/lit-components-manager';
import { manageMount as manageReactMount, manageUnmount as manageReactUnmount } from 'lib/react-component-manager';

const moment = extendMoment(Moment);

moment.locale('en-NZ');

export default {
  apollo,
  store,
  moment,
  manageLitMount,
  manageReactMount,
  manageReactUnmount,

  constants: {
    chat_flow: {
      MEMBER_WIDTH: 70,
      DESKTOP_VISIBLE_MEMBERS: 4,
    },
  },
};
