import classnames from 'classnames';
import { format, isToday, isWithinInterval, parseISO } from 'date-fns';
import { Observe } from 'decorators';
import { showIf } from 'directives';
import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import { Auth } from 'services';
import { Router } from 'services/router.service';
import buttonStyles from 'styles/button.module.scss';
import cardStyles from 'styles/card.module.scss';
import DashUpcomingEventsController from './dash-upcoming-events.controller';
import styles from './dash-upcoming-events.module.scss';

class DashUpcomingEventsTemplate {
  public controller: DashUpcomingEventsController;

  get noEvents() {
    return html`
      <div class=${styles['no-events']}>
        You have no events scheduled. Schedule an event from within a chat or group.
      </div>
    `;
  }

  public event(event) {
    const { statusTypes } = this.controller;
    const { id, status, title, data, organizerId } = event;
    const { subType, startTime, endTime } = data;
    const isOrganizer = organizerId === Auth.getUserId();
    const happeningNow = isWithinInterval(new Date(), { start: parseISO(startTime), end: parseISO(endTime) });
    const today = isToday(parseISO(startTime));
    const formattedDay = format(parseISO(startTime), 'EEEE d MMM');
    const formattedTime = `${format(parseISO(startTime), 'h:mmaa')} - ${format(parseISO(endTime), 'h:mmaa')}`;

    return html`
      <div class=${styles.event}>
        <div class=${classnames(styles.date, happeningNow && styles.now)}>
          <span>
            ${[!today && formattedDay, happeningNow && 'NOW', today && !happeningNow && 'Today'].filter(Boolean)}
          </span>
          <span>${formattedTime}</span>
        </div>
        <div class=${styles.detail}>
          <p class=${styles.title}>${subType.charAt(0).toUpperCase()}${subType.slice(1)}: ${title}</p>
          <button
            class=${buttonStyles.small}
            @click=${() => Router.go({ name: 'calendar.event', params: { actionId: id } })}
          >
            ${isOrganizer ? 'Change' : statusTypes[status]}
          </button>
        </div>
      </div>
    `;
  }

  public list(events: { [key: string]: any[] }) {
    return html`
      <div class=${styles.events}>
        ${Object.entries(events).map(([, events]) =>
          !!events.length
            ? html`
                <div class=${styles['event-list']}>
                  ${repeat(
                    events,
                    ({ id }) => id,
                    (event) => this.event(event)
                  )}
                </div>
              `
            : nothing
        )}
      </div>
    `;
  }

  @Observe('groupedEvents')
  get template() {
    const { groupedEvents } = this.controller;
    const hasEvents = Object.values(groupedEvents).reduce<any[]>((r: any[], k: any[]) => r.concat(k), []).length;

    return html`
      <div class=${cardStyles.card}>
        <div class=${cardStyles.header}>
          <span class=${cardStyles.label}>Upcoming events</span>
        </div>
        <div class=${cardStyles.content}>${hasEvents ? this.list(groupedEvents) : this.noEvents}</div>
      </div>
    `;
  }
}

export default DashUpcomingEventsTemplate;
