import apollo from 'api';
import { observable } from 'decorators';
import { Workbook } from 'interfaces/workbooks';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';
import DashWorkbookModule from './dash-workbook.module';

class DashWorkbookController {
  public module: DashWorkbookModule;
  @observable([]) public workbooks;
  @observable({}) public selectedWorkbook: any;

  constructor() {
    this.handleActiveWorkbook = this.handleActiveWorkbook.bind(this);
  }

  public async OnInit() {
    const workbooks = await this.fetchWorkbooks();
    const sharedWorkbooks = workbooks.filter((workbook: Workbook) => workbook.type === 'shared');

    this.selectedWorkbook =
      sharedWorkbooks.length === 0 ? workbooks.find((workbook) => workbook.type === 'personal') : sharedWorkbooks[0];
    this.workbooks = workbooks;
  }

  public handleActiveWorkbook(event: React.FormEvent<HTMLSelectElement>) {
    const { value } = event.currentTarget;

    if (!value) {
      return;
    }

    this.selectedWorkbook = this.workbooks.find((workbook: Workbook) => workbook.id === value);
  }

  public async fetchWorkbooks() {
    const {
      data: {
        profile: { workbooks },
      },
    } = await apollo.query({
      query: ProfileQuery,
      fetchPolicy: 'no-cache',
    });

    return workbooks;
  }
}

export default DashWorkbookController;
