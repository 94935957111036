import apollo from 'api';
import { Auth } from 'services';
import BaseService from 'services/data/base.service';
import IndividualQuery from '../../graphql/queries/individual.graphql';
import IndividualsQuery from '../../graphql/queries/individuals.graphql';

class IndividualsService extends BaseService {
  public async load() {
    this.setLoadingState('Building your connections');

    return this.queryPrefetcher(IndividualsQuery, 'individuals', {});
  }

  public async getIndividual(id) {
    const { data: { individual } } = await apollo.query({
      query: IndividualQuery,
      variables: {
        id,
      },
    });

    return individual;
  }

  public async getIndividuals(includeSelf: boolean = false) {
    const userId = Auth.getUserId();
    const { data: { individuals } } = await apollo.query({
      query: IndividualsQuery,
    });

    return includeSelf ? [...individuals, await this.getIndividual(userId)] : individuals;
  }
}

export default new IndividualsService();
