import apollo from 'api';
import throttle from 'lodash/throttle';
import BaseService from 'services/data/base.service';
import ActionQuery from '../../graphql/queries/action.graphql';
import ActionsQuery from '../../graphql/queries/actions.graphql';

class ActionsService extends BaseService {
  public async load() {
    this.setLoadingState('Loading events');

    return this.queryPrefetcher(ActionsQuery, 'actions');
  }

  public handleActionUpdated = throttle((id) => {
    apollo.query({
      query: ActionQuery,
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    });
  }, 4000);

  public handleActionCreated(id: string) {
    apollo.query({
      query: ActionsQuery,
      fetchPolicy: 'network-only',
    });
  }

  public handleActionDeleted(id: string) {
    apollo.query({
      query: ActionsQuery,
      fetchPolicy: 'network-only',
    });
  }
}

export default new ActionsService();
