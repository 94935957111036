import Moment from 'moment';
import { directive, html } from 'lit-html';
import { Icons } from 'components/lit';
import { extendMoment } from 'moment-range';
import styles from './datepicker.module.scss';
import { repeat } from 'lit-html/directives/repeat';
import { classMap } from 'lit-html/directives/class-map';

const moment = extendMoment(Moment);
const WEEKDAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

class DatePicker {
  constructor({ selected, onChange, disablePreviousDates = false, highlightDays = [], disabled = [] }) {
    const date = moment();
    const limitDate = moment().startOf('month').startOf('week');

    this.days = [];
    this.date = date.clone();
    this.disablePreviousDates = disablePreviousDates;
    this.disabledRange = disabled.length && moment.range(disabled.map((date) => moment(date)));
    this.highlightDays = highlightDays.map((date) => moment(date));
    this.focusedMonth = date.clone();
    this.startOfMonth = limitDate.clone();
    this.endOfMonth = limitDate.clone().add(1, 'months');
    this.onChange = onChange;

    this.selected = selected ? moment(selected) : date.clone();
    this.limitDate = limitDate;
    this.listeners = {};

    this._getDays();
  }

  setSelected(date) {
    this.selected = moment(date).toISOString();
  }

  setHighlighted(dates) {
    this.highlightDays = dates;
    this._triggerListeners('highlighted');
  }

  get header() {
    const isMinReached = this.focusedMonth.get('month') === this.date.get('month');

    return html`
      <div class="${styles.calendar_header}">
        <button ?disabled=${isMinReached} @click=${() => this._onSelectPrevMonth()}>${Icons.circleArrowLeft}</button>
        <span>${this.focusedMonth.format('MMMM, YYYY')}</span>
        <button @click=${() => this._onSelectNextMonth()}>${Icons.circleArrowRight}</button>
      </div>
    `;
  }

  weekday(day) {
    return html` <div class="${styles.weekday}">${day}</div> `;
  }

  day(day) {
    const notCurrentMonth = day.month() !== this.focusedMonth.month();
    const disabled = this.disabledRange && this.disabledRange.contains(day);
    const before = this.disablePreviousDates && day.isBefore(this.date, 'day');
    const weekEnd = [0, 6].includes(day.weekday());
    const disabledDate = before || disabled;
    const classes = {
      [styles.not_current_month]: notCurrentMonth,
      [styles.weekend]: weekEnd,
      [styles.current]: day.isSame(this.date, 'day'),
      [styles.current_selected]: day.isSame(this.date, 'day') && day.isSame(this.selected, 'day'),
      [styles.selected]: !day.isSame(this.date, 'day') && day.isSame(this.selected, 'day'),
      [styles.highlighted]: this.highlightDays && this.highlightDays.find((date) => day.isSame(date, 'day')),
    };

    return html`
      <div class="${styles.day}">
        <button ?disabled=${disabledDate} class="${classMap(classes)}" @click=${() => this._onSelect(day)}>
          ${day.format('D')}
        </button>
      </div>
    `;
  }

  get daysList() {
    return html`
      <div class="${styles.days}">
        ${repeat(
          [...WEEKDAYS, ...this.days],
          (days, i) => i,
          (day) => (WEEKDAYS.includes(day) ? this.weekday(day) : this.day(day))
        )}
      </div>
    `;
  }

  get renderMonthHeader() {
    return directive(() => (part) => {
      this._subscribe(() => {
        this._getStartEnd();

        part.setValue(this.header);
        part.commit();
      }, 'focusedMonth');
    });
  }

  get renderMonthDays() {
    return directive(() => (part) => {
      this._subscribe(
        () => {
          part.setValue(this.daysList);
          part.commit();
        },
        'startOfMonth',
        'selectedDate',
        'highlighted'
      );
    });
  }

  get template() {
    return html` ${this.renderMonthHeader()} ${this.renderMonthDays()} `;
  }

  _onSelectPrevMonth() {
    this.focusedMonth = this.focusedMonth.subtract(1, 'months');

    this._triggerListeners('focusedMonth');
  }

  _onSelectNextMonth() {
    this.focusedMonth = this.focusedMonth.add(1, 'months');

    this._triggerListeners('focusedMonth');
  }

  _getStartEnd() {
    this.startOfMonth = this.focusedMonth.clone().startOf('month').startOf('week');
    this.endOfMonth = this.focusedMonth.clone().endOf('month').endOf('week');

    this._getDays();
    this._triggerListeners('startOfMonth');
  }

  _onSelect(day) {
    this.onChange(day.toISOString(true));
    this.selected = day;

    this._triggerListeners('selectedDate');
  }

  _subscribe(fn, ...keys) {
    fn();

    keys.forEach((key) => {
      this.listeners[key] = this.listeners[key] || new Set([]);
      this.listeners[key].add(fn);
    });
  }

  _getDays() {
    const end = this.startOfMonth.clone().add(1, 'months').endOf('month');
    const daysRange = moment.range(this.startOfMonth, end).by('days');
    this.days = Array.from(daysRange);
  }

  _triggerListeners(key) {
    this.listeners[key] && this.listeners[key].forEach((fn) => fn());
  }
}

export default DatePicker;
