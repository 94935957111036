// import { Auth } from 'services';
import { render } from 'lit-html';

// const userId = Auth.getUserId();

// export function getUnread(messages) {
//   const unread = [];
//
//   /** Get unread messages */
//   messages.forEach(message => {
//     const statuses = message.statuses.filter(status =>
//       status.participantId === userId
//       && status.status === 'unread'
//       && message.sender.id !== userId
//     );
//
//     statuses.length && unread.push(message.id);
//   });
//
//   return Promise.resolve(unread);
// }

const close = () => {
  const popup = document.getElementById('popup');
  popup && popup.parentElement.removeChild(popup);
  window.removeEventListener('click', handleMissClick, true);
};

const handleMissClick = (e) => {
  const popup = document.getElementById('popup');
  const isMissClick = popup && !popup.contains(e.target) && !popup.parentElement.contains(e.target);

  if (isMissClick) {
    close();
  }
};

export function openPopup(popupTmp, styles, containerId) {
  let popup = document.getElementById('popup');

  if (popup) {
    return close();
  }

  popup = document.createElement('div');
  Object.assign(popup.style, styles);
  popup.setAttribute('id', 'popup');

  const popupCmp = popupTmp(close);
  render(popupCmp, popup);

  window.addEventListener('click', handleMissClick, true);
  const popupContainer = document.getElementById(containerId);
  popupContainer.style.position = 'relative';
  popupContainer.appendChild(popup);
}
