import { directive, nothing } from 'lit-html';

export const showIf = directive((condition, component) => part => {
  if (condition) {
    part.setValue(component);
  } else {
    part.setValue(nothing);
  }

  part.commit();
});
