import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import greeting from 'utils/greeting';
import DashController from './dash.controller';
import styles from './dash.module.scss';

class DashboardTemplate {
  public controller: DashController;

  @Observe('cards')
  get grid() {
    const { cards } = this.controller;

    return cards ? html` <div class=${styles.grid}>${cards.map((card) => card.module)}</div> ` : nothing;
  }

  public header(profile) {
    const { firstName } = profile.general;

    return profile && html` <h1>${greeting()}, ${firstName}</h1> `;
  }

  @Observe('profile')
  get template() {
    const { profile } = this.controller;

    return html` <div class=${styles.wrapper}>${profile && this.header(profile)} ${this.grid}</div> `;
  }
}

export default DashboardTemplate;
