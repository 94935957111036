import { html } from 'lit-html';
import Template from 'services/templator/template';
import { loader } from 'components/lit';

class ActionsTemplate extends Template {
  get loader() {
    return html`
      <div class="${this.styles.loader}">
        ${loader}
      </div>
    `;
  }

  get template() {
    return html`
      ${this.directives.renderLoader()}
      ${this.directives.renderHeader()}
      ${this.directives.renderScreen()}
    `;
  }
}

export default ActionsTemplate;
