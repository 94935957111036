import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import SignalR from 'services/signalR.service';


interface MuteOptions {
  mute: boolean;
  mute_minutes: number;
  mute_start: string;
}
interface IStatus {
  do_not_disturb: boolean;
  present: boolean;
  notification_mute: MuteOptions;
}

type Callback = (arg: IStatus) => any;

export class Statuses {
  private handler: any = {
    get: (target, key) => target[key],
    set: (target, key, value) => {
      target[key] = value;
      const subscribers = this.statusSubscribers.get(key);
      subscribers && subscribers.forEach((cb) => cb(value));

      return true;
    },
  };
  private statuses: any =  new Proxy({}, this.handler);
  private components: Map<string, string[]> = new Map();
  private statusSubscribers: Map<string, Set<Callback>> = new Map();

  public statusUpdatesHandler(status: string) {
    const { user_id, present, do_not_disturb, notification_mute } = JSON.parse(status);
    this.statuses[user_id] =  { present, do_not_disturb, notification_mute };
    const subscribers = this.statusSubscribers.get(user_id);
    subscribers && subscribers.forEach((cb) => cb(this.statuses.get(user_id)));
  }

  public init() {
    SignalR.subscribe('UserPresenceStatus', this.statusUpdatesHandler.bind(this));
  }

  public subscribeStatus(id: string, callback: Callback) {
    callback(this.statuses[id]);

    let idSubscribers = this.statusSubscribers.get(id);

    if (!idSubscribers) {
      idSubscribers = new Set();
      this.statusSubscribers.set(id, idSubscribers);
    }

    idSubscribers.add(callback);
  }

  private get uniqueIds(): string[] {
    return uniq(Array.from(this.components.values()).flat());
  }


  public async subscribe(componentName: string, ids: string[]) {
    if (ids.length && difference(ids, this.uniqueIds).length) {
      this.components.set(componentName, ids);

      const newStatuses = await SignalR.invoke('SubscribeUserStatus', this.uniqueIds);
      newStatuses.forEach((status) => {
        const { user_id, present, do_not_disturb, notification_mute } = status;
        this.statuses[user_id] = { present, do_not_disturb, notification_mute };
      });
    }
    this.components.set(componentName, ids);
  }

  public async unsubscribe(componentName: string, ids: string[]) {
    this.components.delete(componentName);
    const deleteIds = difference(ids, this.uniqueIds);
    if (deleteIds.length) {
      deleteIds.forEach((id) => delete this.statuses[id]);
      await SignalR.invoke('UnsubscribeStatus', deleteIds);
    }
  }
}
