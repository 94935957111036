import store from '@werkin/store';
import apollo from 'api';
import { isAfter, parseISO } from 'date-fns';
import { observable } from 'decorators';
import { IAlertItem } from 'interfaces';
import HeaderAlertsModule from 'modules/HeaderAlerts/header-alerts.module';
import AlertsQuery from '../../graphql/queries/alerts.graphql';

class HeaderAlertsController {
  public module: HeaderAlertsModule;

  @observable([], true) public alerts: IAlertItem[];
  @observable(false, true) public active: boolean;
  @observable(0, true) public unreadCount: number;

  public OnInit() {
    this.watchAlerts();
    this.modalListeners();
  }

  private watchAlerts() {
    const observe = apollo.watchQuery({
      query: AlertsQuery,
    }).subscribe({
      next: ({ data: { alerts } }) => {
        const { items, lastViewed } = alerts;
        const unread = items.filter(({ read }) => !read).length;
        const unviewed = items.filter(({ createdAt }) => isAfter(parseISO(createdAt), parseISO(lastViewed))).length;

        this.unreadCount = lastViewed ? unviewed : unread;
        window.ExperimentalBadge && window.ExperimentalBadge.set(this.unreadCount);
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  private modalListeners() {
    const unsubscribeFromStore = store.subscribe('isAlertsModal', (isAlertsModal) => {
      this.active = store.get('isAlertsModal');

      if (isAlertsModal) {
        window.addEventListener('click', this.handleOutsideClick);

        this.module.subs.add(() => window.removeEventListener('click', this.handleOutsideClick));
      }
    });

    this.module.subs.add(unsubscribeFromStore);
  }

  private handleOutsideClick = (evt) => {
    const path = (evt.composedPath && evt.composedPath()) || evt.path;

    if (!path.includes(this.module.root)) {
      store.set('isAlertsModal', false);
      this.active = false;

      window.removeEventListener('click', this.handleOutsideClick);
    }
  }

  public handleAlertsModal() {
    store.set('isAlertsModal', !this.active);
  }
}

export default HeaderAlertsController;
