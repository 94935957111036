import messages from 'modules/Chat/Messages/messages.module';
import suggestions from 'modules/Chat/Suggestions/suggestions.module';
import Router from 'services/router';
import { RouterModule } from 'services/router/router.m';

@RouterModule()
class ChatModule {
  public router: Router;

  public mount() {
    this.router.controller.addTSModule(messages);
    this.router.controller.addTSModule(suggestions);
  }
}

export default ChatModule;
