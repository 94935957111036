import store from '@werkin/store';
import apollo from 'api';
import Preloader from 'modules/Preloader';
import { SignalR, StatusesService } from 'services';
import { REST } from 'services';
import ActionsService from 'services/data/actions.service';
import ActivitiesService from 'services/data/activities.service';
import AlertsService from 'services/data/alerts.service';
import BaseService from 'services/data/base.service';
import ChatsService from 'services/data/chats.service';
import CollectivesService from 'services/data/collectives.service';
import IndividualsService from 'services/data/individuals.service';
import PollService from 'services/data/poll.service';
import ProfileService from 'services/data/profile.service';
import { Router } from 'services/router.service';
import SharedGoalsService from 'services/shared-goals.service';
import setActiveCollective from '../../graphql/mutations/setActiveCollective.graphql';
import DashboardService from './dashboard.service';
import MatchService from './match.service';

class DataService extends BaseService {
  private destroyers: Set<() => void> = new Set();
  private resolve: () => void;
  public awaitLoading = new Promise((resolve) => this.resolve = resolve);

  constructor() {
    super();
    this.collectiveSwitched = this.collectiveSwitched.bind(this);
  }

  public async run() {
    await this.requestData();
    await SignalR.init();
  }

  public async changeCollective(collectiveId) {
    try {
      await apollo.mutate({ mutation: setActiveCollective, variables: { command: { collectiveId } } });
      await this.collectiveSwitched();
    } catch (e) {
      console.error(`DATA SERVICE (changeCollective): ${e.message}`);
    }

  }

  public async collectiveSwitched() {
    try {
      store.unset('landingRoute');
      store.set('generalSearch', '');

      const { data: { route } } = await REST.getLandingRoute();

      store.set('landingRoute', route);
      store.set('isAlertsModal', false);

      await this.requestData();

      Router.deleteHash('ProfileEditingModal', 'CreateDirectModal');
      Router.deleteState('left');

      if (Router.display === 'desktop') {
        Router.go({ path: route });
      } else {
        Router.go({ name: 'chat' });
      }

    } catch (e) {
      console.error(`DATA SERVICE (collectiveSwitched): ${e.message}`);
    }
  }

  private async requestData() {
    try {
      Preloader.mount();

      this.setLoadingState('Welcome');
      this.runDestroyers(); // Destroyers should be run before requesting new data;

      await this.addSubscriptions();

      await Promise.all([
        ProfileService.load(),
        IndividualsService.load(),
        CollectivesService.load(),
      ]);

      await Promise.all([
        ActionsService.load(),
        AlertsService.load(),
        ActivitiesService.load(),
        ChatsService.load(),
      ]);

      await StatusesService.init();

      this.resolve();
      this.awaitLoading = Promise.resolve();

      SharedGoalsService.collectMyShares();
      PollService.schedulePoll();

      Preloader.unmount();
    } catch (e) {
      Preloader.unmount();
      console.error(`DATA SERVICE (requestData): ${e.message}`);
    }
  }

  private updateIndividualData(strIndividual) {
    const { id } = JSON.parse(strIndividual);
    IndividualsService.getIndividual(id);
  }

  private async addSubscriptions() {
    const unsubscribeAll = await Promise.all([
      SignalR.subscribe('ChatMessageCreated', ChatsService.handleCreateMessage),
      SignalR.subscribe('ChatMessageUpdated', ChatsService.setMessages),
      SignalR.subscribe('ChatMessageDeleted', ChatsService.handleDeleteMessage),
      SignalR.subscribe('ChatMessagesUpdated', ChatsService.handleChatsListUpdated),
      SignalR.subscribe('ChatListUpdated', ChatsService.handleChatsListUpdated),
      SignalR.subscribe('ChatDeleted', ChatsService.handleChatDeleted),
      SignalR.subscribe('GoalsShared', SharedGoalsService.getUserShares),
      SignalR.subscribe('PollCreated', PollService.pollCreationHandler),
      SignalR.subscribe('AlertCreated', AlertsService.setAlert),
      SignalR.subscribe('AlertCenterUpdated', AlertsService.getAlerts),
      SignalR.subscribe('ActionCreated', ActionsService.handleActionCreated),
      SignalR.subscribe('ActionUpdated', ActionsService.handleActionUpdated),
      SignalR.subscribe('ActionDeleted', ActionsService.handleActionDeleted),
      SignalR.subscribe('ActivityFeedUpdated', ActivitiesService.activityFeedUpdatedHandler),
      SignalR.subscribe('IndividualUpdated', this.updateIndividualData),
      SignalR.subscribe('ProfileUpdated', ProfileService.updateProfile),
      SignalR.subscribe('CollectiveSwitched', this.collectiveSwitched),
      SignalR.subscribe('MatchCreated', MatchService.getMatches),
      SignalR.subscribe('OutstandingTasksUpdated', DashboardService.getTasks),
    ]);

    unsubscribeAll.map((fn) => this.destroyers.add(fn));
  }

  private runDestroyers() {
    this.destroyers.forEach((fn) => fn());
    this.destroyers = new Set();
  }
}

export default new DataService();
