// eslint-disable-next-line
import '-!svg-sprite-loader!assets/icons.svg';

import PropTypes from 'prop-types';
import React from 'react';

/**
 * @file Holds common <DatePickerComponent> component
 * @since 0.3.0
 */
const Icon = ({ className, name, fill }) => {
  const classNames = [
    'icon',
    `icon-${name}`,
    className,
  ].join(' ');

  return (
    <svg className={classNames} fill={fill}>
      <use xlinkHref={`#icons_${name}`} />
    </svg>
  );
};

Icon.propTypes = {
  /**
   * Icon's name
   * */
  name: PropTypes.string.isRequired,
  /**
   * Additional icon's classes
   * */
  className: PropTypes.string,
  /**
   * Additional icon's color
   * */
  fill: PropTypes.string,
};

export default Icon;
