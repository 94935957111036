import { observable } from 'decorators';
import { Auth } from 'services';
import { Router } from 'services/router.service';
import { cutSpaces } from 'utils/common';
import ValidatorHelper from 'utils/validation-helpers';

class ResetPasswordController {
  public vHelper: ValidatorHelper;

  @observable() public newPassword: string;
  @observable() public number: string | null;
  @observable() public special: string | null;
  @observable() public length: string | null;
  @observable() public uppercase: string | null;
  @observable() public lowercase: string | null;
  @observable() public error: string | null;
  @observable() public loading: boolean;
  @observable() public valid: boolean;

  public OnInit() {
    if (!this.email) {
      return Router.go({ name: 'forgot-password' });
    }

    this.setValidators();
  }

  public get email() {
    return Router.state.email || Router.query.email;
  }

  public onChange(evt) {
    this.newPassword = evt.target.newPassword.value;
    this.valid = Array.from(evt.target.controls).every(([, { valid }]) => valid);

    evt.detail.current.getAttribute('name') === 'newPassword' && this.isValidPassword();
  }

  public isValidPassword() {
    this.vHelper.validate(this.newPassword);
    this.setValidators();
  }

  public link(e, route?) {
    e.preventDefault();

    route && Router.go(route);
  }

  public async onConfirm(evt) {
    evt.preventDefault();

    try {
      this.loading = true;
      this.valid = false;

      await Auth.resetPassword(
        cutSpaces(evt.target.code.value),
        cutSpaces(evt.target.newPassword.value),
        this.email
      );

      Router.go({ name: 'login' });
    } catch (e) {
      this.error = e.message;
      this.valid = false;
    } finally {
      this.loading = false;
    }
  }

  private setValidators() {
    Object.keys(this.vHelper.validation).forEach((key: string) => {
      this[key] = this.vHelper.validation[key];
    });
  }
}

export default ResetPasswordController;
