import { html } from 'lit-html';
import { Icons } from 'components/lit';
import Component from 'services/templator/component';
import styles from './password-validation.module.scss';

export class PasswordValidation extends Component {
  validationField(validation, text) {
    const color = validation === 'valid' ? '#4cb49c' : '#b9b9b9';
    const icon = Icons.circleCheckIcon(color);

    return html`
      <span class="${styles.val_field}">
        ${ validation === 'invalid' ? Icons.error : icon }
        ${text}
       </span>
    `;
  }

  get template() {
    return html`
      <div class="${styles.validation}">
        <div class="${styles.section}">
          ${this.validationField(this.options.lowercase, '1 lowercase character')}
          ${this.validationField(this.options.number, '1 number')}
          ${this.validationField(this.options.length, '8 character minimum')}
        </div>

        <div class="${styles.section}">
          ${this.validationField(this.options.uppercase, '1 uppercase character')}
          ${this.validationField(this.options.special, '1 special character')}
        </div>
      </div>
    `;
  }
}
