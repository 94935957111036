import Controller from 'services/templator/controller';
import FullscreenCollectivesModal from 'components/lit/modals/fullscreen-collectives-modal';

class CollectiveController extends Controller {
  handleClick(evt) {
    evt.preventDefault();

    if (this.module.router.display === 'mobile') {
      new FullscreenCollectivesModal().open();
    } else {
      this.module.router.go({ name: 'chat' });
    }
  }
}

export default CollectiveController;
