import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import DashMatchController from './dash-match.controller';
import DashMatchView from './dash-match.view';

@RouterModule({
  name: 'w-dash-match-module',
  controller: DashMatchController,
  view: DashMatchView,
})
class DashMatchModule {
  public subs: Set<() => void> = new Set([]);
  public view: DashMatchView;

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default DashMatchModule;
