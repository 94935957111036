import { html } from 'lit-html';
import Template from 'services/templator/template';

class CalendarTemplate extends Template {
  get template() {
    return html`
      <div class="${this.styles.wrapper}">
        <h1>${this.directives.renderSelectedDate()}</h1>
        ${this.directives.renderCalendar()}
      </div>
    `;
  }
}

export default CalendarTemplate;
