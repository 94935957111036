import { RouterModule } from 'services/router/router.module';
import SubNav from './subnav.view';

import styles from './subnav.module.scss';
import SubNavController from 'modules/SubNav/subnav.controller';
import SubNavDirectives from 'modules/SubNav/subnav.directives';

class SubNavModule extends RouterModule {
  mount() {
    const subnav = new SubNav(this.module);

    subnav.controller = SubNavController;
    subnav.directives = SubNavDirectives;
    subnav.styles = styles;

    this.manageLitMount(subnav.template, 'sub_nav');
  }
}

export default SubNavModule;
