import React from 'react';
import styles from './Wrapper.module.scss';
import { Router } from 'services/router.service';
import Text from 'atoms/Text';
import Icon from 'atoms/Icon';
import FAQ from 'modules/Help/sections/FAQ';
import TechnicalRequirements from 'modules/Help/sections/TechnicalRequirements';
import Support from 'modules/Help/sections/Support';
import { reportProblem, suggestFeature } from 'modules/Help/Controller';
import { NotificationManager } from 'services';

const links = [
  { text: 'Frequently asked questions' },
  { text: 'Is my browser supported?' },
  { text: 'Suggest an improvement' },
  { text: 'Report a problem' },
];

const MobileWrapper = () => {
  const [index, setIndex] = React.useState(null);

  const container = React.useRef();

  const suggestFeatureSubmit = async (text) => {
    await suggestFeature(text);
    NotificationManager.showNotification({ text: 'Thanks for telling us.', type: 'success' });
    setIndex(null);
  };

  const reportProblemSubmit = async (text) => {
    await reportProblem(text);
    NotificationManager.showNotification({ text: 'Thanks for telling us.', type: 'success' });
    setIndex(null);
  };

  const Cards = () => (
    <div className={styles.block}>
      {links.map(({ text }, i) => (
        <div className={styles.card} onClick={() => setIndex(i)} key={i}>
          <Text tag='h6' color='deep-teal'>{text}</Text>
          <Icon name='arrow-right-long' />
        </div>
      ))}

      <Text color='dark-grey'>Still need help?</Text>
      <a href='mailto:support@getwerkin.com' className='mt-5'>support@getwerkin.com</a>

      <div className={styles.links}>
        <Text color='dark-grey' className='mb-5'>App version {require('../../../package.json').version}</Text>
        <a target='_blank' rel='noopener noreferrer' href='https://getwerkin.com/terms-of-use'>Terms of Use</a>
      </div>
    </div>
  );

  const renderContent = () => {
    switch (index) {
      case 0:
        return <FAQ parent={container} />;
      case 1:
        return <TechnicalRequirements />;
      case 2:
        return <Support type='suggest' onSubmit={suggestFeatureSubmit} />;
      case 3:
        return <Support type='report' onSubmit={reportProblemSubmit} />;
      default:
        return <Cards />;
    }
  };

  const goToChat = () => Router.go({ name: 'chat' });

  return (
    <div className={styles.container} ref={container}>
      <div className={styles.header}>
        <Text tag='h3' color='deep-teal'>How can we help you?</Text>
        <div className={styles.oval_1} />
        <div className={styles.oval_2} />
      </div>

      <div className={`${styles.back} mt-20 ml-15`} onClick={() => index ? setIndex(null) : goToChat()}>
        <Icon name='arrow-left' />
        <Text color='deep-teal' className='ml-10'>Back</Text>
      </div>
      {renderContent()}
    </div>
  );
};

export default MobileWrapper;
