import { Observe } from 'decorators';
import { ITracker, ITrackerAction } from 'interfaces/dash/tracker.interface';
import { directive, html, nothing } from 'lit-html';
import cardStyles from 'styles/card.module.scss';
import truncateName from 'utils/truncate-name';
import { bubbleContent } from './config';
import DashTrackerController from './dash-tracker.controller';
import styles from './dash-tracker.module.scss';

class DashTrackerTemplate {
  public controller: DashTrackerController;

  get empty() {
    return html`
      <div class=${styles.empty}>
        We're preparing your match tracker.
      </div>
    `;
  }

  get noActions() {
    return html`
      <div class=${styles.empty}>
        We'll track your interactions with your match here ✨
      </div>
    `;
  }

  @Observe('matches')
  get switcher() {
    const {matches, handleActiveMatch } = this.controller;

    return matches.length > 1 ? html`
      <select
        class=${cardStyles['header-select']}
        @change=${handleActiveMatch}
      >
        ${matches.map(({ individual }, index) => html`
          <option value=${index}>${truncateName(individual.name)}</option>
        `)}
      </select>
    ` : nothing;
  }

  public tracker = directive((tracker: ITracker[]) => (part) => {
    const { scrollListeners } = this.controller;

    if (tracker.some(({ actions }) => actions.length)) {
      part.setValue(html`
      <div class=${styles.tracker} data-id="tracker">
        ${tracker.map((period) => this.period(period))}
      </div>
    `);
    } else {
      part.setValue(this.noActions);
    }

    part.commit();
    scrollListeners();
  });

  public period({ label, actions }) {
    return html`
      <div class=${styles.period}>
        ${this.actions(actions)}
        ${this.label(label)}
      </div>
    `;
  }

  public label(label: string) {
    return html`
      <div class=${styles.promise}>
        <p>${label}</p>
        <span class=${styles.dot}></span>
      </div>
    `;
  }

  public actions(actions: ITrackerAction[]) {
    return html`
      <div class=${styles.actions}>
        ${actions.map(({ type, occurrences }) => this.bubble(bubbleContent(type, occurrences)))}
      </div>
    `;
  }

  public bubble({ label, icon, points }) {
    return html`
      <div class=${styles.bubble}>
        ${points ? html`
          <div class=${styles.points}>${points}pts</div>
        ` : nothing}
        ${label}
        ${icon ? html`
          <div class=${styles.icon}>${icon}</div>
        ` : nothing}
      </div>
    `;
  }

  @Observe('tracker')
  get template() {
    const { tracker } = this.controller;

    return html`
      <div class=${cardStyles.card}>
        <div class=${cardStyles.header}>
          <span class=${cardStyles.label}>Your match tracker</span>
          ${this.switcher}
        </div>
        <div class=${styles.wrapper} data-id="timeline-wrapper">
          ${tracker.length ? this.tracker(tracker) : this.empty}
        </div>
      </div>
    `;
  }
}

export default DashTrackerTemplate;
