import { observable } from 'decorators';
import { manageMount, manageUnmount } from 'lib/react-component-manager';
import { styleMap } from 'lit-html/directives/style-map';
import inRange from 'lodash/inRange';
import LaunchModule from 'modules/Launch/launch.module';
import TechnicalRequirements from 'modules/Launch/TechnicalRequirements';
import { REST } from 'services';
import { Router } from 'services/router.service';
import { DynamicListeners, OnInit } from 'services/router/router.interfaces';

class LaunchController implements OnInit {
  public module: LaunchModule;

  @observable()
  public isRequirementsOpened;

  @observable()
  public activeIndex: number;

  @observable(0)
  public top: number;

  @observable(false)
  public isMenuActive: boolean;

  @observable({})
  public launchData: any;

  public scrollListener = (evt) => {
    this.updateActiveBlock(evt);
    this.top = evt.target.scrollTop;
  }

  public updateActiveBlock(evt) {
    const elements = this.launchData.blocks
      .map(({value}) => document.getElementById(value))
      .filter((v) => v)
      .map(({offsetTop, offsetHeight, id}) => ({ offsetTop, offsetHeight, id}));

    const headerHeight = document.getElementById('launch-header').getBoundingClientRect().height;
    elements.forEach(({ offsetTop, offsetHeight, id}) => {
    if (inRange(evt.target.scrollTop  + headerHeight, offsetTop, offsetTop + offsetHeight)) {
      this.activeIndex = this.launchData.blocks.findIndex(({ value }) => value === id);
    }
    });
  }

  public onSelect(i) {
    this.scrollTo(this.launchData.blocks[i].value);
  }

  public async OnInit() {
    setImmediate(() => document.getElementById('App').scrollTo(0, 0));

    const unsubscribe = Router.listenDynamic(DynamicListeners.hash, () => {
      if (Router.hash.RequirementsModal) {
        this.isRequirementsOpened = Router.hash.RequirementsModal;

        manageMount(TechnicalRequirements, 'launch_modal');
      } else {
        manageUnmount('launch_modal');
        this.isRequirementsOpened = Router.hash.RequirementsModal;
      }
    });

    const [{ data }, clientData] =
      await Promise.all([REST.getSystemData(), REST.getClientData(Router.params.client)]);
    const keyDates = data.onboarding.keyDates;
    Object.keys(clientData.keyDates).forEach((k) => clientData.keyDates[k].date = new Date(keyDates[k]));
    this.launchData = clientData;
    const app = document.body;
    app.style.setProperty('--nav-color', this.launchData.header.navColors.default);
    app.style.setProperty('--nav-active-color', this.launchData.header.navColors.active);
    app.style.setProperty('--timer-bg', this.launchData.timer.background);
    app.style.setProperty('--timer-title-color', this.launchData.timer.colors.title);
    app.style.setProperty('--dates-bg', this.launchData.dates.background);
    app.style.setProperty('--footer-bg', this.launchData.footer.background);
    app.addEventListener('scroll', this.scrollListener);

    this.module.subs.add(() => { app.removeEventListener('scroll', this.scrollListener); });
    this.module.subs.add(unsubscribe);
  }

  get logo() {
    return this.launchData.header.logo;
  }

  get headerStyle() {
    return styleMap({
      backgroundColor: this.launchData.header.bgColor(this.isRequirementsOpened ? 1 : this.top / 300),
      boxShadow: `-2px 1px 2px 0px rgba(0, 0, 0, ${this.isRequirementsOpened ? 1 : this.top / 300})`,
    });
  }

  public scrollTo(selector: string) {
    Router.deleteHash('RequirementsModal');

    const wrapper = document.getElementById('App');
    const headerHeight = document.getElementById('launch-header').getBoundingClientRect().height;
    const top = document.getElementById(selector).offsetTop;
    wrapper.scrollTo({
      behavior: 'smooth',
      top: top - headerHeight,
    });
  }

  public toggleMobileMenu() {
    this.isMenuActive = !this.isMenuActive;
  }

  public openMobileLink(value) {
    Router.deleteHash('RequirementsModal');
    this.toggleMobileMenu();
    this.scrollTo(value);
  }

  public onOpenRequirements() {
    this.isRequirementsOpened = true;
    Router.go({ hash: { RequirementsModal: true } });
    Router.display === 'mobile' && this.toggleMobileMenu();
  }

}

export default LaunchController;
