import { html } from 'lit-html';
import Fragment from 'services/templator/fragment';

class DateTimePicker extends Fragment {
  get switcher() {
    const { dateTimeTab } = this.controller.state;

    return html`
      <div class="${this.styles.switcher}">
        <div
          class="${this.styles.option}"
          @click=${() => { this.controller.onTabChange('Date'); }}
        >
          Date
        </div>
        <div
          class="${this.styles.option}"
          @click=${() => { this.controller.onTabChange('Time'); }}
        >
          Time
        </div>

        <div class="${this.styles.option} ${this.styles.holder} ${this.styles[dateTimeTab.toLowerCase()]}">${dateTimeTab}</div>
      </div>

    `;
  }

  get template() {
    return html`
      <div>
        ${this.directives.renderSwitcher()}
        ${this.directives.renderView()}
      </div>
    `;
  }
}

export default DateTimePicker;
