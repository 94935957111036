import { observable } from 'decorators';
import { Auth, NotificationManager, REST } from 'services';
import { Router } from 'services/router.service';
import { cutSpaces } from 'utils/common';
import ValidatorHelper from 'utils/validation-helpers';
import styles from './temporary.module.scss';

const ERRORS = {
  400: `A new temporary password could not be created (error code 400).
       Please check your email address and try again. Contact
       <a class="${styles.error_link}" href = "mailto: support@getwerkin.com">support@getwerkin.com</a>
       if you need help and include the details of this message.`,
  500: `A new temporary password could not be created (error code 500). Please contact
       <a class="${styles.error_link}" href = "mailto: support@getwerkin.com">support@getwerkin.com</a>
       for help and include the details of this message.`,
};

class TemporaryController {
  public vHelper: ValidatorHelper;

  @observable() public newPassword: string;
  @observable() public number: string | null;
  @observable() public special: string | null;
  @observable() public length: string | null;
  @observable() public uppercase: string | null;
  @observable() public lowercase: string | null;
  @observable() public error: string | null;
  @observable() public loading: boolean;
  @observable() public valid: boolean;

  public OnInit() {
    this.setValidators();
  }

  public onChange(evt) {
    this.newPassword = evt.target.newPassword.value;
    this.valid = Array.from(evt.target.controls).every(([, { valid }]) => valid);

    evt.detail.current.getAttribute('name') === 'newPassword' && this.isValidPassword();
  }

  public resendTemporaryCode(evt) {
    evt.preventDefault();

    REST.resendTemporaryPassword(Router.state.email).then(() => {
      NotificationManager.showNotification({
        text: 'Success! Please check your email for your new temporary password.',
        type: 'success',
        duration: 4000,
      });
    }).catch((e) => {
      NotificationManager.showNotification({ text: ERRORS[e.status] || e.message, type: 'error', duration: 8000, });
    });
  }

  public isValidPassword() {
    this.vHelper.validate(this.newPassword);
    this.setValidators();
  }

  public link(e, route?) {
    e.preventDefault();

    route && Router.go(route);
  }

  public async onConfirm(evt) {
    evt.preventDefault();
    try {
      this.loading = true;
      this.valid = false;

      await Auth.login(
        Router.state.email,
        cutSpaces(evt.target.temporaryPassword.value),
        cutSpaces(evt.target.newPassword.value)
      );

      const { data: { route } } = await REST.getLandingRoute();

      Router.go({ path: route });
    } catch (e) {
      let error;

      if (e.name === 'NotAuthorizedException') {
        error = 'Temporary password is not correct. Please check and try again.';
      }

      this.valid = false;
      this.error = error || e.message;
    } finally {
      this.loading = false;
    }
  }

  private setValidators() {
    Object.keys(this.vHelper.validation).forEach((key: string) => {
      this[key] = this.vHelper.validation[key];
    });
  }
}

export default TemporaryController;
