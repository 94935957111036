import store from '@werkin/store';
import Controller from 'services/templator/controller';

class HeaderController extends Controller {
  get store() {
    return store;
  }

  get alerts() {
    return this.module.router.controller.alerts;
  }

  handleNavigate(route, params = {}) {
    if (this.store.get('generalSearch')) {
      return;
    }

    if (route.includes(':id')) {
      if (this.module.router.comparePath(route) && this.module.router.params.id === this.userId) {
        return;
      }
    } else if (this.module.router.comparePath(route)) {
      return;
    }

    this.module.router.go({
      name: route,
      params,
    });
  }

  wrapHandler(handler) {
    return ({
      handleEvent: handler,
      capture: true,
    });
  }
}

export default HeaderController;
