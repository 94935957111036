import apollo from 'api';
import Compressor from 'compressorjs';
import { observable } from 'decorators';
import ProfileHeaderModule from 'modules/ProfileHeader/profile-header.module';
import { Auth } from 'services';
import { NotificationManager } from 'services';
import leaderboardService from 'services/data/leaderboard.service';
import profileService from 'services/data/profile.service';
import REST from 'services/rest.service';
import { Router } from 'services/router.service';
import { errorHandler } from 'utils/error-helpers';
import individualGeneralProfileMutation from '../../graphql/mutations/setIndividualGeneralProfile.graphql';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';

class ProfileHeaderController {
  public module: ProfileHeaderModule;

  @observable({}, true) public general: any;
  @observable('0 PTS', true) public points: any;

  public OnInit() {
    const observe = apollo.watchQuery({
      query: ProfileQuery,
      variables: {
        id: Auth.getUserId(),
      },
    }).subscribe({
      next: ({ data: { profile } }) => {
        this.general = profile.general;
        this.points = `${profile.points} PTS`;
      },
      error: (e) => console.error(e),
    });

    const unsubscribeFromCreditsUpdates = leaderboardService
      .subscribeLeaderboardUpdated(() => profileService.getProfile());

    this.module.subs.add(() => observe.unsubscribe());
    this.module.subs.add(unsubscribeFromCreditsUpdates);
  }

  public async removeAvatar() {
    await apollo.mutate({
      mutation: individualGeneralProfileMutation,
      variables: {
        command: {
          ...this.general,
          location: {
            ...this.general.location,
            city: this.general.location.city.id,
            country: this.general.location.country.id,
          },
          profileImageUrl: null,
        },
      },
      refetchQueries: [{
        query: ProfileQuery,
        variables: {
          id: Auth.getUserId(),
        },
      }],
    }).then(( { data: { setIndividualGeneralProfile } }) => {
      const { errors } = setIndividualGeneralProfile;
      const hasErrors = errors && errors.length;

      const states = {
        error: 'We encountered a problem removing your avatar.',
        success: 'Your avatar has been removed!',
      };

      NotificationManager.showNotification({
        text: hasErrors ? states.error : states.success,
        type: hasErrors ? 'error' : 'success',
      });

      return hasErrors && errorHandler(new Error(states.error), true);
    });
  }

  public async handleAvatarUpload(evt) {
    const file = evt.target.files[0];
    if (!file) {
      return;
    }
    const formData = new FormData();
    const compressedImage = await new Promise((res, rej) => {
      new Compressor(file, {
        quality: 1,
        maxHeight: 100,
        checkOrientation: false,
        success(result) { res(result); },
        error(err) { rej(err.message); },
      });
    }) as any;

    formData.append('files', compressedImage);

    const [profileImageUrl] = await REST.uploadFile(formData);

    const mutationData = {
      ...this.general,
      location: {
        ...this.general.location,
        city: this.general.location.city.id,
        country: this.general.location.country.id,
      },
      profileImageUrl,
    };

    await apollo.mutate({
      mutation: individualGeneralProfileMutation,
      variables: {
        command:
        mutationData,
      },
      refetchQueries: [{
        query: ProfileQuery,
        variables: {
          id: Auth.getUserId(),
        },
      }],
    }).then(({ data: { setIndividualGeneralProfile } }) =>  {
      const { errors } = setIndividualGeneralProfile;
      const hasErrors = errors && errors.length;

      const states = {
        error: 'We encountered a problem uploading your avatar.',
        success: 'Your avatar has been uploaded!',
      };

      NotificationManager.showNotification({
        text: hasErrors ? states.error : states.success,
        type: hasErrors ? 'error' : 'success',
      });

      return hasErrors && errorHandler(new Error(states.error), true);
    });
  }

  public onOpenPublicProfile() {
    Router.go({ hash: { IndividualModal: true, userId: Auth.getUserId() } });
  }

  public onPointsRoute() {
    Router.go({ name: 'credits', params: { userId: Auth.getUserId(), tab: 'leaderboards' } });
  }
}

export default ProfileHeaderController;
