import { directive } from 'lit-html';
import Header from 'modules/ActionFlow/fragments/header.fragment';
import ScheduleOptionsScreen from 'modules/ActionFlow/fragments/options.fragment';
import MeetTimeScreen from 'modules/ActionFlow/fragments/meet-time.fragment';
import TimezoneFragment from 'modules/ActionFlow/fragments/timezone.fragment';
import TimeZonesListScreen from 'modules/ActionFlow/fragments/timezones-list.fragment';
import ParticipantsTimezoneFragment from 'modules/ActionFlow/fragments/participants-timezone.fragment';
import DetailsScreen from 'modules/ActionFlow/fragments/details.fragment';
import GeneralDirectives from 'directives/directives';
import DateTimePickerFragment from './fragments/datetime.fragment';
import DatePicker from 'atoms/lit/datepicker/datepicker.view';
import TimePicker from 'atoms/lit/timepicker/timepicker.view';

class ActionsDirective extends GeneralDirectives {
  onInit() {
    this.cleaners = new Set([]);
    this.header = new Header(this.view);

    this.scheduling_options = new ScheduleOptionsScreen(this.view);
    this.meet_time = new MeetTimeScreen(this.view);
    this.select_timezone = new TimeZonesListScreen(this.view);
    this.details = new DetailsScreen(this.view);

    this.participant_timezones = new ParticipantsTimezoneFragment(this.view);
    this.dateTimePicker = new DateTimePickerFragment(this.view);
    this.timezone = new TimezoneFragment(this.view);

    this.datePicker = new DatePicker({
      selected: this.data.date,
      disablePreviousDates: true,
      onChange: this.controller.onDateChange,
    });

    this.timePicker = new TimePicker({
      duration: this.data.duration,
      onChange: this.controller.onDateTimeChange,
      onDurationChange: this.controller.onChangeDuration,
      onMinLimitReached: this.controller.onMinLimitReached,
    });
  }

  get renderLoader() {
    return directive(() => part => {
      this.controller.subscribeState(state => {
        part.setValue(state.loading ? this.view.loader : '');
        part.commit();
      }, 'loading');
    });
  }

  get renderHeader() {
    return directive(() => part => {
      this.controller.subscribeState(() => {
        part.setValue(this.header.template);
        part.commit();
      }, 'selectedScreen', 'selectedOption', 'allowNext');
    });
  }

  get renderScreen() {
    return directive(() => part => {
      this.controller.subscribeState(state => {
        if (state.selectedScreen !== 'scheduling_options') { return; }

        part.setValue(this[state.selectedScreen].template);
        part.commit();
      }, 'selectedOption');

      this.controller.subscribeState(state => {
        this.cleaners.forEach(fn => fn());
        this.cleaners = new Set([]);
        this.timePicker.destroy();

        part.setValue(this[state.selectedScreen].template);
        part.commit();
      }, 'selectedScreen');
    });
  }

  get renderTimezoneSection() {
    return directive(() => part => {
      const unsubscribe = this.controller.subscribeData(() => {
        part.setValue(this.timezone.template);
        part.commit();
      }, 'date');

      this.cleaners.add(unsubscribe);
    });
  }

  get renderParticipantsTimezoneSection() {
    return directive(() => part => {
      const unsubscribe = this.controller.subscribeState(() => {
        part.setValue(this.participant_timezones.template);
        part.commit();
      }, 'participants', 'action');

      this.cleaners.add(unsubscribe);
    });
  }

  get renderTimezonesList() {
    return directive(template => part => {
      const unsubscribe = this.controller.subscribeState(state => {
        part.setValue(template(state.filteredTimezones));
        part.commit();
      }, 'filteredTimezones');

      this.cleaners.add(unsubscribe);
    });
  }

  get renderParticipantTimesList() {
    return directive(template => part => {
      const unsubscribe = this.controller.subscribeData(() => {
        part.setValue(template);
        part.commit();
      }, 'date');

      this.cleaners.add(unsubscribe);
    });
  }

  get renderSwitcher() {
    return directive(() => part => {
      const unsubscribe = this.controller.subscribeState(() => {
        part.setValue(this.dateTimePicker.switcher);
        part.commit();
      }, 'dateTimeTab');

      this.cleaners.add(unsubscribe);
    });
  }

  get renderView() {
    return directive(() => part => {
      const unsubscribe = this.controller.subscribeState(() => {
        if (this.controller.state.dateTimeTab === 'Time') {
          this.timePicker.destroy();
          part.setValue(this.timePicker.template);
        } else {
          part.setValue(this.datePicker.template);
        }

        part.commit();
      }, 'dateTimeTab');

      this.cleaners.add(unsubscribe);
    });
  }

  get renderDateTimePicker() {
    return directive(() => part => {
      const offset = this.module.moment(this.data.date).tz(this.data.timezone).utcOffset();
      const date = this.module.moment(this.data.date).utcOffset(offset).format();
      const minDate = this.module.moment().utcOffset(offset).format();
      this.controller.setDataByKey('minDate', minDate);

      this.datePicker.setSelected(date);
      this.timePicker.min = minDate;

      part.setValue(this.dateTimePicker.template);
      part.commit();

      const unsubscribe = this.controller.subscribeData(() => {
        const offset = this.module.moment(this.data.date).tz(this.data.timezone).utcOffset();
        const date = this.module.moment(this.data.date).utcOffset(offset).format();
        this.timePicker.setDate(date);
        this.datePicker.setSelected(date);
      }, 'date', 'timezone');

      this.cleaners.add(unsubscribe);
    });
  }
}

export default ActionsDirective;
