import React from 'react';
import store from '@werkin/store';

export function useWerkinStore(key) {
  const [data, setData] = React.useState(store.get(key));

  React.useEffect(() => {
    const unsubscribe = store.subscribe(key, setData);

    return () => { unsubscribe(); };
  }, [key]);

  return [
    data,
    (val, path) => {
      const objPath = path ? `${key}.${path}` : key;
      store.set(objPath, val);
    },
    () => store.unset(key),
  ];
}
