import { render, TemplateResult } from 'lit-html';

export abstract class Element extends HTMLElement {
  public abstract get template(): TemplateResult;

  public connectedCallback() {
    this.update();
  }

  public update() {
    render(this.template, this);
  }
}

export default Element;

