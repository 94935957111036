import { createBrowserHistory } from 'history';
import { routeMatcher } from 'route-matcher';
import { gaTrackPage } from 'services/analytics.service';
import Error from './error';
import { DynamicListeners, IGenerateRoute, IGoRoute, IRoute, RouteStrategy } from './router.interfaces';

const defaultRejector = (error) => {
  if (error) {
    throw (error);
  }
};

class Router extends Error {
  public onError: (e, methodName?, className?) => void;
  public resize_subs_set: Set<any>;
  public sizes_data: any;
  public display: string;
  public history: any;
  public routes_set: IRoute[];
  public listeners_set: Set<any> = new Set([]);
  public hash_listeners: Set<any> = new Set([]);
  public state_listeners: Set<any> = new Set([]);
  public query_listeners: Set<any> = new Set([]);
  public listeners_map: Map<any, any> = new Map();
  public query_data: any;
  public hash_data: any;
  public route: IRoute;
  public global_rejector: (e, methodName?, className?) => void;
  public notFoundMethod: () => void;
  public module_plugins: any;
  public controller_plugins: any;
  public params: any;
  public controller: any;
  public historyFlow = [];
  private subscriptions = [];

  constructor(options) {
    super(options.onError);

    this.onError = options.onError;
    this.resize_subs_set = new Set([]);
    this.sizes_data = options.sizes || {
      desktop: '(min-width: 1025px)',
      mobile: '(max-width: 1024px)',
    };

    this.display = this._defineDisplay();
    this.history = createBrowserHistory(options.history || {});
    this.routes_set = options.routes || [];
    this.query_data = {};
    this.hash_data = {};
    this.route = undefined;
    this.global_rejector = options.onError || defaultRejector;
    this.notFoundMethod = options.notFound;
    this.module_plugins = options.module_plugins;
    this.controller_plugins = options.controller_plugins;

    this._initialisation(options.plugins).then(() => { options.onInit && options.onInit(); });
  }

  public get location() { return this.history.location; }

  public get match() { return this.history.match; }

  public get state() { return this.history.location.state || {}; }

  public get query() { return this._getQueryParams(); }

  public get hash() { return this._getHashParams(); }

  public getRouteMetaData(pathname = this.location.pathname) {
    let matchedRoute;

    this.routes_set.forEach((route) => {
      const matched = this.isMatchedRoute(route, pathname);

      if (matched) {
        matchedRoute = route;
      }
    });

    return matchedRoute;
  }

  public go(route: IGoRoute) {
    const {
      name = this.route.name,
      path = this.location.pathname,
      state = this.state,
      params = this.params,
      query = {},
      hash = this.location.hash,
      strategy = RouteStrategy.push,
    } = route;
    const current_state = { ...this.state, ...state };
    let pathname = path;

    if (path === this.location.pathname) {
      const generateOpts = {
        name: name || this.route.name,
        params: params || this.params,
        query: query || this.query,
        hash: hash || this.location.hash,
      };

      this.params = params;

      pathname = this.generate(generateOpts);
      gaTrackPage(pathname);
    }

    this.history[strategy](pathname, current_state);
  }

  public setState(key, value) {
    if (key) { this.history.location.state[key] = value; }
  }

  public replaceState(state) {
    this.history.location.state = state;
    this.go({});
  }

  public deleteState(stateKey) {
    const state = this.state;

    delete state[stateKey];

    this.history.location.state = state;
    this.go({});
  }

  public clearState() {
    this.history.location.state = {};
    this.go({});
  }

  public back() {
    this.history.goBack();
  }

  public countedBack(n: number) {
    return this.controller.out()
      .then(() => {
        this.history.go(-(n));
      });
  }

  public generate({ name, params = {}, query = {}, hash = this.hash }: IGenerateRoute) {
    const search = new URLSearchParams(this.history.location.search);
    const route = this.routes_set.find((route) => route.name === name);
    const pathConfig = routeMatcher(route.pattern, route.patternOptions);
    const path = pathConfig.stringify(params);

    if (query) {
      query.set && Object.keys(query.set).forEach((key) => search.set(key, query.set[key]));
      query.delete && query.delete.forEach((key) => search.delete(key));
    }

    const queryString = search.toString() || '';
    let hashString = '';

    if (hash) {
      hashString = this.updateHash(hash);
    }

    return `${path}${queryString.length ? '?' : ''}${queryString}${hashString.length ? hashString : ''}`;
  }

  public subscribeOnResizing(fn) {
    this.resize_subs_set.add(fn);

    fn(this.display);

    return () => {
      this.resize_subs_set.delete(fn);
    };
  }

  public listenDynamic(key: DynamicListeners, cb) {
    this[key].add(cb);

    cb();

    return () => {
      this[key].delete(cb);
    };
  }

  public listenMap(key, fn) {
    this.listeners_map.set(key, fn);

    return () => {
      this.listeners_map.delete(key);
    };
  }

  public listen(listener) {
    this.listeners_set.add(listener);

    return () => {
      this.listeners_set.delete(listener);
    };
  }

  public comparePath(name) {
    return this.route.name === name;
  }

  public deleteHash(...keys) {
    if (this.hash_data) {
      keys.forEach((key) => { delete this.hash_data[key]; });
      this.go({ hash: this.hash_data, params: this.params, ...this.route });
    }
  }

  /** Private methods */
  private async _initialisation(plugins) {
    this.historyFlow.unshift(this.location);

    await this._define_route();

    Object.keys(plugins).forEach((plugin_name) => {
      this[plugin_name] = plugins[plugin_name];
    });

    window.addEventListener(
      'resize',
      () => {
        const display = this._defineDisplay();

        if (display !== this.display) {
          this.display = display;
          this.resize_subs_set.forEach((sub) => {
            sub(display);
          });
        }
      }
    );

    this.history.listen((data) => {
      const prevRoute = this.historyFlow[0];

      if (prevRoute.pathname === data.pathname) {
        const changedHash = data.hash !== prevRoute.hash;
        const changedQuery = data.search !== prevRoute.search;
        const prevState = Object.keys(prevRoute.state || {});
        const currentState = Object.keys(prevRoute.state || {});
        const initializer = prevState.length > currentState.length ? prevState : currentState;
        const sub = prevState.length <= currentState.length ? currentState : prevState;

        const stateDiffKeys = initializer.length && initializer.reduce((acc, key) => {
          if (!sub.includes(key)) { acc.push(key); }

          return acc;
        }, []);

        const changedState = !stateDiffKeys.length;

        changedHash && this.hash_listeners.forEach((listener) => { listener(); });
        changedQuery && this.query_listeners.forEach((listener) => { listener(); });
        changedState && this.state_listeners.forEach((listener) => { listener(); });

        (changedQuery || changedHash || currentState) && this.historyFlow.unshift(data);

        return;
      }

      this.subscriptions.forEach((ln) => ln());
      this.subscriptions = [];

      this._define_route();
      this.historyFlow.unshift(data);

      this.listeners_map.forEach((listener) => { listener(); });
      this.listeners_set.forEach((listener) => listener());
    });

    if (!this.route) {
      this.notFoundMethod();
    }
  }

  private async _define_route() {
    const route = this.routes_set.find((route) => this.isMatchedRoute(route));

    if (!route) {
      return this.notFoundMethod();
    }
    const newRoute = this.getRoute(route);
    this.route = newRoute.route;
    this.params = newRoute.params;

    const Controller = this.route.controller;
    const controller = new Controller(this);

    const unsubscribe = this.subscribeOnResizing(async (display) => {
      try {
        const layout = route[display];

        await layout();
        !!this.controller && await this.controller.out();

        this.controller = controller;
        await this.controller.in();
      } catch (e) {
        this.onError(e);
      }

    });

    this.subscriptions.push(unsubscribe);
  }

  private _getHashParams() {
    this.location.hash.slice(1).split('&').forEach((pair) => {
      const [key, value] = pair.split('=');

      if (!key.length) { return; }

      this.hash_data[key] = value || true;
    });

    return this.hash_data;
  }

  private _getQueryParams(): any {
    new URLSearchParams(this.location.search).forEach((value, key) => {

      this.query_data[key] = value;
    });

    return this.query_data;
  }

  private isMatchedRoute(route, pathname = this.location.pathname) {
    /** @ser https://www.npmjs.com/package/route-matcher#sample-usage */
    const matcher = routeMatcher(route.pattern, route.patternOptions);

    return matcher.parse(pathname);
  }

  private getRoute(route, pathname = this.location.pathname) {
    const params = this.isMatchedRoute(route, pathname);

    return params && { route, params };
  }

  private updateHash(hash) {
    let hashString = '';

    if (typeof hash === 'string') {
      hashString = hash;
    } else {
      Object.keys(hash).forEach((key) => {
        if (hash[key] === false) { return; }

        const con = hashString.length ? '&' : '#';

        if (hash[key] === true) {
          hashString = `${hashString}${con}${key}`;
        } else {
          hashString = `${hashString}${con}${key}=${hash[key]}`;
        }
      });
    }

    return hashString;
  }

  private _defineDisplay() {
    let definedDisplay = 'desktop';

    Object.keys(this.sizes_data).forEach((display) => {
      if (window.matchMedia(this.sizes_data[display]).matches) {
        definedDisplay = display;
      }
    });

    return definedDisplay;
  }
}

export default Router;
