import { Icons } from 'components/lit';
import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import MarkdownIt from 'markdown-it';
import emoji from 'markdown-it-emoji';
import cardStyles from 'styles/card.module.scss';
import DashThemesController from './dash-themes.controller';
import styles from './dash-themes.module.scss';

const md = new MarkdownIt({
  html: true,
});
md.use(emoji, []);

class DashThemesTemplate {
  public controller: DashThemesController;

  @Observe('hasRated')
  get rating() {
    const { hasRated, handleRating } = this.controller;

    return !hasRated ? html`
      <div class=${styles.rating}>
        <span>Is this theme useful to you?</span>
        <div class=${styles.options}>
          <button @click=${() => handleRating('liked')}>${Icons.thumbUpIcon}</button>
          <button @click=${() => handleRating('disliked')}>${Icons.thumbDownIcon}</button>
        </div>
      </div>
    ` : nothing;
  }

  @Observe('theme')
  get template() {
    const { theme } = this.controller;

    return theme ? html`
      <div class=${cardStyles.card}>
        <div class=${cardStyles.header}>
          <span class=${cardStyles.label}>${theme.title}</span>
        </div>
        <div class=${cardStyles.content}>
          <div .innerHTML=${md.render(theme.text)}></div>
          ${this.rating}
        </div>
      </div>
    ` : nothing;
  }
}

export default DashThemesTemplate;
