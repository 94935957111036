import { html } from 'lit-html';
import styles from './registration-closed.module.scss';

class RegistrationClosedTemplate {
  get template() {
    return html`
    <div class="${styles.wrapper}">
      <h2>Registration closed</h2>
      <p>The match deadline for the current cohort has already passed
        so you have been registered for the next cohort.</p>
      <p>We'll notify you as soon the launch date is agreed.</p>
      <w-button @click="${() => window.location.replace('https://getwerkin.com/')}">Close</w-button>
    </div>
    `;
  }
}

export default RegistrationClosedTemplate;
