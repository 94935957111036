import { directive, html } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { Icons } from 'components/lit';
import styles from './validated-select.module.scss';
import { ValidationWrapper } from 'components/lit/validated/validation-wrapper.view';
import { repeat } from 'lit-html/directives/repeat';

export class ValidatedSelect extends ValidationWrapper {

  /** ===============================================================================================================
   *  =========================================== CONTROLLERS =======================================================
   *  =============================================================================================================== */

  onInit() {
    this.clicked = function(evt) {
      const { name, handleClose } = this.options;
      const select = document.getElementById(`${name}-select-component`);
      !select.contains(evt.target) && this.setOptions({ opened: false });
      !select.contains(evt.target) && handleClose && handleClose(this.options);
    };

    this.clickHandler = this.clicked.bind(this);

    this.subscribe(() => {
      this.options.opened && window.addEventListener('mouseup', this.clickHandler);
      !this.options.opened && window.removeEventListener('mouseup', this.clickHandler);
    }, 'opened');

    this.subscribe(() => {
      const { opened, name } = this.options;
      const optionEls = opened ? document.querySelectorAll(`.${name}-select-option`) : [];

      this.setOptions({ optionEls, focusedIndex: 0 });
    }, 'opened', 'search');

    this.subscribe(() => {
      const { optionEls, focusedIndex, opened } = this.options;

      if (!opened || !optionEls.length) { return; }

      optionEls.forEach((option, index) => {
        if (index === focusedIndex) {
          const isFirst = option.offsetTop === option.parentNode.offsetTop;
          /** Container last child has same offsetTop as current focused element */
          const isLast = option.parentNode.lastElementChild.offsetTop === option.offsetTop;

          option.classList.add(styles.focused);

          /** If is first set scroll position equal to element's position*/
          if (isFirst) {
            return option.parentNode.scrollTop = option.scrollTop;
          }

          /** If is last set scroll position equal to element's offset from top */
          if (isLast) {
            return option.parentNode.scrollTop = option.offsetTop;
          }

          /** If element's offset from top lager than container height increase scroll position to elements'height */
          if (option.parentNode.offsetHeight < option.offsetTop) {
            return option.parentNode.scrollTop = option.parentNode.scrollTop + option.scrollHeight;
          }

          /** If element's offset from top lager than container height decrease scroll position to elements'height */
          if (option.parentNode.offsetHeight > option.offsetTop) {
            return option.parentNode.scrollTop = option.parentNode.scrollTop - option.scrollHeight;
          }

        } else {
          option.classList.remove(styles.focused);
        }
      });
    }, 'focusedIndex');
  }

  closeOnTab(evt) { evt.key === 'Tab' && this.options.opened && this.toggleDropdown(); }

  onSearch(evt) {
    if (!this.options.withSearch) { return; }

    const { value } = evt.target;

    this.setOptions({
      filteredOptions: this.options.options.filter(opt => opt.label.toUpperCase().includes(value.toUpperCase())),
      search: value,
      focusedIndex: 0,
    });
  }

  handleKeyDown(evt) {
    const { optionEls, focusedIndex: fIndex, filteredOptions, options } = this.options;
    const currentOption = filteredOptions ? filteredOptions[fIndex] : options[fIndex];
    evt.key === 'ArrowDown' && this.setOptions({ focusedIndex: fIndex === optionEls.length - 1 ? 0 : fIndex + 1 });
    evt.key === 'ArrowUp' && this.setOptions({ focusedIndex: fIndex === 0 ? optionEls.length - 1 : fIndex - 1 });
    evt.key === 'Enter' && this.onSelect(currentOption, true);
    evt.key === 'Tab' && this.toggleDropdown();
  }

  onSelect(option, isKey) {
    const { selectHandler } = this.options;

    selectHandler && selectHandler(this.options, option);

    !isKey && this.toggleDropdown();
  }

  toggleDropdown() {
    const { opened, options, handleOpen, handleClose } = this.options;

    this.setOptions({
      opened: !opened,
      search: '',
      filteredOptions: options,
    });

    if (opened === false) { handleOpen && handleOpen(this.options); }
    if (opened === true) { handleClose && handleClose(this.options); }

    !opened && !!options.length && document.getElementById(`${this.options.name}-filter-input-field`).focus();
  }

  /** ===============================================================================================================
   *  ============================================== TEMPLATES ======================================================
   *  =============================================================================================================== */

  get optionsPlaceholder() {
    return html`
      <div class="${styles['options-placeholder']}">${this.options.optionsPlaceholder || 'No options'}</div>
    `;
  }

  option(option) {
    const { value } = this.options;
    const selectedClass = value === option.id ? styles.selected : '';

    return html`
      <button
        class="${this.options.name}-select-option ${selectedClass}"
        @click=${() => this.onSelect(option, false)}
      >
        ${option.label}
      </button>
    `;
  }

  get placeholder() {
    const { placeholder } = this.options;

    return html`<button
        class="${styles.placeholder}"
        @click=${() => this.toggleDropdown()}
        @keydown=${evt => this.closeOnTab(evt)}
    >
      ${placeholder}
      ${Icons.arrowDown}
    </button>`;
  }

  get value() {
    const { value, options } = this.options;

    return html`
      <button
        @click=${() => this.toggleDropdown()}
        @keydown=${evt => this.closeOnTab(evt)}
      >
        ${options.find(opt => opt.id === value).label} ${Icons.arrowDown}
      </button>`;
  }

  get search() {
    const { withSearch, name, search = '' } = this.options;
    const fieldId = `${name}-filter-input-field`;

    return html`
      <label class="${!withSearch ? styles.hidden : styles['search-label']}" for=${fieldId}>
        ${Icons.search}
        <input
          id=${fieldId}
          name="search_field"
          type="text"
          .value=${search}
          @input=${evt => this.onSearch(evt)}
          @keydown=${evt => this.handleKeyDown(evt)}
        />
      </label>
    `;
  }

  get dropdown() {
    const { options, filteredOptions } = this.options;

    return options.length ?
      html`
          <div class="${styles['list-content']}">
            ${this.search}

            <div class="${styles.options}">
              ${ repeat(filteredOptions || options, o => o.id, opt => this.option(opt)) }
            </div>
          </div>
      ` : this.optionsPlaceholder;
  }

  get select() {
    const { value, opened, name, options } = this.options;

    const selected = options.find(opt => opt.id === value);

    if (!name) { throw new Error('Option "name" is required'); }

    const classes = {
      [styles['select-wrapper']]: true,
      [styles['opened-select-wrapper']]: opened,
    };

    const dropdown = {
      [styles.list]: true,
      [styles.opened]: opened,
    };

    return html`
      <div class="${classMap(classes)}" id="${name}-select-component">
        <div class="${styles.select}">
          ${!value || !selected ? this.placeholder : this.value}
        </div>

        <div class="${classMap(dropdown)}">
          ${this.renderDropdown()}
        </div>
      </div>
    `;
  }

  /** ===============================================================================================================
   *  ============================================== DIRECTIVES =====================================================
   *  =============================================================================================================== */

  get renderDropdown() {
    return directive(() => part => {
      this.subscribe(() => {
        part.setValue(this.dropdown);
        part.commit();
      }, 'options', 'filteredOptions');
    });
  }

  get renderSelect() {
    return directive(() => part => {
      this.subscribe(() => {
        part.setValue(this.select);
        part.commit();
      }, 'opened');
    });
  }

  get field() {
    return this.renderSelect();
  }
}
