import apollo from 'api';
import { NotificationManager } from 'services';
import { Router } from 'services/router.service';
import mutation from '../../graphql/mutations/respondToContractValidationResult.graphql';

class BeforeContinueController {
  public async signMe() {
    await this.respond('SIGNUP');
    NotificationManager.showNotification(
      {
        text: 'Thank you for registering. We hope you enjoy the programme.',
        type: 'success',
        duration: 2000,
      }
    );
    setTimeout(() => { window.location.replace(Router.hash.micrositeUrl); }, 2000);
  }

  public async willBeInTouch() {
    await this.respond('REVISIT');
    window.location.replace('/will-be-in-touch');
  }

  public async leaveProgram() {
    await this.respond('LEAVE');
    window.location.replace('/will-be-in-touch');
  }

  public respond(response) {
    return apollo.mutate({
      mutation,
      variables: {
        command: { response },
      },
    });
  }
}

export default BeforeContinueController;
