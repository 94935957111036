import { Icons } from 'components/lit';
import { Observe } from 'decorators';
import { html } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { repeat } from 'lit-html/directives/repeat';
import SidebarController from 'modules/Sidebar/sidebar.controller';
import styles from './sidebar.module.scss';

class SidebarView {
  public controller: SidebarController;

  public collective(collective) {
    const borderWidth = collective.isActive ? 5 : 1;

    const nameClasses = {
      [styles.collective_name]: true,
      [styles.active_name]: collective.isActive,
      [styles.inactive_name]: !collective.isActive,
    };

    return html`
      <div
        class="${styles.collective}"
        @click=${ () => this.controller.changeCollective(collective.id) }
      >
        <w-avatar
          avatar="${collective.avatar}"
          title="${collective.name}"
          width="49px"
          borderWidth="${borderWidth}"
        >
        </w-avatar>

        <span class="${classMap(nameClasses)}">${collective.name}</span>
      </div>
    `;
  }

  @Observe('collectives')
  public get collectivesList() {
    return repeat(this.controller.collectives, (c, i) => i, (collective) => this.collective(collective));
  }

  get template() {
    return html`
      <div class="${styles.wrapper}">
        <div class=${styles.header}>
          <h1>Collectives</h1>
          <button @click=${() => this.controller.destroy()}>${Icons.close}</button>
        </div>
        ${this.collectivesList}
      </div>
    `;
  }
}

export default SidebarView;
