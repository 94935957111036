import { directive, html, nothing } from 'lit-html';
import styles from './status.module.scss';
import * as Icons from 'components/lit/icons.view';
import { classMap } from 'lit-html/directives/class-map';
import { Auth, StatusesService } from 'services';
import moment from 'moment';
import profileService from 'services/data/profile.service';

const StatusIcons = {
  private: Icons.padlock,
  public: Icons.publicChat,
};

const group = (access, active) => html`<div>${StatusIcons[access](active)}</div>`;

const multiDirect = (count) => html`<div class="${styles.multi_direct}"><span>${count}</span></div>`;

const isNotificationsMuted = (notification_mute) => {
  if (!notification_mute) { return; }

  const { mute, muteStart, muteMinutes } = notification_mute;

  const muteEndDate = moment(muteStart).add(muteMinutes, 'minutes');
  const isInfiniteMute = mute && !muteStart && !muteMinutes;

  return isInfiniteMute || muteEndDate.isAfter(moment());
};

export const statusTmp = ({ present, do_not_disturb, notification_mute }) => {
  const classes = {
    [styles.user_status]: true,
    [styles.offline] : !present,
    [styles.online] : present,
    [styles.do_not_disturb] : do_not_disturb,
    [styles.muted]: isNotificationsMuted(notification_mute),
  };

  return html`<div class="${classMap(classes)}"></div>`;
};

export const userStatus = directive((id) => async (part) => {
  if (id === Auth.getUserId()) {
    const {
      profile: {
        doNotDisturb,
      },
      individual: {
        status: present,
      },
    } = await profileService.getProfileAndIndividual();

    part.setValue(statusTmp({ present, do_not_disturb: doNotDisturb }));
    part.commit();

    return;
  }

  part.setValue(statusTmp({}));

  StatusesService.subscribeStatus(id, (individualStatus) => {
    if (individualStatus) {
      const status = statusTmp({
        ...individualStatus,
        muted: isNotificationsMuted(individualStatus.notification_mute),
      });

      part.setValue(status);
      part.commit();
    }
  });
});

export const statusTemplate = (chat, active = false) => {

  if (chat.type !== 'direct') {
    return group(chat.attributes.access, active);
  }

  const length = chat.participants.length;

  if (!length) { return; }

  if (length > 2) {
    return multiDirect(length);
  }

  const participant = chat.participants.find(({ id }) => id !== Auth.getUserId());

  return participant ? userStatus(participant.id) : nothing;
};
