import { classNames } from '@werkin/lib/helpers';
import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import buttonStyles from 'styles/button.module.scss';
import cardStyles from 'styles/card.module.scss';
import DashWorkbookController from './dash-workbook.controller';
import styles from './dash-workbook.module.scss';

class DashWorkbookTemplate {
  public controller: DashWorkbookController;

  @Observe('selectedWorkbook')
  get workbookContent() {
    const { selectedWorkbook } = this.controller;

    if (Object.keys(selectedWorkbook).length === 0) {
      return false;
    }

    if (selectedWorkbook.type === 'personal') {
      return html`
        <p>This is your <strong>personal programme workbook</strong> which is only shared with the WERKIN team.</p>
        <p>Update this workbook as required during the programme.</p>
        <div class=${styles.buttonWrapper}>
          <button class=${classNames('button', styles, buttonStyles.small)}>
            <a href=${selectedWorkbook.url} target="_blank">Open workbook</a>
          </button>
        </div>
      `;
    } else {
      return html`
        <p>
          This is the workbook you share with <strong>${selectedWorkbook.with.name}</strong>. Set and maintain your
          relationship contract, update on progress and provide match feedback.
        </p>
        <p>Your workbook should be updated at least <strong>once a month</strong>.</p>
        <div class=${styles.buttonWrapper}>
          <button class=${classNames('button', styles, buttonStyles.small)}>
            <a href=${selectedWorkbook.url} target="_blank">Open workbook</a>
          </button>
        </div>
      `;
    }
  }

  @Observe('workbooks')
  get switcher() {
    const { workbooks, handleActiveWorkbook } = this.controller;
    console.log(workbooks);
    if (workbooks.length === 1) {
      const workbook = workbooks[0];

      return html`<p class=${styles.header}>
        ${workbook.type === 'personal' ? 'Your personal workbook ' : 'Your shared workbook: ' + workbook.with.name}
      </p>`;
    }

    return workbooks
      ? html`
          <select class=${cardStyles['header-select-full']} @change=${handleActiveWorkbook}>
            ${workbooks.map(
              (workbook) =>
                html`
                  <option value=${workbook.id}>
                    ${workbook.type === 'personal'
                      ? 'Your personal workbook'
                      : 'Your shared workbook:' + workbook.with.name}
                  </option>
                `
            )}
          </select>
        `
      : nothing;
  }

  get template() {
    return html`
      <div class=${cardStyles.card}>
        <div class=${cardStyles.header}>${this.switcher}</div>
        <div class=${cardStyles.content}>
          <div class=${styles.workbookContent}>${this.workbookContent}</div>
        </div>
      </div>
    `;
  }
}

export default DashWorkbookTemplate;
