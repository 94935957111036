import 'core-js';
import './device-detected-info';
import 'indexeddb-getall-shim';
import 'quill/dist/quill.core';
import 'whatwg-fetch';
import smoothscroll from 'smoothscroll-polyfill';

import './components/custom';
import './modules';
import 'array-flat-polyfill';

smoothscroll.polyfill();
