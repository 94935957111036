import { observable } from 'decorators';
import Auth from 'services/auth.service';
import { cutSpaces } from 'utils/common';
import ValidatorHelper from 'utils/validation-helpers';

class ProfileResetPasswordController {
  public vHelper: ValidatorHelper;

  @observable() public newPassword: string;
  @observable() public oldPassword: string;
  @observable() public number: string | null;
  @observable() public special: string | null;
  @observable() public length: string | null;
  @observable() public uppercase: string | null;
  @observable() public lowercase: string | null;
  @observable() public error: string | null;
  @observable() public loading: boolean;
  @observable() public valid: boolean;

  public OnInit() {
    this.setValidators();
  }

  public onChange(evt) {
    const { value, name } = evt.detail.current;

    this[name] = value;
    this.valid = Array.from(evt.target.controls).every(([, { valid }]) => valid);

    name === 'newPassword' && this.isValidPassword();
  }

  public isValidPassword() {
    this.vHelper.validate(this.newPassword);
    this.setValidators();
  }

  public async onConfirm(evt) {
    evt.preventDefault();

    try {
      this.loading = true;
      this.valid = false;

      await Auth.changePassword(cutSpaces(evt.target.oldPassword.value), cutSpaces(evt.target.newPassword.value));
    } catch (e) {
      this.error = e.message;
    } finally {
      this.loading = false;
    }
  }

  private setValidators() {
    Object.keys(this.vHelper.validation).forEach((key: string) => {
      this[key] = this.vHelper.validation[key];
    });
  }
}

export default ProfileResetPasswordController;
