import { render } from 'lit-html';
import ActivityController from 'modules/Activity/activity.controller';
import ActivityView from 'modules/Activity/activity.view';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';
import BadgeItem from './fragments/badge.fragment';
import HighlightItem from './fragments/highlight.fragment';
import SimpleItem from './fragments/simple.fragment';

const fragments = new Map();
fragments.set('simple', SimpleItem);
fragments.set('badge', BadgeItem);
fragments.set('highlight', HighlightItem);

@RouterModule({
  name: 'w-activity-module',
  view: ActivityView,
  controller: ActivityController,
  fragments,
})
class ActivityModule {
  public view: ActivityView;
  public controller: ActivityController;
  public subs: Set<() => void> = new Set([]);

  public async loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default ActivityModule;
