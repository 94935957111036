import { RouterModule } from 'services/router/router.module';
import CalendarTemplate from './calendar.view';
import CalendarDirective from './calendar.directives';
import CalendarController from './calendar.controller';
import styles from './calendar.module.scss';

class CalendarModule extends RouterModule {
  subs = new Set([]);

  mount() {
    const calendar = new CalendarTemplate(this.module);

    calendar.controller = CalendarController;
    calendar.directives = CalendarDirective;
    calendar.styles = styles;

    this.manageLitMount(calendar.template, 'calendar');
  }

  unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default CalendarModule;
