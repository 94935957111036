import apollo from 'api';
import { isAfter, parseISO } from 'date-fns';
import { observable } from 'decorators';
import { IChat } from 'interfaces';
import TabbarModule from 'modules/Tabbar/tabbar.module';
import { Router } from 'services/router.service';
import AlertsQuery from '../../graphql/queries/alerts.graphql';
import ChatsListQuery from '../../graphql/queries/chat/ChatsList.graphql';

class TabbarController {
  public module: TabbarModule;

  @observable(0, true) public unreadCount: number;
  @observable(0, true) public unreadMessagesCount: number;
  @observable(Router.route.name) public activeRoute: string;

  public OnInit() {
    this.fetchAlerts();
    this.fetchChats();
    this.subscribeToRoute();
  }

  private fetchAlerts() {
    const observe = apollo.watchQuery({
      query: AlertsQuery,
    }).subscribe({
      next: ({ data: { alerts } }) => {
        const { items, lastViewed } = alerts;
        const unread = items.filter(({ read }) => !read).length;
        const unviewed = items.filter(({ createdAt }) => isAfter(parseISO(createdAt), parseISO(lastViewed))).length;

        this.unreadCount = lastViewed ? unviewed : unread;
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public fetchChats() {
    const observe = apollo.watchQuery({
      query: ChatsListQuery,
    }).subscribe({
      next: ({ data: { chats } }) => {
        this.unreadMessagesCount = (chats as IChat[]).reduce((acc: number, { unreadMessageIds }) =>
          acc + unreadMessageIds.length, 0);
      },
      error: (e) => console.error('watchQuery Error', e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  private subscribeToRoute() {
    const unsubscribeRouter = Router.listen(async () => {
      this.activeRoute = Router.route.name;
    });

    this.module.subs.add(unsubscribeRouter);
  }
}

export default TabbarController;
