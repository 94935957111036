import { render } from 'lit-html';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';
import CreditStreaksController from './credits-streaks.controller';
import CreditStreaksView from './credits-streaks.view';

@RouterModule({
  name: 'w-credits-streaks-module',
  controller: CreditStreaksController,
  view: CreditStreaksView,
})
class CreditStreaksModule {
  public subs: Set<() => void> = new Set([]);
  public view: CreditStreaksView;

  public async loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default CreditStreaksModule;
