import get from 'lodash/get';
import intercomService from 'services/intercom.service';
import profileService from 'services/data/profile.service';
import rest from 'services/rest.service';
const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

export async function setupIntercomProfile() {
  const profile = await profileService.getProfile();

  const { data: { values } } = await rest.getSystemData();
  const intercomProfile = {
    user_id: get(profile, 'id', ''),
    name: [get(profile, 'general.firstName', ''), get(profile, 'general.lastName', '')].join(' '),
    firstName: get(profile, 'general.firstName', ''),
    lastName: get(profile, 'general.lastName', ''),
    email: get(get(profile, 'contactDetails.emailAddresses', []).find(({ isPrimary }) => isPrimary), 'value'),
    phone: get( get(profile, 'contactDetails.telephoneNumbers[0]'), 'value'),
    avatar: { type: 'avatar', image_url: get(profile, 'general.profileImageUrl') },
    company: {
      name: get(profile, 'general.job.organization'),
      // TODO: Temporary solution, 'comapany_id' is required
      company_id: get(profile, 'general.job.industry'),
      industry: get(values.find(({ id }) => id === get(profile, 'general.job.industry')), 'label'),
    },
    app_id: APP_ID,
  };

  intercomService('shutdown');
  intercomService('boot', intercomProfile);
}

export function resetIntercomProfile() {
  intercomService('shutdown');
  intercomService('boot', { app_id: APP_ID });
}
