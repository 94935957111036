import { html } from 'lit-html';
import styles from './center-modal.module.scss';
import { Icons } from 'components/lit';
import ModalBase from 'atoms/lit/modal/base';

class CenterPromisifiedModal extends ModalBase {
  submitForm() {
    return undefined;
  }

  constructor(...args) {
    super(...args);

    this.title = '';
    this.text = '';
    this.submitBtnText = '';
    this.cancelBtnText = 'Cancel';
  }

  get tmp() {
    return html`
      <div class="${styles.logout}">
        <div class="${styles.modal_header}">
          <h2 class="${styles.title}">${this.title}</h2>
          <div class="${styles.close_btn}" @click=${() => this.destroy()}>
            ${Icons.circleCross}
          </div>
        </div>

        <div class="${styles.text}">
          <span>
            ${this.text}
          </span>
        </div>

        <div class="${styles.buttons_wrapper}">
          <button class="${styles.button}" @click=${() => this.submitForm()}>${this.submitBtnText}</Button>
          <button @click=${() => this.destroy()}>${this.cancelBtnText}</Button>
        </div>
      </div>
    `;
  }
}

export default CenterPromisifiedModal;
