import { render } from 'lit-html';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';
import CreditsAchievementsController from './credits-achievements.controller';
import CreditsAchievementsView from './credits-achievements.view';

@RouterModule({
  name: 'w-credits-achievements-module',
  view: CreditsAchievementsView,
  controller: CreditsAchievementsController,
})
class CreditsAchievementsModule {
  public subs: Set<() => void> = new Set([]);
  public view: CreditsAchievementsView;

  public async loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default CreditsAchievementsModule;
