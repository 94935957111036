import { Icons } from 'components/lit';
import { html } from 'lit-html';
import WillBeInTouchController from './will-be-in-touch.controller';
import styles from './will-be-in-touch.module.scss';

class WillBeInTouchView {
  public controller: WillBeInTouchController;

  get template() {
    return html`
      <div class="${styles.wrapper}">
        <div class="${styles.description}">
          Someone will be in touch
        </div>

        ${Icons.messagesEmojiSad}

        <p>
          We’re sorry you couldn’t be added to the programme at this time. We’ve let the WERKIN
          team know and someone will be in touch to discuss next steps.
        </p>

        <button @click=${() => this.controller.close()}>Ok</button>
      </div>
    `;
  }
}

export default WillBeInTouchView;
