import { html } from 'lit-html';
import styles from './center-modal.module.scss';
import { Icons } from 'components/lit';
import { showIf } from 'directives';
import ModalBase from 'atoms/lit/modal/base';

class CenterAutonomousModal extends ModalBase {
  submitForm() {
    return undefined;
  }

  constructor(...args) {
    super(...args);

    this.title = '';
    this.text = '';
    this.submitBtnText = '';
    this.cancelBtnText = 'Cancel';
    this.displayButtons = true;
    this.styles = styles;
  }

  setStyles(customStyles) {
    this.styles = {
      ...styles,
      ...customStyles,
    };
  }

  get tmp() {
    const buttons = html`
      <div class="${this.styles.buttons_wrapper}">
        <button
          class="${this.styles.button}"
          @click=${() => this.submitForm()}
        >
          ${this.submitBtnText}
        </button>
        <button @click=${() => this.destroy()}>
          ${this.cancelBtnText}
        </button>
      </div>
    `;

    return html`
      <div class="${this.styles.logout}">
        <div class="${this.styles.modal_header}">
          <h2 class="${this.styles.title}">${this.title}</h2>
          <div class="${this.styles.close_btn}" @click=${() => this.destroy()}>
            ${Icons.circleCross}
          </div>
        </div>

        <div class="${this.styles.text}">
          <span>
            ${this.text}
          </span>
        </div>

        ${showIf(this.displayButtons, buttons)}
      </div>
    `;
  }
}

export default CenterAutonomousModal;
