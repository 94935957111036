export default (Quill) => {
  const Embed = Quill.import('blots/embed');

  class ImagesBlot extends Embed {
    public static create(value) {
      const node = document.createElement('img');

      node.setAttribute('src', value);
      node.style.maxWidth = '300px';

      return node;
    }

    public static value(node) {
      return node.src;
    }
  }

  ImagesBlot.blotName = 'picture';

  Quill.register({ 'formats/image': ImagesBlot }, true);
};
