import React from 'react';
import { Link } from 'react-router-dom';

/* Pride assets */
import prideLogo from 'assets/pre-launch/pride/logo.png';
import prideSponsor from 'assets/pre-launch/pride/sponsor.png';
import prideFooterPic from 'assets/pre-launch/pride/footer_small_pic.png';

/* EY assets */
import eyLogo from 'assets/pre-launch/apac/logo.png';
import eySponsor from 'assets/pre-launch/apac/sponsor.png';
import eyFooterPic from 'assets/pre-launch/apac/footer_small_pic.png';

/* WCC assets */
import wccSponsor from 'assets/pre-launch/wcc/sponsor.png';
import wccTip1 from 'assets/pre-launch/wcc/tip1.png';
import wccTip2 from 'assets/pre-launch/wcc/tip2.png';
import wccTip3 from 'assets/pre-launch/wcc/tip3.png';
import wccFooterPic from 'assets/pre-launch/wcc/footer_small_pic.png';
import timerBackground from 'assets/pre-launch/apac/backgrounds/header.png';
import timerPrideBackground from 'assets/pre-launch/pride/backgrounds/header.png';
import apacKeyDatesBg from 'assets/pre-launch/apac/backgrounds/key-dates.png';
import wccFooterBackground from 'assets/pre-launch/wcc/backgrounds/footer.png';

/* APAC assets */
import apacTip1 from 'assets/pre-launch/apac/tip1.jpg';
import apacTip2 from 'assets/pre-launch/apac/tip2.png';
import apacTip3 from 'assets/pre-launch/apac/tip3.png';

import girl from 'assets/pre-launch/girl.png';

/* WIBF assets */
import wibfSponsor from 'assets/pre-launch/wibf/sponsor.png';
import t1wibf from 'assets/pre-launch/wibf/Lorena.png';
import t2wibf from 'assets/pre-launch/wibf/Melissa.png';
import t3wibf from 'assets/pre-launch/wibf/Eleni.png';
import wibfFooterBackground from 'assets/pre-launch/wibf/Bottom-image.png';
import wibfFooterPic from 'assets/pre-launch/wibf/collective.png';

export default {
  wcc: {
    blocks: [
      { title: 'Why', value: 'sponsor', mTitle: 'Why this is important' },
      { title: 'Key Dates', value: 'dates', mTitle: 'Key Dates' },
      { title: 'Tips', value: 'tips', mTitle: 'Tips from the WERKIN world' },
      { title: null, value: 'footer' },
    ],
    header: {
      logo: eyLogo,
      iconsColor: '#fff',
      bgColor: (scroll) => `rgba(1, 70, 96, ${scroll})`,
      navColors: {
        default: '#fff',
        active: '#dda700',
      },
    },
    timer: {
      title: 'WCC Inclusive Mentoring',
      dateText: 'Launching 11 February 2021',
      background: `url(${timerBackground}) center / cover no-repeat`,
      colors: {
        unit: '#fff',
        value: '#4cb49c',
        title: '#fff',
      },
    },
    sponsor: {
      photo: wccSponsor,
      title: 'Stuart Love, CEO',
      text: '“Reverse mentoring is an opportunity for our leaders to learn about different perspectives and' +
        ' experiences of staff from different backgrounds, with the aim of creating a more diverse and inclusive' +
        ' workplace”.',
      background: 'none',
    },
    keyDates: {
      registrationOpen: {
        title: 'Registration open for new cohort',
        text: 'That\’s you! Congratulations for finalising your registration on the WCC Inclusive Mentoring programme. You\’ll be joining the rest of the Collective soon.',
        action: 'Scroll down to read other people\’s experiences',
      },
      registrationClose: {
        title: 'Registration closes',
        text: 'Your registration is confirmed but if you know other WCC employees who would enjoy or benefit from this programme, we\’d love to include them.',
        action: 'Invite others to register here: app.getwerkin.com',
      },
      cohortLaunch: {
        title: 'Meet your match',
        text: 'Sign in via the app to meet your match, remember don\'t be shy and just say hi!',
        action: '',
      },
    },
    dates: {
      background: `url(${apacKeyDatesBg}) center/cover no-repeat`,
    },
    video: null,
    tips: [
      {
        avatar: wccTip1,
        name: 'Tia Daji, Team Manager at Westminster Home Improvement Agency @WCC',
        text: "“I'm competent, but I'm also very compassionate and the kind of leader that I want to be is a caring one.”",
        link: 'https://www.werkinwithyou.com/werkin-with/tia-daji',
        btnText: 'Listen',
      },
      {
        avatar: wccTip2,
        name: 'Arun Plumb, Organisational Advisor for Diversity & Inclusion @WCC',
        text: `“With reverse mentoring, you get to share your lived experiences with people who might not be aware of them,
        or people who are particularly interested in them.”`,
        link: 'https://getwerkin.com/blog/arun-plumb-westminster-city-council',
        btnText: 'Listen',
      },
      {
        avatar: wccTip3,
        name: 'Proven benefits of reverse mentoring, Forbes',
        text: "“A recent Forbes study found that 85% of senior executives believe diverse and inclusive workforces are 'crucial' to innovation...”",
        link:
          'https://www.forbes.com/sites/jasonwingard/2018/08/08/reverse-mentoring-3-proven-outcomes-driving-change/',
        btnText: 'Read',
      },
    ],
    footer: {
      pic: wccFooterPic,
      background: `url(${wccFooterBackground}) center/cover no-repeat`,
      title: 'WCC Launch',
      subtitle: 'Launching 22 February 2021',
      text: 'WCC Inclusive Mentoring',
    },
  },
  apac: {
    blocks: [
      { title: 'Why', value: 'sponsor' },
      { title: 'Key Dates', value: 'dates' },
      { title: 'WERKIN video', value: 'video' },
      { title: 'Tips', value: 'tips' },
      { title: null, value: 'footer' },
    ],
    header: {
      logo: eyLogo,
      iconsColor: '#fff',
      bgColor: (scroll) => `rgba(1, 70, 96, ${scroll})`,
      navColors: {
        default: '#fff',
        active: '#dda700',
      },
    },
    timer: {
      title: 'EY APAC',
      initialDate: new Date(2019, 6, 2).getTime(),
      dateText: '2nd July 2019',
      background: `url(${timerBackground}) center / cover no-repeat`,
      colors: {
        unit: '#fff',
        value: '#4cb49c',
        title: '#fff',
      },
    },
    sponsor: {
      photo: eySponsor,
      title: 'Dan Higgins Global Technology Consulting Leader',
      text: `"Our partnership with WERKIN is founded on the principle that through active sponsorship on a modern
              digital platform we will evolve the way we mentor and support talented individuals from all genders,
              backgrounds and preferences. This is a key pillar of our Women in Tech movement aimed at creating a
              future that is equal and exciting!"`,
      background: 'none',
    },
    keyDates: {
      registrationOpen: {
        title: 'Get WERKIN!',
        text: 'Participants can now register to be matched up with a mentor or mentee and for access to ' +
          'the WERKIN platform.',
        action: 'You\'re registered, well done!',
      },
      registrationClose: {
        title: 'Registration closes',
        text: 'It\'s almost time to meet your match, ensure you have the right technical setup and keep an' +
          ' eye out for WERKIN\'s in-app updates!',
        action: 'Check your technical set up',
      },
      cohortLaunch: {
        title: 'Meet your match',
        text: 'Attend the official live launch event or log into the virtual meeting here where you\'ll meet your' +
          ' match and get access to the WERKIN app',
        action: 'Show me my match',
      },
    },
    dates: {
      background: `url(${apacKeyDatesBg}) center/cover no-repeat`,
    },
    video: 'https://player.vimeo.com/video/341971935',
    tips: [
      {
        avatar: apacTip1,
        name: 'Elizabeth Martine, Barclays',
        text: '‘As a mentee, it\'s important to outline what you want from the relationship’',
        listen: 'https://getwerkin.com/blog/elizabeth-martine',
      },
      {
        avatar: apacTip2,
        name: 'Jennifer Rademaker, Mastercard',
        text: '‘Have a very strong view of where your talents are and where are your areas of development’',
        listen: 'https://getwerkin.com/blog/episode-22-werkin-with-jennifer-rademaker',
      },
      {
        avatar: apacTip3,
        name: 'Charlotte Livingston, RBC',
        text: '‘The benefit of mentors is that they provide a different perspective’',
        listen: 'https://getwerkin.com/blog/charlotte-livingston',
      },
    ],
    footer: {
      pic: eyFooterPic,
      title: 'ey apac launch',
      subtitle: 'Singapore 02 July 2019',
      text: 'EY Women in Technology',
    },
  },
  emea: {
    blocks: [
      { title: 'Why', value: 'sponsor' },
      { title: 'Key Dates', value: 'dates' },
      { title: 'WERKIN video', value: 'video' },
      { title: 'Tips', value: 'tips' },
      { title: null, value: 'footer' },
    ],
    header: {
      logo: eyLogo,
      iconsColor: '#fff',
      bgColor: (scroll) => `rgba(1, 70, 96, ${scroll})`,
      navColors: {
        default: '#fff',
        active: '#dda700',
      },
    },
    timer: {
      title: 'EY EMEA',
      initialDate: new Date(2019, 6, 11),
      dateText: '11th July 2019',
      background: `url(${timerBackground}) center / cover no-repeat`,
      colors: {
        unit: '#fff',
        value: '#4cb49c',
        title: '#fff',
      },
    },
    sponsor: {
      photo: eySponsor,
      title: 'Dan Higgins Global Technology Consulting Leader',
      text: `"Our partnership with WERKIN is founded on the principle that through active sponsorship on a modern
              digital platform we will evolve the way we mentor and support talented individuals from all genders,
              backgrounds and preferences. This is a key pillar of our Women in Tech movement aimed at creating a
              future that is equal and exciting!"`,
      background: 'none',
    },
    keyDates: {
      registrationOpen: {
        title: 'Get WERKIN!',
        text: 'Participants can now register to be matched up with a mentor or mentee and for access to ' +
          'the WERKIN platform.',
        action: 'You\'re registered, well done!',
      },
      registrationClose: {
        title: 'Registration closes',
        text: 'It\'s almost time to meet your match, ensure you have the right technical setup and keep an' +
          ' eye out for WERKIN\'s in-app updates!',
        action: 'Check your technical set up',
      },
      cohortLaunch: {
        title: 'Meet your match',
        text: 'Attend the official live launch event or log into the virtual meeting here where you\'ll meet your' +
          ' match and get access to the WERKIN app',
        action: <>See you there or
          <a target='_blank' rel='noopener noreferrer' href='https://zoom.us/j/2782290767'>join here</a>
        </>,
      },
    },
    dates: {
      background: `url(${apacKeyDatesBg}) center/cover no-repeat`,
    },
    video: 'https://player.vimeo.com/video/341971935',
    tips: [
      {
        avatar: apacTip1,
        name: 'Elizabeth Martine, Barclays',
        text: '‘As a mentee, it\'s important to outline what you want from the relationship’',
        listen: 'https://getwerkin.com/blog/elizabeth-martine',
      },
      {
        avatar: apacTip2,
        name: 'Jennifer Rademaker, Mastercard',
        text: '‘Have a very strong view of where your talents are and where are your areas of development’',
        listen: 'https://getwerkin.com/blog/episode-22-werkin-with-jennifer-rademaker',
      },
      {
        avatar: apacTip3,
        name: 'Charlotte Livingston, RBC',
        text: '‘The benefit of mentors is that they provide a different perspective’',
        listen: 'https://getwerkin.com/blog/charlotte-livingston',
      },
    ],
    footer: {
      pic: eyFooterPic,
      title: 'ey emea launch',
      subtitle: 'London 11 July 2019',
      text: 'EY Women in Technology',
    },
  },
  pride: {
    blocks: [
      { title: 'Why', value: 'sponsor' },
      { title: 'Key Dates', value: 'dates' },
      { title: 'WERKIN video', value: 'video' },
      { title: 'Tips', value: 'tips' },
      { title: null, value: 'footer' },
    ],
    header: {
      logo: prideLogo,
      iconsColor: '#274a5a',
      bgColor: (scroll) => `rgba(255, 255, 255, ${scroll})`,
      navColors: {
        default: '#274a5a',
        active: '#df9ca1',
      },
    },
    timer: {
      title: 'WORLD PRIDE',
      initialDate: new Date(2019, 5, 26).getTime(),
      dateText: '26 June 2019',
      background: `url(${timerPrideBackground}) center / cover no-repeat`,
      colors: {
        unit: '#df9ca1',
        value: '#df9ca1',
        title: '#939292',
      },
    },
    sponsor: {
      photo: prideSponsor,
      title: 'A message from programme sponsor, RuPaul',
      text: '“This is a message about how amazing the programme is,' +
        ' what we are trying to do and how WERKIN will support”',
      background: 'linear-gradient(to bottom, rgba(255, 131, 119, 0.5), rgba(229, 94, 80, 0.5))',
    },
    dates: [
      {
        day: 17,
        month: 'June',
        title: 'Registration Opens',
        text: 'Participants can register to be matched with a mentor / mentee and for access' +
          ' to the WERKIN platform. You can update your match profile till the match deadline',
        action: 'You’ve registered, well done!',
      },
      {
        day: 23,
        month: 'June',
        title: 'Match Deadline',
        text: 'Registration closes and you can no longer edit your match profile. WERKIN will pair ' +
          'you using their matching engine and the information you provided',
        action: <>Action: Check your <Link to={''}>profile data</Link> before the match.</>,
      },
      {
        day: 26,
        month: 'June',
        title: 'Meet Your Match',
        text: 'Attend the official live launch event or log into the virtual meeting here where you’ll' +
          ' meet your match and get access to the WERKIN app',
        action: <>Action: <Link to={''}>Save the date!</Link></>,
      },
    ],
    video: 'https://player.vimeo.com/video/341971935',
    tips: [
      {
        avatar: girl,
        name: 'Elizabeth Martine, Barclays',
        text: '“As a mentee its important to outline what you want from the relationship”',
      },
      {
        avatar: girl,
        name: 'Elizabeth Martine, Barclays',
        text: '“As a mentee its important to outline what you want from the relationship”',
      },
      {
        avatar: girl,
        name: 'Elizabeth Martine, Barclays',
        text: '“As a mentee its important to outline what you want from the relationship”',
      },
    ],
    footer: {
      pic: prideFooterPic,
      title: 'world pride launch',
      subtitle: 'NYC 26 June 2019',
      text: 'Out and Proud Mentoring',
    },
  },
  wibf: {
    blocks: [
      { title: 'Why', value: 'sponsor', mTitle: 'Why this is important' },
      { title: 'Key Dates', value: 'dates', keyDates: 'Key dates', mTitle: 'Key Dates' },
      { title: 'Tips', value: 'tips', mTitle: 'Tips from the WERKIN world' },
      { title: null, value: 'footer' },
    ],
    header: {
      logo: eyLogo,
      iconsColor: '#fff',
      bgColor: (scroll) => `rgba(1, 70, 96, ${scroll})`,
      navColors: {
        default: '#fff',
        active: '#dda700',
      },
    },
    timer: {
      title: 'WIBF Mentoring',
      dateText: 'Coming January 2020',
      background: `url(${timerBackground}) center / cover no-repeat`,
      colors: {
        unit: '#fff',
        value: '#4cb49c',
        title: '#fff',
      },
    },
    sponsor: {
      photo: wibfSponsor,
      title: 'Jay Surti, Head of Mentoring, WIBF',
      text: `“Mentoring is a key pillar in WIBF’s offering to the network,
       and we are thrilled to be able to give participants a new way to connect to each other.
        It’s so important to surround yourself with people who will support you, challenge you,
         and champion you when they can. We hope you find some of those people on this programme.”`,
      background: 'none',
    },
    keyDates: {
      registrationOpen: {
        title: 'WIBF Mentoring Programme 2020 Reboot',
        text: `WERKIN will host a virtual event to kick off the WIBF 2020 Mentoring Programme!
         There will be a walk through of the new platform, a speaker,
          and a chance to ask the WERKIN team questions. A recording of the event will be available. Happy Mentoring!`,
        action: 'You\'re registered! Congratulations!',
      },
      registrationClose: {
        title: 'Registration closes',
        text: `It's almost time to meet your match! What do you expect them to offer you?
         What can you offer them? Remember to prepare as much as possible ahead of that all-important first meeting.`,
        action: 'It’s nearly time to GET WERKIN! Check back in a few days to see who your match is',
      },
      cohortLaunch: {
        title: 'Meet your match',
        text: 'Sign in via the app to meet your match, remember don\'t be shy and just say hi!',
        action: 'Show me my match!',
      },
    },
    dates: {
      background: `url(${apacKeyDatesBg}) center/cover no-repeat`,
    },
    video: null,
    tips: [
      {
        avatar: t1wibf,
        name: 'Lorena Busto Hurtado\n' +
          'Santander',
        text: `“Now I believe in mentorship. The good thing about being a mentor is that you also
         get advice from your mentee sometimes. “`,
        link: 'https://getwerkin.com/blog/werkin-with-wibf',
        btnText: 'Listen',
      },
      {
        avatar: t2wibf,
        name: 'Melissa Di Donato\n' +
          'SAP',
        text: `“How do we get women to avoid the trap doors, they truck along in their career and
        then the trapdoor happens and they fall before they can get into a management role. How do we avoid that?”`,
        link: 'https://getwerkin.com/werkin-with/melissa-di-donato',
        btnText: 'Listen',
      },
      {
        avatar: t3wibf,
        name: 'Eleni Vlami\n' +
          'Meniga',
        text: `“I learned to identify what types of people I can work with,
         and what type of experience and tips and advice I can get from different people,
          and understand also how important it is to have a good chemistry and a good connection with a mentor,
           like any relationship.”`,
        link: 'https://getwerkin.com/blog/eleni-vlami',
        btnText: 'Listen',
      },
    ],
    footer: {
      pic: wibfFooterPic,
      background: `url(${wibfFooterBackground}) center/cover no-repeat`,
      title: 'WIBF mentoring',
      subtitle: 'January 2020',
      text: 'WIBF Mentoring',
    },
  },
};
