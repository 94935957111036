import apollo from 'api';
import { observable } from 'decorators';
import { IProfessionalDevelopment } from 'interfaces/profile/professional-development.interface';
// tslint:disable-next-line:import-spacing
import ProfileProfessionalDevelopmentModule
  from 'modules/ProfileProfessionalDevelopment/profile-professional-development.module';
import { Auth, REST } from 'services';
import { Router } from 'services/router.service';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';

class ProfileProfessionalDevelopmentController {
  public module: ProfileProfessionalDevelopmentModule;

  @observable(null) public careerStages: any;
  @observable(null) public pureAreas: any;
  @observable({
    relationships: [],
    learningAndDevelopmentAreas: [],
  })
  public professionalDevelopment: IProfessionalDevelopment;

  public OnInit() {
    this.fetchSystemData();
    this.watchProfile();
  }

  public watchProfile() {
    const observe = apollo.watchQuery({
      query: ProfileQuery,
      variables: {
        id: Auth.getUserId(),
      },
    }).subscribe({
      next: ({ data: { profile } }) => {
        this.professionalDevelopment = profile.professionalDevelopment;
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public async fetchSystemData() {
    const { data: { values } } = await REST.getSystemData();

    this.careerStages = values.filter((v) => v.category === 'career-stages');
    this.pureAreas = values.filter((v) => v.category === 'learning-development-area');
  }

  get careerStage() {
    const { careerStage } = this.professionalDevelopment;

    return this.careerStages && this.careerStages.find(({ id }) => id === careerStage);
  }

  get areas() {
    const { learningAndDevelopmentAreas } = this.professionalDevelopment;

    return this.pureAreas && learningAndDevelopmentAreas.map((dev) => ({
      ...dev, ...this.pureAreas.find(({ id }) => id === dev.area),
    }));
  }

  public goToUserProfile(relationship) {
    Router.go({ name: 'individual', params: { userId: relationship.id } });
  }

  public openContractModal(relationship) {
    Router.go({ hash: { MentoringContractModal: true, relationshipId: relationship.id } });
  }

  public onShareGoal() {
    Router.go({ hash: { ShareGoals: true } });
  }

  public onEdit(targetEntity) {
    Router.go({
      hash: {
        ProfileEditingModal: true,
        root: 'professionalDevelopment',
        targetEntity,
      },
    });
  }
}

export default ProfileProfessionalDevelopmentController;
