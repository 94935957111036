import { Icons, statusTemplate } from 'components/lit/index';
import { html, nothing } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';
import { repeat } from 'lit-html/directives/repeat';
import { openPopup } from 'modules/Chat/chat.utils';
import styles from 'modules/ChatHeader/header.module.scss';

import { Observe } from 'decorators';
import ChatHeaderController from 'modules/ChatHeader/header.controller';
import HeaderModule from 'modules/ChatHeader/header.module';

class ChatHeaderView {
  public chat: any;
  public popupClose: any;
  public module: HeaderModule;
  public controller: ChatHeaderController;

  @Observe('chat')
  public get headerDataTemplate() {
    if (!this.controller.chat) { return nothing; }

    const avatar = html`
      <w-avatar
        avatar="${this.controller.chat.avatar}"
        title="${this.controller.chat.title}"
        width="40px"> </w-avatar>`;

    return html`
      <div class="${styles.header_data}">
        <button class="${styles.back}" @click=${() => this.controller.goToChat()}> ${ Icons.arrowLeftLong } </button>
        ${ this.controller.chat && this.controller.chat.type !== 'direct' ? avatar : ''}
        <div class="${styles.status} ${this.controller.chat.type === 'direct' ? styles.direct_status : ''}">
          ${ this.controller.chat && statusTemplate(this.controller.chat) }
        </div>
        <div>
          ${this.controller.getChatTitle()}
        </div>
      </div>
    `;
  }

  @Observe('chat')
  public get menu() {
    if (!this.controller.chat) { return nothing; }

    const popupStyles = {
      position: 'absolute',
      top: '40px',
      right: '0',
      zIndex: 1000,
    };

    const popupTmp = this.settings.bind(this);

    return this.controller.isVisibleMenu ? html`
      <div id="header_popup-container">
        <button class="${styles.menu}" @click=${() => openPopup(popupTmp, popupStyles, 'header_popup-container')}>
          ${Icons.menuIcon()}
        </button>
      </div>
    ` : nothing;
  }

  public setting({ label, handler, disabled }: any, close = () => {}) {
    return html`
      <span
        class="${styles.option} ${ifDefined(disabled &&  styles.disabled)}"
        @click="${() => handler(this.chat).then(close)}"
      >${ label }</span>
    `;
  }

  public settings(close) {
    this.popupClose = close;

    return html`
      <div class="${styles.popup_wrapper}">
        <div class="${styles.close}" @click="${(e) => this.popupClose(e)}">
          ${ Icons.circleCross }
        </div>
        <h4>Settings:</h4>
        <div class="${styles.settings_wrapper}">
          ${repeat(this.controller.getSettings(), (setting) => this.setting(setting, this.popupClose))}
        </div>
      </div>
    `;
  }

  @Observe('invisible')
  private get wrapper() {
    return !this.controller.invisible ? html`
      <div class="${styles.wrapper}">
        ${this.headerDataTemplate}
        ${this.menu}
      </div>
    ` : nothing;
  }

  get template() {
    return this.wrapper;
  }
}

export default ChatHeaderView;
