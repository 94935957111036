import { html } from 'lit-html';
import styles from 'modules/Activity/activity.module.scss';
import ActivityTemplate from '../activity.view';
import { IActivityFragmentProps } from './fragment.interface';

class BadgeItem {
  public view: ActivityTemplate;

  constructor() {
    this.template = this.template.bind(this);
  }

  public template({ item, loading }: IActivityFragmentProps) {
    const {
      created,
      content: {
        text,
        value,
        caption,
      },
      id,
      likes,
    } = item;

    return html`
      <div class=${styles.feed_item}>
        ${this.view.itemHead({ created, text })}
        <div class=${styles.badge_wrapper}>
          <div class=${styles.badge_background}></div>
          <div class=${styles.badge_content}>
            <span class=${styles.badge_value}>${value}</span>
            <p class=${styles.badge_caption}>${caption}</p>
          </div>
        </div>
        ${this.view.social({ id, likes }, loading)}
      </div>
    `;
  }
}

export default BadgeItem;
