import { html, render } from 'lit-html';
import styles from './base.module.scss';
import { showIf } from 'directives';
import { Icons } from 'components/lit';

let storedFunc;

class ModalBase {
  constructor(options = {}) {
    this.modalContainer = document.createElement('div');
    this.modalContainer.setAttribute('class', styles.wrapper);
    this.listeners = {};

    this.modal_name = this.constructor.name;
    this.options = options;
    const tmp = html`
      ${this.backPanel}
      <div class=${styles.wrapper}>
        <span>Base template</span>
        <button @click=${this.destroy.bind(this)}>${this.cancelBtnText}</button>
      </div>
      ${showIf(options.closeBtn, this.closeBtn)}
    `;

    this.template = this.wrap(tmp);
  }

  onOpen() {}

  async open() {
    await this.onOpen();

    document.body.appendChild(this.modalContainer);
    render(this.template, this.modalContainer);

    this.triggerListeners('open');

    storedFunc = this.destroy.bind(this);
    this.modalContainer.addEventListener('click', storedFunc, false);
  }

  destroy() {
    this.modalContainer.removeEventListener('click', storedFunc, false);

    this.triggerListeners('destroy');
    this.listeners = {};

    if (document.body.contains(this.modalContainer)) {
      document.body.removeChild(this.modalContainer);
    }
    this.onDestroy();
  }

  subscribe(fn, ...events) {
    if (!events.length) {
      throw new Error('Modal subscribe method expected event names listed after function fn, "close", "open"...');
    }

    const unsubscribers = events.map(event => {
      this.listeners[event] = this.listeners[event] || new Set([]);
      this.listeners[event].add(fn);

      return () => {
        this.listeners[event].delete(fn);
      };
    });

    return () => unsubscribers.forEach(fn => fn());
  }

  onDestroy() {}

  get closeBtn() {
    return html`<div class="${styles.close_btn}" @click=${this.destroy.bind(this)}>${Icons.cancel}</div>`;
  }

  get backPanel() {
    return html`
      <div class=${styles.back_panel}>
        <span class=${styles.back_btn} @click=${this.destroy.bind(this)}>${Icons.arrowLeftLong} Back</span>
      </div>`;
  }

  wrap(template) {
    return html`
      <div id="${this.modal_name}" @click=${(e) => e.stopPropagation()} class=${styles.container}>
        ${template}
      </div>
    `;
  }

  triggerListeners(event) {
    if (!this.listeners[event]) { return; }

    this.listeners[event].forEach(fn => fn());
  }
}

export default ModalBase;
