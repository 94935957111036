import Fragment from 'services/templator/fragment';
import { html } from 'lit-html';
import { checkbox } from 'atoms/lit';
import { showIf } from 'directives';

class MembersFragment extends Fragment {
  member(user) {
    const exist = !!this.controller.state[`${user.id}_selected_member`];

    const onChange = (value, evt) => this.controller.addRemove(evt, user, 'member');

    return html`
      <div class="${this.styles.member}" @click=${evt => this.controller.addRemove(evt, user, 'member')}>
        <w-avatar avatar="${user.avatar}" title="${user.name}" width="40px"> </w-avatar>
        <div class="${this.styles.details}">
          <div class="${this.styles.name}">
            ${user.name}
          </div>
          <div class="${this.styles.sub_info}">
            <p>${user.position}</p>
            ${showIf(user.location, html`<p>Based in ${user.location}</p>`)}
          </div>
        </div>
        ${checkbox({ value: exist, onChange })}
      </div>
    `;
  }

  get template() {
    return html`
      <h2>Search invitees</h2>

      ${this.directives.renderSearch('memberSearch', 'Name here', 'Search name')}

      ${this.directives.renderParticipants()}

      <div class="${this.styles.members}">
        ${this.directives.renderMembersList() }
      </div>
    `;
  }
}

export default MembersFragment;
