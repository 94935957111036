/*eslint no-sequences: "off"*/

import apollo from 'api';
import { observable } from 'decorators';
import { ILeaveJoin } from 'interfaces';
import orderBy from 'lodash/orderBy';
import { Auth, NotificationManager, SharedGoal, StatusesService } from 'services';
import CollectivesService from 'services/data/collectives.service';
import IndividualsService from 'services/data/individuals.service';
import { Router } from 'services/router.service';
import ShareGoalsMutation from '../../graphql/mutations/shareGoals.graphql';
import ChatQuery from '../../graphql/queries/chat/Chat.graphql';
import receivedGoals from '../../graphql/queries/receivedGoals.graphql';
import InfoModule from './info.module';

class InfoController {
  public module: InfoModule;
  public leaveJoin: ILeaveJoin;

  @observable(false) public goalsDetails: any;
  @observable(null) public goalsDate: any;
  @observable([]) public goalsList: any;
  @observable(null) public user: any;
  @observable(null) public userCollective: any;
  @observable(null) public chat: any;
  @observable([]) public chatMembers: any;

  @observable() public yourShare: boolean;
  @observable() public userShare: boolean;
  @observable() public isSingle: boolean;
  @observable() public userShareLatest: boolean | Date;
  @observable(true) public latestVersion: boolean;

  public async OnInit() {
    await this.getChat();
    this.userCollective = await CollectivesService.getActiveCollective();
  }

  private subscribeOnSharedGoals() {
    const unsubscribe = SharedGoal.subscribe(() => {
      this.yourShare = !this.user || !this.checkIfShared();
    });

    this.module.subs.add(unsubscribe);
  }

  private invokeMembersStatuses(chat) {
    const userId = Auth.getUserId();
    const ids = chat.participants.reduce((acc, member) => (member.id !== userId && acc.push(member.id), acc), []);

    StatusesService.subscribe(this.constructor.name, ids);

    this.module.subs.add(() => StatusesService.unsubscribe(this.constructor.name, ids));
  }

  private getUser() {
    const user = this.isSingle ? this.chatMembers.find((user) => user.id !== Auth.getUserId()) : null;

    return this.user || user;
  }

  public getShares() {
    if (this.user) {
      SharedGoal.shareStatus(this.user.id).then(({ data: { sharedGoals } }) => {
        this.yourShare = !sharedGoals.isShared;
      });

      const observe = apollo.watchQuery({
        query: receivedGoals,
        variables: {
          individualId: this.user.id,
        },
      }).subscribe({
        next: async ({ data: { receivedGoals } }) => {
          this.userShare = !!receivedGoals;
          this.goalsList = receivedGoals ? receivedGoals.goals : [];
          this.goalsDate = receivedGoals ? this.module.moment(receivedGoals.shared).format('Do MMM YYYY') : null;
          this.userShareLatest = receivedGoals ? receivedGoals.shared : null;
          this.latestVersion = receivedGoals ? receivedGoals.latestVersion : true;
        },
        error: (e) => console.error(e),
      });

      this.module.subs.add(() => observe.unsubscribe());
    }
  }

  public async chooseUser(evt, user) {
    this.user = user;
    this.isSingle = true;
    this.yourShare = !this.user || !this.checkIfShared();
  }

  public backToUsers() {
    this.user = null;
    this.isSingle = false;
    this.yourShare = false;
  }

  public showProfile() {
    return this.user && Router.go({ hash: { IndividualModal: true, userId: this.user.id } });
  }

  public viewShares() {
    this.goalsDetails = true;

    this.getShares();
  }

  public async shareGoals() {
    try {
      this.yourShare = false;

      await apollo.mutate({
        mutation: ShareGoalsMutation,
        variables: {
          command: {
            recipientIds: [this.user.id],
          },
        },
      });

      SharedGoal.collectMyShares();

      NotificationManager.showNotification({ type: 'success', text: 'Your goals were successfully shared' });
    } catch (e) {
      NotificationManager.showNotification({ type: 'error', text: 'Your goals were not shared. Server error' });
      this.yourShare = true;
    }
  }

  public edit(single = false, tab = 'details') {
    const { route, params, display } = Router;

    return Router.go({
      name: display === 'mobile' ? 'chat.edit' : route.name,
      params,

      state: {
        left: {
          modal: 'groups',
          tab,
          single,
          action: 'edit',
          text: 'group',
          chatId: Router.params.chatId,
          types: ['group', 'channel'],
        },
      },
    });
  }

  public checkIfShared() {
    return SharedGoal.shares.find((share) => share.individual.id === this.user.id);
  }

  public backToUser() {
    this.goalsDetails = false;
  }

  private async getChat() {
    const observe = await apollo.watchQuery({
      query: ChatQuery,
      variables: {
        id: Router.params.chatId,
      },
    }).subscribe({
      next: async ({ data: { chat } }) => {
        if (!chat) { return; }

        const individuals = await IndividualsService.getIndividuals(true);

        const memberIds = chat.participants.map((m) => m.id);
        const chatMembers = individuals.filter((i) => memberIds.includes(i.id));
        this.chat = chat;
        this.isSingle = chat.participants.length === 2 && chat.type === 'direct';
        this.chatMembers = orderBy(chatMembers, 'name');
        this.user = this.getUser();

        this.invokeMembersStatuses(chat);
        this.subscribeOnSharedGoals();
        this.getShares();
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }
}

export default InfoController;
