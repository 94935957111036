import Fragment from 'services/templator/fragment';
import { html } from 'lit-html';
import { Icons } from 'components/lit';

class DetailsFragment extends Fragment {
  get isPublic() {
    const { isPublic } = this.data;

    return html`
      <div class="${this.styles.access}">

        <h3>${isPublic ? 'Public' : 'Private'}</h3>
        <div>
          <p>${ isPublic ? 'Anyone can join this group' : 'Invite only and not viewable by others' }
          </p>

          <label class="${this.styles.switch}">
            <input type="checkbox" .defaultChecked=${isPublic} @change=${evt => this.controller.handleSwitchChange(evt)}>
            <span class="${this.styles.slider}"/>
          </label>
        </div>
      </div>
    `;
  }

  get icon() {
    const { icon } = this.data;

    return html`
      <div class="${this.styles.upload} ${icon.title ? this.styles.valid : ''}">
        <div class="${this.styles.input}">
          ${!icon.value ? Icons.cameraIcon : html`<img src="${icon.value}" alt="${icon.name}"/>` }
          <input
              type="file" name="icon" accept=".jpg, .jpeg, .png"
              @input=${evt => this.controller.handleIconChange(evt)}
           />
          </div>

          <div class="${this.styles['icon-info']}">
            <span> ${ icon.title || 'Choose a group profile icon' } </span>
            <h3>Optional</h3>
          </div>


          ${Icons.circleCheckIcon( icon.title && icon.value ? '#4cb49c' : '#939292')}
       </div>
    `;
  }

  get template() {
    return html`
      ${this.directives.renderAccess()}
      ${this.directives.renderUploader('icon', this.controller)}
      ${this.directives.renderInput('title', this.controller)}
      ${this.directives.renderTextArea('purpose', this.controller)}
    `;
  }
}

export default DetailsFragment;
