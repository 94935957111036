import { Collapsable } from 'components/lit/collapsable.view';
import ProfileAutonomousModal from 'components/lit/modals/profile-autonomus-modal.view';
import { AutonomousModal } from 'decorators';
import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import ProfileService from 'services/data/profile.service';
import { Router } from 'services/router.service';
import styles from './mentoring-contract.module.scss';

@AutonomousModal('MentoringContractModal', 'relationshipId')
class MentoringContractModal extends ProfileAutonomousModal {
  public text: any;

  public async onOpen() {
    const { relationshipId } = Router.hash;

    if (!relationshipId) {
      return Router.go({ hash: {} });
    }

    const {
      professionalDevelopment: {
        contract: ownContract,
        relationships,
      },
    } = await ProfileService.getProfile();

    const { contract, name } = relationships.find(({ id }) => id === relationshipId);

    const { template: ownContractTemplate } = new Collapsable({
      title: `Your promise to ${name}`,
      template: this.buildContractTemplate(ownContract, 'You have agreed to'),
    });

    const { template: alienContractTemplate } = new Collapsable({
      title: `${name}’s promise`,
      template: this.buildContractTemplate(contract, `${name} has agreed to`),
    });

    this.title = `Your promise with ${name}`;
    this.text = html`
      <div class=${styles['dialog-wrapper']}>
        ${ownContractTemplate}
        ${alienContractTemplate}
      </div>
    `;
    this.displayButtons = false;
    this.template = this.wrap(this.tmp);
  }

  public buildContractTemplate(contractInfo, description) {
    return html`
      <p class=${styles.description}>${description}:</p>
      ${repeat(contractInfo, this.buildRuleItem)}
    `;
  }

  public buildRuleItem(rule) {
    return html`<p class=${styles['contract-line']}>${rule}</p>`;
  }
}

export default MentoringContractModal;
