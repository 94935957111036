import apollo from 'api';
import { observable } from 'decorators';
import { ICollective } from 'interfaces';
import SidebarModule from 'modules/Sidebar/sidebar.module';
import dataService from 'services/data';
import CollectivesQuery from '../../graphql/queries/collectives.graphql';

class SidebarController {
  public destroy: () => void;
  public module: SidebarModule;

  @observable([], true) public collectives: ICollective[];

  public OnInit() {
    const observe = apollo.watchQuery({
      query: CollectivesQuery,
    }).subscribe({
      next: ({ data: { collectives } }) => {
        this.collectives = collectives;
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public async changeCollective(collectiveId) {
    await dataService.changeCollective(collectiveId);
    this.destroy();
  }
}

export default SidebarController;
