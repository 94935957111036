import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import buttonStyles from 'styles/button.module.scss';
import cardStyles from 'styles/card.module.scss';
import DashProfileCompletionController from './dash-profile-completion.controller';
import styles from './dash-profile-completion.module.scss';

class DashProfileCompletionTemplate {
  public controller: DashProfileCompletionController;

  public action(action) {
    const { handleAction } = this.controller;
    const { label, value } = action;

    return html`
      <li class=${styles.item}>
        <p>${label}</p>
        ${!!value ? html`
          <span class=${styles.done}>Done</span>
        ` : html`
          <button class=${buttonStyles.small} @click=${() => handleAction(action)}>Set now</button>
        `}
      </li>
    `;
  }

  public list(actions) {
    return html`
      <ul class=${styles.actions}>
        ${repeat(actions, ({ label }) => label, (action) => this.action(action))}
      </ul>
    `;
  }

  @Observe('profileActions')
  get template() {
    const { profileActions } = this.controller;
    const percentageComplete = (100 * profileActions.filter(({ value }) => value).length) / profileActions.length;

    return profileActions.length ? html`
      <div class=${cardStyles.card}>
        <div class=${cardStyles.header}>
          <span class=${cardStyles.label}>Your profile is ${percentageComplete}% complete</span>
        </div>
        <div class=${cardStyles.content}>
          <div>
            <p>This improves the quality of your matches.</p>
            ${this.list(profileActions)}
          </div>
        </div>
      </div>
    ` : nothing;
  }
}

export default DashProfileCompletionTemplate;
