export interface IModuleParams {
  name?: string;
  controller?: any;
  fragments?: Map<any, any>;
  view?: any;
  data?: any;
  vm?: any;
  plugins?: Map<any, any>;
  observers?: any[];
}

export interface IModule {
  view: any;
  mount: (params?) => void;
  unmount?: () => void;
  addSubscription?: (cb: () => void) => void;
}

export interface OnInit {
  OnInit: () => void;
}

export interface OnDestroy {
  OnDestroy: () => void;
}

export interface WithState {
  state: any;
  setStateByKey: (key: string, value: any) => {};
  subscribeState: (cb: (state: any) => void, ...keys: string[]) => {};
}

export enum RouteStrategy {
  push = 'push',
  replace = 'replace',
}

export interface IGoQuery {
  set?: any;
  delete?: string[];
}

export interface IGenerateRoute {
  name?: string;
  params?: any;
  query?: IGoQuery;
  hash?: any;
}

export interface IGoRoute extends IGenerateRoute {
  path?: string;
  state?: any;
  strategy?: RouteStrategy;
}

export interface IRoute {
  pattern: string;
  name: string;
  controller: any;
  mobile: any;
  desktop: any;
  patternOptions?: any;
  private?: boolean;
  loader?: boolean;
  pass?: boolean;
}

export enum DynamicListeners {
  hash = 'hash_listeners',
  query = 'query_listeners',
  state = 'state_listeners',
}
