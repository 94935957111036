import apollo from 'api';
import { observable } from 'decorators';
import { ITracker } from 'interfaces/dash/tracker.interface';
import { gaTrackEvent } from 'services/analytics.service';
import MatchesQuery from '../../graphql/queries/matches.graphql';
import MatchTrackerQuery from '../../graphql/queries/matchTracker.graphql';
import DashTrackerModule from './dash-tracker.module';

class DashTrackerController {
  public module: DashTrackerModule;
  @observable([]) public matches: any[];
  @observable(0) public activeMatchIndex: number;
  @observable([], true) public tracker: ITracker[];

  constructor() {
    this.handleActiveMatch = this.handleActiveMatch.bind(this);
  }

  public async OnInit() {
    this.fetchMatches();
  }

  private async fetchMatches() {
    const { data: { matches } } = await apollo.query({
      query: MatchesQuery,
    });

    this.matches = matches;
    this.fetchMatchTracker(matches[this.activeMatchIndex].id);
  }

  private async fetchMatchTracker(id: string) {
    const { data: { matchTracker } } = await apollo.query({
      query: MatchTrackerQuery,
      variables: {
        id,
      },
    });

    this.tracker = matchTracker;
  }

  public handleActiveMatch(event) {
    const { value } = event.target;

    if (!value) { return; }

    this.activeMatchIndex = value;
    this.fetchMatchTracker(this.matches[value].id);
  }

  public scrollListeners() {
    const trackerEl: HTMLElement = document.querySelector('[data-id="tracker"]');
    let isDown = false;
    let startX;
    let scrollLeft;

    if (!trackerEl) { return; }

    const start = (e) => {
      isDown = true;
      startX = (e.pageX || e.targetTouches[0].pageX) - trackerEl.offsetLeft;
      scrollLeft = trackerEl.scrollLeft;
    };

    const move = (e) => {
      if (!isDown) { return; }
      e.preventDefault();
      const x = (e.pageX || e.targetTouches[0].pageX) - trackerEl.offsetLeft;
      const walk = (x - startX) * 2.5; // scroll-fast
      trackerEl.scrollLeft = scrollLeft - walk;
    };

    const end = () => {
      gaTrackEvent({ category: 'dashboard:tracker', action: 'scrolled' });
      isDown = false;
    };

    trackerEl.addEventListener('mousedown', start);
    trackerEl.addEventListener('mouseleave', end);
    trackerEl.addEventListener('mouseup', end);
    trackerEl.addEventListener('mousemove', move);
    trackerEl.addEventListener('touchstart', start);
    trackerEl.addEventListener('touchmove', move);
    trackerEl.addEventListener('touchend', end);
  }
}

export default DashTrackerController;
