import { html, TemplateResult } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import {
  BlockGroup,
  BlotBlock,
  InlineGroup,
  ListGroup,
  QuillDeltaToHtmlConverter,
  VideoItem,
} from 'quill-delta-to-html';
import { IOption, IOptions } from './interfaces';
import { block, blot, br, emoji, image, list, mention, text, video } from './templates/templates';

class Converter {
  public templates: Array<TemplateResult | string>;

  protected options: IOption[];
  protected converter: QuillDeltaToHtmlConverter;

  constructor(options: IOptions) {
    this.options = options.ops;
    this.converter = new QuillDeltaToHtmlConverter(this.options);

    const groups = this.converter.getGroupedOps();

    this.templates = groups.map((group) => {
      switch (true) {
        case group instanceof ListGroup:
          return list(group);
        case group instanceof BlockGroup:
          return block(group);
        case group instanceof BlotBlock:
          return blot(group);
        case group instanceof VideoItem:
          return video(group);
        case group instanceof InlineGroup:
          const options = (group as InlineGroup).ops.slice(0, -1);

          const templates = options.map((option: any) => {
            switch (option.insert.type) {
              case 'mention':
                return mention(option);
              case 'emoji':
                return emoji(option, options.length > 1);
              case 'picture':
                return image(option);
              case 'text':
                if (option.insert.value === '\n') {
                  return br();
                }

                return text(option);
              default:
                return html`Not found`;
            }
          });

          return html`<div>
            ${repeat(
              templates,
              (t, i) => i,
              (template) => template
            )}
          </div>`;
        default:
          return 'Undefined type';
      }
    });
  }
}

export default Converter;
