import { Observe } from 'decorators';
import { showIf } from 'directives';
import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import ProfileSettingsController from './profile-settings.controller';
import { settings } from './profile-settings.data';
import styles from './profile-settings.module.scss';

class ProfileSettingsTemplate {
  private controller: ProfileSettingsController;

  public buildCard({ title, options, autonomous }) {
    return html`
      <div class=${styles.card}>
        <p class=${styles['card-title']}>${title}</p>

        ${repeat(options, this.buildCardOption.bind(this))}
      </div>

      ${showIf(!autonomous, this.saveButton(title))}
    `;
  }

  public buildCardOption({ controller: { targetKey, type }, description }) {
    const control = {
      switch: this.switch(targetKey),
      button: this.button(targetKey),
    }[type];

    return html`
      <div class=${styles['card-content']}>
        <p>${description}</p>
        ${control}
      </div>
    `;
  }

  @Observe('settingsGroups')
  public saveButton(targetKey: string) {
    const { next, loading } = this.controller.settingsGroups[targetKey] || {};

    return html`
      <button
        @click=${() => this.controller.onSaveSettings(targetKey)}
        class=${styles['save-button']}
        ?disabled=${!next}
      >
        ${loading ? 'Saving...' : 'Save changes'}
      </button>
    `;
  }

  @Observe('individualSettings')
  public button(targetKey: string) {
    const value = this.controller.individualSettings[targetKey];
    const text = value ? 'Remove' : 'Allow';
    const className = value ? styles['button-outlined'] : styles.button;

    return html`
      <button
        @click=${() => this.controller.updateIndividualProfileOption(targetKey)}
        class=${className}
      >
        ${text}
      </button>
    `;
  }

  @Observe('settings')
  public switch(targetKey: string) {
    const { value } = this.controller.settings.find((option) => option.key === targetKey) || {};
    const isChecked = value === 'true';

    return html`
      <label class="${styles['switch-input']}">
        <input
          type="checkbox"
          .defaultChecked=${isChecked}
          @change=${() => this.controller.updateProfileOption(targetKey)}
        >
        <span class="${styles.slider}"/>
      </label>
    `;
  }

  // buildDropdown() {
  //   const title = 'Send event reminder';
  //   const description = 'See notifications and emails to update how it is sent';

  //   return html`
  //     <div>${title}</div>
  //     <input/>
  //     <div>${description}</div>
  //   `;
  // }

  public get cards() {
    return html`${repeat(settings, this.buildCard.bind(this))}`;
  }

  get template() {
    return html`
      <div class=${styles['cards-wrapper']}>
        <h3 class=${styles.title}>Settings</h3>

        ${this.cards}

        <button
          @click=${this.controller.onLogOut}
          class="${styles['save-button']} ${styles['button-outlined']}"
        >
          Log out of WERKIN
        </button>
      </div>
    `;
  }
}

export default ProfileSettingsTemplate;
