import { Observe } from 'decorators';
import { html } from 'lit-html';
import buttonStyles from 'styles/button.module.scss';
import cardStyles from 'styles/card.module.scss';
import DashConnectivityController from './dash-connectivity.controller';
import styles from './dash-connectivity.module.scss';

class DashConnectivityTemplate {
  public controller: DashConnectivityController;

  @Observe('suggestPWA')
  get pwa() {
    const { suggestPWA, handleInstallPWA } = this.controller;

    return (
      suggestPWA &&
      html`
        <div>
          <p>Install WERKIN as an app to your desktop or phone.</p>
          <button class=${buttonStyles.small} @click=${handleInstallPWA}>Install the app</button>
        </div>
      `
    );
  }

  get push() {
    const { suggestPush, handleNotifications } = this.controller;

    return html`
      <div>
        ${suggestPush === 'prompt'
          ? html` <p>Enable push notifications to bring updates on your WERKIN journey straight to your device.</p> `
          : html` <p>You're receiving WERKIN updates straight to your device.</p> `}
        <button class=${buttonStyles.small} @click=${handleNotifications}>Adjust notifications</button>
      </div>
    `;
  }

  get template() {
    return html`
      <div class=${cardStyles.card}>
        <div class=${cardStyles.header}>
          <span class=${cardStyles.label}>Stay connected</span>
        </div>
        <div class=${cardStyles.content}>
          <div class=${styles.actions}>${this.pwa} ${this.push}</div>
        </div>
      </div>
    `;
  }
}

export default DashConnectivityTemplate;
