import Fragment from 'services/templator/fragment';
import { html } from 'lit-html';
import { Icons } from 'components/lit';

class ActionsHeader extends Fragment {
  back(opts) {
    return html`
      <button
        @click=${() => this.controller.back()}
      >
        ${Icons[opts.backIcon]} ${opts.backLabel}
      </button>
    `;
  }

  next(opts) {
    return html`
      <button
       @click=${() => this.controller.next()}
       ?disabled=${!this.controller.state.allowNext || !opts.nextScreen}
       >
          ${opts.nextLabel} ${Icons[opts.nextIcon]}
       </button>
    `; }

  get template() {
    const { nav, selectedScreen, selectedOption } = this.controller.state;
    const opts = nav[selectedScreen];

    const labels = {
      schedule: 'Schedule',
      meeting: 'Meet up',
      call: 'Call',
    };

    return html`
      <div class="${this.styles.header}">
        <div>
          ${this.directives.showIf(opts.backLabel || opts.backIcon, this.back(opts))}
        </div>
        <div>
          ${this.directives.showIf(opts.withOptionName, labels[selectedOption])}
        </div>
        <div>
          ${this.directives.showIf(opts.nextLabel || opts.nextIcon, this.next(opts))}
        </div>
      </div>
    `;
  }
}

export default ActionsHeader;
