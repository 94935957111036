import apollo from 'api';
import { observable } from 'decorators';
import HeaderMenuModule from 'modules/HeaderMenu/header-menu.module';
import HeaderMenuView from 'modules/HeaderMenu/header-menu.view';
import moment from 'moment';
import { Auth } from 'services';
import { Router } from 'services/router.service';
import DoNotDisturbMutation from '../../graphql/mutations/doNotDisturb.graphql';
import setIndividualNotificationMuteMutation from '../../graphql/mutations/setIndividualNotificationMute.graphql';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';
import styles from './header-menu.module.scss';

interface IOption {
  title: string;
  value: any;
}

class HeaderMenuController {
  public module: HeaderMenuModule;
  public view: HeaderMenuView;
  public muteOptions: IOption[];

  @observable(false, true) public doNotDisturb: boolean;
  @observable({}, true) public notificationMute: any;

  public OnInit() {
    const dayStartsAt = {
      hour: 8,
      minute: 0,
      second: 0,
    };

    const nextWeek = moment().add(1, 'weeks').isoWeekday(1).set(dayStartsAt);
    const tomorrow = moment().add(1, 'day').set(dayStartsAt);

    this.muteOptions = [
      {
        title: '30 Minutes',
        value: 30,
      },
      {
        title: '1 Hour',
        value: 60,
      },
      {
        title: '2 Hours',
        value: 2 * 60,
      },
      {
        title: '4 Hours',
        value: 4 * 60,
      },
      {
        title: 'Until tomorrow',
        get value() {
          return tomorrow.diff(moment(), 'minutes') + 1;
        },
      },
      {
        title: 'Next week',
        get value() {
          return nextWeek.diff(moment(), 'minutes') + 1;
        },
      },
    ];

    this.watchSettings();

    this.missClickListener = this.missClickListener.bind(this);

    this.module.subs.add(() => window.removeEventListener('click', this.missClickListener));
  }

  public missClickListener(evt) {
    const container = document.querySelector(`.${styles['menu-container']}`);
    const block = document.querySelector(`.${styles['menu-block']}`);

    if (block.contains(evt.target)) { return; }

    container.setAttribute('class', `${styles['menu-container']} ${styles.close}`);

    window.removeEventListener('click', this.missClickListener);
  }

  public onMenuOpen() {
    const container = document.querySelector(`.${styles['menu-container']}`);
    const isOpen = container.classList.contains(styles.open);

    if (isOpen) {
      container.setAttribute('class', `${styles['menu-container']} ${styles.close}`);

      window.removeEventListener('click', this.missClickListener);
    } else {
      container.setAttribute('class', `${styles['menu-container']} ${styles.open}`);

      window.addEventListener('click', this.missClickListener);
    }
  }

  get isMuted() {
    return this.notificationMute.mute;
  }

  get dateOfNotificationUnmute() {
    const { muteMinutes, muteStart } = this.notificationMute;

    if (!muteStart) {
      return '';
    }

    const dateOfMuteExpiration = moment(muteStart).add(muteMinutes, 'minutes');

    if (dateOfMuteExpiration.isBefore(moment())) {
      return '';
    }

    if (dateOfMuteExpiration.diff(moment(), 'days') > 0) {
      return dateOfMuteExpiration.format('DD MMM, hh:mm A');
    }

    return dateOfMuteExpiration.format('hh:mm A');
  }

  public onNotificationMute(muteMinutes) {
    return this.muteNotification({
      mute: true,
      muteMinutes,
      muteStart: moment().toISOString(),
    });
  }

  public onInfiniteNotificationMute() {
    return this.muteNotification({
      mute: true,
      muteMinutes: 0,
      muteStart: null,
    });
  }

  public onTurnOffMute() {
    return this.muteNotification({
      mute: false,
      muteMinutes: 0,
      muteStart: null,
    });
  }

  public async muteNotification(notificationMute) {
    await apollo.mutate({
      mutation: setIndividualNotificationMuteMutation,
      variables: {
        command: notificationMute,
      },
      refetchQueries: [{
        query: ProfileQuery,
        variables: {
          id: Auth.getUserId(),
        },
      }],
    });
  }

  public async setDoNotDisturb(evt) {
    const value = evt.target.checked;

    await apollo.mutate({
      mutation: DoNotDisturbMutation,
      variables: {
        command: {
          status: value,
        },
      },
      refetchQueries: [{
        query: ProfileQuery,
        variables: {
          id: Auth.getUserId(),
        },
      }],
    });
  }

  public close() {
    const container = document.querySelector(`.${styles['menu-container']}`);

    container.setAttribute('class', `${styles['menu-container']} ${styles.close}`);

    window.removeEventListener('click', this.missClickListener);
  }

  public keyDown(evt) {
    evt.keyCode === 27 && this.close();
  }

  public activateModal(name: string) {
    this.close();

    Router.go({ hash: { [name]: true } });
  }

  private watchSettings() {
    const observe = apollo.watchQuery({
      query: ProfileQuery,
      variables: {
        id: Auth.getUserId(),
      },
    }).subscribe({
      next: ({ data: { profile } }) => {
        this.notificationMute = profile.notificationMute;
        this.doNotDisturb = profile.doNotDisturb;
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }
}

export default HeaderMenuController;
