import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import ValidatorHelper from 'utils/validation-helpers';
import TemporaryController from './temporary.controller';
import TemporaryView from './temporary.view';

const plugins = new Map();

plugins.set('vHelper', new ValidatorHelper());

@RouterModule({
  name: 'w-temporary-module',
  view: TemporaryView,
  controller: TemporaryController,
  plugins,
})
class TemporaryModule {
  public view: TemporaryView;

  public mount(module) {
    render(this.view.template, module);
  }
}

export default TemporaryModule;
