import { manageMount as reactMount, manageUnmount } from 'lib/react-component-manager';
import { render } from 'lit-html';
import TechnicalRequirements from 'modules/Launch/TechnicalRequirements';
import { Router } from 'services/router.service';
import { DynamicListeners, IModule } from 'services/router/router.interfaces';
import { RouterModule } from 'services/router/router.m';
import LaunchController from './launch.controller';
import LaunchView from './launch.view';

@RouterModule({
  name: 'w-launch-module',
  controller: LaunchController,
  view: LaunchView,
})
class LaunchModule implements IModule {
  public subs: Set<() => void> = new Set([]);
  public view: LaunchView;
  public controller: LaunchController;

  public mount(module) {
    render(this.view.template, module);

    const unsubscribe = Router.listenDynamic(DynamicListeners.hash, () => {
      if (Router.hash.RequirementsModal) {
        this.controller.isRequirementsOpened = Router.hash.RequirementsModal;

        setImmediate(() => { reactMount(TechnicalRequirements, 'launch_modal'); });
      } else {
        manageUnmount('launch_modal');
        this.controller.isRequirementsOpened = Router.hash.RequirementsModal;
      }
    });
    this.subs.add(unsubscribe);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default LaunchModule;
