import { render } from 'lit-html';
import BeforeContinueController from 'modules/BeforeContinue/before-continue.controller';
import BeforeContinueView from 'modules/BeforeContinue/before-continue.view';
import { RouterModule } from 'services/router/router.m';

@RouterModule({
  name: 'w-before-continue-module',
  view: BeforeContinueView,
  controller: BeforeContinueController,
})
class BeforeContinueModule {
  public view: BeforeContinueView;

  public mount(module) {
    render(this.view.template, module);
  }
}

export default BeforeContinueModule;
