import store from '@werkin/store';
import { REST, Auth, NotificationManager, TimeZone, Worker } from 'services';
import { RouterController } from 'services/router/router.controller';
import intercom, { toggleWhenLocationChanged } from 'services/intercom.service';
import RouterError from 'services/router/router-error';
import dataService from 'services/data';
import profileService from 'services/data/profile.service';

function setLoading(loading) {
  const el = document.getElementById('preloader');
  const evt = new CustomEvent('loading', { detail: { loading } });

  el.dispatchEvent(evt);
}

class ControllerMiddleware extends RouterController {
  makeLogout() {
    this.router.go({ name: 'get-link' });
    Auth.logout();
    Worker.unsubscribePushManager();
    window.location.reload(true);
  }

  async routeHandler() {
    const landingRoute = store.get('landingRoute');
    const isMobileChat = this.router.display === 'mobile' && landingRoute.includes('chats');

    if (this.router.location.pathname !== '/' || isMobileChat) { return; }

    const landingRouteMetaData = this.router.getRouteMetaData(landingRoute);

    this.store.set('loading', landingRouteMetaData.loader);
    this.router.go({ path: landingRoute });

    return Promise.reject(new RouterError({ message: `Redirecting to ${landingRoute}` }));
  }

  async loadLandingRoute() {
    const { data: { route } } = await REST.getLandingRoute();

    store.set('landingRoute', route);
  }

  isPreventedLoader() {
    const currentRouteMetadata = this.router.getRouteMetaData();

    if (!currentRouteMetadata.loader) {
      this.store.set('loading', false);

      return true;
    }

    return false;
  }

  initAccordingHashState() {
    if (Object.keys(this.router.hash).length) {
      this.router.go({});
    }
  }

  async showNotificationIfComeFromCronofy() {
    const {
      individual: {
        authorizedCalendarAccess,
      },
    } = await profileService.getProfileAndIndividual();

    if (this.router.query.cronofyAuth === 'success' && authorizedCalendarAccess) {
      NotificationManager.showNotification({
        text: 'Your calendar has been successfully synced to WERKIN.',
        type: 'success',
        duration: 4000,
      });

      this.router.go({ query: { delete: ['cronofyAuth'] } });
    }

    if (this.router.query.cronofyAuth === 'success' && !authorizedCalendarAccess) {
      const link = '<a href = "mailto: support@getwerkin.com">support@getwerkin.com</a>';

      NotificationManager.showNotification({
        text: `Your calendar has failed to sync to WERKIN. Please try again or contact ${link}.`,
        type: 'error',
        duration: 4000,
      });

      this.router.go({ query: { delete: ['cronofyAuth'] } });
    }

  }

  async setAwaiter() {
    TimeZone.update();

    if (this.router.query.test_error) {
      throw new Error('Internal server error');
    }

    const loading = this.store.get('loading');

    if (!loading) {
      return;
    }

    await dataService.run();
    setLoading(true);

    await this.loadLandingRoute();
    await this.routeHandler();

    const currentRouteMetadata = this.router.getRouteMetaData();

    if (!currentRouteMetadata.loader) {
      this.store.set('loading', false);

      return;
    }

    intercom('update', { hide_default_launcher: true });

    this.store.set('loading', false);
    setLoading(false);

    toggleWhenLocationChanged();
    this.initAccordingHashState();
    this.showNotificationIfComeFromCronofy();
  }
}

export default ControllerMiddleware;
