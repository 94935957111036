import apollo from 'api';
import { observable } from 'decorators';
import { Auth } from 'services';
import { gaTrackEvent } from 'services/analytics.service';
import CollectivesService from 'services/data/collectives.service';
import { Router } from 'services/router.service';
import AcknowledgeMatchMutation from '../../graphql/mutations/acknowledgeMatch.graphql';
import RequestNewMatchMutation from '../../graphql/mutations/requestNewMatch.graphql';
import FindDirectChatQuery from '../../graphql/queries/findDirectChat.graphql';
import MatchesWithRequestsQuery from '../../graphql/queries/matchesWithRequests.graphql';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';
import DashMatchModule from './dash-match.module';

class DashMatchController {
  public module: DashMatchModule;
  @observable(null) public profile: any;
  @observable([]) public matches: any;
  @observable([]) public requests: any;
  @observable(0) public activeMatchIndex: number;
  @observable('') public associatedChatId: string;

  constructor() {
    this.handleMeetCommunity = this.handleMeetCommunity.bind(this);
    this.handleSchedule = this.handleSchedule.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.handleViewProfile = this.handleViewProfile.bind(this);
    this.handleActiveMatch = this.handleActiveMatch.bind(this);
  }

  public async OnInit() {
    this.watchProfile();
    this.watchMatches();
    this.getAssociatedChatId();
  }

  private watchProfile() {
    const observe = apollo.watchQuery({
      query: ProfileQuery,
    }).subscribe({
      next: ({ data: { profile } }) => {
        this.profile = profile;
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  private watchMatches() {
    const observe = apollo.watchQuery({
      query: MatchesWithRequestsQuery,
    }).subscribe({
      next: ({ data: { matchesWithRequests: { matches, requests } } }) => {
        this.matches = matches;
        this.requests = requests;

        this.activeMatchIndex = 0;
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public async getAssociatedChatId() {
    const { associatedChatId } = await CollectivesService.getActiveCollective();
    this.associatedChatId = associatedChatId;
  }

  public handleActiveMatch(event) {
    const { value } = event.target;

    if (!value) { return; }

    this.activeMatchIndex = value;
  }

  public async handleRequestMatch() {
    const { id: collectiveId } = await CollectivesService.getActiveCollective();

    await apollo.mutate({
      mutation: RequestNewMatchMutation,
      variables: {
        command: {
          collectiveId,
        },
      },
      refetchQueries: [{
        query: MatchesWithRequestsQuery,
      }],
    });
  }

  private acknowledgeMatch() {
    const { acknowledged, id } = this.matches[this.activeMatchIndex];

    if (acknowledged) { return; }

    apollo.mutate({
      mutation: AcknowledgeMatchMutation,
      variables: {
        id,
      },
    });
  }

  private async routeOrCreateChat(chatId: string, route: string) {
    const { individual } = this.matches[this.activeMatchIndex];
    let id = chatId;

    if (!id) {
      const { data: { findDirectChat: { chat } } } = await apollo.query({
        query: FindDirectChatQuery,
        variables: {
          createIfNotExists: true,
          participantIds: [individual.id, Auth.getUserId()],
        },
      });

      id = chat.id;
    }

    this.acknowledgeMatch();
    Router.go({ name: route, params: { chatId: id } });
  }

  public handleMeetCommunity() {
    gaTrackEvent({ category: 'dashboard:community', action: 'view', label: 'Get to know your community' });
    Router.go({
      name: 'chats.chat',
      params: {
        chatId: this.associatedChatId,
      },
    });
  }

  public handleViewProfile(id: string) {
    this.acknowledgeMatch();
    gaTrackEvent({ category: 'dashboard:match', action: 'view', label: 'View profile' });
    Router.go({ hash: { IndividualModal: true, userId: id } });
  }

  public handleSendMessage(chatId: string) {
    gaTrackEvent({ category: 'dashboard:match', action: 'message', label: 'Send a message' });
    this.routeOrCreateChat(chatId, 'chats.chat');
  }

  public handleSchedule(chatId: string) {
    gaTrackEvent({ category: 'dashboard:match', action: 'schedule', label: 'Schedule a catch-up' });
    this.routeOrCreateChat(chatId, 'chats.actions.create');
  }
}

export default DashMatchController;
