import uEmojiParser from 'universal-emoji-parser';
import styles from './emoji.module.scss';

export default (Quill) => {
  const Embed = Quill.import('blots/embed');

  class EmojiBlot extends Embed {
    public static create(value) {
      const node = document.createElement('span');

      node.classList.add(styles.emoji);

      if (typeof value === 'string') {
        const shortname = `:${value}:`;

        node.innerHTML = uEmojiParser.parse(shortname);

        node.dataset.name = value;
        node.dataset.shortname = shortname;
      } else {
        node.innerHTML = uEmojiParser.parse(value.shortname);

        node.dataset.name = value.name;
        node.dataset.shortname = value.shortname;
      }

      return node;
    }

    public static value(node) {
      return node.dataset.name;
    }
  }

  EmojiBlot.blotName = 'emoji';

  Quill.register({ 'formats/emoji-textarea': EmojiBlot }, true);
};
