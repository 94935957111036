import { render } from 'lit-html';
import CreditsLeaderboardController from 'modules/CreditsLeaderboard/credits-leaderboard.controller';
import CreditsLeaderboardView from 'modules/CreditsLeaderboard/credits-leaderboard.view';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';

@RouterModule({
  name: 'w-credits-leaderboards-module',
  view: CreditsLeaderboardView,
  controller: CreditsLeaderboardController,
})
class CreditsLeaderboardModule {
  public subs: Set<() => void> = new Set([]);
  public view: CreditsLeaderboardView;

  public async loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default CreditsLeaderboardModule;
