import { Icons } from 'components/lit';
import { Observe } from 'decorators';
import { IFeedItem } from 'interfaces';
import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import MarkdownIt from 'markdown-it';
import emoji from 'markdown-it-emoji';
import styles from 'modules/Activity/activity.module.scss';
import moment from 'moment';
import ActivityController from './activity.controller';
import BadgeItem from './fragments/badge.fragment';
import HighlightItem from './fragments/highlight.fragment';
import SimpleItem from './fragments/simple.fragment';

const md = new MarkdownIt();
md.use(emoji, []);

class ActivityTemplate {
  public controller: ActivityController;
  public simple: SimpleItem;
  public badge: BadgeItem;
  public highlight: HighlightItem;

  public noActivity() {
    return html`
      <div class="${styles.wrapper}">
        ${Icons.activity}

        <h1>There's no activity for this chat.</h1>
      </div>
    `;
  }

  public itemHead({ created, text }) {
    const momentDate = moment(created);
    const isToday = momentDate.isSame(new Date(), 'day');
    const formattedDate = isToday
      ? `Today, ${momentDate.format('HH:mm')}`
      : momentDate.format('DD MMM');

    return html`
      <div class=${styles.item_content}>
        ${text && html`<div class=${styles.item_text} .innerHTML=${md.render(text)}></div>`}
        ${created && html`<span class=${styles.item_date}>${formattedDate}</span>`}
      </div>
    `;
  }

  public social({ id, likes }, loading: boolean) {
    const { currentUserId, handleLikeItem } = this.controller;
    const hasLike = likes && likes.includes(currentUserId);
    const totalLikes = likes.length > 0 ? likes.length : '';

    return html`
      <div class=${styles.social_wrapper}>
        <a class=${styles.social_item} @click=${() => !loading && handleLikeItem(id)}>
          ${Icons.heartIcon({ fill: hasLike })}
          ${totalLikes}
        </a>
      </div>
    `;
  }

  @Observe('activityFeed', 'loading')
  public feedItems() {
    const { activityFeed, loading } = this.controller;
    const itemTemplates = {
      simple: this.simple.template,
      badge: this.badge.template,
      highlight: this.highlight.template,
    };

    return html`
    <div class=${styles.feed_list}>
      ${repeat(activityFeed, (item: IFeedItem) => item.id, (item: IFeedItem) => {
        if (!itemTemplates[item.type]) { return null; }
        const itemProps = {
          item,
          loading,
        };

        return itemTemplates[item.type](itemProps);
      })}
    </div>
    `;
  }

  get template() {
    return html`
      ${this.controller.hasActivityItems ? this.feedItems() : this.noActivity()}
  `;
  }
}

export default ActivityTemplate;
