import { loader } from 'components/lit';
import { Observe } from 'decorators';
import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import MatchBodyController from 'modules/MatchBody/match-body.controller';
import styles from './match-body.module.scss';

class MatchBodyTemplate {
  public controller: MatchBodyController;

  @Observe('matchName', 'matchLookingFor')
  get looking_for() {
    return html`
      <div class="${styles['looking-wrapper']}">
        <h1>What ${this.controller.matchName} is looking for</h1>

        <ul>
          ${repeat(this.controller.matchLookingFor, (item) => item, (item) => html`<li>${item}</li>`)}
        </ul>
      </div>
    `;
  }

  @Observe('loading')
  get button() {
    const { loading } = this.controller;

    return loading
      ? html`<div class="${styles.loading}">${loader}</div>`
      : html`<button @click=${() => this.controller.getStarted()}>Get started</button>`;
  }

  @Observe('matchName', 'matchCommitment')
  get commitment() {
    return html`
      <section>
        <div class="${styles['commitment-wrapper']}">
          <h1>${this.controller.matchName}'s promise</h1>

          <h2>${this.controller.matchName} has agreed to:</h2>

          <ul>
            ${repeat(this.controller.matchCommitment, (commit) => commit, (commit) => html`<li>${commit}</li>`)}
          </ul>

          ${this.button}
        </div>
      </section>
    `;
  }

  get template() {
    return html`
      <div class="${styles.wrapper}">
        ${this.looking_for}
        ${this.commitment}
      </div>
    `;
  }
}

export default MatchBodyTemplate;
