import apollo from 'api';
import { observable } from 'decorators';
import { IDashLeaderboard } from 'interfaces';
import { Auth } from 'services';
import { gaTrackEvent } from 'services/analytics.service';
import { Router } from 'services/router.service';
import LeaderboardsQuery from '../../graphql/queries/leaderboards.graphql';
import MatchesQuery from '../../graphql/queries/matches.graphql';
import DashLeaderboardModule from './dash-leaderboard.module';

class DashLeaderboardController {
  public module: DashLeaderboardModule;
  @observable([]) public leaderboards: IDashLeaderboard[];
  @observable(0) public activeLeaderboard: IDashLeaderboard;
  @observable(null) public match: any;

  constructor() {
    this.handleBoost = this.handleBoost.bind(this);
    this.handleActiveLeaderboard = this.handleActiveLeaderboard.bind(this);
  }

  public async OnInit() {
    this.fetchLeaders();
    this.fetchMatches();
  }

  private async fetchLeaders() {
    const { data: { leaderboards } } = await apollo.query({
      query: LeaderboardsQuery,
    });

    this.leaderboards = leaderboards;
    this.activeLeaderboard = leaderboards[0];
  }

  private async fetchMatches() {
    const { data: { matches } } = await apollo.query({
      query: MatchesQuery,
    });

    this.match = matches[0];
  }

  public getFormattedName(id, name: string) {
    const isMe = Auth.getUserId() === id;
    const splitName = name.split(' ');
    const youPart = isMe ? ' (you)' : '.';

    return `${splitName[0]} ${splitName[1][0]}${youPart}`;
  }

  public handleBoost() {
    gaTrackEvent({ category: 'dashboard:leaderboard', action: 'schedule' });
    Router.go({ name: 'chats.actions.create', params: { chatId: this.match.chatId } });
  }

  public handleActiveLeaderboard(event) {
    const { value } = event.target;

    if (!value) { return; }

    this.activeLeaderboard = this.leaderboards.find(({ type }) => type === value);
  }
}

export default DashLeaderboardController;
