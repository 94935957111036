import { Observe } from 'decorators';
import { IAchievement, ICategory } from 'interfaces';
import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import kebabCase from 'lodash/kebabCase';
import mapKeys from 'lodash/mapKeys';
import icons from './assets/icons';
import medals from './assets/medals';
import CreditsAchievementsController from './credits-achievements.controller';
import styles from './credits-achievements.module.scss';

const badges = mapKeys(icons, (value, key) => kebabCase(key));

class CreditsAchievementsView {
  public controller: CreditsAchievementsController;

  @Observe('categories', 'achievements', 'selected')
  public displayAchievements() {
    const { visibleAchievements, categories, selected } = this.controller;

    if (!visibleAchievements.length && categories.length) {
      const category = categories.find(({ id }) => id === selected.id);

      return this.emptyResult(category);
    }

    return html`
      <div class="${styles.list}">
        ${repeat(visibleAchievements, this.buildAchievement)}
      </div>
    `;
  }

  public emptyResult({ title, text, icon, button }: ICategory) {

    return html`
      <div class=${styles['empty-result']}>
        <div class=${styles.icon}>
          ${badges[icon]}
        </div>
        <h3 class=${styles.title}>${title}</h3>
        <div class=${styles.description}>${text}</div>
        <button
          @click=${this.controller.goToChat.bind(this)}
          class=${styles.button}
        >
          ${button ? button.text : 'TEXT'}
        </button>
      </div>
    `;
  }

  public buildAchievement({ title, text, badge, value }: IAchievement) {
    return html`
      <div class="${styles.achievement_card}">
        <div class=${styles.list_item__text}>
          <h1>${title}</h1>
          <p>${text}</p>
          <span>${value}</span>
        </div>

        <div class=${styles.icon}>
          <img src="${medals[badge]}" alt="Badge">
        </div>
      </div>
    `;
  }

  @Observe('categories', 'achievements')
  get categorySelect() {
    return html`
      <form @changeForm=${(evt) => this.controller.onChangeForm(evt)}>
        <w-box label="Filter by:">
          <w-select
            name="credits-achievements"
           .selected=${this.controller.selected}
            vRequired
           .options=${this.controller.categories}
          />
        </w-box>
      </form>
    `;
  }

  get template() {
    return html`
      <div class="credits-wrapper">
        <div class='credits-wrapper-background'></div>

        <div class=${'search-card'}>
          <h4 class=${styles.title}>Achievements</h4>
          ${this.categorySelect}
        </div>

        ${this.displayAchievements()}
      </div>
    `;
  }
}

export default CreditsAchievementsView;
