import { customElement } from 'components/custom/decorators';
import styles from 'components/custom/wwc-message-context.module.scss';
import { IMessage } from 'interfaces';
import { Router } from 'services/router.service';


/**
 * Events
 * @open - when context menu is opened
 * @close - when context menu is closed
 * @delete - Triggered when user click on delete message button
 * @report - Triggered when user click on delete message button
 *
 * Expected events from first child
 * @connected - Event which informed message context-menu that current component connected. Will be triggered only if
 *              parent has tag name `wwc-message-context`
 * */
@customElement({ name: 'wwc-message-context' })
class WWCMessageContext extends HTMLElement {
  public listId = 'messages-list';
  public message: IMessage;
  public messageView: HTMLDivElement | undefined;
  public menu: any;
  public timeout: any;
  public isMyMessage = false;
  public isMoving = false;

  get listElement() {
    return document.getElementById(this.listId);
  }

  get shareButton() {
    const shareButton = document.createElement('button');

    shareButton.innerText = 'Share via direct message';
    shareButton.setAttribute('disabled', 'true');

    return shareButton;
  }

  get copyLinkButton() {
    const copyLinkButton = document.createElement('button');

    copyLinkButton.innerText = 'Copy link to message';
    copyLinkButton.setAttribute('disabled', 'true');

    return copyLinkButton;
  }

  get speakMessageButton() {
    const speakMessageButton = document.createElement('button');

    speakMessageButton.innerText = 'Speak message';
    speakMessageButton.setAttribute('disabled', 'true');

    return speakMessageButton;
  }

  get muteButton() {
    const muteButton = document.createElement('button');

    muteButton.innerHTML = `Mute <b>${this.message.sender.name}</b>`;
    muteButton.setAttribute('disabled', 'true');

    return muteButton;
  }

  get deleteButton() {
    const deleteButton = document.createElement('button');

    deleteButton.innerText = 'Delete message';
    deleteButton.onclick = () => this.triggerEventAndClose('delete');

    return deleteButton;
  }

  get reportMessageButton() {
    const reportMessageButton = document.createElement('button');

    reportMessageButton.innerText = 'Report message';
    reportMessageButton.onclick = () => this.triggerEventAndClose('report');

    return reportMessageButton;
  }

  get menuTemplate(): HTMLDivElement {
    const menu = document.createElement('div');
    const section = document.createElement('section');

    menu.classList.add(styles.context_menu);

    section.appendChild(this.shareButton);
    section.appendChild(this.copyLinkButton);

    if (this.isMyMessage) {
      section.appendChild(this.deleteButton);
    } else {
      section.appendChild(this.muteButton);
    }

    section.appendChild(this.speakMessageButton);

    if (!this.isMyMessage) {
      section.appendChild(this.reportMessageButton);
    }

    menu.appendChild(section);

    return menu;
  }

  public connectedCallback() {
    if (!this.isConnected) { return; }

    this.firstElementChild.addEventListener('connected', (evt: CustomEvent) => {
      evt.stopPropagation();
      this.isMyMessage = evt.detail.isMyMessage;
      this.messageView = evt.detail.messageView;
      this.message = evt.detail.message;
      this.menu = this.menuTemplate;

      if (['iOS', 'Android OS'].includes(deviceDetectedInfo.os)) {
        this.messageView.oncontextmenu = null;
        this.handleIOSContext();
      } else {
        this.messageView.removeEventListener('touchmove', this.onTouchMove);
        this.messageView.removeEventListener('touchstart', this.onTouchStart);
        this.messageView.removeEventListener('touchend', this.onTouchEnd);

        this.messageView.oncontextmenu = this.onContextMenu;
      }
    });
  }

  private onTouchStart = (evt) => {
    if (!this.isMoving) {
      this.timeout = setTimeout(() => {
        this.onContextMenu(evt);
      }, 500);
    }
  }

  private onTouchEnd = (evt) => {
    this.isMoving = false;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  private onTouchMove = (evt) => {
    this.isMoving = true;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  private handleIOSContext() {
    this.messageView.addEventListener('touchmove', this.onTouchMove);
    this.messageView.addEventListener('touchstart', this.onTouchStart);
    this.messageView.addEventListener('touchend', this.onTouchEnd);
  }

  private addDocumentClickListeners() {
    this.listElement.addEventListener('scroll', this.closeMenu);

    document.addEventListener('click', this.onMissClick);
    document.addEventListener('contextmenu', this.onMissClick);
  }

  private clearDocumentClickListeners() {
    this.listElement.removeEventListener('scroll', this.closeMenu);

    document.removeEventListener('click', this.onMissClick);
    document.removeEventListener('contextmenu', this.onMissClick);
  }

  private onContextMenu = (evt) => {
    evt.preventDefault();

    if (Router.display === 'mobile') {
      this.openMobileMenu();
    } else {
      this.openDesktopMenu(evt);
    }

    window.addEventListener('resize', this.closeMenu);
  }

  private openMobileMenu() {
    const el = document.querySelector(`.${styles.backdrop}`);

    if (!!el) { return; }

    const mobileBackdrop = document.createElement('div');

    mobileBackdrop.classList.add(styles.backdrop);
    mobileBackdrop.classList.add(styles.backdrop_in);

    mobileBackdrop.onclick = () => {
      this.closeMenu();
    };

    mobileBackdrop.appendChild(this.menu);

    document.body.appendChild(mobileBackdrop);

    this.menu.classList.add(styles.context_in);
    this.sendEvent('open', { display: Router.display });
  }

  private openDesktopMenu(evt) {
    if (this.contains(this.menu)) {
      try {
        this.messageView.removeChild(this.menu);
        this.clearDocumentClickListeners();
      } catch (e) {
        this.clearDocumentClickListeners();
      }
    }

    const listClientHeight = this.listElement.clientHeight;
    const clientYOffset = evt.clientY || evt.detail.clientY;
    const targetOffsetLeft = evt.layerX || evt.target.offsetLeft;
    const targetOffsetTop = evt.layerY || evt.target.offsetTop;

    this.messageView.appendChild(this.menu);

    if (this.isMyMessage) {
      this.menu.style.right = `${this.clientWidth - targetOffsetLeft}px`;
    } else {
      this.menu.style.left = `${targetOffsetLeft}px`;
    }

    if ((clientYOffset + this.messageView.clientHeight) >= listClientHeight) {
      this.menu.style.bottom = `${this.messageView.clientHeight - targetOffsetTop}px`;
    } else {
      this.menu.style.top = `${targetOffsetTop}px`;
    }

    this.addDocumentClickListeners();
    this.sendEvent('open', { display: Router.display });
  }

  private onMissClick = (evt) => {
    if (!this.menu) { return; }

    if (!this.messageView.contains(evt.target)) {
      this.closeMenu();
    } else if (evt.type === 'contextmenu') {
      evt.preventDefault();
    }
  }

  private closeMenu = () => {
    if (Router.display === 'mobile') {
      const el = document.querySelector(`.${styles.backdrop}`);

      if (el) {
        el.classList.add(styles.backdrop_out);
        this.menu.classList.add(styles.context_out);

        setTimeout(() => {
          document.body.contains(el) && document.body.removeChild(el);
          this.menu.classList.remove(styles.context_out);
        }, 400);
      }
    } else {
      this.messageView && this.messageView.removeChild(this.menu);
      this.clearDocumentClickListeners();
    }

    window.removeEventListener('resize', this.closeMenu);
    this.sendEvent('close', { display: Router.display });
  }

  private sendEvent(name, detail?) {
    const openEvent = new CustomEvent(name, { bubbles: true, detail });

    this.dispatchEvent(openEvent);
  }

  private triggerEventAndClose(name) {
    this.sendEvent(name, { message: this.message });
    this.closeMenu();
  }
}

export default WWCMessageContext;
