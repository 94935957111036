import { html } from 'lit-html';
import styles from './not_joined.module.scss';
import { file, comment } from './icons.view';
import { button } from 'atoms/lit';
import { leaveJoinChat } from 'utils/chat-helpers';

const icons = {
  activity: file,
  chat: comment,
};

const texts = {
  activity: 'Find out about this group’s activity including meet ups, collaborations and other shared moments.',
  chat: 'Join the conversation and connect with others interested in this topic.',
};

export const NotJoined =  (type) =>
  html`
    <div class="${styles.wrapper}">
      ${icons[type]}

      <h1>Not a member yet?</h1>

      <span>${texts[type]}</span>

      ${button({ text: 'Join', onClick: () => leaveJoinChat.join() })}
    </div>
  `;
