import { Observe } from 'decorators';
import { html } from 'lit-html';
import cardStyles from 'styles/card.module.scss';
import DashStatsController from './dash-stats.controller';
import styles from './dash-stats.module.scss';

class DashStatsTemplate {
  public controller: DashStatsController;

  get empty() {
    return html`
      <div class=${styles.empty}>
        Your weekly stats will be ready soon.
      </div>
    `;
  }

  public statsList(stats) {
    return html`
      <ul class=${styles['stats-list']}>
        ${stats.map(({ key, value }) => html`
          <li>
            <span>${key}</span>
            <span>${value}</span>
          </li>
        `)}
      <ul>
    `;
  }

  public highlight(name, value) {
    return html`
      <div class=${styles.highlight}>
        <p>${name}</p>
        <p>${value}</p>
      </div>
    `;
  }

  public header({ position, points }) {
    const { profile: { general: { profileImageUrl, firstName, lastName } } } = this.controller;

    return html`
      <div class=${styles.header}>
        ${this.highlight('Position', position)}
        <w-avatar
          title=${`${firstName} ${lastName}`}
          avatar=${profileImageUrl}
          width="60px"
        > </w-avatar>
        ${this.highlight('Points', points)}
      </div>
    `;
  }

  public renderStats({ position, points, stats }) {
    return html`
      ${this.header({ position, points })}
      ${this.statsList(stats)}
    `;
  }

  @Observe('stats', 'profile')
  get template() {
    const { stats, profile } = this.controller;

    return html`
      <div class=${cardStyles.card}>
        <div class=${cardStyles.header}>
          <span class=${cardStyles.label}>Your weekly stats</span>
        </div>
        <div class=${cardStyles.content}>
          ${stats && profile ? this.renderStats(stats) : this.empty}
        </div>
      </div>
    `;
  }
}

export default DashStatsTemplate;
