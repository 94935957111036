import styles from 'components/custom/w-navigation.module.scss';
import { html, TemplateResult } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { repeat } from 'lit-html/directives/repeat';
import { customElement, observableProperty } from './decorators';
import Element from './Element';

@customElement({name: 'w-navigation'})
class WNavigation extends Element {
  @observableProperty() private dotsLength: number;
  @observableProperty() private active: number;
  private color: string = '#4cb49c';
  private onSelect(i) {
    this.dispatchEvent(new CustomEvent('select', { bubbles: true, detail: i}));
  }

  public connectedCallback() {
    super.connectedCallback();
    this.style.setProperty('--color', this.color);
  }

  private dot(i) {
    const classes = classMap({
      [styles.dot]: true,
      [styles.active]: i === this.active,
    });

    return html`<div class=${classes} @click=${() => this.onSelect(i)}></div>`;
  }

  get template(): TemplateResult {
    return html`
      <div class=${styles.wrapper}>
        ${repeat(new Array(this.dotsLength).fill(null), (_, i) => this.dot(i))}
      </div>`;
  }

  static get observedAttributes() {
    return ['dotsLength', 'active', 'color'];
  }

  public attributeChangedCallback(name, oldValue, newValue) {
    console.log(name, oldValue, newValue);
    this.update();
  }

}

export default WNavigation;
