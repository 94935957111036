import { render } from 'lit-html';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';
import ProfileSkillsAndInterestsController from './profile-skills-and-interests.controller';
import ProfileSkillsAndInterestsTemplate from './profile-skills-and-interests.view';

@RouterModule({
  name: 'w-profile-skills-and-interests-module',
  view: ProfileSkillsAndInterestsTemplate,
  controller: ProfileSkillsAndInterestsController,
})
class ProfileSkillsAndInterestsModule {
  public view: ProfileSkillsAndInterestsTemplate;
  public subs: Set<() => void> = new Set([]);

  public async loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default ProfileSkillsAndInterestsModule;
