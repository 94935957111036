import ErrorView from 'modules/Error';
import RouterError from 'services/router/router-error';
import { errorHandler } from 'utils/error-helpers';

const handleDefaultError = (e, defaultDescription) => {
  const error = {
    name: e.name,
    description: e.description || defaultDescription,
    message: e.message,
  };

  if (e instanceof RouterError) {
    return;
  }

  ErrorView.mount();

  if (error.message && !error.message.includes('GraphQL error')) {
    errorHandler(e, true);
  } else if (e.message) {
    console.log(error);
  }

  if (e.stack) {
    console.info(e.stack);
  }
};

const handlePromiseRejectionError = (e: PromiseRejectionEvent) => {
  if (e.type === 'unhandledrejection' ) {
    return;
  }

  ErrorView.mount();

  if (e.reason && !e.reason.hasOwnProperty('graphqlErrors')) {
    errorHandler(e, true);
  } else if (e.reason.message) {
    console.log(e.reason);
  }

  if (e.reason.stack) {
    console.info(e.reason.stack);
  }
};


export default (e, methodName, className) => {
  const defaultDescription = 'Error in method ' + methodName + ' of class ' + className;
  const handler = e instanceof PromiseRejectionEvent ? handlePromiseRejectionError : handleDefaultError;

  handler(e, defaultDescription);
};
