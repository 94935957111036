import apollo from 'api';
import SyncCalendarModal from 'components/lit/modals/sync-calendar.view';
import { observable } from 'decorators';
import ProfilesettingsModule from 'modules/ProfileSettings/profile-settings.module';
import { REST } from 'services';
import { Auth } from 'services';
import profileService from 'services/data/profile.service';
import { Router } from 'services/router.service';
import individualGeneralSettingsMutation from '../../graphql/mutations/setIndividualGeneralSettings.graphql';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';
import ProfileAndIndividualQuery from '../../graphql/queries/profile/ProfileAndIndividual.graphql';
import { settings } from './profile-settings.data';

class ProfileSettingsController {
  public module: ProfilesettingsModule;

  @observable([]) public settingsGroups;
  @observable([]) public settings;
  @observable({}) public individualSettings;

  public OnInit() {
    settings.forEach((option) =>
      this.settingsGroups = {
        ...this.settingsGroups,
        [option.title]: {
          next: true,
          loading: false,
        },
      });

    const observe = apollo.watchQuery({
      query: ProfileAndIndividualQuery,
      variables: {
        id: Auth.getUserId(),
      },
    }).subscribe({
      next: ({ data: { profile, individual } }) => {
        this.settings = profile.settings;
        this.individualSettings = individual;
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public onSaveSettings(blockName) {
    if (blockName === 'Calendar') {
      // FIXME such mutation no more needed in this case
      //  as well as save button, because calendar access requested by cronofy
      // return this.updateSettings(blockName, authorizeCalendarAccess, { token: auth.getToken() });
      return;
    }
    this.updateSettings(blockName, individualGeneralSettingsMutation, { settings: this.settings });
  }

  public onLogOut() {
    Router.go({ hash: { LogoutModal: true } });
  }

  public updateProfileOption(targetKey) {
    const { value } = this.settings.find((option) => option.key === targetKey);
    const isEnabled = value === 'true';

    const settings = [
      ...this.settings,
    ];

    if (settings.some((item) => item.key === targetKey)) {
      settings.forEach((item, index) => {
        if (item.key === targetKey) {
          settings[index] = {
            ...item,
            value: !isEnabled,
          };
        }
      });
    } else {
      settings.push({
        key: targetKey,
        value: !isEnabled,
      });
    }

    this.settings = settings;
  }

  public async toggleCalendarAccess() {
    if (!this.individualSettings.authorizedCalendarAccess) {
        return new SyncCalendarModal().open();
    }

    await REST.revokeCalendarAccess();
    await profileService.getProfile();
  }

  public updateIndividualProfileOption(targetKey) {
    if (targetKey === 'authorizedCalendarAccess') {
      this.toggleCalendarAccess();
    }

    const value = this.individualSettings[targetKey];

    this.individualSettings = {
      ...this.individualSettings,
      [targetKey]: !value,
    };
  }

  public async updateSettings(blockName, mutation, payload) {
    this.settingsGroups = {
      ...this.settingsGroups,
      [blockName]: {
        next: false,
        loading: true,
      },
    };

    await apollo.mutate({
      mutation,
      variables: {
        command: payload,
      },
      refetchQueries: [{
        query: ProfileQuery,
        variables: {
          id: Auth.getUserId(),
        },
      }],
    });

    this.settingsGroups = {
      ...this.settingsGroups,
      [blockName]: {
        next: true,
        loading: false,
      },
    };
  }
}

export default ProfileSettingsController;
