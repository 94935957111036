import apollo from 'api';
import { isAfter, isFuture, isThisWeek, isToday, parseISO } from 'date-fns';
import { observable } from 'decorators';
import ActionsQuery from '../../graphql/queries/actions.graphql';
import DashUpcomingEventsModule from './dash-upcoming-events.module';

class DashUpcomingEventsController {
  public module: DashUpcomingEventsModule;
  @observable(null) public calendarConnected: boolean;
  @observable({}) public groupedEvents: { [key: string]: any[] };

  public statusTypes = {
    pending: 'Invited',
    accepted: 'Going',
    declined: 'Declined',
  };

  public async OnInit() {
    this.watchEvents();
  }

  private watchEvents() {
    const observe = apollo
      .watchQuery({
        query: ActionsQuery,
      })
      .subscribe({
        next: ({ data: { actions: eventsData } }) => {
          const parsedAndSortedEvents = eventsData
            .map((event) => ({ ...event, data: JSON.parse(event.data) }))
            .sort((a, b) => (isAfter(new Date(a.data.startTime), new Date(b.data.startTime)) ? 1 : -1));

          this.setGroupedEvents(parsedAndSortedEvents);
        },
        error: (e) => console.error(e),
      });

    this.module.subs.add(() => observe.unsubscribe());
  }

  private setGroupedEvents(events: any[]) {
    this.groupedEvents = events.reduce(
      (acc, action) => {
        const accSize = Object.values(acc).reduce<any[]>((r: any[], k: any[]) => r.concat(k), []).length;
        const { startTime, endTime } = action.data;
        const start = parseISO(startTime);
        const end = parseISO(endTime);

        if (!isFuture(end) || accSize >= 3) {
          return acc;
        }

        if (isToday(start)) {
          acc.Today.push(action);

          return acc;
        }

        if (!isToday(start) && isThisWeek(start)) {
          acc['This Week'].push(action);

          return acc;
        }

        acc.Future.push(action);

        return acc;
      },
      { 'Today': [], 'This Week': [], 'Future': [] }
    );
  }
}

export default DashUpcomingEventsController;
