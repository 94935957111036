import FullScreenModal from 'components/lit/modals/full-screen-modal.view';
import { AutonomousModal } from 'decorators';
import { html } from 'lit-html';

@AutonomousModal('ShareGoals')
class ShareGoals extends FullScreenModal {
  public text: any;
  public title = 'Back to your profile';

  public onOpen() {
    this.text = this.body;
    this.template = this.wrap(this.tmp);
  }

  get body() {
    return html`<w-shared-goals-module .destroy=${() => this.destroy()}> </w-shared-goals-module>`;
  }
}

export default ShareGoals;
