/* tslint:disable:max-line-length */

import { svg } from 'lit-html';

const all = svg`
  <svg width="630px" height="615px" viewBox="0 0 630 615" version="1.1">
      <!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com -->
      <title>sign-on</title>
      <desc>Created with Sketch.</desc>
      <defs>
          <filter x="-15.8%" y="-16.3%" width="131.5%" height="132.5%" filterUnits="objectBoundingBox" id="filter-1">
              <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
              <feGaussianBlur stdDeviation="25" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
              <feColorMatrix values="0 0 0 0 1   0 0 0 0 0.784313725   0 0 0 0 0.4  0 0 0 1 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
              <feMerge>
                  <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                  <feMergeNode in="SourceGraphic"></feMergeNode>
              </feMerge>
          </filter>
          <linearGradient x1="23.4384353%" y1="10.3281494%" x2="74.5947147%" y2="89.6718506%" id="linearGradient-2">
              <stop stop-color="#FFFFFF" offset="0%"></stop>
              <stop stop-color="#000000" offset="100%"></stop>
          </linearGradient>
          <path d="M552.248031,284.752304 L453.780031,114.224304 C445.806031,100.422304 431.097031,91.9323044 415.173031,91.9323044 L218.246031,91.9323044 C202.323031,91.9323044 187.618031,100.422304 179.638031,114.224304 L81.1760309,284.752304 C73.2150309,298.553304 73.2150309,315.537304 81.1760309,329.340304 L179.638031,499.883304 C187.618031,513.685304 202.323031,522.176304 218.246031,522.176304 L415.172031,522.176304 C431.097031,522.176304 445.806031,513.685304 453.779031,499.883304 L552.248031,329.340304 C560.208031,315.537304 560.208031,298.553304 552.248031,284.752304 Z" id="path-3"></path>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-5">
              <stop stop-color="#FF77BF" offset="0%"></stop>
              <stop stop-color="#E55083" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="100%" y1="0.994408424%" x2="7.41089324%" y2="94.277249%" id="linearGradient-6">
              <stop stop-color="#9003BE" offset="0%"></stop>
              <stop stop-color="#43B395" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="92.8066733%" y1="14.3227108%" x2="7.77489958%" y2="91.499691%" id="linearGradient-7">
              <stop stop-color="#410161" offset="0%"></stop>
              <stop stop-color="#43B395" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="92.8066733%" y1="13.658293%" x2="7.77489958%" y2="92.2725393%" id="linearGradient-8">
              <stop stop-color="#410161" offset="0%"></stop>
              <stop stop-color="#43B395" offset="100%"></stop>
          </linearGradient>
      </defs>
      <g id="sign-on" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Group-2-Copy" transform="translate(-4.000000, 0.000000)">
              <g id="Colour/-Pink-Gradient" filter="url(#filter-1)">
                  <mask id="mask-4" fill="white">
                      <use xlink:href="#path-3"></use>
                  </mask>
                  <use id="Mask" fill="url(#linearGradient-2)" fill-rule="nonzero" transform="translate(316.711656, 307.054304) rotate(-330.000000) translate(-316.711656, -307.054304) " xlink:href="#path-3"></use>
                  <g mask="url(#mask-4)" fill="url(#linearGradient-5)" id="Rectangle-Copy">
                      <g transform="translate(72.000000, 64.000000)">
                          <rect x="0" y="0" width="490" height="500"></rect>
                      </g>
                  </g>
                  <g id="Group-4" opacity="0.393391927" mask="url(#mask-4)" stroke="#FFCEE8" stroke-width="4">
                      <g transform="translate(34.000000, 0.000000)">
                          <polyline id="Path-38" points="624 31.9084262 518.959029 99 481.895319 0 458 95.7597493"></polyline>
                          <path d="M357,31 L413,125" id="Path-39"></path>
                          <polyline id="Path-40" points="398 227 438.346859 286.27966 497.386917 230.455765 546 310"></polyline>
                          <polyline id="Path-43" points="319 18.2800863 253.025147 70 206.469438 14 150.936481 50.148408 106 16.583918"></polyline>
                          <path d="M4,58.016556 C4,58.016556 79.2615055,55.209134 81.7232769,122.710496 C84.1850484,190.211858 111.2111,192.256771 159,196" id="Path-44"></path>
                          <path d="M260.612832,149 C260.612832,149 249.594284,101.384793 321,97" id="Path-45"></path>
                          <polyline id="Path-46" points="341 189 193 204.27379 194.540583 328"></polyline>
                          <polyline id="Path-47" points="291 311 413.969661 333.410056 424.538001 456.059688 516 474"></polyline>
                          <path d="M246,567 C246,567 257.193014,502.697674 361,427" id="Path-50"></path>
                          <path d="M108,413 L258,403" id="Path-51"></path>
                          <polyline id="Path-52" points="57.293535 230 116 337.332659 0 296.514981 28.9689466 377"></polyline>
                          <polyline id="Path-53" points="97.2333858 482 18 545.847672 116 595"></polyline>
                          <path d="M207,476 C207,476 114.573831,503.106925 173.830885,586" id="Path-54"></path>
                      </g>
                  </g>
              </g>
              <path d="M515.668808,288.186109 L432.493065,143.91404 C425.757442,132.237112 413.332776,125.054304 399.881802,125.054304 L233.53792,125.054304 C220.08779,125.054304 207.666503,132.237112 200.925812,143.91404 L117.755138,288.186109 C111.030495,299.86219 111.030495,314.23119 117.755138,325.908964 L200.925812,470.193723 C207.666503,481.87065 220.08779,489.054304 233.53792,489.054304 L399.880957,489.054304 C413.332776,489.054304 425.757442,481.87065 432.492221,470.193723 L515.668808,325.908964 C522.392605,314.23119 522.392605,299.86219 515.668808,288.186109 Z" id="Path-Copy-2" fill="#FFFFFF" fill-rule="nonzero" opacity="0.223599" transform="translate(316.711656, 307.054304) rotate(-330.000000) translate(-316.711656, -307.054304) "></path>
              <g id="quill-drawing-a-line" transform="translate(193.000000, 103.000000)" fill-rule="nonzero">
                  <path d="M17.421,317.462 L21.843,299.801 L9.649,294.987 L1.273,308.791 C1.273,308.791 5.891,321.317 0.762,331.33 C0.766,331.331 7.184,320.399 17.421,317.462 Z M9.229,309.358 C10.73,308.743 12.46,309.445 13.08,310.919 C13.705,312.393 12.993,314.09 11.492,314.705 C9.991,315.32 8.261,314.618 7.641,313.144 C7.01,311.664 7.723,309.967 9.229,309.358 Z" id="Shape" fill="#3B3B3B"></path>
                  <path d="M145.353,112.621 C110.238,141.421 64.267,200.745 25.28,270.044 L25.258,270.017 L18.443,282.043 L25.71,284.839 L29.55,274.722 C50.349,237.231 106.774,139.322 209.947,74.271 C209.947,74.271 248.358,51.394 286.203,19.755 C276.989,27.457 258.812,37.111 248.956,43.339 C223.088,59.683 191.166,75.043 145.353,112.621 Z" id="Path" fill="url(#linearGradient-6)"></path>
                  <path d="M20.2,215.365 C12.215,243.588 12.672,265.083 15.762,270.99 L20.592,270.99 C33.929,243.365 98.164,143.297 138.146,111.974 C179.57,79.519 211.524,60.635 238.399,46.863 C247.836,42.028 257.517,35.479 266.247,28.914 C276.848,20.554 287.595,11.612 297.005,2.861 L269.728,20.75 L281.89,2.148 L258.67,25.759 L273.78,0 C238.034,3.225 204.862,21.109 204.862,21.109 C191.591,36.85 180.903,61.891 180.903,61.891 C180.533,49.37 189.013,30.41 189.013,30.41 C182.747,33.271 158.94,46.869 158.94,46.869 C147.295,56.529 143.678,81.929 143.678,81.929 C141.464,71.91 149.204,52.596 149.204,52.596 C115.661,71.916 91.702,104.827 91.702,104.827 C75.118,137.38 88.754,162.78 83.592,156.699 C78.43,150.618 79.54,128.438 79.54,128.438 C44.523,162.068 40.841,178.162 40.841,178.162 C34.945,192.472 29.783,230.752 29.783,230.752 C26.465,227.173 29.783,207.141 29.783,207.141 C21.304,225.03 25.361,241.842 25.361,241.842 C21.309,240.407 20.2,215.365 20.2,215.365 Z" id="Path" fill="url(#linearGradient-7)"></path>
                  <path d="M297.01,14.191 C297.01,14.191 283.527,27.256 266.252,40.244 C239.171,61.603 213.096,79.063 213.096,79.063 C110.945,139.425 54.025,237.932 35.212,271.708 L45.214,271.708 C48.749,268.874 54.058,266.737 76.228,260.319 C104.239,252.209 120.948,235.278 120.948,235.278 C120.948,235.278 95.395,249.588 83.353,248.158 C71.311,246.728 55.13,251.258 55.13,251.258 C55.13,251.258 48.951,248.397 79.421,243.866 C109.891,239.335 160.017,205.232 160.017,205.232 C160.017,205.232 140.85,213.102 132.006,212.384 C123.162,211.666 101.292,212.384 101.292,212.384 C115.787,209.044 129.303,210.954 151.418,202.605 C173.533,194.256 172.304,194.974 172.304,194.974 C197.367,186.625 207.778,160.632 207.778,160.632 C203.443,162.302 170.335,166.354 156.602,162.302 C142.868,158.25 119.47,162.302 119.47,162.302 C141.585,154.91 146.502,158.25 151.903,158.011 C157.309,157.772 174.758,159.202 209.405,147.28 C244.052,135.358 253.88,120.569 253.88,120.569 L230.514,123.691 C245.771,121.124 262.969,111.029 262.969,111.029 C252.401,113.89 235.937,115.32 235.937,115.32 C255.349,111.029 266.162,105.067 266.162,105.067 C284.345,91.235 288.522,70.725 288.522,70.725 C262.719,79.547 242.328,75.495 242.328,75.495 C277.715,73.113 287.543,64.046 292.454,61.903 C297.371,59.755 299.394,50.873 299.394,50.873 C281.516,57.313 261.244,58.384 261.244,58.384 C283.174,54.985 301.966,43.894 301.966,43.894 L301.966,32.792 C293.487,37.622 278.567,41.38 278.567,41.38 L301.786,26.357 C303.091,18.841 297.01,14.191 297.01,14.191 Z" id="Path" fill="url(#linearGradient-8)"></path>
                  <polygon id="Path" fill="#3B3B3B" points="10.551 290.571 24.361 296.103 26.933 289.989 13.124 284.458"></polygon>
                  <path d="M164.036,285.458 C118.408,307.394 74.574,322.346 16.278,324.304 C10.839,324.489 10.812,329.928 16.278,329.743 C68.428,327.992 111.821,316.782 153.669,297.168 C200.287,275.314 243.104,257.001 301.497,250.778 C306.882,250.201 304.592,244.964 299.245,245.535 C247.531,251.051 205.514,265.519 164.036,285.458 Z" id="Path" fill="#400160"></path>
              </g>
          </g>
      </g>
  </svg>
`;

const streakAwards = svg`
    <svg id="icons_award-streak" viewBox="0 0 350 350">
        <g id="icons_Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="icons_Group-2-Copy" transform="translate(29.000000, 29.000000)">
                <g id="icons_Colour/-White-Copy-11" filter="url(#icons_filter-award-streak-1)">

                <g id="icons_Mask" fill="#D8D8D8"><circle id="icons_path-award-streak-2" cx="146.5" cy="146.5" r="146.5"></circle></g>
                <g id="icons_Colour/-Yellow-Gradient-" mask="url(#icons_mask-award-streak-3)" fill="url(#icons_linearGradient-award-streak-4)">
                    <g transform="translate(-0.000000, -0.000000)" id="icons_Rectangle-Copy">
                    <rect x="0" y="0" width="293" height="293"></rect>
                    </g>
                </g>
                </g>
                <g id="icons_flash" transform="translate(87.000000, 39.000000)" fill="#FFFFFF" fill-rule="nonzero">
                <path d="M121.664008,73.9737054 C121.075734,72.721064 119.808781,71.9171051 118.413156,71.9171051 L75.8926434,71.9171051 L117.856841,6.21264816 C118.556545,5.11679549 118.599436,3.72904904 117.968272,2.59399921 C117.336688,1.45519646 116.130706,0.750481464 114.824647,0.750481464 L57.4131243,0.750481464 C56.0532416,0.750481464 54.8115181,1.51190725 54.2017995,2.71826256 L0.378365618,109.46799 C-0.177950031,110.567595 -0.116978173,111.876947 0.536051459,112.926514 C1.19286555,113.976081 2.34460294,114.616579 3.58969034,114.616579 L40.4873363,114.616579 L0.281231139,209.310676 C-0.404176652,210.929852 0.205962431,212.808397 1.71638255,213.726612 C2.29750743,214.078969 2.94338864,214.249519 3.58590588,214.249519 C4.61569955,214.249519 5.63119637,213.811678 6.33468547,212.986452 L121.158151,77.7699925 C122.058432,76.7100011 122.252281,75.2296826 121.664008,73.9737054 Z" id="icons_Path"></path>
                </g>
            </g>
        </g>
    </svg>
`;

const leaderboardAwards = svg`
    <svg id="icons_award-leaderboard" viewBox="0 0 293 293">
        <g id="icons_Leaderboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="icons_Group-Copy" transform="translate(28.000000, 28.000000)">
                <g id="icons_Colour/-White-Copy-11" filter="url(#icons_filter-award-leaderboard-1)">

                <g id="icons_Mask" fill="#D8D8D8"><circle id="icons_path-award-leaderboard-2" cx="118.5" cy="118.5" r="118.5"></circle></g>
                <g id="icons_Colour/-Teal-Gradient" mask="url(#icons_mask-award-leaderboard-3)" fill="url(#icons_linearGradient-award-leaderboard-4)">
                    <g transform="translate(-0.000000, -0.000000)" id="icons_Rectangle-Copy">
                    <rect x="0" y="0" width="237" height="237"></rect>
                    </g>
                </g>
                </g>
                <g id="icons_rank" transform="translate(45.615063, 44.623431)" fill="#FFFFFF" fill-rule="nonzero">
                <path d="M95.1530645,142.462077 L95.1530645,90.5822932 C95.1530645,88.2064106 93.2409733,86.2828636 90.8792404,86.2828636 L56.6886478,86.2828636 C54.3269149,86.2828636 52.4148237,88.2064106 52.4148237,90.5822932 L52.4148237,142.462077 C52.4148237,144.837959 54.3269149,146.761506 56.6886478,146.761506 L90.8792404,146.761506 C93.2409733,146.761506 95.1530645,144.837959 95.1530645,142.462077 Z" id="icons_Path"></path>
                <path d="M142.435882,120.957285 L107.542508,120.957285 C105.15215,120.957285 103.216884,122.860258 103.216884,125.210728 L103.216884,142.508063 C103.216884,144.858533 105.15215,146.761506 107.542508,146.761506 L142.435882,146.761506 C144.82624,146.761506 146.761506,144.858533 146.761506,142.508063 L146.761506,125.210728 C146.761506,122.860258 144.82624,120.957285 142.435882,120.957285 Z" id="icons_Path"></path>
                <path d="M0,142.435882 C0,144.82624 1.93526665,146.761506 4.32562478,146.761506 L39.218998,146.761506 C41.6093561,146.761506 43.5446227,144.82624 43.5446227,142.435882 L43.5446227,107.542508 C43.5446227,105.15215 41.6093561,103.216884 39.218998,103.216884 L4.32562478,103.216884 C1.93526665,103.216884 0,105.15215 0,107.542508 L0,142.435882 Z" id="icons_Path"></path>
                <path d="M64.5105522,8.87020093 C64.5105522,13.760361 68.490593,17.7404019 73.3807531,17.7404019 C78.2709132,17.7404019 82.2509541,13.760361 82.2509541,8.87020093 C82.2509541,3.98004082 78.2709132,0 73.3807531,0 C68.490593,0 64.5105522,3.98004082 64.5105522,8.87020093 Z" id="icons_Path"></path>
                <path d="M25.8042209,17.7404019 C25.8042209,22.630562 29.7842617,26.6106028 34.6744218,26.6106028 C39.5645819,26.6106028 43.5446227,22.630562 43.5446227,17.7404019 C43.5446227,12.8502418 39.5645819,8.87020093 34.6744218,8.87020093 C29.7842617,8.87020093 25.8042209,12.8502418 25.8042209,17.7404019 Z" id="icons_Path"></path>
                <path d="M120.957285,17.7404019 C120.957285,12.8502418 116.977245,8.87020093 112.087084,8.87020093 C107.196924,8.87020093 103.216884,12.8502418 103.216884,17.7404019 C103.216884,22.630562 107.196924,26.6106028 112.087084,26.6106028 C116.977245,26.6106028 120.957285,22.630562 120.957285,17.7404019 Z" id="icons_Path"></path>
                <path d="M103.365351,73.9511024 L111.280703,33.9749073 C107.796203,33.8189912 104.593595,32.6400865 101.948059,30.6995491 L91.0952126,47.0247418 L79.7099025,24.1914571 C77.7467254,24.9755241 75.6202294,25.4376636 73.3807531,25.4376636 C71.1412769,25.4376636 69.0147809,24.9755241 67.0516038,24.1914571 L55.6662937,47.0247418 L44.8134468,30.6995491 C42.1679113,32.6400865 38.9653034,33.8189912 35.4808037,33.9749073 L43.3961558,73.9511024 C43.7988588,75.9611853 45.5606844,77.4126627 47.6077584,77.4126627 L99.1537479,77.4126627 C101.200822,77.4126627 102.962647,75.9611853 103.365351,73.9511024 L103.365351,73.9511024 Z" id="icons_Path"></path>
                </g>
            </g>
        </g>
    </svg>
`;

const reciprocityAwards = svg`
    <svg id="icons_award-reciprocity" viewBox="0 0 293 293">
      <g id="icons_Reciprocity" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="icons_Group-Copy" transform="translate(27.000000, 27.000000)">
          <g id="icons_Colour/-White-Copy-11" filter="url(#icons_filter-award-reciprocity-1)">

            <g id="icons_Mask" fill="#D8D8D8"><circle id="icons_path-award-reciprocity-2" cx="119.5" cy="119.5" r="119.5"></circle></g>
            <g id="icons_Colour/-Red-Gradient" mask="url(#icons_mask-award-reciprocity-3)" fill="url(#icons_linearGradient-award-reciprocity-4)">
              <g transform="translate(-0.000000, -0.000000)" id="icons_Rectangle-Copy">
                <rect x="0" y="0" width="239" height="239"></rect>
              </g>
            </g>
          </g>
          <g id="icons_deal" transform="translate(36.000000, 50.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <path d="M37.610397,78.1999996 L20.4421125,95.4615499 C17.597581,98.321745 17.597581,102.959556 20.4421125,105.819751 C23.2866443,108.678675 27.8990571,108.678675 30.7435886,105.819751 L47.9106092,88.5569299 C50.755141,85.6967348 50.755141,81.0589242 47.9106092,78.1999996 C45.0660777,75.3398045 40.4549288,75.3398045 37.610397,78.1999996 L37.610397,78.1999996 Z" id="icons_Path"></path>
            <path d="M54.6986321,95.3817538 L37.530348,112.644059 C34.6858162,115.504169 34.6858162,120.140571 37.530348,123.00068 C40.3748798,125.86079 44.9860286,125.86079 47.8305601,123.00068 L64.9988445,105.739645 C67.8433762,102.879536 67.8433762,98.2418635 64.9988445,95.3817538 C62.1543127,92.521644 57.5431639,92.521644 54.6986321,95.3817538 L54.6986321,95.3817538 Z" id="icons_Path"></path>
            <path d="M71.785604,112.563572 L54.6185833,129.825877 C51.7740515,132.685987 51.7740515,137.322389 54.6185833,140.182498 C57.4631151,143.042608 62.0742639,143.042608 64.9187957,140.182498 L82.08708,122.920193 C84.9316115,120.060083 84.9316115,115.423681 82.08708,112.563572 C79.2425482,109.703462 74.6301355,109.703462 71.785604,112.563572 L71.785604,112.563572 Z" id="icons_Path"></path>
            <path d="M78.2463699,150.787957 C81.1318469,153.689273 85.810652,153.689273 88.696129,150.787957 L99.1446064,140.282126 C102.030083,137.38081 102.030083,132.677611 99.1446064,129.776295 C96.2591294,126.874979 91.581606,126.874979 88.696129,129.776295 L78.2463699,140.282126 C75.362175,143.183442 75.362175,147.886641 78.2463699,150.787957 L78.2463699,150.787957 Z" id="icons_Path"></path>
            <path d="M131.688848,45.0955038 L158.319216,71.9639442 C168.889269,54.9292128 168.420696,31.742201 153.733261,16.9234629 L150.301688,13.4612125 C133.093304,-3.90101217 103.99377,-3.1338884 87.0758758,13.9339744 L59.6220308,41.633253 C57.7010069,43.5714509 57.7010069,46.6182821 59.6220308,48.5577543 C65.5934979,54.5826054 74.9965376,55.4835297 81.9973503,50.7737476 L111.098148,31.2452271 C113.019172,29.3070295 116.039007,29.3070295 117.961294,31.2452271 C119.540044,32.8380919 121.392866,34.0155374 123.521023,34.638666 C128.531346,36.5093262 134.433348,35.4006926 138.551993,31.2452271 C140.473017,29.3070295 143.492852,29.3070295 145.415139,31.2452271 C147.337426,33.1859734 147.337426,36.2315308 145.415139,38.1710026 C141.571828,42.0486723 136.630969,44.4022891 131.688848,45.0955038 Z" id="icons_Path"></path>
            <path d="M21.9550626,17.0624346 L15.0769067,23.9871075 C-2.01848845,41.1981185 -5.45756642,65.6396408 11.6365627,82.8493775 L15.400942,86.6379408 L30.8774259,71.0567891 C37.5138213,64.375512 48.3145769,64.375512 54.952238,71.0567891 C58.3204333,74.449038 59.9608621,78.9219128 59.9102316,83.3782218 C64.336605,83.3259743 68.7781673,84.9774974 72.1476285,88.3697463 C72.7083613,88.9342716 107.91809,124.382124 106.539675,122.994386 C109.388907,125.862888 114.008942,125.862888 116.858174,122.994386 C119.707407,120.125884 119.707407,115.474604 116.858174,112.607376 L84.1850344,79.7132675 C82.2851242,77.8017828 82.2851242,74.7013539 84.1850344,72.7885946 C86.0849446,70.8758353 89.1645459,70.8758353 91.0631907,72.7885946 L123.736331,105.682703 C126.585563,108.549931 131.205598,108.549931 134.053565,105.682703 C136.902797,102.812927 136.902797,98.1629212 134.053565,95.2944192 L101.381691,62.4015849 C99.4817801,60.4888256 99.4817801,57.388397 101.381691,55.4756377 C103.281601,53.5628781 106.361202,53.5641523 108.259847,55.4756377 L140.932987,88.3697463 C143.782219,91.2382482 148.400988,91.2382482 151.250221,88.3697463 C154.099453,85.5012443 154.099453,80.8499643 151.250221,77.9827366 L114.583598,41.0681377 L87.9228325,58.921408 C76.8322173,66.3672791 62.0556999,64.3602198 53.2320663,55.4769119 C47.5234751,49.7284392 47.5234751,40.4488167 53.2320663,34.7016183 L76.9600593,10.8131529 C59.7747952,-3.00306107 37.8720324,1.03781836 21.9550626,17.0624346 Z" id="icons_Path"></path>
          </g>
        </g>
      </g>
    </svg>
`;

const inclusivenessAwards = svg`
    <svg id="icons_award-inclusiveness" viewBox="0 0 293 293">
        <g id="icons_inclusiveness" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="icons_Group-Copy" transform="translate(27.000000, 27.000000)">
                <g id="icons_Colour/-White-Copy-11" filter="url(#icons_filter-award-inclusiveness-1)">

                <g id="icons_Mask" fill="#D8D8D8"><circle id="icons_path-award-inclusiveness-2" cx="119.5" cy="119.5" r="119.5"></circle></g>
                <g id="icons_Colour/-Dpurple-gradient" mask="url(#icons_mask-award-inclusiveness-3)" fill="url(#icons_linearGradient-award-inclusiveness-4)">
                    <g transform="translate(-0.000000, -0.000000)" id="icons_Rectangle-Copy">
                    <rect x="0" y="0" width="239" height="239"></rect>
                    </g>
                </g>
                </g>
                <g id="icons_meeting-(1)" transform="translate(37.000000, 34.000000)" fill="#FFFFFF" fill-rule="nonzero">
                <ellipse id="icons_Oval" cx="83" cy="14.71875" rx="14.6470588" ry="14.71875"></ellipse>
                <path d="M106.770292,20.4427083 C104.308375,31.2975836 94.6073632,39.4445829 82.9998374,39.4445829 C71.3923116,39.4445829 61.6912998,31.2975836 59.2293831,20.4427083 C50.4980498,22.3848351 43.9411765,30.1504206 43.9411765,39.4445829 L43.9411765,58.939861 C43.9411765,64.3134138 48.3229394,68.6875 53.705923,68.6875 C59.0889066,68.6875 63.4706695,64.3134138 63.4706695,58.939861 L63.4706695,49.192222 L102.52933,49.192222 L102.52933,58.939861 C102.52933,64.3134138 106.911093,68.6875 112.294077,68.6875 C117.677061,68.6875 122.058824,64.3134138 122.058824,58.939861 L122.058824,39.4445829 C122.058498,30.1504206 115.5013,22.3848351 106.770292,20.4427083 Z" id="icons_Path"></path>
                <path d="M158.492912,119.724347 C151.546328,115.660294 142.664567,118.071477 138.653959,125.110629 C134.643673,132.149127 137.02347,141.149897 143.970054,145.213296 C150.915993,149.277348 160.121143,146.865512 164.131107,139.827014 C168.141715,132.788189 165.438851,123.788072 158.492912,119.724347 Z" id="icons_Path"></path>
                <path d="M149.024134,86.3599467 L132.256992,76.5420547 C127.634986,73.8359142 121.701204,75.4484169 119.032839,80.1358563 C116.364475,84.8232957 117.954468,90.8410825 122.576474,93.547223 L130.960367,98.4560055 L111.598686,132.466015 L103.214792,127.557233 C98.5927864,124.851092 92.6590039,126.463595 89.9906394,131.151034 C87.3222749,135.838474 88.9122681,141.85626 93.5342739,144.562401 L110.301416,154.380293 C118.295871,159.060866 128.225269,157.262004 134.223206,150.637403 C126.107535,143.027385 123.908883,130.477523 129.662775,120.369956 C135.416668,110.262389 147.232652,105.918898 157.789407,109.241334 C160.447455,100.661263 157.018589,91.0405202 149.024134,86.3599467 Z" id="icons_Path"></path>
                <path d="M27.3460509,125.110656 C23.3354622,118.071617 14.4537445,115.660146 7.50719471,119.72446 C0.561289727,123.788121 -2.1418838,132.788093 1.8690273,139.827132 C5.87897115,146.865517 15.0840766,149.277315 22.0299816,145.213328 C28.9765314,141.14934 31.3563172,132.149042 27.3460509,125.110656 Z" id="icons_Path"></path>
                <path d="M76.0093466,131.150825 C73.3409767,126.463391 67.4068601,124.851217 62.7851675,127.557027 L54.4012573,132.465804 L35.0395375,98.4558335 L43.4234478,93.5470566 C48.0454628,90.8409193 49.6351368,84.8228124 46.9670893,80.1357054 C44.2987195,75.4482714 38.3646029,73.8360975 33.7429103,76.5419079 L16.9757345,86.3597886 C8.98126375,91.0403567 5.55239081,100.661089 8.21076673,109.241476 C18.7672207,105.918718 30.5835501,110.262531 36.3374543,120.370086 C42.0913584,130.477642 39.8927016,143.027161 31.7770146,150.637498 C37.7749631,157.261764 47.7043808,159.060952 55.6988516,154.380384 L72.4660274,144.562176 C77.08772,141.856039 78.6777164,135.837932 76.0093466,131.150825 Z" id="icons_Path"></path>
                </g>
            </g>
        </g>
    </svg>
`;

const participationAwards = svg`
    <svg id="icons_award-inclusiveness" viewBox="0 0 293 293">
        <g id="icons_inclusiveness" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="icons_Group-Copy" transform="translate(27.000000, 27.000000)">
                <g id="icons_Colour/-White-Copy-11" filter="url(#icons_filter-award-inclusiveness-1)">

                <g id="icons_Mask" fill="#D8D8D8"><circle id="icons_path-award-inclusiveness-2" cx="119.5" cy="119.5" r="119.5"></circle></g>
                <g id="icons_Colour/-Dpurple-gradient" mask="url(#icons_mask-award-inclusiveness-3)" fill="url(#icons_linearGradient-award-inclusiveness-4)">
                    <g transform="translate(-0.000000, -0.000000)" id="icons_Rectangle-Copy">
                    <rect x="0" y="0" width="239" height="239"></rect>
                    </g>
                </g>
                </g>
                <g id="icons_meeting-(1)" transform="translate(37.000000, 34.000000)" fill="#FFFFFF" fill-rule="nonzero">
                <ellipse id="icons_Oval" cx="83" cy="14.71875" rx="14.6470588" ry="14.71875"></ellipse>
                <path d="M106.770292,20.4427083 C104.308375,31.2975836 94.6073632,39.4445829 82.9998374,39.4445829 C71.3923116,39.4445829 61.6912998,31.2975836 59.2293831,20.4427083 C50.4980498,22.3848351 43.9411765,30.1504206 43.9411765,39.4445829 L43.9411765,58.939861 C43.9411765,64.3134138 48.3229394,68.6875 53.705923,68.6875 C59.0889066,68.6875 63.4706695,64.3134138 63.4706695,58.939861 L63.4706695,49.192222 L102.52933,49.192222 L102.52933,58.939861 C102.52933,64.3134138 106.911093,68.6875 112.294077,68.6875 C117.677061,68.6875 122.058824,64.3134138 122.058824,58.939861 L122.058824,39.4445829 C122.058498,30.1504206 115.5013,22.3848351 106.770292,20.4427083 Z" id="icons_Path"></path>
                <path d="M158.492912,119.724347 C151.546328,115.660294 142.664567,118.071477 138.653959,125.110629 C134.643673,132.149127 137.02347,141.149897 143.970054,145.213296 C150.915993,149.277348 160.121143,146.865512 164.131107,139.827014 C168.141715,132.788189 165.438851,123.788072 158.492912,119.724347 Z" id="icons_Path"></path>
                <path d="M149.024134,86.3599467 L132.256992,76.5420547 C127.634986,73.8359142 121.701204,75.4484169 119.032839,80.1358563 C116.364475,84.8232957 117.954468,90.8410825 122.576474,93.547223 L130.960367,98.4560055 L111.598686,132.466015 L103.214792,127.557233 C98.5927864,124.851092 92.6590039,126.463595 89.9906394,131.151034 C87.3222749,135.838474 88.9122681,141.85626 93.5342739,144.562401 L110.301416,154.380293 C118.295871,159.060866 128.225269,157.262004 134.223206,150.637403 C126.107535,143.027385 123.908883,130.477523 129.662775,120.369956 C135.416668,110.262389 147.232652,105.918898 157.789407,109.241334 C160.447455,100.661263 157.018589,91.0405202 149.024134,86.3599467 Z" id="icons_Path"></path>
                <path d="M27.3460509,125.110656 C23.3354622,118.071617 14.4537445,115.660146 7.50719471,119.72446 C0.561289727,123.788121 -2.1418838,132.788093 1.8690273,139.827132 C5.87897115,146.865517 15.0840766,149.277315 22.0299816,145.213328 C28.9765314,141.14934 31.3563172,132.149042 27.3460509,125.110656 Z" id="icons_Path"></path>
                <path d="M76.0093466,131.150825 C73.3409767,126.463391 67.4068601,124.851217 62.7851675,127.557027 L54.4012573,132.465804 L35.0395375,98.4558335 L43.4234478,93.5470566 C48.0454628,90.8409193 49.6351368,84.8228124 46.9670893,80.1357054 C44.2987195,75.4482714 38.3646029,73.8360975 33.7429103,76.5419079 L16.9757345,86.3597886 C8.98126375,91.0403567 5.55239081,100.661089 8.21076673,109.241476 C18.7672207,105.918718 30.5835501,110.262531 36.3374543,120.370086 C42.0913584,130.477642 39.8927016,143.027161 31.7770146,150.637498 C37.7749631,157.261764 47.7043808,159.060952 55.6988516,154.380384 L72.4660274,144.562176 C77.08772,141.856039 78.6777164,135.837932 76.0093466,131.150825 Z" id="icons_Path"></path>
                </g>
            </g>
        </g>
    </svg>
`;

const advocacyAwards = svg`
    <svg id="icons_award-advocacy" viewBox="0 0 293 293">
        <g id="icons_advocacy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="icons_Group-Copy" transform="translate(27.000000, 27.000000)">
                <g id="icons_Colour/-White-Copy-11" filter="url(#icons_filter-award-advocacy-1)">

                <g id="icons_Mask" fill="#D8D8D8"><circle id="icons_path-award-advocacy-2" cx="119.5" cy="119.5" r="119.5"></circle></g>
                <g id="icons_Colour/-Orange-Gradient" mask="url(#icons_mask-award-advocacy-3)" fill="url(#icons_linearGradient-award-advocacy-4)">
                    <g transform="translate(-0.000000, -0.000000)" id="icons_Rectangle-Copy">
                    <rect x="0" y="0" width="239" height="239"></rect>
                    </g>
                </g>
                </g>
                <g id="icons_cheer" transform="translate(70.000000, 33.000000)" fill="#FFFFFF" fill-rule="nonzero">
                <path d="M24.5,7.33980583 C24.5,3.28627573 21.20916,0 17.15,0 C13.09084,0 9.8,3.28627573 9.8,7.33980583 L9.8,63.6116505 L24.5,63.6116505 L24.5,7.33980583 Z" id="icons_Path"></path>
                <path d="M66.15,83.184466 C70.20916,83.184466 73.5,79.8981903 73.5,75.8446602 L73.5,46.4854369 C73.5,42.4315806 70.20916,39.1456311 66.15,39.1456311 C62.09084,39.1456311 58.8,42.4315806 58.8,46.4854369 L58.8,75.8446602 C58.8,79.8981903 62.09084,83.184466 66.15,83.184466 Z" id="icons_Path"></path>
                <path d="M78.4,88.6328439 C75.2836,91.8238693 70.9506933,93.8205067 66.15,93.8205067 C60.6417467,93.8205067 55.7848667,91.1624627 52.64462,87.1116742 C47.2660533,94.1214729 38.8962,98.7222354 29.4,98.7222354 L24.5,98.7222354 C21.7916067,98.7222354 19.6,96.5298556 19.6,93.8205067 C19.6,91.1111579 21.7916067,88.9187781 24.5,88.9187781 L29.4,88.9187781 C38.5156333,88.9187781 46.2007933,82.6622117 48.3780267,74.2135922 L4.9,74.2135922 C2.19389333,74.2135922 0,76.407606 0,79.1153209 L0,92.5081506 C0,106.288217 4.31428667,120.354871 14.33642,129.808998 C17.72428,133.004272 19.6,137.3557 19.6,141.995349 L19.6,163.098271 C19.6,165.805333 21.7938933,168 24.5,168 L83.3,168 C86.0061067,168 88.2,165.805333 88.2,163.098271 L88.2,143.996235 C88.2,137.349164 89.9395,130.879536 92.8409533,124.899754 C96.21934,117.935378 98,110.193261 98,102.465522 L98,92.0996732 C95.76364,93.1708643 93.29012,93.8205067 90.65,93.8205067 C85.8493067,93.8205067 81.5164,91.8238693 78.4,88.6328439 Z M63.7,147.739522 L44.1,147.739522 C41.3916067,147.739522 39.2,145.547142 39.2,142.837793 C39.2,140.128444 41.3916067,137.936064 44.1,137.936064 L49,137.936064 L49,121.359072 L46.2916067,122.71391 C43.8752533,123.915487 40.9323133,122.943637 39.7167867,120.52153 C38.50616,118.099422 39.48714,115.155444 41.9083933,113.94439 L51.7083933,109.042662 C53.2205333,108.286488 55.0292867,108.353479 56.47446,109.258011 C57.9196333,110.153067 58.8,111.728155 58.8,113.427421 L58.8,137.936064 L63.7,137.936064 C66.4083933,137.936064 68.6,140.128444 68.6,142.837793 C68.6,145.547142 66.4083933,147.739522 63.7,147.739522 Z" id="icons_Shape"></path>
                <path d="M90.65,48.9320388 C86.59084,48.9320388 83.3,52.2179883 83.3,56.2718447 L83.3,75.8446602 C83.3,79.8981903 86.59084,83.184466 90.65,83.184466 C94.70916,83.184466 98,79.8981903 98,75.8446602 L98,56.2718447 C98,52.2179883 94.70916,48.9320388 90.65,48.9320388 Z" id="icons_Path"></path>
                <path d="M49,36.6990291 C49,32.645499 45.70916,29.3592233 41.65,29.3592233 C37.59084,29.3592233 34.3,32.645499 34.3,36.6990291 L34.3,63.6116505 L49,63.6116505 L49,36.6990291 Z" id="icons_Path"></path>
                </g>
            </g>
        </g>
    </svg>
`;

const masteryAwards = svg`
    <svg id="icons_award-mastery" viewBox="0 0 293 293">
        <g id="icons_Mastery" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="icons_Group-Copy" transform="translate(27.000000, 27.000000)">
                <g id="icons_Colour/-White-Copy-11" filter="url(#icons_filter-award-mastery-1)">

                <g id="icons_Mask" fill="#D8D8D8"><circle id="icons_path-award-mastery-2" cx="119.5" cy="119.5" r="119.5"></circle></g>
                <g id="icons_Colour/-Pink-Gradient" mask="url(#icons_mask-award-mastery-3)" fill="url(#icons_linearGradient-award-mastery-4)">
                    <g transform="translate(-0.000000, -0.000000)" id="icons_Rectangle-Copy">
                    <rect x="0" y="0" width="239" height="239"></rect>
                    </g>
                </g>
                </g>
                <g id="icons_graduate-cap" transform="translate(42.000000, 61.000000)" fill="#FFFFFF" fill-rule="nonzero">
                <polygon id="icons_Path" points="157 41.1281631 157 37.6840138 78.4656161 0 0 37.6507323 0 41.1607983 78.4656161 78.8125"></polygon>
                <path d="M27.6580311,61.75 L27.6580311,84.4107563 L74.9344542,107.25 L82.8786931,107.25 L130.025122,84.4735032 C130.070506,84.3736933 130.112973,84.2742085 130.15544,84.1737483 L130.15544,61.75 L78.9065737,86.5080653 L27.6580311,61.75 Z" id="icons_Path"></path>
                <polygon id="icons_Path" points="147.976919 96.3244521 147.976919 52 141.619268 55.1564125 141.619268 96.3244521 137.476684 108.74874 143.738706 117 145.857481 117 152.119171 108.74874"></polygon>
                </g>
            </g>
        </g>
    </svg>
`;

export default {
  all,
  streakAwards,
  leaderboardAwards,
  reciprocityAwards,
  inclusivenessAwards,
  participationAwards,
  advocacyAwards,
  masteryAwards,
};
