import React from 'react';
import PropTypes from 'prop-types';
import modalStyles from 'styles/components/modals/modal.module.scss';
import Icon from 'atoms/Icon';
import Support from 'modules/Help/sections/Support';
import { suggestFeature, reportProblem } from 'modules/Help/Controller';
import { NotificationManager } from 'services';

const SupportModal = ({ close, type }) => {

  const suggestFeatureSubmit = async (text) => {
    await suggestFeature(text);
    NotificationManager.showNotification({ text: 'Thanks for telling us.', type: 'success' });
    close();
  };

  const reportProblemSubmit = async (text) => {
    await reportProblem(text);
    NotificationManager.showNotification({ text: 'Thanks for telling us.', type: 'success' });
    close();
  };

  return (
    <div className={modalStyles.wrapper}>
      <div className={modalStyles.container}>
        <div className={modalStyles.close} onClick={close}>
          <Icon name='close-circle'/>
        </div>

        <Support
          onSubmit={type === 'suggest' ? suggestFeatureSubmit : reportProblemSubmit}
          type={type}
        />
      </div>
    </div>
  );
};

SupportModal.propTypes = {
  close: PropTypes.func,
  type: PropTypes.string,
};

export default SupportModal;
