import { ValidationStrategy } from 'components/custom/enums';
import { Observe } from 'decorators';
import { IInput } from 'interfaces';
import { html, nothing } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';
import { repeat } from 'lit-html/directives/repeat';
import { Router } from 'services/router.service';
import TemporaryController from './temporary.controller';
import styles from './temporary.module.scss';

const INPUTS = [
  {
    autofocus: true,
    name: 'temporaryPassword',
    label: 'Temporary password',
    placeholder: 'Type password from email',
    type: 'password',
    validationStrategy: ValidationStrategy.off,
  },
  {
    name: 'newPassword',
    label: 'New password',
    placeholder: 'Type new password',
    type: 'password',
    validationBox: true,
    validationStrategy: ValidationStrategy.immediate,
  },
];

class TemporaryView {
  public controller: TemporaryController;

  public static input(ops: IInput) {
    return html`
      <w-validation-box
        name="${ops.name}"
        label="${ops.label}"
        strategy="${ops.validationStrategy}"
        >
          <w-input
            autocomplete="new-password"
            minlength="${!!ops.validationBox ? 8 : 0}"
            autofocus=${ifDefined(ops.autofocus)}
            type="${ops.type}"
            name="${ops.name}"
            placeholder="${ops.placeholder}"
            vRequired
            vDisallowSpace
            ?vSpecial=${!!ops.validationBox}
            ?vNumber=${!!ops.validationBox}
            ?vUpperCase=${!!ops.validationBox}
            ?vLowerCase=${!!ops.validationBox}
          >
      </w-validation-box>
    `;
  }

  public get template() {
    const back = { name: 'get-link' };
    const temporary = { name: 'temporary' };

    return html`
      <div class="${styles.wrapper}">
        <p>
          You have been emailed a temporary password to
          <a href=${Router.generate(temporary)} @click=${(evt) => this.controller.link(evt)}>${Router.state.email}</a>
        </p>

        <p>Need to generate a
           <a href=${Router.generate(temporary)} @click=${(evt) => this.controller.resendTemporaryCode(evt)}>
          new temporary password?</a>
        </p>

        <form @changeForm=${(e) => this.controller.onChange(e)}  @submit=${(evt) => this.controller.onConfirm(evt)}>
          ${this.inputs}
          ${this.error}
          ${this.validationBox}

          <div class="${styles.buttons}">
             <a href="${Router.generate(back)}" @click=${(evt) => this.controller.link(evt, back)}>Back</a>

            ${this.button}
          </div>
        </form>
      </div>`;
  }

  private get inputs() {
    return repeat(INPUTS, (i: IInput) => i.name, (i) => TemporaryView.input(i));
  }

  @Observe('number', 'special', 'length', 'uppercase', 'lowercase')
  private get validationBox() {
    return html`
      <w-password-validation
        number="${this.controller.number}"
        special="${this.controller.special}"
        length="${this.controller.length}"
        uppercase="${this.controller.uppercase}"
        lowercase="${this.controller.lowercase}"
      >
      </w-password-validation>
    `;
  }

  @Observe('error')
  private get error() {
    const { error } = this.controller;

    return !!error ? html`<section class="${styles.error}">${error}</section>` : nothing;
  }

  @Observe('loading', 'valid')
  private get button() {
    const { valid, loading } = this.controller;
    const text = loading ? 'Loading...' : 'Submit';

    return html`<button class="w-button primary" ?disabled=${!valid || loading}>${text}</button>`;
  }

}


export default TemporaryView;
