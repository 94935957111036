export function View(module, TargetView, fragments = new Map(), data = {}) {
  const view = new TargetView();
  const defaultFn = () => {};

  view.OnInit = view.OnInit || defaultFn;
  view.OnDestroy = view.OnDestroy || defaultFn;

  fragments.forEach((Fragment, key) => {
    Object.defineProperty(view, key, {
      get() {
        const fragment = new Fragment();

        fragment.view = view;
        fragment.module = module;

        return fragment;
      },
    });
  });

  view.data = data;

  return view;
}
