import { ValidationStrategy } from 'components/custom/enums';
import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import styles from '../join.module.scss';
import JoinTemplate from '../join.view';

class StepTwoFragment {
  public view: JoinTemplate;

  get fields() {
    return ['role', 'desiredQualities', 'hoping'];
  }

  @Observe('onboardHeader')
  get header() {
    const { onboardHeader } = this.view.controller;

    return html`
     <div class="${styles.desc}">
        ${onboardHeader}
      </div>
    `;
  }

  @Observe('twoValid')
  get buttons() {
    return html`
    <div class="${styles.buttons}">
      <button
       type="button"
       @click=${(e) => this.view.controller.onBack(e)}
       class="w-button outline ${styles.back}">
       Back
      </button>
      <button
       type="submit"
       class="w-button primary ${styles.next}" ?disabled="${!this.view.controller.twoValid}">
       Next
       </button>
    </div>
    `;
  }

  @Observe('roleOptions', 'role')
  get roleSelect() {
    return html`
      <w-validation-box label=${this.view.controller.roleLabel}>
        <w-select
         name="role"
         .selected=${this.view.controller.role}
         vRequired
         .options=${this.view.controller.roleOptions}
         .placeholder="${'Please choose'}"
         />
      </w-validation-box>
    `;
  }

  @Observe('desiredQualities')
  get desiredQualities() {
    const disabled = this.view.controller.disabledFields.includes('desiredQualities');

    return html`
      <w-validation-box
       label="Briefly explain what qualities you are looking for in a match*"
       strategy="${disabled ? ValidationStrategy.off : ValidationStrategy.delay}"
       >
       <w-textarea
          value=${this.view.controller.desiredQualities}
          minlength="2"
          rows="6"
          name="desiredQualities"
          placeholder="Type your response here"
          vRequired
        >
      </w-validation-box>
    `;
  }

  @Observe('hoping')
  get hoping() {
    const disabled = this.view.controller.disabledFields.includes('hoping');

    return html`
      <w-validation-box
       label="What are you hoping to get out of this programme?*"
       strategy="${disabled ? ValidationStrategy.off : ValidationStrategy.delay}"
       >
       <w-textarea
          value=${this.view.controller.hoping}
          minlength="2"
          rows="6"
          name="hoping"
          placeholder="Type your response here"
          vRequired
        >
      </w-validation-box>
    `;
  }

  @Observe('disabledFields', 'hiddenFields')
  get form() {
    const { hiddenFields } = this.view.controller;

    return html`
       <form
         name="two"
         novalidate="novalidate"
         class="${styles.form}"
         @changeForm="${(e) => this.view.controller.onChange(e)}"
         @submit="${(e) => this.view.controller.onNext(e, 'Mentoring preferences completed')}"
       >
       ${hiddenFields && !hiddenFields.includes('role') ? this.roleSelect : nothing}
       ${hiddenFields && !hiddenFields.includes('desiredQualities') ? this.desiredQualities : nothing}
       ${hiddenFields && !hiddenFields.includes('hoping') ? this.hoping : nothing}
       ${this.buttons}
      </form>
    `;
  }

  get template() {
    return html`
      <div>
      ${this.header}
      ${this.view.mandatory}
      ${this.form}
      </div>
    `;
  }
}

export default StepTwoFragment;
