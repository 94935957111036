import { html } from 'lit-html';
import Template from 'services/templator/template';
import { repeat } from 'lit-html/directives/repeat';

class ChatFlowTemplate extends Template {
  get status() {
    const { selectedScreen } = this.data;

    return html`
      <div class="${this.styles.status} ${this.styles[`${selectedScreen}_bar`]}">
        ${repeat(Object.keys(this.controller.state.nav), key => key, () => html`<span> </span>`)}
      </div>
    `;
  }

  get template() {
    return html`
      <div class="${this.styles.wrapper}">
        <div>${this.directives.renderHeader()}</div>
        <div>${this.directives.renderStatus()}</div>
        <div class="${this.styles.screen}">${this.directives.renderScreen()}</div>
      </div>
    `;
  }
}

export default ChatFlowTemplate;
