import Fragment from 'services/templator/fragment';
import { html } from 'lit-html';

class MeetTimeFragment extends Fragment {
  get template() {
    const labelEnd = this.controller.state.selectedOption === 'call' ? 'make a call' : 'meet';

    return html`
      <div class="${this.styles['meet-time']}">
        <h1>When do you want to ${labelEnd}?</h1>

        <div class="${this.styles.datetime}">${this.directives.renderDateTimePicker()}</div>

        ${this.directives.showIf(!this.controller.state.authorizedCalendarAccess, this.calendarAccess)}
        ${this.directives.renderTimezoneSection()} ${this.directives.renderParticipantsTimezoneSection()}
      </div>
    `;
  }
}

export default MeetTimeFragment;
