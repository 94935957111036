import { html } from 'lit-html';
import REST from 'services/rest.service';
import CenterPromisifiedModal from 'components/lit/modals/center-promisified-modal.view';

class SyncCalendar extends CenterPromisifiedModal {
  constructor(...args) {
    super(...args);

    this.title = 'Allow WERKIN to sync with your calendar?';

    this.text = html`
      This will allow you to see your WERKIN appointments and events in your work calendar.
      <br/> <br/>
      Make scheduling easy, viewing available slots when booking time.
    `;

    this.submitBtnText = 'Yes';
    this.template = this.wrap(this.tmp);
  }

  async submitForm() {
    this.triggerListeners('submit');

    const { data: url } = await REST.getCronofyAuthUrl(`${document.location.href}?cronofyAuth=success`);

    document.location.replace(url);
  }
}

export default SyncCalendar;
