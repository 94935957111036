import { observable } from 'decorators';

class MatchHeaderController {
  public match: any;

  @observable() public matchAvatar: string;
  @observable() public matchName: string;
  @observable() public matchRole: string;
  @observable() public matchBusinessGroupDivision: string;
  @observable() public matchBusinessUnit: string;
  @observable() public matchLocation: string;

  public OnInit() {
    this.matchAvatar = this.match.avatar;
    this.matchName = this.match.name;
    this.matchRole = this.match.role;
    this.matchBusinessGroupDivision = this.match.businessGroupDivision;
    this.matchBusinessUnit = this.match.businessUnit;
    this.matchLocation = this.match.location;
  }
}

export default MatchHeaderController;
