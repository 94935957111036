import Fragment from 'services/templator/fragment';
import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';

class TimeZonesListFragment extends Fragment {
  timezone(timezone) {
    return html`
      <div class="${this.styles['timezone-card']}">
        <div>
          <div>${timezone.name}</div>
          <div>${timezone.offset}</div>
        </div>
        <button class="w-button primary" @click=${() => this.controller.selectTimezone(timezone.value)}>Select</button>
      </div>
    `;
  }

  tzsList(timezones) {
    return repeat(timezones, zone => zone.name, this.timezone);
  }

  get template() {
    return html`
      <div class="${this.styles.timezones}">
        <div class="${this.styles['search-timezone']}">
          <label for="search">Select a timezone</label>
          <input type="text" name="search" placeholder="Search" @input=${evt => this.controller.onTimeZoneSearch(evt)}/>
        </div>

        <h1>Select a timezone</h1>

        <div class="${this.styles['tz-list']}">
          ${ this.directives.renderTimezonesList(timezones => this.tzsList(timezones)) }
        </div>
      </div>
    `;
  }
}

export default TimeZonesListFragment;
