import { get } from 'lodash';
import { Auth, NotificationManager } from 'services';
import { Router } from 'services/router.service';
import apollo from 'api';
import acceptMutation from '../graphql/mutations/acceptActionInvitation.graphql';
import ActionQuery from '../graphql/queries/action.graphql';
import tentativelyAcceptMutation from '../graphql/mutations/tentativelyAcceptActionInvitation.graphql';
import nudgeMutation from '../graphql/mutations/nudgeActionParticipant.graphql';
import CancelModal from 'components/lit/modals/cancel-modal.view';

const modal = new CancelModal({ closeBtn: true });

class ActionUtils {
  eventStatus({ status, organizer, participants }) {
    const declined = [];
    const withStatus = [];
    const tentativelyAccepted = [];
    const currentUserId = Auth.getUserId();
    const participantStatus = status === 'pending' ? 'invited' : status;
    let { name } = organizer;
    const currentParticipant = participants.find((participant) => participant.id === currentUserId);
    let key = organizer.id === currentUserId ? 'organizer' : 'attendee';

    participants.forEach((participant) => {
      if (participant.id !== organizer.id) {
        participant.status === 'declined' && declined.push(participant);
        participant.status === 'tentatively-accepted' && tentativelyAccepted.push(participant);
        participant.status === participantStatus && withStatus.push(participant);
      }
    });

    const size = participants.length > 2 ? 'multiple' : 'single';
    const isEvery = withStatus.length === participants.lastIndex ? 'all' : 'some';

    status = declined.length ? 'declined' : status;

    const tentatively = status === 'pending' && !!tentativelyAccepted.length ? '-tentatively' : '';

    key = `${key}-${status}-${size}-${isEvery}${tentatively}`;

    if (size === 'single' && key.includes('organizer')) {
      const participant = participants.find(({ id }) => id !== organizer.id);
      name = (participant && participant.name) || undefined;
    }

    return name && this._statuses(name.split(' ')[0], get(currentParticipant, 'status'))[key];
  }

  async action(name, event) {
    try {
      const text = this._getNotificationText(name, event.organizer.id, event.data.subType);

      /** Call methods with names: yes, no, maybe, view, cancel, nudge, change */
      const success = await this[`${name}`](event.id, event.data.chatId);

      !!text && success && NotificationManager.showNotification({ text, type: 'success', duration: 2000 });
    } catch (e) {
      const text = this._getNotificationText(name, event.organizer.id, event.data.subType, true);

      !!text && NotificationManager.showNotification({ text, type: 'error', duration: 2000 });

      return e;
    }
  }

  change(actionId, chatId) {
    Router.go({ name: 'chats.actions.edit', params: { actionId, chatId } });

    return Promise.resolve(false);
  }

  view(id) {
    const names = {
      calendar: 'calendar.event',
      'chats.actions.list': 'chats.actions.action',
      'chats.activity': 'chats.actions.action',
    };

    const main = document.querySelector('#main.main-mobile');

    if (Router.display === 'mobile' && !!main) {
      main.scrollTo(0, 0);
    }

    Router.go({ name: names[Router.route.name], params: { ...Router.params, actionId: id } });

    return Promise.resolve(false);
  }

  async accept(id) {
    await apollo.mutate({
      mutation: acceptMutation,
      variables: { command: { id } },
    });

    return true;
  }

  async maybe(id) {
    await apollo.mutate({
      mutation: tentativelyAcceptMutation,
      variables: { command: { id } },
    });

    return true;
  }

  async decline(id) {
    await this.cancel(id);
  }

  async cancel(id) {
    const names = {
      calendar: 'calendar',
      'calendar.event': 'calendar',
      'chats.actions.list': 'chats.activity',
      'chats.activity': 'chats.activity',
    };

    const { data: { action } } = await apollo.query({
      query: ActionQuery,
      variables: {
        id,
      },
    });
    const { title, organizerId } = action;
    const isOrganizer = organizerId === Auth.getUserId();

    return modal.open(id, isOrganizer)
      .then((success) => {
        if (!success) { return; }

        Router.go({ name: names[Router.route.name] || 'chats.activity', params: { chatId: Router.params.chatId } });
        NotificationManager.showNotification({
          text: `${title} has been removed from your schedule`,
          type: 'success',
          duration: 2000,
        });
      });
  }

  async nudge(id) {
    await apollo.mutate({
      mutation: nudgeMutation,
      variables: { command: { id } },
    });

    return true;
  }

  _getNotificationText(name, organizerId, type, isError) {
    const isOrganizer = Auth.getUserId() === organizerId;
    const actions = {
      yes: 'accepted',
      maybe: 'tentatively accepted',
      no: 'declined',
      cancel: isOrganizer ? 'canceled' : 'declined',
    };

    let text = null;
    let errorText = null;

    if (name === 'nudge') {
      text =  `A nudge for your ${type} has been sent`;
      errorText = 'The participants were not nudged. Server error. Please try again';
    }

    if (name !== 'nudge' && actions[name]) {
      text = `The ${type} was ${actions[name]}`;
      errorText = `The ${type} wasn't ${actions[name]}. Server error. Please try again`;
    }

    if (isError) {
      return errorText;
    }

    return text;
  }

  _statuses(name, status) {
    return {
      'organizer-pending-single-some': `Status: ${name} has not accepted yet`,
      'organizer-pending-single-all': `Status: ${name} has not accepted yet`,
      'organizer-pending-multiple-all': 'Status: No one has accepted yet',
      'organizer-pending-multiple-some': 'Status: Some people have not accepted yet',
      'attendee-pending-single-some': `Status: ${name} is waiting for you to accept`,
      'attendee-pending-single-all': `Status: ${name} is waiting for you to accept`,
      'attendee-pending-multiple-all': `Status: ${name} is waiting for you to accept`,
      'attendee-pending-multiple-some': status === 'invited'
        ? `Status: ${name} is waiting for you to accept`
        : 'Status: Some people have not accepted yet',
      'organizer-pending-single-some-tentatively': `Status: ${name} has tentatively accepted`,
      'organizer-pending-multiple-some-tentatively': 'Status: No one has accepted yet',
      'organizer-pending-multiple-all-tentatively': 'Status: No one has accepted yet',
      'attendee-pending-multiple-some-tentatively': status === 'tentatively-accepted'
        ? `Status: ${name} is waiting for you to confirm`
        : `Status: ${name} is waiting for you to accept`,
      'attendee-pending-multiple-all-tentatively': `Status: ${name} is waiting for you to confirm`,
      'attendee-pending-single-all-tentatively': `Status: ${name} is waiting for you to confirm`,
      'attendee-pending-single-some-tentatively': `Status: ${name} is waiting for you to confirm`,
      'organizer-declined-single-some': `Status: ${name} has declined`,
      'organizer-declined-multiple-all': 'Status: Everyone has declined',
      'organizer-declined-multiple-some': 'Status: Not everyone can attend',
      'organizer-declined-multiple-some-tentatively': 'Status: Not everyone can attend',
      'attendee-declined-multiple-some': 'Status: Not everyone can attend',
    };
  }

  getEventButtonCases(organizer, participants, status) {
    const { status: currentUserStatus } = participants.find(({ id }) => id === Auth.getUserId());
    const isOrganizer = Auth.getUserId() === organizer.id;

    const isAccepted = !isOrganizer && currentUserStatus !== 'accepted';
    const canDecline = !isOrganizer && [
      'accepted',
      'invited',
      'tentatively-accepted',
    ].includes(currentUserStatus);
    const isPending = isOrganizer && status === 'pending';
    const canChange = isOrganizer;
    const canCancel = isOrganizer;
    const isCancelled = status === 'cancelled';

    return {
      isAccepted,
      isCancelled,
      isPending,
      canDecline,
      canCancel,
      canChange,
    };
  }
}

export default ActionUtils;
