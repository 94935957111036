import { observable } from 'decorators';
import { IStreaks } from 'interfaces';
import CreditStreaksModule from 'modules/CreditStreaks/credits-streaks.module';
import streaksService from 'services/data/streaks.service';

class CreditStreaksController {
  public module: CreditStreaksModule;

  @observable(null, true) public streaks: IStreaks;

  public async OnInit() {
    this.setStreaks();

    const unsubscribeFromStreaksUpdate = streaksService.handleStreaksUpdate(() => this.setStreaks());

    this.module.subs.add(unsubscribeFromStreaksUpdate);
  }

  public numberFormat(target: number) {
    return Number((target).toFixed(1)).toLocaleString();
  }

  private async setStreaks() {
    this.streaks = await streaksService.getStreaks();
  }
}

export default CreditStreaksController;
