import { html } from 'lit-html';
import Template from 'services/templator/template';

class HeaderTemplate extends Template {
  NavButton({ icon, text, active, badge, clickHandler }) {
    return html`
      <div
        class=${[this.styles.nav, active ? this.styles.active : ''].join(' ')}
        @click=${() => this.controller.wrapHandler(clickHandler())}
      >
        ${icon(active, '#fff')} ${badge} <span>${text}</span>
      </div>
    `;
  }
}

export default HeaderTemplate;
