import { Icons } from 'components/lit';
import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import NavigationController from 'modules/Navigation/navigation.controller';
import { Router } from 'services/router.service';
import styles from './navigation.module.scss';

class NavigationView {
  public controller: NavigationController;

  get backButton() {
    const buttonText = this.controller.isMobileLayout
      ? 'Back'
      : 'Back to WERKIN';

    return html`
      <button
        class=${styles.back}
        @click=${() => this.controller.goToChat()}
      >
        ${Icons.arrowLeftLong}
        <div>
          <div>${buttonText}</div>
        </div>
      </button>
    `;
  }

  public tabLink(tabName, tabIndex) {
    const isSelected = this.controller.selectedTab === tabIndex;

    if (isSelected) {
      this.controller.scrollItemToCenter(tabIndex);
    }

    return html`
      <div
        id="${tabIndex}-navlink"
        class="${!!isSelected && styles.current} noselect"
        @click=${() => this.controller.changeTab(tabIndex)}
      >
        ${tabName}
      </div>
    `;
  }

  get frontSpacer() {
    if (Router.display === 'desktop') {
      return nothing;
    }

    this.controller.tabs.length && setImmediate(() => {
      const firstItemIndex = 0;
      const spacerFront = document.getElementById('spacer-front');
      const firstLink = document.getElementById(`${firstItemIndex}-navlink`);

      if (!firstLink) { return; }

      spacerFront.style.minWidth = (this.controller.navbarWidth - firstLink.offsetWidth) / 2 + 'px';
    });

    return html`<div id="spacer-front" class=${styles.spacer}></div>`;
  }

  get backSpacer() {
    if (Router.display === 'desktop') {
      return nothing;
    }

    this.controller.tabs.length && setTimeout(() => {
      const lastItemIndex = this.controller.tabs.length - 1;
      const spacerBack = document.getElementById('spacer-back');
      const lastLink = document.getElementById(`${lastItemIndex}-navlink`);

      if (!lastLink) { return; }

      spacerBack.style.minWidth = (this.controller.navbarWidth - lastLink.offsetWidth) / 2 + 'px';
    });

    return html`<div id="spacer-back" class=${styles.spacer}></div>`;
  }

  @Observe('selectedTab')
  get tabs() {
    const keys = (v, i) => i;
    const views = ({ label }, i) => this.tabLink(label, i);

    return repeat(this.controller.tabs, keys, views);
  }

  @Observe('tabs')
  get items() {
    return this.controller.tabs.length ? html`
      <div id="items" class="${styles['nav-items']}">
        ${this.frontSpacer}
        ${this.tabs}
        ${this.backSpacer}
      </div>
    ` : nothing;
  }

  @Observe('title')
  get title() {
    return !!this.controller.title ? html`<h6>${this.controller.title}</h6>` : nothing;
  }

  get template() {
    return html`
      <div class="${styles.wrapper}">
        <div class=${styles.top}>
          ${this.backButton}
          ${this.title}
        </div>

        ${this.items}
      </div>

    `;
  }
}

export default NavigationView;

