import { html } from 'lit-html';
import { Icons } from 'components/lit';
import styles from './radio-buttons.module.scss';

export const RadioButton = ({ checked, handler }) => {
  const icon = checked ? Icons.radioButtonChecked : Icons.radioButton;

  return html`
    <label class=${styles['radio-button']}>
      <div class=${styles['radio-icon']}>${icon}</div>
      <input type="radio" checked=${checked} @click=${handler}>
    </label>
  `;
};
