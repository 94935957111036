import GeneralDirectives from 'directives/general';
import { manageMount } from 'lib/lit-components-manager';
import moment from 'lib/moment';
import { IModule } from 'services/router/router.interfaces';
import { RouterModule } from 'services/router/router.m';
import { leaveJoinChat } from 'utils/chat-helpers';
import InfoController from './info.controller';
import InfoView from './info.view';

const plugins = new Map();

plugins.set('leaveJoin', leaveJoinChat);

@RouterModule({
  view: InfoView,
  controller: InfoController,
  plugins,
  vm: GeneralDirectives,
})
class InfoModule implements IModule {
  public view: any;
  public moment = moment;
  public subs: Set<() => void> = new Set([]);

  public mount() {
    manageMount(this.view.template, 'info_body');
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default InfoModule;
