import { Observe } from 'decorators';
import { showIf } from 'directives';
import { html } from 'lit-html';
import ProfileContactDetailsController from 'modules/ProfileContactDetails/profile-contact-details.controller';
import styles from 'modules/ProfileContactDetails/profile-contact-details.module.scss';
import { Router } from 'services/router.service';

class ProfileContactDetailsTemplate {
  private controller: ProfileContactDetailsController;

  private buildHeader(label, value, handler, adaptive?) {
    const editButton = showIf(Router.display === 'desktop' || adaptive, html`
      <button
        @click=${handler}
        class=${styles['button-outlined']}
      >
        Edit
      </button>
    `);

    return html`
      <div class=${styles['card-title']}>
        <p class=${styles['card-line']}>
          <span class=${styles['line-label']}>${label}:</span>
          <span>${value}</span>
        </p>
        ${editButton}
      </div>
    `;
  }

  private buildLine(label, value) {
    return html`
      <p class=${styles['card-line']}>
        <span class=${styles['line-label']}>${label}:</span>
        <span class=${styles['line-content']}>${value}</span>
      </p>
    `;
  }

  private buildMobileHeader() {
    return html`
      <div class=${styles['mobile-header']}>
        <div class=${styles['mobile-title']}>Contact Details</div>
        <button
          @click=${() => this.controller.onEdit('details')}
          class=${styles['button-outlined']}
        >
          Edit
        </button>
      </div>
    `;
  }

  @Observe('contactDetails')
  private get dynamicTemplate() {
    const {
      alias,
      emailAddresses,
      telephoneNumbers,
    } = this.controller.contactDetails;

    const { value: primaryEmail } = emailAddresses.find((email) => email.isPrimary) || {};
    const { value: landline } = telephoneNumbers.find((tel) => tel.type === 'Landline') || {};
    const { value: mobile } = telephoneNumbers.find((tel) => tel.type === 'Mobile') || {};

    return html`
      <div class=${styles['cards-wrapper']}>
        <h3 class=${styles.title}>Contact Details</h3>

        <div class=${styles.card}>
          ${this.buildMobileHeader()}
          ${this.buildHeader('Landline', landline, () => this.controller.onEdit('details'))}
          ${this.buildLine('Mobile/Cell', mobile)}
          ${this.buildLine('Email', primaryEmail)}
          <p class=${styles.info}>
            Your contact details are not publicly displayed.
            The app uses them to connect you with your mentors and mentees.
          </p>
        </div>

        <div class=${styles.card}>
          ${this.buildHeader(
            'Alias',
            alias.value,
            () => this.controller.onEdit('alias'),
            true)
          }
          <p class=${styles.info}>
            An alias is automatically create for you by WERKIN and is used as your chat handle. If you prefer, your alias
            can be displayed in place of your name. This can be different for each collective you are a part of.
          </p>
        </div>
      </div>
    `;
  }

  get template() {
    return this.dynamicTemplate;
  }
}

export default ProfileContactDetailsTemplate;
