import { textCollapser } from 'atoms/lit';
import { Icons, userStatus } from 'components/lit';
import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import PublicProfileController from 'modules/ProfilePublic/public-profile.controller';
import auth from 'services/auth.service';
import { Router } from 'services/router.service';
import styles from './public-profile.module.scss';

class PublicProfileView {
  public controller: PublicProfileController;

  private get title() {
    return this.controller.isModal ? html`<div class="${styles.title}">Viewing public profile</div>` : nothing;
  }

  private get header() {
    return html`
      <div class=${styles.header}>
        <div>
          <div class=${styles.avatar_block}>
            ${this.avatar}
            ${userStatus(this.controller.user.id)}
          </div>
        </div>

        <div class=${styles.details}>
          <div class=${styles.username}>${this.controller.user.name}</div>

          ${this.chatButtons}
        </div>

      </div>
    `;
  }

  private get avatar() {
    return html`
      <w-avatar
        avatar="${this.controller.user.avatar}"
        title="${this.controller.user.name}"
        width=${Router.display === 'mobile' ? '70px' : '90px'}
        borderWidth="2"
      > </w-avatar>`;
  }

  private socialLink({ type, value })  {
    return value && html`
      <div class=${styles.social_link_wrapper}>
        ${Icons[type]}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href=${this.controller.getWebsiteURL(type, value)}
        >${this.controller.user.name.split(' ')[0] } on <span class=${styles.capitalized}>${type}</span></a>
      </div>`;
  }

  private websiteLink(site) {
    const url = this.controller.formattedUrl(site);

    return html`
    <a target='_blank' rel='noopener noreferrer' href=${url}>${site}</a>
    `;
  }

  private get about() {
    return html`
      <div class=${styles.card}>
        <h4>About ${this.controller.firstName}</h4>
        <div class=${styles.card_content}>
          <span>${ this.controller.user.position.split(', ')[0] }${ this.controller.user.businessGroup ? ', ' + this.controller.user.businessGroup : '' }</span>
          <span>${ this.controller.user.position.split(', ')[1] }</span>
          <span>${ this.controller.user.businessUnit }</span>
          <span>Based in ${this.controller.user.location}</span>
        </div>
      </div>
    `;
  }

  private get socialMedia() {
    const { socialMedia } = this.controller.user;

    return socialMedia.length ? html`
      <div class=${styles.card}>
        <h4>Social Media</h4>
        <div class=${styles.card_content}>
          ${repeat(socialMedia, (item) => item, (item) => this.socialLink(item))}
        </div>
      </div>
    ` : nothing;
  }

  private get relevantWebsites() {
    const { websites } = this.controller.user;

    return websites.length ? html`
      <div class=${styles.card}>
        <h4>Relevant Websites</h4>
        <div class=${styles.card_content}>
          ${repeat(websites, (item) => item, (item) => this.websiteLink(item))}
        </div>
      </div>
    ` : nothing;
  }

  private get skillsAndInterests() {
    return this.controller.user.skillsAndInterests.length ? html`
      <div class=${styles.card}>
        <h4>Skills and Interests</h4>
        <div class=${styles.card_content}>
          <span>${this.controller.firstName} has listed the following Skills and Interests:</span>
          <span>
            ${ this.controller.user.skillsAndInterests.map((skill) => skill.label).join(', ') }
          </span>
        </div>
      </div>
    ` : nothing;
  }

  private get biography() {
    return this.controller.user.biography ? html`
      <div class=${styles.card}>
        <h4>Bio</h4>
        <div class=${styles.card_content}>
          <span class=${styles.break_lines}>${ textCollapser(this.controller.user.biography)}</span>
        </div>
      </div>
    ` : nothing;
  }

  get chatButtons() {
    const { handleChatRoute } = this.controller;
    const isAlienUser = auth.getUserId() !== this.controller.user.id;

    return isAlienUser ? html`
      <div class=${styles.buttons}>
        <button
          @click=${() => handleChatRoute('chats.chat')}
          class="${styles.button}"
        >
          Message ${this.controller.firstName}
        </button>
        <button
          @click=${() => handleChatRoute('chats.actions.create')}
          class="${styles.button}"
        >
          Schedule a call or meet up
        </button>
      </div>
    ` : nothing;
  }

  private get profileError() {
    return html`
      <div class="${styles.errorWrapper}">
        <div class="${styles.title}">Oops! We couldn't find a profile for that user.</div>
      </div>
    `;
  }

  @Observe('user')
  private get view() {
    const { user } = this.controller;

    return user ? html`
      ${this.title}
      ${this.header}

      <div class=${styles.cards_wrapper}>
        ${this.about}
        ${this.biography}
        ${this.skillsAndInterests}
        ${this.socialMedia}
        ${this.relevantWebsites}
      </div>
    ` : nothing;
  }

  @Observe('hasError')
  get template() {
    const { hasError, isModal } = this.controller;

    return html`
      <div class="
        ${styles.wrapper}
        ${isModal ? styles.modal : styles.layout}
        ${hasError ? styles.hasError : ''}
      ">
        ${this.view}
        ${hasError ? this.profileError : nothing}
      </div>
    `;
  }
}

export default PublicProfileView;
