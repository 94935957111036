import * as Icons from 'components/lit/icons.view';
import { Observe } from 'decorators';
import { showIf } from 'directives';
import { html, nothing } from 'lit-html';
import buttonStyles from 'styles/button.module.scss';
import PollerController from './poller.controller';
import styles from './poller.module.scss';

class PollerTemplate {
  public controller: PollerController;
  public icons = {
    'poll-yay': Icons.pollYay,
    'poll-nay': Icons.pollNay,
    'poll-maybe': Icons.pollMaybe,
    'poll-tell-us': Icons.pollTell,
  };

  public captureInput(visible) {
    const { handleForm } = this.controller;

    return visible ? html`
      <form
        class=${styles['input-wrapper']}
        @submit=${handleForm}
      >
        <input
          name="poll"
          class=${styles.input}
          placeholder="Tell us more"
        />
        <button
          type="submit"
          class=${buttonStyles.button}
        >
          Send
        </button>
      </form>
    ` : nothing;
  }

  public button(options) {
    const { handleSelection } = this.controller;

    return html`
      <div class=${styles.buttons}>
        ${options.map(({ type, text, icon }) => html`
          <button
            class=${buttonStyles.outline}
            @click=${() => handleSelection(type, text)}
          >
            ${this.icons[icon]}
            ${text}
          </button>
        `)}
      </div>
    `;
  }

  get error() {
    const { handleClose } = this.controller;

    return html`
      <div class=${styles.completed}>
        <h2>Oops! That poll is no longer available.</h2>
        <button class=${buttonStyles.button} @click=${handleClose}>Go back</button>
      </div>
    `;
  }

  get completed() {
    const { handleClose } = this.controller;

    return html`
      <div class=${styles.completed}>
        <h2>You've already completed this poll!</h2>
        <button class=${buttonStyles.button} @click=${handleClose}>Go back</button>
      </div>
    `;
  }

  @Observe('showInput')
  get poll() {
    const { activePoll, showInput } = this.controller;
    const { title, text, type, options } = activePoll;

    return html`
      <div class=${styles.wrapper}>
        <h2>${title}</h2>
        <p>${text}</p>
        ${showIf(type === 'BUTTON', this.button(options))}
        ${this.captureInput(showInput)}
      </div>
    `;
  }

  @Observe('pollState')
  get template() {
    const { pollState } = this.controller;

    switch (pollState) {
      case 'OPEN':
        return this.poll;
      case 'COMPLETED':
        return this.completed;
      case 'ERROR':
        return this.error;
      default:
        return nothing;
    }
  }
}

export default PollerTemplate;
