import { directive, html, nothing } from 'lit-html';
import HeaderFragment from 'modules/ChatFlow/fragments/header.fragment';
import DetailsFragment from 'modules/ChatFlow/fragments/details.fragment';
import GeneralDirectives from 'directives/directives';
import TagsFragment from 'modules/ChatFlow/fragments/tags.fragment';
import MembersFragment from 'modules/ChatFlow/fragments/members.fragment';
import SearchFragment from 'modules/ChatFlow/fragments/search.fragment';
import { alphabetTemplate } from 'components/lit';
import NoResultsFragment from 'modules/ChatFlow/fragments/no-results.fragment';
import ParticipantsFragment from 'modules/ChatFlow/fragments/participants.fragment';

class ChatFlowDirective extends GeneralDirectives {
  onInit() {
    this.view.addFragment('header', new HeaderFragment());
    this.view.addFragment('details', new DetailsFragment());
    this.view.addFragment('tags', new TagsFragment());
    this.view.addFragment('members', new MembersFragment());
    this.view.addFragment('participants', new ParticipantsFragment());
    this.view.addFragment('search', new SearchFragment());
    this.view.addFragment('no-results', new NoResultsFragment());

    this.clearListeners = new Set([]);
    this.clearScreenOnlyListeners = new Set([]);
    this.clearParticipantsListeners = new Set([]);
  }

  get renderHeader() {
    return directive(() => part => {
      part.setValue(this.view.fragments.header.template);
      part.commit();
    });
  }

  get renderNavButton() {
    return directive(button => part => {
      this.controller.subscribeState(() => {
        part.setValue(this.view.fragments.header[button]);
        part.commit();
      }, 'allowNext', 'loading');

      this.controller.subscribeData(() => {
        part.setValue(this.view.fragments.header[button]);
        part.commit();
      }, 'selectedScreen');
    });
  }

  get renderStatus() {
    return directive(() => part => {
      const { single } = this.router.state.left;
      !single && this.controller.subscribeData(() => {
        part.setValue(this.view.status);
        part.commit();
      }, 'selectedScreen');
    });
  }

  get renderAccess() {
    return directive(() => part => {
      const unsubscribe = this.controller.subscribeData(() => {
        part.setValue(this.view.fragments.details.isPublic);
        part.commit();
      }, 'isPublic');

      this.clearScreenOnlyListeners.add(unsubscribe);
    });
  }

  get renderSearch() {
    return directive((name, placeholder, label) => part => {
      const { search } = this.view.fragments;

      search.setOptions({ name, placeholder, label });

      const unsubscribe = this.controller.subscribeState((state) => {
        const isLoading = state.loading;
        const isMemberSearch = name === 'memberSearch';

        if (isLoading && isMemberSearch) {
          part.setValue(nothing);
        } else {
          part.setValue(search.template);
        }
        part.commit();
      }, name, 'loading');

      this.clearScreenOnlyListeners.add(unsubscribe);
    });
  }

  get renderResults() {
    return directive(() => part => {
      const unsubscribe = this.controller.subscribeState(state => {
        this.clearListeners.forEach(fn => fn());

        const fragment = this.view.fragments.tags;
        const isLoading = state.loading;
        const searchValue = state.tagSearch;
        const isResultExists = !!state.tags.length;
        const isSelectedTagsExists = !!this.controller.selectedTags.length;

        if (isLoading) {
          part.setValue(fragment.loading);
        } else {
          switch (true) {
            case !searchValue && !isSelectedTagsExists && !isSelectedTagsExists: {
              part.setValue(nothing);
              break;
            }

            case searchValue && !isResultExists: {
              part.setValue(this.view.fragments['no-results'].template);
              break;
            }

            case (searchValue  && isResultExists) || isSelectedTagsExists: {
              part.setValue(fragment.results);
              break;
            }

            default:
              part.setValue(nothing);
          }
        }

        part.commit();

      }, 'cachedTags', 'tagSearch', 'tags', 'loading');

      this.clearScreenOnlyListeners.add(unsubscribe);
    });
  }

  get renderEntity() {
    return directive((
      entity,
      entityName,
      fragment = this.view.fragments[`${entityName}s`][entityName]
    ) => part => {
      this.controller.subscribeState(() => {
        part.setValue(fragment(entity));
        part.commit();
      }, `${entity.id}_selected_${entityName}`);
    });
  }

  get renderParticipants() {
    return directive(() => part => {
      const unsubscribe = this.controller.subscribeState(state => {
        const fragment = this.view.fragments.tags;
        const isLoading = state.loading;
        const list = part.startNode.nextElementSibling.lastElementChild;
        const isParticipantsBlock = list && list.classList.contains(this.view.styles['participants-list-wrapper']);

        if (isLoading) {
          part.setValue(fragment.loading);
        } else {
          part.setValue(this.view.fragments.participants.template);
        }

        part.commit();

        if (!isParticipantsBlock) { return; }

        if (this.router.display === 'desktop') {
          return list && list.style.setProperty('--offset', state.offset > 0 ? `-${state.offset}px` : 0);
        }

        setImmediate(() => {
          list.scrollTo({
            left: list.scrollWidth,
            behavior: 'smooth',
          });
        });
      }, 'scrollToEnd', 'members', 'loading');

      this.clearScreenOnlyListeners.add(unsubscribe);
    });
  }

  get renderMembersList() {
    return directive(() => part => {
      const unsubscribe = this.controller.subscribeState((state) => {
        this.clearListeners.forEach(fn => fn());
        if (state.members && state.members.length) {
          part.setValue(alphabetTemplate(state.members, member => this.renderEntity(member, 'member')));
        } else {
          part.setValue(this.view.fragments['no-results'].template);
        }

        part.commit();
      }, 'members');

      this.clearScreenOnlyListeners.add(unsubscribe);
    });
  }

  get renderChevron() {
    return directive(dir => part => {
      const unsubscribe = this.controller.subscribeState(state => {
        if (this.router.display === 'mobile') { return; }

        const { MEMBER_WIDTH } = this.module.constants.chat_flow;
        const MOBILE_VISIBLE_MEMBERS = parseInt((window.innerWidth / MEMBER_WIDTH).toString(), 10);

        const visibleMembers = this.router.display === 'mobile' ? MOBILE_VISIBLE_MEMBERS : 4;
        const count = this.data.members.length - visibleMembers;
        const steps = state.offset / MEMBER_WIDTH / count;
        const hideRight = dir === 'right' && (steps >= 1);
        const hideLeft = dir === 'left' && (steps <= 0);
        const noChevrons = this.router.display === 'mobile' || this.data.members.length < 5;

        const hide = hideLeft || hideRight || noChevrons;

        part.setValue(!hide ? this.view.fragments.participants.chevron(dir) : '');
        part.commit();
      }, 'offset');

      this.clearScreenOnlyListeners.add(unsubscribe);
      this.clearParticipantsListeners.add(unsubscribe);
    });
  }

  get renderScreen() {
    return directive(() => part => {
      const options = {
        tags: {
          icon: 'label',
          text: html`There are no tags matching your <br> criteria. Please try a different search`,
        },

        members: {
          icon: 'search',
          text: html`No results found. <br/> Please try a different search.`,
        },
      };

      this.controller.subscribeData((data) => {
        this.clearScreenOnlyListeners.forEach(fn => fn());

        this.view.fragments['no-results'].setOptions(options[data.selectedScreen]);

        part.setValue(this.view.fragments[data.selectedScreen].template);
        part.commit();
      }, 'selectedScreen');

    });
  }
}

export default ChatFlowDirective;
