import { Icons } from 'components/lit';
import { directive, html } from 'lit-html';
import range from 'lodash/range';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import styles from './timepicker.module.scss';

const moment = extendMoment(Moment);
const getTimeValuesFromRange = ({
  entity,
  min,
  max,
}) => (
  range(min, max + 1).map(value => ({
    value: `${value < 10 ? `0${value}` : value}`,
    entity,
  }))
);

class TimePicker {
  constructor(options) {
    this.listeners = {};
    this.destroyers = new Set([]);
    this.date = null;
    this.minLimitHook = options.onMinLimitReached || (() => {});
    this.minDate = moment(options.minDate);
    this.onChange = options.onChange || (() => {});
    this.onDurationChange = options.onDurationChange || (() => {});

    this.state = {
      hour: {
        format: 'hh',
        value: null,
        values: getTimeValuesFromRange({
          entity: 'hour',
          min: 1,
          max: 12,
        }),
      },
      minute: {
        format: 'mm',
        value: null,
        values: getTimeValuesFromRange({
          entity: 'minute',
          min: 0,
          max: 59,
        }),
      },
      abbr: {
        format: 'A',
        value: null,
        values: [{ value: 'AM', entity: 'abbr' }, { value: 'PM', entity: 'abbr' }],
      },
      duration: {
        format: 'mm',
        value: options.duration.value || 15,
        values: [
          { value: 15, entity: 'minute' },
          { value: 30, entity: 'minute' },
          { value: 45, entity: 'minute' },
          { value: 1, entity: 'hour' },
          { value: 2, entity: 'hour' },
        ],
      },
    };
  }

  setDate(date) {
    this.date = moment.parseZone(date);

    this.setState({
      hour : { ...this.state.hour, value: this.date.format('hh') },
      minute : { ...this.state.minute, value: this.date.format('mm') },
      abbr : { ...this.state.abbr, value: this.date.format('A') },
    });
  }

  subscribe(fn, ...keys) {
    fn();

    keys.forEach(key => {
      this.listeners[key] = this.listeners[key] || new Set([]);
      this.listeners[key].add(fn);
    });

    this.destroyers.add(() => keys.forEach(key => this.listeners[key].delete(fn)));
  }

  onReachedMinimum(name, newValue) {
    const minDate = this.minDate;
    const isSameYear = this.date.year() === minDate.year();
    const isSameMonth = this.date.month() === minDate.month();
    const isSameDay = this.date.date() === minDate.date();
    const notLimitedHours = name === 'hour' && ['12', '00'].includes(this.date.format('HH'));

    if (isSameDay && isSameMonth && isSameYear) {
      const min = name === 'duration' ? 0 : minDate.format(this.state[name].format);
      const minExisted = min > newValue.value && newValue.entity !== 'abbr';
      const minAbbr = newValue.entity === 'abbr' && (min === 'PM') && (newValue.value === 'AM');

      if (minExisted || minAbbr) {
        return !notLimitedHours;
      }
    }

    return false;
  }

  adjustTimeWithMeridiem(entity, value) {
    const date = moment(this.date);
    const meridiemValue = (this.state.abbr.value === 'AM' ? value : +value + 12);

    switch (entity) {
    case 'minute':
      return date.set(entity, value).toDate();
    case 'hour':
      return date.set(entity, meridiemValue).toDate();
    case 'abbr':
      if (value === 'AM' && date.hours() > 12) {
        date.hours(date.hours() - 12);
      } else if (value === 'PM' && date.hours() <= 12) {
        date.hours(date.hours() + 12);
      }

      return date.toDate();
    default: return;
    }
  }

  up(name) {
    const { value, values } = this.state[name];
    const index = values.findIndex(val => val.value === value);
    const newValue = index + 1 === values.length ? values[0] : values[index + 1];

    const isReachedMinimum = this.onReachedMinimum(name, newValue);

    if (isReachedMinimum) {
      this.date = moment(this.date).add(1, 'day').toDate();

      this.onChange(this.date);
    }

    if (name !== 'duration') {
      const date = this.adjustTimeWithMeridiem(newValue.entity, newValue.value);
      this.onChange(date);
    } else {
      this.onDurationChange(newValue);
    }

    this.setState({ [name] : { ...this.state[name], value: newValue.value } });
  }

  down(name) {
    const { value, values } = this.state[name];
    const index = values.findIndex(val => val.value === value);
    const newValue = index === 0 ? values[values.length - 1] : values[index - 1];

    const isReachedMinimum = this.onReachedMinimum(name, newValue);

    if (isReachedMinimum) {
      return this.minLimitHook();
    }

    if (name !== 'duration') {
      const date = this.adjustTimeWithMeridiem(newValue.entity, newValue.value);
      this.onChange(date);
    } else {
      this.onDurationChange(newValue);
    }

    this.setState({ [name] : { ...this.state[name], value: newValue.value } });
  }

  setState(newState) {
    this.state = { ...this.state, ...newState };

    Object.keys(newState).forEach(key => {
      if (!this.listeners[key]) { return; }

      this.listeners[key].forEach(fn => fn());
    });
  }

  switcher(name, showEntity) {
    const { value, values } = this.state[name];
    const val = values.find(val => val.value === value);

    return html`
      <div class="${styles.picker}">
        <button @click=${() => this.up(name)}>${Icons.circleArrowUp}</button>
        <div class="${styles.content}">
          <span>${ val.value }</span>
          <span>${ showEntity ? val.entity + 's' : '' }</span>
        </div>
        <button @click=${() => this.down(name)}>${Icons.circleArrowDown}</button>
      </div>
    `;
  }

  get renderSwitcher() {
    return directive((name, showEntity = false) => part => {
      this.subscribe(() => {
        part.setValue(this.switcher(name, showEntity));
        part.commit();
      }, name);
    });
  }

  get template() {
    return html`
      <div class="${styles.wrapper}">
        <div class="${styles.section}">
          Start time
          <div class="${styles.switchers}">
           ${this.renderSwitcher('hour')}
           <div class=${styles.semicolon}>:</div>
           ${this.renderSwitcher('minute')}
           ${this.renderSwitcher('abbr')}
          </div>
        </div>

        <div class="${styles.section}">
          Duration
          ${this.renderSwitcher('duration', true)}
        </div>
      </div>
    `;
  }

  destroy() {
    this.destroyers.forEach(fn => fn());
    this.destroyers = new Set([]);
  }

  set min(date) { this.minDate = moment.parseZone(date); }
}

export default TimePicker;
