import apollo from 'api';
import { observable } from 'decorators';
import { Auth, StatusesService } from 'services';
import { gaTrackEvent } from 'services/analytics.service';
import Router from 'services/router';
import ChatsListQuery from '../../graphql/queries/chat/ChatsList.graphql';
import MatchesQuery from '../../graphql/queries/matches.graphql';
import ChatsModule from './chats.module';

class ChatsController {
  public module: ChatsModule;
  public router: Router;
  @observable([]) public chats: any[];
  @observable([]) public matchIds: string[];

  constructor() {
    this.getChatsByType = this.getChatsByType.bind(this);
    this.getChatMatch = this.getChatMatch.bind(this);
  }

  public OnInit() {
    this.fetchChats();
  }

  public fetchChats() {
    const observe = apollo.watchQuery({
      query: ChatsListQuery,
    }).subscribe({
      next: ({ data: { chats } }) => {
        this.updateChatPresenceStatus(chats);
        this.fetchMatchIds();
        this.chats = chats;
      },
      error: (e) => console.error('watchQuery Error', e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  private async fetchMatchIds() {
    const { data: { matches } } = await apollo.query({
      query: MatchesQuery,
    });

    this.matchIds = matches.map(({ individual }) => individual.id);
  }

  public getChatsByType(type: string) {
    const filteredChats = this.chats.filter((chat) => chat.type === type);

    return type !== 'direct' ? filteredChats : filteredChats.filter((chat) => chat.participants.length > 1);
  }

  public openChatsList(modalName) {
    this.router.go({ hash: { [modalName] : true }, params: this.router.params });
  }

  public getName() {
    return ['chats.info', 'chat.activity'].includes(this.router.route.name) ? this.router.route.name : 'chats.chat';
  }

  public handleClickCreate(state) {
    if (state.modal === 'direct') {
      return this.router.go({
        hash: { [state.modalName]: true },
        params: this.router.params,
      });
    }

    return this.router.go({
      name: this.router.display === 'mobile' ? 'chat.create' : this.router.route.name,
      state: { left: state },
    });
  }

  public getLink(chatId) {
    return this.router.generate({ name: this.getName(), params: { chatId } });
  }

  public navToChat(chatId, evt) {
    evt.preventDefault();

    if (this.router.params.chatId === chatId) { return; }

    gaTrackEvent({ category: 'chat', action: 'view'});
    this.router.go({ name: this.getName(), params: { chatId } });
  }

  public getChatTitle(chat) {
    if (chat.type !== 'direct') {
      return chat.title;
    }

    const current = Auth.getUserId();
    const guests = chat.participants.filter(({ id }) => id !== current);
    const guestNames = guests.map((guest) => guest.name).join(', ');

    return guestNames;
  }

  public getChatMatch(chat) {
    if (chat.type !== 'direct') { return false; }

    return chat.participants.some(({ id }) => this.matchIds.includes(id));
  }

  private updateChatPresenceStatus = (chats) => {
    const ids = chats
      .filter(({type, participants}) => type === 'direct' && participants.length <= 2)
      .map(({participants}) => participants.filter(({id}) => id !== Auth.getUserId())
        .map(({id}) => id)).flat();

    StatusesService.subscribe(this.constructor.name, ids);
  }
}

export default ChatsController;
