import apollo from 'api';
import { ValidationStrategy } from 'components/custom/enums';
import { Icons } from 'components/lit';
import CenterAutonomousModal from 'components/lit/modals/center-autonomus-modal.view';
import { AutonomousModal } from 'decorators';
import { html } from 'lit-html';
import { NotificationManager } from 'services/index';
import ReportProblemMutation from '../../../graphql/mutations/reportProblem.graphql';

@AutonomousModal('ReportProblemModal')
class ReportProblemModal extends CenterAutonomousModal {
  public onOpen() {
    this.title = 'Report a problem';
    this.text = 'Has something happened?';

    this.template = this.wrap(this.body);
  }

  public async sendForm(evt) {
    evt.preventDefault();
    const text = evt.target.problem.value;

    await apollo.mutate({
      mutation: ReportProblemMutation,
      variables: {
        command: {
          text,
        },
      },
    });

    NotificationManager.showNotification({ text: 'Thanks for your feedback', type: 'success'});
    this.destroy();
  }

  get body() {
    return html`
      <div class=${this.styles.outer}>
        <div class="${this.styles.modal_header}">
          <h2 class="${this.styles.title}">${this.title}</h2>
          <div class="${this.styles.close_btn}" @click=${() => this.destroy()}>
            ${Icons.circleCross}
          </div>
        </div>
        <form @submit=${(evt) => this.sendForm(evt)}>
          ${this.textarea}
          ${this.buttons}
        </form>
      </div>
    `;
  }

  get textarea() {
    return html`
      <div class=${this.styles.form}>
        <w-validation-box
          label=${this.text}
          strategy="${ValidationStrategy.delay}"
        >
          <w-textarea
            minlength="10"
            rows="6"
            name="problem"
            placeholder="Tell us about it here and please include a description of what you did, what you expected to be different, and any error messages you saw."
          >
        </w-validation-box>
      </div>
    `;
  }

  get buttons() {
    return html`
      <div class=${this.styles.buttons_wrapper}>
        <button
          class="w-button primary"
          type="submit"
        >
          Send
        </button>
      </div>
    `;
  }
}

export default ReportProblemModal;
