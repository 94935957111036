import { html } from 'lit-html';
import Fragment from 'services/templator/fragment';
import { ValidationStrategy } from 'components/custom/enums';

class DetailsFragment extends Fragment {

  get title() {
    return html`
       <w-validation-box
       label="Title*"
       >
        <w-input
          minlength="2"
          value=${this.controller.data.title.value}
          type="text"
          name="title"
          placeholder="Type title here"
          vRequired
        >
      </w-validation-box>
    `;
  }

  get details() {
    return html`
       <w-validation-box
       label="Call details (Optional)"
       strategy=${ValidationStrategy.off}
       >
        <w-input
          value=${this.controller.data.details.value}
          type="text"
          name="details"
          placeholder='Entering conferencing details / Dial-in number'
        >
      </w-validation-box>
    `;
  }

  get location() {
    return html`
       <w-validation-box
       label="Location (Optional)"
       strategy=${ValidationStrategy.off}
       >
        <w-input
          value=${this.controller.data.location.value}
          type="text"
          name="location"
          placeholder="Type location here"
        >
      </w-validation-box>
    `;
  }

  get notes() {
    return html`
      <w-validation-box
       label="Add notes here (Optional)"
       strategy="${ValidationStrategy.off}"
       >
       <w-textarea
          value=${this.controller.data.notes.value}
          name="notes"
          placeholder="Type notes here"
        >
      </w-validation-box>
    `;
  }


  get template() {
    return html`
      <div class="${this.styles.details}">
        <form @changeForm=${(e) => this.controller.onChange(e)}
          @submit=${(evt) => evt.preventDefault()}>
          ${this.title}
          ${this.data.type === 'call' ? this.details : this.location}
          ${this.notes}
        </form>

      </div>
    `;
  }
}

export default DetailsFragment;
