import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import NoMatchController from './no-match.controller';
import NoMatchView from './no-match.view';

@RouterModule({
  name: 'w-no-match-module',
  view: NoMatchView,
  controller: NoMatchController,
})
class NoMatchModule {
  public view: NoMatchView;

  public mount(module) {
    render(this.view.template, module);
  }
}

export default NoMatchModule;
