import apollo from 'api';
import { Icons } from 'components/lit';
import CenterInfoModal from 'components/lit/modals/center-info-modal.view';
import { AutonomousModal } from 'decorators';
import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import { Auth } from 'services';
import ChatQuery from '../../../graphql/queries/chat/Chat.graphql';
import styles from './message-statuses-modal.module.scss';

@AutonomousModal('MessageStatusesModal', 'messageId', 'chatId')
class MessageStatusesModal extends CenterInfoModal {
  public title = 'Statuses';
  public receivers = [];

  public async onOpen() {
    const { messageId, chatId } = this.router.hash;
    const currentUserId = Auth.getUserId();

    const { data: { chat } } = await apollo.query({
      query: ChatQuery,
      variables: {
        id: chatId,
      },
    });

    const { participants, messages } = chat;
    const message = messages.find((m) => m.id === messageId);

    const statusesObj = message.statuses
      .reduce((acc, item) => {
        acc[item.participantId] = item;

        return acc;
      }, {});

    this.receivers = participants
      .reduce((acc, { name, avatar, id }) =>
        id === currentUserId || !statusesObj[id]
          ? acc
          : [...acc, { id, name, avatar, readStatus: statusesObj[id].status }], []);

    this.template = this.wrap(this.tmp);
  }

  public status(s) {
    switch (s) {
    case 'read':
      return Icons.circleCheckIcon('#4cb49c');
    case 'unread':
      return Icons.circleCheckIcon('#ddd');
    default:
      return;
    }
  }

  public openProfileModal(id) {
    this.destroy();
    this.router.go({ hash: { ...this.router.hash, IndividualModal: true, userId: id } });
  }

  public receiver(r) {
    return html`
      <div class="${styles.receiver}"  @click=${() => this.openProfileModal(r.id)}>
        <div class="${styles.info}">
          <w-avatar avatar="${r.avatar}" title="${r.name}" width="30px" borderWidth="2"> </w-avatar>
          <span>${r.name}</span>
        </div>
        ${this.status(r.readStatus)}
      </div>
    `;
  }

  get main() {
    return html`
      <div class="${styles.receivers_list}">
      ${repeat(this.receivers, (r) => r.id, (r) => this.receiver(r))}
      </div>
    `;
  }
}

export default MessageStatusesModal;
