import GeneralDirectives from 'directives/general';
import { manageMount } from 'lib/lit-components-manager';
import { RouterModule } from 'services/router/router.m';
import MessagesController from './messages.controller';
import MessagesView from './messages.view';

@RouterModule({
  controller: MessagesController,
  view: MessagesView,
  vm: GeneralDirectives,
})
class MessagesModule {
  public subs: Set<() => void> = new Set([]);
  public view: MessagesView;

  public mount() {
    manageMount(this.view.template,  'chat_messages');
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default MessagesModule;
