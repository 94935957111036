import { manageMount } from 'lib/lit-components-manager';
import { RouterModule } from 'services/router/router.m';
import ReportController from './report.controller';
import ReportView from './report.view';

@RouterModule({
  view: ReportView,
  controller: ReportController,
})
class ReportModule {
  public view: ReportView;

  public mount() {
    manageMount(this.view.template, 'report');
  }
}

export default ReportModule;
