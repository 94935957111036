import { classNames } from '@werkin/lib/helpers';
import React from 'react';
import styles from './Button.module.scss';

export enum ButtonVariants {
  Primary = 'primary',
  Link = 'link',
  PrimaryOutline = 'primary-outline',
  Secondary = 'secondary',
  Light = 'light',
}
interface ButtonProps {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  variant?: ButtonVariants;
  children: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = ButtonVariants.Primary, disabled, className, onClick, children }: ButtonProps, ref) => {
    const classes = classNames(`base ${variant} ${disabled ? 'disabled' : ''}`, styles, className);

    return (
      <button ref={ref} className={classes} onClick={onClick}>
        {children}
      </button>
    );
  }
);

export default Button;
