import store from '@werkin/store';
import { observable } from 'decorators';
import { Worker } from 'services';
import { gaTrackEvent } from 'services/analytics.service';
import { Router } from 'services/router.service';
import DashConnectivityModule from './dash-connectivity.module';

class DashConnectivityController {
  public module: DashConnectivityModule;
  @observable(null) public calendarConnected: boolean;
  @observable(Worker.currentPermission) public suggestPush: string;
  @observable(null) public suggestPWA: any;

  constructor() {
    this.handleInstallPWA = this.handleInstallPWA.bind(this);
  }

  public async OnInit() {
    this.fetchPWA();
  }

  private fetchPWA() {
    const unsubscribeFromStore = store.subscribe('promptPWA', (promptPWA) => {
      this.suggestPWA = promptPWA;
    });

    this.module.subs.add(unsubscribeFromStore);
  }

  public async handleInstallPWA() {
    if (this.suggestPWA) {
      gaTrackEvent({ category: 'dashboard:connectivity', action: 'install-pwa' });
      this.suggestPWA.prompt();

      await this.suggestPWA.userChoice;
      store.unset('promptPWA');
      this.suggestPWA = undefined;
    }
  }

  public handleNotifications() {
    gaTrackEvent({ category: 'dashboard:connectivity', action: 'notification-settings' });
    Router.go({ name: 'user-profile', params: { tab: 'notifications_and_emails' } });
  }
}

export default DashConnectivityController;
