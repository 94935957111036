import apollo from 'api';
import { TagInput } from 'atoms/lit';
import { Icons, userStatus } from 'components/lit';
import FullScreenModal from 'components/lit/modals/full-screen-modal.view';
import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import get from 'lodash/get';
import intersectionBy from 'lodash/intersectionBy';
import { StatusesService } from 'services';
import { Auth, NotificationManager } from 'services';
import { Router } from 'services/router.service';
import { pluralize, pluralizeCounter } from 'utils/pluralize';

import { AutonomousModal, event, ObserveEvent, ObserveResizing } from 'decorators';
import { IUser } from 'interfaces';
import IndividualsService from 'services/data/individuals.service';
import findDirectChatQuery from '../../../graphql/queries/findDirectChat.graphql';
import styles from './create-direct-modal.module.scss';

const MAX_MEMBERS = 7;

@AutonomousModal('CreateDirectModal')
class CreateDirectModal extends FullScreenModal {
  @event() public submitEvent: any;
  @event() public usersEvent: any;

  public title = 'Direct messages';
  public disabled = false;

  private users = [];
  private filteredUsers = [];
  private tagInput: TagInput = new TagInput();
  private search_string = '';

  public async onOpen() {
    this.tagInput.configure({
      onChange: (evt) => this.onSearch(evt),
      onDeleteTag: () => this.onDelete(),
      limit: MAX_MEMBERS,

      placeholder: 'Find or create direct conversation',

      keys: [
        {
          property: Router.display === 'desktop' ? 'avatar' : undefined,
          template: (user) => html`<w-avatar avatar="${user.avatar}" title="${user.name}" width="30px"> </w-avatar>`,
        },
        {
          property: 'name',
        },
      ],
    });

    // const multiUserChats = chats.filter((chat) => chat.type === 'direct' && chat.participants.length > 2)
    //   .map((chat) => chat.participants.filter((user) => user.id !== Auth.getUserId() ));
    const singleUsers = await IndividualsService.getIndividuals();

    this.users = [...singleUsers.map((user) => ([user]))];
    this.template = this.wrap(this.tmp);
    this.usersEvent();
    this.subscribeToStatuses(singleUsers);
  }

  public get body() {
    return html`
      <div class="${styles.wrapper}">
        <div class="${styles['top-block']}">
          ${this.titleView()}
          ${this.search}
        </div>

        ${this.membersLeft()}
        ${this.list()}
      </div>
    `;
  }

  private subscribeToStatuses = (users) => {
    const ids = users.flat().map((user) => user.id);

    StatusesService.subscribe(this.constructor.name, ids);
  }


  private onDelete() {
    this.usersEvent();
  }

  private onSearch(evt) {
    const { value } = evt.target;

    this.search_string = value;
    this.usersEvent();
  }

  private onSelect(users) {
    const added = users.map((user) =>
      this.tagInput.add(user));

    Promise.all(added)
      .then(() => {
        this.onSearch({ target: { value: '' } });
      })
      .catch((error) => {
        NotificationManager.showNotification({ text: error.message, type: 'warning', duration: 2000 });
      });
  }

  private async onSubmit() {
    if (this.disabled) { return; }

    const selected = this.tagInput.tags;

    if (!selected.length) {
      const text = 'Should be selected minimum 1 member';

      NotificationManager.showNotification({ text, type: 'warning', duration: 2000 });

      return;
    }

    this.disabled = true;
    this.submitEvent();

    const participantIds = [...selected.map((member) => member.id), Auth.getUserId()];
    const response = await apollo.query(
      {
        query: findDirectChatQuery,
        variables: { createIfNotExists: true, participantIds },
        fetchPolicy: 'no-cache',
      });

    if (response.errors) {
      return;
    }

    const chatId = get(response, 'data.findDirectChat.chat.id');
    const isCreate = get(response, 'data.findDirectChat.created');
    isCreate && NotificationManager.showNotification({ text: 'Chat was created', type: 'success', duration: 4000 });

    this.destroy();
    Router.go({ name: 'chats.chat', params: { chatId } });
  }

  @ObserveEvent('usersEvent')
  private list() {
    return html`
      <div class="${styles['list-wrapper']}">
        ${repeat(this.users, (users) => users[0].id, (users) => this.item(users))}
      </div>
    `;
  }

  private userAvatar(user, isDirect) {
    return html`
      <div class="${styles.avatar}">
        <w-avatar
          avatar="${user.avatar}"
          title="${user.name}"
          width="${isDirect ? '30px' : '40px'}"
          borderWidth="2"
        > </w-avatar>
        <span class="${styles.status}">
          ${ isDirect ? '' : userStatus(user.id) }
        </span>
      </div>
    `;
  }

  @ObserveResizing()
  private titleView() {
    return Router.display === 'desktop' ? this.titleTmp : nothing;
  }

  @ObserveEvent('submitEvent')
  public get submit() {
    return html`
      <button
        ?disabled=${!!this.disabled}
        class="${styles.create}"
        @click="${() => this.onSubmit()}"
      >Start</button>
    `;
  }

  @ObserveEvent('usersEvent')
  private get search() {
    return html`
      <div class="${styles['search-wrapper']}">
        ${this.tagInput.template}
        ${this.submit}
      </div>`;
  }

  @ObserveEvent('usersEvent')
  private item(users) {
    const intersect =  intersectionBy(this.tagInput.tags, users, 'id');
    const isOverMax = (this.tagInput.tags.length - intersect.length + users.length) > MAX_MEMBERS;
    const isSelected = intersect.length === users.length;
    const name = users.map((user) => user.name).join(', ');
    const members = users.length > 2 ? users.slice(0, 2) : users;
    const isDirect = users.length >= 2;

    if (isSelected || isOverMax) {
      return nothing;
    }

    return !this.search_string || name.toLowerCase().includes(this.search_string.toLowerCase()) ? html`
      <div class="${styles['user-card']}" @click="${() => this.onSelect(users)}">
        <div class="${styles.avatars}">
          ${ repeat(
              members.reverse(),
              (member: IUser) => member.id,
              (user) => this.userAvatar(user, isDirect)
          ) }
        </div>

        <div class="${styles.info}">
          <span>${name}</span>
          <span class="${styles.position}">${users.length === 1 ? users[0].position : ''}</span>
        </div>

        <div class="${styles['add-symbol']}">
          <span>${Icons.circlePlus}</span>
        </div>
      </div>
    ` : nothing;
  }

  @ObserveEvent('usersEvent')
  private membersLeft() {
    const selected = this.tagInput.tags.length;

    if (!selected) {
      return nothing;
    }

    const remainingUsers = MAX_MEMBERS - selected;

    if (!remainingUsers) {
      return html`<span class="${styles.left}">You have reached the limit of ${pluralizeCounter(MAX_MEMBERS, 'user')}.</span>`;
    }

    return html`<span class="${styles.left}">You can add ${remainingUsers} more ${pluralize(remainingUsers, 'user')}.</span>`;
  }
}

export default CreateDirectModal;
