import { Icons } from 'components/lit';
import { Observe } from 'decorators';
import { showIf } from 'directives';
import { html, nothing } from 'lit-html';
import _upperFirst from 'lodash/upperFirst';
import moment from 'moment';
import ActionController from './action.controller';
import styles from './action.module.scss';

class ActionView {
  public controller: ActionController;

  public get header() {
    return html`
      <div class="${styles.header}">
        <button @click=${() => this.controller.goBack()}>
          ${ Icons.arrowLeftLong }
        </button>
        <span>Event Details</span>
      </div>
    `;
  }

  public button(name, type = 'outline') {
    const { handleAction } = this.controller;

    return html`
      <button
        @click=${() => handleAction(name)}
        class="${styles[type]}"
      >
        ${_upperFirst(name)}
      </button>
    `;
  }

  @Observe('currentUserId')
  public member(member) {
    const { action, currentUserId } = this.controller;
    const organizer = member.id === action.organizerId ? ' (Organizer)' : '';
    const icons = {
      'accepted': Icons.circleCheckIcon('#4cb49c'),
      'invited': Icons.question(),
      'tentatively-accepted': Icons.question('#dda700'),
      'declined': Icons.errorCircle,
    };

    return html`
      <div class="${styles.member}">
        ${icons[member.status]}
        ${currentUserId === member.id ? 'You' : member.name} ${organizer}
      </div>
    `;
  }

  @Observe('showAllMembers')
  public get who() {
    const limit = 10;
    const { participantsSortedByState, showAllMembers, toggleShowAllMembers } = this.controller;
    const members = showAllMembers ? participantsSortedByState : participantsSortedByState.slice(0, limit);

    return html`
      <div class="${styles.who}">
        <b>Who:</b>
        <div>
          ${ members.map((member) => this.member(member)) }
          ${ participantsSortedByState.length > limit ? html`
            <a class="${styles.remainingToggle}" @click=${() => toggleShowAllMembers()}>
              ${showAllMembers ? 'Show less' : `Show ${participantsSortedByState.length - limit} more`}
            </a>
          ` : nothing }
        </div>
      </div>
    `;
  }

  public subTypeData(subType) {
    const { location, details } = this.controller.action.data;

    switch (subType) {
    case 'meeting':
      return html`
        <div><b>Location:</b> ${location ? location : 'There is no location'}</div>
      `;
    case 'call':
      return html`
        <div><b>Call Details:</b> ${details ? details : 'Not provided'}</div>
      `;
    default: return;
    }
  }

  @Observe('action')
  public get details() {
    const { startTime, endTime, timezone, subType } = this.controller.action.data;

    return html`
      <div class="${styles.details}">
      ${this.subTypeData(subType)}
      <div><b>Date:</b> ${moment(startTime).format('MMMM Do, YYYY')}</div>
      <div><b>From:</b> ${moment(startTime).format('h:mm A')} - ${moment(endTime).format('h:mm A')}</div>
      <div><b>Timezone:</b> ${timezone}</div>
      </div>
    `;
  }

  public get loading() {
    return html`<div class="${styles.loader}"></div>`;
  }

  @Observe('action')
  public get buttons() {
    const { organizerId, participants, status } = this.controller.action;
    const organizer = { id: organizerId };
    const {
      isAccepted,
      isCancelled,
      isPending,
      canDecline,
      canCancel,
      canChange,
    } = this.controller.actionUtils.getEventButtonCases(organizer, participants, status);

    return !isCancelled ? html`
      ${showIf(canChange, this.button('change', 'primary'))}
      ${showIf(canCancel, this.button('cancel'))}
      ${showIf(isPending, this.button('nudge'))}
      ${showIf(isAccepted, this.button('accept', 'primary'))}
      ${showIf(canDecline, this.button('decline'))}
      ${showIf(isAccepted, this.button('maybe'))}
    ` : nothing;
  }

  public get statusCanceled() {
    return html`<div class="${styles.cancelled}">Status: This event has been cancelled</div>`;
  }

  get info() {
    const { data, notes, title, status } = this.controller.action;

    return html`
      <div class="${styles.info}">
        <h1>${_upperFirst(data.subType)}: ${title}</h1>
        ${this.details}
        <div class="${styles.info_block}">
          <div class="${styles['details-info']}">
            ${this.who}
          </div>
          <div class="${styles.buttons}">
            ${this.buttons}
          </div>
        </div>
        ${showIf(status === 'cancelled', this.statusCanceled)}

        <div class="${styles.notes}">
          <h3>Notes</h3>

          ${ notes ? html`<p>${notes}</p>` : 'There are no notes associated with this event'}
        </div>
      </div>
    `;
  }

  @Observe('action')
  get template() {
    return this.controller.action ? html`
      ${ this.header }
      ${ this.info }
    ` : nothing;
  }
}

export default ActionView;
