import { render } from 'lit-html';
import { IModule } from 'services/router/router.interfaces';
import { RouterModule } from 'services/router/router.m';
import RegisterController from './register.controller';
import RegisterView from './register.view';

@RouterModule({
  name: 'w-register-module',
  controller: RegisterController,
  view: RegisterView,
})
class RegisterModule implements IModule {
  public view: RegisterView;
  public addSubscription?: (cb: () => void) => void;

  public mount(module) {
    render(this.view.template, module);
  }
}

export default RegisterModule;
