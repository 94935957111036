import { manageMount } from 'lib/lit-components-manager';
import { render } from 'lit-html';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';
import CreditsFAQController from './credits-faq.controller';
import CreditsFAQView from './credits-faq.view';

/**
 * Module CreditsFAQModule was disabled
 * according to the ticket (@link (https://thisissmith.atlassian.net/browse/TKLM-1245))
 * Now it isn't used.
 * */

@RouterModule({
  name: 'w-credits-faqs-module',
  controller: CreditsFAQController,
  view: CreditsFAQView,
})
class CreditsFAQModule {
  public view: CreditsFAQView;

  public async loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    render(this.view.template, module);
    manageMount(this.view.template, 'credits-faqs');
  }
}

export default CreditsFAQModule;
