import apollo from 'api';
import { IAchievement } from 'interfaces';
import { SignalR } from 'services/index';
import achievementsQuery from '../../graphql/queries/achievements.graphql';
import BaseService from './base.service';

class AchievementsService extends BaseService {
  public subscribeAchievementsUpdated(handler) {
    return SignalR.subscribe('AchievementAwarded', handler);
  }

  public getAchievements() {
    return apollo
      .query({ query: achievementsQuery, fetchPolicy: 'no-cache' })
      .then(({ data: { achievements } }): IAchievement[] => achievements);
  }
}

export default new AchievementsService();
