import Help from 'modules/Help';
import DefaultController from 'controllers/default';

class HelpController extends DefaultController {
  controller() {
    super.controller();
    this.addModule(Help);
  }
}

export default HelpController;
