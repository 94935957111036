import { Icons, loader } from 'components/lit';
import { Observe } from 'decorators';
import { IChat } from 'interfaces';
import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import ChatsListController from 'modules/ChatsList/chats-list.controller';
import moment from 'moment';
import { Router } from 'services/router.service';
import styles from './chats-list.module.scss';

class ChatsListView {
  public controller: ChatsListController;

  public get template() {
    return html`
      <div>
        <w-search
          variant="simple"
          @input=${this.controller.onInput.bind(this.controller)}
          @search=${this.controller.onSearch.bind(this.controller)}
          @clear=${this.controller.onSearch.bind(this.controller)}
          searchDelay="1000"
          name="search"
          placeholder="Find conversation"
        > </w-search>

      ${this.listLabel}
      ${this.chatsList}
      </div>
    `;
  }

  private chatTmp(chat) {
    const routeConfig = { name: 'chats.chat', params: { chatId: chat.id } };
    const tags = chat.tags.map((tag) => tag.label).join(', ');

    return html`
      <a
        href="${Router.generate(routeConfig)}"
        class="${styles.chat}"
        @click=${(evt) => this.controller.goToChat(evt, routeConfig, chat)}
      >
        <section>
          <w-avatar avatar="${chat.avatar}" title="${chat.title}" width="35px" borderWidth="1px"> </w-avatar>
        </section>

        <div class="${styles.info}">
          <span>${chat.title}</span>
          <span>${tags}</span>
        </div>

        <div class="${styles.last_updated}">
          ${moment(new Date(chat.lastUpdated)).fromNow()}
        </div>

        <div class="${styles.go_to}">Go to</div>
      </a>`;
  }

  @Observe('search')
  private get listLabel() {
    const label = !this.controller.search.length ? 'Channels and groups you belong to' : 'Search results';

    return html`
      <div class=${styles.list_label} >${label}</div>
    `;
  }

  private get loading() {
    return html`
      <div class="${styles.loading}">${loader}</div>
    `;
  }

  @Observe('filteredChats', 'loading')
  private get chatsList() {
    const { filteredChats, search, loading } = this.controller;

    if (!filteredChats.length && search.length) {
      return html`
      <div class="${styles.no_results}">
         ${Icons.search}
         <div>There are no chats matching your search string. Please try a different search.</div>
      </div>
    `;
    }

    return loading
      ? this.loading
      : html`
        <div class="${styles.list}">
          ${repeat(filteredChats, (chat: IChat) => chat.id, (chat) => this.chatTmp(chat))}
        </div>
    `;
  }

}

export default ChatsListView;
