import ChatFlow from 'modules/ChatFlow';
import AuthorizedController from 'controllers/authorized';

class UpsertChatController extends AuthorizedController {
  before() {
    if (!this.router.state.left || !this.router.state.left.modal || this.router.display === 'desktop') {
      this.router.go({ name: 'chat' });
    }
  }

  controller() {
    this.addModule(ChatFlow);
  }
}

export default UpsertChatController;
