export function ViewModel(TargetViewModel, data) {
  const vm = new TargetViewModel();
  const defaultFn = () => {};

  vm.OnInit = vm.OnInit || defaultFn;
  vm.OnDestroy = vm.OnDestroy || defaultFn;

  vm.data = data;

  return vm;
}
