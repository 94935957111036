import apollo from 'api';
import AuthorizedController from 'controllers/authorized';
import { IAlertItem } from 'interfaces';
import DataService from 'services/data';
import REST from 'services/rest.service';
import { Router } from 'services/router.service';
import { RouteStrategy } from 'services/router/router.interfaces';
import ReadAlertMutation from '../graphql/mutations/readAlert.graphql';
import setActiveCollective from '../graphql/mutations/setActiveCollective.graphql';
import AlertQuery from '../graphql/queries/alert.graphql';
import AlertsQuery from '../graphql/queries/alerts.graphql';

interface AlertResponseAttributes {
  data: {
    alert?: IAlertItem;
  };
}

class AlertTrackerController extends AuthorizedController {
  public controller() {
    const id = Router.params.alertId;

    if (!id) { return this.goHome(); }

    this.fetchAlert(id);
  }

  private async fetchAlert(id: string) {
    try {
      const { data: { alert } }: AlertResponseAttributes = await apollo.query({
        query: AlertQuery,
        variables: {
          id,
        },
      });

      if (!alert) { return this.goHome(); }

      this.handleAlert(alert);
    } catch (err) {
      this.goHome();
    }
  }

  private async handleAlert(item: IAlertItem) {
    const { id, read, collectiveId, attributes: { link } } = item;

    if (!link) { return this.goHome(); }

    !read && this.readAlert({
      alertId: id,
      medium: this.medium,
    });

    try {
      window.location.replace(new URL(link).href);
    } catch {
      await this.switchCollective(collectiveId);
      await DataService.run();
      Router.go({ path: link, strategy: RouteStrategy.replace });
    }
  }

  private async switchCollective(collectiveId) {
    await apollo.mutate({ mutation: setActiveCollective, variables: { command: { collectiveId } } });
  }

  private get medium() {
    return Router.query.medium || null;
  }

  private readAlert(command) {
    apollo.mutate({
      mutation: ReadAlertMutation,
      variables: {
        command,
      },
      refetchQueries: [{
        query: AlertsQuery,
      }],
    });
  }

  private async goHome() {
    const { data: { route } } = await REST.getLandingRoute();
    Router.go({ path: route, strategy: RouteStrategy.replace });
  }
}

export default AlertTrackerController;
