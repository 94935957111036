import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { InMemoryCache, IntrospectionFragmentMatcher, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import promiseToObservable from './promiseToObservable';
import { resolvers, typeDefs } from './resolvers';
import { Auth } from 'services';
import introspectionQueryResultData from './fragmentTypes.json';

const { REACT_APP_API_URL } = process.env;

const httpLink = createHttpLink({
  uri: REACT_APP_API_URL || null,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: `bearer ${Auth.getToken()}`,
  },
}));

const errorLink = onError(({ graphQLErrors, networkError = {}, operation, forward }) => {
  if (graphQLErrors.some(({ extensions }) => extensions.code === 'AUTH_NOT_AUTHORIZED' || 'AUTH_NOT_AUTHENTICATED')) {
    return promiseToObservable(Auth.refreshToken()).flatMap(() => forward(operation));
  }
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const link = ApolloLink.from([
  errorLink,
  authLink,
  httpLink,
]);

const cacheRedirects = {
  Query: {
    chat: (_, args, { getCacheKey }) =>
      getCacheKey({ __typename: 'ChatViewModel', id: args.id }),
    chatMessage: (_, args, { getCacheKey }) =>
      getCacheKey({ __typename: 'ChatMessageViewModel', id: args.id }),
  },
};

export default new ApolloClient({
  cache: new InMemoryCache({
    fragmentMatcher,
    cacheRedirects,
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'CreditsLeaderboardIndividual':
          return `CreditsLeaderboardIndividual:${object.id}:${object.position}:${object.value}`;
        default: return defaultDataIdFromObject(object);
      }
    },
  }),
  link,
  typeDefs,
  resolvers,
  connectToDevTools: true,
});
