import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import DashTrackerController from './dash-tracker.controller';
import DashTrackerView from './dash-tracker.view';

@RouterModule({
  name: 'w-dash-tracker-module',
  controller: DashTrackerController,
  view: DashTrackerView,
})
class DashTrackerModule {
  public subs: Set<() => void> = new Set([]);
  public view: DashTrackerView;

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default DashTrackerModule;
