import { Icons } from 'components/lit/index';
import { Observe } from 'decorators';
import { showIf } from 'directives';
import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import ProfileSkillsAndInterestsController from './profile-skills-and-interests.controller';
import styles from './profile-skills-and-interests.module.scss';

class ProfileSkillsAndInterestsTemplate {
  private controller: ProfileSkillsAndInterestsController;

  @Observe('skillsAndInterests', 'isEditMode')
  private get skills() {
    const { skillsAndInterests: skills, isEditMode } = this.controller;

    return html`${repeat(skills, (skill) => this.skill(skill, isEditMode))}`;
  }

  private skill(skill, isEditMode) {
    return html`
      <div class=${styles.skill}>
        ${showIf(isEditMode, this.skillRemoveControl(skill))}
        <span class=${styles.skill_name}>${skill.label}</span>
        ${isEditMode ? this.skillEditControls(skill) : this.skillExperience(skill)}
      </div>
    `;
  }

  private skillRemoveControl(skill) {
    return html`
      <div class=${styles.skill_remove} @click=${() => this.controller.onRemove(skill.id)}>
        ${Icons.circleMinus}
      </div>
    `;
  }

  private skillExperience(skill) {
    return html`<span class=${styles.skill_experience}>${skill.yearsOfExperience}+</span>`;
  }

  private skillEditControls(skill) {
    return html`
      <div class=${styles.skill_controls}>
        <div class=${styles.left_control} @click=${() => this.controller.onDecr(skill.id)}>
          ${Icons.circleArrowLeft}
        </div>

        ${this.skillExperience(skill)}

        <div class=${styles.right_control} @click=${() => this.controller.onIncr(skill.id)}>
          ${Icons.circleArrowRight}
        </div>
      </div>
    `;
  }

  @Observe('suggestedSkills')
  private get suggestedSkills() {
    const { suggestedSkills } = this.controller;

    if (!suggestedSkills.length) {
      return nothing;
    }

    return html`
      <div class=${styles.suggested}>
        <h4 class=${styles.subtitle}>Suggested</h4>

        <div class=${styles.skills_wrapper}>
          ${repeat(suggestedSkills, (skill) => this.suggestedSkill(skill))}
        </div>
      </div>
    `;
  }

  private suggestedSkill(skill) {
    return html`
      <div class=${styles.skill}>
        <span class=${styles.skill_name}>${skill.label}</span>

        <button
          class=${styles.button}
          @click=${() => this.controller.onAddSkill(skill)}
        >
          Add
        </button>
      </div>
    `;
  }

  @Observe('isEditMode', 'next', 'loading')
  private get editButton() {
    const { next, loading, isEditMode } = this.controller;

    const saveButton = html`
      <button
        ?disabled=${!next}
        class="w-button primary"
        @click=${() => this.controller.onSaveSkills()}
      >
        ${loading ? 'Updating...' : 'Save'}
      </button>
    `;

    const editButton = html`
      <button
        class=${styles['outlined-button']}
        @click=${() => this.controller.onEnableEditMode()}
      >
        Edit
      </button>
    `;

    return isEditMode ? saveButton : editButton;
  }

  get template() {
    return html`
      <div class=${styles['cards-wrapper']}>
        <div class=${styles.header}>
          <h3 class=${styles.title}>Skills and Interests</h3>
          <p class=${styles.description}>
            Build out your list of skills and interests for better matches and connections.
          </p>
        </div>

        <div class=${styles['select-skills-wrapper']}>
          <div class=${styles['select-skills-header']}>
            <h4 class=${styles.subtitle}>Selected skills & interests</h4>
            <div class=${styles.control}>
              ${this.editButton}
              <div class=${styles['col-desc']}>years experience</div>
            </div>
          </div>

          ${this.skills}
        </div>

        <div class=${styles.input}>
          <div class=${styles.label}>Search for skills and interests</div>
          <input
            placeholder="Search"
            @input=${(e) => this.controller.onSearchSkills(e)}
          />
        </div>

        ${this.suggestedSkills}
      </div>
    `;
  }
}

export default ProfileSkillsAndInterestsTemplate;
