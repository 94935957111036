import AlertTrackerController from 'controllers/alert-tracker';
import AlertsController from 'controllers/alerts';
import AuthorizedController from 'controllers/authorized';
import CalendarController from 'controllers/calendar';
import CalendarEventController from 'controllers/calendar-event';
import ChatController from 'controllers/chat';
import CreditsController from 'controllers/credits';
import DashController from 'controllers/dash';
import DirectController from 'controllers/direct';
import HelpController from 'controllers/help';
import IndividualProfileController from 'controllers/individual-profile';
import InfoController from 'controllers/info';
import LogoutController from 'controllers/logout';
import MatchController from 'controllers/match';
import MessagesController from 'controllers/messages';
import NotAuthorizedController from 'controllers/not-authorized';
import ReportController from 'controllers/report';
import ScheduleController from 'controllers/schedule';
import UpsertChatController from 'controllers/upsert_chat';
import UserProfileController from 'controllers/user-profile';
import { manageMount } from 'lib/lit-components-manager';
import mockClients from 'mock/pre-launch/pageData';
import Layouts from 'view';

export const PROFILE_TABS = [
  {
    label: 'General',
    path: 'general',
  },
  {
    label: 'Contact Details',
    path: 'contact_details',
  },
  {
    label: 'Skills and interests',
    path: 'skills_and_interests',
  },
  {
    label: 'Professional development',
    path: 'professional_development',
  },
  {
    label: 'Settings',
    path: 'settings',
  },
  {
    label: 'Notifications and emails',
    path: 'notifications_and_emails',
  },
  {
    label: 'Reset password',
    path: 'reset_password',
  },
];

export const CREDITS_TABS = [
  {
    label: 'Achievements',
    path: 'achievements',
  },
  {
    label: 'Leaderboards',
    path: 'leaderboards',
  },
  {
    label: 'Streaks',
    path: 'streaks',
  },

  /**
   * Module Credits FAQ section was disabled
   * according to the ticket (@link (https://thisissmith.atlassian.net/browse/TKLM-1245))
   * Now it isn't used.
   *
   * This section was initialized in module CreditsFAQModule @see /modules/CreditsFAQ
   *
   * {
   *   label: 'FAQs',
   *   path: 'faqs',
   * }
   * */
];

const ID_REGEXP = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
const PROFILE_TAB_REGEXP = new RegExp(PROFILE_TABS.map(({ path }) => `^${path}$`).join('|'));

const CREDITS_TAB_REGEXP = new RegExp(CREDITS_TABS.map(({ path }) => `^${path}$`).join('|'));
const CLIENTS_TAB_REGEX = new RegExp(Object.keys(mockClients).map((k) => `^${k}$`).join('|'));

export default [
  {
    name: 'get-link',
    pattern: '/get-link',
    mobile: () => Layouts.getLink('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.getLink('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: NotAuthorizedController,
  },
  {
    name: 'login',
    pattern: '/login',
    mobile: () => Layouts.login('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.login('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: NotAuthorizedController,
  },
  {
    name: 'forgot-password',
    pattern: '/forgot-password',
    mobile: () => Layouts.forgotPassword('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.forgotPassword('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: NotAuthorizedController,
  },
  {
    name: 'reset-password',
    pattern: '/reset-password',
    mobile: () => Layouts.resetPassword('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.resetPassword('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: NotAuthorizedController,
  },
  {
    name: 'temporary',
    pattern: '/temporary',
    mobile: () => Layouts.temporary('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.temporary('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: NotAuthorizedController,
  },
  {
    name: 'register-interest',
    pattern: '/register-interest',
    mobile: () => Layouts.registerInterest('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.registerInterest('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: NotAuthorizedController,
  },
  {
    name: 'join',
    pattern: '/join',
    mobile: () => Layouts.join('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.join('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: AuthorizedController,
    private: true,
    loader: false,
  },
  {
    name: 'before-continue',
    pattern: '/before-continue',
    mobile: () => Layouts.beforeContinue('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.beforeContinue('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: AuthorizedController,
    private: true,
    loader: false,
  },
  {
    name: 'will-be-in-touch',
    pattern: '/will-be-in-touch',
    mobile: () => Layouts.willBeInTouch('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.willBeInTouch('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: AuthorizedController,
  },
  {
    name: 'register',
    pattern: '/register',
    mobile: () => Layouts.register('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.register('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: NotAuthorizedController,
  },
  {
    name: 'registration-closed',
    pattern: '/registration-closed',
    mobile: () => Layouts.registrationClosed('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.registrationClosed('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: NotAuthorizedController,
  },
  {
    name: 'match',
    pattern: '/match',
    loader: false,
    private: true,
    mobile: () => Layouts.match('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.match('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: MatchController,
  },
  {
    name: 'no-match',
    pattern: '/no-match',
    loader: false,
    private: true,
    mobile: () => Layouts.noMatch('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.noMatch('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: AuthorizedController,
  },
  {
    name: 'chat',
    pattern: '/',
    private: true,
    loader: true,
    mobile: () => Layouts.chat('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.chat('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: ChatController,
  },
  {
    name: 'chat.create',
    pattern: '/create',
    private: true,
    loader: true,
    mobile: () => Layouts['chat.create']('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts['chat.create']('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: UpsertChatController,
  },
  {
    name: 'chat.edit',
    pattern: '/edit/:chatId',
    private: true,
    loader: true,
    mobile: () => Layouts['chat.edit']('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts['chat.edit']('desktop').then((tmp) => manageMount(tmp, 'App')),
    patternOptions: {
      chatId: ID_REGEXP,
    },
    controller: UpsertChatController,
  },
  {
    name: 'help',
    pattern: '/help',
    private: true,
    loader: true,
    mobile: () => Layouts.help('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.help('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: HelpController,
  },
  {
    name: 'calendar',
    pattern: '/calendar',
    private: true,
    loader: true,
    mobile: () => Layouts.calendar('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.calendar('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: CalendarController,
  },
  {
    name: 'calendar.event',
    pattern: '/calendar/event/:actionId',
    private: true,
    loader: true,
    mobile: () => Layouts['calendar.event']('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts['calendar.event']('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: CalendarEventController,
  },
  {
    name: 'credits',
    pattern: '/credits/:userId/:tab',
    private: true,
    loader: true,
    hideLoader: true,
    mobile: () => Layouts.credits('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.credits('desktop').then((tmp) => manageMount(tmp, 'App')),
    patternOptions: {
      userId: ID_REGEXP,
      tab: CREDITS_TAB_REGEXP,
    },
    controller: CreditsController,
  },
  {
    name: 'chats.info',
    pattern: '/chats/:chatId/info',
    private: true,
    loader: true,
    mobile: () => Layouts['chats.info']('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts['chats.info']('desktop').then((tmp) => manageMount(tmp, 'App')),
    patternOptions: {
      chatId: ID_REGEXP,
    },
    controller: InfoController,
  },
  {
    name: 'chats.chat',
    pattern: '/chats/:chatId/direct',
    private: true,
    loader: true,
    mobile: () => Layouts['chats.chat']('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts['chats.chat']('desktop').then((tmp) => manageMount(tmp, 'App')),
    patternOptions: {
      chatId: ID_REGEXP,
    },
    controller: DirectController,
  },
  {
    name: 'chats.activity',
    pattern: '/chats/:chatId/activity',
    private: true,
    loader: true,
    mobile: () => Layouts['chats.activity']('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts['chats.activity']('desktop').then((tmp) => manageMount(tmp, 'App')),
    patternOptions: {
      chatId: ID_REGEXP,
    },
    controller: DirectController,
  },
  {
    name: 'chats.actions.edit',
    pattern: '/chats/:chatId/actions/:actionId/edit',
    private: true,
    loader: true,
    mobile: () => Layouts['chats.schedule']('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts['chats.schedule']('desktop').then((tmp) => manageMount(tmp, 'App')),
    patternOptions: {
      chatId: ID_REGEXP,
      actionId: ID_REGEXP,
    },
    controller: ScheduleController,
  },
  {
    name: 'chats.actions.create',
    pattern: '/chats/:chatId/actions/create',
    private: true,
    loader: true,
    mobile: () => Layouts['chats.schedule']('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts['chats.schedule']('desktop').then((tmp) => manageMount(tmp, 'App')),
    patternOptions: {
      chatId: ID_REGEXP,
    },
    controller: ScheduleController,
  },
  {
    name: 'chats.actions.action',
    pattern: '/chats/:chatId/actions/:actionId/info',
    private: true,
    loader: true,
    mobile: () => Layouts['chats.sub']('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts['chats.sub']('desktop').then((tmp) => manageMount(tmp, 'App')),
    patternOptions: {
      chatId: ID_REGEXP,
      actionId: ID_REGEXP,
    },
    controller: DirectController,
  },
  {
    name: 'chats.actions.list',
    pattern: '/chats/:chatId/actions/list',
    private: true,
    loader: true,
    mobile: () => Layouts['chats.sub']('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts['chats.sub']('desktop').then((tmp) => manageMount(tmp, 'App')),
    patternOptions: {
      chatId: ID_REGEXP,
    },
    controller: DirectController,
  },
  {
    name: 'chats.connect',
    pattern: '/chats/:chatId/connect',
    private: true,
    loader: true,
    mobile: () => Layouts['chats.info']('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts['chats.info']('desktop').then((tmp) => manageMount(tmp, 'App')),
    patternOptions: {
      chatId: ID_REGEXP,
    },
    controller: MessagesController,
  },
  {
    name: 'dashboard',
    pattern: '/dashboard',
    private: true,
    loader: true,
    mobile: () => Layouts.dash('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.dash('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: DashController,
  },
  {
    name: 'alert-tracker',
    pattern: '/alert/:alertId',
    private: true,
    loader: false,
    mobile: () => {},
    desktop: () => {},
    controller: AlertTrackerController,
  },
  {
    name: 'alerts',
    pattern: '/alerts',
    private: true,
    loader: true,
    mobile: () => Layouts.alerts('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.alerts('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: AlertsController,
  },
  {
    name: 'chats.report',
    pattern: '/chat/:chatId/report/:messageId',
    private: true,
    loader: true,
    mobile: () => Layouts.report('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.report('desktop').then((tmp) => manageMount(tmp, 'App')),
    patternOptions: {
      chatId: ID_REGEXP,
      messageId: ID_REGEXP,
    },
    controller: ReportController,
  },
  {
    name: 'user-profile',
    pattern: '/profile/:tab',
    private: true,
    loader: true,
    mobile: () => Layouts['user-profile']('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts['user-profile']('desktop').then((tmp) => manageMount(tmp, 'App')),
    patternOptions: {
      tab: PROFILE_TAB_REGEXP,
    },
    controller: UserProfileController,
  },
  {
    name: 'individual',
    pattern: '/individual/:userId',
    private: true,
    loader: true,
    mobile: () => Layouts.individual('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.individual('desktop').then((tmp) => manageMount(tmp, 'App')),
    patternOptions: {
      userId: ID_REGEXP,
    },
    controller: IndividualProfileController,
  },
  {
    name: 'logout',
    pattern: '/logout',
    private: true,
    loader: true,
    mobile: () => {},
    desktop: () => {},
    controller: LogoutController,
  },
  {
    name: 'launch',
    pattern: '/launch/:client',
    private: true,
    loader: false,
    pass: false,
    mobile: () => Layouts.launch('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.launch('desktop').then((tmp) => manageMount(tmp, 'App')),
    patternOptions: {
      client: CLIENTS_TAB_REGEX,
    },
    controller: AuthorizedController,
  },
  {
    name: 'no-collective',
    pattern: '/not-assigned',
    private: true,
    loader: false,
    pass: false,
    mobile: () => Layouts.noCollective('mobile').then((tmp) => manageMount(tmp, 'App')),
    desktop: () => Layouts.noCollective('desktop').then((tmp) => manageMount(tmp, 'App')),
    controller: AuthorizedController,
  },
];
