import { observable } from 'decorators';
import { gaTrackEvent } from 'services/analytics.service';
import { Router } from 'services/router.service';
import DashPreMatchModule from './dash-prematch.module';

class DashPreMatchController {
  public module: DashPreMatchModule;
  @observable(null) public prematchActions: any;

  constructor() {
    this.handleAction = this.handleAction.bind(this);
  }

  public async OnInit() {
    this.prematchActions = [
      {
        id: 'update',
        title: 'Get your profile up-to-date so people can find out about you',
        label: 'Update',
        route: 'user-profile',
        params: {
          tab: 'general',
        },
      },
      {
        id: 'watch',
        title:
          "Watch a video on Visibility & Vulnerability from WERKIN's CEO. Think about what lessons you can take into the programme",
        label: 'Watch',
        route: 'https://www.youtube.com/watch?v=YXHP9fBljCk',
        external: true,
      },
      // {
      //   id: 'thing',
      //   title: 'Do a product, tour so you know where everything is and how to use it',
      //   label: 'Take tour',
      //   route: '#',
      // },
    ];
  }

  public handleAction(action) {
    const { id, title, route, external, params } = action;

    gaTrackEvent({ category: 'dashboard:prematch', action: id, label: title });
    if (!external) {
      return Router.go({ name: route, params });
    }

    return window.open(route);
  }
}

export default DashPreMatchController;
