import { render } from 'lit-html';
import WillBeInTouchController from 'modules/WillBeInTouch/will-be-in-touch.controller';
import WillBeInTouchView from 'modules/WillBeInTouch/will-be-in-touch.view';
import { RouterModule } from 'services/router/router.m';

@RouterModule({
  name: 'w-will-be-in-touch-module',
  view: WillBeInTouchView,
  controller: WillBeInTouchController,
})
class WillBeInTouchModule {
  public view: WillBeInTouchView;

  public mount(module) {
    render(this.view.template, module);
  }
}

export default WillBeInTouchModule;
