import { render } from 'lit-html';
import ActionController from 'modules/Action/action.controller';
import ActionView from 'modules/Action/action.view';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';

@RouterModule({
  name: 'w-action-module',
  view: ActionView,
  controller: ActionController,
})
class ActionModule {
  public subs: Set<() => void> = new Set([]);
  public view: ActionView;

  public async loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default ActionModule;
