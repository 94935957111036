import DirectController from 'controllers/direct';
import Info from 'modules/Info/info.module';

class InfoController extends DirectController {
  controller() {
    super.controller();
    this.addTSModule(Info);
  }
}

export default InfoController;
