import { ValidationStrategy } from 'components/custom/enums';
import { Icons } from 'components/lit';
import { Observe } from 'decorators';
import { html } from 'lit-html';
import ReportController from './report.controller';
import styles from './report.module.scss';


class ReportView {
  public controller: ReportController;

  get BackButton() {
    return html`
      <button @click=${() => this.controller.onCancel()}> ${Icons.arrowLeftLong} Back </button>
    `;
  }

  @Observe('message')
  get message() {
    const { message } = this.controller;

    return html`
      <div class="${styles.block}">
        <wwc-message
          .type="${'message'}"
          message=${JSON.stringify(message)}
        > </wwc-message>
      </div>
    `;
  }

  get report() {
    return html`
      <w-validation-box
        label="Tell us why you are reporting this message."
        strategy="${ValidationStrategy.delay}"
      >
        <w-textarea
          minlength="10"
          rows="6"
          name="report"
          placeholder="Type notes"
        >
      </w-validation-box>
    `;
  }

  @Observe('loading', 'valid')
  public get buttons() {
    return html`
      <section>
        <div class="${styles.buttons_wrapper}">
          <button
            class="w-button outlined"
            ?disabled=${this.controller.loading}
            @click=${() => this.controller.onCancel()}
          >
            Cancel
          </button>
          <button
            class="w-button primary"
            type="submit"
            ?disabled=${this.controller.loading || !this.controller.valid}
          >
            ${this.controller.loading ? 'Reporting...' : 'Report'}
          </button>
        </div>
      </section>
    `;
  }

  get template() {
    return html`
      <div class="${styles.wrapper}">
        <div class="${styles.body}">
          <div>
            ${this.BackButton}
          </div>

          <h1>Report message?</h1>

          <form
            @changeForm=${(evt) => this.controller.onFormChange(evt)}
            @submit=${(evt) => this.controller.onReport(evt)}
          >
            ${this.message}
            ${this.report}
            ${this.buttons}
          </form>

        </div>
      </div>
    `;
  }
}

export default ReportView;
