import apollo from 'api';
import { observable } from 'decorators';
import { ILearningAndDevelopment } from 'interfaces/profile/professional-development.interface';
import { gaTrackEvent } from 'services/analytics.service';
import { Router } from 'services/router.service';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';
import DashGoalsModule from './dash-goals.module';

class DashGoalsController {
  public module: DashGoalsModule;
  @observable([]) public userGoals: ILearningAndDevelopment[];
  @observable(false) public hasGoals: boolean;

  public async OnInit() {
    this.fetchUserGoals();
  }

  private async fetchUserGoals() {
    const observe = apollo.watchQuery({
      query: ProfileQuery,
    }).subscribe({
      next: ({
        data: {
          profile: {
            professionalDevelopment: {
              learningAndDevelopmentAreas,
            },
          },
        },
      }) => {
        this.userGoals = learningAndDevelopmentAreas;
        this.hasGoals = learningAndDevelopmentAreas.some(({ goal }) => !!goal);
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public handleChange() {
    gaTrackEvent({ category: 'dashboard:goals', action: 'update', label: 'Update goals' });
    Router.go({
      hash: {
        ProfileEditingModal: true,
        root: 'professionalDevelopment',
        targetEntity: 'learningAndDevelopmentAreas',
      },
    });
  }

  public handleShare() {
    gaTrackEvent({ category: 'dashboard:goals', action: 'share', label: 'Share goals' });
    Router.go({
      hash: { ShareGoals: true },
    });
  }
}

export default DashGoalsController;
