import {customElement, LitElement, property} from 'lit-element';
import { html } from 'lit-html';
import { styleMap } from 'lit-html/directives/style-map';
import get from 'lodash/get';

@customElement('w-avatar')
export class WAvatar extends LitElement {
  @property({ type: String || null || undefined }) public avatar: string;
  @property({ type: String }) public title: string = 'AV';
  @property({ type: String }) public background: string = window.COLORS.PASTEL_YELLOW.hash;
  @property({ type: String }) public color: string = window.COLORS.DEEP_TEAL.hash;
  @property({ type: Number }) public borderwidth: number = 1;
  @property({ type: String }) public bordercolor: string = window.COLORS.GREYSTASH_TEAL.hash;
  @property({ type: String }) public height: string;
  @property({ type: String }) public width: string = '60px';

  get generalStyles() {
    return {
      alignItems: 'center',
      justifyCcontent: 'center',
      display: 'flex',
      height: this.height || this.width,
      maxHeight: this.height || this.width,
      maxWidth: this.width,
      width: this.width,
      border: `${this.borderwidth}px solid ${this.bordercolor}`,
      borderRadius: '100%',
    };
  }

  get image() {
    const style = styleMap({
      ...this.generalStyles,
      backgroundImage: `url('${this.avatar}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    });

    return html`
      <div style=${style}></div>
     `;
  }

  public attributeChangedCallback(name, oldValue, newValue) {
    this[name] = newValue;
    this.performUpdate();
  }

  get placeholder() {
    const fontSize = parseInt(
      (this.height && this.height.replace('px', ''))
      || this.width.replace('px', '')
    ) / 2.5;

    const style = styleMap({
      ...this.generalStyles,
      backgroundColor: this.background,
      fontSize: `${fontSize}px`,
      color: this.color,
      justifyContent: 'center',
    });

    const [ first, second ] = this.title.split(' ');
    const initials = [ get(first, '[0]', ''), get(second, '[0]', '')].join('');

    return html`<div style=${style}>${initials.toUpperCase()}</div>`;
  }

  public render() {
    return !!this.avatar && !['null', 'undefined'].includes(this.avatar)
      ? this.image
      : this.placeholder;
  }
}
