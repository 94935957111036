import { html } from 'lit-html';
import styles from './form-submit-button.module.scss';
import Component from 'services/templator/component';

export class FormSubmitButtonView extends Component {
  get template() {
    const { title, next, loading, loadingText } = this.options;

    return html`
      <button is="w-button" class="${styles.submit}" type="submit" ?disabled=${!next}>${loading ? loadingText || `${title}ing...` : title }</button>
    `;
  }
}
