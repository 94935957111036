import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import PollerController from './poller.controller';
import PollerView from './poller.view';

@RouterModule({
  name: 'w-poller-module',
  controller: PollerController,
  view: PollerView,
})
class PollerModule {
  public subs: Set<() => void> = new Set([]);
  public view: PollerView;
  public controller: PollerController;

  public mount(module) {
    this.controller.destroy = module.destroy;
    this.controller.pollId = module.pollId;

    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default PollerModule;
