import store from '@werkin/store';
import apollo from 'api';
import schedulePollMutation from '../../graphql/mutations/schedulePoll.graphql';

class PollService {
  public pollCreationHandler = (stringifiedPoll) => {
    const poll = JSON.parse(stringifiedPoll);

    setTimeout(() => store.set('activePoll', poll), 1200);
  }

  public schedulePoll() {
    return apollo.mutate({ mutation: schedulePollMutation,  variables: { command: {} } });
  }
}

export default new PollService();
