import { isURL } from '@werkin/lib/validators';
import { html, nothing } from 'lit-html';
import { get, keyBy } from 'lodash';
import iconsImages from './icons';
import styles from './icons.module.scss';

const ICON_TYPES = ['primary', 'secondary'];

const DefaultIcon = (objIcons, type) => {
  const defaultValue = get(objIcons, `${type}.default`);

  return defaultValue ? html`<div class="${styles[type]}">${defaultValue}</div>` : nothing;
};

const UrlIcon = (objIcons, type) =>
  html`<img src="${get(objIcons, `${type}.value`)}" class="${styles[type]}" alt="${type}"/>`;

const LocalIcon = (objIcons, type) => {
  const Icon = iconsImages[get(objIcons, `${type}.value`)] || null;

  return Icon && Icon(styles[type]);
};

const icon = (type, objIcons) => {
  const value = get(objIcons, `${type}.value`);
  const Icon = isURL(value) ? UrlIcon : LocalIcon;

  return value && Icon ? Icon(objIcons, type) : DefaultIcon(objIcons, type);
};

export default (alertIcons) => {
  const objIcons = keyBy(alertIcons, ({ type }) => type);

  return html`
    <div class="${styles.icon}">
      ${ICON_TYPES.map((type) => icon(type, objIcons))}
    </div>
  `;
};
