import React from 'react';
import PropsTypes from 'prop-types';
import { classNames } from '@werkin/lib/helpers';
import styles from './Textarea.module.scss';

/**
 * @since 0.1.0
 * */
const Textarea = ({
  cols,
  rows,
  value,
  onChange,
  className,
  onValidate,
  reference,
  ...rest
}) => {

  const fieldClasses = classNames('base', styles, className);

  const handleChange = evt => {
    if (evt && evt.target) {
      evt.target.valid = onValidate(evt.target.value);
    }

    onChange(evt);
  };

  return (
    <textarea
      ref={reference}
      cols={cols}
      rows={rows}
      value={value}
      onChange={handleChange}
      className={fieldClasses}
      {...rest}
    />
  );
};

Textarea.defaultProps = {
  rows: '6',
  cols: '30',
  onValidate: () => true,
};

Textarea.propTypes = {
  /**
   * Component's cols
   * */
  cols: PropsTypes.string,
  /**
   * Component's rows
   * */
  rows: PropsTypes.string,
  /**
   * Component's value
   * */
  value: PropsTypes.string.isRequired,
  /**
   * Handler for 'change' event
   * */
  onChange: PropsTypes.func.isRequired,
  /**
   * Event to pass validation
   * */
  onValidate: PropsTypes.func,
  /**
   * Extended className
   * */
  className: PropsTypes.string,
  /**
   * Reference to textarea
   * */
  reference: PropsTypes.object,
};

export default Textarea;
