import moment from 'moment';

/** Navigation configuration */
export const nav = () => ({
  scheduling_options: {
    name: 'scheduling_options',
    nextIcon: 'circleArrowRight',
    nextLabel: 'Next',
    backIcon: 'arrowLeftLong',
    backLabel: '',
    withOptionName: true,
  },
  meet_time: {
    nextScreen: 'details',
    prevScreen: 'scheduling_options',
    name: 'meet_time',
    nextIcon: 'circleArrowRight',
    nextLabel: 'Next',
    backIcon: 'circleArrowLeft',
    backLabel: 'Back',
    withOptionName: true,
  },
  select_timezone: {
    prevScreen: 'meet_time',
    name: 'select_timezone',
    nextIcon: '',
    nextLabel: '',
    backIcon: 'arrowLeftLong',
    backLabel: '',
  },
  details: {
    prevScreen: 'meet_time',
    nextScreen: 'actions_list',
    name: 'details',
    nextIcon: 'circleArrowRight',
    nextLabel: 'Next',
    backIcon: 'circleArrowLeft',
    backLabel: 'Back',
    withOptionName: true,
  },
});

export default (config, action = {}) => {
  let data = {};
  let duration = { value: 15, entity: 'minute' };

  if (action.data) {
    data = action.data;
    const start = moment(data.startTime);
    const end = moment(data.endTime);
    const diffInMin = Math.round(moment.duration(end.diff(start)).as('minutes'));
    duration = {
      value: diffInMin && diffInMin >= 60 ? (diffInMin - diffInMin % 60) / 60 : diffInMin,
      entity: diffInMin >= 60 ? 'hour' : 'minute',
    };
  }

  return ({
    ...config,

    flow: null,
    id: action.id,
    chatId: null,
    tab: 0,
    type: data.subType,
    duration: duration || { value: 15, entity: 'minute' },
    available: null,
    date: data.startTime && moment(data.startTime).toDate(),
    minDate: null,
    cronofyView: data.cronofyView,
    selectedSlot: null,
    timezone: data.timezone,
    title: {
      value: action.title || '',
      placeholder: 'Type title here',
      label: 'Title*',
      touched: false,
      valid: null,
      error: null,
    },

    location: {
      value: data.location || '',
      placeholder: 'Type location here',
      label: 'Location (Optional)',
      valid: null,
      error: null,
      hiddenValidationIcon: true,
    },

    details: {
      value: data.details || '',
      placeholder: 'Entering conferencing details / Dial-in number',
      label: 'Call details (Optional)',
      valid: null,
      error: null,
      hiddenValidationIcon: true,
    },

    notes: {
      value: action.notes || '',
      placeholder: 'Type notes here',
      label: 'Add notes here (Optional)',
      valid: null,
      error: null,
      fillToEnd: true,
      hiddenValidationIcon: true,
    },

    participants: action.participants || [],
    isNew: !action.id,
  });
};
