import BaseService from 'services/data/base.service';
import ActivitiesQuery from '../../graphql/queries/activities.graphql';
import CollectivesService from './collectives.service';

class ActivitiesService extends BaseService {
  public async load() {
    this.setLoadingState('Preparing your activity feed');

    const activeCollective = await CollectivesService.getActiveCollective();

    return this.queryPrefetcher(
      ActivitiesQuery,
      'channelActivityFeed',
      {
        chatId: activeCollective.associatedChatId,
      }
    );
  }

  public activityFeedUpdatedHandler = () => this.load();
}

export default new ActivitiesService();
