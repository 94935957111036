import { html } from 'lit-html';
import Fragment from 'services/templator/fragment';

class TimezoneFragment extends Fragment {
  get template() {
    const date = this.module.moment(this.data.date).tz(this.data.timezone).format('dddd, D, MMMM hh:mm A');

    return html`
      <section>
        <div class="${this.styles['choose-timezone']}">
          Your timezone
          <button class="w-button outlined" @click=${() => this.controller.chooseTimezone()}>Change</button>
        </div>
      </section>

      <div class="${this.styles['like-input']}">${this.data.timezone.replace(new RegExp('/', 'g'), ' / ')}</div>
      <div class="${this.styles['like-input']}">${date}</div>
    `;
  }
}

export default TimezoneFragment;
