import store from '@werkin/store';
import apollo from 'api';
import { observable } from 'decorators';
import { REST } from 'services';
import collectivesService from 'services/data/collectives.service';
import { Router } from 'services/router.service';
import startParticipationMutation from '../../graphql/mutations/startParticipation.graphql';

class NoMatchController {
  @observable(false) public loading: boolean;

  public async startParticipation(collective) {
    return apollo.mutate({
      mutation: startParticipationMutation,
      variables: {
        command: {
          collectiveId: collective.id,
        },
      },
    });
  }

  public async getStarted() {
    store.set('loading', true);

    this.loading = true;

    const activeCollective = await collectivesService.getActiveCollective();
    await this.startParticipation(activeCollective);

    const { data: { route } } = await REST.getLandingRoute();

    Router.go({ path: route });
  }
}

export default NoMatchController;
