import apollo from 'api';
import { render } from 'lit-html';
import sortBy from 'lodash/sortBy';
import { Auth } from 'services';
import { mentionsList } from 'services/editor/mentions/mentions.view';
import { Router } from 'services/router.service';
import ChatQuery from '../../../graphql/queries/chat/Chat.graphql';
import constants from './constants';
import styles from './mentions.module.scss';

export default (Quill) => {
  const Module = Quill.import('core/module');

  class MentionsModule extends Module {
    private quill: any;
    private lastRange: any;
    private selectedIndex: number = 0;
    private matchRanges: Map<string, any>;
    private container: HTMLElement = document.createElement('div');

    constructor(quill, options) {
      super(quill, options);

      this.quill = quill;
      this.container.classList.add(styles.container);

      this.quill.scrollingContainer.appendChild(this.container);
      this.quill.scrollingContainer.classList.add(styles.scrollingContainer);

      this.quill.on('text-change', this.onTextChange.bind(this));
      this.quill.on('selection-change', this.updateMentionsListState.bind(this));

      Router.listen(() => {
        if (Router.route.name === 'chats.chat') {
          this.quill.blur();
          this.selectedIndex = 0;
          this.lastRange = undefined;
        }
      });
    }

    private onTextChange(...props) {
      const [ source ] = props.reverse();

      this.updateMatchRanges();

      if (source === 'user') {
        this.selectedIndex = 0;
        const selection = this.quill.getSelection();

        this.updateMentionsListState(selection);
      }
    }

    private oldBindings = {
      [constants.UP]: [],
      [constants.DOWN]: [],
      [constants.ENTER]: [],
    };

    private keyHandlers = {
      [constants.UP]() {
        this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : 0;
        this.toggleMentionList();
        const listEl = this.container.querySelector(`.${constants.ITEM_CLASS_PREFIX + this.selectedIndex}`);

        if (listEl) {
          const scrollPosition = listEl.clientHeight * this.selectedIndex;
          this.container.scrollTo(0, scrollPosition);
        }
      },

      [constants.DOWN]() {
        this.selectedIndex = this.selectedIndex + 1;
        this.toggleMentionList();

        const listEl = this.container.querySelector(`.${constants.ITEM_CLASS_PREFIX + this.selectedIndex}`);
        const index = this.selectedIndex - 3 < 0 ? 0 : this.selectedIndex - 2;

        if (listEl) {
          const scrollPosition = listEl.clientHeight * index;
          this.container.scrollTo(0, scrollPosition);
        }
      },

      async [constants.ENTER]() {
        const users = await this.getFilteredUsers();
        this.selectHandler(users[this.selectedIndex]);
      },
    };

    private toggleMentionList() {
      const mention = this.getSelectedMention();

      if (mention) {
        this.openMentionsList();
      } else {
        this.closeMentionsList();
      }
    }

    private updateMentionsListState(selection) {
      if (!selection || !selection.index) {
        return this.closeMentionsList();
      }

      this.lastRange = selection;
      this.selectedIndex = 0;
      this.toggleMentionList();
    }

    private selectHandler(user) {
      const mention = this.getSelectedMention();
      const item = { id: user.id, value: user.name, denotationChar: mention[0] };
      const [ start, end ] = this.matchRanges.get(mention);
      const embedIndex = start <= 0 ? start : start + 1;

      this.quill.deleteText(embedIndex, end - start);
      this.quill.insertEmbed(embedIndex, 'mention', item, Quill.sources.USER);
      this.quill.setSelection(embedIndex + 1);

      this.lastRange = this.quill.getSelection();

      this.closeMentionsList();
    }

    private async renderList() {
      const select = (user) => this.selectHandler(user);
      const close = () => this.closeMentionsList();
      const users = await this.getFilteredUsers();

      if (this.selectedIndex < 0) {
        this.selectedIndex = users.length + this.selectedIndex;
      }

      if (this.selectedIndex > (users.length - 1)) {
        this.selectedIndex = 0;
      }

      render(mentionsList(users, this.selectedIndex, select, close), this.container);
    }

    private async getFilteredUsers() {
      const mention = this.getSelectedMention();
      const userId = Auth.getUserId();

      const { data: { chat } } = await apollo.query({
        query: ChatQuery,
        variables: {
          id: Router.params.chatId,
        },
      });

      const comparator = (user) => {
        const [ first, last ] = user.name.split(' ');

        if (!user.alias || !mention) { return; }

        return (user.alias.toLowerCase().startsWith(mention.slice(1).toLowerCase().trim()) && user.id !== userId) ||
          (first && first.toLowerCase().startsWith(mention.slice(1).toLowerCase().trim()) && user.id !== userId) ||
          (last && last.toLowerCase().startsWith(mention.slice(1).toLowerCase().trim()) && user.id !== userId);
      };

      return sortBy(chat.participants.filter(comparator), [(user) => user.alias.toLowerCase()]);
    }

    private updateMatchRanges() {
      const length = this.quill.getLength();
      const text = this.quill.getContents(0, length - 1).reduce((acc, op) => {
        if (typeof op.insert === 'string') {
          acc = acc + op.insert;
        } else {
          acc = acc + ' ';
        }

        return acc;
      }, '');

      const mentions = [...text.matchAll(constants.MATCH_MENTION)];
      const ranges = mentions.map((mention) =>
        [mention[2], [mention.index, mention.index + mention[0].length]]) as [[string, any]];

      this.matchRanges = new Map([...ranges]);
    }

    private getSelectedMention() {
      let selectedMention = '';

      this.matchRanges.forEach((value, key) => {
        if (value[0] <= this.lastRange.index && value[1] >= this.lastRange.index ) {
          selectedMention = key;
        }
      });

      return selectedMention;
    }

    private addBindingForKey(key) {
      this.oldBindings[key] = this.quill.keyboard.bindings[key];
      this.quill.keyboard.bindings[key] = [];

      this.quill.keyboard.addBinding({
        key,
        handler: this.keyHandlers[key].bind(this),
      });
    }

    public deleteBindingForKey(key) {
      this.quill.keyboard.bindings[key] = this.oldBindings[key];
    }

    private addKeyboardBindings() {
      this.addBindingForKey(constants.UP);
      this.addBindingForKey(constants.DOWN);
      this.addBindingForKey(constants.ENTER);
    }

    private removeKeyboardBindings() {
      this.deleteBindingForKey(constants.UP);
      this.deleteBindingForKey(constants.DOWN);
      this.deleteBindingForKey(constants.ENTER);
    }

    private openMentionsList() {
      if (!this.container.classList.contains(styles.show)) {
        this.container.classList.add(styles.show);
        this.addKeyboardBindings();
      }

      this.renderList();
    }

    private closeMentionsList() {
      if (this.container.classList.contains(styles.show)) {
        this.container.classList.remove(styles.show);
        this.removeKeyboardBindings();
        this.selectedIndex = 0;
      }
    }
  }

  Quill.register({ 'modules/mention': MentionsModule }, true);
};

