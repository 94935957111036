/**
 * @file Holds useDebounce hook
 * @since 0.3.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';

function useDebounce(value, delay) {

  /**
   * State and setters for debounced value
   */
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(
    () => {

      /**
       * Set debouncedValue to value (passed in) after the specified delay
       */
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      /**
       * Return a cleanup function that will be called every time
       * useEffect is re-called. useEffect will only be re-called
       * if value changes (see the inputs array below).
       */
      return () => {
        clearTimeout(handler);
      };
    },

    /**
     * Only re-call effect if value changes.
     * You could also add the "delay" var to inputs array if you
     * need to be able to change that dynamically.
     */
    [value, delay]
  );

  return debouncedValue;
}

export default useDebounce;
