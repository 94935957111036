import { render } from 'lit-html';
import ChatsListController from 'modules/ChatsList/chats-list.controller';
import ChatsListView from 'modules/ChatsList/chats-list.view';
import { RouterModule } from 'services/router/router.m';

@RouterModule({
  name: 'w-chats-list-module',
  view: ChatsListView,
  controller: ChatsListController,
})
class ChatsListModule {
  public subs: Set<any> = new Set([]);
  public view: ChatsListView;
  public controller: ChatsListController;

  public mount(module) {
    this.controller.destroy = module.destroy;

    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default ChatsListModule;
