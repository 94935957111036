import Component from 'services/templator/component';
import styles from './validation-wrapper.module.scss';
import { directive, html, nothing } from 'lit-html';
import { Icons } from 'components/lit/index';
import { classMap } from 'lit-html/directives/class-map';

export class ValidationWrapper extends Component {
  get classes() {
    const { valid, disabled, fillToEnd = false } = this.options;

    return {
      [ styles.field ]: true,
      [ styles.invalid ]: valid === false,
      [ styles.valid ]: valid === true,
      [ styles.disabled]: !!disabled,
      [ styles.fill_to_end]: fillToEnd,
    };
  }

  get styled_field() {
    const {
      valid = null,
      error = null,
      label = null,
      name = 'name',
      hiddenValidationIcon = false,
      fillToEnd = false,
    } = this.options;

    const validIcon = Icons.circleCheckIcon(valid === true ? '#4cb49c' : '#b9b9b9');
    const classes = {
      [styles.box]: true,
      [styles.fill_to_end]: fillToEnd,
      [styles.hidden_icon]: hiddenValidationIcon,
    };

    return html`
      ${ label ? html`<label for=${name}>${label}</label>` : '' }

      <div class="${classMap(classes)}">
        ${ this.field }
        ${ valid !== false ? validIcon : Icons.error }
      </div>

      ${ error ? html`<div class="${styles.error}">${error}</div>` : nothing }
    `;
  }

  get withoutAutocompleteWrapper() {
    return html`
      <form
        class="${classMap(this.classes)}"
        @submit=${evt => evt.preventDefault()}
      >
         ${this.styled_field}
      </form>
    `;
  }

  get withAutocompleteWrapper() {
    return html`
      <div class="${classMap(this.classes)}">
        ${this.styled_field}
      </div>
    `;
  }

  get renderField() {
    return directive(() => part => {
      this.subscribe(() => {
        part.setValue(this.options.autocomplete ? this.withAutocompleteWrapper : this.withoutAutocompleteWrapper);
        part.commit();
      }, 'type');
    });
  }

  get template() {
    return this.renderField();
  }
}
