import { svg } from 'lit-html';

export const emojiIcon = `
  <svg xmlns="http://www.w3.org/2000/svg"  height="20px" width="20px" viewBox="0 0 368 368">
    <g>
      <path d="M261.336,226.04c-3.296-2.952-8.36-2.664-11.296,0.624C233.352,245.312,209.288,256,184,256
        c-25.28,0-49.352-10.688-66.04-29.336c-2.952-3.288-8-3.576-11.296-0.624c-3.296,2.944-3.568,8-0.624,11.296
        C125.76,259.368,154.176,272,184,272c29.832,0,58.248-12.64,77.96-34.664C264.904,234.04,264.624,228.984,261.336,226.04z"/>
      <path d="M184,0C82.544,0,0,82.544,0,184s82.544,184,184,184s184-82.544,184-184S285.456,0,184,0z M184,352
        c-92.64,0-168-75.36-168-168S91.36,16,184,16s168,75.36,168,168S276.64,352,184,352z"/>
      <path d="M248,128c-22.056,0-40,17.944-40,40c0,4.416,3.584,8,8,8c4.416,0,8-3.584,8-8c0-13.232,10.768-24,24-24s24,10.768,24,24
        c0,4.416,3.584,8,8,8c4.416,0,8-3.584,8-8C288,145.944,270.056,128,248,128z"/>
      <path d="M144,168c0,4.416,3.584,8,8,8s8-3.584,8-8c0-22.056-17.944-40-40-40c-22.056,0-40,17.944-40,40c0,4.416,3.584,8,8,8
        s8-3.584,8-8c0-13.232,10.768-24,24-24S144,154.768,144,168z"/>
    </g>
  </svg>
`;

export const people = svg`
  <svg height="20px" width="20px" viewBox="0 0 368 368">
    <g>
      <path d="M261.336,226.04c-3.296-2.952-8.36-2.664-11.296,0.624C233.352,245.312,209.288,256,184,256
        c-25.28,0-49.352-10.688-66.04-29.336c-2.952-3.288-8-3.576-11.296-0.624c-3.296,2.944-3.568,8-0.624,11.296
        C125.76,259.368,154.176,272,184,272c29.832,0,58.248-12.64,77.96-34.664C264.904,234.04,264.624,228.984,261.336,226.04z"/>
      <path d="M184,0C82.544,0,0,82.544,0,184s82.544,184,184,184s184-82.544,184-184S285.456,0,184,0z M184,352
        c-92.64,0-168-75.36-168-168S91.36,16,184,16s168,75.36,168,168S276.64,352,184,352z"/>
      <path d="M248,128c-22.056,0-40,17.944-40,40c0,4.416,3.584,8,8,8c4.416,0,8-3.584,8-8c0-13.232,10.768-24,24-24s24,10.768,24,24
        c0,4.416,3.584,8,8,8c4.416,0,8-3.584,8-8C288,145.944,270.056,128,248,128z"/>
      <path d="M144,168c0,4.416,3.584,8,8,8s8-3.584,8-8c0-22.056-17.944-40-40-40c-22.056,0-40,17.944-40,40c0,4.416,3.584,8,8,8
        s8-3.584,8-8c0-13.232,10.768-24,24-24S144,154.768,144,168z"/>
    </g>
  </svg>
`;

export const activity = svg`
  <svg width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
    <g id="Bounding_Box">
      <rect fill="none" width="24" height="24"/>
      <rect fill="none" width="24" height="24"/>
    </g>
    <g id="Master">
      <g>
        <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M13,5.3l1.35-0.95
        c1.82,0.56,3.37,1.76,4.38,3.34l-0.39,1.34l-1.35,0.46L13,6.7V5.3z M9.65,4.35L11,5.3v1.4L7.01,9.49L5.66,9.03L5.27,7.69
        C6.28,6.12,7.83,4.92,9.65,4.35z M7.08,17.11l-1.14,0.1C4.73,15.81,4,13.99,4,12c0-0.12,0.01-0.23,0.02-0.35l1-0.73L6.4,11.4
        l1.46,4.34L7.08,17.11z M14.5,19.59C13.71,19.85,12.87,20,12,20s-1.71-0.15-2.5-0.41l-0.69-1.49L9.45,17h5.11l0.64,1.11
        L14.5,19.59z M14.27,15H9.73l-1.35-4.02L12,8.44l3.63,2.54L14.27,15z M18.06,17.21l-1.14-0.1l-0.79-1.37l1.46-4.34l1.39-0.47
        l1,0.73C19.99,11.77,20,11.88,20,12C20,13.99,19.27,15.81,18.06,17.21z"/>
      </g>
    </g>
  </svg>
`;

export const travel_and_places = svg`
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0V0z"/>
    <path d="M20 6h-3V4c0-1.11-.89-2-2-2H9c-1.11 0-2 .89-2 2v2H4c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zM9 4h6v2H9V4zm11 15H4v-2h16v2zm0-5H4V9c0-.55.45-1 1-1h2v1c0 .55.45 1 1 1s1-.45 1-1V8h6v1c0 .55.45 1 1 1s1-.45 1-1V8h2c.55 0 1 .45 1 1v5z"/>
  </svg>
`;

export const food_and_drink = svg`
  <svg width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
    <g id="Bounding_Box">
      <rect fill="none" width="24" height="24"/>
      <rect fill="none" width="24" height="24"/>
    </g>
    <g id="Flat">
      <g id="ui_x5F_spec_x5F_header_copy_2"/>
      <g>
        <path d="M19,19H3c-0.55,0-1,0.45-1,1s0.45,1,1,1h16c0.55,0,1-0.45,1-1S19.55,19,19,19z"/>
        <path d="M20,3H9v2.4l1.81,1.45C10.93,6.94,11,7.09,11,7.24v4.26c0,0.28-0.22,0.5-0.5,0.5h-4C6.22,12,6,11.78,6,11.5V7.24
        c0-0.15,0.07-0.3,0.19-0.39L8,5.4V3H6C4.9,3,4,3.9,4,5v8c0,2.21,1.79,4,4,4h6c2.21,0,4-1.79,4-4v-3h2c1.1,0,2-0.9,2-2V5
        C22,3.9,21.1,3,20,3z M20,8h-2V5h2V8z"/>
      </g>
    </g>
  </svg>
`;

export const symbols = svg`
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
     height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
    <g id="Bounding_Box">
      <rect fill="none" width="24" height="24"/>
      <rect fill="none" width="24" height="24"/>
    </g>
    <g id="Flat">
      <g id="ui_x5F_spec_x5F_header_copy_2"/>
        <g>
        <path d="M10,5H4C3.45,5,3,5.45,3,6c0,0.55,0.45,1,1,1h2v3c0,0.55,0.45,1,1,1s1-0.45,1-1V7h2c0.55,0,1-0.45,1-1
        C11,5.45,10.55,5,10,5z"/>
        <path d="M10,2H4C3.45,2,3,2.45,3,3s0.45,1,1,1h6c0.55,0,1-0.45,1-1S10.55,2,10,2z"/>
        <path d="M20.89,13.11c-0.39-0.39-1.02-0.39-1.41,0l-6.36,6.36c-0.39,0.39-0.39,1.02,0,1.41c0.39,0.39,1.02,0.39,1.41,0l6.36-6.36
        C21.28,14.13,21.28,13.5,20.89,13.11z"/>
        <circle cx="14.5" cy="14.5" r="1.5"/>
        <circle cx="19.5" cy="19.5" r="1.5"/>
        <path d="M15.5,11c1.38,0,2.5-1.12,2.5-2.5V4h2c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-2c-0.55,0-1,0.45-1,1v3.51
        C16.58,6.19,16.07,6,15.5,6C14.12,6,13,7.12,13,8.5C13,9.88,14.12,11,15.5,11z"/>
        <path d="M10.45,18.09c0.39-0.39,0.39-1.02,0-1.41c-0.39-0.39-1.02-0.39-1.41,0l-0.71,0.71l-0.71-0.71l0.35-0.35
        c0.98-0.98,0.98-2.56,0-3.54h0c-0.49-0.49-1.13-0.73-1.77-0.73c-0.64,0-1.28,0.24-1.77,0.73c-0.98,0.98-0.98,2.56,0,3.54
        l0.35,0.35l-1.06,1.06c-0.98,0.98-0.98,2.56,0,3.54C4.22,21.76,4.86,22,5.5,22c0.64,0,1.28-0.24,1.77-0.73l1.06-1.06l0.71,0.71
        c0.39,0.39,1.02,0.39,1.41,0c0.39-0.39,0.39-1.02,0-1.41l-0.71-0.71v0L10.45,18.09z M5.85,14.2c0.12-0.12,0.26-0.15,0.35-0.15
        s0.23,0.03,0.35,0.15c0.19,0.2,0.19,0.51,0,0.71l-0.35,0.35L5.85,14.9c-0.12-0.12-0.15-0.26-0.15-0.35S5.73,14.32,5.85,14.2z
         M5.85,19.85C5.73,19.97,5.59,20,5.5,20s-0.23-0.03-0.35-0.15C5.03,19.73,5,19.59,5,19.5s0.03-0.23,0.15-0.35l1.06-1.06l0.71,0.71
        L5.85,19.85z"/>
      </g>
    </g>
  </svg>
`;

export const objects = svg`
  <svg width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
    <g id="Bounding_Box">
      <rect fill="none" width="24" height="24"/>
      <rect fill="none" width="24" height="24"/>
    </g>
    <g id="Master">
      <g id="ui_x5F_spec_x5F_header_copy"/>
      <g>
        <path d="M12,3c-0.46,0-0.93,0.04-1.4,0.14C7.84,3.67,5.64,5.9,5.12,8.66c-0.48,2.61,0.48,5.01,2.22,6.56
        C7.77,15.6,8,16.13,8,16.69V19c0,1.1,0.9,2,2,2h0.28c0.35,0.6,0.98,1,1.72,1s1.38-0.4,1.72-1H14c1.1,0,2-0.9,2-2v-2.31
        c0-0.55,0.22-1.09,0.64-1.46C18.09,13.95,19,12.08,19,10C19,6.13,15.87,3,12,3z M12.5,14h-1v-2.59L9.67,9.59l0.71-0.71L12,10.5
        l1.62-1.62l0.71,0.71l-1.83,1.83V14z M13.5,19c-0.01,0-0.02-0.01-0.03-0.01V19h-2.94v-0.01c-0.01,0-0.02,0.01-0.03,0.01
        c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5c0.01,0,0.02,0.01,0.03,0.01V18h2.94v0.01c0.01,0,0.02-0.01,0.03-0.01
        c0.28,0,0.5,0.22,0.5,0.5C14,18.78,13.78,19,13.5,19z M13.5,17h-3c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h3
        c0.28,0,0.5,0.22,0.5,0.5C14,16.78,13.78,17,13.5,17z"/>
      </g>
    </g>
  </svg>
`;

export const mood = svg`
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0V0z"/>
    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.03 0 3.8-1.11 4.75-2.75.19-.33-.05-.75-.44-.75H7.69c-.38 0-.63.42-.44.75.95 1.64 2.72 2.75 4.75 2.75z"/>
  </svg>
`;

export const flags = svg`
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0V0z"/>
    <path d="M14 6l-.72-1.45c-.17-.34-.52-.55-.9-.55H6c-.55 0-1 .45-1 1v15c0 .55.45 1 1 1s1-.45 1-1v-6h5l.72 1.45c.17.34.52.55.89.55H19c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1h-5zm4 8h-4l-1-2H7V6h5l1 2h5v6z"/>
  </svg>
`;
export const animals_and_nature = svg`
  <svg width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
    <g id="Bounding_Box">
      <rect fill="none" width="24" height="24"/>
      <rect fill="none" width="24" height="24"/>
    </g>
    <g id="Flat">
      <g id="ui_x5F_spec_x5F_header_copy_2"/>
      <g>
        <path d="M21.94,4.88C21.76,4.35,21.25,4,20.68,4c-0.03,0-0.06,0-0.09,0H19.6l-0.31-0.97C19.15,2.43,18.61,2,18,2h0
        c-0.61,0-1.15,0.43-1.29,1.04L16.4,4h-0.98c-0.03,0-0.06,0-0.09,0c-0.57,0-1.08,0.35-1.26,0.88c-0.19,0.56,0.04,1.17,0.56,1.48
        l0.87,0.52L15.1,8.12c-0.23,0.58-0.04,1.25,0.45,1.62C15.78,9.91,16.06,10,16.33,10c0.31,0,0.61-0.11,0.86-0.32L18,8.98l0.81,0.7
        C19.06,9.89,19.36,10,19.67,10c0.27,0,0.55-0.09,0.78-0.26c0.5-0.37,0.68-1.04,0.45-1.62l-0.39-1.24l0.87-0.52
        C21.89,6.05,22.12,5.44,21.94,4.88z M18,7c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1C19,6.55,18.55,7,18,7z"/>
        <path d="M15.5,12h-1.09c-0.19-0.54-0.49-1.05-0.93-1.49S12.54,9.78,12,9.59V8.5C12,8.22,11.78,8,11.5,8S11,8.22,11,8.5v0.88
        c-0.11-0.01-0.23-0.03-0.34-0.03c-1.02,0-2.05,0.39-2.83,1.17c-0.16,0.16-0.3,0.34-0.43,0.53L6,10.52
        c-1.56-0.55-3.28,0.27-3.83,1.82c0,0,0,0,0,0c-0.27,0.75-0.23,1.57,0.12,2.29c0.23,0.48,0.58,0.87,1,1.16
        c-0.38,1.35-0.06,2.85,1,3.91c1.06,1.06,2.57,1.38,3.91,1c0.29,0.42,0.68,0.77,1.16,1C9.78,21.9,10.21,22,10.65,22
        c0.34,0,0.68-0.06,1.01-0.17c0,0,0,0,0,0c1.56-0.55,2.38-2.27,1.82-3.85l-0.52-1.37c0.18-0.13,0.36-0.27,0.53-0.43
        c0.87-0.87,1.24-2.04,1.14-3.17h0.88c0.28,0,0.5-0.22,0.5-0.5C16,12.22,15.78,12,15.5,12z M4.67,14.29
        c-0.25-0.09-0.45-0.27-0.57-0.51s-0.13-0.51-0.04-0.76c0.19-0.52,0.76-0.79,1.26-0.61l3.16,1.19C7.33,14.2,5.85,14.71,4.67,14.29z
         M10.99,19.94c-0.25,0.09-0.52,0.08-0.76-0.04c-0.24-0.11-0.42-0.32-0.51-0.57c-0.42-1.18,0.09-2.65,0.7-3.8l1.18,3.13
        C11.78,19.18,11.51,19.76,10.99,19.94z M12.2,14.6l-0.61-1.61c0-0.01-0.01-0.02-0.02-0.03c-0.02-0.04-0.04-0.08-0.06-0.12
        c-0.02-0.04-0.04-0.07-0.07-0.11c-0.03-0.03-0.06-0.06-0.09-0.09c-0.03-0.03-0.06-0.06-0.09-0.09c-0.03-0.03-0.07-0.05-0.11-0.07
        c-0.04-0.02-0.07-0.05-0.12-0.06c-0.01,0-0.02-0.01-0.03-0.02L9.4,11.8c0.36-0.29,0.79-0.46,1.26-0.46c0.53,0,1.04,0.21,1.41,0.59
        C12.8,12.66,12.84,13.81,12.2,14.6z"/>
      </g>
    </g>
  </svg>
`;
