import { directive } from 'lit-html';
import { contents } from './subnav.config';
import GeneralDirectives from 'directives/directives';

class SubNavDirectives extends GeneralDirectives {
  get renderSubNav() {
    return directive(() => part => {
      part.setValue(this.view.navTemplate());
      part.commit();
    });
  }

  get renderSubContent() {
    return directive(() => async (part) => {
      const content = await contents();

      part.setValue(content[this.router.route.name]);
      part.commit();
    });
  }
}

export default SubNavDirectives;
