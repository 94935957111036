import apollo from 'api';
import { directive, nothing } from 'lit-html';
import { Auth } from 'services';
import { Router } from 'services/router.service';
import ChatQuery from '../graphql/queries/chat/Chat.graphql';

export const isMember = directive((trueComponent, falseComponent) => async (part) => {
  const { chatId } = Router.params;
  const userId = Auth.getUserId();

  if (!chatId) { return; }

  const { data: { chat } } = await apollo.query({
    query: ChatQuery,
    variables: {
      id: Router.params.chatId,
    },
  });

  const participant = chat.participants.find(participant => participant.id === userId);

  part.setValue(participant ? trueComponent : falseComponent || nothing);
  part.commit();
});
