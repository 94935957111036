import { Icons } from 'components/lit';
import { Observe } from 'decorators';
import { showIf } from 'directives';
import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import capitalize from 'lodash/capitalize';
import { Router } from 'services/router.service';
import ProfileGeneralController from './profile-general.controller';
import styles from './profile-general.module.scss';

class ProfileGeneralTemplate {
  public controller: ProfileGeneralController;

  public location(location) {
    const { country, city } = location;

    return city && html`
      <div>
        ${city.name ? `Based in ${city.name}, ${country.name}` : country.name}
      </div>
    `;
  }

  public buildCard({ title, buttonState, buttonHandler, template }) {
    return html`
      <div class=${styles.card}>
        <div class=${styles.header}>
          <div class=${styles.subtitle}>${title}</div>
          ${this.editButton(buttonState, buttonHandler)}
        </div>
        ${template}
      </div>
    `;
  }

  public editButton(isExists, handler) {
    const className = isExists ? styles['button-outlined'] : styles.button;
    const text = isExists ? 'Edit' : 'Add';

    return html`
      <button
        @click=${handler}
        class=${className}
      >
        ${text}
      </button>
    `;
  }

  public socialMediaLink(firstName, { type, value }) {
    return value && html`
      <div class=${styles.link}>
        <span class=${styles['soc-net-icon']}>
          ${Icons[type]}
        </span>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href=${this.controller.getWebsiteURL(type, value)}
        >
          ${firstName} on ${capitalize(type)}
        </a>
      </div>
    `;
  }

  public websiteLink(site) {
    const url = this.controller.formattedUrl(site);

    return html `
      <div>
        <a target='_blank' rel='noopener noreferrer' href=${url}>${site}</a>
      </div>
    `;
  }

  @Observe('profile')
  get dynamicTemplate() {
    const aboutMeTitle = Router.display === 'mobile' ? 'General' : 'About me';

    const {
      job: { group, organization, title, unit },
      location,
      biography,
      firstName,
      socialMedia,
      relevantWebsites,
    } = this.controller.profile;

    const cards = [
      {
        title: aboutMeTitle,
        buttonState: title || group || organization || unit || location,
        buttonHandler: this.controller.onEdit.bind(this, 'aboutMe'),
        template: html`
          <div class=${styles.content}>
            <div>${ title }${ group ? ', ' + group : '' }</div>
            <div>${ organization }</div>
            <div>${ unit }</div>
            ${showIf(Object.keys(location).length, this.location(location))}
          </div>
        `,
      },
      {
        title: 'Bio',
        buttonState: !!biography,
        buttonHandler: this.controller.onEdit.bind(this, 'biography'),
        template: html`<div class=${styles.content}><span class=${styles.break_lines}>${biography}</span></div>`,
      },
      {
        title: 'Social Media',
        buttonState: socialMedia.length,
        buttonHandler: this.controller.onEdit.bind(this, 'socialMedia'),
        template: html`${repeat(socialMedia, this.socialMediaLink.bind(this, firstName))}`,
      },
      {
        title: 'Relevant Website(s)',
        buttonState: relevantWebsites.length,
        buttonHandler: this.controller.onEdit.bind(this, 'relevantWebsites'),
        template: html`${repeat(relevantWebsites, this.websiteLink.bind(this))}`,
      },
    ];

    return html`
      <div class=${styles['cards-wrapper']}>
        <h3 class=${styles.title}>General</h3>
        ${repeat(cards, this.buildCard.bind(this))}
      </div>
    `;
  }

  get template() {
    return this.dynamicTemplate;
  }
}

export default ProfileGeneralTemplate;
