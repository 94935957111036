/**
 * services/rest.service.js
 *
 * @file Holds service to work with WERKIN REST API's
 * @since 0.3.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import auth from 'services/auth.service';
import { errorHandler } from 'utils/error-helpers';
import get from 'lodash/get';
import { getErrorMessageByStatusCode } from '@werkin/lib/httpErrors';
import mockClients from 'mock/pre-launch/pageData';
import FetchError from 'utils/fetchError';

/**
 * Getting desired variables from process.env
 */

const API_URL = process.env.REACT_APP_API_ONBOARDING_API_URL;

function getErrorMessage(status, body) {
  if (status >= 500) {
    return 'Internal server error';
  }

  return get(body, 'message', getErrorMessageByStatusCode(status));
}
/**
 * Provides ability to perform
 * HTTP requests using Fetch API
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API|Fetch API}
 *
 * @param {string} method - HTTP method
 * @param {string} endpoint - API endpoint to call
 * @param {object} params - query params
 * @param {object} data - data for POST request
 * @param {bool} requireAuth - Auth required by Cognito JWT token
 *
 * @return {promise}
 */
function _fetch({ method, endpoint, params, data, requireAuth }) {
  /** Authorization */
  const authToken = auth.getToken() || null;

  /** Constructing request url */
  const url = new URL(API_URL + endpoint);

  /** Constructing request body */
  const body = data ? JSON.stringify(data) : undefined;

  /**
     * Constructing request headers
     * @type {Object}
     */
  const headers = {
    'Content-Type': 'application/json',
  };

  /** Adding search params to url */
  if (params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  }

  /** Adding authorization header */
  if (requireAuth) {
    headers.authorization = 'bearer ' + authToken;
  }

  /** Perform POST request using Fetch API */
  return fetch(url, {
    method,
    mode: 'cors',
    cache: 'no-cache',
    headers,
    body,
  })
    .then(
      async (res) => {
        let body;
        try {
          body = await res.json();
        } catch {
          console.log();
        }

        if (!res.ok) {
          throw new FetchError({ message: getErrorMessage(res.status, body), status: res.status });
        }

        return body;
      });
}

/**
 * Provides an interface for accessing
 * WERKIN REST API's
 *
 * @return {object}
 */

/**
 * Public interface
 */
export default (() => ({
  /**
     * Returns link for redirecting based on entered email
     */
  getLink: email => _fetch({
    method: 'GET',
    endpoint: '/queries/GetUserRoute',
    params: { emailAddress: email },
  }),

  /**
     * Returns link for redirecting after login
     */
  getLandingRoute: () => _fetch({
    method: 'GET',
    endpoint: '/queries/GetLandingRoute',
    requireAuth: true,
  }),

  /**
     * Returns data used for populating register form (authenticated)
     */
  getSystemData: () => _fetch({
    method: 'GET',
    endpoint: '/queries/GetSystemData',
    requireAuth: true,
  }),

  /**
     * Returns data used for populating register form (unauthenticated)
     */
  getRegisterInterestFormData: () => _fetch({
    endpoint: '/queries/GetRegisterInterestFormData',
    method: 'GET',
  }),

  /**
     * Returns skills based on entered search string
     */
  getSkills: searchString => _fetch({
    method: 'GET',
    endpoint: '/queries/GetSkillsAndInterests',
    params: { s: searchString },
  }),

  /**
     * Notify backend about reset temporary password proceess success
     */
  notifyAboutPasswordReset: email => _fetch({
    method: 'POST',
    endpoint: '/admin/commands/CompleteAuthenticationSetup',
    data: { email },
  }),

  /**
     * Request to getting cronofy authorization url
     */
  getCronofyAuthUrl: RedirectUrl => _fetch({
    method: 'POST',
    endpoint: '/calendar/AuthorizeCalendarAccessToken',
    data: { RedirectUrl },
    requireAuth: true,
  }),

  /**
     * Returns user available calendar slots and calendar availability
     */
  getCalendar: (user) => new Promise(resolve => setTimeout(
    () => resolve(require('mock/Calendar').default[user]), 1000)
  ),

  /**
     * Exchange cronofy short-lived, single-use code to accessToken
     */
  exchangeCronofyCode: (token) => _fetch({
    method: 'POST',
    endpoint: '/commands/AuthorizeCalendarAccess',
    data: { token },
    requireAuth: true,
  }),

  /*
     * Revoke calendar access
     */
  revokeCalendarAccess: () => _fetch({
    method: 'POST',
    endpoint: '/commands/RevokeCalendarAccess',
    data: {},
    requireAuth: true,
  }),

  /*
   * Resend temporary password
   */
  resendTemporaryPassword: (emailAddress) => _fetch({
    method: 'POST',
    endpoint: '/commands/ResendTemporaryPassword',
    data: { emailAddress },
  }),

  /**
     * Returns scheduled werkin events
     */
  getWerkinEvents: (user) => new Promise(resolve => setTimeout(
    () => resolve(require('mock/WerkinEvents').default[user]), 1000)
  ),

  /**
     * Called when users to be kept informed rather than complete the Register Interest flow
     */
  keepMeInformed: ({ firstName, lastName, emailAddress }) => _fetch({
    method: 'POST',
    endpoint: '/commands/KeepMeInformed',
    data: { firstName, lastName, emailAddress },
  }),

  /**
     * Provides ability to create user in both WERKIN X and AWS Cognito
     */
  register: ({ firstName, lastName, emailAddress }) => _fetch({
    method: 'POST',
    endpoint: '/commands/RegisterIndividual',
    data: { firstName, lastName, emailAddress },
  }),

  registerInterest: data => _fetch({
    method: 'POST',
    endpoint: '/commands/RegisterInterest',
    data,
  }),

  /**
     * Provides ability to onboard user
     */
  onboard: data => _fetch({
    method: 'POST',
    endpoint: '/commands/OnboardIndividual',
    data,
    requireAuth: true,
  }),

  uploadFile: body => fetch(`${API_URL}/commands/UploadFile`, {
    method: 'POST',
    body,
    headers: {
      authorization: `bearer ${auth.getToken()}`,
    },
  }).then(res => res.json()).then(({ data }) => data.urls).catch(e => errorHandler(e)),

  getClientData: (client) =>
    Promise.resolve(mockClients[client]),
})
)();
