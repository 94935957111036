import { Icons } from 'components/lit';
import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import startCase from 'lodash/startCase';
import person from './assets/steinfeld.jpg';
import CreditStreaksController from './credits-streaks.controller';
import styles from './credits-streaks.module.scss';

class CreditstreaksTemplate {
  public controller: CreditStreaksController;

  public streakCount(streak) {
    const label = `#${startCase(streak.type)} streaks`;

    return html`
      <div class=${styles['streak-counter']}>
        <div class=${styles.label}>${label}</div>
        <div class=${styles.content}>
          <div class=${styles.value}>${streak.value}</div>
        </div
      </div>
    `;
  }

  @Observe('streaks')
  get streaksCount() {
    const { streaks } = this.controller;

    if (!streaks) {
      return nothing;
    }

    return html`
      <div class=${styles.streaks}>
        ${repeat(streaks.streaks, (streak) => this.streakCount(streak))}
      </div>
    `;
  }

  @Observe('streaks')
  get streaksView() {
    const { streaks, numberFormat } = this.controller;

    if (!streaks) {
      return nothing;
    }

    const { longest, current } = streaks;

    return html`
      <div class=${styles.content}>
        <div class=${styles.line}>
          <div>Current streak:</div>
          <div>${numberFormat(current.value)}</div>
          <div>${numberFormat(current.points)}</div>
        </div>

        <div class=${styles.line}>
          <div>Longest streak:</div>
          <div>${numberFormat(longest.value)}</div>
          <div>${numberFormat(longest.points)}</div>
        </div>
      </div>
    `;
  }

  get template() {

    return html`
      <div class=${styles['credits-achievements']}>
        <div class=${styles['background-decoration']}></div>

        <section>
          <div class=${styles['streaks-title']}>
            <div class=${styles.icon}>
              ${Icons.streakAwards}
            </div>

            <div class=${styles.title}>Streak - o - meter</div>
          </div>
        </section>

        <section>
          <div class=${styles.labels}>
            <span>Days</span>
            <span>Bonus PTS</span>
          </div>
        </section>

        <section>
          ${this.streaksView}
        </section>

        <section>
          ${this.streaksCount}
        </section>

        <section>
          <div class=${styles.description}>
            For every 7 days you open WERKIN and take a meaningful step towards your goals or building the community you
            get 100 points. You receive 500 bonus points when you get to 14 days and 1,000 when you reach 30 days.
          </div>
        </section>

        <section class=${styles['footer-section']}>
          <div class=${styles.footer}>
            <div class=${styles['icon-person']}>
              <img src="${person}"/>
            </div>
            <div>
              <span class=${styles.label}>Jerry says</span>
              <div class=${styles.quote}>"Don't break the chain."</div>
            </div>
          </div>

          <div class=${styles['background-decoration-bottom']}>
            <div class=${styles.background}></div>
          </div>
        </section>
      </div>
    `;
  }
}

export default CreditstreaksTemplate;
