import apollo from 'api';
import { observable } from 'decorators';
import { html } from 'lit-html';
import NudgeboardQuery from '../../graphql/queries/nudgeboard.graphql';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';
import DashboardModule from './dash.module';

class DashboardController {
  public module: DashboardModule;
  @observable(null) public nudgeboard: any;
  @observable(null) public profile: any;
  @observable(null) public cards: any;

  public async OnInit() {
    this.fetchNudgeboard();
    this.fetchProfile();
  }

  public async fetchNudgeboard() {
    const {
      data: { nudgeboard },
    } = await apollo.query({
      query: NudgeboardQuery,
    });

    const cards = [...nudgeboard.cards].map((card) => ({
      ...card,
      module: this.cardModules[card.name],
    }));

    this.cards = cards;
    this.nudgeboard = nudgeboard;
  }

  public async fetchProfile() {
    const {
      data: { profile: profileData },
    } = await apollo.query({
      query: ProfileQuery,
    });

    this.profile = profileData;
  }

  public get cardModules() {
    return {
      'dash-match': html`<w-dash-match-module />`,
      'dash-prematch': html`<w-dash-prematch-module />`,
      'dash-tracker': html`<w-dash-tracker-module />`,
      'dash-themes': html`<w-dash-themes-module />`,
      'dash-tasks': html`<w-dash-tasks-module />`,
      'dash-profile': html`<w-dash-profile-completion-module />`,
      'dash-upcoming-events': html`<w-dash-upcoming-events-module />`,
      'dash-leaderboard': html`<w-dash-leaderboard-module />`,
      'dash-stats': html`<w-dash-stats-module />`,
      'dash-connectivity': html`<w-dash-connectivity-module />`,
      'dash-goals': html`<w-dash-goals-module />`,
      'dash-programme': html`<w-dash-programme-module />`,
      'dash-workbook': html`<w-dash-workbook-module />`,
      'dash-session-guides': html`<w-dash-session-guides-module />`,
      'dash-resources': html`<w-dash-resources-module />`,
    };
  }
}

export default DashboardController;
