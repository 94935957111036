import { RouterModule } from 'services/router/router.module';
import ProfileTemplate from './profile.view';
import ProfileDirectives from './profile.directives';
import ProfileController from './profile.controller';
import styles from './profile.module.scss';

class ProfileAvatarModule extends RouterModule {
  mount() {
    const profile = new ProfileTemplate(this.module);

    profile.controller = ProfileController;
    profile.directives = ProfileDirectives;
    profile.styles = styles;

    this.manageLitMount(profile.template, 'header-profile');
  }
}

export default ProfileAvatarModule;
