import store from '@werkin/store';
import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import AlertsController from './alerts.controller';
import AlertsView from './alerts.view';

@RouterModule({
  name: 'w-alerts-module',
  view: AlertsView,
  controller: AlertsController,
})
class AlertsModule {
  public view: AlertsView;
  public subs: Set<() => void> = new Set<() => void>([]);
  public controller: AlertsController;

  public mount(module) {
    const unsubscribe = store.subscribe('isAlertsModal', (isAlertsOpen) => {
      if (isAlertsOpen) {
        setImmediate(() => {
          render(this.view.template, module);
        });
      }
    });

    this.subs.add(unsubscribe);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default AlertsModule;
