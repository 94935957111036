/* tslint:disable:max-line-length */

export default {
  '7-day-streak': require('./7-day-streak.png'),
  '14-day-streak': require('./14-day-streak.png'),
  '30-day-streak': require('./30-day-streak.png'),
  'award-points-basic': require('./award-points-basic.svg'),
  'award-points-number-50': require('./award-points-number-50.svg'),
  'award-points-number-100': require('./award-points-number-100.svg'),
  'award-points-number-250': require('./award-points-number-250.svg'),
  'award-points-number-500': require('./award-points-number-500.svg'),
  'collective-leaderboard-3 months-running': require('./collective-leaderboard-3 months-running.png'),
  'early-bird': require('./early-bird.png'),
  'featured-in-the-cohort-leaderboard-4 weeks-running': require('./featured-in-the-cohort-leaderboard-4 weeks-running.png'),
  'first-in-cohort-leaderboard': require('./first-in-cohort-leaderboard.png'),
  'first-in-collective-leaderboard': require('./first-in-collective-leaderboard.png'),
  'reaches-10,000-points': require('./reaches-10,000-points.png'),
  'reaches-15,000-points': require('./reaches-15,000-points.png'),
  'reaches-20,000-points': require('./reaches-20,000-points.png'),
  'reaches-5000-points': require('./reaches-5000-points.png'),
  'second-in-cohort-leaderboard': require('./second-in-cohort-leaderboard.png'),
  'second-in-collective-leaderboard': require('./second-in-collective-leaderboard.png'),
  'sign-on': require('./sign-on.png'),
  'third-in collective-leaderboard': require('./third-in collective-leaderboard.png'),
  'third-in-cohort-leaderboard': require('./third-in-cohort-leaderboard.png'),
};
