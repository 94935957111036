import { html } from 'lit-html';
import BeforeContinueController from './before-continue.controller';
import styles from './before-continue.module.scss';

class BeforeContinueView {
  public controller: BeforeContinueController;

  get description() {
    return html`
      <div class="${styles.description}">Before we can continue</div>
    `;
  }

  get template() {
    return html`
      <div class="${styles.wrapper}">
        ${this.description}

        <p>
          There is a minimum level of commitment required to ensure that everyone on the programme has a great
          experience and based on your answers you may not be able to give what is needed.
        </p>

        <p>
          You can agree to meet the minimum level of commitment, withdraw from this cohort (with a view to being
          included in another cohort down the track) or leave the programme completely.
        </p>

        <p>What would you like to do?</p>

        <div class="${styles.card}">
          <span>Sign me up</span>
          <button @click=${() => this.controller.signMe()} class="${styles.primary}">Choose</button>
        </div>

        <div class="${styles.card}">
          <span>Come back to me in 6 months</span>
          <button @click=${() => this.controller.willBeInTouch()} class="${styles.outline}">Choose</button>
        </div>

        <div class="${styles.card}">
          <span>Leave programme completely</span>
          <button @click=${() => this.controller.leaveProgram()} class="${styles.outline}">Choose</button>
        </div>
      </div>
    `;
  }
}

export default BeforeContinueView;
