import {
  addHours,
  addDays,
  setMilliseconds,
  setSeconds,
  setMinutes,
} from 'date-fns';

let date = new Date();
date = setMilliseconds(date, 0);
date = setSeconds(date, 0);
date = setMinutes(date, 15);

export default {
  sender: {
    authorizedCalendarAccess: false,
    slots: [{
      start: addHours(date, 0).toISOString(),
      end: addDays(date, 4).toISOString(),
    }],
  },
  receiver: {
    authorizedCalendarAccess: false,
    slots: [{
      start: addHours(date, 0).toISOString(),
      end: addDays(date, 4).toISOString(),
    }],
  },
};
