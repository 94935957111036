import ControllerMiddleware from 'controllers/middleware';
import { Auth } from 'services';
import RouterError from 'services/router/router-error';

class NotAuthorizedController extends ControllerMiddleware {
  guard() {
    return Auth.isAuthenticated().then(authorized => {
      if (authorized) {
        this.router.go({ name: 'chat' });

        return Promise.reject(new RouterError({ message: 'Already authorized' }));
      }
    });
  }
}

export default NotAuthorizedController;
