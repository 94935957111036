import apollo from 'api';
import { Icons, ValidatedInput } from 'components/lit';
import { ValidatedSelect } from 'components/lit';
import { ValidatedTextArea } from 'components/lit';
import { AutonomousModal } from 'decorators';
import { directive, html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import _ from 'lodash';
import get from 'lodash/get';
import { NotificationManager } from 'services';
import { Auth, REST } from 'services';
import ProfileService from 'services/data/profile.service';
import { Router } from 'services/router.service';
import setIndividualContactDetailsMutation from '../../../graphql/mutations/setIndividualContactDetails.graphql';
import setIndividualGeneralProfileMutation from '../../../graphql/mutations/setIndividualGeneralProfile.graphql';
import setIndividualProfessionalDevelopmentMutation from '../../../graphql/mutations/setIndividualProfessionalDevelopment.graphql';
import ProfileQuery from '../../../graphql/queries/profile/Profile.graphql';
import DiscardModal from './discard-modal.view';
import ProfileAutonomousModal from './profile-autonomus-modal.view';
import styles from './profile-editing-modal.module.scss';

const fieldTypes = {
  TEXT: 'text',
  SELECT: 'select',
  TEXTAREA: 'textarea',
  CHECKBOX: 'checkbox',
};

const validation = {
  PASSED: 'passed',
  REQUIRED: 'required',
  CUSTOM: 'custom',
};

const validationRules = {
  [validation.REQUIRED]: (payload) => payload.length ? validation.PASSED : 'Field is required',
  [validation.PASSED]: (payload) => payload.length ? validation.PASSED : undefined,
};

const config = {
  general: {
    title: 'General',
    mutation: setIndividualGeneralProfileMutation,
    aboutMe: {
      title: 'About me',
      text: '*Mandatory Fields',
      buttonName: 'Save',
      fields: [
        {
          type: fieldTypes.TEXT,
          label: 'Organization',
          fieldPath: 'job.organization',
          disabled: true,
        },
        {
          type: fieldTypes.TEXT,
          label: 'Group, function or division',
          fieldPath: 'job.group',
          validation: [validation.PASSED],
        },
        {
          type: fieldTypes.TEXT,
          label: 'Business Unit',
          fieldPath: 'job.unit',
          validation: [validation.PASSED],
        },
        {
          type: fieldTypes.TEXT,
          label: 'Job title*',
          fieldPath: 'job.title',
          validation: [validation.REQUIRED],
        },
      ],
    },
    biography: {
      title: 'Bio',
      buttonName: 'Save',
      fields: [{
        type: fieldTypes.TEXTAREA,
        label: '2,000 character limit*',
        fieldPath: 'biography',
        validation: [validation.PASSED],
      }],
    },
    socialMedia: {
      title: 'Social Media',
      buttonName: 'Save',
      dynamicFields: (store) => store.socialMedia.map((soc, index) => ({
        type: fieldTypes.TEXT,
        label: _.capitalize(soc.type),
        fieldPath: `socialMedia[${index}].value`,
        validation: [validation.PASSED],
      })),
    },
    relevantWebsites: {
      title: 'Relevant Website(s)',
      buttonName: 'Save',
      fields: ['Website address', 'Website address', 'Website address'].map((label, index) => ({
        type: fieldTypes.TEXT,
        label,
        fieldPath: `relevantWebsites[${index}]`,
        validation: [validation.PASSED],
      })),
    },
  },
  contactDetails: {
    title: 'Contact Details',
    mutation: setIndividualContactDetailsMutation,
    details: {
      title: 'Contact details',
      buttonName: 'Save',
      dynamicFields: (store) => {
        const emailIndex = store.emailAddresses.findIndex((email) => email.isPrimary);

        return [
          {
            type: fieldTypes.TEXT,
            label: 'Primary email address (This is the username you log in with)',
            fieldPath: `emailAddresses[${emailIndex}].value`,
            // validation: [validation.CUSTOM]
          },
          {
            type: fieldTypes.TEXT,
            label: 'Password',
            fieldPath: 'password',
            validation: [validation.PASSED],
          },
          ...store.telephoneNumbers.map((phone, index) => ({
            type: fieldTypes.TEXT,
            label: _.capitalize(phone.type),
            fieldPath: `telephoneNumbers[${index}].value`,
            validation: [validation.PASSED],
          })),
        ];
      },
    },
    alias: {
      title: 'Alias',
      buttonName: 'Save',
      fields: [
        {
          type: fieldTypes.TEXT,
          label: 'Alias',
          fieldPath: 'alias.value',
          validation: [validation.PASSED],
        },
        // {
        //   type: fieldTypes.CHECKBOX,
        //   label: 'Use alias as my name in this collective',
        //   fieldPath: 'alias.allowSearchingForRealName',
        // },
        // {
        //   type: fieldTypes.CHECKBOX,
        //   label: 'Allow users to search on my first and last names as well',
        //   fieldPath: 'alias.useAsProfileName',
        // },
      ],
    },
  },
  professionalDevelopment: {
    title: 'Professional Development',
    mutation: setIndividualProfessionalDevelopmentMutation,
    careerStage: {
      title: 'Career Stage',
      buttonName: 'Save',
      fields: [{
        type: fieldTypes.SELECT,
        fieldPath: 'careerStage',
        optionsStore: 'career-stages',
        validation: [validation.REQUIRED],
      }],
    },
    learningAndDevelopmentAreas: {
      title: 'L&D Areas',
      text: 'You can choose up to 3 learning & development areas to focus on.',
      buttonName: 'Save',
      dynamicFields: (store) => {
        const fields = [];
        store.learningAndDevelopmentAreas.forEach((item, index) => {
          fields.push({
            type: fieldTypes.SELECT,
            label: `L&D Focus area #${index + 1}`,
            fieldPath: `learningAndDevelopmentAreas[${index}].area`,
            optionsStore: 'learning-development-area',
            validation: [validation.REQUIRED],
          });
          fields.push({
            type: fieldTypes.TEXTAREA,
            label: 'Related goal',
            fieldPath: `learningAndDevelopmentAreas[${index}].goal`,
            validation: [validation.PASSED],
          });
        });

        return fields;
      },
    },
  },
};

@AutonomousModal('ProfileEditingModal', 'root', 'targetEntity')
class ProfileEditingModal extends ProfileAutonomousModal {
  public text: any;

  private state = {};
  private targetEntity = '';
  private root: any;
  private profile: any;
  private systemData: any = [];

  get config() {
    const {
      title: moduleTitle,
      [this.targetEntity]: {
        title,
        text,
        buttonName,
        dynamicFields,
        fields,
      },
    } = config[this.root];

    return {
      moduleTitle,
      title,
      text,
      buttonName,
      fields: fields ? fields : dynamicFields(this.profile[this.root]),
    };
  }

  public async setProfile() {
    const profile = await ProfileService.getProfile();

    this.profile = profile;
  }

  public async onOpen() {
    await this.fetchSystemData();
    await this.setProfile();

    this.initConfig();
    const { moduleTitle, title, text, buttonName, fields } = this.config;

    this.title = `${moduleTitle}: ${title}`;
    this.submitBtnText = buttonName;

    this.text = html`
      <div class=${styles['dialog-wrapper']}>
        <div>${text}</div>
        ${repeat(fields, this.buildInput.bind(this))}
      </div>
    `;

    this.template = this.wrap(this.tmp);
  }

  public async fetchSystemData() {
    if (Router.hash.root !== 'professionalDevelopment') { return; }

    const { data: { values } } = await REST.getSystemData();

    return this.systemData = values;
  }

  public initConfig() {
    const { root, targetEntity } = Router.hash;

    if (!root || !targetEntity) {
      return Router.go({ hash: {} });
    }

    this.root = root;
    this.targetEntity = targetEntity;

    const clonedProfile = _.cloneDeep(this.profile[this.root]);
    if (root !== 'general') { return this.state = clonedProfile; }

    const { location } = clonedProfile;

    this.state = {
      ...clonedProfile,
      location: {
        ...location,
        city: location.city.id,
        country: location.country.id,
      },
    };
  }

  public async destroy(success = false): Promise<any> {
    if (_.isEqual(this.state, this.profile) || success) {
      return super.destroy();
    }

    const calendarAccessModal = new DiscardModal();
    const result = await calendarAccessModal.open();
    if (result) {
      super.destroy();
    }
  }

  public async submitForm() {
    const { mutation } = config[this.root];
    await apollo.mutate({
      mutation,
      variables: {
        command: this.state,
      },
      refetchQueries: [{
        query: ProfileQuery,
        variables: {
          id: Auth.getUserId(),
        },
      }],
    });

    NotificationManager.showNotification({
      text: `${this.config.title} has been successfully updated`,
      type: 'success',
      duration: 3000,
    });

    this.destroy(true);
  }

  public validateField(path) {
    const { fields } = this.config;
    const value = get(this.state, path);

    const field = fields.find(({ fieldPath }) => fieldPath === path);

    if (!field.validation || !field.validation.length) {
      return;
    }

    if (field.validation.some((item) => item === validation.PASSED)) {
      return (value && !!(value as any).length) || undefined;
    }

    const errors = field.validation.map((rule) => validationRules[rule](value));

    return !errors.some((item) => item !== validation.PASSED);
  }

  public buildInput(field) {
    const renderMethod = {
      [fieldTypes.TEXT]: this.renderInput,
      [fieldTypes.SELECT]: this.renderSelect,
      [fieldTypes.TEXTAREA]: this.renderTextArea,
      [fieldTypes.CHECKBOX]: this.renderCheckbox,
    }[field.type];

    return html`
      <div class=${styles.inputs}>
        ${renderMethod.call(this, field)}
      </div>
    `;
  }

  public abstractRender(ValidatedInput, specificOptions) {
    return directive(({ fieldPath, label, disabled }) => (part) => {
      // replace characters which unavailable for 'id' attribute
      const name = (fieldPath.replace('[', '_')).replace(']', '_');
      const input = new ValidatedInput({
        name,
        value: get(this.state, fieldPath),
        valid: this.validateField(fieldPath),
        disabled,
        label,
        ...specificOptions,
      });
      part.setValue(input.template);

      this.subscribe(() => {
        input.setOptions({
          value: get(this.state, fieldPath, ''),
          valid: this.validateField(fieldPath),
        });
        part.setValue(input.template);
        part.commit();
      }, fieldPath);
    });
  }

  public renderSelect(field) {
    const { optionsStore, fieldPath } = field;
    const options = this.systemData.filter((v) => v.category === optionsStore);

    const specificOptions = {
      rows: 6,
      selectHandler: this.selectHandler.bind(this, fieldPath),
      options,
      opened: false,
      withSearch: true,
    };

    return this.abstractRender(ValidatedSelect, specificOptions)(field);
  }

  public renderInput(field) {
    const { fieldPath } = field;
    const specificOptions = {
      inputHandler: this.inputHandler.bind(this, fieldPath),
      showClearButton: true,
      onClearCb: () => {
        // set validation as invalid
        _.set(this.state, fieldPath, '');
        this.triggerListeners(fieldPath);
      },
    };

    return this.abstractRender(ValidatedInput, specificOptions)(field);
  }

  public renderTextArea(field) {
    const { fieldPath } = field;
    const specificOptions = {
      rows: 6,
      inputHandler: this.inputHandler.bind(this, fieldPath),
    };

    return this.abstractRender(ValidatedTextArea, specificOptions)(field);
  }

  public renderCheckbox({ label, fieldPath, disabled }) {
    const template = () => {
      const isChecked = get(this.state, fieldPath);

      return html`
        <label class=${styles.checkbox}>
          <input
            type="checkbox"
            .disabled=${disabled}
            .defaultChecked=${isChecked}
            @change=${this.checkboxHandler.bind(this, fieldPath)}
          >
          ${isChecked ? Icons.checkboxChecked : Icons.checkbox}
          <span>${label}</span>
        </label>
      `;
    };

    return directive(() => (part) => {
      part.setValue(template());
      this.subscribe(() => {
        part.setValue(template());
        part.commit();
      }, fieldPath);
    })();
  }

  public inputHandler(fieldPath, event) {
    _.set(this.state, fieldPath, event.target.value);
    this.triggerListeners(fieldPath);
  }

  public selectHandler(fieldPath, event, selectedItem) {
    _.set(this.state, fieldPath, selectedItem.id);
    this.triggerListeners(fieldPath);
  }

  public checkboxHandler(fieldPath, event) {
    _.set(this.state, fieldPath, event.target.checked);
    this.triggerListeners(fieldPath);
  }
}

export default ProfileEditingModal;
