import { render } from 'lit-html';
import PublicProfileController from 'modules/ProfilePublic/public-profile.controller';
import PublicProfileView from 'modules/ProfilePublic/public-profile.view';
import dataService from 'services/data';
import { Router } from 'services/router.service';
import { RouterModule } from 'services/router/router.m';

@RouterModule({
  name: 'w-public-profile-module',
  view: PublicProfileView,
  controller: PublicProfileController,
})
class PublicProfileModule {
  public view: PublicProfileView;
  public controller: PublicProfileController;
  public subs: Set<() => void> = new Set([]);

  public async loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    this.controller.isModal = module.hasAttribute('modal');

    render(this.view.template, module);
  }

  public unmount() {
    Router.deleteHash('userId', 'IndividualModal');
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default PublicProfileModule;
