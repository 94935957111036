import HeaderTemplate from '../header.view';
import _upperFirst from 'lodash/upperFirst';

class NavTemplate extends HeaderTemplate {
  constructor(module, opts) {
    super(module);

    this.icon = opts.icon;
    this.name = opts.name;
    this.route = opts.route;
    this.params = opts.params;
  }

  get template() {
    const text = _upperFirst(this.name);
    const active = this.module.router.route.name.includes(this.route || this.name);

    return this.NavButton({
      icon: this.icon,
      text,
      active,
      clickHandler: () => this.controller.handleNavigate(this.route || this.name, this.params),
    });
  }
}

export default NavTemplate;
