import { AutonomousModal } from 'decorators';
import { html } from 'lit-html';
import FullScreenModal from './full-screen-modal.view';

@AutonomousModal('IndividualModal', 'userId')
class IndividualModal extends FullScreenModal {
  public title: string;

  public onOpen() {
    this.title = 'Viewing public profile';
    this.template = this.wrap(this.tmp);
  }

  get body() {
    return html`<w-public-profile-module modal> </w-public-profile-module>`;
  }
}

export default IndividualModal;
