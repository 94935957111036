class FetchError extends Error {
  public status: number;

  constructor({ message, status }) {
    super(message);

    this.status = status;
  }
}

export default FetchError;
