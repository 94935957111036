import { Icons } from 'components/lit';
import { Observe } from 'decorators';
import { showIf } from 'directives';
import { html } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { repeat } from 'lit-html/directives/repeat';
import TabbarController from 'modules/Tabbar/tabbar.controller';
import { Router } from 'services/router.service';
import styles from './tabbar.module.scss';

const tabs = () => [
  {
    id: 1,
    icon: Icons.grid,
    text: 'Dashboard',
    active: 'dashboard',
    handler: () => {
      Router.go({ name: 'dashboard' });
    },
  },
  {
    id: 2,
    icon: Icons.messageSquare,
    text: 'Community',
    active: 'chat',
    handler: () => {
      Router.go({ name: 'chat' });
    },
  },
  {
    id: 3,
    icon: Icons.calendar,
    text: 'Calendar',
    active: 'calendar',
    handler: () => {
      Router.go({ name: 'calendar' });
    },
  },
  {
    id: 4,
    icon: Icons.alerts,
    text: 'Alerts',
    active: 'alerts',
    handler: () => {
      Router.go({ name: 'alerts' });
    },
  },
];


class TabbarView {
  public controller: TabbarController;

  @Observe('activeRoute')
  public tab(tab) {
    const { activeRoute } = this.controller;
    const isActive = activeRoute === tab.active;
    const classes = {
      [styles.tab]: true,
      [styles.active]: isActive,
    };

    return html`
      <button class="${classMap(classes)}" @click=${tab.handler}>
        ${showIf(tab.active === 'alerts', this.badge)}
        ${showIf(tab.active === 'chat', this.messagesBadge)}
        ${tab.icon(isActive)}
        ${tab.text}
      </button>
  `;
  }

  @Observe('unreadCount')
  get badge() {
    const { unreadCount } = this.controller;

    return showIf(unreadCount, html`
      <div class=${styles.badge}>${unreadCount}</div>
    `);
  }

  @Observe('unreadMessagesCount')
  get messagesBadge() {
    const { unreadMessagesCount } = this.controller;

    return showIf(unreadMessagesCount, html`
      <div class=${styles.badge}>${unreadMessagesCount < 100 ? unreadMessagesCount : '99+'}</div>
    `);
  }

  get template() {
    return html`
      <div class="${styles.wrapper}">
        ${repeat(tabs(), (tab) => tab.id, (tab) => this.tab(tab)) }
      </div>
    `;
  }
}

export default TabbarView;
