import { render } from 'lit-html';
import HeaderAlertsController from 'modules/HeaderAlerts/header-alerts.controller';
import HeaderAlertsView from 'modules/HeaderAlerts/header-alerts.view';
import dataService from 'services/data';
import { RouterModule } from 'services/router/router.m';

@RouterModule({
  name: 'w-header-alerts-module',
  view: HeaderAlertsView,
  controller: HeaderAlertsController,
})
class HeaderAlertsModule {
  public subs: Set<() => void> = new Set<() => void>([]);
  public view: HeaderAlertsView;
  public controller: HeaderAlertsController;
  public root: HTMLElement;

  public async loading() {
    return dataService.awaitLoading;
  }

  public mount(module) {
    this.root = module;

    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default HeaderAlertsModule;
