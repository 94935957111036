import { observable } from 'decorators';
import REST from 'services/rest.service';
import { Router } from 'services/router.service';

class GetLinkController {
  @observable() public loading: boolean;
  @observable() public valid: boolean;
  @observable() public error: string | null;

  public onChange(evt) {
    evt.preventDefault();

    this.valid = Array.from(evt.target.controls).every(([, { valid }]) => valid);
  }

  public async onNext(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    try {
      this.loading = true;
      this.valid = false;

      const email = evt.target.controls.get('email').value.toLowerCase();
      const { data: { route } } = await REST.getLink(email);

      Router.go({ path: route, state: { email } });
    } catch (e) {
      this.error = e.message;
      this.valid = false;
    } finally {
      this.loading = false;
    }
  }
}

export default GetLinkController;
