import apollo from 'api';
import { Icons, NotJoined } from 'components/lit';
import { showIf } from 'directives';
import { Router } from 'services/router.service';
import styles from 'modules/SubNav/subnav.module.scss';
import { html } from 'lit-html';
import ChatQuery from '../../graphql/queries/chat/Chat.graphql';

export const tabs  = [
  {
    id: 1,
    icon: Icons.activity,
    text: 'Activity',
    pathName: 'chats.activity',
    handler() {
      (Router.route.name !== 'chats.activity') && Router.go({ name: 'chats.activity' });
    },
  },
  {
    id: 2,
    icon: Icons.chat(),
    text: 'Chat',
    pathName: 'chats.chat',
    class: styles.chat,
    handler() {
      (Router.route.name !== 'chats.chat') && Router.go({ name: 'chats.chat' });
    },
  },
  {
    id: 3,
    icon: Icons.info,
    text: 'Info',
    pathName: 'chats.info',
    handler() {
      (Router.route.name !== 'chats.info') && Router.go({ name: 'chats.info' });
    },
  },
];

export const contents = async () => {
  let activityTmp = NotJoined('activity');

  if (Router.route.name === 'chats.activity') {
    const { data: { chat } } = await apollo.query({
      query: ChatQuery,
      variables: {
        id: Router.params.chatId,
      },
    });

    const { feedTypes } = chat.attributes;

    if (feedTypes.includes('activity')) {
      activityTmp = html`<w-activity-module class=${styles.activity_module}> </w-activity-module>`;
    }

    if (feedTypes.includes('calendar')) {
      activityTmp = html`<w-actions-list-module> </w-actions-list-module>`;
    }
  }

  return {
    'chats.activity': activityTmp,
    'chats.info': html`
     <div id="info_body"></div>
  `,
  'chats.chat': html`
    <div id="messages_body">
    ${showIf(Router.display === 'mobile', html`
      <div id="chat_messages"></div>
      <div id="poll"></div>
      <div id="chat_suggestions"></div>
      <w-chat-input-module> </w-chat-input-module>
    `)}
    </div>
  `,
    'chats.actions.list': html`
     <w-actions-list-module> </w-actions-list-module>
  `,
    'chats.actions.action': html`
     <w-action-module> </w-action-module>
  `,
    'chats.actions.create': html`
     <div id="schedule"></div>
  `,
    'chats.actions.edit': html`
     <div id="schedule"></div>
  `,
  };
};
