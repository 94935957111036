import { Icons } from 'components/lit';
import {IAlertButton, IAlertItem} from 'interfaces';
import { html, nothing } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { repeat } from 'lit-html/directives/repeat';
import MarkdownIt from 'markdown-it';

import { Observe } from 'decorators';
import AlertsController from './alerts.controller';
import getIcon from './alerts.icons';
import styles from './alerts.module.scss';

const md = new MarkdownIt({
  html: true,
});

class AlertsView {
  public controller: AlertsController;

  public buttonsBlock(buttons: IAlertButton[], item: IAlertItem) {
    return html`
      <div class="${styles.alert_buttons}">
        ${ repeat(buttons, (b: IAlertButton) => b.text, (b) => this.btn(b, item)) }
      </div>
    `;
  }

  public btn(button: IAlertButton, item: IAlertItem) {
    const { handleAlertButton } = this.controller;

    return html`
      <w-button
        variant="primary"
        @click=${() => handleAlertButton(button, item)}
      >
        ${button.text}
      </w-button>`;
  }

  public item(item: IAlertItem) {
    const { formatDate, handleAlert } = this.controller;
    const { createdAt, attributes, read, title, text } = item;

    return html`
      <div class="${styles.alert}">
        <span>
          ${createdAt ? formatDate(createdAt) : nothing }
        </span>

        <div @click="${() => handleAlert(item, attributes.link)}">
          ${getIcon(attributes.icons)}

          <div>
            <h3 class="${!read ? styles.unread : ''}">${title}</h3>
            <div .innerHTML=${md.render(text)}></div>
          </div>
        </div>
        ${this.buttonsBlock(item.attributes.buttons, item)}
      </div>
    `;
  }

  public group(groupName: string, items: IAlertItem[]) {
    return html`
      <div class="${styles.header}">
        ${groupName}
      </div>

      ${this.controller.sortedAlertsByCreate(items).map((item) => this.item(item))}
    `;
  }


  @Observe('items', 'loading')
  get container() {
    const classes = {
      [styles.read_all]: true,
      [styles.disabled]: this.controller.loading || !this.controller.unreadAlertsIds.length,
    };

    const groupNames = Object.keys(this.controller.alertsGroups);

    const groups = groupNames.reduce((acc, groupName) => {
      const { alertsGroups } = this.controller;

      if (alertsGroups[groupName].length > 0) {
        acc.push(this.group(groupName, alertsGroups[groupName]));
      }

      return  acc;
    }, []);

    const alertsList = html`
      <div
        class="${classMap(classes)}"
        @click="${() => this.controller.readAll()}"
      >
        ${this.controller.loading ? Icons.loader : Icons.thumbUpIcon} Mark all as read
      </div>

      <div class="${styles.alerts_list}">
        ${groups}
      </div>

    `;

    const placeholder = html`
      <div class="${styles.placeholder}">
          ${Icons.silence}
          <div>No alerts yet.</div>
      </div>`;

    return html`
      <div class="${styles.container}">
        ${ groups.length ? alertsList : placeholder }
      </div>
    `;
  }

  get template() {
    return this.container;
  }
}

export default AlertsView;
