import apollo from 'api';
import { observable } from 'decorators';
import { NotificationManager } from 'services';
import RateThemeMutation from '../../graphql/mutations/rateTheme.graphql';
import CohortThemeQuery from '../../graphql/queries/cohortTheme.graphql';
import DashThemesModule from './dash-themes.module';

class DashThemesController {
  public module: DashThemesModule;
  @observable(null) public theme: any;
  @observable(null) public hasRated: any;

  constructor() {
    this.handleRating = this.handleRating.bind(this);
  }

  public async OnInit() {
    this.fetchTheme();
  }

  private async fetchTheme() {
    const { data: { cohortTheme } } = await apollo.query({
      query: CohortThemeQuery,
    });

    this.theme = cohortTheme;
    this.hasRated = !!cohortTheme.rating;
  }

  public async handleRating(response: string) {
    await apollo.mutate({
      mutation: RateThemeMutation,
      variables: {
        command: {
          themeId: this.theme.id,
          detail: '',
          response,
        },
      },
    });

    this.hasRated = true;

    NotificationManager.showNotification({
      text: "Thanks for your feedback. You've just earned 50 points!",
      type: 'success',
      duration: 3000,
    });
  }
}

export default DashThemesController;
