import { html } from 'lit-element';
import { customElement } from './decorators';
import { TemplatedElement } from './TemplatedElement';

@customElement({name: 'w-box'})
class WBox extends TemplatedElement {
  get name(): string {
    return this.getAttribute('name');
  }

  get label(): string {
    return this.getAttribute('label');
  }

  get template() {
    return html`
    <style>
      .wrapper {
        display: flex;
        flex-direction: column;
        transition: all 0.3s ease-in;
        margin-bottom: 2px;
        flex: 1;
      }

      label {
        margin-bottom: 10px;
        font-size: 0.95em;
        color: ${window.COLORS.DARK_GREY.hash};
      }

      .error {
        min-height: 14px;
        margin-left: 5px;
        margin-top: 10px;
        font-size: 12px;
        color: ${window.COLORS.PASTEL_RED.hash};
      }

      .field-wrapper {
        display: flex;
        width: 100%;
        height: 100%;
      }
    </style>
    <div class="wrapper">
      <label for="${this.name}">${this.label}</label>
      <div class="field-wrapper">
        <slot></slot>
      </div>
    </div>`;
  }
}

export default WBox;
