import React from 'react';
import { Router } from 'services/router.service';
import { findDOMNode, render, unmountComponentAtNode } from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import client from 'api';

export function manageMount(Component, id, props) {
  try {
    if (typeof id === 'string') {
      findDOMNode(id);
    } else if (typeof id === 'object') {

      render(
        <Component router={Router} {...props}/>,
        id
      );
    }

  } catch (e) {
    const block = document.getElementById(id);

    if (block) {
      render(
        <ApolloProvider client={client}>
          <Component router={Router} {...props} />
        </ApolloProvider>,
        block
      );
    }
  }
}

export function manageUnmount(id) {
  if (typeof id === 'string') {
    const container = document.getElementById(id);
    const component = findDOMNode(container);
    if (component) {
      unmountComponentAtNode(container);
    }
  } else if (typeof id === 'object') {
    const component = findDOMNode(id);

    if (component) {
      unmountComponentAtNode(id);
    }
  }
}
