import { Icons } from 'components/lit';
import { Observe } from 'decorators';
import { showIf } from 'directives';
import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import HeaderMenuController from 'modules/HeaderMenu/header-menu.controller';
import styles from './header-menu.module.scss';

class HeaderMenuView {
  public controller: HeaderMenuController;

  @Observe('muteOptions')
  public get muteStatus() {
    const { dateOfNotificationUnmute, isMuted } = this.controller;

    const unmuteDate = html`
      <div class=${styles['unmute-date']}>
        Notifications paused ${showIf(dateOfNotificationUnmute, `until ${dateOfNotificationUnmute}`)}
      </div>
    `;

    const turnOffLink = html`
      <div
        class="${styles.status} ${styles.link}"
        @click=${this.controller.onTurnOffMute}
      >
        Turn Off
      </div>
    `;

    const status = html`
      <div class="${styles['notification-status']}">
        ${turnOffLink}
        ${unmuteDate}
      </div>
    `;

    return showIf(isMuted, status);
  }

  public inactiveMuteOptionsMenu() {
    return html`
      <div class="${styles['menu-header']}">
        <div>Pause notifications for:</div>
        <button @click=${() => this.controller.close()}>${Icons.plus}</button>
      </div>

      ${this.muteStatus}

      ${repeat(this.controller.muteOptions, (option) => html`
        <div class=${styles['menu-item']} @click=${() => this.controller.onNotificationMute(option.value)}>${option.title}</div>
      `)}
      <div class=${styles['menu-item']} @click=${this.controller.onInfiniteNotificationMute}>Until further notice</div>
    `;
  }

  @Observe('doNotDisturb')
  public get doNotDisturb() {
    return html`
      <div class="${styles['do-not-disturb']}">
        <span>Do not disturb</span>
          <label class="${styles.switch}">
          <input
            type="checkbox"
            .checked=${this.controller.doNotDisturb}
            .defaultChecked=${this.controller.doNotDisturb}
            @change=${(evt) => this.controller.setDoNotDisturb(evt)}
          >
          <span class="${styles.slider}"/>
        </label>
      </div>
    `;
  }

  public get menu() {
    return html`
      <div class="${styles['menu-container']} ${styles.closed}">
        <div class="${styles['menu-header']}">
          <div>Menu</div>
          <button @click=${() => this.controller.close()}>${ Icons.plus }</button>
        </div>
        ${this.doNotDisturb}
        <hr class=${styles.rule} />
        <a
          class="${styles['menu-item']} ${styles.link}"
          target="_blank"
          href="https://help.getwerkin.com"
        >
          Help and advice
        </a>
        <div
          class="${styles['menu-item']} ${styles.link}"
          @click=${() => this.controller.activateModal('SuggestFeatureModal')}
        >
          Suggest an improvement
        </div>
        <div
          class="${styles['menu-item']} ${styles.link}"
          @click=${() => this.controller.activateModal('ReportProblemModal')}
        >
          Report a problem
        </div>
        <a
        class="${styles['menu-item']} ${styles.link}"
        target="_blank"
        href="https://getwerkin.com/terms-of-use"
      >
        Terms of use
      </a>
        <hr class=${styles.rule} />
        <div class="${styles['menu-item']} ${styles.link}" @click=${() => this.controller.activateModal('LogoutModal')}>Logout</div>
      </div>
    `;
  }

  get template() {
    return html`
      <div class="${styles['menu-block']}" @keydown=${(evt) => this.controller.keyDown(evt)}>
         <button
           @click=${() => this.controller.onMenuOpen()}
           class="${styles.menu}"
         >
           ${Icons.menuIcon('#f6f6f6')}
          </button>

          ${this.menu}
      </div>
    `;
  }
}

export default HeaderMenuView;
