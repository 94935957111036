import { html, svg } from 'lit-html';
import styles from './styles.module.scss';

const checked = svg`
  <svg viewBox="0 0 61 61">
    <g fill="none" fill-rule="evenodd">
      <rect width="57" height="57" x="2" y="2" fill="#4CB49C" stroke="#4CB49C" stroke-width="4" rx="15"/>
      <g fill="#FFF" transform="translate(14 17)">
      <rect width="4" height="13" x="4.01" y="14.51" rx="2" transform="rotate(-45 6.01 21.01)"/>
      <rect width="4" height="33" x="18.081" y="-2.561" rx="2" transform="rotate(45 20.081 13.94)"/>
      </g>
    </g>
  </svg>
`;

const unchecked = svg`
  <svg viewBox="0 0 61 61">
    <g fill="none" fill-rule="evenodd">
      <rect width="61" height="61" fill="#B9B9B9" opacity=".7" rx="15"/>
      <g fill="#FFF" transform="translate(14 17)">
        <rect width="4" height="13" x="4.01" y="14.51" rx="2" transform="rotate(-45 6.01 21.01)"/>
        <rect width="4" height="33" x="18.081" y="-2.561" rx="2" transform="rotate(45 20.081 13.94)"/>
      </g>
    </g>
  </svg>
`;

export default ({
    className,
    onChange,
    value,
    disabled = false,
  }) =>

  html`
  <div class="${styles.base} ${className} ${disabled ? styles.disabled : ''} checkbox" @click="${(evt) => onChange(!value, evt)}">
    ${value ? checked :  unchecked}
  </div>
`;
