import apollo from 'api';
import { errorHandler } from 'utils/error-helpers';

class BaseService {
  public load(): void {}

  public async queryPrefetcher(query, key, variables?) {
    console.log(`queryPrefetcher:${key}`);
    try {
      await apollo.query({
        query,
        variables,
        fetchPolicy: 'network-only',
      });
    } catch (err) {
      console.error(key, err);
      errorHandler(err, true);
    }
  }

  public setLoadingState(state) {
    const el = document.getElementById('preloader');
    const evt = new CustomEvent('loading_state', { detail: { state } });

    el.dispatchEvent(evt);
  }
}

export default BaseService;
