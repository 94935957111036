import apollo from 'api';
import store from '@werkin/store';
import { NotificationManager } from 'services';
import { leaveJoinChat, convertKeys } from 'utils/chat-helpers';

export default {
  apollo,
  store,
  showNotifications: NotificationManager.showNotification,
  leaveJoinChat,
  convertKeys,
};
