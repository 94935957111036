import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import ValidatorHelper from 'utils/validation-helpers';
import ResetPasswordController from './reset-password.controller';
import ResetPasswordView from './reset-password.view';

const plugins = new Map();

plugins.set('vHelper', new ValidatorHelper());

@RouterModule({
  name: 'w-reset-password-module',
  view: ResetPasswordView,
  controller: ResetPasswordController,
  plugins,
})
class ResetPasswordModule {
  public view: ResetPasswordView;

  public mount(module) {
    render(this.view.template, module);
  }
}

export default ResetPasswordModule;
