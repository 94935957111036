import Controller from 'services/templator/controller';

class SubNavController extends Controller {
  goBack() {
    this.router.go({ name: 'chats.chat' });
  }

  get isOpen() {
    return [
      'chats.info',
      'chats.activity',
      'chats.actions.list',
      'chats.actions.action',
    ].includes(this.router.route.name) || this.router.display === 'mobile';
  }

  get activePath() {
    return this.router.route.name;
  }
}

export default SubNavController;
