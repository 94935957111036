import React from 'react';
import PropTypes from 'prop-types';
import styles from './Accordion.module.scss';
import Text from 'atoms/Text';
import Icon from 'atoms/Icon';
import { classNames } from '@werkin/lib/helpers';
import { animated, useSpring } from 'react-spring';

const Accordion = (
  {
    children,
    title,
    className,
    headerClass,
    titleClass,
    isOpen,
    onOpen,
    parent,
  }
) => {
  const [isOpenLocal, toggle] = React.useState(false);

  const open = isOpen || isOpenLocal;

  const wrapperClasses = classNames('wrapper', styles, className);
  const headerClasses = classNames('header', styles, headerClass);
  const titleClasses = classNames(
    `title ${isOpen && 'underline'}`,
    styles,
    titleClass
  );

  const container = React.useRef(null);

  const [props, set] = useSpring(() => ({ height: 0, display: 'none', config: { duration: 200 } }));

  React.useEffect(() => {
    if (open) {
      set({
        to: async (next) => {
          await next({ display: 'block' });
          await next({ height: container.current.scrollHeight });
          await next({ height: 'auto' });
        },
      });
    } else {
      set({
        to: async (next) => {
          await next({ height: container.current.scrollHeight });
          await next({ height: 0 });
          await next({ display: 'none' });
        },
      });
    }
  }, [open, set]);

  const ref = React.useRef();

  const handleToggle = () => {
    onOpen ? onOpen() : toggle(!isOpenLocal);

    setTimeout(() => {
      parent && parent.current.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
      });
    }, 300);
  };

  return (
    <div className={wrapperClasses} ref={ref}>
      <div className={headerClasses} onClick={handleToggle}>
        <Text tag='h4' color='deep-teal' className={titleClasses}>{ title }</Text>
        <Icon className={styles.icon} name={isOpen ? 'simple-arrow-up' : 'simple-arrow-down' } />
      </div>

      <animated.div className={styles.collapsible} ref={container} style={props}>
        { children }
      </animated.div>
    </div>
  );
};

Accordion.propTypes = {
  children: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  headerClass: PropTypes.string,
  titleClass: PropTypes.string,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  parent: PropTypes.object,
};

export default Accordion;
