export function Module(Target) {
  const target = new Target();

  target.name = Target.name;
  target.controller = {};
  target.view = {};
  target.vm = {};

  target.mount = target.mount ? target.mount : () => {};
  target.unmount = target.unmount ? target.unmount : () => {};

  target.subs = new Set([]);

  target.addSubscription = (sub) => {
    target.subs.add(sub);
  };

  return Object.defineProperty(target, 'destroy', {
    get(): any {
      return () => {
        target.controller.OnDestroy && target.controller.OnDestroy();
        target.view.OnDestroy && target.view.OnDestroy();
        target.vm.OnDestroy && target.vm.OnDestroy();

        target.subs.forEach((subscription) => subscription());
        target.subs = new Set([]);
      };
    },
  });
}
