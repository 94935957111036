import { render } from 'lit-html';
import MatchHeaderController from 'modules/MatchHeader/match-header.controller';
import MatchHeaderView from 'modules/MatchHeader/match-header.view';
import MatchService from 'services/data/match.service';
import { RouterModule } from 'services/router/router.m';

@RouterModule({
  name: 'w-match-header-module',
  view: MatchHeaderView,
  controller: MatchHeaderController,
})
class MatchHeaderModule {
  public view: MatchHeaderView;
  public controller: MatchHeaderController;

  public async loading() {
    const data = await MatchService.requestMatch();

    this.controller.match = data.match;
  }

  public mount(module) {
    render(this.view.template, module);
  }
}

export default MatchHeaderModule;
