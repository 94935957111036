import { RouterModule } from 'services/router/router.m';
import {manageMount} from '../../../lib/lit-components-manager';
import SuggestionsController from './suggestions.controller';
import SuggestionsView from './suggestions.view';
import SuggestionsViewModel from './suggestions.viewmodel';

@RouterModule({
  view: SuggestionsView,
  controller: SuggestionsController,
  vm: SuggestionsViewModel,
})
class SuggestionsModule {
  public view: SuggestionsView;

  public mount() {
    manageMount(this.view.template, 'chat_suggestions');
  }
}

export default SuggestionsModule;
