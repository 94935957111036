import { Icons } from 'components/lit';
import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';

import HeaderAlertsController from './header-alerts.controller';
import styles from './header-alerts.module.scss';

class HeaderAlertsView {
  public controller: HeaderAlertsController;

  public get template() {
    return html`
      ${this.nav()}
      ${this.alerts }
    `;
  }

  @Observe('active')
  private nav() {
    const IsActive = this.controller.active;

    return html`
      <div
        class=${[styles.nav, IsActive ? styles.active : ''].join(' ')}
        @click=${() => this.controller.handleAlertsModal()}
      >
        ${Icons.alerts(IsActive, '#fff')} ${this.badge} <span>Alerts</span>
      </div>
    `;
  }

  @Observe('active')
  public get alerts() {
    return this.controller.active ? html`<w-alerts-module class="${styles.wrapper}" />` : nothing;
  }

  @Observe('unreadCount')
  get badge() {
    const { unreadCount } = this.controller;

    return unreadCount ? html`<div class=${styles.badge}>${unreadCount}</div>` : nothing;
  }
}

export default HeaderAlertsView;
