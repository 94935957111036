import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import Modal from 'modules/Modals';
import { IntlProvider } from 'react-intl';
import { locale, translations } from 'utils/i18n';
import Router from 'react-router-dom/es/Router';
import { ApolloProvider } from '@apollo/react-hooks';
import client from 'api';
import history from 'utils/history';

export function useOpenModal() {
  return React.useCallback((modalName, props = {}, parent) => {
    /** Ensure that configModalName is a string */
    const ModalComponent = Modal[typeof modalName  === 'string' && modalName];
    /** Get appropriate place for modal or create new */
    let div = document.getElementById(modalName);

    if (!div) {
      div = document.createElement('div');
      div.id = modalName;
    }

    if (parent) {
      div.style.position = 'absolute';
      div.style.top = '0';
      div.style.bottom = '0';
      div.style.left = '0';
      div.style.right = '0';
    }

    if (props.zIndex) {
      div.style['z-index'] = props.zIndex;
    }

    const element = parent || document.body;

    element.appendChild(div);

    const unmount = () => {
      const div = document.getElementById(modalName);
      if (div) {
        unmountComponentAtNode(div);
        element.removeChild(div);
      }
    };

    const unsubscribe = history.listen(() => {
      unmount();
      unsubscribe();
    });

    render(
      <IntlProvider locale={locale} messages={translations}>
        <ApolloProvider client={client}>
          <Router history={history}>
            <ModalComponent close={ unmount } {...props} />
          </Router>
        </ApolloProvider>
      </IntlProvider>,
      div);
  }, []);
}
