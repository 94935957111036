import { Icons, loader } from 'components/lit';
import { Observe } from 'decorators';
import { html, nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import JoinTemplate from 'modules/Join/join.view';
import styles from '../join.module.scss';

class StepFourFragment {
  public view: JoinTemplate;

  get fields() {
    return ['skills'];
  }

  get header() {
    return html`
     <div class="${styles.desc}">
         Knowing your interests and skills, as well as the level of experience or knowledge you have in each,
         helps us create the right connections and start great conversations.
      </div>
    `;
  }


  private selectedItem(id) {
    const item = this.view.controller.selected[id];

    return html`
      <div class="${styles.added_skill_card}">
        <button
          type="button"
          @click=${() => this.view.controller.removeSkill(item.id)}
        >
          ${Icons.remove}
        </button>

        <div class=${styles.added_skill_card_label}>${item.label}</div>

        <div class="${styles.experience}">
          <button
            type="button"
            ?disabled=${!item.experience}
            @click=${() => this.view.controller.updateExperience(item, -1)}
          >
            ${Icons.circleArrowLeft}</button>
          <div>${ item.experience }</div>
          <button type="button" @click=${() => this.view.controller.updateExperience(item, 1)}>${Icons.circleArrowRight}</button>
        </div>
      </div>
    `;
  }


  @Observe('selected')
  get selectedSection() {
    const ids = Object.keys(this.view.controller.selected);

    return !!ids.length ? html`
      <div>
        ${repeat(ids, (key) => key, (id) => this.selectedItem(id) )}
      </div>
    ` : nothing;
  }

  @Observe('search')
  get searchField() {
    return html`
      <w-search
        @input=${(evt) => this.view.controller.onInput(evt)}
        @search=${(evt) => this.view.controller.onSearch(evt)}
        @clear=${(evt) => this.view.controller.onInput(evt)}
        searchDelay="1000"
        name="search"
        placeholder="Search"
        label="Search for skills and interests"
        value="${this.view.controller.search}"
      > </w-search>
    `;
  }

  @Observe('filteredResults', 'loadingSkills')
  get resultsSection() {
    const { filteredResults, loadingSkills } = this.view.controller;

    return !!filteredResults.length && !loadingSkills ? html`
      <div class="${styles.results}">
        ${repeat(this.view.controller.filteredResults, (key: any) => key.id, (item) => this.resultCard(item) )}
      </div>
    ` : nothing;
  }

  private get buttons() {
    return html`
      <div class="${styles.buttons}">
        <button
          type="button"
          @click=${(e) => this.view.controller.onBack(e)}
          class="w-button outline ${styles.back}"
        >
          Back
         </button>

       <button
        class="w-button primary ${styles.next}"
        @click=${(e) => this.view.controller.onNext(e, 'Skills and interests completed')}
        >
        Next
        </button>
    </div>
    `;
  }

  get template() {
    return html`
      ${this.header}
      ${this.selectedSection}
      ${this.searchField}
      ${this.loading}
      ${this.placeholder}
      ${this.resultsSection}
      ${this.buttons}
    `;
  }

  public resultCard(item) {
    return html`
        <div class="${styles.skill_card}">
          <div>${item.label}</div>

          <button type="button" @click=${() => this.view.controller.addSkill(item)}> Add </button>
        </div>
      `;
  }

  @Observe('filteredResults', 'search', 'loadingSkills')
  get placeholder() {
    const { filteredResults, loadingSkills, search } = this.view.controller;

    return !filteredResults.length && search.length && !loadingSkills ? html`
      <div class="${styles.no_results}">
         ${Icons.brain}
         <div>There are no skills or interests matching your criteria. Please try a different search.</div>
      </div>
    ` : nothing;
  }

  @Observe('loadingSkills')
  get loading() {
    return this.view.controller.loadingSkills ? html`
      <div class="${styles.loading}">
        ${ loader }
      </div>
    ` : nothing;
  }
}

export default StepFourFragment;
