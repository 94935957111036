import store from '@werkin/store';
import { directive } from 'lit-html';
import { Router } from 'services/router.service';
import Sentry from 'services/sentry.service';

export function observable(d?: any, resetOnDestroy = false): any {
  return (target, propertyKey: string) =>
    ({
      get() {
        const storeKey = `${this.module.name}.${propertyKey}`;
        const value = store.get(storeKey);

        if (resetOnDestroy) {
          const resetCb = () => store.unset(storeKey);

          this.module.addSubscription(resetCb);
        } else {
          const unsubscribe = Router.listenMap(storeKey, () => {
            store.unset(storeKey);
          });

          this.module.addSubscription(unsubscribe);
        }

        if (value === undefined) {
          store.set(storeKey, d);

          return d;
        }

        return value;
      },
      set(value) {
        store.set(`${this.module.name}.${propertyKey}`, value);
      },
    });
}

export function ObserveMethod(...properties: string[]): any {
  return (target, propertyKey: string, descriptor: PropertyDescriptor) => {
    const isGetter = !!descriptor.get;
    const originFn = isGetter ? descriptor.get : descriptor.value;

    const dir = function(...args) {
      const keys = properties.map((key) => `${this.module.name}.${key}`);
      const unsubscribe = store.multipleSubscribe(keys, () => {
        originFn.call(this, ...args);
      });

      this.module.subs.add(unsubscribe);
    };

    if (isGetter) {
      descriptor.get = dir;
    } else {
      descriptor.value = dir;
    }
  };
}

export function Observe(...properties: string[]): any {
  return (target, propertyKey: string, descriptor: PropertyDescriptor) => {
    const isGetter = !!descriptor.get;
    const originFn = isGetter ? descriptor.get : descriptor.value;

    const dir = function(...args) {
      return directive( () => (part) => {
        const keys = properties.map((key) => `${this.module.name}.${key}`);
        const unsubscribe = store.multipleSubscribe(keys, () => {
          setImmediate(() => {
            try {
              if (part.startNode.isConnected) {
                part.setValue(originFn.call(this, ...args));
                part.commit();
              } else {
                unsubscribe();
              }
            } catch (e) {
              const errMsg =
                `Error in ${this.module.name}/${target.constructor.name} [${propertyKey}] \n`;
              console.error(errMsg, e);
              Sentry.captureException(e);
            }
          });
        });

        this.module.addSubscription(unsubscribe);
      })();
    };

    if (isGetter) {
      descriptor.get = dir;
    } else {
      descriptor.value = dir;
    }
  };
}
