import styles from 'components/custom/w-textarea.module.scss';
import { customElement } from './decorators';
import Validator from './validators';


@customElement({name: 'w-textarea'})
class WTextArea extends HTMLElement {
  public validators: Array<(value: any) => string> = [];
  private _el: HTMLTextAreaElement = document.createElement('textarea');

  constructor() {
    super();
    setImmediate(() => this.appendChild(this._el));
    this._el.classList.add(styles['w-textarea']);
  }

  static get observedAttributes() {
    return [
      'rows',
      'name',
      'placeholder',
      'type',
      'autofocus',
      'value',
      'disabled',
      'minlength',
      ...Object.keys(Validator),
    ];
  }

  public connectedCallback() {
    setImmediate(() => {
      const { form } = this._el;
      if (!form.controls) {
        form.controls = new Map();
      }
      const errors = this.validators.map((fn) => fn(this._el.value)).filter((e) => !!e);
      this._el.setCustomValidity(errors[0] || '');
      const { name, validity, value, validationMessage } = this._el;
      form.controls.set(name, { valid: validity.valid, validationMessage, value });
    });
    this._el.addEventListener('keyup', this.onInput);
  }

  public disconnectedCallback() {
    this._el.removeEventListener('keyup', this.onInput);
  }

  public attributeChangedCallback(name, oldValue, newValue) {
    if (!!Validator.hasOwnProperty(name)) {
      this.validators.push(Validator[name]);
    } else {
      this._el.setAttribute(name, newValue);
    }

    if (name === 'value' && newValue && oldValue === null) {
      this._el.innerText = newValue;
      this._el.setAttribute(name, newValue);

      setImmediate(() => {
        this.addFormControls();
        this.validateInput();
      });

    }
  }

  private validateInput() {
    const { form, name, validity, value, validationMessage } = this._el;

    const errors = this.validators.map((fn) => fn(this._el.value)).filter((e) => !!e);
    this._el.setCustomValidity(errors[0] || '');
    const validationEvent = new CustomEvent('validation', {bubbles: true});
    this._el.dispatchEvent(validationEvent);
    form.controls.set(name, { valid: validity.valid, validationMessage, value });
  }

  private addFormControls() {
    if (!this._el.form.controls) {
      this._el.form.controls = new Map();
    }
  }

  private onInput = () => {
    const { form, name, validity, value, validationMessage } = this._el;
    const errors = this.validators.map((fn) => fn(this._el.value)).filter((e) => !!e);
    this._el.setCustomValidity(errors[0] || '');
    const validationEvent = new CustomEvent('validation', {bubbles: true});
    this._el.dispatchEvent(validationEvent);
    form.controls.set(name, { valid: validity.valid, validationMessage, value });
    const changeEvent = new CustomEvent('changeForm', { bubbles: true, detail: { current: this._el } });
    form.dispatchEvent(changeEvent);
  }

}

export default WTextArea;
