import ModalBase from 'atoms/lit/modal/base';
import { Router } from 'services/router.service';

class AutonomousModal extends ModalBase {
  constructor() {
    super();

    this.modal_name = 'AutonomousModal';
  }

  get router() {
    return Router;
  }

  destroy() {
    this.router.deleteHash(this.modal_name);

    super.destroy();
  }
}

export default AutonomousModal;
