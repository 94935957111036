import apollo from 'api';
import { Router } from 'services/router.service';
import RouterError from 'services/router/router-error';
import { RouteStrategy } from 'services/router/router.interfaces';
import IndividualQuery from '../../graphql/queries/individual.graphql';
import MatchQuery from '../../graphql/queries/match.graphql';
import MatchesWithRequestsQuery from '../../graphql/queries/matchesWithRequests.graphql';

class MatchService {
  public request: any;
  private promise: Promise<any>;

  public requestMatch() {
    if (!this.promise) {
      this.promise = apollo.query({ query: MatchQuery })
        .then(({ data }) => {

        if (!data.match) {
          Router.go({ name: 'no-match', strategy: RouteStrategy.replace });

          return Promise.reject(new RouterError({ message: 'No match found' }));
        }

        return data;
      });
    }

    return Promise.all([this.promise]).then(([ data ]) => {
      this.promise = undefined;

      return data;
    });
  }

  public async getMatches(id: string) {
    apollo.query({
      query: IndividualQuery,
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });

    const { data: { matchesWithRequests } } = await apollo.query({
      query: MatchesWithRequestsQuery,
      fetchPolicy: 'network-only',
    });

    return matchesWithRequests;
  }
}

export default new MatchService();
