import apollo from 'api';
import { observable } from 'decorators';
import { IPoll } from 'interfaces/poll.interface';
import { NotificationManager } from 'services';
import { Router } from 'services/router.service';
import RespondToPollMutation from '../../graphql/mutations/respondToPoll.graphql';
import PollQuery from '../../graphql/queries/poll.graphql';
import PollerModule from './poller.module';

class PollerController {
  public module: PollerModule;
  public destroy: () => void;
  public pollId: string;
  @observable(null, true) public pollState: string;
  @observable(false, true) public showInput: boolean;
  @observable(null, true) public activePoll: IPoll;

  constructor() {
    this.handleSelection = this.handleSelection.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  public async OnInit() {
    if (!this.pollId) {
      this.pollState = 'ERROR';

      return;
    }

    this.fetchPoll(this.pollId);
  }

  private async fetchPoll(id: string) {
    try {
      const { data: { poll } } = await apollo.query({
        query: PollQuery,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      });

      this.activePoll = poll;
      this.pollState = poll.state;
    } catch (e) {
      this.pollState = 'ERROR';
    }
  }

  private async respondToPoll(response: string) {
    await apollo.mutate({
      mutation: RespondToPollMutation,
      variables: {
        command: {
          pollId: this.pollId,
          state: 'COMPLETED',
          response,
        },
      },
    });

    NotificationManager.showNotification({ text: 'Thanks for telling us.', type: 'success', close: false });
    this.handleClose();
  }

  public handleClose() {
    Router.deleteHash('pollId');
    this.destroy();
  }

  public handleSelection(type: string, value: string) {
    if (type === 'INPUT') {
      return this.showInput = !this.showInput;
    }

    this.respondToPoll(value);
  }

  public handleForm(e) {
    e.preventDefault();
    const response = e.target.poll.value;

    if (!response) { return; }

    this.respondToPoll(response);
  }
}

export default PollerController;
