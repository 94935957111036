import ErrorView from 'modules/Error';
import RouterError from 'services/router/router-error';
import { errorHandler } from 'utils/error-helpers';
import { IModuleParams } from './router.interfaces';
import Controller from './templator/controller';
import { Module as ModuleConfigurator } from './templator/module';
import { View} from './templator/view';
import { ViewModel } from './templator/vm';

export function RouterModule(params?: IModuleParams) {
  return (Target) => {
    const target = ModuleConfigurator(Target);

    const defaultChild = () => ({
      OnInit: () => {},
      OnDestroy: () => {},
    });

    if (!params) { return target; }

    const { controller: TargetController, view: TargetView, vm: TargetViewModel, data, fragments, plugins } = params;
    const view = TargetView ? View(target, TargetView, fragments, data) : defaultChild();
    const controller = TargetController ? Controller(TargetController, plugins, data) : defaultChild();
    const vm = TargetViewModel ? ViewModel(TargetViewModel, data) : defaultChild();

    view.vm = vm;
    view.module = target;
    view.controller = controller;

    controller.vm = vm;
    controller.view = view;
    controller.module = target;

    vm.view = view;
    vm.module = target;
    vm.controller = controller;

    target.controller = controller;
    target.view = view;
    target.vm = vm;
    const isElementRegistered = params.name && !!customElements.get(params.name);

    if (params.name && !isElementRegistered) {
      customElements.define(
        params.name,
        class extends HTMLElement {

          static get observedAttributes() {
            return ['key', ...(params.observers || [])];
          }

          public connectedCallback() {
            Promise.resolve()
              .then(() =>  target.loading && target.loading())
              .then(() => this.mountModule())
              .catch(this.handleError);
          }

          public disconnectedCallback() {
            this.unmountModule();
          }

          public attributeChangedCallback(name, oldValue, newValue) {
            this[name] = newValue;

            if (!!oldValue && newValue !== 'undefined') {
              this.update();
            }
          }

          private mountModule() {
            target.mount(this);
            target.controller.OnInit();
            target.view.OnInit();
          }

          private unmountModule() {
            const event = new CustomEvent('destroy', { detail: target });

            target.unmount();
            target.destroy();

            this.dispatchEvent(event);
          }

          private update() {
            target.remount && target.remount(this);
          }

          private handleError(e) {
            const defaultDescription = 'Error in method mount of module ' + params.name;

            const error = {
              name: e.name,
              description: e.description || defaultDescription,
              message: e.message,
            };

            if (e instanceof RouterError) {
              return;
            }

            ErrorView.mount();

            if (e.message && !e.message.includes('GraphQL error')) {
              errorHandler(e, true);
            } else if (e.message) {
              console.log(error);
            }

            if (e.stack) {
              console.error(e);
            }
          }
        }
      );
    }

    return Object.defineProperty(target, 'data', {
      get(): any { return target.controller.data; },
      set(data: any): void {
        target.controller.data = data;
        target.view.data = data;
        target.vm.data = data;
      },
    });
  };
}
