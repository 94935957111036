declare global {
  interface Window { COLORS: any; }
}

window.COLORS = {
  DEEP_TEAL: {
    hash: '#014660',
    rgb: '1, 70, 96',
  },
  GREYSTASH_TEAL: {
    hash: '#4cb49c',
    rgb: '76, 180, 156',
  },
  VERY_LIGHT_GREY: {
    hash: '#b9b9b9',
    rgb: '185, 185, 185',
  },
  WARM_GREY: {
    hash: '#939292',
    rgb: '147, 146, 146',
  },
  SIMPLE_GREY: {
    hash: '#ddd',
    rgb: '221, 221, 221',
  },
  DARK_GREY: {
    hash: '#4d4d4d',
    rgb: '77, 77, 77',
  },
  WHITE: {
    hash: '#f6f6f6',
    rgb: '246, 246 246',
  },
  FLAT_WHITE: {
    hash: '#fff',
    rgb: '255, 255, 255',
  },
  BLACK: {
    hash: '#000',
    rgb: '0,0,0',
  },
  PASTEL_RED: {
    hash: '#e55e50',
    rgb: '229, 94, 80',
  },
  PASTEL_PINK: {
    hash: '#ffe6d8',
    rgb: '255, 230, 216',
  },
  PASTEL_ORANGE: {
    hash: '#dda700',
    rgb: '221, 167, 0',
  },
  PASTEL_GREEN: {
    hash: '#007516',
    rgb: '0, 117, 22',
  },
  PASTEL_GREY: {
    hash: '#f2f2f2',
    rgb: '242, 242, 242',
  },
  VERY_LIGHT_PINK: {
    hash: '#b9b9b9',
    rgb: '185, 185, 185',
  },
  PASTEL_YELLOW: {
    hash: '#f8de88',
    rgb: '248, 22, 146',
  },
};


export const COLORS = window.COLORS;
