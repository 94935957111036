import { Observe } from 'decorators';
import { html, nothing, svg } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import MarkdownIt from 'markdown-it';
import LaunchController from './launch.controller';
import styles from './launch.module.scss';

class LaunchTemplate {
  private controller: LaunchController;
  public md = new MarkdownIt();

  @Observe('activeIndex')
  get links() {
    return repeat(
      this.controller.launchData.blocks,
      (b: any) => b.value,
      (b, i) => this.link(b, i)
    );
  }

  @Observe('activeIndex')
  get mobilelinks() {
    return repeat(
      this.controller.launchData.blocks,
      (b: any) => b.value,
      (b, i) => this.mobileLink(b, i)
    );
  }

  private mobileLink({ mTitle, value}, i) {
    return mTitle ?
      html`<span
          ?active=${!this.controller.isRequirementsOpened && this.controller.activeIndex === i}
          @click="${() => this.controller.openMobileLink(value)}"
          >${mTitle}</span>`
      : nothing;
  }


  private link({ title, value}, i) {
    return title ?
    html`<span
          ?active=${!this.controller.isRequirementsOpened && this.controller.activeIndex === i}
          @click="${() => this.controller.scrollTo(value)}"
          >${title}</span>`
  : nothing;
  }

  get calendarIcon() {
    return svg`
      <svg viewBox="0 0 42 47">
        <g>
          <g clip-path="url(#clip-49B26665-74E4-4688-8D1E-480405B48B41)">
            <path fill="#014660" d="M39.557 12.138h-37.2V9.265A3.506 3.506 0 0 1 5.843 5.76h4.824v2.243c0 .645.52 1.168 1.163 1.168.642 0 1.162-.523 1.162-1.168V5.76h15.915v2.243c0 .645.52 1.168 1.163 1.168.642 0 1.162-.523 1.162-1.168V5.76h4.836a3.505 3.505 0 0 1 3.488 3.505zm0 29.032a3.506 3.506 0 0 1-3.488 3.505H5.844a3.506 3.506 0 0 1-3.488-3.505V14.475h37.2zM36.07 3.423h-4.825V1.168c0-.645-.52-1.168-1.162-1.168-.642 0-1.163.523-1.163 1.168v2.243H12.993V1.168C12.993.523 12.473 0 11.83 0c-.642 0-1.162.523-1.162 1.168v2.243H5.843C2.633 3.413.031 6.027.03 9.253v31.906c.001 3.225 2.603 5.84 5.813 5.841H36.07c3.21-.001 5.812-2.616 5.813-5.841V9.265c-.001-3.226-2.603-5.841-5.813-5.842z"/>
          </g>
        </g>
      </svg>
    `;
  }

  get launchMenu() {
    return svg`
     <svg @click=${() => this.controller.toggleMobileMenu()} viewBox="0 0 141 101" fill="#fff">
      <g>
        <g>
          <g>
            <path d="M4.406 12h132.188C139.026 12 141 9.312 141 6s-1.974-6-4.406-6H4.406C1.974 0 0 2.688 0 6s1.974 6 4.406 6z"/>
          </g>
          <g>
            <path d="M136.594 45H4.406C1.974 45 0 47.688 0 51s1.974 6 4.406 6h132.188c2.432 0 4.406-2.688 4.406-6s-1.974-6-4.406-6z"/>
          </g>
          <g>
            <path d="M134.591 89H6.41C2.871 89 0 91.688 0 95s2.872 6 6.409 6H134.59c3.538 0 6.409-2.688 6.409-6s-2.871-6-6.409-6z"/>
          </g>
        </g>
      </g>
    </svg>
    `;
  }

  get launchClose() {
    return svg`
    <svg @click=${() => this.controller.toggleMobileMenu()} viewBox="0 0 109 109" fill="#fff">
      <g stroke="none" stroke-width="1" fill-rule="evenodd">
        <g fill-rule="nonzero">
          <path d="M-9.59385301,61 L122.593853,61 C125.026207,61 127,58.3117207 127,55 C127,51.6877805 125.025841,49 122.593853,49 L-9.59385301,49 C-12.0262073,49 -14,51.6882793 -14,55 C-14,58.3117207 -12.025841,61 -9.59385301,61 Z" id="Path" transform="translate(56.500000, 55.000000) rotate(-45.000000) translate(-56.500000, -55.000000) " />
          <path d="M118.85266,49.4267743 L-9.32944918,49.4267743 C-12.8674216,49.4267743 -15.7383948,52.1150536 -15.7383948,55.4267743 C-15.7383948,58.7389938 -12.8668888,61.4267743 -9.32944918,61.4267743 L118.85266,61.4267743 C122.390632,61.4267743 125.261605,58.738495 125.261605,55.4267743 C125.262138,52.1145549 122.390632,49.4267743 118.85266,49.4267743 Z" id="Path" transform="translate(54.761605, 55.426774) rotate(-315.000000) translate(-54.761605, -55.426774) " />
        </g>
      </g>
    </svg>
    `;
  }

  private get launchWrench() {
    return svg`
      <svg  viewBox="0 0 25 25" fill="#fff">
        <g>
          <path d="M24.787 5.404a.68.68 0 0 0-1.137-.315l-3.023 3.012a1.724 1.724 0 0 1-2.43 0L16.81 6.719a1.708 1.708 0 0 1 0-2.422l2.986-2.975a.675.675 0 0 0-.324-1.135 7.252 7.252 0 0 0-2.224-.164c-3.31.264-6.357 3.357-6.563 6.66a7.097 7.097 0 0 0 .67 3.485L.789 19.216a2.252 2.252 0 0 0-.14 3.292l1.791 1.817a2.268 2.268 0 0 0 3.33-.107l9.153-10.566a7.172 7.172 0 0 0 3.373.611c3.337-.207 6.442-3.268 6.685-6.59a7.163 7.163 0 0 0-.193-2.269z"/>
        </g>
      </svg>
    `;
  }

  @Observe('top', 'isRequirementsOpened')
  public get header() {
    return html`
      <div id="launch-header"  class="${styles.header}" style="${this.controller.headerStyle}">
        <img  src="${this.controller.logo}"/>
        <div class="${styles.nav}">
         ${this.links}
        </div>
        <div class="${styles.options}">
           <span class=${styles.menu}>${this.launchMenu}</span>
           <span class=${styles.wrench} @click=${() => this.controller.onOpenRequirements()}>${this.launchWrench}</span>
        </div>
      </div>
    `;
  }

  get timer() {
    return this.controller.launchData.timer
      ? html`
        <div class="${styles.timer}">
          <div class="${styles.title}">
          ${this.controller.launchData.timer.title}
          <span>${this.controller.launchData.timer.dateText}</span>
          </div>
          <w-timer
           .date=${this.controller.launchData.keyDates.cohortLaunch.date}
           .unitColor="${this.controller.launchData.timer.colors.unit}"
           .valueColor="${this.controller.launchData.timer.colors.value}"
           />
        </div>`
      : nothing;
  }

  get sponsor() {
    const { photo, title, text } = this.controller.launchData.sponsor;

    return html`
      <div id="sponsor" class="${styles.sponsor}">
        <div class="${styles.mask}"></div>
          <img src="${photo}" alt='Sponsor' />
          <div class="${styles.text}">
          <h2>${title}</h2>
          <span>${text}</span>
        </div>
      </div>
    `;
  }

  private date(d) {
    const day = d.getDate();
    const month = d.toLocaleString('en-US', { month: 'short' });

    return html`
      <div class=${styles.date}>
        ${this.calendarIcon}
        <span class=${styles.day}>${day}</span>
        <span class=${styles.month}>${month}</span>
        <hr/>
      </div>
    `;
  }

  private card({ title, text, action }) {
    return html`
      <div class=${styles.card}>
        <div class=${styles.top}>
           <div class=${styles.cardTitle}>
              <h3>${this.md.render(title)}</h3>
           </div>
           <div class=${styles.cardText}>${this.md.render(text)}</div>
        </div>
        <div class=${styles.bottom}>${this.md.render(action)}</div>
      </div>
    `;
  }

  private keyDate({ date, ...rest}: any) {
    return html`
      <div class=${styles.keyDate}>
        ${this.date(date)}
        ${this.card(rest)}
      </div>
    `;
  }

  get dates() {
    return html`
      <div id="dates" class=${styles.keyDatesContainer}>
        <h2>Key Dates</h2>
        ${repeat(
          Object.keys(this.controller.launchData.keyDates || {}),
      (k) => this.keyDate(this.controller.launchData.keyDates[k]))}
      </div>
    `;
  }

  get video() {
    return this.controller.launchData.video ? html`<div id="video" style="height: 700px">video</div>` : null;
  }

  get tips() {
    return html`
      <div id="tips" class=${styles.tips}>
        <div class=${styles.inner}>
          <h2>Tips from the WERKIN world</h2>
          <div class=${styles.tipsWrapper}>${repeat(this.controller.launchData.tips, (t: any) => this.tip(t))}</div>
        </div>
      </div>
    `;
  }

  public tip({ avatar, name, text, link, btnText }) {
    return html`
      <div class=${styles.tip}>
        <img src=${avatar}>
        <div class=${styles.info}>
          <h3>${name}</h3>
          <div>${text}</div>
          <a target=_blank href=${link}>${btnText}</a>
        </div>
      </div>
    `;
  }

  get footer() {
    return html`
      <div id="footer" class=${styles.footer}>
        <div class=${styles.left}></div>
        <div class=${styles.right}>
          <div class=${styles.footerCard}>
            <div class=${styles.footerCardTop}>
              <h3>${this.controller.launchData.footer.title}</h3>
              <h4>${this.controller.launchData.footer.subtitle}</h4>
            </div>
            <div class=${styles.footerCardBottom}>
              <img src=${this.controller.launchData.footer.pic}>
              <div>${this.controller.launchData.footer.text}</div>
            </div>
          </div>
        </div>
      </div>`;
  }

  @Observe('activeIndex')
  get navigation() {
    return html`
      <div class=${styles.navigation}>
        <w-navigation
         @select=${({ detail }) => this.controller.onSelect(detail)}
         .dotsLength=${this.controller.launchData.blocks.length}
         .active=${this.controller.activeIndex}>
      </div>`;
  }

  @Observe('isMenuActive', 'isRequirementsOpened')
  get mobileMenu() {
    const menu = html`
      <div class=${styles.mobileMenu}>
        <div class=${styles.mobileHeader}>
          <img src=${this.controller.logo}>
          ${this.launchClose}
        </div>
        <div class=${styles.mobileNav}>
          ${this.mobilelinks}
        </div>
        <hr/>
        <div class=${styles.mobileNav}>
          <span
            ?active=${this.controller.isRequirementsOpened}
            @click=${() => this.controller.onOpenRequirements()}
          >
            ${this.launchWrench} Technical requirements
          </span>
        </div>
      </div>`;

    return this.controller.isMenuActive ? menu : nothing;
  }

  @Observe('isRequirementsOpened')
  get modal() {
    return this.controller.isRequirementsOpened
      ? html`<div id="launch_modal" class=${styles.launchModal}></div>`
      : nothing;
  }

  @Observe('launchData')
  get launchView() {
    const blocks = this.controller.launchData.blocks;

    return blocks
      ? html`
        ${this.mobileMenu}
        ${this.navigation}
        ${this.header}
        ${this.timer}
        ${repeat(blocks, (block: any) => block.value, ({value}) => this[value])}
      `
      : nothing;
  }

  get template() {
    return html`
      <div class="${styles.launch}">
        ${this.modal}
        ${this.launchView}
      </div>
    `;
  }
}

export default LaunchTemplate;
