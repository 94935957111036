import apollo from 'api';
import { WorkerPermissionTypes } from 'interfaces';
import { Auth } from 'services';
import BaseService from 'services/data/base.service';
import setIndividualNotificationPreferences from '../../graphql/mutations/setAllowPushNotifications.graphql';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';
import ProfileAndIndividualQuery from '../../graphql/queries/profile/ProfileAndIndividual.graphql';

class ProfileService extends BaseService {
  public async load() {
    this.setLoadingState('Loading your profile');

    return this.queryPrefetcher(ProfileQuery, 'profile', { id: Auth.getUserId() });
  }

  public updateProfile = (data) => {
    apollo.query({
      query: ProfileQuery,
      variables: {
        id: Auth.getUserId(),
      },
      fetchPolicy: 'network-only',
    });
  }

  public async getProfile() {
    const { data: { profile } } = await apollo.query({
      query: ProfileQuery,
    });

    return profile;
  }

  public async getProfileAndIndividual() {
    const { data: { profile, individual } } = await apollo.query({
      query: ProfileAndIndividualQuery,
      variables: {
        id: Auth.getUserId(),
      },
    });

    return { profile, individual };
  }

  public async setNotificationStatus(value: WorkerPermissionTypes) {
    try {
      await apollo.mutate({
        mutation: setIndividualNotificationPreferences,
        variables: {
          command: {
            allowPushNotifications: value,
          },
        },
        refetchQueries: [{
          query: ProfileQuery,
        }],
      });
    } catch (err) {
      console.log('setNotificationStatus', err);
    }
  }
}

export default new ProfileService();
