import ReportModule from 'modules/Report/report.module';
import DefaultLeftController from 'controllers/default.left';

class ReportController extends DefaultLeftController {
  controller() {
    super.controller();
    this.addTSModule(ReportModule);
  }
}

export default ReportController;
