import * as Sentry from '@sentry/browser';
import { Auth } from '.';
import { Router } from './router.service';

const {
  REACT_APP_SENTRY_URL,
  REACT_APP_ENVIRONMENT,
} = process.env;

window.addEventListener('error', (e) => {
  Router.onError(e, 'GLOBAL_ERROR', 'window');
});

window.addEventListener('unhandledrejection', (e) => {
  Router.onError(e, 'GLOBAL_ERROR', 'window');
});

class SentryService {
  public init() {
    const userId = Auth.getUserId();

    if (REACT_APP_ENVIRONMENT === 'local') { return; }

    Sentry.init({
      dsn: REACT_APP_SENTRY_URL,
      environment: REACT_APP_ENVIRONMENT,
    });

    if (userId) {
      Sentry.configureScope((scope) => {
        scope.setUser({ id: userId });
      });
    }
  }

  public captureException(error) {
    Sentry.captureException(error);
  }

  public captureInfo(info) {
    Sentry.withScope((scope) => {
      scope.setLevel(Sentry.Severity.Info);
      Sentry.captureException(info);
    });
  }
}

export default new SentryService();
