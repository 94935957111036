import apollo from 'api';
import { observable } from 'decorators';
import { IChat } from 'interfaces';
import HeaderCommunityModule from 'modules/HeaderCommunity/header-community.module';
import collectivesService from 'services/data/collectives.service';
import { Router } from 'services/router.service';
import ChatsListQuery from '../../graphql/queries/chat/ChatsList.graphql';

class HeaderCommunityController {
  public module: HeaderCommunityModule;
  @observable(0, true) public unreadMessagesCount: number;

  public OnInit() {
    this.watchChats();
  }

  private watchChats() {
    const observe = apollo.watchQuery({
      query: ChatsListQuery,
    }).subscribe({
      next: ({ data: { chats } }) => {
        this.unreadMessagesCount = (chats as IChat[]).reduce((acc: number, { unreadMessageIds }) =>
          acc + unreadMessageIds.length, 0);
      },
      error: (e) => console.error('watchQuery Error', e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public async handleRoute() {
    const { associatedChatId } = await collectivesService.getActiveCollective();

    Router.go({ name: 'chats.chat', params: { chatId: associatedChatId }});
  }
}

export default HeaderCommunityController;
