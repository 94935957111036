import ErrorHandler from 'services/templator/error';

class Component extends ErrorHandler {
  constructor(options) {
    super();

    this.options = options || {};
    this.listeners = {};
    this.onInit();
  }

  onInit() {
    return undefined;
  }

  setOptions(newOptions) {
    this.options = { ...this.options, ...newOptions };

    Object.keys(newOptions).forEach(key => {
      if (!this.listeners[key]) { return; }

      this.listeners[key].forEach(listener => listener(this.options));
    });
  }

  subscribe(fn, ...keys) {
    fn(this.options);

    keys.forEach(key => {
      this.listeners[key] = this.listeners[key] || new Set([]);
      this.listeners[key].add(fn);
    });
  }
}

export default Component;
