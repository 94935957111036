import styles from 'components/custom/w-input.module.scss';
import { render, svg } from 'lit-html';
import { customElement } from './decorators';
import Validator from './validators';


@customElement({name: 'w-input'})
class WInput extends HTMLElement {
  public validators: Array<(value: any) => string> = [];
  private eyeWrapper: HTMLElement;
  private toggleVisibility = this._toggleVisibility.bind(this);
  private _el: HTMLInputElement = document.createElement('input');

  constructor() {
    super();
    setImmediate(() => this.appendChild(this._el));
    this._el.classList.add(styles['w-input']);
    this.classList.add(styles['w-input-wrapper']);
  }

  static get observedAttributes() {
    return ['name', 'placeholder', 'type', 'autofocus', 'value', 'disabled', 'minlength', ...Object.keys(Validator)];
  }

  public connectedCallback() {
    setImmediate(() => {
      this.addFormControls();

      const errors = this.validators.map((fn) => fn(this._el.value)).filter((e) => !!e);

      this._el.setCustomValidity(errors[0] || '');

      const { name, validity, value, validationMessage } = this._el;

      this._el.form.controls.set(name, { valid: validity.valid, validationMessage, value });
    });
    this._el.addEventListener('input', this.onInput);
  }

  public disconnectedCallback() {
    this._el.removeEventListener('input', this.onInput);
    if (this.eyeWrapper) {
      this.eyeWrapper.removeEventListener('click', this.toggleVisibility);
    }
  }

  public attributeChangedCallback(name, oldValue, newValue) {
    if (!!Validator.hasOwnProperty(name)) {
      this.validators.push(Validator[name]);
    } else {
      if (name === 'autofocus') {
        setImmediate(() => this._el.focus());
      } else {
        this._el.setAttribute(name, newValue);
      }
    }

    if (name === 'value' && newValue) {
      this._el.setAttribute(name, newValue);

      setImmediate(() => {
        this.addFormControls();
        this.validateInput();
      });

    }

    if (name === 'type' && newValue === 'password') {
      this.applyEye();
    }
  }

  private _toggleVisibility() {
    this._el.type = this._el.type === 'password' ? 'text' : 'password';
    render(this.eyeIcon, this.eyeWrapper);
    this._el.focus();
  }

  get eyeIcon() {
    const inactive = this._el.getAttribute('type') === 'password';

    return svg`
      <svg viewBox="0 0 49 32">
        <path fill="${inactive ? '#014660' : '#939292'}" d="M47.963 14.728c-0.72-0.31-1.563 0.008-1.881 0.71-3.793 8.372-12.3 13.781-21.675 13.781-9.163 0-17.498-5.168-21.411-13.22 3.913-8.052 12.248-13.22 21.411-13.22 6.644 0 13.024 2.749 17.503 7.543 0.53 0.567 1.433 0.609 2.015 0.092s0.625-1.396 0.094-1.964c-5.019-5.371-12.168-8.451-19.613-8.451-10.504 0-20.037 6.060-24.285 15.439-0.162 0.357-0.162 0.765 0 1.123 4.249 9.378 13.781 15.439 24.285 15.439s20.037-6.060 24.285-15.439c0.318-0.703-0.009-1.523-0.729-1.833z"/>
        <path fill="${inactive ? '#4cb49c' : '#939292'}" d="M14.644 16.271c0 5.383 4.38 9.763 9.763 9.763s9.763-4.38 9.763-9.763c0-5.383-4.38-9.763-9.763-9.763s-9.763 4.38-9.763 9.763zM31.372 16.271c0 3.84-3.124 6.965-6.965 6.965s-6.965-3.124-6.965-6.965c0-3.84 3.124-6.965 6.965-6.965s6.965 3.124 6.965 6.965z"/>
      </svg>
    `;
  }

  private applyEye() {
    this.eyeWrapper = document.createElement('span');
    this.eyeWrapper.addEventListener('click', this.toggleVisibility);
    this.eyeWrapper.classList.add(styles['eye-wrapper']);
    this.appendChild(this.eyeWrapper);
    render(this.eyeIcon, this.eyeWrapper);
  }

  private validateInput() {
    const { form, name, validity, value, validationMessage } = this._el;

    const errors = this.validators.map((fn) => fn(this._el.value)).filter((e) => !!e);
    this._el.setCustomValidity(errors[0] || '');
    const validationEvent = new CustomEvent('validation', {bubbles: true});
    this._el.dispatchEvent(validationEvent);
    this.addFormControls();

    form.controls.set(name, { valid: validity.valid, validationMessage, value });
  }

  private addFormControls() {
    if (!this._el.form.controls) {
      this._el.form.controls = new Map();
    }
  }

  private onInput = () => {
    this.validateInput();
    const changeEvent = new CustomEvent(
      'changeForm',
      { bubbles: true, detail: { current: this._el } }
    );
    this._el.form.dispatchEvent(changeEvent);
  }
}

export default WInput;
