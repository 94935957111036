import React from 'react';
import PropTypes from 'prop-types';

const AccordionGroup = ({ children }) => {
  const [active, setActive] = React.useState();

  const handleOpen = i => active === i ? setActive() : setActive(i);

  return React.Children.map(children, (child, i) =>
    React.cloneElement(child, {
      isOpen: i === active,
      onOpen: () => handleOpen(i),
    }));
};

AccordionGroup.propTypes = {
  children: PropTypes.array.isRequired,
};

export default AccordionGroup;
