import { html } from 'lit-html';

export default html`
  <div id="chat_nav"></div>
  <w-chat-header-module> </w-chat-header-module>
  <div id="messages_body">
    <div id="chat_messages"></div>
    <div id="giphy_wrapper"></div>
    <div id="poll"></div>
    <div id="chat_suggestions"></div>
    <w-chat-input-module> </w-chat-input-module>
  </div>
`;
