import { Observe } from 'decorators';
import { html } from 'lit-html';
import KPISFragment from './fragments/kpis.fragment';
import StepOneFragment from './fragments/step-1.fragment';
import StepTwoFragment from './fragments/step-2.fragment';
import StepThreeFragment from './fragments/step-3.fragment';
import StepFourFragment from './fragments/step-4.fragment';
import StepFiveFragment from './fragments/step-5.fragment';
import StepSixFragment from './fragments/step-6.fragment';
import JoinController from './join.controller';
import styles from './join.module.scss';

class JoinTemplate {
  public controller: JoinController;
  public kpi: KPISFragment;
  public one: StepOneFragment;
  public two: StepTwoFragment;
  public three: StepThreeFragment;
  public four: StepFourFragment;
  public five: StepFiveFragment;
  public six: StepSixFragment;
  public screens: string[] = ['one', 'kpis', 'two', 'three', 'four', 'five', 'six'];

  get mandatory() {
    return html`
      <div class="${styles.mandatory}">
        *Mandatory fields
      </div>
    `;
  }

  @Observe('activeScreen')
  get screen() {

    return this[this.screens[this.controller.activeScreen]].template;
  }

  get template() {

    return html`
      <div>
      ${this.screen}
      </div>
    `;
  }
}

export default JoinTemplate;
