import Fragment from 'services/templator/fragment';
import { html } from 'lit-html';
import { Icons } from 'components/lit';

class HeaderFragment extends Fragment {
  get back() {
    const { nav } = this.controller.state;
    const { selectedScreen } = this.data;
    const opts = this.router.state.left.single
      ? {
        backIcon: 'circleCross',
        backLabel: 'Close',
      }
      : nav[selectedScreen];

    return html`
      <button
        @click=${() => this.controller.back()}
      >
        ${Icons[opts.backIcon]} ${opts.backLabel}
      </button>
    `;
  }

  get next() {
    const { nav, allowNext } = this.controller.state;
    const { selectedScreen } = this.data;
    const opts = nav[selectedScreen];

    return html`
      <button
       @click=${() => this.controller.next()}
       ?disabled=${!allowNext}
       >
          ${opts.nextLabel} ${Icons[opts.nextIcon]}
       </button>
    `; }

  get template() {
    return html`
      <div class="${this.styles.header}">
        <div>
           ${this.directives.renderNavButton('back')}
        </div>
        <div>
         ${this.directives.renderNavButton('next')}
        </div>
      </div>
    `;
  }
}

export default HeaderFragment;
