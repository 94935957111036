import apollo from 'api';
import { IStreaks } from 'interfaces';
import BaseService from 'services/data/base.service';
import { SignalR } from 'services/index';
import streaksQuery from '../../graphql/queries/streaks.graphql';

class StreaksService extends BaseService {
  public getStreaks() {
    return apollo
      .query({ query: streaksQuery, fetchPolicy: 'no-cache' })
      .then(({ data: { streaks } }): IStreaks => streaks);
  }

  public handleStreaksUpdate(handler) {
    return SignalR.subscribe('StreakUpdated', handler);
  }
}

export default new StreaksService();
