import apollo from 'api';
import { observable } from 'decorators';
import { SessionGuide } from 'interfaces/sessionGuides';
import guides from '../../graphql/queries//sessionGuides.graphql';
import DashSessionModule from './dash-session-guides.module';

class DashSessionController {
  public module: DashSessionModule;
  @observable([]) public sessionGuides;
  @observable({}) public selectedSessionGuide;

  constructor() {
    this.handleSessionGuides = this.handleSessionGuides.bind(this);
  }

  public async OnInit() {
    const data = await this.fetchSessions();
    const {
      data: {
        profile: { sessionGuides },
      },
    } = data;

    this.sessionGuides = sessionGuides;
    this.selectedSessionGuide = sessionGuides[0];
  }

  private async fetchSessions() {
    const data = await apollo.query({
      query: guides,
      fetchPolicy: 'no-cache',
    });

    return data;
  }

  public handleSessionGuides(event: React.FormEvent<HTMLSelectElement>) {
    const { value } = event.currentTarget;
    if (!value) {
      return;
    }

    this.selectedSessionGuide = this.sessionGuides.find((sessionGuide: SessionGuide) => sessionGuide.id === value);
  }
}

export default DashSessionController;
