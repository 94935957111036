import { render } from 'lit-html';
import { RouterModule } from 'services/router/router.m';
import DashSessionController from './dash-session-guides.controller';
import DashSessionView from './dash-session-guides.view';

@RouterModule({
  name: 'w-dash-session-guides-module',
  controller: DashSessionController,
  view: DashSessionView,
})
class DashSessionModule {
  public subs: Set<() => void> = new Set([]);
  public view: DashSessionView;

  public mount(module) {
    render(this.view.template, module);
  }

  public unmount() {
    this.subs.forEach((fn) => fn());
    this.subs = new Set([]);
  }
}

export default DashSessionModule;
