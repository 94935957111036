import DefaultController from 'controllers/default';
import ChatFlow from 'modules/ChatFlow';
import ChatsModule from 'modules/Chats/chats.module';
import { DynamicListeners } from '../services/router/router.interfaces';

class DefaultLeftController extends DefaultController {
  before() {
    super.before();

    const { state: { left }, display, route } = this.router;

    const isModal = left && left.modal;
    const isMobile = display === 'mobile';
    const isStepper = ['chat.create', 'chat.update'].includes(route.name);

    if (isMobile && !isStepper && isModal) {
      this.router.go({ name: `chat.${left.action}`, params: { chatId: left.chatId } });

      return;
    }

    if (!isMobile && isStepper) {
      const defaultChatId = this.router.controller.collectives.where(({ isActive }) => isActive);

      this.router.go({ name: 'chats.chat', params: { chatId: left.chatId || defaultChatId } });
    }
  }

  controller() {
    super.controller();

    const unsubscribe = this.router.listenDynamic(DynamicListeners.state, () => {
      const { state: { left } } = this.router;
      const isModal = left && left.modal;

      isModal
        ? this.addModule(ChatFlow)
        : this.addTSModule(ChatsModule);
    });

    this.addDestroyer(unsubscribe);
  }
}

export default DefaultLeftController;
