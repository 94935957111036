import { Observe } from 'decorators';
import { html } from 'lit-html';
import styles from '../join.module.scss';
import JoinTemplate from '../join.view';

class StepSixFragment {
  public view: JoinTemplate;

  get fields() {
    return ['frequency', 'availability', 'contribution', 'timezones', 'commitment'];
  }

  get header() {
    return html`
      <div class="${styles.desc}">
        This programme is a commitment and when you agree to take it on, it's like a social contract between you and the
        other person. It's also important to define and commit the boundaries.
      </div>
    `;
  }

  @Observe('sixValid', 'loading')
  get buttons() {
    const { sixValid, loading } = this.view.controller;

    return html`
      <div class="${styles.buttons}">
        <button
          ?disabled="${loading}"
          type="button"
          @click=${(e) => this.view.controller.onBack(e)}
          class="w-button outline ${styles.back}"
        >
          Back
        </button>
        <button type="submit" class="w-button primary ${styles.next}" ?disabled="${!sixValid || loading}">
          ${loading ? 'Loading...' : 'Next'}
        </button>
      </div>
    `;
  }

  @Observe('frequencyOptions', 'frequency')
  get frequency() {
    return html`
      <w-validation-box label="How often do you plan to meet / speak with your your match on the programme?*">
        <w-select
          name="frequency"
          .selected=${this.view.controller.frequency}
          vRequired
          .options=${this.view.controller.frequencyOptions}
          .placeholder="${'Please choose'}"
        />
      </w-validation-box>
    `;
  }

  @Observe('availabilityOptions', 'availability')
  get availability() {
    return html`
      <w-validation-box label="Will you be available on WERKIN app chat between meetings and calls?*">
        <w-select
          name="availability"
          .selected=${this.view.controller.availability}
          vRequired
          .options=${this.view.controller.availabilityOptions}
          .placeholder="${'Please choose'}"
        />
      </w-validation-box>
    `;
  }

  @Observe('contributionOptions', 'contribution')
  get contribution() {
    return html`
      <w-validation-box label="Will you help your community in 2-3 meaningful ways over the programme?*">
        <w-select
          name="contribution"
          .selected=${this.view.controller.contribution}
          vRequired
          .options=${this.view.controller.contributionOptions}
          .placeholder="${'Please choose'}"
        />
      </w-validation-box>
    `;
  }

  @Observe('timezonesOptions', 'timezones')
  get timezones() {
    return html`
      <w-validation-box label="Are you open to your match being based in a different location / time zone?*">
        <w-select
          name="timezones"
          .selected=${this.view.controller.timezones}
          vRequired
          .options=${this.view.controller.timezonesOptions}
          .placeholder="${'Please choose'}"
        />
      </w-validation-box>
    `;
  }

  @Observe('commitmentOptions', 'commitment', 'commitmentLabel')
  get commitment() {
    const { commitmentLabel, commitment, commitmentOptions } = this.view.controller;

    return html`
      <w-validation-box label="${commitmentLabel}">
        <w-select
          name="commitment"
          .selected=${commitment}
          vRequired
          .options=${commitmentOptions}
          .placeholder="${'Please choose'}"
        />
      </w-validation-box>
    `;
  }

  @Observe('disabledFields', 'hiddenFields')
  get form() {
    return html`
      <form
        name="six"
        novalidate="novalidate"
        class="${styles.form}"
        @changeForm="${(e) => this.view.controller.onChange(e)}"
        @submit="${(e) => this.view.controller.onSubmit(e)}"
      >
        ${this.frequency} ${this.availability} ${this.contribution} ${this.timezones} ${this.commitment} ${this.buttons}
      </form>
    `;
  }

  get template() {
    return html` <div>${this.header} ${this.form}</div> `;
  }
}

export default StepSixFragment;
