import normalizeUrl from 'normalize-url';
import Delta from 'quill-delta';
import constants from './constants';

export default (Quill) => {
  const Module = Quill.import('core/module');

  class UrlModule extends Module {
    private quill: any;

    constructor(quill, options) {
      super(quill, options);

      this.quill = quill;
      options = options || {};
      this.options = { ...constants, ...options };
      this.registerTypeListener();
      this.registerPasteListener();
    }

    public registerPasteListener() {
      this.quill.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
        if (typeof node.data !== 'string') {
          return;
        }
        const matches = node.data.match(this.options.globalRegularExpression);
        if (matches && matches.length > 0) {
          const newDelta = new Delta();
          let str = node.data;
          matches.forEach((match) => {
            const split = str.split(match);
            const beforeLink = split.shift();
            newDelta.insert(beforeLink);
            newDelta.insert(match, { link: this.normalize(match) });
            str = split.join(match);
          });
          newDelta.insert(str);
          delta.ops = newDelta.ops;
        }

        return delta;
      });
    }

    public registerTypeListener() {
      this.quill.on('text-change', (delta) => {
        const ops = delta.ops;
        if (!ops || ops.length < 1 || ops.length > 2) {
          return;
        }

        this.checkTextForUrl();
      });
    }

    public checkTextForUrl() {
      const sel = this.quill.getSelection();
      if (!sel) {
        return;
      }

      const [leaf] = this.quill.getLeaf(sel.index);
      const [prevLeaf] = this.quill.getLeaf(sel.index - 1);

      if (!leaf.text) { return; }

      if (leaf.parent.domNode.localName === 'a') {
        const validLink = leaf.text.match(this.options.urlRegularExpression);

        if (validLink) { return; }
        this.urlToText(this.quill.getIndex(leaf), leaf.text);
      }

      let value = leaf.text;
      let startIndex = this.quill.getIndex(leaf);

      if (prevLeaf.parent.domNode.localName === 'a') {
        value = `${prevLeaf.text}${leaf.text}`;
        startIndex = this.quill.getIndex(prevLeaf);
      }

      const endValue = value.split(' ').splice(-1)[0];
      const urlMatch = value.match(this.options.urlRegularExpression);

      if (!urlMatch) { return; }
      if (endValue !== urlMatch[0]) { return; }

      const index = startIndex + urlMatch.index;
      this.textToUrl(index, urlMatch[0]);
    }

    public textToUrl(index, url) {
      try {
        const ops = new Delta()
          .retain(index)
          .delete(url.length)
          .insert(url, { link: this.normalize(url) });
        this.quill.updateContents(ops);
      } catch (err) {
        console.warn('Could not validate user-defined URL');
      }
    }

    public urlToText(index, text) {
      const ops = new Delta()
        .retain(index)
        .delete(text.length)
        .insert(text);
      this.quill.updateContents(ops);
    }

    public normalize(url) {
      if (this.options.normalizeRegularExpression.test(url)) {
        return normalizeUrl(url, this.options.normalizeUrlOptions);
      }

      return url;
    }
  }

  Quill.register({ 'modules/url': UrlModule }, true);
};
