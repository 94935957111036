import Error from './error';

const PROTECTED_METHODS = ['addSubscription', 'destroy', 'module'];

export class RouterModule extends Error {
  constructor(router) {
    super(router.onError);

    Object.keys(router.module_plugins).forEach(key => {
      if (PROTECTED_METHODS.includes(key)) {
        throw new Error(`Method with name "${key}" already existed, please use another name.`);
      }

      this[key] = router.module_plugins[key];
    });

    this.router = router;
    this.module = null;
    this.subscriptions = new Set([]);
  }

  mount() {
    return undefined;
  }

  unmount() {
    return undefined;
  }

  addSubscription(sub) {
    this.subscriptions.add(sub);
  }

  get destroy() {
    return () => { this.subscriptions.forEach(subscription => subscription()); };
  }
}
