import { html } from 'lit-html';
import styles from './terms-footer.module.scss';

export const TermsFooter = html`
  <div class="${styles.wrapper}">
    By clicking &apos;Register&apos; and using our product, you agree to WERKIN&apos;s
    <a href='https://getwerkin.com/terms-of-use' rel='noopener noreferrer' target='_blank'>Terms of Use</a>
    and acknowledge that you have read the
    <a href='https://getwerkin.com/privacy' rel='noopener noreferrer' target='_blank'>Privacy Policy</a>
  </div>
`;
