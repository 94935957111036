import apollo from 'api';
import { observable } from 'decorators';
import { IProfileAction } from 'interfaces';
import { gaTrackEvent } from 'services/analytics.service';
import { Router } from 'services/router.service';
import ProfileQuery from '../../graphql/queries/profile/Profile.graphql';
import DashProfileCompletionModule from './dash-profile-completion.module';

class DashProfileCompletionController {
  public module: DashProfileCompletionModule;
  @observable([]) public profileActions: IProfileAction[];

  constructor() {
    this.handleAction = this.handleAction.bind(this);
  }

  public async OnInit() {
    this.fetchProfile();
  }

  public async fetchProfile() {
    const { data: { profile } } = await apollo.query({
      query: ProfileQuery,
    });

    this.setActions(profile);
  }

  public handleAction(action) {
    const { id, label, route, params } = action;

    gaTrackEvent({ category: 'dashboard:profile-completion', action: id, label });
    Router.go({ name: route, params });
  }

  public setActions(profile) {
    const { general, professionalDevelopment, skillsAndInterests } = profile;
    const { learningAndDevelopmentAreas } = professionalDevelopment;
    const {
      profileImageUrl,
      relevantWebsites,
      socialMedia,
    } = general;

    this.profileActions = [
      {
        id: 'profile-image',
        label: 'Profile image',
        value: profileImageUrl,
        route: 'user-profile',
        params: {
          tab: 'general',
        },
      },
      {
        id: 'social-media',
        label: 'Social media',
        value: socialMedia.filter(({ value }) => value).length,
        route: 'user-profile',
        params: {
          tab: 'general',
        },
      },
      {
        id: 'relevant-websites',
        label: 'Relevant websites',
        value: relevantWebsites.filter(Boolean).length,
        route: 'user-profile',
        params: {
          tab: 'general',
        },
      },
      {
        id: 'goals',
        label: 'Your goals',
        value: learningAndDevelopmentAreas.length,
        route: 'user-profile',
        params: {
          tab: 'professional_development',
        },
      },
      {
        id: 'skills-and-interests',
        label: 'Skills & Interests',
        value: skillsAndInterests.length,
        route: 'user-profile',
        params: {
          tab: 'skills_and_interests',
        },
      },
    ];
  }
}

export default DashProfileCompletionController;
