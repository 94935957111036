import styles from 'components/custom/w-checkbox.module.scss';
import { html, svg } from 'lit-html';
import { customElement } from './decorators';
import Element from './Element';

@customElement({name: 'w-checkbox'})
class WCheckbox extends Element {
  public className: any;
  public value: boolean;
  public disabled: boolean;

  private checked = svg`
    <svg viewBox="0 0 61 61">
      <g fill="none" fill-rule="evenodd">
        <rect width="57" height="57" x="2" y="2" fill="#4CB49C" stroke="#4CB49C" stroke-width="4" rx="15"/>
        <g fill="#FFF" transform="translate(14 17)">
        <rect width="4" height="13" x="4.01" y="14.51" rx="2" transform="rotate(-45 6.01 21.01)"/>
        <rect width="4" height="33" x="18.081" y="-2.561" rx="2" transform="rotate(45 20.081 13.94)"/>
        </g>
      </g>
    </svg>
  `;

  private unchecked = svg`
    <svg viewBox="0 0 61 61">
      <g fill="none" fill-rule="evenodd">
        <rect width="61" height="61" fill="#B9B9B9" opacity=".7" rx="15"/>
        <g fill="#FFF" transform="translate(14 17)">
          <rect width="4" height="13" x="4.01" y="14.51" rx="2" transform="rotate(-45 6.01 21.01)"/>
          <rect width="4" height="33" x="18.081" y="-2.561" rx="2" transform="rotate(45 20.081 13.94)"/>
        </g>
      </g>
    </svg>
  `;

  static get observedAttributes() {
    return ['value', 'disabled', 'classname'];
  }

  public attributeChangedCallback(name, oldValue, newValue) {
    switch (true) {
      case(name === 'classname'):
        this.className = newValue;
        break;
      case(name === 'value'):
        this.value = newValue === 'true';
        break;
      default:
        this[name] = newValue;
    }

    this.update();
  }

  private onChange() {
    const event = new CustomEvent('toggle', { bubbles: true });

    this.value = !this.value;

    this.update();
    this.dispatchEvent(event);
  }

  get template() {
    return html`
      <div
        class="${styles.base} ${this.className} ${this.disabled ? styles.disabled : ''} checkbox"
        @click="${() => this.onChange()}"
      >
        ${!!this.value ? this.checked :  this.unchecked}
      </div>
    `;
  }
}

export default WCheckbox;
