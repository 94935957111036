import apollo from 'api';
import { Auth } from 'services';
import Directive from 'services/templator/directive';
import { directive } from 'lit-html';
import ProfileQuery from '../../../graphql/queries/profile/Profile.graphql';

class ProfileDirectives extends Directive {
  get getAvatar() {
    return directive(() => part =>  {
      const observe = apollo.watchQuery({
        query: ProfileQuery,
        variables: {
          id: Auth.getUserId(),
        },
      }).subscribe({
        next: ({ data: { profile } }) => {
          const {
            doNotDisturb,
            general: {
              firstName,
              lastName,
              profileImageUrl,
            },
            notificationMute,
          } = profile;
          const name = `${firstName} ${lastName}`;

          part.setValue(this.view.avatar(profileImageUrl, name, doNotDisturb, notificationMute));
          part.commit();
        },
        error: (e) => console.error(e),
      });

      this.module.subscriptions.add(() => observe.unsubscribe());
    });
  }
}

export default ProfileDirectives;
