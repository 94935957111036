import apollo from 'api';
import suggestFeatureMutation from '../../graphql/mutations/suggestFeature.graphql';
import reportProblemMutation from '../../graphql/mutations/reportProblem.graphql';

export async function suggestFeature(text) {
  await apollo.mutate({
    mutation: suggestFeatureMutation,
    variables: {
      command: { text },
    },
  });
}

export async function reportProblem(text) {
  await apollo.mutate({
    mutation: reportProblemMutation,
    variables: {
      command: { text },
    },
  });
}
