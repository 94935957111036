import { ValidationStrategy } from 'components/custom/enums';
import { TermsFooter } from 'components/lit';
import { Observe } from 'decorators';
import { IInput } from 'interfaces';
import { html } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';
import { repeat } from 'lit-html/directives/repeat';
import { Router } from 'services/router.service';
import LoginController from './login.controller';
import styles from './login.module.scss';

const INPUTS = [
  {
    name: 'email',
    label: 'Email Address',
    placeholder: 'Email address',
    type: 'email',
    disabled: true,
    validationStrategy: ValidationStrategy.off,
  },
  {
    autofocus: true,
    name: 'password',
    label: 'Password',
    placeholder: 'Enter your password',
    type: 'password',
    catchError: true,
    validationStrategy: ValidationStrategy.delay,
  },
];

class LoginView {
  public controller: LoginController;

  public get template() {
    const forgot = { name: 'forgot-password' };

    return html`
      <div class="${styles.wrapper}">
        <form
          @changeForm=${(e) => this.controller.onChange(e)}
          @submit=${(evt) => this.controller.onNext(evt)}
        >
          ${this.inputs}
          <div class="${styles.buttons}">
            <a
              href=${Router.generate(forgot)}
              @click="${(evt) => this.controller.link.call(this, evt, forgot)}"
            >
              Forgot password?
            </a>
            ${this.button}
          </div>
        </form>

        ${ TermsFooter }
      </div>
    `;
  }

  @Observe('error')
  private get inputs() {
    return repeat(INPUTS, (i) => i.name, (i) => this.input(i));
  }

  private input(ops: IInput) {
    const value = ops.name === 'email' ? Router.state.email : undefined;

    return html`
      <w-validation-box
        name="${ops.name}"
        label="${ops.label}"
        outsideerror="${ops.catchError ? this.controller.error : undefined}"
        strategy="${ifDefined(ops.validationStrategy)}"
        >
          <w-input
            autocomplete="new-password"
            minlength="8"
            autofocus=${ifDefined(ops.autofocus)}
            ?disabled="${ops.disabled}"
            value="${ifDefined(value)}"
            type="${ops.type}"
            name="${ops.name}"
            placeholder="${ops.placeholder}"
            vRequired
            vDisallowSpace
          >
      </w-validation-box>
    `;
  }

  @Observe('loading', 'valid')
  private get button() {
    const { loading, valid } = this.controller;
    const text = loading ? 'Loading...' : 'Next';

    return html`<button class="w-button primary" type="submit" ?disabled="${!valid}">${text}</button>`;
  }
}

export default LoginView;
