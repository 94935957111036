import apollo from 'api';
import { observable } from 'decorators';
import { IChat } from 'interfaces';
import ChatsListModule from 'modules/ChatsList/chats-list.module';
import { Router } from 'services/router.service';
import ChatsListQuery from '../../graphql/queries/chat/ChatsList.graphql';
import findChatsQuery from '../../graphql/queries/findChats.graphql';

class ChatsListController {
  public destroy: () => void;
  public module: ChatsListModule;

  @observable('') public search: string;
  @observable(false) public loading: boolean;
  @observable([]) public chats: IChat[];
  @observable([]) public filteredChats: IChat[];

  public OnInit() {
    const observe = apollo.watchQuery({
      query: ChatsListQuery,
    }).subscribe({
      next: ({ data: { chats } }) => {
        this.filteredChats = chats.filter((chat) => chat.type !== 'direct');
      },
      error: (e) => console.error(e),
    });

    this.module.subs.add(() => observe.unsubscribe());
  }

  public onInput(evt) {
    const { value } = evt.target;
    this.loading = !!value;
    this.search = value;

    if (!value) {
      this.filteredChats = this.chats;
    }
  }

  public async onSearch() {
    if (!!this.search) {
      const { data: { findChats } } = await apollo.query({
        query: findChatsQuery,
        variables: { titleString: this.search },
      });

      this.filteredChats = findChats;
      this.loading = false;
    } else {
      this.filteredChats = this.chats;
    }
  }

  public goToChat(evt, route, chat) {
    evt.preventDefault();
    Router.go(route);
    this.destroy();
  }
}

export default ChatsListController;
