import DefaultLeftController from 'controllers/default.left';
import SubNav from 'modules/SubNav';

class MessagesController extends DefaultLeftController {
  controller() {
    super.controller();
    this.addModule(SubNav);
  }
}

export default MessagesController;
