import Fragment from 'services/templator/fragment';
import { Auth } from 'services';
import { html } from 'lit-html';
import { Icons } from 'components/lit';
import { repeat } from 'lit-html/directives/repeat';

class ParticipantsFragment extends Fragment {
  chevron(dir) {
    return html`
      <div
        class="${this.styles[`chevron_${dir}`]}"
        @click="${() => this.controller.scrollTo(dir)}"
      >
        ${Icons.selectArrow}
      </div>
    `;
  }

  participant(user) {
    return html`
      <div class="${this.styles.participant}">
        ${Icons.removeIcon((evt) => this.controller.addRemove(evt, user, 'member'))}

        <w-avatar avatar="${user.avatar}" title="${user.name}" width="54px"> </w-avatar>

        <span>${user.name.split(' ')[0]}</span>
      </div>
    `;
  }

  get list() {
    const userId = Auth.getUserId();
    const participants = this.data.members.filter(member => member.id !== userId);

    return html`
      <div class="${this.styles['participants-wrapper']}">
        ${this.directives.renderChevron('left')}
        ${this.directives.renderChevron('right')}

        <div class="${this.styles['participants-list-wrapper']}">
          <div class="${this.styles.participants}">
            ${repeat(participants, member => member.id, this.participant)}
          </div>
        </div>
      </div>

    `;
  }

  get template() {
    const userId = Auth.getUserId();
    const participants = this.data.members.filter(member => member.id !== userId);

    return this.directives.showIf(participants.length, this.list);
  }
}

export default ParticipantsFragment;
