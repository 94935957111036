import GeneralDirectives from 'directives/directives';

import LoadButtonTemplate from 'modules/Header/loadButton/load.view';
import styles from 'modules/Header/loadButton/load.module.scss';
import { RouterModule } from 'services/router/router.module';
import LoadController from './load.controller';

class Load extends RouterModule {
  mount() {
    const load = new LoadButtonTemplate(this.module);

    load.styles = styles;
    load.directives = GeneralDirectives;
    load.controller = LoadController;

    this.manageLitMount(load.template, 'header-load-button');
  }
}

export default Load;
