import { observable, ObserveMethod } from 'decorators';
import { ILeaderboard, ISearchTag } from 'interfaces';
import CreditsLeaderboardModule from 'modules/CreditsLeaderboard/credits-leaderboard.module';
import authService from 'services/auth.service';
import leaderboardService from 'services/data/leaderboard.service';

const FILTER_OPTIONS = [
  {
    id: 'points',
    label: 'Collective rank leaderboard',
  },
  {
    id: 'streak',
    label: 'Collective streakboard',
  },
];

class CreditsLeaderboardController {
  public module: CreditsLeaderboardModule;

  @observable(FILTER_OPTIONS[0], true) public searchTag: ISearchTag;
  @observable(FILTER_OPTIONS, true) public options: ISearchTag[];
  @observable(null, true) public leaderboard: ILeaderboard;

  public async OnInit() {
    this.onSearchTagUpdate();

    const unsubscribe = leaderboardService.subscribeLeaderboardUpdated(this.onChangeSearchTag.bind(this));

    this.module.subs.add(unsubscribe);
  }

  @ObserveMethod('searchTag')
  public async onSearchTagUpdate() {
    this.leaderboard = await leaderboardService.getLeaderboard(this.searchTag.id);
    console.log(this.leaderboard);
  }

  public onChangeSearchTag(event) {
    this.searchTag = event.target.controls.get('credits-leaderboard').value;
  }

  public getFormattedName(id, name: string) {
    const isMe = authService.getUserId() === id;
    const slittedName = name.split(' ');
    const youPart = isMe ? ' (you)' : '';

    return slittedName[0] + ' ' + slittedName[1][0] + '.' + youPart;
  }
}

export default CreditsLeaderboardController;
