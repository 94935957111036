import { directive } from 'lit-html';
import Directive from 'services/templator/directive';
import DatePicker from 'atoms/lit/datepicker/datepicker.view';

class CalendarDirective extends Directive {
  onInit() {
    this.calendar = new DatePicker({
      disablePreviousDates: true,
      onChange: this.controller.onChange,
    });
  }

  get renderSelectedDate() {
    return directive(() => part => {
      this.module.store.subscribe('selectedDate', () => {
        const { moment, store } = this.module;
        const date = store.get('selectedDate');

        part.setValue(moment(date).format('Do, MMMM, YYYY'));
        part.commit();
      });
    });
  }

  get renderCalendar() {
    return directive(() => part => {
      this.module.store.subscribe('selectedDate', () => {
        const date = this.module.store.get('selectedDate') || new Date().toISOString();

        this.calendar.setSelected(date);

        part.setValue(this.calendar.template);
        part.commit();
      });

      this.controller.subscribeState(state => {
        this.calendar.setHighlighted(state.highlighted);

        part.setValue(this.calendar.template);
        part.commit();
      }, 'highlighted');
    });
  }
}

export default CalendarDirective;
