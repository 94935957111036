import apollo from 'api';
import { observable } from 'decorators';
import { NotificationManager } from 'services';
import ChatsService from 'services/data/chats.service';
import { Router } from 'services/router.service';
import reportAbuseMutation from '../../graphql/mutations/reportAbuse.graphql';

class ReportController {
  @observable({}) public message: any;
  @observable(false) public loading: boolean;
  @observable(false) public valid: boolean;


  public async OnInit() {
    const message = await ChatsService.fetchChatMessage(Router.params.messageId);
    this.message = message;
  }

  public onCancel() {
    Router.go({ name: 'chats.chat', params: { chatId: Router.params.chatId } });
  }

  public async onReport(evt) {
    evt.preventDefault();
    // notification
    this.loading = true;

    await apollo.mutate({
      mutation: reportAbuseMutation,
      variables: {
        command: {
          text: evt.target.report.value,
          messageId: this.message.id,
        },
      },
    });

    Router.go({ name: 'chats.chat', params: { chatId: Router.params.chatId } });
    NotificationManager.showNotification({ text: 'That message has been reported', type: 'warning', duration: 2000 });
    this.loading = false;
  }

  public onFormChange(evt) {
    this.valid = Array.from(evt.target.controls).every(([, { valid }]) => valid);
  }
}

export default ReportController;
