import Error from './error';
import { Router } from 'services/router.service';
import RouterError from 'services/router/router-error';
import { DynamicListeners } from './router.interfaces';
const PROTECTED_METHODS = ['addModule', 'addDestroyer', 'modules', 'in', 'out', 'rejectable', 'router'];

export class RouterController extends Error {
  constructor(router) {
    super(router.onError);

    Object.keys(router.controller_plugins).forEach(key => {
      if (PROTECTED_METHODS.includes(key)) {
        throw new Error(`Method with name "${key}" already existed, please use another name.`);
      }

      this[key] = router.controller_plugins[key];
    });

    this.modules = new Set([]);
    this.destroyers = new Set([]);
    this.destroy = () => {};
    this.router = router;
  }

  guard() {
    return undefined;
  }

  awaiter() {
    return undefined;
  }

  layout() {
    return undefined;
  }

  before() {
    return undefined;
  }

  controller() {
    return undefined;
  }

  rejector(error, methodName) {
    const proto = Object.getPrototypeOf(this);
    const className = proto.constructor.name;

    return this.router.global_rejector(error, methodName, className);
  }

  get rejectable() {
    return (fn, hookName) => new Promise(async (resolve, reject) => {
      try {
        const response = await fn();

        this.destroy = reject;

        resolve(response);
      } catch (e) {
        this.rejector(e, hookName);
        reject(e, hookName);
      }
    });
  }

  addDestroyer(fn) {
    this.destroyers.add(fn);
  }

  addTSModule(module, props = {}) {
    module.router = this.router;
    module.module = module;

    module.view.module = module;
    module.vm.module = module;
    module.controller.module = module;

    module.view.router = this.router;
    module.vm.router = this.router;
    module.controller.router = this.router;

    Promise.resolve()
      .then(() => module.view.OnInit && module.view.OnInit())
      .then(() => module.controller.OnInit && module.controller.OnInit())
      .then(() => module.vm.OnInit && module.vm.OnInit())
      .then(() => module.mount(props))
      .then(() => {
        this.modules.add(module);
        this.destroyers.add(() => module.unmount());
        this.destroyers.add(() => module.destroy());
      })
      .catch(e => this.router.onError(e, 'mount', module.constructor.name));
  }

  addStateModule(Module, ...stateKeys) {
    let module;

    const state = Object.keys(this.router.state);

    const foundedKeys = stateKeys.reduce((acc, key) => {
      if (state.includes(key)) { acc.push(key); }

      return acc;
    }, []);

    console.log(foundedKeys);

    if (foundedKeys.length === stateKeys.length) {
      module = new Module(this.router);

      module.module = module;

      Promise.resolve()
        .then(() => module.mount())
        .then(() => {
          this.modules.add(module);
          this.destroyers.add(module.unmount);
          this.destroyers.add(module.destroy);
        })
        .catch(e => this.router.onError(e, 'mount', module.constructor.name));
    } else {
      module && this.modules.delete(module);
      this.destroyers.delete(module.unmount);
      this.destroyers.delete(module.destroy);

      module = undefined;
    }
  }

  addModule(Module, props = {}) {
    const module = new Module(this.router);

    if (!module) { return; }

    module.module = module;

    Promise.resolve()
      .then(() => module.mount(props))
      .then(() => {
        this.modules.add(module);
        this.destroyers.add(() => module.unmount());
        this.destroyers.add(() => module.destroy());
      })
      .catch(e => this.router.onError(e, 'mount', module.constructor.name));
  }

  addAutonomousModal(Modal) {
    const modal = new Modal();

    const isOpen = document.getElementById(modal.modal_name);

    !isOpen && Router.hash[modal.modal_name] && modal.open();
  }

  addTSAutonomousModal(Modal) {
    const unsubscribe = Router.listenDynamic(DynamicListeners.hash, () => {
      const modal = new Modal();
      const isOpen = document.getElementById(modal.modal_name);

      !isOpen && Router.hash[modal.modal_name] && modal.open();
    });

    this.addDestroyer(unsubscribe);
  }

  get in() {
    return () =>
      new Promise(async (resolve, reject) => {
        this.destroy = reject;

        try {
          await this.rejectable(this.guard.bind(this), 'GuardHook');
          await this.rejectable(this.awaiter.bind(this), 'AwaiterHook');
          await this.rejectable(this.layout.bind(this), 'LayoutHook');
          await this.rejectable(this.before.bind(this), 'BeforeHook');
          await this.rejectable(this.controller.bind(this), 'ControllerHook');

          resolve();
        } catch (e) {
          /**
           * Required for catching reject from rejectable method
           * Reject required for stopping hooks flow
           * Error will be handled inside rejectable method
           * */
          return e;
        }
      });
  }

  get out() {
    this.destroy(new RouterError({ message: 'Stopping...' }));

    return () =>
      new Promise(async (resolve, reject) => {
        try {
          this.destroyers.forEach(destroyer => destroyer());
          resolve();
        } catch (e) {
          this.rejector(e, 'out');
          reject();
        }
      });
  }
}
