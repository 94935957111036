import { observable } from 'decorators';
import { IAchievement, ICategory } from 'interfaces';
import capitalize from 'lodash/capitalize';
import achievementsService from 'services/data/achievements.service';
import { Router } from 'services/router.service';

import CreditsAchievementsModule from './credits-achievements.module';

const DEFAULT_CATEGORY = 'all';
const DEFAULT_TITLE = 'No awards. Yet';
const DEFAULT_TEXT = 'One day or day one? You decide';

class CreditsAchievementsController {
  public module: CreditsAchievementsModule;

  @observable([], true) public achievements: IAchievement[];
  @observable([], true) public categories: ICategory[];
  @observable(null, true) public selected: ICategory;

  private defaultOption: ICategory;

  public async OnInit() {
    const section = Router.params.section;
    const category = !section ? DEFAULT_CATEGORY : section;
    const text  = !section ? DEFAULT_TEXT : section;

    this.defaultOption = this.createOption(DEFAULT_CATEGORY, { text: DEFAULT_TEXT, title: DEFAULT_TITLE });
    this.selected = this.createOption(category, { text });

    this.setAchievementsAndCategories();

    const unsubscribe = achievementsService.subscribeAchievementsUpdated(this.setAchievementsAndCategories.bind(this));

    this.module.subs.add(unsubscribe);
  }

  public async setAchievementsAndCategories() {
    const { current, categories } = await achievementsService.getAchievements();

    this.achievements = current;
    this.categories = categories.reduce((acc, category) => {
      acc.push({
        id: category.type,
        label: capitalize(category.text),
        category: category.type,
        ...category,
      });

      return acc;
    }, [this.defaultOption]);
  }

  get visibleAchievements(): IAchievement[] {
    if (this.selected.category === DEFAULT_CATEGORY) {
      return this.achievements;
    }

    return this.achievements.filter(({ type }: IAchievement) => type === this.selected.category);
  }

  public onChangeForm(event) {
    const option = event.target.controls.get('credits-achievements');

    this.selected = option.value;

    Router.go({ params: { ...Router.params, section: option.value.id } });
  }

  public goToChat() {
    Router.go({ name: 'chat' });
  }

  private createOption(section, customization) {
    return {
      id: customization.id || section,
      label: customization.label || capitalize(section),
      title: customization.title || capitalize(section),
      category: customization.category || section,
      icon: customization.icon || section,
      text: customization.text || section,
      button: customization.button || {
        text: 'Back to WERKIN',
      },
    };
  }

}

export default CreditsAchievementsController;
